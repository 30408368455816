import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import {
  updateSupplier,
  saveSupplierSettings,
} from "../../../../views/supplier/SupplierActions";
import { getSettings } from "../../../../views/supplier/SupplierSelectors";
import { ButtonNew, Label, Switch, Column } from "../../../../components";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import Text from "../../../../modules/Text";
import { buildObjectFromFields } from "../../../../modules/Format";
import { Radio } from "@material-ui/core";
import createNotification from "../../../../modules/Notification";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

class Shipping extends Component {
  state = {
    saveState: null,
    errors: {},
    radioButtonSelected: this.props.branding_no_promo != null,
  };

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: "changed" });
  };

  validateFields = () => {
    // const settings = get(this.props, ["settings"]);

    let errors = {};

    // if (!settings.ships_to_us && !settings.ships_to_canada) {
    //   errors["general"] = "Shipping to either the US or Canada is required.";
    // }

    this.setState({ errors });

    return isEmpty(errors);
  };

  checkMandatoryFields = () => {
    return (
      this.props.settings.branding_no_promo != null &&
      this.props.settings.branding_on_box != null &&
      this.props.settings.branding_on_packaging != null &&
      this.props.settings.branding_on_product != null &&
      this.props.settings.branding_on_anything != null
    );
  };

  saveSettings = () => {
    if (this.validateFields()) {
      const settings = get(this.props, ["settings"]);

      const data = buildObjectFromFields(settings, [
        "branding_on_product",
        "branding_on_packaging",
        "branding_on_anything",
        "branding_on_box",
        "branding_no_promo",
        "branded_invoicing",
        "branding_description",
        "white_label",
        "white_label_product",
        "white_label_product_image",
        "white_label_packaging",
        "white_label_packaging_image",
        "white_label_logo",
        "white_label_logo_requirements",
        "white_label_email",
        "white_label_website",
        "white_label_message",
        "white_label_message_limit",
      ]);

      if (this.checkMandatoryFields()) {
        this.setState({ saveState: "saving" }, () => {
          this.props.saveSupplierSettings(data).then(() => {
            this.setState({ saveState: null });
          });
        });
      } else
        createNotification({
          type: "danger",
          title: "Please choose an option",
          message:
            "All the questions in the 'Branding / Packaging Description' section must be answered.",
        });
    }
  };

  render() {
    const settings = get(this.props, ["settings"]);

    return (
      <React.Fragment>
        <div style={{ marginTop: "15px" }}>
          {this.state.saveState ? (
            <ButtonNew
              text="SAVE CHANGES"
              type="main"
              onClick={this.saveSettings}
              isFetching={this.state.saveState === "saving"}
              extra="height: 36px; width: 150px; margin-top: 10px;"
              mobile100
            />
          ) : (
            <ButtonNew
              text="CHANGES SAVED"
              type="main-light"
              extra="height: 36px; width: 150px; margin-top: 10px;"
              mobile100
            />
          )}
        </div>

        <SubSection>
          <Switch
            noTopMargin
            active={settings.white_label}
            label="Offer White Labelling"
            description="You are able to offer white labelling (adding the stores branding or info) to the product or to the packaging."
            toggle={() =>
              this.updateSupplier({
                white_label: !settings.white_label,
              })
            }
          />

          {settings.white_label && (
            <Indent>
              <Switch
                noTopMargin
                active={settings.white_label_packaging}
                label="Packaging"
                description="You are able to white label product packaging."
                toggle={() =>
                  this.updateSupplier({
                    white_label_packaging: !settings.white_label_packaging,
                  })
                }
              />
              <Switch
                active={settings.white_label_product}
                label="Product"
                description="You are able to white label the products themselves."
                toggle={() =>
                  this.updateSupplier({
                    white_label_product: !settings.white_label_product,
                  })
                }
              />
            </Indent>
          )}
        </SubSection>

        <SubSection>
          <Switch
            noTopMargin
            active={settings.branded_invoicing}
            label="Branded Invoicing"
            description="You commit to printing out the DropCommerce-provided invoice / packing slip for each order and including it in the shipment. This will display as a special 'badge' on your profile which stores can use to filter suppliers."
            toggle={() =>
              this.updateSupplier({
                branded_invoicing: !settings.branded_invoicing,
              })
            }
          />
        </SubSection>
        <SubSection>
          <Column>
            <Label
              rowStyle={{ marginBottom: "10px", marginTop: "10px" }}
              warning={
                settings.branding_description === "" &&
                "Missing Branding Description"
              }
            >
              Branding / Packaging Description
            </Label>

            <Text.Small light extra="margin-bottom: 10px; font-size: 14px;">
              Please explain exactly what customers should expect to see in a
              shipment like whether or not your branding, contact information or
              other information will be visible to the customer within the
              package.
            </Text.Small>

            <form>
              Is your branding / contact info on the product?
              <Radio
                required
                color="default"
                checked={settings.branding_on_product === true}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_product: true,
                  })
                }
                value="yes"
                name="radio-buttons"
                inputProps={{ "aria-label": "Yes" }}
              />
              Yes &nbsp; &nbsp;
              <Radio
                color="default"
                checked={settings.branding_on_product === false}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_product: false,
                  })
                }
                value="no"
                name="radio-buttons"
                inputProps={{ "aria-label": "No" }}
              />
              No
              <br />
              Is your branding / contact info on the packaging?
              <Radio
                required
                color="default"
                checked={settings.branding_on_packaging === true}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_packaging: true,
                  })
                }
                value="yes"
                name="radio-buttons"
                inputProps={{ "aria-label": "Yes" }}
              />
              Yes &nbsp; &nbsp;
              <Radio
                color="default"
                checked={settings.branding_on_packaging === false}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_packaging: false,
                  })
                }
                value="no"
                name="radio-buttons"
                inputProps={{ "aria-label": "No" }}
              />
              No
              <br />
              Is your branding / contact info on the shipping box?
              <Radio
                required
                color="default"
                checked={settings.branding_on_box === true}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_box: true,
                  })
                }
                value="yes"
                name="radio-buttons"
                inputProps={{ "aria-label": "Yes" }}
              />
              Yes &nbsp; &nbsp;
              <Radio
                color="default"
                checked={settings.branding_on_box === false}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_box: false,
                  })
                }
                value="no"
                name="radio-buttons"
                inputProps={{ "aria-label": "No" }}
              />
              No
              <br />
              Is your branding / contact info on anything else?
              <Radio
                required
                color="default"
                checked={settings.branding_on_anything === true}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_anything: true,
                  })
                }
                value="yes"
                name="radio-buttons"
                inputProps={{ "aria-label": "Yes" }}
              />
              Yes &nbsp; &nbsp;
              <Radio
                color="default"
                checked={settings.branding_on_anything === false}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_on_anything: false,
                  })
                }
                value="no"
                name="radio-buttons"
                inputProps={{ "aria-label": "No" }}
              />
              No
              <br />
              Do you confirm that you will not include any additional
              promotional content like coupons, thank you cards or any other
              inserts?
              <Radio
                required
                color="default"
                checked={settings.branding_no_promo != null}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_no_promo: true,
                  })
                }
                value="yes"
                name="radio-buttons"
                inputProps={{ "aria-label": "Yes" }}
              />
              Yes &nbsp; &nbsp;
              <Radio
                color="default"
                checked={settings.branding_no_promo === null}
                onChange={(e) =>
                  this.updateSupplier({
                    branding_no_promo: false,
                  })
                }
                value="no"
                name="radio-buttons"
                inputProps={{ "aria-label": "No" }}
              />
              No
              <br />
              <StyledTextArea
                placeholder="Anything else you would like to inform us about your branding & packaging"
                onChange={(text) =>
                  this.updateSupplier({
                    branding_description: text.target.value,
                  })
                }
                value={settings.branding_description}
                error={settings.branding_description === ""}
                extraStyle="width: 100%;"
              />
            </form>
          </Column>
        </SubSection>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Shipping)
);

const Indent = styled.div`
  padding-left: 20px;
  padding-top: 20px;
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid
    ${(p) => (p.error ? theme.colors.accent : theme.colors.lightBorder)};
  height: 150px;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
