import React, { Component } from "react";
import styled from "styled-components";
// import { theme } from "../../../modules/Theme";
import checkIcon from "../../../images/check-icon-white.png";
import xIcon from "../../../images/denied-icon-white.png";
import alertIcon from "../../../images/alert-2.png";

export const PassIcon = styled.img`
  height: 50px;
  width: 50px;
  background: ${(p) => p.color};
  border-radius: 50px;
  padding: 10px;
`;

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 5px;
`;

export const Explanation = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
`;

export const TitleRow = styled.div`
  width: 100%;
  text-align: left;
  margin-right: 20px;
`;

class ApprovalRequirement extends Component {
  render() {
    const { title, description, explanation, pass, alert } = this.props;

    let icon = xIcon;
    let color = "#db3232";

    if (alert) {
      icon = alertIcon;
      color = "#30d472";
    } else if (pass) {
      icon = checkIcon;
      color = "#30d472";
    }

    return (
      <PageWrapper>
        <TitleRow>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Explanation>{explanation}</Explanation>
        </TitleRow>
        <PassIcon src={icon} color={color} />
      </PageWrapper>
    );
  }
}

export default ApprovalRequirement;
