import styled from "styled-components";
import { theme } from "../modules/Theme";
import React, { Component } from "react";
import Text from "../modules/Text";

// const FAQHeader = styled.div`
//   font-size: 25px;
//   text-align: center;
//   font-weight: 400;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   @media (max-width: 800px) {
//     font-size: 20px;
//     margin-bottom: 20px;
//   }
// `;

const FAQItem = styled.div`
  background: white;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  border-radius: 4px;
  border-top: 6px solid ${theme.colors.main};
  max-width: 400px;
  margin: 15px;
  margin-top: 30px;
  @media (max-width: 800px) {
    width: 99vw;
    margin: 5px;
  }
`;

const FAQItemTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${theme.colors.medDarkGrey};
`;

const FAQItemDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  opacity: 0.8;
`;

const FAQRow = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FAQColumn = styled.div``;

class FAQ extends Component {
  render() {
    const data = this.props.data;

    return (
      <React.Fragment>
        <Text.Large margin="70px 0 10px 0">
          Frequently Asked Questions
        </Text.Large>

        <FAQRow>
          {data.map((column, i) => (
            <FAQColumn key={i}>
              {column.map((item, i) => (
                <FAQItem key={i}>
                  <FAQItemTitle>{item.question}</FAQItemTitle>
                  <FAQItemDescription>{item.answer}</FAQItemDescription>
                </FAQItem>
              ))}
            </FAQColumn>
          ))}
        </FAQRow>
      </React.Fragment>
    );
  }
}

export default FAQ;
