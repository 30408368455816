import React from "react";
import Text from "../../../modules/Text";
import { Wrapper, Container } from "../../../components";

function AccessBlocked() {
  return (
    <Wrapper>
      <Container>
        <div>
          <Text.Large extra="margin-bottom: 20px;">Access Blocked</Text.Large>
          <Text.Small light>
            Please contact{" "}
            <a
              style={{
                color: "#062200",
                textDecoration: "none",
                fontWeight: "350",
              }}
              href="mailto:support@dropcommerce.com"
            >
              support@dropcommerce.com
            </a>{" "}
            to reinstate your account.
          </Text.Small>
        </div>
      </Container>
    </Wrapper>
  );
}

export default AccessBlocked;
