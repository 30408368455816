import React, { Component } from "react";
import Cookies from "js-cookie";
import Prebuilt from "../views/prebuilt/Prebuilt";
import { getUrlParameter } from "../modules/Format";
import { trackEvent } from "../modules/EventTracking";

class GetPrebuilt extends Component {
  componentDidMount = () => {
    const source = getUrlParameter("ref", this.props.location);

    window.tap("detect");

    if (source) {
      Cookies.set("dc_referral_source", source, { expires: 30 });
    } else {
      Cookies.set("dc_referral_source", "get_prebuilt", { expires: 30 });
    }
    trackEvent("GetPrebuilt - Page Loaded");
  };

  render() {
    return <Prebuilt external />;
  }
}

export default GetPrebuilt;
