import React, { Component } from "react";
import ButtonNew from "../../../../../../components/ButtonNew";
import alert from "../../../../../../images/alert.png";
import styled from "styled-components";
import { theme } from "../../../../../../modules/Theme";
import { FiMail } from "react-icons/fi";

export const ProductWrapper = styled.div`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 30px;
  width: 100%;
  ${(p) => p.padding && "padding: 15px;"}
`;

export const ILProductColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ILProductRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    props.borderTop &&
    "border-top: 1px solid " +
      theme.colors.lightBorder +
      "; padding-top: 15px; margin-top: 15px;"};
  ${(props) =>
    props.borderBottom &&
    "border-bottom: 1px solid " +
      theme.colors.lightBorder +
      ";  margin-bottom: 20px;"};
`;

export const WarningsHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
`;

export const WarningIcon = styled.img`
  height: 30px;
`;

export const ILProductWarningHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ILProductWarningText = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
`;

class ProductErrors extends Component {
  render() {
    const { product } = this.props;
    // const { tab, tags, suggestions, description, variants } = this.state;

    return (
      <ProductWrapper padding>
        <ILProductColumn>
          <ILProductRow style={{ marginBottom: "20px" }}>
            <WarningIcon src={alert} />
            <WarningsHeader>Error</WarningsHeader>
          </ILProductRow>
          <ILProductColumn>
            <ILProductWarningHeader>
              Error Importing Product
            </ILProductWarningHeader>
            <ILProductWarningText>
              We are so sorry, but for some reason we're having an issue
              importing this product. Please contact support and we will be more
              than happy to help get this sorted out ASAP! Head over to the
              support page or email us at support@dropcommerce.com
            </ILProductWarningText>
          </ILProductColumn>
          <ILProductRow style={{ marginTop: "20px" }}>
            <ButtonNew
              text="CONTACT SUPPORT"
              icon={<FiMail size="18" color="white" />}
              type="main"
              onClick={() => this.props.history.push("/contact")}
              isFetching={product.isFetching}
              disabled={product.imported}
              extra="margin-right: 20px; width: 200px"
            />
            <ButtonNew
              text="CLOSE WINDOW"
              type="secondary"
              extra="width: 255px;"
              onClick={() => this.props.fetchImportList(1)}
            />
          </ILProductRow>
        </ILProductColumn>
      </ProductWrapper>
    );
  }
}

export default ProductErrors;
