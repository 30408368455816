import React, { Component } from "react";
import Text from "../../../../modules/Text";
// import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { BorderBox, ButtonNew } from "../../../../components";
import { getRequest, postRequest } from "../../../../modules/API";
import MDSpinner from "react-md-spinner";

export default class Agreement extends Component {
  // PROD
  subdomain = window.location.host.split(".")[0];

  // TESTING
  // subdomain = "northwestapparel";

  state = {
    rules: [],
    fetching: true,
  };

  componentDidMount() {
    getRequest({ url: `api/rules/${this.subdomain}/` }).then((response) =>
      this.setState({
        rules: response.data.rules,
        fetching: false,
        agreed: response.data.agreed,
      })
    );
  }

  clickAgree = () => {
    postRequest({ url: `api/rules/${this.subdomain}/agree/` });
    this.setState({ agreed: true });
  };

  render() {
    return this.state.fetching ? (
      <MDSpinner
        singleColor={theme.colors.main}
        size={50}
        style={{ marginTop: "32px" }}
      />
    ) : (
      <div style={{ marginTop: "16px" }}>
        {this.state.rules.length > 0 && (
          <Text light>
            Users of this platform are expected to abide by these rules
          </Text>
        )}
        <br />
        {this.state.rules.map((rule, idx) => (
          <React.Fragment key={idx}>
            <BorderBox>
              <Text>{rule.name}</Text>
              <p>{rule.description}</p>
            </BorderBox>
            <br />
          </React.Fragment>
        ))}
        Please note that these rules are set by your supplier and are subject to
        change without notice.
        <br />
        <br />
        {!this.state.fetching && this.state.agreed === true ? (
          <ButtonNew disabled text="ACKNOWLEDGED" width="256px" />
        ) : (
          !this.state.fetching &&
          this.state.agreed === false && (
            <ButtonNew
              text="I ACKNOWLEDGE"
              onClick={() => this.clickAgree()}
              width="256px"
            />
          )
        )}
      </div>
    );
  }
}
