import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import BuyPrebuilt from "../BuyPrebuilt";
import { StylePackCard } from "../checkout";
import { getRequest } from "../../../modules/API";

import Account from "../../../views/account/components/AccountComponents";
import { INITIAL_CATEGORIES as categoryData } from "../../../constants/index";
import createNotification from "../../../modules/Notification";
import { ButtonNew } from "../../../components";
import { theme } from "../../../modules/Theme";

export const StoreCardsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ProductCategories = styled(Account)`
  width: 85vh;
`;

const OptionSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  padding: 30px;
  @media (max-width: 800px) {
    padding: 20px 5px 5px 5px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
`;

const SurveyCard = styled.div`
  border: ${(p) => (p.selected ? "2px" : "0px")} solid
    ${(p) => (p.selected ? theme.colors.main : theme.colors.lightBorder)};
  cursor: pointer;
  text-align: center;
  margin: ${(p) => (p.selected ? "0px" : "3px")};
  background: white;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const SurveyCardName = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  @media (max-width: 800px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const SurveyCardImage = styled.img`
  object-fit: cover;
  height: 120px;
  width: 100%;
`;

const StepButton = styled(ButtonNew)`
  margin-top: 20px;
  margin-right: 20px;

  padding: 10px;
  border-radius: 5px 0px 5px 0px;
  font-size: 16px;
  ${(p) => p.disabled && `opacity: 0.3`}
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MIN_CATEGORIES = 1;
const MAX_CATEGORIES = 3;

const Checkout = () => {
  const [step, setStep] = useState("selectCategory"); // [ "selectCategory", "selectStylePack", "purchase" ]
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stylePacks, setStylePacks] = useState([]);
  const [selectedStylePack, setSelectedStylePack] = useState("");

  const [purchased, purchasedSet] = useState(false);

  useEffect(() => {
    getRequest({ url: `prebuilt/style_packs/` })
      .then((response) => setStylePacks(response.data))
      .catch((error) => {});
  }, []);

  const selectOption = (option) => {
    if (
      !selectedCategories.includes(option) &&
      selectedCategories.length >= MAX_CATEGORIES
    ) {
      createNotification({
        type: "info",
        message: `Please select up to ${MAX_CATEGORIES} top categories only.`,
      });
    } else {
      setSelectedCategories((currentCategories) => {
        if (currentCategories.includes(option)) {
          return currentCategories.filter((category) => category !== option);
        }
        return [...currentCategories, option];
      });
    }
  };

  const saveCategories = () => {
    if (selectedCategories.length > 0) {
      setStep("selectStylePack");
    } else {
      createNotification({
        type: "info",
        message: "Please select at least one category",
      });
    }
  };

  let screen = null;
  if (step === "selectCategory") {
    screen = (
      <ProductCategories
        title={`Pick up to ${MAX_CATEGORIES} favorite categories`}
        subtitle="Your store will be loaded with premium products from these categories."
        isFetching={false}
        position="static"
        allowOverflow={true}
      >
        <StepButton
          text="Next step"
          type="main"
          onClick={saveCategories}
          disabled={selectedCategories.length < MIN_CATEGORIES}
        />
        <OptionSelectWrapper>
          {categoryData.map((choice, i) => (
            <SurveyCard
              key={i}
              onClick={() => selectOption(choice.value)}
              selected={selectedCategories.includes(choice.value)}
            >
              <SurveyCardImage src={choice.image} />
              <SurveyCardName>{choice.name}</SurveyCardName>
            </SurveyCard>
          ))}
        </OptionSelectWrapper>
      </ProductCategories>
    );
  } else if (step === "selectStylePack") {
    screen = (
      <Account
        title={`Pick a Style Pack`}
        subtitle="Style packs set the colors, style, and mood of the store."
        isFetching={false}
        position="static"
        allowOverflow={true}
      >
        <ButtonRow>
          <StepButton
            text="Previous step"
            type="main"
            onClick={() => setStep("selectCategory")}
          />
          <StepButton
            text="Next step"
            type="main"
            onClick={() => setStep("purchase")}
            disabled={!selectedStylePack}
          />
        </ButtonRow>
        <StoreCardsRow>
          {stylePacks.map((stylePack, i) => (
            <StylePackCard
              key={i}
              name={stylePack.name}
              image={stylePack.image_url}
              selected={selectedStylePack === stylePack.key}
              onClick={() => {
                setSelectedStylePack(stylePack.key);
              }}
            />
          ))}
        </StoreCardsRow>
      </Account>
    );
  } else {
    screen = (
      <Account
        title={`Complete your purchase`}
        subtitle="Select any extras and enter your Shopify domain"
        isFetching={false}
        position="static"
        allowOverflow={true}
      >
        {!purchased && (
          <StepButton
            text="Previous step"
            type="main"
            onClick={() => setStep("selectStylePack")}
          />
        )}
        <StoreContainer>
          <BuyPrebuilt
            storeData={{
              name: "Custom",
              level: "CUSTOM",
              type: "custom",
              price: 999.0,
            }}
            purchasedSet={purchasedSet}
            customCategories={selectedCategories}
            customStylePack={selectedStylePack}
            onBack={() => setStep("selectStylePack")}
          />
        </StoreContainer>
      </Account>
    );
  }
  return screen;
};

export default withRouter(Checkout);
