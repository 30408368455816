import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { FiPlayCircle } from "react-icons/fi";
import { Modal } from "./Modal";

const Background = styled.div`
  height: 230px;
  background: ${theme.colors.darkGreen};
  background-image: url(${(p) => p.background});
  background-size: cover;
  background-position: 0% 0%;
  border-radius: 4px;
  @media screen and (max-width: 800px) {
    height: 140px;
  }
`;

const BackgroundColor = styled.div`
  background: rgba(22, 63, 66, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  height: 100%;
  transition: all 0.3s;
  &:hover {
    background: rgba(22, 63, 66, 0.3);
  }
  @media screen and (max-width: 800px) {
    padding: 8px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  min-width: 200px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    min-width: 100%;
  }
`;

const PlayButton = styled.div`
  background: rgba(22, 63, 66, 0.8);
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const PlayButtonText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-right: 15px;
`;

class VideoCard extends Component {
  state = { showModal: false };

  hideModal = (e) => {
    e.stopPropagation();
    this.setState({ showModal: false });
  };

  render() {
    const { title, background, vimeoId } = this.props;

    return (
      <Background
        background={background}
        onClick={() => this.setState({ showModal: true })}
      >
        <BackgroundColor>
          <Title>{title}</Title>
          <PlayButton>
            <PlayButtonText>Watch Video</PlayButtonText>
            <FiPlayCircle size="30px" color="white" />
          </PlayButton>
        </BackgroundColor>
        {this.state.showModal && (
          <Modal maxWidth="640px" transparent hide={this.hideModal}>
            <div style={{ border: "4px solid white" }}>
              <iframe
                title={`https://player.vimeo.com/video/${vimeoId}`}
                src={`https://player.vimeo.com/video/${vimeoId}`}
                width="100%"
                height="346"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              />
            </div>
          </Modal>
        )}
      </Background>
    );
  }
}

export default VideoCard;
