import React from "react";

import SupplierReviewTable from "./SupplierReviewTable.jsx";

import Text from "../../../modules/Text";
import { Container } from "../../../components";

function SupplierReview() {
  return (
    <Container>
      <Text.Large extra="margin-bottom: 20px;">Reviews</Text.Large>
      <SupplierReviewTable />
    </Container>
  );
}

export default SupplierReview;
