import React from "react";
import styled from "styled-components";
import Text from "../../../modules/Text";

const StoreImage = styled.img`
  border-radius: 4px;
  height: 300px;
  cursor: pointer;
  ${(p) => p.marginLeft && "margin-left: 40px;"}
  ${(p) => p.marginRight && "margin-right: 40px;"} 
  max-width: 100%;
  @media (max-width: 800px) {
    height: 250px;
    margin: 0px;
    margin-top: 15px;
    ${(p) =>
      p.sellingPoint && p.imagePlacement === "left" && "margin-bottom: 30px;"};
  }
`;

const ImageSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 60px;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const SellingPointColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  @media (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ImageSection = ({ imagePlacement, title, textOne, textTwo, image }) => (
  <ImageSectionRow>
    {imagePlacement === "left" && (
      <StoreImage
        src={image}
        marginRight
        sellingPoint
        imagePlacement={imagePlacement}
      />
    )}
    <SellingPointColumn>
      <Text l bold>
        {title}
      </Text>
      <Text s light extra="margin-top: 15px; margin-bottom: 15px;">
        {textOne}
      </Text>
      <Text s light>
        {textTwo}
      </Text>
    </SellingPointColumn>
    {imagePlacement === "right" && (
      <StoreImage
        src={image}
        marginLeft
        sellingPoint
        imagePlacement={imagePlacement}
      />
    )}
  </ImageSectionRow>
);

export default ImageSection;
