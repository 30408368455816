import React, { Component } from "react";
import { ButtonNew } from "../../../components";
import Text from "../../../modules/Text";
import { get, isEmpty } from "lodash";
import StripeCard from "../../../components/StripeCard";
import { Row, Spinner } from "../../../components";
import PayPalBtn from "./PayPalBtn";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";

class Payment extends Component {
  currentPlan = get(this.props, ["settings", "store", "payment_plan", "plan"]);

  storeHasPlan = ["BASIC", "GROWTH", "PROFESSIONAL", "EXECUTIVE"].includes(
    this.currentPlan
  );

  render() {
    const { supplier } = this.props;
    return (
      <React.Fragment>
        {supplier.errors.length > 0 && (
          <Text>
            There are issues with this order. Please contact
            support@dropcommerce.com
          </Text>
        )}
        <Row style={{ marginTop: "10px" }} gap="10px">
          {this.paymentButtons()}

          <Link to={"/inbox?to_supplier=" + supplier.id}>
            <ButtonNew
              text="Message Supplier"
              type="main-dark"
              icon={<FiMail size="18" color="white" />}
            />
          </Link>
        </Row>
      </React.Fragment>
    );
  }

  paymentButtons = () => {
    const { supplier, order, settings } = this.props;

    const buttonTextMap = {
      pending: "Pending",
      paid: "Paid",
      cancelled: "Cancelled",
      shipped: "Shipped",
      error: "Error - Contact Support",
    };

    if (isEmpty(settings)) {
      return <Spinner />;
    }

    // Paid, Shipped or Error
    if (this.props.isPaid) {
      supplier.status = "paid";
    }
    if (["paid", "shipped", "error"].includes(supplier.status)) {
      return (
        <ButtonNew
          text={buttonTextMap[supplier.status]}
          type="main-light"
          disabled
          width="180px"
        />
      );
    }

    // Cancelled
    if (order.status === "cancelled") {
      return (
        <ButtonNew text="Cancelled" type="main-light" disabled width="180px" />
      );
    }

    // Catch any other errors
    if (supplier.errors.length > 0 || supplier.status !== "pending") {
      return (
        <ButtonNew
          text={buttonTextMap[supplier.status]}
          type="main-light"
          disabled
          width="180px"
        />
      );
    }

    return (
      <Row gap="10px">
        {this.renderCreditCardButton()}
        {this.renderPayPalButton()}
      </Row>
    );
  };

  renderCreditCardButton = () => {
    const { supplier, settings } = this.props;

    const stripeConnected = get(settings, ["store", "stripe_connected"]);

    if (supplier.stripe_id) {
      // First, check whether the supplier has stripe good to go.
      if (stripeConnected) {
        // Then, make sure that this store also has stripe set up.
        if (this.storeHasPlan)
          return (
            <ButtonNew
              text="Pay With Credit Card"
              type="main"
              isFetching={this.props.isPaying}
              onClick={() => this.props.showConfirmation(supplier)}
            />
          );
        else
          return (
            <Link to="/settings/plans" style={{ textDecoration: "none" }}>
              <ButtonNew text="Add Payment Plan" type="main" />
            </Link>
          );
      } else {
        return <StripeCard text="Add Payment Card" />;
      }
    }
  };

  renderPayPalButton() {
    const { supplier, order } = this.props;
    const payment_error = (element) =>
      element.type === "Supplier Payment Account Error";
    const showPayPal =
      supplier.merchant_id && !supplier.errors.some(payment_error);
    if (showPayPal && this.storeHasPlan === true) {
      return (
        <PayPalBtn
          shipToAddress={order.shipping_address}
          orderID={order.id}
          supplierID={supplier.id}
          merchantID={supplier.merchant_id}
          charge={supplier.total.original_amount}
          currency={supplier.currency}
        />
      );
    }
  }
}

export default Payment;
