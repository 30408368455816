export function transformSuppliers(suppliers) {
  return suppliers.map(
    ({
      shopify_url,
      shopify_token,
      manufacturing_time,
      orders,
      ...supplierRest
    }) => ({
      shopifyUrl: shopify_url,
      shopifyToken: shopify_token,
      manufacturingTime: manufacturing_time,
      orders: orders.map(
        ({
          supplier_id,
          created_at,
          shopify_id,
          admin_notes,
          store_id,
          line_items,
          shopify_failed,
          ...orderRest
        }) => ({
          supplierId: supplier_id,
          createdAt: created_at,
          shopifyId: shopify_id,
          adminNotes: admin_notes,
          storeId: store_id,
          lineItems: line_items,
          shopifyFailed: shopify_failed,
          ...orderRest,
        })
      ),
      ...supplierRest,
    })
  );
}
