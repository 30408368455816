import React, { Component } from "react";
import { Switch, Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import HttpsRedirect from "react-https-redirect";
import Internal from "./Internal";
import Supplier from "./Supplier";
import Public from "./Public";
import Admin from "./views/admin/Admin";
import AdminLogin from "./views/admin/AdminLogin";
import AffiliateDashboard from "./views/affiliate/AffiliateDashboard";
import AffiliateLogin from "./views/affiliate/AffiliateLogin";
import { ChooseAccount } from "./views/account";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { getConfirmationData } from "./Selectors";
import { Show } from "./components/Show";
import ConfirmationModal from "./components/ConfirmationModal";
import { connect } from "react-redux";

// base app history
export const history = createBrowserHistory();

class BaseApp extends Component {
  render() {
    const { confirmationData } = this.props;

    return (
      <Router history={history}>
        <ErrorBoundary notFoundImage="https://res.cloudinary.com/dropcommerce/image/upload/h_350/v1675367784/kkqkjixqfz3g2ibjeihk.svg">
          <ReactNotification />

          <Show when={confirmationData}>
            <ConfirmationModal data={confirmationData} />
          </Show>

          <HttpsRedirect>
            <Switch>
              {/* ADMIN */}
              <Route exact path="/admin/login" component={AdminLogin} />
              <Route path="/admin/:tab" component={Admin} />
              <Route exact path="/admin" component={Admin} />

              {/* AFFILIATES */}
              <Route exact path="/affiliate/login" component={AffiliateLogin} />
              <Route
                exact
                path="/affiliate/dashboard"
                component={AffiliateDashboard}
              />

              <Route path="/accounts" component={ChooseAccount} />

              {/* STORE */}
              <Route path="/dashboard" component={Internal} />
              <Route path="/unsynced" component={Internal} />
              <Route path="/prebuilt/buy" component={Internal} />
              <Route path="/prebuilt" component={Internal} />
              <Route path="/inbox/:conversationLabel" component={Internal} />
              <Route path="/inbox" component={Internal} />
              <Route path={`/suppliers`} component={Internal} />
              <Route exact path={`/supplier-id/:id`} component={Internal} />
              <Route path={`/products`} component={Internal} />
              <Route path={`/product/:productId`} component={Internal} />
              <Route
                path="/dcfulfillment/order/:orderId"
                component={Internal}
              />
              <Route path="/dcfulfillment" component={Internal} />
              <Route path="/notifications" component={Internal} />
              <Route path="/samples" component={Internal} />
              <Route path="/imported" component={Internal} />
              <Route exact path="/setup" component={Internal} />
              <Route path="/settings/:tab/:type" component={Internal} />
              <Route exact path="/settings/:tab" component={Internal} />
              <Route exact path="/settings" component={Internal} />
              <Route exact path="/order/new" component={Internal} />
              <Route exact path="/order/:orderId" component={Internal} />
              <Route exact path="/orders" component={Internal} />

              {/* SUPPLIER */}
              <Route path="/supplier" component={Supplier} />

              {/* PUBLIC */}
              <Route exact path="/login" component={Public} />
              <Route exact path="/signup" component={Public} />
              <Route exact path="/apply/supplier" component={Public} />
              <Route exact path="/suppliers" component={Public} />
              <Route exact path="/contact" component={Public} />
              <Route exact path="/privacy" component={Public} />
              <Route exact path="/terms" component={Public} />
              <Route exact path="/bigcommerce" component={Public} />
              <Route exact path="/wix" component={Public} />

              {/* TRACKING PAGES */}
              <Route exact path="/dfs" component={Public} />
              <Route exact path="/dodropshipping" component={Public} />
              <Route exact path="/dodropshippingpricing" component={Public} />
              <Route exact path="/ecom-era" component={Public} />
              <Route exact path="/youtube" component={Public} />
              <Route exact path="/facebook" component={Public} />
              <Route exact path="/tiktok" component={Public} />
              <Route exact path="/twitter" component={Public} />
              <Route exact path="/podcast" component={Public} />
              <Route exact path="/instagram" component={Public} />
              <Route exact path="/getprebuilt" component={Public} />
              <Route exact path="/forgot" component={Public} />
              <Route path="/" component={Public} />
            </Switch>
          </HttpsRedirect>
        </ErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmationData: getConfirmationData(state),
});

export default connect(mapStateToProps)(BaseApp);
