import React, { useState } from "react";
import Cookies from "js-cookie";
import styled from "styled-components";

import { ButtonNew, Column } from "../../components";
import { theme } from "../../modules/Theme";
import { api } from "../../modules/Config";
import PrebuiltDetailSelection from "./PackageDetailSelection";
import { PaymentConfirmation, TermsAndConditions } from "./checkout";
import Text from "../../modules/Text";

// region Style
const InnerContainer = styled.div`
  width: 600px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ConfirmPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

const ConfirmTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

// const Return = styled.div`
//   cursor: pointer;
//   font-size: 18px;
//   font-weight: 400;
// `;

const Errors = styled.div`
  padding: 20px;
  background: white;
  margin-top: 20px;
  margin-bottom: 20px;
`;

// endregion Style

const BuyPrebuilt = (props) => {
  const [error, setError] = useState("");
  const [stage, setStage] = useState("started"); // ["started", "buying", "purchased"]
  const [packageDetails, setPackageDetails] = useState({
    useCurrentStore: true,
    storeEmail: "",
    storeName: "",
    storeURL: Cookies.get("currentPlatform"),
    extraPrebuiltMonths: 3,
  });

  const basePrice = props.storeData.price || 249.0;
  const priceByMonth = {
    3: basePrice,
    6: basePrice + 130,
    12: basePrice + 250,
  };

  const buyHandler = () => {
    setStage("buying");

    const payload = {
      email: packageDetails.storeEmail,
      name: packageDetails.storeName,
      existing: packageDetails.useCurrentStore,
      url: packageDetails.storeURL,
      type: `${props.storeData.type}`,
      extra_months: packageDetails.extraPrebuiltMonths,
      categories: props.customCategories,
      style_pack: props.customStylePack,
    };

    fetch(`${api}/prebuilt/purchases/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${Cookies.get("accessToken")}`,
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        const r = await response.json();
        setStage("purchased");
        props.purchasedSet && props.purchasedSet(true);
        props.refreshStoreDesigns && props.refreshStoreDesigns();

        if (r.redirect) {
          window.location.href = r.redirect;
        }
      })
      .catch((err) =>
        setError(
          "Error buying this Store. Please contact support at support@dropcommerce.com"
        )
      );
  };

  return (
    <InnerContainer>
      <Column>
        <ConfirmPrice>
          {stage === "purchased" ? "Payment Successful!" : "Complete Purchase:"}
        </ConfirmPrice>
        <ConfirmTitle>{`Prebuilt ${props.storeData.name} Store`}</ConfirmTitle>
      </Column>
      {stage !== "purchased" && (
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <PrebuiltDetailSelection
            setPackageDetails={setPackageDetails}
            packageDetails={packageDetails}
            priceByMonth={priceByMonth}
          />
        </div>
      )}

      <ButtonNew
        disabled={stage !== "started"}
        isFetching={stage === "buying"}
        size="large"
        text={
          stage === "purchased" || props.storeData.purchased
            ? "Purchased"
            : `Buy Now - $${
                priceByMonth[packageDetails.extraPrebuiltMonths + 3]
              }`
        }
        onClick={buyHandler}
      />
      {error.length > 0 && (
        <Errors>
          <Text extra="margin-bottom: 10px; color: red;">{error}</Text>
        </Errors>
      )}
      {stage === "purchased" ? <PaymentConfirmation /> : <TermsAndConditions />}
    </InnerContainer>
  );
};

export default BuyPrebuilt;
