import { isEmpty } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

import { getUrlSubdomain } from "../modules/Format";
import {
  errorNotification,
  successNotification,
} from "../modules/Notification";
import { axiosInstance } from "../services/api";
import { validEmail } from "../validation";
import Account, {
  AccountButton,
  AccountInput,
  AccountLink,
  Form,
} from "../views/account/components/AccountComponents";

export function Forgot() {
  // hooks
  const history = useHistory();

  const [email, emailSet] = React.useState("");
  const [success, successSet] = React.useState(false);
  const [error, errorSet] = React.useState("");
  const [isFetching, isFetchingSet] = React.useState(false);

  const subdomain = getUrlSubdomain();

  const isValid = () => {
    let error = "";
    if (!validEmail(email)) {
      error = "Please enter a valid email";
    }
    errorSet(error);
    return isEmpty(error);
  };

  const sendEmail = () => {
    if (!isValid()) return;
    isFetchingSet(true);
    axiosInstance
      .post("/forgot_password/", { email: email })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          successSet(true);
          successNotification("Password reset email sent successfully.");
        }
      })
      .catch((error) => {
        errorNotification(
          "Password reset email failed. Invalid email. Please contact support@dropcommerce.com"
        );
      })
      .finally(() => {
        isFetchingSet(false);
      });
  };

  const navigateToLogin = () => history.push("/login");

  const onChange = (evt) => emailSet(evt.target.value);

  const onKeyPress = (evt) => {
    if (evt.key !== "Enter") return;
    sendEmail();
  };

  if (success) {
    return (
      <Account
        sideImage
        title="Email Sent"
        subtitle="Please check your email for a password reset link!"
        subdomain={subdomain}
      >
        <AccountButton text="Return To Login" onClick={navigateToLogin} />
      </Account>
    );
  }

  return (
    <Account
      sideImage
      title="Forgot Password"
      subtitle="Please enter the email associated with your account."
      subdomain={subdomain}
      isFetching={isFetching}
    >
      <Form>
        <AccountInput
          value={email}
          maxWidth="100%"
          placeholder="Your email"
          onChange={onChange}
          onKeyPress={onKeyPress}
          error={error}
        />
        <AccountButton text="Send Login Email" onClick={sendEmail} />
        <AccountLink
          margin="20px 0 0 0"
          normalText="I remembered by password."
          linkText="Login"
          onClick={navigateToLogin}
        />
      </Form>
    </Account>
  );
}
