import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { getRequest } from "../modules/API";
import BuyCustom from "./BuyCustom";
import PrebuiltList from "./PrebuiltList";
import Dashboard from "./Dashboard";
import MyStores from "./MyStores";
import Wrapper from "./Wrapper";
import Cookies from "js-cookie";
import { Checkout } from "./components/dashboard";
import { Modal } from "../components";

const PrebuiltMain = () => {
  const [storeDesigns, setStoreDesigns] = useState([]);

  const [selectedStoreDesignType, setSelectedStoreDesignType] = useState(null);

  const refreshStoreDesigns = () => {
    getRequest({ url: `prebuilt/designs/` })
      .then((response) => setStoreDesigns(response.data))
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove("accessToken");
        }
      });
  };

  useEffect(() => {
    refreshStoreDesigns();
  }, []);

  const selectStoreDesignTypeHandler = (type) =>
    setSelectedStoreDesignType(type);

  return (
    <Wrapper>
      {selectedStoreDesignType && (
        <Modal
          fullScreen
          maxWidth="900px"
          hide={() => setSelectedStoreDesignType(null)}
        >
          <Checkout
            stores={storeDesigns}
            storeType={selectedStoreDesignType}
            updateTheme={setSelectedStoreDesignType}
            refreshStoreDesigns={refreshStoreDesigns}
          />
        </Modal>
      )}
      <Switch>
        <Route
          path="/dashboard"
          render={() => (
            <Dashboard
              selectStoreDesignTypeHandler={selectStoreDesignTypeHandler}
              storeDesigns={storeDesigns}
              refreshStoreDesigns={refreshStoreDesigns}
            />
          )}
        />
        <Route
          path="/starter-stores"
          render={() => (
            <PrebuiltList
              selectStoreDesignTypeHandler={selectStoreDesignTypeHandler}
              level="STARTER"
              storeDesigns={storeDesigns}
            />
          )}
        />
        <Route
          path="/premium-stores"
          render={() => (
            <PrebuiltList
              selectStoreDesignTypeHandler={selectStoreDesignTypeHandler}
              level="PREMIUM"
              storeDesigns={storeDesigns}
            />
          )}
        />
        <Route path="/my-stores" component={MyStores} />
        <Route path="/custom-stores" component={BuyCustom} />
      </Switch>
    </Wrapper>
  );
};

export default withRouter(PrebuiltMain);
