import React, { Component } from "react";
import styled from "styled-components";
import { BsFillStarFill } from "react-icons/bs";
import { Row } from "./Layout";
import { theme } from "../modules/Theme";

export const StarWrapper = styled.div``;

export const Star = styled.label`
  cursor: pointer;
  transition: 1s;
`;

export const StarContainer = styled.div`
  margin-right: 3px;
`;

export const StarButton = styled.input`
  display: none;
`;

export const RatingPrompt = styled.div`
  display: flex;
  align-items: center;
`;

export const PromptMessage = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  text-align: left;
  margin-bottom: 5px;
  @media (max-width: 1400px) {
    font-size: 12px;
  }
`;

class Stars extends Component {
  state = { hover: null };

  drawStars = () =>
    [...Array(5)].map((star, i) => {
      let currentRating = i + 1;
      return (
        <Star key={i}>
          <StarButton
            type="radio"
            value={currentRating}
            onClick={() => {
              this.props.rating === 1 && currentRating === 1
                ? this.props.updateRating(0)
                : this.props.updateRating(currentRating);
            }}
          />
          <BsFillStarFill
            size={this.props.size || "26"}
            color={
              currentRating <= (this.state.hover || this.props.rating)
                ? theme.colors.yellow
                : theme.colors.medLightGrey
            }
            onMouseEnter={() => this.setState({ hover: currentRating })}
            onMouseLeave={() => this.setState({ hover: null })}
          />
        </Star>
      );
    });

  displayRating = (rating) =>
    [...Array(this.props.amount || 5)].map((star, i) => {
      let currentRating = i + 1;
      return (
        <Star style={{ cursor: "default" }} key={i}>
          <BsFillStarFill
            size={this.props.size || "26"}
            color={
              currentRating <= rating
                ? theme.colors.yellow
                : theme.colors.medLightGrey
            }
          />
        </Star>
      );
    });

  render() {
    const { type, rating } = this.props;
    if (type === "write") {
      return (
        <StarWrapper>
          <RatingPrompt>
            <StarContainer>{this.drawStars()}</StarContainer>
            {this.state.hover || this.props.rating > 0 ? (
              ""
            ) : (
              <PromptMessage>Select your rating</PromptMessage>
            )}
          </RatingPrompt>
        </StarWrapper>
      );
    }

    if (type === "display") {
      return (
        <Row>
          <StarWrapper>{this.displayRating(rating)}</StarWrapper>
        </Row>
      );
    }
  }
}

export default Stars;
