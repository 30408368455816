import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "../../modules/Theme";

import { createStoreReview } from "../../Actions";
import { fetchDashboardData } from "../../views/products/ProductsActions";
import { getSettings } from "../../Selectors";
import {
  getProducts,
  getIsFetchingDashboard,
  getDashboardData,
} from "../../views/products/ProductsSelectors";
import { get, isEmpty } from "lodash";

import DashboardCard, {
  ProductsCard,
  SuppliersCard,
  ReviewCard,
  PrebuiltCard,
} from "./components/DashboardCard";

import Cookies from "js-cookie";
import { getLoginTypeFromSubdomain } from "../../modules/Format";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingDashboard(state),
  products: getProducts(state),
  dashboardData: getDashboardData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchDashboardData, createStoreReview }, dispatch),
});

// const WebinarCard = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 20px;
//   width: fit-content;
//   border-radius: 5px;
//   border: 1.5px solid rgba(0, 0, 0, 0.075);
//   margin: 16px 0px 16px 0px;
//   height: 64px;
//   box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
//     rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

//   &:hover {
//     cursor: pointer;
//     box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
//       rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
//   }
// `;

const PageWrapper = styled.div`
  background: ${theme.colors.lightGrey};
  padding: 20px;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const TopCategoriesLink = styled.div`
  font-size: 18px;
  color: ${theme.colors.main};
  font-weight: 400;
  margin-bottom: 20px;
  display: inline-block;
  width: 260px;
  cursor: pointer;
`;

// const tabModalData = {
//   preloaded_with_products: {
//     name: "Preloaded With Products",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/products.svg",
//     description: [
//       "Each store comes preloaded with 100+ high quality products from fast-shipping US suppliers in the niche of your choice.",
//     ],
//   },
//   professional_design: {
//     name: "15 Professional Designs",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/wireframe.svg",
//     description: [
//       "Pick one of our 15 professional niche store designs and instantly boost your chance of ecommerce success.",
//     ],
//   },
//   free_pro_months: {
//     name: "3 Free Pro Plan Months",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_wallet_aym5.svg",
//     description: [
//       "Each prebuilt store comes with 3 free months of the DropCommerce Professional plan.",
//       "6 and 12 month packages are available for an additional cost.",
//     ],
//   },
//   jumpstart_store: {
//     name: "Save Time & Money",
//     image: "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/time.svg",
//     description: [
//       "Save hundreds of hours and weeks or months of time, and thousands of dollars designing and setting up an ecommerce store.",
//     ],
//   },
// };

class Dashboard extends Component {
  state = { showTutorialVideos: true, prebuiltPopupDismissed: false };

  componentDidMount() {
    this.props.fetchDashboardData();
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  render() {
    const loginTypes = getLoginTypeFromSubdomain();
    const isGlobal = loginTypes[0];

    const featuredSuppliers = get(this.props, [
      "dashboardData",
      "featured_suppliers",
    ]);
    const newSuppliers = get(this.props, ["dashboardData", "new_suppliers"]);
    const categories = get(this.props, ["dashboardData", "categories"]);
    const products = get(this.props, ["products"]);
    const recommendations = get(this.props, [
      "dashboardData",
      "recommendations",
    ]);
    const favouriteSuppliers = get(this.props, [
      "dashboardData",
      "favourite_suppliers",
    ]);

    // const prebuiltPopupDismissed = Cookies.get("prebuiltPopupDismissed");

    const storeSettings = get(this.props, ["settings", "store"]);

    const importCount = get(storeSettings, "imported_to_shopify_count");
    const appStoreReview = get(storeSettings, "app_store_review");
    const hidePrebuilt = get(storeSettings, "hide_prebuilt");
    const storeID = get(storeSettings, ["id"]);

    const isShopify =
      get(this.props, ["settings", "store", "platform", "name"]) === "SHOPIFY";
    const storePlatform = get(this.props, [
      "settings",
      "store",
      "platform",
      "name",
    ]);

    return (
      <PageWrapper>
        {/* {!isEmpty(storeSettings) &&
          !hidePrebuilt &&
          isShopify &&
          !this.state.prebuiltPopupDismissed &&
          !prebuiltPopupDismissed && (
            <TabModal
              hide={() => {
                this.setState({ prebuiltPopupDismissed: true });
                Cookies.set("prebuiltPopupDismissed", true, { expires: 14 });
              }}
              data={tabModalData}
              title="Prebuilt Shopify Stores Available"
              buttonText="Browse Stores"
              buttonOnClick={() => this.props.history.push("/prebuilt")}
            />
          )} */}

        <TopCategoriesLink onClick={() => this.props.history.push("/setup")}>
          Customize Top Categories
        </TopCategoriesLink>

        {importCount > 0 &&
          !(appStoreReview && appStoreReview.rating === 5) &&
          !Cookies.get("reviewSaved") && (
            <ReviewCard
              platform={storePlatform}
              createStoreReview={this.props.createStoreReview}
            />
          )}

        {/* <a
          style={{ textDecoration: "none", width: "fit-content" }}
          href="https://zoom.us/webinar/register/6016194644264/WN_VREcK5QHSeGYQU5it_j4TQ"
        >
          <WebinarCard>
            <img src={logo} width="25" height="25"></img>
            &nbsp; &nbsp;Click here to register for the weekly marketing
            strategy webinar
          </WebinarCard>
        </a> */}

        {!isEmpty(storeSettings) &&
          !hidePrebuilt &&
          isShopify &&
          !Cookies.get("dismissPrebuilt") &&
          !this.state.dismissedPrebuilt && (
            <DashboardCard
              title="$249.00 Shopify Starter Stores"
              hide={() => {
                Cookies.set("dismissPrebuilt", true, { expires: 30 });
                this.setState({ dismissedPrebuilt: true });
              }}
            >
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=general_store")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/general-store-card_dayj4p.jpg"
              />
              <PrebuiltCard
                onClick={() => this.props.history.push("/prebuilt?type=pets")}
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/pets-card_cs4xzv.jpg"
              />
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=home_decor")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818108/Prebuilt%20Cards/dwell_xye2a7.jpg"
              />
              <PrebuiltCard
                onClick={() =>
                  this.props.history.push("/prebuilt?type=wellness")
                }
                background="https://res.cloudinary.com/dropcommerce/image/upload/v1602818109/Prebuilt%20Cards/well-card_bvdhqo.jpg"
              />
            </DashboardCard>
          )}

        {/* {!Cookies.get("dismissTutorialVideos") &&
          this.state.showTutorialVideos && (
            <DashboardCard
              title="Tutorial Videos"
              hideMobile
              hide={() => {
                Cookies.set("dismissTutorialVideos", true, { expires: 60 });
                this.setState({ showTutorialVideos: false });
              }}
            >
              <VideoCard
                title="Quick DropCommerce App Overview"
                background="https://dc-imports.s3.ca-central-1.amazonaws.com/hero-3-video.jpg"
                vimeoId="462449256"
              />
              <VideoCard
                title="Adding Products To Your Import List"
                background="https://dc-imports.s3.ca-central-1.amazonaws.com/hero-2-video.jpg"
                vimeoId="461044366"
              />
              <VideoCard
                title="Importing Products To Your Store"
                background="https://dc-imports.s3.ca-central-1.amazonaws.com/hero-1-video.jpg"
                vimeoId="461040145"
              />
            </DashboardCard>
          )} */}

        {this.props.isFetching > 0 && (
          <React.Fragment>
            <DashboardCard.Skeleton />
            <DashboardCard.Skeleton />
          </React.Fragment>
        )}

        {!isGlobal && favouriteSuppliers && (
          <SuppliersCard
            store={storeID}
            title="Your Favourite Suppliers"
            suppliers={favouriteSuppliers}
            viewAll={() => this.props.history.push("/favorite-suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}

        {!isGlobal && featuredSuppliers && (
          <SuppliersCard
            store={storeID}
            title="Featured Suppliers"
            suppliers={featuredSuppliers}
            viewAll={() => this.props.history.push("/suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}

        {!isGlobal && newSuppliers && (
          <SuppliersCard
            store={storeID}
            title="New Suppliers"
            suppliers={newSuppliers}
            viewAll={() => this.props.history.push("/suppliers")}
            onClick={(supplierId) =>
              this.props.history.push(`/supplier-id/${supplierId}`)
            }
          />
        )}

        {recommendations && (
          <ProductsCard
            title="Products you may be interested in"
            products={recommendations.filter((p) => p.category)}
            viewAll={() =>
              this.props.history.push(`/products?recommendations=true`)
            }
            onClick={(productId) =>
              this.props.history.push(`/product/${productId}`)
            }
          />
        )}

        {categories &&
          categories.map((category, i) => (
            <ProductsCard
              key={i}
              title={`Top Selling In ${category.name}`}
              products={
                products && products.filter((p) => p.category === category.key)
              }
              viewAll={() =>
                this.props.history.push(`/product/?category=${category.key}`)
              }
              onClick={(productId) =>
                this.props.history.push(`/product/${productId}`)
              }
              showApprovalRequirements={this.props.showApprovalRequirements}
            />
          ))}
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
