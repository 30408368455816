import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import {
  Row,
  ButtonNew,
  Container,
  Spinner,
  Image,
  BorderBox,
} from "../../components";
import Text from "../../modules/Text";
import ManualOrderSupplier from "./components/ManualOrderSupplier";
import createNotification from "../../modules/Notification";

import { postRequest, getRequest, deleteRequest } from "../../modules/API";
import Select from "react-select";
import { resizeImage, getUrlSubdomain } from "../../modules/Format";

import { Modal } from "../../components/Modal";

const SelectVariant = styled.div`
  font-size: 20px;
  font-weight: 400;
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
  color: ${(p) => (p.added ? theme.colors.medGrey : theme.colors.medDarkGrey)};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
`;

const DropdownProduct = styled.div`
  border-bottom: 1px solid ${theme.colors.lightBorder};
  display: flex;
  padding: 10px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
`;

const CustomSearch = styled.input`
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
  width: 100%;
  font-size: 16px;
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
  &::placeholder {
    color: ${theme.colors.medGrey};
  }
`;

// function debounce(func, wait, immediate) {
//   let timeout;
//   return function () {
//     const context = this,
//       args = arguments;
//     const later = function () {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     const callNow = immediate && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// }

class ManualOrder extends Component {
  state = {
    selectedAddress: null,
    showAddressModal: false,
    savedAddresses: [],
    shippingAddress: null,
    searchProducts: [],
    selectedProductVariants: null,
    isFetching: false,
    isSearchingProducts: false,
    search: "",
    confirm: false,
    suppliers: [],
  };

  updateQuantity = (supplierId, productId, quantity) => {
    this.setState({
      changes: true,
      suppliers: this.state.suppliers.map((supplier) => {
        if (supplierId === supplier.id) {
          return {
            ...supplier,
            products: supplier.products.map((product) => {
              if (product.id === productId) {
                return { ...product, quantity };
              }
              return product;
            }),
          };
        }
        return supplier;
      }),
    });
  };

  componentDidMount() {
    let addresses = null;
    this.setState({ isFetching: true }, () => {
      getRequest({
        url: "api/addresses/",
      }).then((response) => {
        addresses = response.data;
        getRequest({
          url: "api/cart-items/",
        }).then((response) =>
          this.setState({
            savedAddresses: addresses,
            suppliers: response.data,
            isFetching: false,
          })
        );
      });
    });
  }

  createOrder = () => {
    this.setState({ isCreatingOrder: true });
    postRequest({
      url: "cart-items/checkout/",
      data: {
        ...this.state.confirm,
        shipping_address_id: this.state.selectedAddress.id,
      },
    })
      .then((response) => {
        if (response.data.order_id) {
          createNotification({
            type: "success",
            title: "Success",
            message: "Manual Order Created",
          });
          this.props.history.push(`/order/${response.data.order_id}`);
        } else {
          this.setState({ isCreatingOrder: false });
        }
      })
      .catch((error) => {
        this.setState({ isCreatingOrder: false });
      });
  };

  selectAddress = (addressId) => {
    let selectedAddress = null;
    this.state.savedAddresses.forEach((address) => {
      if (address.id === addressId) {
        selectedAddress = address;
      }
    });

    if (selectedAddress) {
      this.setState({ selectedAddress });
    }
  };

  selectProduct = (productId) => {
    this.setState({ isSearchingProducts: true });
    getRequest({
      url: `api/products/${productId}/`,
    }).then((response) => {
      this.setState({
        search: "",
        isSearchingProducts: false,
        selectedProductVariants: response.data,
      });
    });
  };

  saveCartItems = () => {
    let cartItems = [];

    this.setState({ isSavingCartItems: true });

    this.state.suppliers.forEach((supplier) => {
      supplier.products.forEach((product) => {
        cartItems.push({
          id: product.id,
          quantity: product.quantity,
          variant_id: product.variant_id,
        });
      });
    });

    postRequest({
      url: "api/cart-items/",
      data: { items: cartItems },
    }).then(() => {
      this.setState({ changes: false, isSavingCartItems: false });

      createNotification({
        type: "success",
        title: "Success",
        message: "Changes saved",
      });
    });
  };

  createCartItem = (variantId) => {
    this.setState({ isCreatingCartItem: true });

    postRequest({
      url: "api/cart-items/",
      data: { items: [{ quantity: 1, variant_id: variantId }] },
    }).then((response) => {
      const items = response.data.items;
      const supplierId = items[0].supplier_id;

      const supplierExists =
        this.state.suppliers.filter((s) => s.id === supplierId).length > 0;

      if (supplierExists) {
        // Add new cart item to local state
        this.setState({
          isCreatingCartItem: false,
          selectedProductVariants: false,
          suppliers: this.state.suppliers.map((supplier) => {
            if (parseInt(supplierId, 10) === supplier.id) {
              const currentProducts = [...supplier.products];
              const newProducts = currentProducts.concat(items);
              return {
                ...supplier,
                products: newProducts,
              };
            }
            return supplier;
          }),
        });
      } else {
        const newSuppliers = [...this.state.suppliers];
        newSuppliers.unshift({
          id: supplierId,
          name: items[0].supplier,
          products: items,
        });
        this.setState({
          isCreatingCartItem: false,
          selectedProductVariants: false,
          suppliers: newSuppliers,
        });
      }

      createNotification({
        type: "success",
        title: "Success",
        message: "Item Added",
      });
    });
  };

  searchProducts = (searchTerm) => {
    const subdomain = getUrlSubdomain();
    this.setState({
      isSearchingProducts: true,
    });
    getRequest({
      url: `api/products/?subdomain=${subdomain}&search=${searchTerm}`,
    }).then((response) => {
      this.setState({
        searchProducts: response.data,
        isSearchingProducts: false,
      });
    });
  };

  deleteCartItem = (cartItemId, supplierId) => {
    // Mark cart item as fetching
    this.setState({
      suppliers: this.state.suppliers.map((supplier) => {
        if (supplierId === supplier.id) {
          return {
            ...supplier,
            products: supplier.products.map((product) => {
              if (product.id === cartItemId) {
                return { ...product, isFetching: true };
              }
              return product;
            }),
          };
        }
        return supplier;
      }),
    });

    deleteRequest({
      url: "api/cart-items/",
      data: { id: cartItemId },
    }).then(() => {
      createNotification({
        type: "success",
        title: "Success",
        message: "Item Removed",
      });

      // Remove cart item from local state
      this.setState({
        suppliers: this.state.suppliers.map((supplier) => {
          if (supplierId === supplier.id) {
            return {
              ...supplier,
              products: supplier.products.filter(
                (product) => product.id !== cartItemId
              ),
            };
          }
          return supplier;
        }),
      });
    });
  };

  setConfirmState = (type, supplierIds) => {
    this.setState({
      confirm: {
        type: type,
        supplier_ids: supplierIds,
      },
    });
  };

  render() {
    let totalCommitmentQuantity = 0;
    // let unsavedCommitmentCount = 0;

    this.state.suppliers.forEach((supplier) => {
      supplier.products &&
        supplier.products.forEach((product) => {
          totalCommitmentQuantity += product.quantity;
          if (product.changed) {
            // unsavedCommitmentCount += 1;
          }
        });
    });

    // const statesOrProvinces =
    //   this.state.shippingAddress &&
    //   this.state.shippingAddress.country_code === "US"
    //     ? usStates
    //     : this.state.shippingAddress &&
    //       this.state.shippingAddress.country_code === "CA"
    //     ? canadaProvinces
    //     : [];

    // const address = this.state.shippingAddress;
    // const isValidProvinceCountry = ["US", "CA"].includes(
    //   get(address, "country_code")
    // );

    // const hasLength = (value, length) => {
    //   return !value || (value && value.length < length);
    // };

    // const addressFormItems = [
    //   {
    //     type: "input",
    //     label: "First Name",
    //     key: "first_name",
    //     validation: (value) =>
    //       hasLength(value, 2) && "Please enter a valid First Name",
    //   },
    //   {
    //     type: "input",
    //     label: "Last Name",
    //     key: "last_name",
    //     validation: (value) =>
    //       hasLength(value, 2) && "Please enter a valid Last Name",
    //   },
    //   {
    //     type: "input",
    //     label: "Address 1",
    //     key: "address1",
    //     validation: (value) =>
    //       (hasLength(value, 2) || !/\d/.test(value)) &&
    //       "Please enter a valid Address",
    //   },
    //   {
    //     type: "input",
    //     label: "Address 2",
    //     key: "address2",
    //   },
    //   {
    //     type: "select",
    //     label: "Country",
    //     key: "country_code",
    //     validation: (value) => !value && "Please select a valid Country",
    //     selectOptions: countries,
    //     labelMap: countryCodeMap,
    //   },
    //   {
    //     type: "select",
    //     label: "Province",
    //     key: "province_code",
    //     validation: (value) =>
    //       isValidProvinceCountry &&
    //       (!value || !statesOrProvinces.some((item) => item.value == value)) &&
    //       "Please select a valid Province",
    //     displayRequirement: isValidProvinceCountry,
    //     selectOptions: statesOrProvinces,
    //     labelMap: stateCodeMap,
    //   },
    //   {
    //     type: "input",
    //     label: "Province",
    //     key: "province",
    //     displayRequirement: !isValidProvinceCountry,
    //   },
    //   {
    //     type: "input",
    //     label: "City",
    //     key: "city",
    //     validation: (value) =>
    //       hasLength(value, 2) && "Please enter a valid City",
    //   },
    //   {
    //     type: "input",
    //     label: "Zip / Postal Code",
    //     key: "zip",
    //     validation: (value) =>
    //       hasLength(value, 2) && "Please enter a valid Zip / Postal Code",
    //   },
    //   {
    //     type: "input",
    //     label: "Company",
    //     key: "company",
    //   },
    // ];

    let existingVariants = [];
    this.state.suppliers.forEach((supplier) => {
      supplier.products.forEach((product) => {
        existingVariants.push(product.variant_id);
      });
    });

    const allowSampleOrder =
      !this.state.changes &&
      !this.state.confirm &&
      totalCommitmentQuantity > 0 &&
      this.state.selectedAddress;

    const productSuppliers = this.state.suppliers.filter(
      (supplier) => supplier.products && supplier.products.length > 0
    );

    return (
      <>
        {this.state.isFetching ? (
          <Spinner />
        ) : (
          <Container>
            {this.state.selectedProductVariants && (
              <Modal
                isFetching={this.state.isCreatingCartItem}
                maxWidth="700px"
                hide={() => this.setState({ selectedProductVariants: false })}
              >
                <Text margin="0 0 15px 0">Select a product option</Text>
                {this.state.selectedProductVariants && (
                  <div>
                    {this.state.selectedProductVariants.map((variant, i) => {
                      const added = existingVariants.includes(variant.id);

                      return (
                        <SelectVariant
                          key={i}
                          added={added}
                          onClick={() =>
                            !added && this.createCartItem(variant.id)
                          }
                        >
                          {`${variant.name}${added ? " - Added To Order" : ""}`}
                        </SelectVariant>
                      );
                    })}
                  </div>
                )}
              </Modal>
            )}

            <div style={{ width: "350px" }}>
              <Text.Large extra="margin-bottom: 30px;">
                Create A Manual Order
              </Text.Large>

              <Text
                extra="margin-bottom: 5px;"
                red={!this.state.selectedAddress}
              >
                Select Shipping Address
              </Text>
              <Select
                options={this.state.savedAddresses.map((address) => {
                  return { value: address.id, label: address.address1 };
                })}
                value={
                  this.state.selectedAddress
                    ? {
                        value: this.state.selectedAddress.id,
                        label: this.state.selectedAddress.address1,
                      }
                    : null
                }
                onChange={(data) => this.selectAddress(data.value)}
              />

              <NavLink to="/settings/addresses/new">
                Or Enter A New Address
              </NavLink>

              <Text margin="30px 0 5px 0">Add Products</Text>
              <CustomSearch
                placeholder="Type to filter products by name"
                value={this.state.search}
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                  this.searchProducts(e.target.value);
                }}
              />
            </div>

            {this.state.search !== "" &&
              (this.state.isSearchingProducts ||
                this.state.searchProducts.length > 0) && (
                <BorderBox padding="0px" width="400px">
                  {this.state.isSearchingProducts && <Spinner />}
                  {!this.state.isSearchingProducts &&
                    this.state.searchProducts.length > 0 &&
                    this.state.searchProducts.map((product) => (
                      <DropdownProduct
                        key={product.id}
                        onClick={() => this.selectProduct(product.id)}
                      >
                        {product.image && (
                          <Image src={resizeImage(product.image, 50)} />
                        )}
                        <Text>{product.title}</Text>
                      </DropdownProduct>
                    ))}
                </BorderBox>
              )}

            {productSuppliers.map((supplier, i) => {
              return (
                <ManualOrderSupplier
                  key={i}
                  supplier={supplier}
                  updateQuantity={this.updateQuantity}
                  deleteCartItem={this.deleteCartItem}
                />
              );
            })}

            <Row style={{ marginTop: "30px" }}>
              <ButtonNew
                text="Create Sample Order With All Items"
                type="main"
                disabled={!allowSampleOrder}
                onClick={() => {
                  this.setConfirmState(
                    "SAMPLE",
                    productSuppliers.map((s) => s.id)
                  );
                }}
              />

              <ButtonNew
                text="Save Changes"
                type="main"
                disabled={!this.state.changes}
                isFetching={this.state.isSavingCartItems}
                margin="0 0 0 15px"
                onClick={this.saveCartItems}
              />
            </Row>

            {this.state.confirm && (
              <Modal
                maxWidth="600px"
                hide={() => this.setState({ confirm: null })}
              >
                <Text>Confirm Sample Order?</Text>
                <Text.Small light margin="10px 0 25px 0">
                  You will confirm payment at the next step, but quantities and
                  products will not be editable once you confirm.
                </Text.Small>
                <Row align style={{ marginTop: "30px" }}>
                  <ButtonNew
                    text="Cancel"
                    type="secondary"
                    margin="0 15px 0 0"
                    fontStyle="font-size: 18px;"
                    onClick={() => this.setState({ confirm: null })}
                  />
                  <ButtonNew
                    text="Confirm & Create"
                    type="main"
                    fontStyle="font-size: 18px;"
                    onClick={this.createOrder}
                    isFetching={this.state.isCreatingOrder}
                  />
                </Row>
              </Modal>
            )}
          </Container>
        )}
      </>
    );
  }
}

export default withRouter(ManualOrder);

const NavLink = styled(Link)`
  line-height: 40px;
  font-weight: 500;
  text-decoration: none;
  color: ${theme.colors.mainDark};
`;
