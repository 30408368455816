import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { FiChevronUp, FiChevronDown, FiX } from "react-icons/fi";

const ClearWrapper = styled.div`
  padding: 5px;
  border-radius: 50px;
  height: 28px;
  width: 28px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const DropdownError = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #c12119;
  width: 100%;
  text-align: left;
  margin-top: 5px;
`;

const Dropdown = styled.div`
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin};
  position: relative;
  @media screen and (max-width: 800px) {
    width: calc(100% / 2);
    @media screen and (max-width: 500px) {
      ${(props) => props.mobileMargin && "margin:" + props.mobileMargin}
      margin-top: 16px;
    }
  }
`;

export const DropdownHeader = styled.div`
  padding: 6px 15px 6px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  background: ${(p) => (p.active ? "#333333" : "#f5f5f5")};
  &:hover {
    background: ${(p) => (p.active ? "#000000" : "#eaeaea")};
  }
`;

const OptionsWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  right: 0;
  z-index: 1000;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.17);
  margin: 6px 0;

  @media screen and (max-width: 500px) {
    left: auto;
    right: auto;
  }
`;

const OptionsWrapperModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding: 40px;
  justify-content: center;
`;

const OptionsWrapperModal = styled.div`
  max-height: 100%;
  background: white;
  border: 1px solid ${theme.colors.lightBorder};
  position: relative;
  width: 700px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px !important;
`;

const TitleRow = styled.div`
  display: flex;
  width: 100%;
`;

export const DropdownTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: ${(p) => (p.active ? "white" : "#333333")};
  margin-right: 5px;
`;

const CurrentSelectionTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: ${(p) => (p.active ? "white" : "#333333")};
`;

class SelectDropdown extends Component {
  render() {
    const { name, show, active, displayText } = this.props;

    return (
      <Dropdown
        mobileMargin="0px 8px 0px 0px"
        width={this.props.width}
        margin={this.props.margin}
        extra={this.props.extra}
      >
        <Header
          active={active || show}
          reset={this.props.reset}
          clearable={active}
          error={this.props.error}
          showHideFilter={this.props.showHideFilter}
          name={name}
          displayText={displayText}
        />

        {this.props.error && <DropdownError>{this.props.error}</DropdownError>}

        {show && (
          <React.Fragment>
            {this.props.full ? (
              <OptionsWrapperModalBackground>
                <OptionsWrapperModal>{this.props.children}</OptionsWrapperModal>
              </OptionsWrapperModalBackground>
            ) : (
              <OptionsWrapper>{this.props.children}</OptionsWrapper>
            )}
          </React.Fragment>
        )}
      </Dropdown>
    );
  }
}

const Header = ({
  active,
  clearable,
  reset,
  error,
  showHideFilter,
  name,
  displayText,
}) => (
  <DropdownHeader active={active} error={error} onClick={showHideFilter}>
    <TitleRow>
      {name && <DropdownTitle active={active}>{name}</DropdownTitle>}
      <CurrentSelectionTitle active={active}>
        {displayText}
      </CurrentSelectionTitle>
    </TitleRow>

    {clearable ? (
      <ClearWrapper
        onClick={(e) => {
          e.stopPropagation();
          reset();
        }}
      >
        <FiX size="18" color={theme.colors.white} />
      </ClearWrapper>
    ) : (
      <React.Fragment>
        {active ? (
          <FiChevronUp size="20" color={theme.colors.white} />
        ) : (
          <FiChevronDown
            size="20"
            color={active ? theme.colors.white : theme.colors.medDarkGrey}
          />
        )}
      </React.Fragment>
    )}
  </DropdownHeader>
);

export default SelectDropdown;
