import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchSupplierManagement,
  manageSupplier,
} from "../../../views/admin/AdminActions";
import {
  getIsFetching,
  getSupplierManagement,
} from "../../../views/admin/AdminSelectors";
import Supplier from "../../../views/admin/components/Supplier";
import { getUrlParameter } from "../../../modules/Format";
import { Row, Spinner } from "../../../components";
import Text from "../../../modules/Text";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  suppliers: getSupplierManagement(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSupplierManagement, manageSupplier }, dispatch),
});

const defaultProps = {
  isFetching: false,
};

class SupplierManagement extends Component {
  UNSAFE_componentWillMount = () => {
    const sorting =
      getUrlParameter("sorting", this.props.location) || "default";
    const status = getUrlParameter("status", this.props.location) || "inactive";
    this.props.fetchSupplierManagement(sorting, status);
  };

  switchSorting = (sorting) => {
    const status = getUrlParameter("status", this.props.location) || "inactive";
    this.props.history.push(
      `/admin/suppliers?sorting=${sorting}&status=${status}`
    );
    this.props.fetchSupplierManagement(sorting, status);
  };

  switchStatus = (status) => {
    const sorting =
      getUrlParameter("sorting", this.props.location) || "default";
    this.props.history.push(
      `/admin/suppliers?sorting=${sorting}&status=${status}`
    );
    this.props.fetchSupplierManagement(sorting, status);
  };

  render() {
    const { suppliers } = this.props;
    const sorting =
      getUrlParameter("sorting", this.props.location) || "default";
    const status = getUrlParameter("status", this.props.location) || "inactive";

    return (
      <PageWrapper>
        <Row style={{ marginBottom: "15px" }}>
          <Row style={{ marginRight: "100px" }}>
            <Text bold extra="margin-right: 15px;">
              {status === "active" ? "Active" : "Inactive"}
            </Text>
            <Text
              light
              link
              onClick={() =>
                this.switchStatus(status === "active" ? "inactive" : "active")
              }
            >
              {status === "inactive" ? "Active" : "Inactive"}
            </Text>
          </Row>
          <Row>
            <Text bold extra="margin-right: 15px;">
              {sorting === "issues"
                ? "Sorting by issue count"
                : "Sorting by newest"}
            </Text>
            <Text
              light
              link
              onClick={() =>
                this.switchSorting(sorting === "issues" ? "default" : "issues")
              }
            >
              {sorting === "issues" ? "Sort by newest" : "Sort by issue count"}
            </Text>
          </Row>
        </Row>

        {this.props.isFetching > 0 ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {suppliers.map((supplier, i) => (
              <Supplier
                key={i}
                supplier={supplier}
                manageSupplier={this.props.manageSupplier}
              />
            ))}
          </React.Fragment>
        )}
      </PageWrapper>
    );
  }
}

SupplierManagement.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplierManagement)
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
