import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSettings } from "../Selectors";
import MDSpinner from "react-md-spinner";
import styled from "styled-components";

export const AppWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  margin-right: 30px;
`;

export const AppWrapperGeneral = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

export const ProductsSection = styled.div`
  width: 100%;
  flex-direction: row;
`;

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

const defaultProps = { isFetching: true, settings: {} };

class FetchingWrapper extends Component {
  render() {
    const fetching = this.props.fetching; // passed into component from parent manually for entire-page fetching
    return (
      <div>
        {!this.props.settings || fetching ? (
          <AppWrapper>
            <MDSpinner singleColor="rgb(73,190,175)" size={50} />
          </AppWrapper>
        ) : (
          <ProductsSection>{this.props.children}</ProductsSection>
        )}
      </div>
    );
  }
}

FetchingWrapper.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(FetchingWrapper);
