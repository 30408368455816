import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { fetchReferrals, createReferral } from "../../../../Actions";
import {
  getSettings,
  getIsFetching,
  getIsFetchingSettings,
  getReferrals,
} from "../../../../Selectors";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import SettingsItem from "../../SettingsItem";
import { ButtonNew, Input, Row } from "../../../../components";
import createNotification from "../../../../modules/Notification";
import { SettingsPageWrapper } from "../../../../views/settings/SettingsStyle";
import Label from "../../../../components/Label";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
  referrals: getReferrals(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchReferrals,
      createReferral,
    },
    dispatch
  ),
});

const ReferralsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ReferralContainer = styled.div`
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
  margin-top: 10px;
  width: 300px;
`;

const ReferralName = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const ReferralEmail = styled.div`
  font-size: 18px;
  font-weight: 300;
`;

const ReferralStatus = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${(p) =>
    p.status === "registered" ? theme.colors.main : theme.colors.medGrey};
`;

class Referrals extends Component {
  state = {
    sender_name: "",
    recipient_name: "",
    recipient_email: "",
    recipient_type: "STORE",
  };

  submitReferral = () => {
    let errors = {};

    if (
      !this.state.recipient_name.match(/^[a-zA-Z\s]*$/) ||
      !this.state.sender_name.match(/^[a-zA-Z\s]*$/) // accepts alphabets and spaces only (numbers and/or special characters would be considered invalid input)
    ) {
      createNotification({
        type: "danger",
        title: "Invalid Name",
        message: "Please enter a valid name",
      });
    } else if (!this.state.recipient_email.includes("@")) {
      createNotification({
        type: "danger",
        title: "Invalid Email",
        message: "Please enter a valid email",
      });
    } else if (isEmpty(errors)) {
      this.props.createReferral(this.state).then((response) => {
        if (response.error) {
          createNotification({
            type: "danger",
            title: "Error",
            message: response.error,
          });
        } else {
          createNotification({
            type: "success",
            title: "Success",
            message: "Referral Sent Successfully",
          });
          this.setState({
            sender_name: "",
            recipient_name: "",
            recipient_email: "",
          });
        }
      });
    }
  };

  componentDidMount() {
    this.props.fetchReferrals();
  }

  render() {
    const referrals = get(this.props, "referrals");
    const isSupplier = get(this.props, ["match", "path"]).includes("supplier");

    const stringStart = isSupplier
      ? "Earn 1 free week of in-app promotion"
      : "Earn 1 free month of DropCommerce";

    const allowSubmit =
      this.state.recipient_name.length >= 4 &&
      this.state.sender_name.length >= 4 &&
      this.state.recipient_email.length >= 4;

    return (
      <SettingsPageWrapper>
        <SettingsItem
          title="Refer Vendors & Suppliers To DropCommerce!"
          description={`${stringStart} for each store who signs up for a paid plan or supplier who goes live in the public marketplace!`}
        />
        <Label
          hint="Are you referring someone to become a Vendor (a dropshipper / reseller of your products), or a Supplier who makes their own products?"
          warning={this.props.hintWarning}
        >
          Referral Account Type
        </Label>
        <Row style={{ marginTop: "5px" }}>
          <ButtonNew
            text="Vendor"
            margin="0 10px 0 0"
            type={this.state.recipient_type === "STORE" ? "main" : "secondary"}
            onClick={() => this.setState({ recipient_type: "STORE" })}
          />
          <ButtonNew
            text="Supplier"
            type={
              this.state.recipient_type === "SUPPLIER" ? "main" : "secondary"
            }
            onClick={() => this.setState({ recipient_type: "SUPPLIER" })}
          />
        </Row>
        <Input
          label="Your Name"
          value={this.state.sender_name}
          extraStyle="width: 300px; margin-top: 0px;"
          onChange={(e) => this.setState({ sender_name: e.target.value })}
        />
        <Input
          label="Referral Name"
          value={this.state.recipient_name}
          extraStyle="width: 300px; margin-top: 0px;"
          onChange={(e) => this.setState({ recipient_name: e.target.value })}
        />
        <Input
          label="Referral Email"
          value={this.state.recipient_email}
          extraStyle="width: 300px; margin-top: 0px;"
          onChange={(e) => this.setState({ recipient_email: e.target.value })}
        />
        <ButtonNew
          text="Submit Referral"
          margin="20px 0 40px 0"
          width="180px"
          size="large"
          disabled={!allowSubmit}
          isFetching={this.props.isFetching}
          onClick={this.submitReferral}
        />

        <SettingsItem
          title="My Referrals"
          description="View past referrals and their status"
        />
        <ReferralsContainer>
          {referrals &&
            referrals.map((referral, idx) => (
              <ReferralContainer key={idx}>
                <ReferralName>{referral.recipient_name}</ReferralName>
                <ReferralEmail>{referral.recipient_email}</ReferralEmail>
                <ReferralStatus>{referral.status}</ReferralStatus>
              </ReferralContainer>
            ))}
        </ReferralsContainer>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referrals)
);
