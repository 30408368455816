import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  saveSupplierSettings,
  updateSupplier,
} from "../../../../views/supplier/SupplierActions";
import { getSettings } from "../../../../views/supplier/SupplierSelectors";
import { ButtonNew, Row, Input } from "../../../../components";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import Text from "../../../../modules/Text";
import { buildObjectFromFields } from "../../../../modules/Format";
import { MenuItem, Select } from "@material-ui/core";
import Hint from "../../../../components/Hint";

const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 2px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  padding: 10px;
`;

class SupplierNotifications extends Component {
  state = {
    saveState: null,
    errors: {},
  };

  notification_frequencies = [
    { value: "immediately", text: "Immediately", selected: false },
    { value: "daily", text: "Daily", selected: false },
    { value: "weekly", text: "Weekly", selected: true },
  ];

  hint = "Please contact support@dropcommerce.com to update this information";

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: "changed" });
  };

  saveSettings = () => {
    const settings = get(this.props, ["settings"]);

    const data = buildObjectFromFields(settings, [
      "inbox_notification_frequency",
      "notification_email",
    ]);

    this.setState({ saveState: "saving" }, () => {
      this.props.saveSupplierSettings(data).then(() => {
        this.setState({ saveState: null });
      });
    });
  };

  render() {
    const settings = get(this.props, ["settings"]);

    let notificationFrequency = "---";
    if (settings && settings.inbox_notification_frequency) {
      notificationFrequency = settings.inbox_notification_frequency;
    }

    return (
      <React.Fragment>
        <SettingsSection>
          <Row align style={{ justifyContent: "space-between" }}>
            <Row gap="10px" align>
              <Text.Small>Notify me of new Inbox messages:</Text.Small>

              <Select
                value={notificationFrequency.toLowerCase()}
                label={notificationFrequency}
                onChange={(event) =>
                  this.updateSupplier({
                    inbox_notification_frequency: event.target.value,
                  })
                }
              >
                {this.notification_frequencies.map((freq, i) => (
                  <MenuItem key={i} value={freq.value}>
                    {freq.text}
                  </MenuItem>
                ))}
              </Select>
            </Row>

            {this.state.saveState ? (
              <ButtonNew
                text="SAVE CHANGES"
                type="main"
                onClick={this.saveSettings}
                isFetching={this.state.saveState === "saving"}
                extra=""
              />
            ) : (
              <ButtonNew text="CHANGES SAVED" type="main-light" extra="" />
            )}
          </Row>

          <Row gap="10px" align>
            <Text.Small>Email: </Text.Small>
            <Text.Small light>{settings.email}</Text.Small>
            <Hint hint={this.hint} narrow />
          </Row>

          <Row gap="10px" align>
            <Text.Small>Notification Email: </Text.Small>

            <Input
              small
              width="300px"
              value={settings.notification_email || ""}
              extraStyle=""
              onChange={(event) =>
                this.updateSupplier({
                  notification_email: event.target.value,
                })
              }
            />
          </Row>

          <Row gap="10px" align>
            <Text.Small>Phone: </Text.Small>
            <Text.Small light>{settings.phone}</Text.Small>
            <Hint hint={this.hint} narrow />
          </Row>
        </SettingsSection>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplierNotifications)
);
