import React, { Component } from "react";
// import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { getRequest, postRequest } from "../../../../modules/API";

import moment from "moment";
import {
  BackNavBar,
  ButtonNew,
  Container,
  Hint,
  Row,
  Spinner,
} from "../../../../components";
import {
  HintTitle,
  OrderDetailItem,
  OrderDetailsWrapper,
} from "../../../../views/orders/OrdersStyle.jsx";
import Text from "../../../../modules/Text";
import { get } from "lodash";
import { theme } from "../../../../modules/Theme";
import styled from "styled-components";
import createNotification from "../../../../modules/Notification";
import { trackEvent } from "../../../../modules/EventTracking";

const ResponsiveDiv = styled.div`
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none"};
  }
`;

const StyledTextArea = styled.textarea`
  background: ${theme.colors.white};
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 18px;
  width: 600px;
  margin-right: 20px;
  height: 83px;
`;

class Order extends Component {
  state = {
    hintVisible: null,
    confirmShipping: false,
    supplierData: {},
    trackingNumber: "",
    shippingCarrier: "",

    order: undefined,
    convertPrices: true,
  };

  UNSAFE_componentWillMount = () => {
    this.fetchSupplierOrder(this.props.match.params.orderId);
  };

  fetchSupplierOrder = (order_id) => {
    getRequest({ url: `supplier_order/?id=${order_id}` })
      .then((response) =>
        this.setState({
          order: console.log(response.data) || response.data.order,
          orderStatus: response.data.order.status,
        })
      )
      .then(() => {
        trackEvent("View Supplier Order");
      })
      .catch((err) => console.log(err));
  };

  shippingSection = () => {
    const order = this.state.order;
    const address = order.shipping_address;

    return (
      <div>
        {!order.error && (
          <React.Fragment>
            <Text style={{ marginBottom: "15px", marginTop: "20px" }}>
              Details
            </Text>
            <Row style={{ marginTop: "10px" }}>
              <Text.Small light>
                Shipping Charged: ${order.shipping_charge.toFixed(2)}
              </Text.Small>
              <Hint hint="Shipping Charged = First item's shipping rate + Reduced shipping rate per additional item" />
            </Row>
            <Text.Small light>
              Product Total: ${order.subtotal.toFixed(2)}
            </Text.Small>
            <Text.Small>Order Total: ${order.total.toFixed(2)}</Text.Small>

            {order.stripe_fee !== 0.0 && (
              <React.Fragment>
                <Row style={{ marginTop: "10px" }}>
                  <Text.Small light>
                    Stripe Fee: ${order.stripe_fee.toFixed(2)}
                  </Text.Small>
                  <Hint hint="The fee our payment processor Stripe takes from the transaction. DropCommerce does not take any commission or profit on orders." />
                </Row>

                <Row style={{ marginTop: "10px" }}>
                  <Text.Small>
                    Payout Amount: ${order.payout_amount.toFixed(2)}
                  </Text.Small>
                  <Hint hint="The final amount you were paid after Stripe transaction fees. DropCommerce does not take any commission or profit on orders." />
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <div>
          <Text style={{ marginBottom: "15px", marginTop: "20px" }}>
            Shipping Address
          </Text>
          <div>{address.name}</div>
          <div>
            {address.address1}
            {address.address2 && ` - ${address.address2}`}
          </div>
          <div>
            {address.city}
            {", "}
            {address.province}
            {", "}
            {address.country}
          </div>
          <div>{address.zip}</div>
        </div>

        <div>
          <Text style={{ marginBottom: "5px", marginTop: "20px" }}>
            Order Notes
          </Text>
          <div style={{ marginBottom: "20px" }}>{order.note || "None"}</div>
        </div>
      </div>
    );
  };

  markOrderAsShipped = (orderId, trackingNumber = "", shippingCarrier) => {
    this.setState({ isFetching: true });
    postRequest({
      url: `mark_order_as_shipped/`,
      data: {
        order_id: orderId,
        tracking_number: trackingNumber,
        shipping_carrier: shippingCarrier,
      },
    })
      .then((response) => {
        trackEvent("Order Shipped");
        this.setState({ orderStatus: "shipped", isFetching: false });
      })
      .catch((error) => {
        createNotification({
          type: "danger",
          title: "Error",
          message: "ERROR!",
        });
      });
  };

  showShippingConfirm = (bool) => {
    this.setState({
      confirmShipping: bool,
    });
  };

  confirmOrderShipped = () => {
    if (this.state.shippingCarrier === "") {
      this.setState({ carrierError: "You must enter a shipping carrier" });
    } else {
      this.showShippingConfirm(false);
      this.markOrderAsShipped(
        this.state.order.id,
        this.state.trackingNumber,
        this.state.shippingCarrier
      );
    }
  };

  renderOrderStatusButton = (orderStatus) => {
    if (orderStatus === "paid") {
      return (
        <ButtonNew
          text="Mark Order As Shipped"
          type="main"
          extra="width: 300px; margin-bottom: 20px"
          fontStyle="font-size: 20px"
          onClick={() => this.showShippingConfirm(true)}
        />
      );
    } else if (orderStatus === "shipped") {
      return (
        <ButtonNew
          text="Shipped"
          type="main-light"
          disabled
          extra="width: 300px; margin-bottom: 20px"
          fontStyle="font-size: 20px"
        />
      );
    } else if (orderStatus === "cancelled") {
      return (
        <ButtonNew
          text="Cancelled"
          type="main-light"
          disabled
          extra="width: 300px; margin-bottom: 20px"
          fontStyle="font-size: 20px"
        />
      );
    }
  };

  renderPage() {
    const order = this.state.order;
    // const shippingOptions = get(this.props, ["product", "shipping_options"]);

    if (this.state.confirmShipping) {
      return (
        <OrderDetailsWrapper>
          <HintTitle>Confirm That This Order Is Shipped</HintTitle>
          <Text.Small light extra="margin-top:10px;">
            Tracking information is important for trust. Please ensure your
            shipments come with tracking information.
          </Text.Small>
          <Text.Small extra="margin-top:10px;">Tracking Number</Text.Small>
          <StyledTextArea
            onChange={(e) => this.setState({ trackingNumber: e.target.value })}
          />
          <Text.Small extra="margin-top:10px;">
            Shipping Carrier (UPS, Fedex, Etc)
          </Text.Small>
          {this.state.carrierError && (
            <Text.Small extra="color: #ff0000">
              {this.state.carrierError}
            </Text.Small>
          )}
          <StyledTextArea
            onChange={(e) => this.setState({ shippingCarrier: e.target.value })}
          />
          <div style={{ display: "flex" }}>
            <ButtonNew
              text="MARK ORDER AS SHIPPED"
              type="main"
              onClick={() => this.confirmOrderShipped()}
              extra="width: 300px"
              fontStyle="font-size: 20px"
            />
            <ButtonNew
              text="CANCEL"
              type="secondary"
              onClick={() => this.showShippingConfirm(false)}
              extra="width: 300px; margin-left: 20px"
              fontStyle="font-size: 20px"
            />
          </div>
        </OrderDetailsWrapper>
      );
    }

    if (order.error) {
      return (
        <OrderDetailsWrapper>
          <Text
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              color: "#be4958",
            }}
          >
            There was an error loading the products in this order. Please
            contact support at support@dropcommerce.com
          </Text>
          {this.shippingSection()}
        </OrderDetailsWrapper>
      );
    }

    return (
      <OrderDetailsWrapper>
        {this.renderOrderStatusButton(this.state.orderStatus)}

        <Text style={{ marginBottom: "15px" }}>Order Items</Text>

        <OrderDetailItem style={{ marginBottom: "10px" }}>
          <div
            style={{ width: "50px", fontWeight: "500", marginRight: "30px" }}
          >
            Image
          </div>
          <div style={{ width: "100px", fontWeight: "500" }}>Qty</div>
          <div style={{ width: "300px", fontWeight: "500" }}>Product</div>
          <ResponsiveDiv
            canHide="true"
            style={{ width: "150px", fontWeight: "500" }}
          >
            Price Per Item
          </ResponsiveDiv>
          <ResponsiveDiv
            canHide="true"
            iv
            style={{ width: "150px", fontWeight: "500" }}
          >
            Shipping Total
          </ResponsiveDiv>
        </OrderDetailItem>

        {order.products &&
          order.products.map((product, i) => {
            const showAdjustedRate =
              product.adjusted_shipping_rate !== product.shipping_rate;

            const adjustmentReasons = {
              CHARGE_ONCE_PER_ORDER:
                "When this order was processed, your settings were to charge one flat rate per order, so shipping was charged for the highest item only.",
            };

            const adjustmentReason =
              adjustmentReasons[product.adjustment_reason];

            return (
              <OrderDetailItem key={i} style={{ marginBottom: "10px" }}>
                <img
                  alt="Product Thumbnail"
                  src={product.image}
                  style={{
                    height: "50px",
                    width: "50px",
                    marginRight: "30px",
                    objectFit: "cover",
                  }}
                />
                <div style={{ width: "100px" }}>{product.quantity}</div>
                <div style={{ width: "300px" }}>{product.title}</div>
                <ResponsiveDiv canHide="true" style={{ width: "150px" }}>
                  ${product.price.toFixed(2)}
                </ResponsiveDiv>
                <ResponsiveDiv
                  canHide="true"
                  style={{ width: "150px", display: "flex" }}
                >
                  {showAdjustedRate && (
                    <ResponsiveDiv
                      canHide="true"
                      style={{
                        textDecoration: "line-through",
                        marginRight: "5px",
                        color: theme.colors.medGrey,
                      }}
                    >
                      ${product.shipping_rate.toFixed(2)}
                    </ResponsiveDiv>
                  )}
                  {product.adjusted_shipping_rate ||
                  product.adjusted_shipping_rate === 0 ? (
                    <ResponsiveDiv canHide="true">
                      ${product.adjusted_shipping_rate.toFixed(2)}
                    </ResponsiveDiv>
                  ) : (
                    <Text red>
                      Invalid Shipping Zone: You do not have shipping setup for
                      this country. Please contact support@dropcommerce.com
                    </Text>
                  )}
                  {showAdjustedRate && adjustmentReason && (
                    <Hint hint={adjustmentReason} narrow />
                  )}
                </ResponsiveDiv>
              </OrderDetailItem>
            );
          })}
        {this.shippingSection()}
        <ButtonNew
          text="Download Packing Slip PDF"
          type="main"
          onClick={() => window.open(order.packing_slip)}
          extra="width: 300px; margin-top: 20px"
          fontStyle="font-size: 20px"
        />
        <Text.Small style={{ marginTop: "15px", marginBottom: "15px" }}>
          Please download PDF packing slip and include inside the shipment
        </Text.Small>
        {order.invoice && (
          <React.Fragment>
            <ButtonNew
              text="Download Invoice (Internal)"
              type="main"
              onClick={() => window.open(order.invoice)}
              extra="width: 300px; margin-top: 20px"
              fontStyle="font-size: 20px"
            />
            <Text.Small style={{ marginTop: "15px" }}>
              This invoice is for your records only and contains internal
              pricing. Do not include this in the shipment.
            </Text.Small>
          </React.Fragment>
        )}
      </OrderDetailsWrapper>
    );
  }

  render() {
    const orderName = get(this.state.order, "name");
    const createdAt = get(this.state.order, "created_at");
    const createdTime =
      createdAt && moment(createdAt).format("MMMM Do YYYY, h:mma");

    const title = orderName ? `Order ${orderName}` : "Loading Order...";

    return (
      <Container>
        <Text.Large extra="margin-bottom: 10px;">{title}</Text.Large>
        <Text light extra="margin-bottom: 30px;">
          {createdTime}
        </Text>
        <BackNavBar title="BACK" onClick={() => this.props.history.goBack()} />
        {!get(this.state, ["order"]) || this.state.isFetching ? (
          <OrderDetailsWrapper
            style={{
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </OrderDetailsWrapper>
        ) : (
          this.renderPage()
        )}
      </Container>
    );
  }
}

export default withRouter(Order);
