import React from "react";
import MDSpinner from "react-md-spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchArchivedConversations,
  unarchiveConversation,
} from "../../Actions";
import { Show } from "../../components/Show";
import { theme } from "../../modules/Theme";
import Conversation from "./components/Conversation";
import { ScrollableContainer } from "./Conversations";

function ArchivedConversations(props) {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    props.fetchArchivedConversations().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <ScrollableContainer flex="7">
      <Show
        when={!loading}
        fallback={<MDSpinner singleColor={theme.colors.main} />}
      >
        {props.archivedConversations.map((conversation) => {
          const mostRecentMessage =
            conversation.messages[conversation.messages.length - 1];
          return (
            <Conversation
              archived
              actions={[
                {
                  text: "Unarchive",
                  onClick: () => props.unarchiveConversation(conversation),
                },
              ]}
              key={conversation.label}
              conversation={conversation}
              mostRecentMessage={mostRecentMessage}
            />
          );
        })}
      </Show>
    </ScrollableContainer>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    { unarchiveConversation, fetchArchivedConversations },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ArchivedConversations);
