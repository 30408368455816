import React, { Component } from "react";
import styled, { css } from "styled-components";
import MDSpinner from "react-md-spinner";

import { theme } from "../modules/Theme";

// export const ButtonIcon = styled.img`
//   height: 18px;
//   padding: 0px;
//   margin-right: 10px;
// `;

const ButtonText = styled.div`
  font-size: 14px;
  text-decoration: none !important;
  font-weight: 400;
  color: ${theme.colors.white};
  ${(props) => props.fontStyle};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  ${(p) =>
    p.size === "large" &&
    `
    font-size: 18px;
    `}
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${(p) => p.padding || "8px 11px 8px 11px"};
  text-align: center;
  width: ${(p) => p.width || "auto"};
  white-space: nowrap;
  margin: ${(p) => p.margin || "0px"};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  transition: 0.3s;
  letter-spacing: 1px;
  border-radius: 5px;

  /* DANGER */
  ${(props) =>
    props.type === "danger" &&
    css`
      background-color: #dc3545;

      &:hover {
        background-color: #c82333;
      }

      ${ButtonText} {
        color: #ffffff;
      }
    `}

  /* SECONDARY */
  ${(p) =>
    p.type === "secondary" &&
    css`
      background: #ffffff;
      border: 1px solid ${theme.colors.inputBorder};
      ${(p) =>
        p.disabled &&
        `
          background: #fafafd;
        `}
      ${ButtonText} {
        color: #3a4b4a;
        ${(p) =>
          !p.disabled &&
          `
          &:hover {
            color: #2a4240;
          }
        `}
      }
    `}

  /* MAIN */
  ${(p) =>
    p.type === "main" &&
    css`
      background: ${theme.colors.main};
      ${(p) =>
        !p.disabled &&
        `
          &:hover {
          background: ${theme.colors.mainDark};
        }
        `}

      ${(p) =>
        p.disabled &&
        `
          background: ${theme.colors.mainLight};
        `}
      ${ButtonText} {
        color: ${theme.colors.white};
      }
    `}

  /* MAIN LIGHT */
  ${(p) =>
    p.type === "main-light" &&
    css`
      background: ${theme.colors.mainLight};
      ${ButtonText} {
        color: ${theme.colors.white};
      }
    `}

  /* MAIN DARK */
  ${(p) =>
    p.type === "main-dark" &&
    css`
      background: ${theme.colors.darkGreen};
      &:hover {
        background: ${theme.colors.darkGrey};
      }
      ${ButtonText} {
        color: ${theme.colors.white};
      }
    `}

    /* CUSTOM */
  ${(p) =>
    p.type === "custom" &&
    css`
      background: ${p.backgroundColor};
      &:hover {
        background: ${p.backgroundColor};
      }
      ${ButtonText} {
        color: ${theme.colors.white};
      }
    `}

    ${(p) =>
    p.disabled &&
    `
        &:hover {
          cursor: default;
        }
    `}

    ${(p) =>
    p.size === "large" &&
    `
    padding: 15px;
    `}
    ${(p) => p.extra}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

class Button extends Component {
  render() {
    const {
      backgroundColor,
      icon,
      text,
      type,
      isFetching,
      fontStyle,
      className,
      margin,
      disabled,
      onClick,
      fixed,
      width,
      size,
      extra,
    } = this.props;

    return (
      <StyledButton
        backgroundColor={backgroundColor}
        type={type || "main"}
        size={size}
        fixed={fixed}
        className={className}
        onClick={disabled ? null : onClick}
        disabled={disabled}
        margin={margin}
        width={width}
        extra={extra}
      >
        {isFetching ? (
          <MDSpinner
            singleColor={
              type === "secondary" ? "#3a4b4a" : "rgb(255, 255, 255)"
            }
            size={20}
          />
        ) : (
          <ButtonWrapper>
            <ButtonText
              size={size}
              fontStyle={fontStyle}
              type={type}
              style={{ marginRight: icon ? "7px" : "0px" }}
            >
              {text}
            </ButtonText>
            {icon}
          </ButtonWrapper>
        )}
      </StyledButton>
    );
  }
}

export default Button;
