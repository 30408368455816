import React, { Component } from "react";
import { Column } from "../../../../../components/Layout";
import Hint from "../../../../../components/Hint";
import Text from "../../../../../modules/Text";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import caretDown from "../../../../../images/icons/feather/caret-down.svg";
import { get } from "lodash";
import ChangeValue from "./ChangeValue";

export const PricingSectionWrapper = styled.div`
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
  width: 400px;
  margin-left: 15px;
`;

export const VariantWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-top: ${(p) => (p.noBorder ? "0px" : "1px")} solid
    ${theme.colors.lightBorder};
  align-items: center;
  ${(p) => !p.active && "opacity: 0.5;"};
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none"}
  }
`;

export const VariantItemRow = styled.div`
  display: flex;
  align-items: center;
  width: ${(p) => p.width || "120px"};
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none"}
  }
`;

export const VariantItem = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  font-weight: ${(p) => (p.light ? "300" : "500")};
  width: ${(p) => p.width || "120px"};
  ${(p) =>
    p.profit &&
    `
  font-weight: 500;
  color: ${p.profit > 0 ? "#00be2f" : theme.colors.orange}
  `}
  ${(p) => p.noPaddingRight && "padding-right: 0px;"}
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: None"}
  }
`;

export const VariantOptionInput = styled.input`
  background: transparent;
  font-size: 16px;
  width: 150px;
  font-weight: 300;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
  outline: none;
`;

export const VariantInput = styled.input`
  background: transparent;
  font-size: 16px;
  width: 90px;
  font-weight: 300;
  border: 0px;
  outline: none;
`;

export const PriceInputWrapper = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.mapError ? "#ff0000" : theme.colors.inputBorder)};
  align-items: center;
`;

export const DownArrow = styled.img`
  height: 5px;
  width: 5px;
`;

export const PriceInputCurrency = styled.div`
  margin-right: 5px;
  font-size: 16px;
  font-weight: 300;
`;

const VariantItemHeader = ({ type, hintText, hintLink, width, canHide }) => (
  <VariantItemRow canHide={canHide} width={width}>
    <VariantItem key={type} width="auto" noPaddingRight>
      {type}
    </VariantItem>
    {hintText && <Hint hint={hintText} link={hintLink} />}
  </VariantItemRow>
);

const PriceInput = ({ value, currency, mapError, onChange, disabled }) => (
  <PriceInputWrapper mapError={mapError}>
    <PriceInputCurrency>{currency}</PriceInputCurrency>
    <VariantInput
      disabled={disabled}
      price
      value={value}
      onChange={onChange}
      type="number"
      step="0.01"
    />
  </PriceInputWrapper>
);

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.checked ? theme.colors.main : theme.colors.lightGrey};
  border-radius: 3px;
  transition: all 150ms;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Price = styled.div`
  color: ${theme.colors.darkGrey};
  font-weight: 300;
  opacity: 0.6;
  ${(p) => p.dark && "font-weight: 500; opacity: 1.0;"}
`;

export const ChangeAllButton = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  font-size: 12px;
  font-weight: 400;
  width: 135px;
  align-items: center;
  color: ${theme.colors.medDarkGrey};
  position: relative;
`;

const ChangeAllButtonText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  width: 100%;
  cursor: pointer;
`;

export const DropdownOptions = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #abb3b2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  top: 25px;
  right: 1px;
  z-index: 1000;
`;

export const ShippingOption = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: ${theme.colors.medDarkGrey};
  &:hover {
    color: ${theme.colors.main};
  }
`;

class Variants extends Component {
  state = {
    checked: false,

    changeAll: false,
    changeAllValue: "",
    changeAllType: "",

    changeAllCompareAt: false,
    changeAllCompareAtValue: "",
    changeAllCompareAtType: "",

    changeShipping: false,
    shippingCountry: null,

    showAllVariants: false,
  };

  handleCheckboxChange = (event) =>
    this.setState({ checked: event.target.checked });

  chooseShippingOption = (e, shippingOption) => {
    e.stopPropagation();

    this.setState({
      changeShipping: false,
      shippingCountry: shippingOption,
    });
  };

  changeAllCompareAt = (type) => {
    const value = this.state.changeAllCompareAtValue;
    this.props.editAll("compare_at_price", type, value);
    this.setState({
      changeAllCompareAt: false,
      changeAllCompareAtValue: "",
      changeAllCompareAtType: "",
    });
  };

  changeAll = (type) => {
    const value = this.state.changeAllValue;
    this.props.editAll("custom_price", type, value);
    this.setState({
      changeAll: false,
      changeAllValue: "",
      changeAllType: "",
    });
  };

  propagateThenSetState = (e, newState) => {
    e.stopPropagation();
    this.setState(newState);
  };

  render() {
    const { variants, selectVariant, editField, userCurrency, product } =
      this.props;

    const usShipping = get(product, ["shipping_options", "US"]);
    const canShipping = get(product, ["shipping_options", "CA"]);
    const intlShipping = get(product, ["shipping_options", "INTL"]);

    let currentShippingOption = null;

    // USE CORRECT SHIPPING COUNTRY
    switch (this.state.shippingCountry) {
      case null:
        if (usShipping) {
          currentShippingOption = usShipping;
        } else if (canShipping) {
          currentShippingOption = canShipping;
        } else if (intlShipping) {
          currentShippingOption = intlShipping;
        }
        break;
      case "US":
        currentShippingOption = usShipping;
        break;
      case "CA":
        currentShippingOption = canShipping;
        break;
      case "INTL":
        currentShippingOption = intlShipping;
        break;
      default:
        currentShippingOption = usShipping;
    }

    let currencyDisplay = "$";

    if (userCurrency === "USD") {
      currencyDisplay = "US$";
    } else if (userCurrency === "CAD") {
      currencyDisplay = "C$";
    }

    return (
      <Column style={{ width: "100%", paddingTop: "10px" }}>
        <VariantWrapper active noBorder style={{ paddingBottom: "10px" }}>
          <VariantItem width="40px" />
          <VariantItem width="200px">Name</VariantItem>
          <VariantItemHeader
            canHide={true}
            type="Cost"
            hintText="The price you will pay for the product if you make a sale."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            canHide={true}
            type="Shipping"
            width="150px"
            hintText="The shipping rate charged by the supplier. You will need to pay this in addition to the Cost if you make a sale."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            type="Base Price"
            width="150px"
            hintText="The price you will pay for the product and shipping if you make a sale."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            type="Price"
            width="150px"
            hintText="The price you will charge in your store (in your store's currency)."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            canHide={true}
            type="Profit"
            width="100px"
            hintText="The estimated profit you will earn based on your current price."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            canHide={true}
            type="Compare Price"
            width="170px"
            hintText="The price your store will display as the 'old price', to indicate a discount or sale. To remove this setting, set this to the same as your Price."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />

          <VariantItemHeader
            canHide={true}
            type="Inventory"
            hintText="The quantity of this product in stock, available to be sold."
            hintLink="https://help.dropcommerce.com/en/articles/4360553-product-variants"
          />
        </VariantWrapper>
        <VariantWrapper canHide="true" active>
          <VariantItem width="40px"></VariantItem>
          <VariantItem width="200px"></VariantItem>
          <VariantItem></VariantItem>
          <VariantItem width="150px">
            <ChangeAllButton
              onClick={() =>
                this.setState({ changeShipping: !this.state.changeShipping })
              }
            >
              <ChangeAllButtonText>
                {currentShippingOption.country}
              </ChangeAllButtonText>
              <DownArrow src={caretDown} />

              {this.state.changeShipping && (
                <DropdownOptions>
                  {Object.keys(product.shipping_options).map((option) => (
                    <ShippingOption
                      key={option.country}
                      onClick={(e) =>
                        this.chooseShippingOption(
                          e,
                          product.shipping_options[option].country_code
                        )
                      }
                    >
                      {product.shipping_options[option].country}
                    </ShippingOption>
                  ))}
                </DropdownOptions>
              )}
            </ChangeAllButton>
          </VariantItem>
          <VariantItem width="150px">
            <ChangeAllButton
              onClick={() =>
                this.setState({ changeAll: !this.state.changeAll })
              }
            >
              <ChangeAllButtonText>Change All Prices</ChangeAllButtonText>
              <DownArrow src={caretDown} />

              {this.state.changeAllType !== "" && (
                <ChangeValue
                  type={this.state.changeAllType}
                  onChange={(e) =>
                    this.setState({
                      changeAllValue: e.target.value,
                    })
                  }
                  apply={() => this.changeAll(this.state.changeAllType)}
                  close={() =>
                    this.setState({
                      changeAll: false,
                      changeAllValue: "",
                      changeAllType: "",
                    })
                  }
                />
              )}

              {this.state.changeAll && (
                <DropdownOptions>
                  <ShippingOption
                    onClick={(e) =>
                      this.propagateThenSetState(e, {
                        changeAllType: "multiply",
                        changeAll: false,
                      })
                    }
                  >
                    Multiply By
                  </ShippingOption>
                  <ShippingOption
                    onClick={(e) =>
                      this.propagateThenSetState(e, {
                        changeAllType: "value",
                        changeAll: false,
                      })
                    }
                  >
                    Set new value
                  </ShippingOption>
                </DropdownOptions>
              )}
            </ChangeAllButton>
          </VariantItem>

          <VariantItem width="100px"></VariantItem>
          <VariantItem width="170px">
            <ChangeAllButton
              onClick={() =>
                this.setState({
                  changeAllCompareAt: !this.state.changeAllCompareAt,
                })
              }
            >
              <ChangeAllButtonText>Change All Prices</ChangeAllButtonText>
              <DownArrow src={caretDown} />

              {this.state.changeAllCompareAtType !== "" && (
                <ChangeValue
                  type={this.state.changeAllCompareAtType}
                  onChange={(e) =>
                    this.setState({
                      changeAllCompareAtValue: e.target.value,
                    })
                  }
                  apply={() =>
                    this.changeAllCompareAt(this.state.changeAllCompareAtType)
                  }
                  close={() =>
                    this.setState({
                      changeAllCompareAt: false,
                      changeAllCompareAtValue: "",
                      changeAllCompareAtType: "",
                    })
                  }
                />
              )}

              {this.state.changeAllCompareAt && (
                <DropdownOptions>
                  <ShippingOption
                    onClick={(e) =>
                      this.propagateThenSetState(e, {
                        changeAllCompareAtType: "multiply",
                        changeAllCompareAt: false,
                      })
                    }
                  >
                    Multiply By
                  </ShippingOption>
                  <ShippingOption
                    onClick={(e) =>
                      this.propagateThenSetState(e, {
                        changeAllCompareAtType: "value",
                        changeAllCompareAt: false,
                      })
                    }
                  >
                    Set new value
                  </ShippingOption>
                </DropdownOptions>
              )}
            </ChangeAllButton>
          </VariantItem>

          <VariantItem></VariantItem>
        </VariantWrapper>
        {variants &&
          variants.map((variant, i) => {
            const profit = (
              variant.custom_price -
              variant.price.amount -
              currentShippingOption.price.amount
            ).toFixed(2);
            const originalCurrencyDisplay =
              variant.price.original_currency === "CAD" ? "C$" : "US$";

            if (i < 8 || this.state.showAllVariants) {
              return (
                <div key={variant.id}>
                  <VariantWrapper active={variant.active}>
                    <VariantItem width="40px">
                      <label>
                        <Checkbox
                          checked={variant.active}
                          onChange={(e) => selectVariant(e, variant.id)}
                        />
                      </label>
                    </VariantItem>

                    <VariantItem width="200px">
                      <VariantOptionInput
                        disabled={!variant.active}
                        value={variant.name}
                        onChange={(e) =>
                          editField("name", e.target.value, variant.id)
                        }
                      />
                    </VariantItem>

                    <VariantItem canHide={true}>
                      <Column>
                        <Price
                          dark
                        >{`${currencyDisplay}${variant.price.amount.toFixed(
                          2
                        )}`}</Price>
                        {currencyDisplay !== originalCurrencyDisplay && (
                          <Price>
                            {`${originalCurrencyDisplay}${variant.price.original_amount.toFixed(
                              2
                            )}`}
                          </Price>
                        )}
                      </Column>
                    </VariantItem>

                    <VariantItem canHide={true} light width="150px">
                      <Column>
                        <Price
                          dark
                        >{`${currencyDisplay}${currentShippingOption.price.amount.toFixed(
                          2
                        )}`}</Price>
                        {currencyDisplay !== originalCurrencyDisplay && (
                          <Price>
                            {`${originalCurrencyDisplay}${currentShippingOption.price.original_amount.toFixed(
                              2
                            )}`}
                          </Price>
                        )}
                      </Column>
                    </VariantItem>

                    <VariantItem canHide={true} light width="150px">
                      <Column>
                        <Price dark>{`${currencyDisplay}${(
                          variant.price.amount +
                          currentShippingOption.price.amount
                        ).toFixed(2)}`}</Price>
                        {currencyDisplay !== originalCurrencyDisplay && (
                          <Price>
                            {`${originalCurrencyDisplay}${(
                              variant.price.original_amount +
                              currentShippingOption.price.original_amount
                            ).toFixed(2)}`}
                          </Price>
                        )}
                      </Column>
                    </VariantItem>

                    <VariantItem width="150px">
                      <PriceInput
                        currency={currencyDisplay}
                        disabled={!variant.active}
                        mapError={variant.mapError}
                        value={variant.custom_price}
                        onChange={(e) =>
                          editField("custom_price", e.target.value, variant.id)
                        }
                      />
                    </VariantItem>

                    <VariantItem
                      canHide="true"
                      light
                      profit={profit}
                      width="100px"
                    >
                      {currencyDisplay}
                      {profit}
                    </VariantItem>
                    <VariantItem canHide="true" width="170px">
                      <PriceInput
                        currency={currencyDisplay}
                        disabled={!variant.active}
                        value={variant.compare_at_price}
                        mapError={variant.comparePriceError}
                        onChange={(e) =>
                          editField(
                            "compare_at_price",
                            e.target.value,
                            variant.id
                          )
                        }
                      />
                    </VariantItem>
                    <VariantItem canHide="true" light>
                      {variant.quantity}
                    </VariantItem>
                  </VariantWrapper>
                  {variant.comparePriceError && (
                    <VariantWrapper active>
                      <VariantItem
                        bold
                        style={{ color: "#db3232", width: "100%" }}
                      >
                        {variant.comparePriceError}
                      </VariantItem>
                    </VariantWrapper>
                  )}
                  {variant.mapError && (
                    <VariantWrapper active>
                      <VariantItem
                        bold
                        style={{ color: "#db3232", width: "100%" }}
                      >
                        {variant.mapError}
                      </VariantItem>
                    </VariantWrapper>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}

        {variants.length > 8 && !this.state.showAllVariants && (
          <div
            style={{ padding: "15px" }}
            onClick={() => this.setState({ showAllVariants: true })}
          >
            <Text light link>{`Show ${
              variants.length - 8
            } remaining variants`}</Text>
          </div>
        )}
      </Column>
    );
  }
}

export default Variants;
