import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import SkeletonLoader from "./SkeletonLoader";
import { Row } from "./Layout";

export const Category = styled.div`
  width: calc(100% * (1 / 4) - 10px);
  @media (max-width: 1200px) {
    width: calc(100% * (1 / 3) - 10px);
  }
  @media (max-width: 800px) {
    width: calc(100% * (1 / 2) - 10px);
    height: 150px;
  }
  margin-bottom: 20px;
`;

export const CategoryImage = styled(SkeletonLoader)`
  height: 200px;
  border: 1px solid ${theme.colors.lightGrey};
  margin-bottom: 20px;
  @media (max-width: 800px) {
    height: 150px;
  }
`;

export const TextBlob = styled(SkeletonLoader)`
  height: 15px;
  margin-bottom: 15px;
  min-width: auto;
`;

class CategorySkeleton extends Component {
  render() {
    return (
      <Category>
        <CategoryImage />
        <Row>
          <TextBlob style={{ width: "70%", marginRight: "20px" }} />
          <TextBlob style={{ width: "30%" }} />
        </Row>
      </Category>
    );
  }
}

export default CategorySkeleton;
