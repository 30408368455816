import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Row, Column } from "../../../components/Layout";
import ButtonNew from "../../../components/ButtonNew";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import moment from "moment";

const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medGrey};
  margin-right: 15px;
`;

const Section = styled.div`
  display: flex;
  border-top: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  align-items: center;
`;

const SupplierWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 1000px;
  margin: 15px;
`;

const fields = [
  "access_granted",
  "theme_imported",
  "theme_edited",
  "products_imported",
  "shipping_configured",
  "reviewed",
  "completed",
];

class PrebuiltStore extends Component {
  loadProducts = () => {
    this.props
      .updatePrebuiltStore(this.props.store.id, "products_imported")
      .then(() => {
        console.log("PREBUILT STORE SET TO IMPORTED, NOW ACTUALLY RUN IMPORT");
        this.props.loadProducts("LOAD_PREMADE_STORE", {
          store_id: this.props.store.id,
        });
      });
  };

  render() {
    const { store, updatePrebuiltStore } = this.props;

    return (
      <SupplierWrapper>
        <Section style={{ border: "0px", width: "100%" }}>
          <Column style={{ width: "100%" }}>
            <Row style={{ width: "100%", justifyContent: "space-between" }}>
              <Text.Large bold>{store.package.toUpperCase()}</Text.Large>
              <Text light>{moment(store.date).format("MMM Do YYYY")}</Text>
            </Row>

            <Text.Small light extra="margin-bottom: 20px;">
              {store.store_type.toUpperCase().replace("_", " ")}
            </Text.Small>
            <Text.Small bold>Email</Text.Small>
            <Text light extra="margin-bottom: 20px; opacity: 0.6;">
              {store.email}
            </Text>

            <Text.Small bold>Purchaser Store URL</Text.Small>
            <Text
              light
              link
              onClick={() => window.open(`https://${store.url}`)}
            >
              {store.url}
            </Text>

            <Row style={{ marginTop: "20px" }}>
              <Text.Small bold>Notes</Text.Small>
              <Text.Small
                light
                link
                extra="margin-left: 5px;"
                onClick={() =>
                  window.open(
                    `http://dropcommerce-app.herokuapp.com/admin/dropcommerce/premadestore/${store.id}`
                  )
                }
              >
                Edit
              </Text.Small>
            </Row>

            <Text light extra="opacity: 0.6;">
              {store.notes || "None"}
            </Text>
          </Column>
        </Section>
        <Section>
          <SectionHeader>Steps</SectionHeader>

          <Row>
            {fields.map((field) => {
              if (field === "products_imported") {
                if (store.access_granted) {
                  return (
                    <ButtonNew
                      key={field}
                      disabled={false}
                      isFetching={store[`isFetching_${field}`]}
                      type={store[field] ? "main-light" : "main"}
                      text={
                        store[field] ? "PRODUCTS IMPORTED" : "IMPORT PRODUCTS"
                      }
                      onClick={this.loadProducts}
                      extra="margin-right: 15px;"
                    />
                  );
                } else return ""; // @Patrick, is this style okay? Linter expected a return statement.
              } else {
                return (
                  <ButtonNew
                    key={field}
                    disabled={false}
                    isFetching={store[`isFetching_${field}`]}
                    type={store[field] ? "main-light" : "main"}
                    text={field.toUpperCase().replace("_", " ")}
                    onClick={() => updatePrebuiltStore(store.id, field)}
                    extra="margin-right: 15px;"
                  />
                );
              }
            })}
          </Row>
        </Section>
      </SupplierWrapper>
    );
  }
}

export default withRouter(PrebuiltStore);
