import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { updateStateDirectly } from "../../../../Actions";
import { getSettings, getIsFetchingSettings } from "../../../../Selectors";
import { Form, Spinner } from "../../../../components";
import {
  SettingsPageWrapper,
  SettingsSubWrapper,
} from "../../../../views/settings/SettingsStyle";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateStateDirectly,
    },
    dispatch
  ),
});

class ImportSettings extends Component {
  updateStore = (field, value) => {
    if (field === "global_markup_percent") {
      value = parseInt(value, 10);
    }
    this.props.updateStateDirectly([
      { key: "settings" },
      { key: "store", fields: [[field, value]] },
    ]);
  };

  render() {
    const store = get(this.props, ["settings", "store"]);

    const globalMarkupLabelMap = {
      null: "None",
      PERCENT: "Percent",
      FLAT: "Flat Rate",
    };

    const globalMarkupOptions = [
      {
        value: null,
        label: "None",
      },
      {
        value: "PERCENT",
        label: "Percent",
      },
      {
        value: "FLAT",
        label: "Flat Rate",
      },
    ];

    const isShopify = get(store, ["platform", "name"]) === "SHOPIFY";

    const items = [
      {
        type: "switch",
        label: "Include Category Tags",
        hint: "Automatically include our system's default categories as tags for your product import, used to categorize and organize within your online store.",
        key: "include_category_tags",
      },
      {
        type: "select",
        label: "Global Price Markup",
        hint: "Set automatic percentage-based or flat-rate markups for product pricing.",
        key: "global_markup",
        selectOptions: globalMarkupOptions,
        labelMap: globalMarkupLabelMap,
        marginTop: true,
      },
      {
        type: "input",
        label: "Markup Amount",
        hint: "The amount that you want to mark up each product",
        key: "global_markup_amount",
        selectOptions: globalMarkupOptions,
        labelMap: globalMarkupLabelMap,
        marginTop: true,
        displayRequirement: get(store, "global_markup"),
      },
      {
        type: "select",
        label: "Markup Base Price",
        hint: "Choose whether to use the 'cost' that you pay or the 'retail price' for markup calculations.",
        key: "global_pricing_use_retail_price",
        selectOptions: [
          {
            value: false,
            label: "Cost",
          },
          {
            value: true,
            label: "Retail Price",
          },
        ],
        labelMap: {
          true: "Retail Price",
          false: "Cost",
        },
      },
      {
        type: "switch",
        label: "Round Prices Up",
        hint: "Round up prices to the nearest dollar for a nice, clean look.",
        key: "global_markup_round_up",
      },
      {
        type: "switch",
        label: "Add Separate Shipping Rate",
        hint: "Add the supplier's shipping rate as a separate shipping charge for the product in your store.",
        key: "add_separate_shipping_rate",
        marginTop: true,
        displayRequirement: !isShopify,
      },
      {
        type: "switch",
        label: "Include Shipping In Price",
        hint: "Automatically add the highest shipping rate to your default product import price.",
        key: "include_shipping",
        marginTop: true,
      },
      {
        type: "switch",
        label: "US Shipping",
        key: "include_us_shipping",
        displayRequirement: get(store, "include_shipping"),
      },
      {
        type: "switch",
        label: "Canada Shipping",
        key: "include_canada_shipping",
        displayRequirement: get(store, "include_shipping"),
      },
      {
        type: "switch",
        label: "International Shipping",
        key: "include_international_shipping",
        displayRequirement: get(store, "include_shipping"),
      },
      {
        type: "switch",
        label: "Show Bulk Imports",
        key: "show_bulk_imports",
        displayRequirement: get(store, "allow_bulk_imports"),
      },
    ];

    const formValidation = [
      {
        rule:
          get(store, "include_shipping") &&
          !get(store, "include_us_shipping") &&
          !get(store, "include_canada_shipping") &&
          !get(store, "include_international_shipping"),
        error:
          "Please choose at least one country to use for shipping rate calculation or turn off the 'Include Shipping Rates' setting.",
      },
      {
        rule:
          !isShopify &&
          get(store, "add_separate_shipping_rate") &&
          get(store, "include_shipping"),
        error:
          "Please select either 'Add Separate Shipping Rate' or 'Include Shipping Rates In Price', since they are conflicting settings.",
      },
    ];

    return (
      <SettingsPageWrapper>
        <SettingsSubWrapper>
          {this.props.isFetching ? (
            <Spinner />
          ) : (
            <Form
              width="auto"
              title="Product Import Settings"
              items={items}
              labelWidth="180px"
              flex={true}
              validation={formValidation}
              onChange={(field, value) => this.updateStore(field, value)}
              action={{
                type: "POST",
                endpoint: "/store_settings/",
              }}
              stateLocation={store}
            />
          )}
        </SettingsSubWrapper>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportSettings)
);
