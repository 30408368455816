import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchUnsyncedProducts,
  fixUnsyncedProduct,
} from "../../views/products/ProductsActions";
import { getSettings } from "../../Selectors";
import { Row, Column, Hint } from "../../components";
import { get } from "lodash";
import { theme } from "../../modules/Theme";
import {
  getIsFetching,
  getUnsyncedProducts,
} from "../../views/products/ProductsSelectors";
import Titlebar from "../../components/Titlebar";

import { Container } from "../../components/Container";
import ButtonNew from "../../components/ButtonNew";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import Text from "../../modules/Text";

export const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 5px;
`;

export const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
  max-width: 600px;
`;

export const SubtitleSmall = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
  max-width: 600px;
`;

export const Date = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  margin-left: 20px;
  width: 100px;
  text-align: right;
`;

export const Image = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  margin-right: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
`;

export const Supplier = styled.span`
  font-weight: 700;
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const VariantsHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Variant = styled.div`
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #e6ebea;
  padding: 8px;
`;

export const ChangeWrapper = styled.div`
  border-radius: 4px;
  border-top: 1px solid #e6ebea;
  padding: 15px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ChangeType = styled.div`
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  color: white;
  margin-right: 20px;
  background: ${(p) =>
    p.type === "PRODUCT" ? theme.colors.main : theme.colors.orange};
`;

export const Product = styled.div`
  background: white;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  margin-top: 15px;
  margin-right: 15px;
  width: calc(100% / 2 - 15px);
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ChangeText = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
  margin-top: 10px;
`;

export const ChangeTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

export const VariantName = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

export const MainColor = styled.span`
  font-weight: 600;
  color: ${theme.colors.darkGrey};
`;

export const VariantsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  products: getUnsyncedProducts(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchUnsyncedProducts,
      fixUnsyncedProduct,
    },
    dispatch
  ),
});

class UnsyncedProducts extends Component {
  state = {};

  componentDidMount = () => {
    this.props.fetchUnsyncedProducts();
  };

  renderButton = (product) => {
    // if (product.error) {
    //   return (
    //     <ButtonNew
    //       type="red"
    //       text="Error"
    //       extra="width: 300px;"
    //       fontStyle="font-size: 15px;"
    //     />
    //   );
    // } else if (product.issue_type === "NONE") {
    //   return (
    //     <ButtonNew
    //       type="red"
    //       text="Waiting On Supplier Response"
    //       extra="width: 300px;"
    //       fontStyle="font-size: 15px;"
    //     />
    //   );
    // } else if (product.issue_type === "REMOVE") {
    //   return (
    //     <ButtonNew
    //       type={product.fixed ? "main-light" : "main"}
    //       text={product.fixed ? "Removed" : "Remove Product"}
    //       icon={product.fixed && "check"}
    //       isFetching={product.isFetching}
    //       extra="width: 300px;"
    //       fontStyle="font-size: 15px;"
    //       onClick={() => this.props.fixUnsyncedProduct(product.id, "remove")}
    //     />
    //   );
    // } else if (product.issue_type === "SYNC") {
    // }
    return (
      <Column>
        <Row align>
          <ButtonNew
            type={product.fixed ? "main-light" : "main"}
            text={product.fixed ? "Fixed" : "Resync With Default Pricing"}
            icon={product.fixed && "check"}
            isFetching={product.isFetching}
            extra="width: 300px;"
            fontStyle="font-size: 15px;"
            onClick={() =>
              this.props.fixUnsyncedProduct(
                product.bad_product,
                product.id,
                "sync"
              )
            }
          />
          <Hint hint="Sync the data in your store with the current options in our system, using your default pricing rules in DropCommerce." />
        </Row>

        {/* <Row align style={{ marginTop: "15px" }}>
          <ButtonNew
            type={product.fixed ? "main-light" : "main"}
            text={product.fixed ? "Fixed" : "Delete & Re-Add To Import List"}
            icon={product.fixed && "check"}
            isFetching={product.isFetching}
            extra="width: 300px;"
            fontStyle="font-size: 15px;"
            onClick={() =>
              this.props.fixUnsyncedProduct(
                product.id,
                product.id,
                "import_list"
              )
            }
          />
          <Hint hint="Delete this product entirely from your Shopify store and add the current version back to your Import List so you can go edit pricing before importing to your Store." />
        </Row> */}
      </Column>
    );
  };

  render() {
    const products = get(this.props, "products");

    return (
      <>
        <Titlebar title="Unsynced Products" />
        <Container>
          <Subtitle>
            Attention: The following products are no longer synced with your
            Store. Inventory sync and order processing will not work.
          </Subtitle>
          <SubtitleSmall>
            It's important that you resolve these issues by clicking an option
            below for each item. Warning: Updating or removing products in your
            store may cause issues with third-party apps or sales channels.
          </SubtitleSmall>
          <NotificationsContainer>
            {!this.props.isFetching && this.props.products.length === 0 && (
              <Title style={{ marginTop: "20px" }}>
                There are no unsynced products.
              </Title>
            )}

            {this.props.isFetching ? (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  padding: "30px",
                }}
              >
                <MDSpinner singleColor="rgb(73,190,175)" size={40} />
              </div>
            ) : (
              products &&
              products.map((product, idx) => {
                return (
                  <Product key={idx}>
                    <Row
                      style={{
                        alignItems: "center",
                        padding: "15px",
                      }}
                    >
                      <Image src={product.product_image_url} />
                      <Column style={{ width: "100%" }}>
                        <Title>{product.product_title}</Title>
                        {product.error && (
                          <Text.Small bold red extra="margin-top: 5px;">
                            Error: {product.error}
                          </Text.Small>
                        )}
                      </Column>
                      {this.renderButton(product)}
                    </Row>
                  </Product>
                );
              })
            )}
          </NotificationsContainer>
        </Container>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsyncedProducts);
