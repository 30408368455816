import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { signUp } from "../../Actions";
import { getError, getIsFetching, getSubdomainData } from "../../Selectors";
import Cookies from "js-cookie";
import { getUrlParameter, getUrlSubdomain } from "../../modules/Format";
import Account, {
  AccountButton,
  AccountInput,
  AccountLink,
  Form,
  LoginOptions,
} from "./components/AccountComponents";

import { get, isEmpty } from "lodash";
import { trackEvent } from "../../modules/EventTracking";
import { validEmail, validPassword } from "../../validation";

const mapStateToProps = (state) => ({
  error: getError(state),
  isFetching: getIsFetching(state),
  subdomainData: getSubdomainData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      signUp,
    },
    dispatch
  ),
});

const defaultProps = {};

class Signup extends Component {
  state = {
    errors: {},
    email: "",
    password: "",
  };

  onChange = (e) => {
    if (e.key === "Enter") {
      this.signUp();
    }
  };

  isValid = () => {
    const errors = {};

    const { email, password } = this.state;

    if (!validPassword(password)) {
      errors.password = "Please enter a longer, more secure password";
    }

    if (!validEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    this.setState((previousState) => ({ ...previousState, errors: errors }));

    return isEmpty(errors);
  };

  signUp = () => {
    let redirectRoute = "";
    const prebuilt = getUrlParameter("prebuilt", this.props.location);
    if (prebuilt) {
      redirectRoute = `/prebuilt?type=${prebuilt}`;
    }

    const subdomain = getUrlSubdomain();
    if (subdomain === "app" || subdomain === "global" || subdomain === "nft") {
      redirectRoute = "/setup";
    } else {
      redirectRoute = "/settings";
    }

    if (this.isValid()) {
      if (window.location.href.includes("iframe")) {
        this.props
          .signUp({
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            subdomain: getUrlSubdomain(),
            referral_source: Cookies.get("dc_referral_source"),
          })
          .then((response) => {
            if (response.data.token) {
              Cookies.set("accessToken", response.token, { expires: 10 });
              this.props.history.push("/thanks");
            }
          });
      } else {
        this.props
          .signUp({
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            subdomain: getUrlSubdomain(),
            referral_source: Cookies.get("dc_referral_source"),
          })
          .then((response) => {
            if (response.data.token) {
              Cookies.set("accessToken", response.data.token, { expires: 10 });
              this.props.history.push(redirectRoute);
            }
          });
      }
    }
  };

  componentDidMount = () => {
    trackEvent("Signup Page");
    let referralUrl = get(this.props, ["location", "pathname"]);
    const referralSource = getUrlParameter("ref", this.props.location) || "";
    const initialReferralUrl = referralUrl;

    if (referralSource !== "") {
      // TRACK REFERRAL SOURCE
      window.tap("detect");
    }

    if (referralUrl === "/dodropshippingpricing") {
      referralUrl = "/dodropshipping";
    }

    if (!Cookies.get("dc_referral_source")) {
      if (referralUrl) {
        Cookies.set("dc_referral_source", referralUrl.replace("/", ""), {
          expires: 30,
        });
      } else if (referralSource !== "") {
        Cookies.set("dc_referral_source", referralSource, { expires: 30 });
      }
    }

    if (initialReferralUrl === "/dodropshippingpricing") {
      window.location.href = "https://www.dropcommerce.com/pricing";
    }
  };

  render() {
    const subdomain = getUrlSubdomain();

    // Patch for NFT demo
    let isGlobal = false;
    if (subdomain === "global" || subdomain === "nft") {
      isGlobal = true;
    }
    const isSupplierPortal =
      subdomain !== "app" && subdomain !== "global" && subdomain !== "nft";
    const logo = get(this.props, ["subdomainData", "logo"]);
    const subdomainName = get(this.props, ["subdomainData", "name"]);
    const fetchingSubdomainData = get(this.props, [
      "subdomainData",
      "isFetching",
    ]);

    const prebuilt = getUrlParameter("prebuilt", this.props.location);

    let title =
      isSupplierPortal && subdomainName
        ? `Dropship With ${subdomainName}`
        : "Sign Up";

    let subtitle = isSupplierPortal
      ? "Join our dropshipping program today"
      : "Automate your dropshipping business today";

    if (prebuilt) {
      title = "Complete Purchase";
      subtitle = "Sign up to complete your prebuilt store purchase";
    }

    return (
      <Account
        sideImage={!isSupplierPortal}
        isSupplierPortal={isSupplierPortal}
        logo={logo}
        isFetching={this.props.isFetching > 0 || fetchingSubdomainData}
        title={title}
        subtitle={subtitle}
      >
        <Form>
          <AccountInput
            maxWidth="100%"
            error={this.state.errors.email}
            onChange={(text) => this.setState({ email: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your email"
            value={this.state.email}
          />
          <AccountInput
            maxWidth="100%"
            error={this.state.errors.password}
            onChange={(text) => this.setState({ password: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your password"
            value={this.state.password}
            type="password"
          />
          <AccountButton
            text="Sign up"
            onClick={this.signUp}
            isFetching={this.props.isFetching > 0}
          />
          <AccountLink
            margin="20px 0 0 0"
            normalText="Already have an account?"
            linkText="Login"
            onClick={() =>
              this.props.history.push(
                prebuilt ? `/login?prebuilt=${prebuilt}` : "/login"
              )
            }
          />
          {!isSupplierPortal && !prebuilt && !isGlobal && (
            <AccountLink
              margin="20px 0 0 0"
              normalText="Product supplier?"
              linkText="Sign up here"
              onClick={() => this.props.history.push("/apply/supplier")}
            />
          )}
        </Form>
        {!isSupplierPortal && !prebuilt && <LoginOptions />}
      </Account>
    );
  }
}

Signup.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
