import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { Row, Column } from "../../components";
import Text from "../../modules/Text";
import { withRouter } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { get } from "lodash";

const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px;
`;

const PremiumBanner = styled.div`
  background: ${theme.colors.main};
  color: white;
  position: fixed;
  top: 5px;
  right: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  font-size: 13px;
`;

const SoldBanner = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const Image = styled.img`
  height: 220px;
  width: 331px;
  border-radius: 4px;
  @media (max-width: 1300px) {
    height: 200px;
    width: 300px;
  }
  @media (max-width: 800px) {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
  ${(p) =>
    p.small &&
    `
    height: 200px;
    width: 300px;
    object-fit: cover;
  `}
`;

const StoreName = styled.div`
  font-size: 18px;
  font-weight: 600;
  ${(p) => p.sale && `color: ${theme.colors.main};`}
  ${(p) => p.slashed && "text-decoration: line-through; margin-right: 5px; "}
`;

class StoreCard extends Component {
  state = { hover: false, showDetails: false, showPassword: false };

  componentDidMount = () => {
    const showDetails = get(this.props, ["showDetails"]);
    if (showDetails) {
      this.setState({ showDetails: true });
    }
  };

  render() {
    const { storeData, onClick, small, onSale, defaultPrice } = this.props;

    return (
      <StoreContainer onClick={onClick}>
        {this.state.showPremium && (
          <Modal
            hide={() => this.setState({ showPremium: false })}
            maxWidth="800px"
          >
            <Text.Large bold margin="0 0 30px 0">
              Buy A Premium Prebuilt Store
            </Text.Large>
            <Text light> - Comes with branded .com domain and logo</Text>
            <Text light>
              - Professional Template, filled with bestselling products
            </Text>
            <Text light> - Basic Shopify apps installed</Text>
            <Text light>
              - Asset folder filled with pro designed vectors, logos, favicons,
              and graphics
            </Text>
            <Text light>
              - Pages: Contact page and return policy pages included
            </Text>
            <Text light>
              - One-off store (no duplicates of this store will ever be sold)
            </Text>
            <Text margin="30px 0 0 0">
              Email support@dropcommerce.com to arrange the purchase of this
              store
            </Text>
          </Modal>
        )}
        <React.Fragment>
          <ImageWrapper>
            {storeData.sold && (
              <SoldBanner>
                <Text.Large white bold>
                  Sold
                </Text.Large>
                <Text.Small light white center margin="10px 0 0 0">
                  Want something similar? Contact support
                </Text.Small>
              </SoldBanner>
            )}
            {storeData.premium && <PremiumBanner>Premium</PremiumBanner>}
            <Image src={storeData.image} small={small} />
          </ImageWrapper>
        </React.Fragment>
        <Row style={{ justifyContent: "space-between" }}>
          <StoreName>{storeData.name}</StoreName>
          <Column>
            <StoreName sale={onSale}>
              ${(storeData.price && storeData.price.toFixed(2)) || defaultPrice}
            </StoreName>
          </Column>
        </Row>
      </StoreContainer>
    );
  }
}

export default withRouter(StoreCard);
