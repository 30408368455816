import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { Row, Input, Image, Spinner } from "../../../components";
import Text from "../../../modules/Text";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";

const CommitmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 4px;
`;

const StyledText = styled(Text.Small)`
  width: 80px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

class Commitment extends Component {
  render() {
    const { data } = this.props;

    if (data.isFetching) {
      return (
        <CommitmentWrapper>
          <Spinner />
        </CommitmentWrapper>
      );
    }

    return (
      <CommitmentWrapper>
        <Row align>
          <Image src={data.image} margin="0 20px 0 0" />
          <StyledText light style={{ width: "180px" }}>
            {data.product_title}
          </StyledText>
          <StyledText light style={{ width: "150px" }}>
            {data.variant_name}
          </StyledText>
          <StyledText light style={{ width: "140px" }}>
            ${parseFloat(data.price).toFixed(2)}
          </StyledText>

          <Row align style={{ width: "150px" }}>
            {!this.props.confirm && !this.props.submitted && (
              <FiMinusCircle
                size="20"
                color={theme.colors.medGrey}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  data.quantity > 0 &&
                  this.props.updateQuantity(parseInt(data.quantity, 10) - 1)
                }
              />
            )}

            {this.props.confirm || this.props.submitted ? (
              <StyledText light style={{ width: "50px" }}>
                {data.quantity}
              </StyledText>
            ) : (
              <div style={{ marginLeft: "3px", marginRight: "3px" }}>
                <Input
                  width="40px"
                  value={data.quantity}
                  disabled={this.props.confirm || this.props.submitted}
                  extraStyle="text-align: center; padding: 8px;"
                  onChange={(e) => this.props.updateQuantity(e.target.value)}
                />
              </div>
            )}
            {!this.props.confirm && !this.props.submitted && (
              <FiPlusCircle
                size="20"
                color={theme.colors.medGrey}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.updateQuantity(parseInt(data.quantity, 10) + 1)
                }
              />
            )}
          </Row>
          <StyledText>${(data.price * data.quantity).toFixed(2)}</StyledText>
          {!this.props.confirm && !this.props.submitted && (
            <FiXCircle
              size="25"
              color={theme.colors.medLightGrey}
              onClick={() => this.props.delete(data.id)}
              style={{ cursor: "pointer", marginLeft: "20px" }}
            />
          )}
        </Row>
      </CommitmentWrapper>
    );
  }
}

export default withRouter(Commitment);
