import React from "react";
import Account from "./account/components/AccountComponents";

function Thanks() {
  return (
    <React.Fragment>
      <Account
        title={"Thank you!"}
        subtitle={"Please check your email to complete registration."}
      />
    </React.Fragment>
  );
}

export default Thanks;
