import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { saveSetup } from "../../Actions";
import { getIsFetching, getSettings } from "../../Selectors";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { errorNotification } from "../../modules/Notification";
import Account, {
  AccountButton,
} from "../../views/account/components/AccountComponents";
import { getDefaultStoreRoute } from "../../modules/Format";

const SurveyCard = styled.div`
  border: ${(p) => (p.selected ? "4px" : "1px")} solid
    ${(p) => (p.selected ? theme.colors.main : theme.colors.lightBorder)};
  text-align: center;
  margin: ${(p) => (p.selected ? "0px" : "3px")};
  background: white;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const SurveyCardName = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 10px;

  @media (max-width: 800px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const SurveyCardImage = styled.img`
  object-fit: cover;
  height: 120px;
  width: 100%;
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`;

const OptionSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  padding: 30px;

  @media (max-width: 800px) {
    padding: 5px;
    padding-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ saveSetup }, dispatch),
});

class Setup extends Component {
  selectOption = (value) => {
    const selectedValues = this.props.categories
      .filter((cat) => cat.selected)
      .map((cat) => cat.value);

    if (!selectedValues.includes(value) && selectedValues.length >= 5) {
      errorNotification("Please select up to 5 top categories only.");
      return;
    }

    this.props.handleChange(value);
  };

  saveCategories = () => {
    const selectedValues = this.props.categories
      .filter((cat) => cat.selected)
      .map((cat) => cat.value);

    // async
    this.props
      .saveSetup(selectedValues)
      .then(() => this.props.history.push(getDefaultStoreRoute));
  };

  render() {
    const categories = this.props.categories;

    const selectedCategories = categories.filter((cat) => cat.selected);

    return (
      <Account
        title="Pick up to 5 favorite categories"
        subtitle="You aren't limited to these categories, this just helps us show you relevant products."
        isFetching={this.props.isFetching > 0}
        position="static"
      >
        <MainSection>
          {selectedCategories.length > 0 && (
            <AccountButton
              text="COMPLETE SETUP"
              onClick={this.saveCategories}
            />
          )}
          <OptionSelectWrapper>
            {categories.map((choice, i) => (
              <SurveyCard
                key={i}
                onClick={() => this.selectOption(choice.value)}
                selected={choice.selected}
              >
                <SurveyCardImage
                  draggable={false}
                  alt={choice.name}
                  src={choice.image}
                />
                <SurveyCardName>{choice.name}</SurveyCardName>
              </SurveyCard>
            ))}
          </OptionSelectWrapper>
        </MainSection>
      </Account>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
