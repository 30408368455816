import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  countries,
  stateCodeMap,
  countryCodeMap,
  allStates,
} from "../../../../modules/Config";
import { updateStateDirectly } from "../../../../Actions";
import { getSettings, getIsFetchingSettings } from "../../../../Selectors";
import { Form, Spinner } from "../../../../components";
import {
  SettingsPageWrapper,
  SettingsSubWrapper,
} from "../../../../views/settings/SettingsStyle";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateStateDirectly,
    },
    dispatch
  ),
});

class ShippingAddress extends Component {
  updateAddress = (field, value) => {
    this.props.updateStateDirectly([
      { key: "settings" },
      { key: "store" },
      {
        key: "shipping_address",
        fields: [[field, value]],
      },
    ]);
  };

  render() {
    const address = get(this.props, ["settings", "store", "shipping_address"]);
    console.log(this.props.settings);
    const isValidProvinceCountry = ["US", "CA"].includes(
      get(address, "country_code")
    );

    const items = [
      {
        type: "input",
        label: "First Name",
        key: "first_name",
        validation: (value) =>
          value.length < 2 && "Please enter a valid First Name",
      },
      {
        type: "input",
        label: "Last Name",
        key: "last_name",
        validation: (value) =>
          value.length < 2 && "Please enter a valid Last Name",
      },
      {
        type: "input",
        label: "Address 1",
        key: "address1",
        validation: (value) =>
          value.length < 2 ||
          (!/\d/.test(value) && "Please enter a valid Address"),
      },
      {
        type: "input",
        label: "Address 2",
        key: "address2",
      },
      {
        type: "select",
        label: "Country",
        key: "country_code",
        selectOptions: countries,
        labelMap: countryCodeMap,
      },
      {
        type: "select",
        label: "Province",
        key: "province_code",
        displayRequirement: isValidProvinceCountry,
        selectOptions: allStates,
        labelMap: stateCodeMap,
      },
      {
        type: "input",
        label: "Province",
        key: "province",
        displayRequirement: !isValidProvinceCountry,
      },
      {
        type: "input",
        label: "City",
        key: "city",
        validation: (value) => value.length < 2 && "Please enter a valid City",
      },
      {
        type: "input",
        label: "Zip / Postal Code",
        key: "zip",
        validation: (value) =>
          value.length < 2 && "Please enter a valid Zip / Postal Code",
      },
      {
        type: "input",
        label: "Company",
        key: "company",
      },
    ];

    return (
      <SettingsPageWrapper>
        <SettingsSubWrapper>
          {this.props.isFetching ? (
            <Spinner />
          ) : (
            <Form
              width="auto"
              title="Address Details"
              items={items}
              labelWidth="180px"
              flex={true}
              onChange={(field, value) => this.updateAddress(field, value)}
              action={{
                type: "POST",
                endpoint: "/addresses/",
              }}
              stateLocation={address}
            />
          )}
        </SettingsSubWrapper>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddress)
);
