import {
  FETCH_AFFILIATE_REPORT_RES,
  GENERIC_REQ,
  GENERIC_RES,
  GENERIC_ERR,
} from "./AffiliateActions";

const initialState = {
  isFetching: false,
  affiliateReport: [],
  error: {},
};

const affiliate = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AFFILIATE_REPORT_RES:
      return {
        ...state,
        isFetching: false,
        affiliateReport: action.payload,
      };

    case GENERIC_REQ:
      return {
        ...state,
        isFetching: true,
      };

    case GENERIC_RES:
      return {
        ...state,
        isFetching: false,
      };

    case GENERIC_ERR:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default affiliate;
