import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import {
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { history } from "../../../BaseApp";
import Pagination from "@material-ui/lab/Pagination";
import { MdSignalWifi0Bar, MdSignalWifi4Bar } from "react-icons/md";
import { StyledInput } from "../../../components/Input";
import moment from "moment";
import Supplier from "../components/Supplier";
import { Modal } from "../../../components/Modal";

/*
 * Styling
 */
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: { backgroundColor: "rgba(0, 0, 0, 0.01)" },
}))(TableRow);

/*
 * Renders the Supplier table in the Admin Dashboard
 * API Endpoint: /api/admin/suppliers
 * URL: /admin/suppliers
 * */
export default function AdminSuppliersTable() {
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState("ALL");
  const [detailData, setDetailData] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);

  const DCHeaders = {
    "Content-Type": "application/json",
    Authorization: `Token ${Cookies.get("adminAccessToken")}`,
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setDataFetched(false);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", value);
    const queryString = urlParams.toString();
    const endpoint = `${api}/api/admin/suppliers/?${queryString}`;
    fetch(endpoint, { method: "GET", headers: DCHeaders }).then(
      async (response) => {
        const r = await response.json();
        setData(r);
        setDataFetched(true);
      }
    );
    history.push(`?${queryString}`);
  };

  const handleStatusChange = (event, object) => {
    const status = event.target.value || "ONBOARDING";
    setDataFetched(false);
    const endpoint = `${api}/api/admin/suppliers/?status=${status}`;
    fetch(endpoint, { method: "GET", headers: DCHeaders }).then(
      async (response) => {
        const r = await response.json();
        setStatus(status);
        setData(r);
        setDataFetched(true);
        history.push(`?status=${status}`);
      }
    );
  };

  const handleSearch = (event, object) => {
    const searchTerm = event.target.value;
    setDataFetched(false);
    const endpoint = `${api}/api/admin/suppliers/?search=${searchTerm}`;
    fetch(endpoint, { method: "GET", headers: DCHeaders }).then(
      async (response) => {
        const r = await response.json();
        setData(r);
        setDataFetched(true);
      }
    );
    history.push(`?search=${searchTerm}`);
  };

  const viewDetails = (supplierId) => {
    setDataFetched(false);
    const endpoint = `${api}/api/admin/suppliers/${supplierId}`;
    fetch(endpoint, { method: "GET", headers: DCHeaders }).then(
      async (response) => {
        const r = await response.json();
        setDetailData(r);
        setDataFetched(true);
        setShowModal(true);
      }
    );
  };

  useEffect(() => {
    setDataFetched(false);
    const urlParams = new URLSearchParams(window.location.search);
    const queryString = urlParams.toString();
    const endpoint = `${api}/api/admin/suppliers/?${queryString}`;
    fetch(endpoint, { method: "GET", headers: DCHeaders })
      .then(async (response) => {
        const r = await response.json();
        setData(r);
        setDataFetched(true);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <h2>Our Suppliers</h2>
      <StyledInput
        type="text"
        placeholder="Search Suppliers by ID, Name, status or email"
        onKeyUp={handleSearch}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination
          shape="rounded"
          count={25}
          page={page}
          size="small"
          showFirstButton
          showLastButton
          onChange={handlePageChange}
          siblingCount={0}
          boundaryCount={2}
          style={{ padding: "0px 50px" }}
        />
        <Typography style={{ padding: "0px 10px" }}>Status:</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleStatusChange}
        >
          <MenuItem value={"ALL"}>All</MenuItem>
          <MenuItem value={"PENDING"}>Pending Approval</MenuItem>
          <MenuItem value={"ONBOARDING"}>On-boarding</MenuItem>
          <MenuItem value={"HIDDEN"}>Hidden</MenuItem>
          <MenuItem value={"NO_PLATFORM"}>Platform Disconnected</MenuItem>
          <MenuItem value={"NO_PAYMENT_METHOD"}>
            Payment Method Disconnected
          </MenuItem>
          <MenuItem value={"ON_HOLIDAYS"}>On Holidays</MenuItem>
          <MenuItem value={"LOW_SCORE"}>Low Supplier Score</MenuItem>
          <MenuItem value={"REJECTED"}>Rejected During Sign Up</MenuItem>
          <MenuItem value={"REMOVED"}>Removed</MenuItem>
          <MenuItem value={"PRIVATE"}>Private</MenuItem>
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
        </Select>
      </div>
      <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Connected</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Issues</StyledTableCell>
              <StyledTableCell>Notes</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.results &&
              data.results.map((datum) => (
                <StyledTableRow key={datum.id}>
                  <StyledTableCell>{datum.id}</StyledTableCell>
                  <StyledTableCell>{datum.name}</StyledTableCell>
                  <StyledTableCell>
                    {datum.shopify_url ? (
                      <MdSignalWifi4Bar />
                    ) : (
                      <MdSignalWifi0Bar />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{datum.status}</StyledTableCell>
                  <StyledTableCell>
                    {moment(datum.created_at).format("MMM Do YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>{datum.issues}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant={"contained"}
                      onClick={() => viewDetails(datum.id)}
                    >
                      Details
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop
        style={{
          color: "#fff",
          background: "#ccc",
          position: "absolute",
          zIndex: 100,
          opacity: 0.4,
        }}
        open={!dataFetched}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {showModal && (
        <Modal hide={() => setShowModal(false)}>
          <Supplier supplier={detailData} manageSupplier={true} />
        </Modal>
      )}
    </React.Fragment>
  );
}
