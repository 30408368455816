import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container } from "../../../components/Container";
import Card from "../../../components/Card";
import ProductCardInside from "../../../views/products/components/ProductCardInside";
import Pagination from "../../../components/Pagination";

import {
  getUrlParameter,
  objectToQueryString,
  truncateText,
} from "../../../modules/Format";
import {
  clearFilters,
  initializeFilters,
  updateFilters,
} from "../../../Actions";

import { fetchSupplier } from "../../../views/products/ProductsActions";
import { getFilters, getSettings } from "../../../Selectors";
import {
  getIsFetchingSupplier,
  getPagination,
  getProducts,
  getSupplier,
} from "../../../views/products/ProductsSelectors";

import { ProductsRow } from "../../../components/Layout";
import BackNavBar from "../../../components/BackNavBar";
import styled from "styled-components";
import Range from "../../../components/Range";
import Search from "../../../components/Search";
import { get } from "lodash";
import Text from "../../../modules/Text";
import NotFound from "../../../components/NotFound";
import { SupplierBanner } from "./components/SupplierBanner";
import { addQuantityPropertyToProductObject } from "../../../utils";
import { useEffectOnce, useUpdateEffect } from "../../../hooks";

const FilterSearchRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    margin-bottom: 5px;
  }
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingSupplier(state),
  supplier: getSupplier(state),
  settings: getSettings(state),
  products: getProducts(state),
  pagination: getPagination(state),
  filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchSupplier,
      updateFilters,
      initializeFilters,
      clearFilters,
    },
    dispatch
  ),
});

const defaultProps = {
  isFetching: false,
  supplier: {},
};

const ranges = [
  { name: "Price", type: "price" },
  { name: "Profit", type: "profit" },
  { name: "Shipping Rate", type: "shipping" },
];

function Supplier(props) {
  useEffectOnce(() => {
    props.initializeFilters(props.filters, props.location).then((response) => {
      fetchSupplier(response, false);
    });
  });

  useUpdateEffect(() => {
    fetchSupplier(props.filters);
  }, [props.filters.page]);

  const fetchSupplier = (filterData = null, updateUrl = true) => {
    const supplierId = props.match.params.id;

    let f = filterData || props.filters;
    const data = {
      id: supplierId,
      page: f.page,
      search: f.search,
      price_min: f.price.min,
      price_max: f.price.max,
      price_type: f.price.optionsValue,
      profit_min: f.profit.min,
      shipping_max: f.shipping.max,
      shipping_country: f.shipping.optionsValue,
    };

    const queryString = objectToQueryString(data);

    props.fetchSupplier(queryString);

    if (updateUrl) {
      props.history.push(`/supplier-id/${supplierId}${queryString}`);
    }
  };

  let supplier = props.supplier;

  let search = getUrlParameter("search", props.location) || "";

  const products = props.products.filter(
    (p) => get(p, ["images", "length"]) > 0
  );

  const privatePortal = get(props, ["settings", "store", "private_portal"]);

  return (
    <Container>
      {!privatePortal && (
        <BackNavBar title="BACK" onClick={() => props.history.goBack()} />
      )}
      <React.Fragment>
        {props.isFetching ? (
          <ProductsRow>
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
            <Card.Skeleton />
          </ProductsRow>
        ) : (
          <div style={{ width: "100%" }}>
            {supplier && (
              <SupplierBanner
                store={props.settings.store}
                supplier={supplier}
              />
            )}

            <FilterSearchRow>
              <Search
                onChange={(term) => props.updateFilters("search", term)}
                onEnterPress={(e) => e.key === "Enter" && fetchSupplier()}
                search={props.filters.search}
                clear={() =>
                  props.updateFilters("search", "").then(fetchSupplier)
                }
                initialSearchTerm={search}
                searchForProducts={() => fetchSupplier()}
              />

              <FiltersRow>
                {ranges.map((range, index) => (
                  <Range key={index} range={range} submit={fetchSupplier} />
                ))}
              </FiltersRow>
            </FilterSearchRow>

            {products.some((prod) => prod.featured) && (
              <div>
                <Text.Large light margin="0px 0px 10px 0px">
                  Featured Products
                </Text.Large>

                <ProductsRow>
                  {products
                    .filter((product) => product.featured)
                    .map((p) => {
                      const product = addQuantityPropertyToProductObject(p);

                      if (!product.quantity) return null;

                      return (
                        <Card
                          key={product.id}
                          image={product.images[0].url}
                          imageFit={supplier.image_fit}
                          name={truncateText(product.title, 25)}
                          subtext={supplier.name}
                          description={`$${product.price.toFixed(2)}`}
                          descriptionColor="orange"
                          onClick={() =>
                            props.history.push(`/product/${product.id}`)
                          }
                        >
                          <ProductCardInside
                            supplier={supplier}
                            product={product}
                            image={product.images[0].url}
                            showApprovalRequirements={
                              props.showApprovalRequirements
                            }
                          />
                        </Card>
                      );
                    })}
                </ProductsRow>

                <Text
                  margin="10px 0px 10px 0px;"
                  extra="padding-top: 20px; border-top: 1px solid #dae7e5;"
                >
                  All Products
                </Text>
              </div>
            )}

            {supplier && supplier.name && (
              <ProductsRow>
                {products.map((p) => {
                  const product = addQuantityPropertyToProductObject(p);

                  if (!product.quantity) return null;

                  return (
                    <Card
                      key={product.id}
                      image={product.images[0].url}
                      imageFit={supplier.image_fit}
                      name={truncateText(product.title, 25)}
                      subtext={supplier.name}
                      description={`$${product.price.toFixed(2)}`}
                      descriptionColor="orange"
                      onClick={() =>
                        props.history.push(`/product/${product.id}`)
                      }
                    >
                      <ProductCardInside
                        supplier={supplier}
                        product={product}
                        image={product.images[0].url}
                        showApprovalRequirements={
                          props.showApprovalRequirements
                        }
                      />
                    </Card>
                  );
                })}
              </ProductsRow>
            )}

            {(!supplier || !supplier.name) && <NotFound />}

            <Pagination
              onClick={(pageNumber) => props.updateFilters("page", pageNumber)}
              pagination={props.pagination}
            />
          </div>
        )}
      </React.Fragment>
    </Container>
  );
}

Supplier.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
