import Cookies from "js-cookie";
import { api } from "../../modules/Config";
import moment from "moment";
import { checkStatus } from "../../utils/index";
import { getRequest } from "../../modules/API";
import { trackEvent } from "../../modules/EventTracking";

const type = "supplier";

export const RESET_SUPPLIER_SETTINGS = `${type}/RESET_SUPPLIER_SETTINGS`;
export const GENERIC_REQ = `${type}/GENERIC_REQ`;
export const GENERIC_RES = `${type}/GENERIC_RES`;
export const GENERIC_ERR = `${type}/GENERIC_ERR`;
export const FETCH_HAS_UNREAD_MESSAGE = `${type}/FETCH_HAS_UNREAD_MESSAGE`;
export const FETCH_SUPPLIER_ORDER_REQ = `${type}/FETCH_SUPPLIER_ORDER_REQ`;
export const FETCH_SUPPLIER_ORDER_RES = `${type}/FETCH_SUPPLIER_ORDER_RES`;
export const SAVE_SUPPLIER_PRODUCT_RES = `${type}/SAVE_SUPPLIER_PRODUCT_RES`;
export const SAVE_SUPPLIER_PRODUCT_REQ = `${type}/SAVE_SUPPLIER_PRODUCT_REQ`;
export const SET_SUPPLIER_PRODUCT_SAVED_RES = `${type}/SET_SUPPLIER_PRODUCT_SAVED_RES`;
export const FETCH_SUPPLIER_PRODUCTS_RES = `${type}/FETCH_SUPPLIER_PRODUCTS_RES`;
export const FETCH_SUPPLIER_ORDERS_RES = `${type}/FETCH_SUPPLIER_ORDERS_RES`;
export const FETCH_VENDORS_RES = `${type}/FETCH_VENDORS_RES`;
export const FETCH_VENDOR_RES = `${type}/FETCH_VENDOR_RES`;
export const FETCH_TAGS_RES = `${type}/FETCH_TAGS_RES`;
export const UPDATE_VENDOR_APPROVAL_STATUS_REQ = `${type}/UPDATE_VENDOR_APPROVAL_STATUS_REQ`;
export const UPDATE_VENDOR_APPROVAL_STATUS_RES = `${type}/UPDATE_VENDOR_APPROVAL_STATUS_RES`;
export const UPDATE_VENDOR_APPROVAL_STATUS_ERR = `${type}/UPDATE_VENDOR_APPROVAL_STATUS_ERR`;
export const MARK_ORDER_AS_SHIPPED_REQ = `${type}/MARK_ORDER_AS_SHIPPED_REQ`;
export const MARK_ORDER_AS_SHIPPED_RES = `${type}/MARK_ORDER_AS_SHIPPED_RES`;
export const MARK_ORDER_AS_SHIPPED_ERR = `${type}/MARK_ORDER_AS_SHIPPED_ERR`;
export const ACTIVATE_PRODUCT_REQ = `${type}/ACTIVATE_PRODUCT_REQ`;
export const ACTIVATE_PRODUCT_RES = `${type}/ACTIVATE_PRODUCT_RES`;
export const ACTIVATE_PRODUCT_ERR = `${type}/ACTIVATE_PRODUCT_ERR`;
export const ACTIVATE_SHIPPING_OPTION_RES = `${type}/ACTIVATE_SHIPPING_OPTION_RES`;
export const SET_FEATURE_IMAGE_REQ = `${type}/SET_FEATURE_IMAGE_REQ`;
export const SET_FEATURE_IMAGE_RES = `${type}/SET_FEATURE_IMAGE_RES`;
export const SET_FEATURE_IMAGE_ERR = `${type}/SET_FEATURE_IMAGE_ERR`;
export const UPDATE_SUPPLIER_PRODUCT_REQ = `${type}/UPDATE_SUPPLIER_PRODUCT_REQ`;
export const UPDATE_SUPPLIER_PRODUCT_RES = `${type}/UPDATE_SUPPLIER_PRODUCT_RES`;
export const UPDATE_SUPPLIER_PRODUCT_ERR = `${type}/UPDATE_SUPPLIER_PRODUCT_ERR`;
export const UPDATE_STATE = `${type}/UPDATE_STATE`;
export const UPDATE_SUPPLIER = `${type}/UPDATE_SUPPLIER`;
// export const UPDATE_PRODUCT_FIELD = `${type}/UPDATE_PRODUCT_FIELD`;
export const UPDATE_VARIANT_RELATION_FIELD = `${type}/UPDATE_VARIANT_RELATION_FIELD`;
export const DELETE_VARIANT = `${type}/DELETE_VARIANT`;
export const ADD_VARIANT = `${type}/ADD_VARIANT`;
export const UPDATE_TAG = `${type}/UPDATE_TAG`;
export const UPDATE_ALL = `${type}/UPDATE_ALL`;
export const SAVE_TAGS_RES = `${type}/SAVE_TAGS_RES`;
export const SELECT_FEATURE_IMAGE = `${type}/SELECT_FEATURE_IMAGE`;
export const UPDATE_SUPPLIER_LOGO = `${type}/UPDATE_SUPPLIER_LOGO`;
export const FETCH_SHIPPING_OPTIONS_REQ = `${type}/FETCH_SHIPPING_OPTIONS_REQ`;
export const FETCH_SHIPPING_OPTIONS_RES = `${type}/FETCH_SHIPPING_OPTIONS_RES`;
export const FETCH_SHIPPING_OPTIONS_ERR = `${type}/FETCH_SHIPPING_OPTIONS_ERR`;
export const CREATE_SHIPPING_OPTION_REQ = `${type}/CREATE_SHIPPING_OPTION_REQ`;
export const CREATE_SHIPPING_OPTION_RES = `${type}/CREATE_SHIPPING_OPTION_RES`;
export const CREATE_SHIPPING_OPTION_ERR = `${type}/CREATE_SHIPPING_OPTION_ERR`;
export const UPDATE_SHIPPING_OPTION_REQ = `${type}/UPDATE_SHIPPING_OPTION_REQ`;
export const UPDATE_SHIPPING_OPTION_RES = `${type}/UPDATE_SHIPPING_OPTION_RES`;
export const UPDATE_SHIPPING_OPTION_ERR = `${type}/UPDATE_SHIPPING_OPTION_ERR`;
export const SET_SHIPPING_OPTION_ACTIVE_RES = `${type}/SET_SHIPPING_OPTION_ACTIVE_RES`;
export const UPDATE_SHIPPING_OPTION_FIELD = `${type}/UPDATE_SHIPPING_OPTION_FIELD`;
export const CLEAR_SHIPPING_OPTION = `${type}/CLEAR_SHIPPING_OPTION`;
export const MESSAGE_READ = `${type}/MESSAGE_READ`;
export const ANSWER_REFUND_RES = `${type}/ANSWER_REFUND_RES`;
export const ANSWER_REFUND_REQ = `${type}/ANSWER_REFUND_REQ`;
export const ANSWER_REFUND_ERR = `${type}/ANSWER_REFUND_ERR`;

export const fetchSupplierUnreadMessage = () => (dispatch) =>
  new Promise((resolve) => {
    getRequest({ url: "has-unread-messages/" }).then((res) => {
      resolve(res);
      dispatch({
        type: "supplier/FETCH_HAS_UNREAD_MESSAGE",
        payload: res.data.hasUnreadMessage,
      });
    });
  });

export const updateAll = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ALL,
    payload: data,
  });
};

export const addVendorToList = (vendorData) => (dispatch) => {
  dispatch({
    type: "supplier/ADD_VENDOR",
    payload: vendorData,
  });
};

export const clearShippingOption = (productId) => (dispatch) => {
  dispatch({
    type: CLEAR_SHIPPING_OPTION,
    payload: { productId },
  });
};

export const updateShippingOptionField = (field, value) => (dispatch) => {
  dispatch({
    type: UPDATE_SHIPPING_OPTION_FIELD,
    payload: { field, value },
  });
};

export const updateTag = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TAG,
    payload: data,
  });
};

export const updateSupplier = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SUPPLIER,
    payload: data,
  });
};

export const updateState = (dispatch, data) => {
  dispatch({
    type: UPDATE_STATE,
    payload: data,
  });
};

export const fetchSupplierSettings = () => (dispatch) =>
  new Promise((resolve) => {
    updateState(dispatch, [{ fields: [["isFetchingSettings", true]] }]);

    fetch(`${api}/supplier_settings/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        const localhost = window.location.host.includes("localhost");

        if (!localhost && response.supplier.username !== "patrick") {
          window.Intercom("boot", {
            app: window.location.href.includes("global")
              ? "GLOBAL"
              : "DROPCOMMERCE",
            account_type: "Supplier",
            supplier_id: response.supplier.id,
            app_id: "ulrlc7kx",
            email: response.supplier.email,
            notification_email: response.supplier.notification_email,
            dropshipping: response.supplier.dropshipping,
          });
        }

        resolve(response);

        updateState(dispatch, [
          {
            fields: [
              ["isFetchingSettings", false],
              ["settings", response.supplier],
            ],
          },
        ]);
      })
      .catch(() => {
        updateState(dispatch, [
          {
            fields: [
              ["isFetchingSettings", false],
              ["error", "Error retrieving settings"],
            ],
          },
        ]);
      });
  });

export const saveSupplierSettings = (data) => (dispatch) =>
  new Promise((resolve) => {
    fetch(`${api}/supplier_settings/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((responseData) => {
        if (data.logo) {
          dispatch({
            type: UPDATE_SUPPLIER_LOGO,
            payload: { logo: data.logo },
          });
        }

        resolve();
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error saving info" },
        });
      });
  });

export const moveBoxItem =
  (boxIndex, itemId, newBox, quantity) => (dispatch) => {
    dispatch({
      type: "supplier/MOVE_BOX_ITEM",
      payload: { boxIndex, itemId, newBox, quantity },
    });
  };

export const setShippingOptionActive = (bool, optionId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GENERIC_REQ,
    });

    const type = bool ? "activate" : "deactivate";

    fetch(`${api}/api/shipping-options/${optionId}/${type}/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        if (!data.error && !data.errors) {
          dispatch({
            type: SET_SHIPPING_OPTION_ACTIVE_RES,
            payload: bool,
          });
        } else {
          dispatch({
            type: SET_SHIPPING_OPTION_ACTIVE_RES,
            payload: !bool,
          });
        }
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: {
            error:
              "Error updating shipping option. Please contact support at support@dropcommerce.com",
          },
        });
      });
  });

export const updateShippingOption = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_SHIPPING_OPTION_REQ,
    });
    fetch(`${api}/api/shipping-options/${data.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: UPDATE_SHIPPING_OPTION_RES,
        });
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: UPDATE_SHIPPING_OPTION_ERR,
          payload: {
            error:
              "Error creating shipping option. Please contact support at support@dropcommerce.com",
          },
        });
      });
  });

export const createShippingOption = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/api/shipping-options/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);

        if (response.id) {
          dispatch({
            type: CREATE_SHIPPING_OPTION_RES,
            payload: response,
          });
        } else {
          dispatch({
            type: GENERIC_ERR,
            payload: {
              error:
                "Error creating shipping option. Please contact support at support@dropcommerce.com",
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: {
            error:
              "Error creating shipping option. Please contact support at support@dropcommerce.com",
          },
        });
      });
  });

export const fetchShippingOptions =
  (productId = null) =>
  (dispatch) => {
    dispatch({
      type: FETCH_SHIPPING_OPTIONS_REQ,
    });

    const endpoint = productId
      ? `${api}/api/shipping-options/?product-id=${productId}`
      : `${api}/api/shipping-options/?supplier-only=1`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: FETCH_SHIPPING_OPTIONS_RES,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_SHIPPING_OPTIONS_ERR,
          payload: {
            error:
              "Error retrieving options. Please contact support at support@dropcommerce.com",
          },
        });
      });
  };

export const setSupplierProductSaved = (bool) => (dispatch) => {
  dispatch({
    type: SET_SUPPLIER_PRODUCT_SAVED_RES,
    payload: { supplierProductSaved: bool },
  });
};

export const createNewProduct = (title) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/new_product/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve({ productId: response.product_id });

        dispatch({
          type: GENERIC_RES,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error saving product. Please contact support." },
        });
      });
  });

// OLD VERSION, RENAME TO UPDATE
export const createProduct = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_SUPPLIER_PRODUCT_REQ,
      payload: { product: data },
    });
    fetch(`${api}/create_product/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve({ productId: response.product_id });

        dispatch({
          type: UPDATE_SUPPLIER_PRODUCT_RES,
          payload: {
            product: response.product,
          },
        });
      })
      .catch(() => {
        reject("There was an error.");
        dispatch({
          type: UPDATE_SUPPLIER_PRODUCT_ERR,
          payload: { error: "Error saving product. Please contact support." },
        });
      });
  });

export const saveSupplierProduct = (supplierProduct) => (dispatch) => {
  dispatch({
    type: SAVE_SUPPLIER_PRODUCT_REQ,
    payload: { supplierProduct },
  });
  fetch(`${api}/save_supplier_product/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product: supplierProduct }),
  })
    .then((response) => checkStatus(response))
    .then(() => {
      trackEvent("Update Product");
      dispatch({
        type: SAVE_SUPPLIER_PRODUCT_RES,
        payload: { supplierProduct },
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error saving product" },
      });
    });
};

export const saveTags = (data) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/supplier_tags/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: SAVE_TAGS_RES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error saving tags" },
      });
    });
};

export const fetchAdminCategories = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/admin_categories/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "supplier/FETCH_ADMIN_CATEGORIES",
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error retrieving categories" },
      });
    });
};

export const fetchTags = (category) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/supplier_tags/?category=${category}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_TAGS_RES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error retrieving products" },
      });
    });
};

export const fetchSupplierProducts =
  (page = 1, search = "", tab = "active") =>
  (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(
      `${api}/supplier_products/?page=${page}&search=${search}&type=${tab}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("View Supplier Products");
        dispatch({
          type: FETCH_SUPPLIER_PRODUCTS_RES,
          payload: {
            products: response.products,
            pagination: response.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error retrieving products" },
        });
      });
  };

export const fetchSupplierOrders =
  (page = 1, search = "", tab = "paid") =>
  (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(
      `${api}/supplier_orders/?page=${page}&search=${search}&status=${tab}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("View Supplier Orders");
        dispatch({
          type: FETCH_SUPPLIER_ORDERS_RES,
          payload: {
            supplier_orders: response.supplier_orders,
            pagination: response.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error retrieving orders" },
        });
      });
  };

export const featureProduct = (product_id) => (dispatch) => {
  dispatch({ type: GENERIC_REQ });
  fetch(`${api}/supplier_products/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product_id: product_id }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("Mark as Featured");
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error retrieving products" },
      });
    });
};

export const updateVendorApprovalStatus =
  (vendorId, status, explanation = "") =>
  (dispatch) => {
    dispatch({
      type: UPDATE_VENDOR_APPROVAL_STATUS_REQ,
      payload: { vendorId },
    });
    fetch(`${api}/update_vendor_status/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        vendor_id: vendorId,
        status,
        explanation,
        date: moment(),
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (!response.error) {
          trackEvent(`Vendor Requets ${response ? "Approved" : "Denied"}`);
          dispatch({
            type: UPDATE_VENDOR_APPROVAL_STATUS_RES,
            payload: { vendorId, status },
          });
        } else {
          dispatch({
            type: UPDATE_VENDOR_APPROVAL_STATUS_ERR,
            payload: { error: response.error, vendorId },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_VENDOR_APPROVAL_STATUS_ERR,
          payload: { error, vendorId },
        });
      });
  };

export const fetchVendors = (page, type) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/vendors/?page=${page}&type=${type}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("View Vendors");
      dispatch({
        type: FETCH_VENDORS_RES,
        payload: { vendors: response.vendors },
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error fetching vendors" },
      });
    });
};

export const answerRefundRequest = (refundId, data) => (dispatch) =>
  new Promise((resolve) => {
    fetch(`${api}/api/refunds/${refundId}/answer/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (response.error) {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_ERR,
            payload: { error: response.error },
          });
        } else {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_RES,
            payload: response.refunds,
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: ANSWER_REFUND_ERR,
          payload: { error: response.error },
        });
      });
  });
