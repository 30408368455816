import React from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";

const Container = styled.div``;

const Bubble = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  ${(p) =>
    p.selected &&
    `
    border: 3px solid ${theme.colors.main}; 
    padding: 13px;
  `}
`;

const OptionLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const OptionDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
`;

export const OptionBubbles = ({ options }) => (
  <Container>
    {options.map((option, i) => (
      <Bubble selected={option.selected} onClick={option.onClick} key={i}>
        <OptionLabel>{option.label}</OptionLabel>
        <OptionDescription>{option.description}</OptionDescription>
      </Bubble>
    ))}
  </Container>
);
