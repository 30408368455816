import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";
import { Modal } from "./Modal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createRefundRequest } from "../views/products/ProductsActions";
import { getIsFetchingRefund } from "../views/products/ProductsSelectors";
import { Checkbox } from "@material-ui/core";
import Text from "../modules/Text";
import { Row } from "./Layout";
import Input from "./Input";
import {
  errorNotification,
  successNotification,
} from "../modules/Notification";

const mapStateToProps = (state) => ({
  isFetchingRefund: getIsFetchingRefund(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ createRefundRequest }, dispatch),
});

export const Title = styled.div`
  color: #265454;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const ErrorText = styled.div`
  color: ${theme.colors.darkRed};
  font-size: 13px;
  font-weight: 400;
`;

export const SupplierName = styled.div`
  /* margin-bottom: 10px; */
  color: #265454;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 6px;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 125px;
  max-width: 800px;
  margin-top: 5px;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const PolicyHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #265454;
`;

const ERRORS_INITIAL_STATE = {
  reason: "",
  readed: "",
  quantity: "",
};

class ModalRefund extends Component {
  state = {
    errors: ERRORS_INITIAL_STATE,
    reason: "",
    readed: false,
    quantity: 1,
    orderId: 0,
    supplierId: 0,
    isCreatingRefund: false,
  };

  isValid = (product) => {
    const errors = {};
    if (!this.state.readed) {
      errors["readed"] = "You must read and accept the refund policy";
    }
    if (this.state.reason.length < 10) {
      errors["reason"] = "Please enter a longer, more descriptive reason.";
    }
    if (this.state.quantity === 0) {
      errors["quantity"] = "Enter a value greater than zero";
    } else if (this.state.quantity > product.quantity) {
      errors["quantity"] = "You cannot ask for more than the quantity bought";
    }
    this.setState((prev) => ({ ...prev, errors }));
    return isEmpty(errors);
  };

  makeRefundRequest = (order, supplier, product) => {
    if (!this.isValid(product)) return;

    this.setState((prev) => ({ ...prev, isCreatingRefund: true }));
    this.props
      .createRefundRequest({
        order: order.id,
        item: product.id,
        variant_id: product.variant_id,
        supplier: supplier.id,
        store: order.store,
        quantity: this.state.quantity,
        reason: this.state.reason,
        image: "",
      })
      .then((response) => {
        if (response.error) {
          this.setState((prev) => ({
            ...prev,
            isCreatingRefund: false,
          }));
          errorNotification("Refund requested failed!");
          return;
        }

        this.props.hideModal();
        this.props.createRefund();
        successNotification("Refund requested successfully!");
      });
  };

  render() {
    const { order, supplier, product, hideModal } = this.props;

    return (
      <Modal hide={hideModal}>
        <Title>Refund Request - Order {order.id}</Title>

        <hr></hr>

        <SupplierName>
          {supplier.name.toUpperCase()} - Return Policy
        </SupplierName>

        <StyledTextArea value={supplier.return_policy} readOnly disabled />

        <Row>
          <Text
            light
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <Checkbox
              color="primary"
              style={{ padding: 0 }}
              onChange={(e) =>
                this.setState((prev) => ({
                  ...prev,
                  readed: e.target.checked,
                }))
              }
            />
            I have read the supplier's refund requirements, and my request
            follows their policy
          </Text>
        </Row>

        {this.state.errors.readed && (
          <ErrorText>{this.state.errors.readed}</ErrorText>
        )}

        <div style={{ marginBottom: "10px" }} />

        {product.quantity > 1 && (
          <Row>
            <Input
              value={this.state.quantity}
              type="number"
              min="1"
              onChange={(e) => {
                this.setState((prev) => ({
                  ...prev,
                  quantity: +e.target.value,
                }));
              }}
              width="200px"
              placeholder="Item quantity"
            />
          </Row>
        )}

        {this.state.errors.quantity && (
          <ErrorText>{this.state.errors.quantity}</ErrorText>
        )}

        <div style={{ marginBottom: "10px" }} />

        <PolicyHeader>
          Please explain the reason for the refund request:
        </PolicyHeader>

        <Row>
          <StyledTextArea
            onChange={(e) =>
              this.setState((prev) => ({ ...prev, reason: e.target.value }))
            }
          />
        </Row>

        {this.state.errors.reason && (
          <ErrorText>{this.state.errors.reason}</ErrorText>
        )}

        <ButtonNew
          type="main"
          text="Submit Request"
          onClick={() => this.makeRefundRequest(order, supplier, product)}
          isFetching={this.state.isCreatingRefund}
          extra="width: 200px; margin-top: 10px;"
        />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRefund);
