import React from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { MenuOption } from "./MenuOption";
import { OutsideAlerter } from "../OutsideAlerter";

const Container = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  &:hover {
    ${(props) => props.hover && `background: ${theme.colors.main};`}
  }
`;

const TitleContainer = styled("div")`
  font-size: 14px;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  color: white;
  margin-left: 10px;
  white-space: nowrap;
`;

const IconButton = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
`;

const MenuPopup = styled.div`
  position: absolute;
  top: 42px;
  right: 10px;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  ${(props) => props.extra}
  ${(props) =>
    props.optionsWidth
      ? `min-width: ${props.optionsWidth};`
      : "min-width: 200px;"}
`;

export function PopupMenu({
  popupMenuOptions,
  title,
  icon,
  optionsWidth,
  extra,
}) {
  const [showSettingsDropdown, showSettingsDropdownSet] = React.useState(false);

  const MainIcon = icon.element;

  return (
    <OutsideAlerter
      customId="menu-popup"
      onClickOutside={() => showSettingsDropdownSet(false)}
    >
      <Container
        hover={icon.hover}
        onClick={(evt) => {
          evt.stopPropagation();
          showSettingsDropdownSet(true);
        }}
      >
        {title && (
          <TitleContainer
            style={{
              marginTop: "10px",
            }}
          >
            {title}
          </TitleContainer>
        )}
        <IconButton>
          {showSettingsDropdown && (
            <MenuPopup optionsWidth={optionsWidth} extra={extra}>
              {popupMenuOptions.map((option, i) => {
                if (option.hide) return null;
                const Icon = option.icon;
                return (
                  <MenuOption
                    key={i}
                    icon={
                      option.icon ? (
                        <Icon size="22" color={theme.colors.medDarkGrey} />
                      ) : null
                    }
                    text={option.text}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      showSettingsDropdownSet(false);
                      option.onClick();
                    }}
                  />
                );
              })}
            </MenuPopup>
          )}
          <MainIcon size="22" color={icon.color} />
        </IconButton>
      </Container>
    </OutsideAlerter>
  );
}
