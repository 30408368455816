import Cookies from "js-cookie";
import React from "react";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SaleBanner = styled.div`
  background-image: url("https://dropcommerce-images.s3.ca-central-1.amazonaws.com/banner.jpeg");
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
  font-weight: 400;
  color: #fff;
`;

const SaleHeader = styled.div`
  color: #ab271b;
  font-weight: bold;
  padding: 0px 10px;
`;

const SaleInfo = styled.div`
  color: #3b4614;
  font-weight: normal;
`;

export const MemorialSaleBanner = ({ hide }) => (
  <SaleBanner>
    <SaleHeader>Holiday Sale</SaleHeader>
    <SaleInfo>
      Take advantage of savings on
      <Link
        to="/prebuilt"
        style={{
          textDecoration: "none",
          color: "#3b4614",
          margin: "0px 5px",
          fontWeight: "bold",
        }}
      >
        Prebuilt Stores
      </Link>
      and
      <Link
        to="/settings/plans"
        style={{
          textDecoration: "none",
          color: "#3b4614",
          margin: "0px 5px",
          fontWeight: "bold",
        }}
      >
        Annual Plans
      </Link>
    </SaleInfo>

    <FiX
      style={{ marginLeft: "2%", cursor: "pointer" }}
      onClick={() => {
        Cookies.set("dismissedSale", true, { expires: 1 });
        hide();
      }}
    />
  </SaleBanner>
);
