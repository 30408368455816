import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchAnalytics } from "../../../views/admin/AdminActions";
import { getAnalytics } from "../../../views/admin/AdminSelectors";
import { get, isEmpty } from "lodash";
import styled from "styled-components";
import { Spinner, Stat } from "../../../components";
import moment from "moment";
import { getUrlParameter } from "../../../modules/Format";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Text from "../../../modules/Text";
import {} from "react-icons/fi";
import { ChangeWrapper } from "../../../components/Stat";

export const Header = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 40px;
`;

export const StatsWrapper = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const StatRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ColorWrapper = styled.div``;

const mapStateToProps = (state) => ({
  analytics: getAnalytics(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchAnalytics }, dispatch),
});

class Analytics extends Component {
  UNSAFE_componentWillMount = () => {
    const password = getUrlParameter("p", this.props.location);
    this.props.fetchAnalytics(password);
  };

  render() {
    let analytics = get(this.props, ["analytics"]);

    if (isEmpty(analytics)) {
      return (
        <ColorWrapper>
          <StatsWrapper>
            <Spinner />
          </StatsWrapper>
        </ColorWrapper>
      );
    }

    return (
      <ColorWrapper>
        <StatsWrapper>
          <Header>Installs</Header>
          <StatRow>
            {analytics.installs.map((data, idx) => {
              return (
                <Stat
                  key={idx}
                  name={moment(data.date).format("MMM D")}
                  value={data.value}
                  direction={data.direction}
                  change={data.change}
                />
              );
            })}
          </StatRow>

          <Header>Conversions (last 90 days)</Header>
          <Text>
            Conversion Rate:{" "}
            {(
              (100 * analytics.conversions.paid) /
              analytics.conversions.total
            ).toFixed(2) + "%"}
          </Text>
          <StatRow>
            <Stat
              name={"New Stores"}
              value={analytics.conversions.total}
              change={null}
            />
            <ChangeWrapper>
              <span color="#49beaf"> -- </span>
            </ChangeWrapper>
            <Stat
              name={"Free Plan"}
              value={analytics.conversions.free}
              change={null}
            />
            <ChangeWrapper>
              <span color="#49beaf"> -- </span>
            </ChangeWrapper>
            <Stat
              name={"Still Active"}
              value={analytics.conversions.paid}
              change={null}
            />
          </StatRow>
          <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>New Plans</TableCell>
                  <TableCell>Still Active</TableCell>
                  <TableCell>Expired</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Basic</TableCell>
                  <TableCell>{analytics.conversions.basic}</TableCell>
                  <TableCell>{analytics.conversions.active_basic}</TableCell>
                  <TableCell>{analytics.conversions.cancelled_basic}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Growth</TableCell>
                  <TableCell>{analytics.conversions.growth}</TableCell>
                  <TableCell>{analytics.conversions.active_growth}</TableCell>
                  <TableCell>
                    {analytics.conversions.cancelled_growth}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Professional</TableCell>
                  <TableCell>{analytics.conversions.pro}</TableCell>
                  <TableCell>{analytics.conversions.active_pro}</TableCell>
                  <TableCell>{analytics.conversions.cancelled_pro}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Header>Trial Starts</Header>
          <StatRow>
            {analytics.paid_users.map((data, idx) => {
              return (
                <Stat
                  key={idx}
                  name={moment(data.date).format("MMM D")}
                  value={data.value}
                  direction={data.direction}
                  change={data.change}
                />
              );
            })}
          </StatRow> */}

          <Header>Lost Paid Users</Header>
          <StatRow>
            {analytics.paid_uninstalls.map((data, idx) => {
              return (
                <Stat
                  key={idx}
                  name={moment(data.date).format("MMM D")}
                  value={data.value}
                  direction={data.direction}
                  change={data.change}
                />
              );
            })}
          </StatRow>

          {/* <Header>Landing Page Signups</Header>
          <StatRow>
            {analytics.signups.map((data, idx) => {
              return (
                <Stat
                  key={idx}
                  name={moment(data.date).format("MMM D")}
                  value={data.value}
                  direction={data.direction}
                  change={data.change}
                />
              );
            })}
          </StatRow> */}
        </StatsWrapper>
      </ColorWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics)
);
