import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import arrowLeft from "../images/arrow-left-icon.png";
import arrowRight from "../images/arrow-right-icon.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getIsFetching } from "../Selectors";
import MDSpinner from "react-md-spinner";
import { isEmpty } from "lodash";
import { Show } from "./Show";

const PaginationArrow = styled.img`
  height: 16px;
`;

const PaginationItem = styled.div`
  padding: 8px;
  border: 1px solid ${theme.colors.lightBorder};
  background: ${theme.colors.white};
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.darkGrey};
  margin: 3px;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.medGrey};
  }

  ${(props) =>
    props.currentPage &&
    `
      border: 1px solid ${theme.colors.main};
      color: ${theme.colors.main};
    `};
`;

const ShowingText = styled.div`
  color: ${theme.colors.medGrey};
  margin-left: 10px;
`;

const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  align-items: center;
  margin-bottom: 50px;
`;

const StyledPaginationSub = styled.div`
  display: flex;
  flex-direction: row;
`;

const PaginationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

class Pagination extends Component {
  render() {
    if (!isEmpty(this.props.pagination)) {
      let showRightArrow = false;
      let showLeftArrow = false;
      let showRightDots = false;
      let showLeftDots = false;
      let arrayStart = 1;
      let arrayCount = 9;
      let currentPage = this.props.pagination.page;
      let pages = this.props.pagination.pages;
      if (pages < arrayCount) arrayCount = pages;
      // left arrow
      if (currentPage > 1) showLeftArrow = true;
      // right arrow
      if (currentPage < pages) showRightArrow = true;
      // left dots
      if (currentPage > 5 && pages > arrayCount) {
        showLeftDots = true;
        arrayStart = currentPage - 2;
      }
      // right dots
      if (pages > arrayCount && pages - 5 > currentPage) {
        showRightDots = true;
      }
      if (showLeftDots) arrayCount -= 1;
      if (showRightDots) arrayCount -= 1;
      let projectedEnd = arrayCount + arrayStart - 1;
      if (projectedEnd > pages) {
        arrayStart -= projectedEnd - pages;
      }
      const pagArray = Array.from(
        new Array(arrayCount),
        (val, index) => arrayStart + index
      );
      return (
        <StyledPagination>
          <PaginationRow>
            {showLeftArrow && (
              <PaginationItem
                onClick={() =>
                  this.props.onClick(currentPage - 1, "pagination")
                }
              >
                <PaginationArrow src={arrowLeft} />
              </PaginationItem>
            )}

            {showLeftDots && (
              <StyledPaginationSub>
                <PaginationItem
                  onClick={() => this.props.onClick(1, "pagination")}
                >
                  1
                </PaginationItem>

                <PaginationItem>...</PaginationItem>
              </StyledPaginationSub>
            )}

            {pagArray.length > 1 &&
              pagArray.map((page) => (
                <PaginationItem
                  key={page}
                  onClick={() => this.props.onClick(page, "pagination")}
                  currentPage={page === currentPage}
                >
                  {page}
                </PaginationItem>
              ))}

            {showRightDots && (
              <StyledPaginationSub>
                <PaginationItem>...</PaginationItem>

                <PaginationItem
                  onClick={() => this.props.onClick(pages, "pagination")}
                >
                  {pages}
                </PaginationItem>
              </StyledPaginationSub>
            )}

            {showRightArrow && (
              <PaginationItem
                onClick={() =>
                  this.props.onClick(currentPage + 1, "pagination")
                }
              >
                <PaginationArrow src={arrowRight} />
              </PaginationItem>
            )}
          </PaginationRow>

          <Show when={this.props.pagination.count}>
            <ShowingText>
              Showing {this.props.pagination.first_item}-
              {this.props.pagination.last_item} of {this.props.pagination.count}
            </ShowingText>
          </Show>
        </StyledPagination>
      );
    } else {
      return <MDSpinner singleColor="rgb(255, 255, 255)" size={20} />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
