import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { login, shopifyAuth, wixAuth, shoplazzaAuth } from "../../Actions";
import { getError, getIsFetching, getSubdomainData } from "../../Selectors";
import Cookies from "js-cookie";
import {
  getAppName,
  getDefaultStoreRoute,
  getUrlParameter,
} from "../../modules/Format";
import Account, {
  AccountButton,
  AccountInput,
  AccountLink,
  Form,
  LoginOptions,
} from "./components/AccountComponents";
import createNotification from "../../modules/Notification";
import { get, isEmpty } from "lodash";
import { trackEvent } from "../../modules/EventTracking";
import { validEmail, validPassword } from "../../validation";

const mapStateToProps = (state) => ({
  error: getError(state),
  isFetching: getIsFetching(state),
  subdomainData: getSubdomainData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      login,
      shopifyAuth,
      wixAuth,
      shoplazzaAuth,
    },
    dispatch
  ),
});

const defaultProps = {};

class SupplierAppInstall extends Component {
  state = {
    errors: {},
    email: "",
    password: "",
  };

  // On keyboard ENTER login user
  onChange = (e) => {
    if (e.key === "Enter") {
      this.loginUser();
    }
  };

  // Validates email and password
  isValid = () => {
    const errors = {};
    const { email, password } = this.state;
    if (!validPassword(password))
      errors.password = "Please enter a longer, more secure password";
    if (!validEmail(email))
      errors.email = "Please enter a valid email address";
    this.setState((previousState) => ({ ...previousState, errors: errors }));
    return isEmpty(errors);
  };

  loginUser = () => {
    if (this.isValid()) {
      this.props.login(this.state.email, this.state.password);
    }
  };

  validateShopifyUrlParams = () => {
    const loc = this.props.location;
    const shop = getUrlParameter("shop", loc);
    const code = getUrlParameter("code", loc);
    const hmac = getUrlParameter("hmac", loc);
    const state = getUrlParameter("state", loc);
    const timestamp = getUrlParameter("timestamp", loc);

    if (code && hmac && shop && state && timestamp) {
      if (Cookies.get("nonce") === state) {
        // SUPPLIER AUTH
        // this.props.shopifyAuth({
        console.log({
          code,
          shop,
          state,
          timestamp,
          hmac,
          created_at: moment(),
        });
      } else {
        // SHOW UNEXPECTED ERROR CONNECTING TO SHOPIFY
        createNotification({
          type: "danger",
          message:
            "Unexpected error connecting to Shopify. Contact support@dropcommerce.com",
        });
      }
      return true;
    }
    return false;
  };

  componentDidMount = () => {
    trackEvent("Supplier App Login Screen");

    // UNAUTHORIZED
    if (getUrlParameter("type", this.props.location) === "unauthorized") {
      createNotification({
        type: "danger",
        message: "Your login session has expired. Please login again.",
      });
    }

    const shopify = this.validateShopifyUrlParams();

  };

  render() {
    const title = "DropCommerce for Suppliers";
    const fetchingSubdomainData = get(this.props, ["subdomainData", "isFetching"]);
    return (
      <Account
        title={title}
        isSupplierPortal={true}
        isFetching={false}
        sideImage={true}
      >
        <AccountLink
          margin="15px 0 0 0"
          normalText="Don't have an account?"
          linkText="Sign up"
          onClick={() => this.props.history.push("/signup")}
        />
        <Form>
          <AccountInput
            onChange={(text) => this.setState({ email: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your email"
            value={this.state.email}
            error={this.state.errors.email}
          />
          <AccountInput
            onChange={(text) => this.setState({ password: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your password"
            value={this.state.password}
            error={this.state.errors.password}
            type="password"
          />
          <AccountButton text="Sign in" onClick={this.loginUser} />
          <AccountLink
            margin="20px 0 0 0"
            linkText="Forgot your password?"
            onClick={() => this.props.history.push("/forgot")}
          />
        </Form>
        <LoginOptions appName={""} />
      </Account>
    );
  }
}

SupplierAppInstall.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierAppInstall));
