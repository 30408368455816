import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";

import { Row } from "../../../components";
import { Button } from "@material-ui/core";
import { getRequest } from "../../../modules/API";
import styled from "styled-components";
import { get } from "lodash";
import MDSpinner from "react-md-spinner";

import { history } from "../../../BaseApp";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { theme } from "../../../modules/Theme";
import moment from "moment";

// TODO: move these 2 to a common place and import it from there
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

const base_url = "api/async-tasks";

export const Demerit = styled.span`
  border-radius: 4px;
  padding: 8px;
  color: white;
  margin: 5px;
  min-width: 0px;
  background: ${theme.colors.darkRed};
`;

/*
 * Renders the Task log table in the Admin Dashboard
 * API Endpoint: api/async-tasks
 * URL: /admin/task-log
 * */
export default class TaskLog extends React.Component {
  state = {
    ordering: null,
    fetching: true,
    data: { results: [] },
    selectedID: false,
  };

  columnNames = {
    id: "ID",
    date: "Date",
    func_name: "Task",
    param_values: "Parameters",
  };

  componentDidMount() {
    const fetchUrl = this.props.location.search.includes("page")
      ? `${api}/${base_url}/${this.props.location.search}`
      : `${api}/${base_url}`;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data, fetching: false }))
      .catch((error) => console.log(error));
  }

  nextPage() {
    this.setState({ fetching: true });
    getRequest({}, this.state.data.next).then((res) => {
      this.setState({
        data: res.data,
        fetching: false,
        selectedID: false,
      });
      history.push(
        this.state.ordering
          ? `?ordering=${this.state.ordering}&page=${this.state.data.page}`
          : `?page=${this.state.data.page}`
      );
    });
  }

  prevPage() {
    getRequest({}, this.state.data.previous).then((res) =>
      this.setState({
        data: res.data,
        fetching: false,
        selectedID: false,
      })
    );
  }

  search(term) {
    getRequest({ url: `${base_url}?search=${term}` }).then((res) => {
      this.setState({ data: res.data, ordering: null, selectedID: false });
      history.push(`?`);
    });
  }

  sortBy(column) {
    if (this.state.ordering === column) {
      this.setState({ sortingSearching: true, ordering: `-${column}` });
      history.push(`?ordering=${column}&page=${this.state.data.page}`);
      getRequest({
        url: `${base_url}?ordering=-${column}&page=${this.state.data.page}`,
      }).then((res) =>
        this.setState({
          sortingSearching: false,
          data: res.data,
          selectedID: false,
        })
      );
    } else {
      this.setState({ sortingSearching: true, ordering: column });
      history.push(`?ordering=${column}&page=${this.state.data.page}`);
      getRequest({
        url: `${base_url}?ordering=${column}&page=${this.state.data.page}`,
      }).then((res) =>
        this.setState({
          sortingSearching: false,
          ordering: column,
          data: res.data,
          selectedID: false,
        })
      );
    }
  }

  render() {
    let orderingArrow;
    if (this.state.ordering && this.state.ordering[0] === "-") {
      orderingArrow = <FiArrowDown size="18" />;
    } else orderingArrow = <FiArrowUp size="18" />;

    return (
      <React.Fragment>
        <h2>Task Logs</h2>
        <StyledInput
          type="text"
          placeholder="Search Parameters"
          onKeyUp={(e) => e.key === "Enter" && this.search(e.target.value)}
        />{" "}
        {this.state.sortingSearching && <MDSpinner />}
        <TableContainer
          component={Paper}
          style={{ marginBottom: "15px", overflow: "auto" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(this.columnNames).map((column, i) => (
                  <StyledTableCell key={i} onClick={() => this.sortBy(column)}>
                    {this.columnNames[column] + " "}
                    {(this.state.ordering === column ||
                      this.state.ordering === "-" + column) &&
                      orderingArrow}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {get(this, ["state", "data", "results"], []).map((datum, i) => (
                <StyledTableRow
                  key={i}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      datum.id === this.state.selectedID
                        ? "rgba(0,0,0,0.05)"
                        : null,
                  }}
                  onClick={() =>
                    this.setState({
                      selectedID: datum.id,
                    })
                  }
                >
                  <StyledTableCell>{datum.id}</StyledTableCell>
                  <StyledTableCell>
                    {moment(datum.date).format("MMM D, h:mma")}
                  </StyledTableCell>
                  <StyledTableCell>{datum.func_name}</StyledTableCell>
                  <StyledTableCell>
                    {JSON.stringify(datum.param_values)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Row>
          {this.state.data.previous && (
            <Button onClick={() => this.prevPage()}>Prev</Button>
          )}
          {this.state.data.next && (
            <Button onClick={() => this.nextPage()}>Next</Button>
          )}
          {this.state.fetching && <MDSpinner />}
        </Row>
      </React.Fragment>
    );
  }
}

const StyledInput = styled.input`
  margin-bottom: 10px;
  height: 40px;
  width: 250px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  :active {
    border: rgba(0, 0, 0, 0.3);
  }
`;
