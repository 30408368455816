import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Row } from "../../../components";
import { history } from "../../../BaseApp";
import Stars from "../../../components/Stars";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

function SupplierReviewTable() {
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [openAppeal, setOpenAppeal] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [appealId, setAppealId] = useState(null);
  const [appealMessage, setAppealMessage] = useState("");

  const prevPage = () => {
    setDataFetched(false);
    if (data.previous) {
      fetch(`${data.previous}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          response = await response.json();
          setData(response);
          setDataFetched(true);
        })
        .catch((error) => console.log(error));
      console.log("data fetched:", dataFetched);
      if (data.page) {
        history.push(`?page=${data.page - 1}`);
      }
    }
  };

  const nextPage = () => {
    setDataFetched(false);
    if (data.next) {
      fetch(`${data.next}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const r = await response.json();
          setData(r);
          setDataFetched(true);
        })
        .catch((error) => console.log(error));
      console.log("data fetched:", dataFetched);
      if (data.page) {
        history.push(`?page=${data.page + 1}`);
      }
    }
  };

  const appealReview = () => {
    setDataFetched(false);
    setOpenAppeal(false);
    fetch(`${api}/api/supplier-reviews/${appealId}/appeal/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: appealMessage }),
    })
      .then(async (response) => {
        const r = await response.json();
        if (data.results) {
          data.results.forEach((row, index) => {
            if (row.id === appealId) {
              row.status = r.status;
            }
          });
        }
        setDataFetched(true);
      })
      .catch((error) => console.log(error));
    return true;
  };

  const closeAppealDialog = () => {
    setOpenAppeal(false);
  };

  const openAppealDialog = (payload) => {
    setOpenAppeal(true);
    setAppealId(payload.id);
    setAppealMessage(payload.message);
    setStoreName(payload.storeName);
  };

  useEffect(() => {
    setDataFetched(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const page = parseInt("" + params.get("page"));
    let url = `${api}/api/supplier-reviews/`;
    if (page) {
      url += `?page=${page}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const r = await response.json();
        setData(r);
        setDataFetched(true);
      })
      .catch((error) => console.log(error));
    if (data.page) {
      history.push(`?page=${data.page}`);
    }
  }, []);

  const onTextChange = (e) => setAppealMessage(e.target.value);

  return (
    <React.Fragment>
      <div style={{ position: "relative", align: "center" }}>
        {openAppeal && (
          <form id="appealForm" onSubmit={appealReview}>
            <Dialog open={openAppeal} onClose={() => closeAppealDialog()}>
              <DialogTitle>Appeal review from {storeName}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Not all reviews are fair. We understand. If you disagree with
                  this review, please let us know your reasons. Our Customer
                  Success department will take a look and remove the review if
                  it offends our internal policy.
                </DialogContentText>
                <TextField
                  autoFocus
                  name="message"
                  margin="dense"
                  id="message"
                  label="Reason"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={appealMessage}
                  onChange={onTextChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => closeAppealDialog()}>Cancel</Button>
                <Button onClick={() => appealReview()}>Submit</Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
        <TableContainer component={Paper}>
          <Table aria-label="customized table" disabled={true}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Store</StyledTableCell>
                <StyledTableCell>Rating</StyledTableCell>
                <StyledTableCell>Review</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Make an Appeal</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.results &&
                data.results.map((datum) => (
                  <StyledTableRow key={datum.id}>
                    <StyledTableCell>{datum.storeName}</StyledTableCell>
                    <StyledTableCell>
                      <Stars
                        type={"display"}
                        rating={datum.rating}
                        size={"15"}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{datum.review}</StyledTableCell>
                    <StyledTableCell>{datum.status}</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "#32968e",
                          color: "#f8fbfb",
                          margin: "10px 0",
                        }}
                        onClick={() =>
                          openAppealDialog({
                            message: "",
                            id: datum.id,
                            storeName: datum.storeName,
                          })
                        }
                      >
                        Appeal
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <Row>
            <Button
              onClick={() => prevPage()}
              disabled={!data || !data.previous}
            >
              <FiChevronsLeft size="20" />
            </Button>
            <Button onClick={() => nextPage()} disabled={!data || !data.next}>
              <FiChevronsRight size="20" />
            </Button>
          </Row>
        </TableContainer>
        <Backdrop
          style={{
            color: "#fff",
            background: "#ccc",
            position: "absolute",
            zIndex: 100,
            opacity: 0.4,
          }}
          open={!dataFetched}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </React.Fragment>
  );
}

export default SupplierReviewTable;
