import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ButtonNew, Label } from "../../../../../components";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import ShippingOptionCard from "./ShippingOptionCard";
import MDSpinner from "react-md-spinner";

class ShippingOptionsList extends Component {
  render() {
    const { shippingOptions, product, productTitle } = this.props;
    return (
      <React.Fragment>
        <Label
          rowStyle={{ marginBottom: "10px", marginTop: "30px" }}
          hint="Set rates for each country you ship to, or provide an 'International' fallback rate for any countries not defined here."
        >
          {productTitle
            ? `Shipping Rates for ${productTitle}`
            : "Shipping Rates"}
        </Label>

        {this.props.isFetchingShippingOptions ? (
          <ShippingOptionWrapper>
            <MDSpinner singleColor="rgb(73,190,175)" size={40} />
          </ShippingOptionWrapper>
        ) : (
          <React.Fragment>
            <ButtonNew
              text="New Shipping Rate"
              type="main"
              extra="margin-top: 20px; width: 255px;"
              onClick={() =>
                this.props.history.push(
                  product
                    ? `/supplier/settings/shipping/new?product=${product}`
                    : "/supplier/settings/shipping/new"
                )
              }
            />
            <SubSection>
              {shippingOptions.map((country, i) => (
                <div key={i} style={{ marginBottom: "15px" }}>
                  <CountryName>{country.country_name}</CountryName>
                  {country.options.map((option) => (
                    <ShippingOptionCard
                      countryOptions={country.options}
                      key={option.id}
                      data={option}
                      onClick={() =>
                        this.props.history.push(
                          `/supplier/settings/shipping/${option.id}?product=${product}`
                        )
                      }
                    />
                  ))}
                </div>
              ))}
            </SubSection>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(ShippingOptionsList);

const ShippingOptionWrapper = styled.div`
  margin-top: 30px;
`;

const CountryName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
