import {
  GENERIC_REQ,
  GENERIC_RES,
  GENERIC_ERR,
  FETCH_SUPPLIER_PRODUCTS_RES,
  FETCH_SUPPLIER_ORDERS_RES,
  SET_SUPPLIER_PRODUCT_SAVED_RES,
  SAVE_SUPPLIER_PRODUCT_REQ,
  SAVE_SUPPLIER_PRODUCT_RES,
  UPDATE_VENDOR_APPROVAL_STATUS_REQ,
  UPDATE_VENDOR_APPROVAL_STATUS_RES,
  UPDATE_VENDOR_APPROVAL_STATUS_ERR,
  FETCH_VENDORS_RES,
  FETCH_VENDOR_RES,
  SET_FEATURE_IMAGE_REQ,
  SET_FEATURE_IMAGE_RES,
  SET_FEATURE_IMAGE_ERR,
  UPDATE_SUPPLIER_PRODUCT_REQ,
  UPDATE_SUPPLIER_PRODUCT_RES,
  UPDATE_SUPPLIER_PRODUCT_ERR,
  UPDATE_STATE,
  UPDATE_SUPPLIER,
  FETCH_TAGS_RES,
  SAVE_TAGS_RES,
  UPDATE_TAG,
  UPDATE_ALL,
  UPDATE_SUPPLIER_LOGO,
  UPDATE_SHIPPING_OPTION_REQ,
  UPDATE_SHIPPING_OPTION_RES,
  RESET_SUPPLIER_SETTINGS,
} from "./SupplierActions";

import { updateState } from "../../Reducer";

const initialState = {
  hasUnreadMessage: false,
  isFetching: 0,
  isFetchingSettings: false,
  isSearchingVariants: false,
  error: {},
  settings: {},
  tags: {},
  pagination: {},
  products: [],
  product: { title: "New Product", variants: [], description: "" },
  supplierProductSaved: false,
  supplier_orders: [],
  supplierOrder: {},
  vendors: [],
  shipping: {},
  shippingOptions: [],
  shippingOption: {},
  // WHOLESALE
  // searchVariants: [],
};

const supplier = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SUPPLIER_SETTINGS:
      return { ...state, settings: {} };

    case "supplier/FETCH_HAS_UNREAD_MESSAGE":
      return {
        ...state,
        settings: { ...state.settings, has_unread_messages: action.payload },
      };

    case "supplier/ADD_VENDOR": {
      const currentVendors = state.vendors;
      const newVendors = currentVendors.concat(action.payload);
      return { ...state, vendors: newVendors };
    }

    case "supplier/CREATE_SHIPPING_OPTION_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        shippingOption: action.payload,
      };

    case "supplier/CLEAR_SHIPPING_OPTION":
      return {
        ...state,
        shippingOption: {
          to_country: "US",
          from_country: "US",
          price: "0.00",
          additional_item_shipping_rate: "0.00",
          // product: null,
          carrier: "",
          delivery_days_min: 1,
          delivery_days_max: 2,
          deactivated: null,
          product: action.payload.product,
        },
      };

    case "supplier/SET_SHIPPING_OPTION_ACTIVE_RES":
      return {
        ...state,
        shippingOption: {
          ...state.shippingOption,
          deactivated: action.payload ? null : true,
        },
        isFetching: state.isFetching - 1,
      };

    case "supplier/UPDATE_SHIPPING_OPTION_FIELD": {
      let newOption = {
        ...state.shippingOption,
        [action.payload.field]: action.payload.value,
      };

      if (action.payload.field === "to_country") {
        newOption = {
          ...state.shippingOption,
          to_country: action.payload.value,
          region: "",
        };
      }

      return {
        ...state,
        shippingOption: newOption,
      };
    }

    case "supplier/FETCH_SHIPPING_OPTIONS_REQ":
      return {
        ...state,
        isFetchingShippingOptions: true,
      };

    case "supplier/FETCH_SHIPPING_OPTIONS_RES":
      return {
        ...state,
        isFetchingShippingOptions: false,
        shippingOptions: action.payload,
      };

    case "supplier/FETCH_SHIPPING_OPTIONS_ERR":
      return {
        ...state,
        isFetchingShippingOptions: false,
        shippingOptions: action.payload,
      };

    case "supplier/FETCH_ADMIN_CATEGORIES":
      return { ...state, categories: action.payload };

    case UPDATE_SUPPLIER_LOGO:
      return {
        ...state,
        settings: { ...state.settings, logo: action.payload.logo },
      };

    case UPDATE_ALL:
      return {
        ...state,
        tags: {
          ...state.tags,
          products: state.tags.products.map((product) => {
            if (action.payload.productIds.includes(product.id)) {
              if (action.payload.type === "add") {
                let newCategories = product.categories;
                newCategories.push(action.payload.tag);
                return { ...product, categories: newCategories, changed: true };
              } else if (action.payload.type === "remove") {
                return {
                  ...product,
                  changed: true,
                  categories: product.categories.filter(
                    (category) => category !== action.payload.tag
                  ),
                };
              }
            }
            return product;
          }),
        },
      };

    case UPDATE_TAG:
      return {
        ...state,
        tags: {
          ...state.tags,
          products: state.tags.products.map((product) => {
            if (product.id === action.payload.product) {
              if (action.payload.type === "add") {
                let newCategories = product.categories;
                newCategories.push(action.payload.tag);
                return { ...product, categories: newCategories, changed: true };
              } else if (action.payload.type === "remove") {
                return {
                  ...product,
                  changed: true,
                  categories: product.categories.filter(
                    (category) => category !== action.payload.tag
                  ),
                };
              }
            }
            return product;
          }),
        },
      };

    case SAVE_TAGS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        tags: {
          ...state.tags,
          products: state.tags.products.map((product) => {
            return { ...product, changed: false };
          }),
        },
      };

    case FETCH_TAGS_RES:
      return {
        ...state,
        tags: action.payload,
        isFetching: state.isFetching - 1,
        pagination: action.payload.pagination,
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };

    case UPDATE_STATE:
      return updateState(state, action.payload);

    case SET_FEATURE_IMAGE_REQ:
      return {
        ...state,
        product: {
          ...state.product,
          images: state.product.images.map((image) => {
            if (image.id === action.payload.imageId) {
              return { ...image, updating: true };
            }
            return image;
          }),
        },
      };

    case UPDATE_SUPPLIER_PRODUCT_REQ:
      return {
        ...state,
        product: {
          ...action.payload.product,
          isFetching: true,
        },
      };

    case UPDATE_SUPPLIER_PRODUCT_RES:
      return {
        ...state,
        product: {
          ...action.payload.product,
          isFetching: false,
        },
      };

    case UPDATE_SUPPLIER_PRODUCT_ERR:
      return {
        ...state,
        product: { ...state.product, isFetching: false },
      };

    case SET_FEATURE_IMAGE_ERR:
      return {
        ...state,
        product: {
          ...state.product,
          images: state.product.images.map((image) => {
            if (image.id === action.payload.imageId) {
              return { ...image, updating: false };
            }
            return image;
          }),
        },
      };

    case SET_FEATURE_IMAGE_RES:
      return {
        ...state,
        product: {
          ...state.product,
          images: action.payload.images,
        },
      };

    case UPDATE_VENDOR_APPROVAL_STATUS_ERR:
      return {
        ...state,
        error: action.payload.error,
        vendors: state.vendors.map((vendor) => {
          if (vendor.id === action.payload.vendorId) {
            return { ...vendor, is_fetching: false };
          } else {
            return vendor;
          }
        }),
      };

    case FETCH_VENDORS_RES:
      return {
        ...state,
        vendors: action.payload.vendors,
        isFetching: state.isFetching - 1,
      };

    case FETCH_VENDOR_RES:
      return {
        ...state,
        vendor: action.payload.vendor,
        isFetching: state.isFetching - 1,
      };

    case UPDATE_VENDOR_APPROVAL_STATUS_REQ:
      return {
        ...state,
        vendors: state.vendors.map((vendor) => {
          if (vendor.id === action.payload.vendorId) {
            return { ...vendor, is_fetching: true };
          } else {
            return vendor;
          }
        }),
      };

    case UPDATE_VENDOR_APPROVAL_STATUS_RES:
      return {
        ...state,
        vendors: state.vendors.map((vendor) => {
          if (vendor.id === action.payload.vendorId) {
            return {
              ...vendor,
              is_fetching: false,
              approval_status: action.payload.status,
            };
          } else {
            return vendor;
          }
        }),
      };

    case SET_SUPPLIER_PRODUCT_SAVED_RES:
      return {
        ...state,
        productSaved: action.payload.productSaved,
      };

    case FETCH_SUPPLIER_PRODUCTS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        products: action.payload.products,
        product: {},
        pagination: action.payload.pagination,
      };

    case FETCH_SUPPLIER_ORDERS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        supplier_orders: action.payload.supplier_orders,
        order: {},
        pagination: action.payload.pagination,
      };

    case UPDATE_SHIPPING_OPTION_REQ:
      return {
        ...state,
        isFetchingShippingOption: true,
      };

    case UPDATE_SHIPPING_OPTION_RES:
      return {
        ...state,
        isFetchingShippingOption: false,
      };

    case GENERIC_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
      };

    case GENERIC_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
      };

    case GENERIC_ERR:
      return {
        ...state,
        error: action.payload.error,
        isFetching: state.isFetching - 1,
      };

    case SAVE_SUPPLIER_PRODUCT_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
        product: {
          ...state.product,
          description: action.payload.product.description,
          title: action.payload.product.title,
          quantity: action.payload.product.quantity,
        },
      };

    case SAVE_SUPPLIER_PRODUCT_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        product: {
          ...state.product,
          isFetching: false,
        },
      };

    default:
      return state;
  }
};

export default supplier;
