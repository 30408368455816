import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";
import { Row } from "./Layout";

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  flex-direction: column;
  z-index: 99999;
  text-align: center;
  cursor: auto;
`;

export const Title = styled.div`
  color: ${theme.colors.white};
  font-size: 45px;
  text-align: center;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const Description = styled.div`
  color: ${theme.colors.white};
  font-size: 25px;
  text-align: center;
  font-weight: 300;
  margin: 40px;
  max-width: 800px;

  @media (max-width: 800px) {
    font-size: 16px;
    margin: 15px;
  }
`;

class Explanation extends Component {
  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.cancel();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { title, description, cancel, confirm, loading } = this.props;

    return (
      <Modal>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Row gap="10px">
          <ButtonNew
            type="secondary"
            text="CANCEL"
            onClick={cancel}
            extra="width: 200px;"
          />
          <ButtonNew
            type="main"
            text="PROCEED"
            onClick={confirm}
            extra="width: 200px;"
            isFetching={loading}
          />
        </Row>
      </Modal>
    );
  }
}

export default Explanation;
