import { FiCheck, FiClock, FiMail, FiPlus } from "react-icons/fi";
import React, { Component } from "react";
import {
  addToImportList,
  deleteProduct,
  importProduct,
  requestApproval,
  setLastPaginationState,
} from "../../../views/products/ProductsActions";
import { getPagination, getSettings } from "../../../Selectors";
import { getUrlParameter, truncateText } from "../../../modules/Format";

import ButtonNew from "../../../components/ButtonNew";
import DeleteItem from "../../../components/DeleteItem";
import Hint from "../../../components/Hint";
import { Row } from "../../../components/Layout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { setError } from "../../../Actions";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { withRouter } from "react-router-dom";

const ProductCardPrice = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  ${(props) => props.small && "font-size: 13px;"}
  ${(props) => props.medium && "font-size: 16px;"}
  ${(props) => props.light && "font-weight: 300;"}
  color: ${(props) => (props.grey ? theme.colors.medGrey : "#e48832")};
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

const ProductCardRowText = styled.div`
  font-size: 12px;
  color: ${theme.colors.medGrey};
  font-weight: 300;
  text-align: left;
  @media (max-width: 1200px) {
    font-size: 10px;
  }
`;

const ProductCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ProductCardPriceRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    ${(p) => p.hideMobile && "display: none;"}
  }
`;

const ProductCardName = styled.div`
  font-size: 14px;
  color: #5e5e5e;
  font-weight: 400;
  @media (max-width: 1200px) {
    font-size: 13px;
  }
`;

const ProductCardSupplier = styled.div`
  font-size: 14px;
  color: #5e5e5e;
  text-align: left;
  font-weight: 300;
`;

const ProductCardInsideContainer = styled.div`
  padding: 20px;
  width: 100%;
  ${(props) => props.hideProductCardInsideContainer && "display: none"}
  @media (max-width: 800px) {
    padding: 5px;
  }
`;

const StyledButton = styled(ButtonNew)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const mapStateToProps = (state) => ({
  pagination: getPagination(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      importProduct,
      requestApproval,
      addToImportList,
      setLastPaginationState,
      setError,
      deleteProduct,
    },
    dispatch
  ),
});

const defaultProps = {};

class ProductCardInside extends Component {
  state = {
    hover: false,
    confirmProduct: null,
  };

  importProduct = (e, product) => {
    e.stopPropagation();
    this.props.addToImportList(product.id);
  };

  requestApproval = (e, supplierId, cancel = false) => {
    e.stopPropagation();
    this.props.requestApproval(supplierId, cancel);
  };

  goToPath = (path) => {
    this.props.history.push(path);
  };

  viewImportList = (e) => {
    e.stopPropagation();
    this.props.history.push("/imported/list");
  };

  showExplanation = (e, type, bool) => {
    e.stopPropagation();
    this.setState({ [type]: bool });
  };

  openLink = (e, link) => {
    e.stopPropagation();
    window.open(link);
  };

  showConfirmDelete = (e, type) => {
    e.stopPropagation();
    this.setState({
      confirmProduct: type,
    });
  };

  renderApprovalButton = () => {
    const product = this.props.product;
    const supplier = get(this.props, ["product", "supplier"]);
    const issue = get(this.props, ["product", "issue"]);
    const canSync =
      issue && !issue.includes("Inactive") && !issue.includes("Stock");

    if (product.out_of_stock) {
      return (
        <StyledButton
          text="OUT OF STOCK"
          disabled
          type="main-light"
          buttonStyle="width: 100%;"
        />
      );
    }

    if (this.props.type === "imported") {
      if (this.state.confirmProduct === "DELETE") {
        return (
          <DeleteItem
            title="Delete Product From Store?"
            description="Product will be removed from your store and your DropCommerce list."
            buttonLink={(e) => {
              e.stopPropagation();
              this.setState({ hideProductCardInsideContainer: true });
              this.props.deleteProduct(this.props.product.id, "DELETE");
            }}
            buttonText="CONFIRM"
            buttonTwoLink={(e) => {
              e.stopPropagation();
              this.setState({ confirmProduct: null });
            }}
            buttonTwoText="CANCEL"
          />
        );
      } else if (this.state.confirmProduct === "SYNC_VARIANTS") {
        return (
          <DeleteItem
            title="Sync Product In Store?"
            description="Product variants will be re-imported with your default pricing rules."
            buttonLink={(e) => {
              e.stopPropagation();
              this.props.deleteProduct(this.props.product.id, "SYNC_VARIANTS");
            }}
            buttonText="CONFIRM"
            buttonTwoLink={(e) => {
              e.stopPropagation();
              this.setState({ confirmProduct: null });
            }}
            buttonTwoText="CANCEL"
          />
        );
      } else {
        return (
          <Row>
            {getUrlParameter("status", this.props.location) === "issues" &&
              canSync && (
                <ButtonNew
                  text="SYNC"
                  onClick={(e) => this.showConfirmDelete(e, "SYNC_VARIANTS")}
                  type="main"
                  extra="width: 100%; margin-right: 10px;"
                />
              )}
            <ButtonNew
              text="DELETE"
              onClick={(e) => this.showConfirmDelete(e, "DELETE")}
              type="main"
              extra="width: 100%;"
            />
          </Row>
        );
      }
    }

    if (supplier.approval_status === "A" && !product.soft_delete) {
      if (product.imported === "shopify") {
        return (
          <StyledButton
            text="ADDED TO STORE"
            type="main-light"
            icon={<FiCheck size="18" color="white" />}
            buttonStyle="width: 100%;"
          />
        );
      } else if (product.imported === "list") {
        return (
          <StyledButton
            text="VIEW IN IMPORT LIST"
            isFetching={product.isFetching}
            type="main-light"
            icon={<FiCheck size="18" color="white" />}
            onClick={(e) => this.viewImportList(e)}
            buttonStyle="width: 100%;"
          />
        );
      } else {
        return (
          <StyledButton
            disabled={
              this.props.supplier && this.props.supplier.status === "Hidden"
            }
            text="ADD TO IMPORT LIST"
            isFetching={product.isFetching}
            onClick={(e) => this.importProduct(e, product)}
            icon={<FiPlus size="18" color="white" />}
            type="main"
            buttonStyle="width: 100%;"
          />
        );
      }
    } else if (
      product.supplier.approval_status === "P" &&
      !product.soft_delete
    ) {
      return (
        <StyledButton
          disabled={
            this.props.supplier && this.props.supplier.status === "Hidden"
          }
          text={
            this.state.hoverPending ? "CANCEL REQUEST?" : "PENDING APPROVAL"
          }
          isFetching={product.isFetching}
          onClick={(e) => this.requestApproval(e, product.supplier.id, true)}
          onMouseEnter={() => this.setState({ hoverPending: true })}
          onMouseLeave={() => this.setState({ hoverPending: false })}
          type="main-light"
          icon={<FiClock size="18" color="white" />}
          buttonStyle={this.state.hoverPending && "background:red;"}
        />
      );
    } else if (
      ["R", "C", "NEW"].includes(product.supplier.approval_status) &&
      !product.soft_delete
    ) {
      return (
        <StyledButton
          disabled={
            this.props.supplier && this.props.supplier.status === "Hidden"
          }
          text="REQUEST APPROVAL"
          icon={<FiMail size="18" color="white" />}
          onClick={(e) => {
            e.stopPropagation();
            if (!this.props.showApprovalRequirements) return;
            this.props.showApprovalRequirements(product.supplier.id);
          }}
          type="main"
          isFetching={product.isFetching}
          buttonStyle="width: 100%;"
        />
      );
    } else if (
      product.supplier.approval_status === "D" &&
      !product.soft_delete
    ) {
      return (
        <StyledButton
          text="PENDING APPROVAL"
          disabled
          type="main-light"
          icon={<FiClock size="18" color="white" />}
        />
      );
    } else
      return <StyledButton text="DEACTIVATED" disabled type="main-light" />;
  };

  render() {
    // Patch for NFT demo
    const location = window.location.toString();

    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    const product = this.props.product;

    const supplier = get(this.props, ["product", "supplier"]);

    const type = this.props.type;

    return (
      <ProductCardInsideContainer
        hide={this.state.hideProductCardInsideContainer}
        style={{ cursor: "default" }}
        onMouseLeave={() => this.setState({ confirmProduct: null })}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <ProductCardName>{truncateText(product.title, 30)}</ProductCardName>
        </Row>

        <ProductCardRow>
          <ProductCardSupplier>
            {supplier && !isGlobal && truncateText(supplier.name, 24)}
          </ProductCardSupplier>
          <Hint
            hint="Price: The price you will pay for this product if you make a sale."
            hintTwo="Retail Price: The actual price the supplier sells this product for."
            hintThree="Profit: The difference between the price you pay an the price you could sell for."
            left="-180px"
          />
        </ProductCardRow>
        {type === "imported" ? (
          <ProductCardRow style={{ justifyContent: "center" }}>
            <ProductCardPriceRow>
              <ProductCardRowText>Your Cost:</ProductCardRowText>
              <ProductCardPrice>
                {`$${product.price ? product.price.toFixed(2) : "-"}`}
              </ProductCardPrice>
            </ProductCardPriceRow>
          </ProductCardRow>
        ) : (
          <ProductCardRow>
            {product.quantity && (
              <ProductCardPriceRow hideMobile>
                <ProductCardRowText>In Stock:</ProductCardRowText>
                <ProductCardPrice small grey light>
                  {product.quantity}
                </ProductCardPrice>
              </ProductCardPriceRow>
            )}
            <ProductCardPriceRow hideMobile>
              <ProductCardRowText>Retail:</ProductCardRowText>
              <ProductCardPrice small grey light>
                $
                {product.retail_price.toFixed(2)
                  ? product.retail_price.toFixed(2)
                  : product.description}
              </ProductCardPrice>
            </ProductCardPriceRow>
            <ProductCardPriceRow>
              <ProductCardRowText>Price:</ProductCardRowText>
              <ProductCardPrice>
                ${product.price.toFixed(2) || product.description}
              </ProductCardPrice>
            </ProductCardPriceRow>
            {/* {(product.shipping_rate || shipping_rate === 0.0) && (
              <ProductCardPriceRow>
                <ProductCardRowText>Shipping:</ProductCardRowText>
                <ProductCardPrice small grey>
                  ${product.shipping_rate.toFixed(2) || product.description}
                </ProductCardPrice>
              </ProductCardPriceRow>
            )} */}
            {product.profit && (
              <ProductCardPriceRow>
                <ProductCardRowText>Profit:</ProductCardRowText>
                <ProductCardPrice small grey>
                  ${product.profit.toFixed(2)}
                </ProductCardPrice>
              </ProductCardPriceRow>
            )}
          </ProductCardRow>
        )}
        {this.renderApprovalButton()}
      </ProductCardInsideContainer>
    );
  }
}

ProductCardInside.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCardInside)
);
