import React, { Component } from "react";
import styled from "styled-components";
import Text from "../../modules/Text";

import moment from "moment";
import { getRequest } from "../../modules/API";
import { FiCheck } from "react-icons/fi";
import { Show } from "../../components/Show";
import { errorNotification } from "../../modules/Notification";

const Card = styled("div")`
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

const CardImage = styled("div")`
  flex: 1;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardContent = styled("div")`
  flex: 1;
  padding: 32px;
  background-color: #f9f9f9;
  color: #333;

  p {
    margin: 0 0 10px 0;
    color: #999;
    font-size: 14px;
  }
`;

const CustomText = styled(Text)`
  font-size: 16px;

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

const CardTags = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const Tag = styled("span")`
  display: inline-flex;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  ${(props) =>
    props.value
      ? "background-color: #89bfce; color: #ffffff;"
      : "background-color: #eee; color: #333;"}
  padding: 6px 10px;
  border-radius: 30px;
  margin-right: 6px;
`;

export class PrebuiltStoreCard extends Component {
  gotoStore(store) {
    getRequest({
      url: `prebuilt/launch/?store_id=${store}`,
    }).then((response) => {
      if (response.error) {
        errorNotification(response.error);
      } else {
        window.open(response.data["link"]);
      }
    });
  }

  render() {
    const { store } = this.props;

    const getThumbnailUrl = () => {
      if (store.design_set["level"] === "CUSTOM") {
        return store.style_pack["image_url"];
      }
      return store.design_set["image"];
    };

    return (
      <Card>
        <CardImage onClick={() => this.gotoStore(store.store)}>
          <img alt="A Store" src={getThumbnailUrl()} />
        </CardImage>

        <CardContent>
          <CustomText bold>{store.package.toUpperCase()}</CustomText>
          <CustomText.Small light extra="margin-bottom: 10px;">
            {store.store_type.toUpperCase().replace("_", " ")}
          </CustomText.Small>

          <p>PURCHASE DATE: {moment(store.date).format("MMMM, Do, YYYY")}</p>

          <Show when={store.email}>
            <p>EMAIL: {store.email}</p>
          </Show>

          {store.url && store.url !== "undefined" && (
            <Text.Small
              light
              link
              extra="margin-bottom: 10px;"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`https://${store.url}`);
              }}
            >
              {store.url}
            </Text.Small>
          )}

          <CardTags>
            {fields.map((field, i) => {
              const value = store[field];
              const text = field.replace("_", " ");

              return (
                <Tag key={field} value={value}>
                  <Show when={value}>
                    <FiCheck color="#ffffff" size={10} />
                  </Show>
                  {text}
                </Tag>
              );
            })}
          </CardTags>
        </CardContent>
      </Card>
    );
  }
}

const fields = [
  "access_granted",
  "theme_imported",
  "theme_edited",
  "products_imported",
  "shipping_configured",
  "reviewed",
  "completed",
];
