import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import star from "../images/icons/feather/star-yellow.svg";
import Text from "../modules/Text";

const Testimonial = ({ text, owner }) => (
  <TestimonialContainer>
    <StarsRow>
      <Star src={star} />
      <Star src={star} />
      <Star src={star} />
      <Star src={star} />
      <Star src={star} />
    </StarsRow>
    <TestimonialText>"{text}"</TestimonialText>
    <TestimonialStore>~ {owner}</TestimonialStore>
  </TestimonialContainer>
);

class Testimonials extends Component {
  render() {
    return (
      <Container>
        <Text.Large margin="70px 0 20px 0">Real Customer Reviews</Text.Large>
        <TestimonialsContainer>
          <Testimonial
            text="Fantastic drop-shipping aggregator. Great vendors and products shipping from the US and Canada, and support has been phenomenal. Thank you Patrick!"
            owner="Time Refinery, Shopify Shop Owner"
          />
          <Testimonial
            text="The app is incredibly easy to use. It did not take long for the products to import. Very satisfied with the app, altogether."
            owner="Beauty Products For You, Shopify Shop Owner"
          />
          <Testimonial
            text="What separates DropCommerce from the other Drop shipping apps? It is their awesome customer support, the products and the flawless app that integrates with Shopify."
            owner="disNdatmarket, Shopify Shop Owner"
          />
        </TestimonialsContainer>
      </Container>
    );
  }
}

export default Testimonials;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TestimonialsContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 60px;
  min-height: 310px;
  height: 310px;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: 800px;
    min-height: 800px;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 20px;
  max-width: 400px;
  min-height: 250px;
  height: 250px;
  align-items: center;
`;

const Star = styled.img`
  margin: 2px;
  height: 23px;
  width: 23px;
`;

const StarsRow = styled.div`
  display: flex;
`;

const TestimonialText = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
`;

const TestimonialStore = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;
