import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiAlertTriangle,
} from "react-icons/fi";

import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { Row } from "../../../components";
import { history } from "../../../BaseApp";
import ModalRefundSupplier from "../../../components/ModalRefundSupplier";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

function SupplierRefundTable() {
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  const [, setShowRefundForm] = useState(false);

  const prevPage = () => {
    setDataFetched(false);
    if (data.previous) {
      fetch(`${data.previous}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          response = await response.json();
          setData(response);
          setDataFetched(true);
        })
        .catch((error) => console.log(error));
      console.log("data fetched:", dataFetched);
      if (data.page) {
        history.push(`?page=${data.page - 1}`);
      }
    }
  };

  const nextPage = () => {
    setDataFetched(false);
    if (data.next) {
      fetch(`${data.next}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const r = await response.json();
          setData(r);
          setDataFetched(true);
        })
        .catch((error) => console.log(error));
      console.log("data fetched:", dataFetched);
      if (data.page) {
        history.push(`?page=${data.page + 1}`);
      }
    }
  };

  useEffect(() => {
    setDataFetched(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const page = parseInt("" + params.get("page"));
    let url = `${api}/api/refunds/?groupBy=supplier_order`;

    if (page) {
      url += `?page=${page}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const r = await response.json();
        setData(r);
        setDataFetched(true);
      })
      .catch((error) => console.log(error));
    if (data.page) {
      history.push(`?page=${data.page}`);
    }
  }, []);

  return (
    <React.Fragment>
      <div style={{ position: "relative", align: "center" }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" disabled={true}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Order Number</StyledTableCell>
                <StyledTableCell>Refund Quantity </StyledTableCell>
                <StyledTableCell>Requested Refund</StyledTableCell>
                <StyledTableCell>Shipping Cost</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                Object.entries(data).map(([key, datum], i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>{key}</StyledTableCell>
                    <StyledTableCell>{datum.qty}</StyledTableCell>
                    <StyledTableCell>${datum.total_products}</StyledTableCell>
                    <StyledTableCell>${datum.shipping_rate}</StyledTableCell>
                    {datum.error ? (
                      <StyledTableCell>
                        <FiAlertTriangle size="24px" color="red" />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>{datum.status}</StyledTableCell>
                    )}
                    <StyledTableCell>
                      <ModalRefundSupplier
                        data={datum}
                        hideModal={() => setShowRefundForm(false)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <Row>
            <Button
              onClick={() => prevPage()}
              disabled={!data || !data.previous}
            >
              <FiChevronsLeft size="20" />
            </Button>
            <Button onClick={() => nextPage()} disabled={!data || !data.next}>
              <FiChevronsRight size="20" />
            </Button>
          </Row>
        </TableContainer>
        <Backdrop
          style={{
            color: "#fff",
            background: "#ccc",
            position: "absolute",
            zIndex: 100,
            opacity: 0.4,
          }}
          open={!dataFetched}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </React.Fragment>
  );
}

export default SupplierRefundTable;
