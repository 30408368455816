import React from "react";
import styled from "styled-components";

const Container = styled("div")`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
`;

const LogoWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const Logo = styled("img")`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  user-select: none;
  object-fit: contain;
`;

const Name = styled("p")`
  color: #222;
  font-size: 14px;
`;

const Contact = styled("p")`
  color: #222;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
`;

export const SupplierContactInfo = ({ logo, supplierName, website }) => {
  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt={supplierName} />

        <Name>{supplierName}</Name>
      </LogoWrapper>

      <Contact onClick={() => window.open("https://" + website)}>
        Website
      </Contact>
    </Container>
  );
};
