import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { Row } from "../../components/Layout";
import Input from "./components/Input";
import Messages from "./Messages";
import Conversations from "./Conversations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getConversations, getIsFetching, getSettings } from "../../Selectors";
import { getSettings as getSupplierSettings } from "../../views/supplier/SupplierSelectors";
import {
  acceptInboxAgreement,
  addMessageToConversation,
  fetchConversations,
  fetchHasUnreadMessage,
  markConversationRead,
  getOrCreateChat,
} from "../../Actions";
import { get, isEmpty } from "lodash";
import Icon from "./components/Icon";
import { NoResults } from "../../components";
import RecipientModal from "./components/RecipientModal";
import InboxCarousel from "./components/InboxCarousel";
import { FiSidebar, FiX } from "react-icons/fi";

import { FullScreenSpinner } from "../../components/FullScreenSpinner";

const ChatContainer = styled.div`
  position: relative;
  height: 100%;
  border-right: 1px solid ${theme.colors.lightBorder};
  flex: 2 0 320px;

  @media screen and (max-width: 800px) {
  }
`;

const InputChatContainer = styled.div`
  bottom: 10px;
  width: 100%;
`;

const NoResultsContainer = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.lightBorder};
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;

  @media screen and(min-width: 640px) {
    padding: 15px;
  }
`;

const DummyInboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${theme.colors.lightBorder};
  border-radius: 15px;
  margin: 20px 50px;
  padding: 10px 10px;
`;

const DummyInboxImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const RecipientContainer = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  justify-content: space-between;
  align-items: center;
  padding: 18px;

  @media screen and (max-width: 800px) {
    padding: 13px;
  }
`;

const RecipientName = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  word-break: break-all;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const TermsWrapper = styled.div`
  padding: 20px;
  width: 600px;
  max-width: 95vw;
`;

const TermsHeader = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 20px;
`;

const TermsText = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
`;

const TermsButton = styled.div`
  font-size: 20px;
  font-weight: 400;
  background: ${theme.colors.main};
  padding: 20px;
  color: white;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.darkGreen};
  }
`;

const ToggleSidebar = styled("div")`
  display: grid;
  place-items: center;
  font-size: 24px;
  cursor: pointer;

  @media screen and (min-width: 560px) {
    display: none;
  }
`;

class Inbox extends Component {
  state = {
    text: "",
    showInboxAgreement: true,
    showRecipientModal: false,
    expanded: false,
    unreadChats: [],
  };

  componentDidMount = () => {
    const inboxPath = this.props.match.path.includes("supplier")
      ? "/supplier/inbox"
      : "/inbox";
    const params = new URLSearchParams(window.location.search);
    const supplierId = params.get("to_supplier");
    if (supplierId) {
      this.props.getOrCreateChat(supplierId).then((response) => {
        if (response.label) {
          this.props.history.push(`${inboxPath}/${response.label}`);
        }
      });
    }
    this.props.fetchHasUnreadMessage();
    this.props.fetchConversations().then(() => {
      this.setState({
        unreadChats: this.props.conversations.filter((c) =>
          c.messages && c.messages.length
            ? c.messages[c.messages.length - 1].read_timestamp === null
            : false
        ),
      });

      const conversationLabel = get(this.props, [
        "match",
        "params",
        "conversationLabel",
      ]);

      const mostRecentConversation =
        this.props.conversations[this.props.conversations.length - 1];

      if (!conversationLabel) {
        if (mostRecentConversation) {
          this.props.history.replace(
            `${inboxPath}/${mostRecentConversation.label}`
          );
          this.props.markConversationRead(mostRecentConversation.label);
        }
      } else {
        const labels = this.props.conversations.map(
          (conversation) => conversation.label
        );

        if (!labels.includes(conversationLabel) && mostRecentConversation) {
          this.props.history.replace(
            `${inboxPath}/${mostRecentConversation.label}`
          );
          this.props.markConversationRead(mostRecentConversation.label);
        }
      }
    });
  };

  sendMessage = (recipientId) => {
    const conversationLabel = get(this.props, [
      "match",
      "params",
      "conversationLabel",
    ]);

    const settingsKey = this.props.match.path.includes("supplier")
      ? "supplierSettings"
      : "settings";

    const userId = get(this.props, [settingsKey, "user_id"]);

    const messageObject = {
      receiver_id: recipientId,
      sender_id: userId,
      text: this.state.text,
      label: conversationLabel,
    };

    const acceptableMessagePattern = /[a-zA-Z0-9]/;
    if (acceptableMessagePattern.exec(this.state.text)) {
      // The above condition returns a falsy value if the message TEXT doesn't match our "acceptable message pattern"

      // REDUX ACTION
      this.props.addMessageToConversation(messageObject);

      // CLEAR LOCAL STATE INPUT FIELD
      this.setState({ text: "" });
    }
  };

  goToSupplier = (supplierId, accountType) => {
    if (!supplierId) return;
    if (accountType === "store") {
      this.props.history.push("/supplier-id/" + supplierId);
    }
  };

  handleUnreadChats = (conversation) => {
    this.setState((currentState) => ({
      ...currentState,
      unreadChats: currentState.unreadChats.filter(
        (chat) => chat !== conversation
      ),
    }));
  };

  render() {
    const conversationLabel = get(this.props, [
      "match",
      "params",
      "conversationLabel",
    ]);

    // Based on whether it's a supplier or a store, get the right settings
    const settingsKey = this.props.match.path.includes("supplier")
      ? "supplierSettings"
      : "settings";

    const settings = get(this.props, settingsKey);

    const inboxAgreement = get(settings, "inbox_agreement");

    const hideInbox = () => {
      // Suppliers can always access Inbox
      if (settings.store === undefined) return false;

      // Grandfather stores with previous messages
      if (this.props.conversations.length >= 0) return false;

      // Block Inbox per plan
      let payment_plan = settings.store.payment_plan;
      if (payment_plan === null) return true;
      if (payment_plan.active === false) return true;

      // Otherwise show it
      return false;
    };

    let currentConversation = null;

    this.props.conversations.forEach((conversation) => {
      if (conversation.label === conversationLabel) {
        currentConversation = conversation;
      }
    });

    const mostRecentConversation =
      this.props.conversations[this.props.conversations.length - 1];

    // IF NO CONVERSATION IN THE URL, SHOW MOST RECENT
    if (!currentConversation && mostRecentConversation) {
      currentConversation = mostRecentConversation;
    }

    if (this.props.isFetching > 0 || isEmpty(settings)) {
      return <FullScreenSpinner />;
    }

    if (!inboxAgreement && this.state.showInboxAgreement) {
      return (
        <TermsWrapper style={{ marginBottom: "64px" }}>
          <TermsText>
            Before using the Inbox, please agree to the terms below.
          </TermsText>
          <TermsHeader>Inbox Terms</TermsHeader>
          <TermsText>
            Messages sent via Inbox may be reviewed by the DropCommerce support
            team for quality assurance and customer support reasons.
          </TermsText>
          <TermsText>
            Messages will not be shared with any third parties.
          </TermsText>
          <TermsText>
            Profanity, rudeness or any other inappropriate conduct will not be
            tolerated, and access to the Inbox will be revoked at the discretion
            of the DropCommerce team.
          </TermsText>
          <TermsText>
            DropCommerce does not guarantee responses from users, but
            responsiveness will be measured and potentially displayed on their
            DropCommerce profile. It is the responsibility of the individual
            user to review and respond to messages at their discretion.
          </TermsText>
          <TermsButton
            onClick={() => {
              this.props.acceptInboxAgreement();
              this.setState({ showInboxAgreement: false });
            }}
          >
            I Agree
          </TermsButton>
        </TermsWrapper>
      );
    }

    if (hideInbox() === true) {
      return (
        <DummyInboxWrapper>
          <DummyInboxImageContainer>
            <InboxCarousel />
          </DummyInboxImageContainer>
        </DummyInboxWrapper>
      );
    }

    return (
      <>
        {this.state.showRecipientModal && (
          <RecipientModal
            hide={() => this.setState({ showRecipientModal: false })}
          />
        )}

        <Row style={{ height: "100%", width: "100%", position: "relative" }}>
          <Conversations
            handleUnreadChats={this.handleUnreadChats}
            conversations={this.props.conversations}
            unreadChats={this.state.unreadChats}
            expanded={this.state.expanded}
            setRecipientModal={(bool) =>
              this.setState({ showRecipientModal: bool })
            }
          />

          {!currentConversation && (
            <NoResultsContainer>
              <NoResults
                title="You have no active conversations"
                message="Click the below to start one now."
                buttonLink={() => this.setState({ showRecipientModal: true })}
                buttonText="START CONVERSATION"
              />
            </NoResultsContainer>
          )}

          {currentConversation && (
            <ChatContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <RecipientContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      name={currentConversation.other_user}
                      color={currentConversation.color}
                    />

                    <RecipientName
                      onClick={() => {
                        this.goToSupplier(
                          currentConversation.supplier_id,
                          settings.account_type
                        );
                      }}
                    >
                      {currentConversation.other_user}
                    </RecipientName>
                  </div>

                  <ToggleSidebar
                    onClick={() =>
                      this.setState((p) => ({ expanded: !p.expanded }))
                    }
                  >
                    {!this.state.expanded ? (
                      <FiSidebar color={theme.colors.main} />
                    ) : (
                      <FiX color={theme.colors.main} />
                    )}
                  </ToggleSidebar>
                </RecipientContainer>

                <Messages conversation={currentConversation} />

                <InputChatContainer>
                  <Input
                    setText={(text) => this.setState({ text })}
                    sendMessage={(event) => {
                      event.preventDefault();
                      this.sendMessage(currentConversation.other_user_id);
                    }}
                    text={this.state.text}
                  />
                </InputChatContainer>
              </div>
            </ChatContainer>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
  conversations: getConversations(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchHasUnreadMessage,
      addMessageToConversation,
      fetchConversations,
      markConversationRead,
      acceptInboxAgreement,
      getOrCreateChat,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
