import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import SkeletonLoader from "./SkeletonLoader";
import { getSettings } from "../Selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHasUnreadMessage } from "../Actions";
import { getSettings as getSupplierSettings } from "../views/supplier/SupplierSelectors";
import { UnselectableText } from "./Container";
import { Show } from "./Show";
import { Badge } from "./Badge";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const StyledSideBarItem = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${(props) => (props.active ? "1.0" : "0.7")};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const SidebarItemText = styled(UnselectableText)`
  font-size: 14px;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  color: white;
  margin-left: 10px;
  white-space: nowrap;
`;

const LabelPopup = styled.div`
  position: absolute;
  padding: 8px;
  left: 50px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  display: flex;
  color: ${theme.colors.medDarkGrey};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
`;

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
  unreadMessage: fetchHasUnreadMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchHasUnreadMessage }, dispatch),
});

export function SidebarItem(props) {
  const [badgeNew, setBadgeNew] = useLocalStorage("badgeNew", true);

  const [hover, setHover] = useState(false);

  const { badge = {} } = props;

  let hasBadge = false;

  if (
    "text" in badge &&
    "gradient" in badge &&
    "finishingColor" in badge.gradient
  ) {
    hasBadge = true;
  }

  let hasUnreadMessage = false;

  if (props.settings && props.supplierSettings) {
    hasUnreadMessage =
      props.settings.has_unread_messages ||
      props.supplierSettings.has_unread_messages;
  }

  const notificationDot =
    props.text === "Inbox" && hasUnreadMessage ? " ‼️" : "";

  return (
    <StyledSideBarItem
      onClick={() => {
        props.onClick();
        setBadgeNew(false);
      }}
      active={props.active}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.icon}
      {props.showLabels && (
        <SidebarItemText>{props.text + notificationDot}</SidebarItemText>
      )}
      {/* &nbsp; &nbsp; */}
      {/* SPECIAL ICONS FOR SALES, ETC. */}
      {hover && !props.showLabels && (
        <LabelPopup>
          {props.text}{" "}
          <Show when={hasBadge && badgeNew}>
            <Badge text={badge.text} gradient={badge.gradient} />
          </Show>
        </LabelPopup>
      )}

      <Show when={hasBadge && badgeNew && props.showLabels}>
        <Badge text={badge.text} gradient={badge.gradient} />
      </Show>

      <Show when={hasBadge && badgeNew && !props.showLabels}>
        <Badge.NotificationDot
          backgroundColor={
            hasBadge && badgeNew ? badge.gradient.finishingColor : "white"
          }
        />
      </Show>
    </StyledSideBarItem>
  );
}

const SidebarItemSkeleton = () => <SidebarItemSkeletonWrapper light />;

SidebarItem.Skeleton = SidebarItemSkeleton;

export default connect(mapStateToProps, mapDispatchToProps)(SidebarItem);

const SidebarItemSkeletonWrapper = styled(SkeletonLoader)`
  height: 32px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 4px;

  @media (max-width: 800px) {
    height: 42px;
  }
`;
