import React, { Component } from "react";
import Price from "./Price";
import { Row } from "./Layout";
import styled from "styled-components";
import canadaIcon from "../images/canadian-flag.png";
import USIcon from "../images/american-flag.png";
import { theme } from "../modules/Theme";
import Hint from "./Hint";

export const Flag = styled.img`
  height: 15px;
  border-radius: 2px;
  margin-left: 7px;

  @media (max-width: 800px) {
    height: 13px;
    margin-left: 5px;
  }
`;

export const ProductAttributeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ProductAttributeValue = styled.div`
  font-size: 16px;
  color: #686868;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  ${(p) => p.weight400 && "font-weight: 400;"}
  ${(p) => p.weight500 && "font-weight: 500;"}
  ${(p) => p.orangeText && "color: #e48832;"}
`;

export const AttributeLabel = styled.div`
  font-size: 16px;
  color: #b1b1b1;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 175px;

  @media (max-width: 800px) {
    font-size: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const NonPrice = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  ${(p) => p.orange && "color: #e48832;"}
  ${(p) => p.green && `color: ${theme.colors.main};`}
  ${(p) => p.red && "color: #be4958;"}
  font-weight: ${(p) => (p.bold ? "500" : "300")};
`;

const LabelSpan = styled.span`
  ${(p) =>
    p.sub &&
    `
  margin-left: 5px;
  opacity: 0.6;
  font-size: 14px;
`}
`;

class ProductAttribute extends Component {
  render() {
    const isPrice =
      this.props.isPrice &&
      this.props.value !== "Not offered" &&
      this.props.value !== "Free";

    return (
      <ProductAttributeWrapper>
        <AttributeLabel>
          <Row style={{ alignItems: "center" }}>
            <LabelSpan sub={this.props.sub}>{this.props.label}</LabelSpan>

            {this.props.shipping && this.props.shipping !== "INTL" && (
              <Flag src={this.props.shipping === "CA" ? canadaIcon : USIcon} />
            )}

            {this.props.hint && <Hint hint={this.props.hint} narrow="true" />}
          </Row>
        </AttributeLabel>

        <ProductAttributeValue redText={this.props.redText}>
          {isPrice ? (
            <Price
              label={this.props.label}
              data={this.props.value}
              convert={this.props.convert}
              badgeClick={this.props.badgeClick}
              orange={this.props.orange}
            />
          ) : (
            <NonPrice
              green={this.props.green}
              bold={this.props.bold}
              orange={this.props.orange}
              red={this.props.redText}
            >
              {this.props.value}
            </NonPrice>
          )}
        </ProductAttributeValue>
      </ProductAttributeWrapper>
    );
  }
}

export default ProductAttribute;
