import styled from "styled-components";
import { theme } from "../modules/Theme";
import React, { Component } from "react";
import Text from "../modules/Text";

export const HelpIconDiv = styled.div`
  font-size: 16px;
  color: ${theme.colors.main};
  text-align: center;
  font-weight: 500;
  width: 48px;
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.main};
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.mainDark};
    border: 1px solid ${theme.colors.mainDark};
  }
`;

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  flex-direction: column;
  z-index: 100;
  cursor: auto;
`;

export const IframeDesktop = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const IframeMobile = styled.div`
  display: none;

  @media screen and (max-width: 800px) {
    display: initial;
  }
`;

class HelpIcon extends Component {
  state = { showModal: false };

  showModal = (e, type, bool) => {
    e.stopPropagation();
    this.setState({ showModal: bool });
  };

  preventClicks = (e) => {
    e.stopPropagation();
    this.setState({ showModal: false });
  };

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({ showModal: false });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { type, text, onClick } = this.props;
    return (
      <React.Fragment>
        {text ? (
          <Text
            onClick={(e) => this.openLink(e, type, true)}
            extra={`color: ${
              theme.colors.main
            }; font-size: 20px; width: 350px; text-align: ${
              this.props.left ? "left" : "right"
            }; cursor: pointer;`}
          >
            {text}
          </Text>
        ) : (
          <HelpIconDiv onClick={onClick}>?</HelpIconDiv>
        )}
      </React.Fragment>
    );
  }
}

export default HelpIcon;
