import React from "react";
import { BsEnvelope } from "react-icons/bs";
import { FiTruck, FiStar } from "react-icons/fi";
import styled from "styled-components";
import { Show } from "../../../../../components/Show";
import { SupplierContactInfo } from "./SupplierContactInfo";
import { SupplierStatsContainer } from "./SupplierStatsContainer";
import SupplierReviewsModal from "../../../../../components/SupplierReviewsModal";
import { ReactPortal } from "../../../../../components/ReactPortal";
import { useFavoriteSupplier } from "../../../../../hooks";

const Container = styled("div")`
  background-color: #fafafa;
  padding: 1.5rem;
  margin-bottom: 20px;

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;

const MiddleContainer = styled("div")`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    gap: 20px;
    flex: 1;
  }
`;

const Card = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Desc = styled("p")`
  font-size: 13px;
  line-height: 18px;
`;

const Title = styled(Desc)`
  font-weight: bold;
`;

export const SupplierBanner = ({ store = {}, supplier, hideForSupplier }) => {
  const {
    handleFavoriteSupplier,
    handleUnfavoriteSupplier,
    isFavorite,
    isLoading,
  } = useFavoriteSupplier(supplier.favourite, store.id, supplier.id);

  const [showModal, setShowModal] = React.useState(false);

  const {
    logo = "",
    name: supplierName = "",
    short_description: slogan = "",
    website = "",
    reviews = {},
    stats: {
      shipments_delayed_count: shipmentsDelayedCount,
      shipped_orders: shippedOrders,
      mean_rating: meanRating,
      mean_reply_time: meanReplyTime,
    } = {},
  } = supplier;

  // advantages
  const smoothShipping =
    (shipmentsDelayedCount / shippedOrders) * 100 < per(shippedOrders);
  const fastReplies =
    meanReplyTime === null ? false : getDays(meanReplyTime) < 2; // usually replies within 2 days
  const greatReviews = parseFloat(meanRating) > 4.8;

  // star supplier
  const isStar = smoothShipping && fastReplies && greatReviews;

  // advantages array
  const transformedAdvantages = advantages.map((a) => {
    if (a.title === "Great Reviews") {
      return {
        ...a,
        render: greatReviews,
      };
    } else if (a.title === "Fast Replies") {
      return {
        ...a,
        render: fastReplies,
      };
    } else {
      return {
        ...a,
        render: smoothShipping,
      };
    }
  });

  return (
    <Container>
      <Show when={showModal}>
        <ReactPortal wrapperId="supplier_reviews_modal">
          <SupplierReviewsModal
            supplier={supplier}
            hideModal={() => setShowModal(false)}
          />
        </ReactPortal>
      </Show>

      <SupplierStatsContainer
        logo={logo}
        supplierName={supplierName}
        slogan={slogan}
        reviews={reviews}
        shippedOrders={shippedOrders}
        isStar={isStar}
        openModal={() => setShowModal(true)}
        isLoading={isLoading}
        isFavorite={isFavorite}
        onFavorite={handleFavoriteSupplier}
        onUnfavorite={handleUnfavoriteSupplier}
        hideForSupplier={hideForSupplier}
      />

      <MiddleContainer>
        {transformedAdvantages.map((item) => {
          const Icon = item.icon;

          if (!item.render) return null;

          return (
            <Card key={item.id}>
              <Title>{item.title}</Title>
              <Icon size={16} color="#32968e" />
              <Desc>{item.description}</Desc>
            </Card>
          );
        })}
      </MiddleContainer>

      <SupplierContactInfo
        logo={logo}
        supplierName={supplierName}
        website={website}
      />
    </Container>
  );
};

function per(num, amount = 10) {
  return (num * amount) / 100;
}

function getDays(time) {
  if (typeof time === "string") {
    return Math.floor(Number(time) / 1440);
  }
  return Math.floor(time / 1440);
}

const advantages = [
  {
    id: 1,
    title: "Smooth Shipping",
    icon: FiTruck,
    description: "Has a history of shipping on time with tracking.",
  },
  {
    id: 2,
    title: "Fast Replies",
    icon: BsEnvelope,
    description: "Has a history of replying to messages quickly.",
  },
  {
    id: 3,
    title: "Great Reviews",
    icon: FiStar,
    description: "Average review rating is 4.8 or higher.",
  },
];
