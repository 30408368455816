import React, { Component } from "react";
import ProductAttribute from "../../../components/ProductAttribute";
import CurrencyToggle from "../../../views/orders/components/CurrencyToggle";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { get, isEmpty } from "lodash";
import Text from "../../../modules/Text";
import { Column } from "../../../components";
import { Modal } from "../../../components/Modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSettings } from "../../../Selectors";
import { addQuantityPropertyToProductObject } from "../../../utils";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

const ShippingTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${theme.colors.medDarkGrey};
`;

const ShippingOptionExclusions = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.medGrey};
  margin-bottom: 3px;
`;

const ShippingOptionDeliveryDays = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
`;

const ShippingOptionsContainer = styled.div`
  margin-bottom: 10px;
`;

const ShippingRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  align-content: flex-end;
`;

const VariantOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const VariantTypeText = styled.div`
  margin-right: 20px;
  font-weight: 500;
  width: 50px;
`;

const VariantTypeWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
`;

const VariantOption = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  padding: 5px;
  border-radius: 5px;
  font-weight: 300;
  margin-right: 10px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;

  ${(p) => p.outOfStock && `color: ${theme.colors.lightBorder};`}

  &:hover {
    ${(p) =>
      !p.selected &&
      !p.outOfStock &&
      `
          border: 1px solid #93aaa8;
        `}
  }
  ${(p) =>
    p.selected
      ? `
          border: 1px solid #39a698;
          color: #39a698;
    `
      : ""}

  ${(p) =>
    p.selected &&
    p.outOfStock &&
    `
      border: 1px solid #be4958;
      color: #be4958;
    `}
`;

class PricingSection extends Component {
  state = {
    activeVariant: null,
    activeVariantID: null,
    retailPrice: null,
    quantity: get(this.props, ["product", "total_quantity"]),

    convertPrices: false,
    helpModals: {
      price: false,
      minSellingPrice: false,
      stockLevels: false,
      priceWithShipping: false,
    },
  };

  // Triggered when the user clicks on a variant
  selectVariantOption = (variantID) => {
    const selectedVariant = this.props.product.variants.filter(
      (variant) => variant.id === variantID
    );
    // we check whether the variant that was clicked was already selected
    // if so, we clear the state, to reflect that no variant is selected
    this.state.activeVariantID === variantID
      ? this.setState({
          activeVariantID: null,
          variantPrice: null,
          retailPrice: null,
          quantity: get(this.props, ["product", "total_quantity"]),
        })
      : // if it wasn't already selected, we set it as the selected variant
        this.setState({
          activeVariantID: variantID,
          variantPrice: selectedVariant[0].price,
          retailPrice: selectedVariant[0].retail_price,
          quantity: selectedVariant[0].quantity,
        });
  };

  render() {
    const product = addQuantityPropertyToProductObject(this.props.product);
    const convert = this.state.convertPrices;
    const isValidCurrency = ["USD", "CAD"].includes(product.store_currency);

    let currentMatch = null;

    let lowestPrice = get(product, ["price_range", "low"]);
    let highestPrice = get(product, ["price_range", "high"]);

    let lowestRetailPrice = get(product, ["retail_price_range", "low"]);
    let highestRetailPrice = get(product, ["retail_price_range", "high"]);

    let priceRange = `$${lowestPrice.original_amount} - $${highestPrice.original_amount} ${lowestPrice.original_currency}`;
    let retailPriceRange = `$${lowestRetailPrice.original_amount} - $${highestRetailPrice.original_amount} ${lowestRetailPrice.original_currency}`;

    if (lowestPrice.original_amount === highestPrice.original_amount) {
      priceRange = `$${lowestPrice.original_amount} ${lowestPrice.original_currency}`;
    }

    if (
      lowestRetailPrice.original_amount === highestRetailPrice.original_amount
    ) {
      retailPriceRange = `$${lowestRetailPrice.original_amount} ${lowestRetailPrice.original_currency}`;
    }

    if (convert) {
      priceRange = `$${lowestPrice.amount} - $${highestPrice.amount} ${lowestPrice.currency}`;

      if (lowestPrice.amount === highestPrice.amount) {
        priceRange = `$${lowestPrice.amount} ${lowestPrice.currency}`;
      }

      retailPriceRange = `$${lowestRetailPrice.amount} - $${highestRetailPrice.amount} ${lowestRetailPrice.currency}`;

      if (lowestRetailPrice.amount === highestRetailPrice.amount) {
        retailPriceRange = `$${lowestRetailPrice.amount} ${lowestRetailPrice.currency}`;
      }
    }

    product.variants.forEach((variant) => {
      if (variant.active) {
        currentMatch = variant;
      }
    });

    const weights = {
      xxs: 1,
      "2xs": 1,
      "000": 1,
      "extra extra small": 1,
      "00": 2,
      xs: 2,
      "extra small": 2,
      "x-small": 2,
      s: 3,
      small: 3,
      m: 4,
      medium: 4,
      l: 5,
      large: 5,
      xl: 6,
      "x-large": 6,
      "extra large": 6,
      xxl: 7,
      "xx-large": 7,
      "2xl": 7,
      "2x-large": 7,
      "extra extra Large": 7,
      "3xl": 4,
      "3x-large": 4,
      "4xl": 5,
      "4x-large": 6,
    };

    let sorted_variants = function (variants) {
      let newArr = [];
      for (let i = 0; i < variants.length; i++) {
        let variant = variants[i];
        let nameChunks = variant.name.toLowerCase().split(" ");

        if (/^-?[0-9]+$/.test(nameChunks[0])) {
          variant.weight = parseInt(nameChunks[0]);
        } else {
          for (let j = 0; j < nameChunks.length; j++) {
            if (Object.keys(weights).includes(nameChunks[j])) {
              variant.weight = weights[nameChunks[j]];
              break;
            } else {
              variant.weight = 23;
            }
          }
        }
        newArr.push(variants[i]);
      }
      return newArr.sort((a, b) => a.weight - b.weight);
    };

    return (
      <div>
        {product.variants.length > 0 && (
          <React.Fragment>
            <VariantOptions>
              <VariantTypeWrapper>
                <VariantTypeText>Variants</VariantTypeText>

                {product.variants.length > 6 && (
                  <select
                    style={{
                      fontSize: "18px",
                      width: "200px",
                      padding: "10px",
                    }}
                    onChange={(e) =>
                      this.selectVariantOption(parseInt(e.target.value, 10))
                    }
                  >
                    {sorted_variants(product.variants).map((variant) => (
                      <option
                        key={variant.id}
                        selected={variant.active}
                        value={variant.id}
                      >
                        {variant.quantity === 0
                          ? `${variant.name} - OUT OF STOCK`
                          : variant.name}
                      </option>
                    ))}
                  </select>
                )}

                {/* <Select
                  value={{
                    label: "None",
                    value: get(store, "global_pricing_use_retail_price"),
                  }}
                  options={product.variants.map((variant) => ({
                    value: false,
                    label:
                      variant.quantity === 0
                        ? `${variant.name} - OUT OF STOCK`
                        : variant.name,
                  }))}
                  onChange={(data) =>
                    this.props.selectVariantOption(parseInt(data.value))
                  }
                /> */}

                {product.variants.length <= 6 && (
                  <VariantOptions>
                    {sorted_variants(product.variants).map((variant) => {
                      return (
                        <VariantOption
                          key={variant.id}
                          selected={this.state.activeVariantID === variant.id}
                          outOfStock={variant.quantity === 0}
                          onClick={() => this.selectVariantOption(variant.id)}
                        >
                          {variant.name}
                        </VariantOption>
                      );
                    })}
                  </VariantOptions>
                )}
              </VariantTypeWrapper>
            </VariantOptions>

            <ProductAttribute
              label="DropCommerce Price"
              value={
                this.state.variantPrice ? this.state.variantPrice : priceRange
              }
              orange
              bold
              isPrice={!!this.state.activeVariantID}
              weight400
              convert={convert}
              badgeClick={() => this.setState({ helpModal: "price" })}
            />

            <ProductAttribute
              label="Minimum Retail Price"
              value={
                this.state.retailPrice
                  ? this.state.retailPrice
                  : retailPriceRange
              }
              isPrice={!!this.state.activeVariantID}
              weight400
              convert={convert}
              badgeClick={() => this.setState({ helpModal: "retailPrice" })}
            />

            <ProductAttribute
              label={
                !this.state.activeVariantID
                  ? "Variants Quantity"
                  : "Variant Quantity"
              }
              value={this.state.quantity}
              redText={get(currentMatch, "quantity") === 0}
              badgeClick={() => this.setState({ helpModal: "stockLevels" })}
            />

            <ProductAttribute
              label="Available Quantity"
              value={product.quantity}
            />

            <ShippingRow>
              <ShippingTitle>Shipping</ShippingTitle>
            </ShippingRow>

            {isEmpty(product.shipping_options) && (
              <div
                style={{ marginBottom: "10px", color: theme.colors.darkRed }}
              >
                This supplier has not set any shipping rates. Please contact
                support@dropcommerce.com
              </div>
            )}

            {Object.keys(product.shipping_options).map((option, i) => {
              const currentOption = product.shipping_options[option];

              const isUS = currentOption.country_code === "US";
              const freeShipping =
                get(this.props, ["settings", "store", "free_shipping"]) && isUS;

              return (
                <ShippingOptionsContainer key={i}>
                  {freeShipping && (
                    <Column>
                      <Text>Free Shipping On Your First 10 US Orders*</Text>

                      <Text.Small
                        margin="2px 0 10px 0"
                        extra="width: 200px"
                        link
                        onClick={() =>
                          this.setState({ showFreeShippingModal: true })
                        }
                      >
                        Click to learn more
                      </Text.Small>

                      {this.state.showFreeShippingModal && (
                        <Modal
                          maxWidth="600px"
                          hide={() =>
                            this.setState({ showFreeShippingModal: false })
                          }
                        >
                          <Text.Large margin="0 0 5px 0" bold>
                            Free Shipping On Your First 10 US Orders*
                          </Text.Large>

                          <Text.Small light margin="0 0 10px 0">
                            *Growth and Professional plan only
                          </Text.Small>

                          <Text light>
                            To help you earn more profit, DropCommerce is
                            covering the cost of shipping your first 10 US
                            orders!
                          </Text>

                          <Text light margin="10px 0 0 0">
                            This will happen automatically at the time of order
                            payment, so no work is required on your end!
                          </Text>
                        </Modal>
                      )}
                    </Column>
                  )}

                  <ProductAttribute
                    label={currentOption.country}
                    value={currentOption.price}
                    weight400
                    // value={freeShipping ? "Free" : currentOption.price}
                    // bold={freeShipping}
                    // green={freeShipping}
                    // isPrice={!freeShipping}
                    isPrice
                    convert={convert}
                    shipping={currentOption.country_code}
                  />

                  <ShippingOptionDeliveryDays style={{ marginBottom: "3px" }}>
                    {`$${
                      convert
                        ? currentOption.additional_price.amount.toFixed(2)
                        : currentOption.additional_price.original_amount.toFixed(
                            2
                          )
                    } for additional items`}
                  </ShippingOptionDeliveryDays>

                  {currentOption.excluded_regions && (
                    <ShippingOptionExclusions>
                      {`*Excludes ${currentOption.excluded_regions}`}
                    </ShippingOptionExclusions>
                  )}

                  <ShippingOptionDeliveryDays>
                    {currentOption.delivery}
                  </ShippingOptionDeliveryDays>
                </ShippingOptionsContainer>
              );
            })}

            {product.more_shipping_options && (
              <div>
                <Text.Small
                  extra="cursor: pointer"
                  onClick={() => this.setState({ moreShippingOptions: true })}
                >
                  <i>More Shipping Options</i>
                </Text.Small>

                {this.state.moreShippingOptions &&
                  product.more_shipping_options.map((option, i) => (
                    <ProductAttribute
                      label={option.country}
                      value={option.price}
                      weight400
                      isPrice
                      convert={convert}
                      key={i}
                    />
                  ))}
              </div>
            )}

            {product.supplier.manufacturing_time &&
              product.supplier.manufacturing_time !== "" && (
                <ProductAttribute
                  label="Processing Time"
                  value={product.supplier.manufacturing_time}
                  hint="The average amount of time it takes a supplier to process a shipment after being paid for an order."
                />
              )}

            {product.supplier.currency !== product.store_currency &&
              isValidCurrency && (
                <CurrencyToggle
                  supplierCurrency={product.supplier.currency}
                  storeCurrency={product.store_currency}
                  convertPrices={convert}
                  toggle={() =>
                    this.setState((prevState) => ({
                      convertPrices: !prevState.convertPrices,
                    }))
                  }
                />
              )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingSection);
