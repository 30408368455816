import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import { Text, Wrapper } from "./sharedStyles";

const Number = styled("p")`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.colors.medDarkGrey};
`;

export function ProductCount({ numberOfProducts = 0 }) {
  return (
    <Wrapper>
      <Text>Products In DropCommerce</Text>

      <Number>{numberOfProducts}</Number>
    </Wrapper>
  );
}
