import React from "react";

import Text from "../../../modules/Text";
import { Container } from "../../../components";
import SupplierRefundTable from "./SupplierRefundTable";

function SupplierRefund() {
  return (
    <Container>
      <Text.Large extra="margin-bottom: 20px;">Refunds</Text.Large>
      <SupplierRefundTable />
    </Container>
  );
}

export default SupplierRefund;
