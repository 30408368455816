import {
  FETCH_ANALYTICS_RES,
  GET_SUPPLIER_MANAGEMENT_RES,
  MANAGE_SUPPLIER_REQ,
  MANAGE_SUPPLIER_RES,
  MANAGE_SUPPLIER_ERR,
  GET_EMAIL_DATA_RES,
  GENERIC_REQ,
  GENERIC_RES,
  GENERIC_ERR,
  FETCH_UNCATEGORIZE_PRODUCTS_RES,
  UPDATE_UNCATEGORIZE_PRODUCT,
  SELECT_ALL_UNCATEGORIZE_PRODUCTS,
  SAVE_UNCATEGORIZE_PRODUCTS_RES,
} from "./AdminActions";

const initialState = {
  isFetching: 0,
  analytics: {},
  issues: [],
  supplierManagement: [],
  prebuiltStores: [],
  error: {},
  audit: {},
  uncategorizeProducts: [],
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_UNCATEGORIZE_PRODUCTS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        tags: {
          ...state.tags,
          products: state.tags.products.map((product) => {
            return { ...product, changed: false };
          }),
        },
      };

    case FETCH_UNCATEGORIZE_PRODUCTS_RES:
      return {
        ...state,
        uncategorizeProducts: action.payload.products,
        isFetching: state.isFetching - 1,
        pagination: action.payload.pagination,
      };

    case SELECT_ALL_UNCATEGORIZE_PRODUCTS:
      return {
        ...state,
        uncategorizeProducts: state.uncategorizeProducts.map((product) => {
          return { ...product, active: action.payload.bool };
        }),
      };

    case UPDATE_UNCATEGORIZE_PRODUCT:
      return {
        ...state,
        uncategorizeProducts: state.uncategorizeProducts.map((product) => {
          if (product.id === action.payload.productId) {
            return { ...product, active: !product.active };
          }
          return product;
        }),
      };

    case "admin/GET_INBOX_MANAGEMENT":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        inboxManagement: action.payload,
      };

    case "admin/FETCH_AUDIT_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        audit: action.payload,
      };

    case "admin/UPDATE_AUDIT": {
      const { field, value, variantId } = action.payload;

      // UPDATE AUDIT VARIANT FIELD
      if (variantId) {
        return {
          ...state,
          audit: {
            ...state.audit,
            variants: state.audit.variants.map((v) => {
              if (v.id === variantId) {
                return { ...v, [field]: value };
              }
              return v;
            }),
          },
        };
      }

      // UPDATE AUDIT FIELD
      if (!variantId) {
        return {
          ...state,
          audit: {
            ...state.audit,
            [field]: value,
          },
        };
      }
      break;
    }

    case "admin/GET_LATE_ORDERS_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        lateOrders: action.payload.lateOrders,
      };

    case "admin/GET_PREBUILT_STORES_RES":
      return {
        ...state,
        prebuiltStores: action.payload.prebuiltStores,
        isFetching: false,
      };

    case "admin/UPDATE_PREBUILT_STORE_REQ":
      return {
        ...state,
        prebuiltStores: state.prebuiltStores.map((store) => {
          if (store.id === action.payload.store_id) {
            return { ...store, [`isFetching_${action.payload.action}`]: true };
          }
          return store;
        }),
      };

    case "admin/UPDATE_PREBUILT_STORE_RES":
      return {
        ...state,
        prebuiltStores: state.prebuiltStores.map((store) => {
          if (store.id === action.payload.store_id) {
            return {
              ...store,
              [action.payload.action]: true,
              [`isFetching_${action.payload.action}`]: false,
            };
          }
          return store;
        }),
      };

    case "admin/UPDATE_PREBUILT_STORE_ERR":
      return {
        ...state,
        prebuiltStores: state.prebuiltStores.map((store) => {
          if (store.id === action.payload.store_id) {
            return {
              ...store,
              [`isFetching_${action.payload.action}`]: false,
            };
          }
          return store;
        }),
      };
    // Patrick, this case is duplicate (consider deleting)
    // case "admin/UPDATE_PREBUILT_STORE_RES":
    //   return {
    //     ...state,
    //     prebuiltStores: state.prebuiltStores.map((store) => {
    //       if (store.id === action.payload.store_id) {
    //         return { ...store, [action.payload.action]: true };
    //       }
    //       return store;
    //     }),
    //   };

    case "admin/CHOOSE_CAMPAIGN":
      return {
        ...state,
        emailData: {
          ...state.emailData,
          campaigns: state.emailData.campaigns.map((campaign) => {
            return {
              ...campaign,
              selected: campaign.value === action.payload.campaignId,
            };
          }),
        },
      };

    case "admin/CHOOSE_OFFER":
      return {
        ...state,
        emailData: {
          ...state.emailData,
          offers: state.emailData.offers.map((offer) => {
            return {
              ...offer,
              selected: offer.value === action.payload.offerId,
            };
          }),
        },
      };

    case "admin/CHOOSE_VERSION":
      return {
        ...state,
        emailData: {
          ...state.emailData,
          campaign_versions: state.emailData.campaign_versions.map(
            (version) => {
              return {
                ...version,
                selected: version.id === action.payload.versionId,
              };
            }
          ),
        },
      };

    case "admin/CHOOSE_USER_GROUP":
      return {
        ...state,
        emailData: {
          ...state.emailData,
          user_groups: state.emailData.user_groups.map((userGroup) => {
            return {
              ...userGroup,
              selected: userGroup.value === action.payload.userGroupValue,
            };
          }),
        },
      };

    case "admin/GET_ISSUES_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        issues: action.payload.issues,
      };

    case GET_EMAIL_DATA_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        emailData: action.payload.emailData,
      };

    case MANAGE_SUPPLIER_REQ:
      return {
        ...state,
        supplierManagement: state.supplierManagement.map((supplier) => {
          if (supplier.id === action.payload.supplier) {
            return {
              ...supplier,
              [`isFetching_${action.payload.action}`]: true,
            };
          }
          return supplier;
        }),
      };

    case MANAGE_SUPPLIER_ERR:
      return {
        ...state,
        supplierManagement: state.supplierManagement.map((supplier) => {
          if (supplier.id === action.payload.supplier) {
            return {
              ...supplier,
              [`isFetching_${action.payload.action}`]: false,
            };
          }
          return supplier;
        }),
      };

    case MANAGE_SUPPLIER_RES:
      return {
        ...state,
        supplierManagement: state.supplierManagement.map((supplier) => {
          if (supplier.id === action.payload.supplier) {
            let newSupplier = {
              ...supplier,
              [`isFetching_${action.payload.action}`]: false,
            };

            if (action.payload.action === "rejected") {
              return { ...newSupplier, rejected: true, status: "REJECTED" };
            }

            if (action.payload.action === "initial_approval") {
              return { ...newSupplier, approved: true };
            }

            if (action.payload.action === "set_live") {
              return { ...newSupplier, active: true };
            }
          }
          return supplier;
        }),
      };

    case GET_SUPPLIER_MANAGEMENT_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        supplierManagement: action.payload.suppliers,
      };

    case FETCH_ANALYTICS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        analytics: action.payload,
      };

    case GENERIC_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
      };

    case GENERIC_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
      };

    case GENERIC_ERR:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default admin;
