import React from "react";

import Text from "../modules/Text";

const NotFound = () => {
  return (
    <div id="wrapper">
      <div id="info">
        <Text.Large style={{ padding: "30px", fontSize: "20px" }}>
          Oops, we couldn't find that
        </Text.Large>
      </div>
      <img
        style={{ filter: "grayscale(90%) opacity(50%)" }}
        src="https://images.freeimages.com/images/small-previews/a42/box-1-1419327.jpg"
        alt="Empty Box"
      />
    </div>
  );
};

export default NotFound;
