import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { withRouter } from "react-router-dom";
import Button from "./Button";
import searchIcon from "../images/icons/feather/search-white.svg";

class NoResults extends Component {
  render() {
    const {
      title,
      message,
      buttonText,
      buttonLink,
      buttonTwoText,
      buttonTwoLink,
    } = this.props;
    return (
      <NoResultsWrapper noBorder>
        <SearchIconWrapper>
          <SearchIcon src={searchIcon} />
        </SearchIconWrapper>

        <NoResultsTitle>{title}</NoResultsTitle>
        <NoResultsMessage>{message}</NoResultsMessage>
        <NoResultsButtonRow>
          {buttonText && (
            <NoResultsButton
              text={buttonText}
              type="main"
              onClick={buttonLink}
            />
          )}
          {buttonTwoText && (
            <NoResultsButton
              hollow
              text={buttonTwoText}
              type="main"
              onClick={buttonTwoLink}
            />
          )}
        </NoResultsButtonRow>
      </NoResultsWrapper>
    );
  }
}

export default withRouter(NoResults);

export const SearchIconWrapper = styled.div`
  background: ${theme.colors.lightGrey};
  padding: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIcon = styled.img`
  height: 50px;
`;

export const NoResultsWrapper = styled.div`
  background: white;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  ${(p) => p.noBorder && "border: 0px;"}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 640px) {
    padding: 50px;
  }
`;

export const NoResultsTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.darkGrey};
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    font-size: 22px;
  }
`;

export const NoResultsMessage = styled.div`
  font-size: 16px;
  color: ${theme.colors.medGrey};
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

export const NoResultsButtonRow = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const NoResultsButton = styled(Button)`
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  @media screen and (max-width: 800px) {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;
