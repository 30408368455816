import React, { Component } from "react";
import { getRequest } from "../../../../../modules/API";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import Select from "react-select";
import {
  updateSupplier,
  saveSupplierSettings,
} from "../../../../../views/supplier/SupplierActions";
import {
  getSettings,
  getShippingOptions,
  getIsFetchingShippingOptions,
} from "../../../../../views/supplier/SupplierSelectors";
import { ButtonNew, Label, SettingsBack } from "../../../../../components";
import { buildObjectFromFields } from "../../../../../modules/Format";
import ShippingOptionsList from "./ShippingOptionsList";
import createNotification from "../../../../../modules/Notification";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  shippingOptions: getShippingOptions(state),
  isFetchingShippingOptions: getIsFetchingShippingOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSupplier,
      saveSupplierSettings,
    },
    dispatch
  ),
});

class ShippingOptions extends Component {
  state = {
    saveState: null,
    shippingOptions: [],
    isFetching: false,
  };

  getShippingOptions = () => {
    this.setState({ isFetching: true });
    getRequest({ url: "api/shipping-options/?supplier-only=1" }).then((res) =>
      this.setState({ shippingOptions: res.data, isFetching: false })
    );
  };

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: "changed" });
  };

  saveSettings = () => {
    const settings = get(this.props, ["settings"]);

    const data = buildObjectFromFields(settings, ["manufacturing_time"]);

    this.setState({ saveState: "saving" }, () => {
      this.props.saveSupplierSettings(data).then(() => {
        this.setState({ saveState: null });
      });
      createNotification({
        type: "success",
        title: "Success",
        message: "Shipping Settings Saved",
      });
    });
  };

  componentDidMount() {
    this.getShippingOptions();
  }

  render() {
    const settings = get(this.props, ["settings"]);

    return (
      <React.Fragment>
        <SettingsBack
          text="Settings"
          back={() => this.props.history.push("/supplier/settings")}
        />
        <Label
          rowStyle={{ marginBottom: "10px", marginTop: "30px" }}
          hint="How long does it take you to prepare and ship an order after receiving payment? Stores will see this, and it will be used to calculate if orders are late."
        >
          Processing / Manufacturing Time
        </Label>

        <div style={{ width: "300px" }}>
          <Select
            options={[
              {
                value: "1-2 Days",
                label: "1-2 Days",
              },
              {
                value: "3-5 Days",
                label: "3-5 Days",
              },
              {
                value: "6-7 Days",
                label: "6-7 Days",
              },
              {
                value: "1-2 Weeks",
                label: "1-2 Weeks",
              },
              {
                value: "2-4 Weeks",
                label: "2-4 Weeks",
              },
            ]}
            value={{
              label: settings.manufacturing_time,
              value: settings.manufacturing_time,
            }}
            onChange={(data) =>
              this.updateSupplier({ manufacturing_time: data.value })
            }
          />
        </div>

        {this.state.saveState === "changed" && (
          <ButtonNew
            text="Save Change"
            type="main"
            isFetching={this.state.saveState === "saving"}
            extra="margin-top: 20px; width: 255px;"
            onClick={this.saveSettings}
          />
        )}

        <ShippingOptionsList
          shippingOptions={this.state.shippingOptions}
          isFetchingShippingOptions={this.state.isFetching}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingOptions)
);
