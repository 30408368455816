import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { ButtonNew, Column, Row } from "../../components";
import {
  OrderDetailItem,
  OrderDetailsWrapper,
  OrderInfoSection,
} from "../../views/orders/OrdersStyle.jsx";
import OrderAttribute from "../../views/orders/OrderAttribute";
import CurrencyToggle from "../../views/orders/components/CurrencyToggle";
import Text from "../../modules/Text";
import { get } from "lodash";
import Price from "../../components/Price";
import { resizeImage } from "../../modules/Format";
import { PopupModal } from "../../components/PopupModal";
import { FiAlertTriangle } from "react-icons/fi";
import Payment from "./components/Payment";
import RatingModal from "../../components/RatingModal";
import ModalRefund from "../../components/ModalRefund";

const ResponsiveDiv = styled.div`
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none"};
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  background: ${theme.colors.darkRed};
  width: 600px;
`;

const ErrorHeader = styled.div`
  font-size: 25px;
  font-weight: 400;
  color: white;
  margin-left: 15px;
`;

const ErrorType = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: white;
`;

class OrderSupplier extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      convertPrices: false,
      showShippingModal: false,
      showRefundForm: false,
      refundCreated: false,
      showReview: true,
    };
    this.createRefund = this.createRefund.bind(this);
  }

  createRefund() {
    this.setState({ refundCreated: true });
  }

  showReviewModal(order) {
    const modalDismissed = localStorage.getItem("modalDismissed") === "true";
    return order.status === "shipped" && !modalDismissed;
  }

  dismissReviewModal() {
    this.setState((current) => ({ ...current, showReview: false }));
    localStorage.setItem("modalDismissed", "true");
  }

  render() {
    const { supplier, order } = this.props;
    const settings = get(this.props, "settings");
    const storeCurrency = get(settings, ["store", "currency"]);
    const isValidCurrency = storeCurrency === "CAD" || storeCurrency === "USD";

    return (
      <OrderDetailsWrapper>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Text bold style={{ marginBottom: "10px" }}>
            {supplier.name}
          </Text>

          <div>
            {this.state.showShippingModal && (
              <PopupModal
                originalRate={supplier.shipping.original_amount}
                hide={() =>
                  this.setState({
                    showShippingModal: false,
                  })
                }
              />
            )}

            <Payment
              supplier={supplier}
              order={order}
              isPaying={this.props.isPaying}
              isPaid={this.props.isPaid}
              settings={settings}
              showConfirmation={this.props.showConfirmation}
            />

            {this.showReviewModal(order) && (
              <RatingModal
                supplier={supplier}
                hideModal={() => this.dismissReviewModal()}
              />
            )}

            {supplier.errors.length > 0 && (
              <ErrorWrapper style={{ marginTop: "10px" }}>
                <Row style={{ alignItems: "center" }}>
                  <FiAlertTriangle size="30px" color="white" />
                  <ErrorHeader>Errors</ErrorHeader>
                </Row>

                {supplier.errors.map((error, i) => (
                  <Column key={i} style={{ marginTop: "20px" }}>
                    <ErrorType>{error.type}</ErrorType>
                    <ErrorMessage>{error.message}</ErrorMessage>
                  </Column>
                ))}
              </ErrorWrapper>
            )}

            <OrderInfoSection>
              <OrderAttribute
                label="Subtotal"
                value={supplier.subtotal}
                convert={this.state.convertPrices}
                isPrice
              />

              <OrderAttribute
                label="Shipping Price"
                value={supplier.shipping}
                convert={this.state.convertPrices}
                isPrice
              />

              <OrderAttribute
                label="Amount You Owe"
                value={supplier.total}
                convert={this.state.convertPrices}
                isPrice
                weight="500"
              />

              {supplier.status === "shipped" && (
                <React.Fragment>
                  <OrderAttribute
                    label="Tracking #"
                    value={supplier.tracking}
                    weight="500"
                  />

                  <OrderAttribute
                    label="Shipping Carrier"
                    value={supplier.shipping_carrier}
                    weight="500"
                  />
                </React.Fragment>
              )}

              {supplier.currency !== storeCurrency && isValidCurrency && (
                <CurrencyToggle
                  supplierCurrency={supplier.currency}
                  storeCurrency={storeCurrency}
                  convertPrices={this.state.convertPrices}
                  toggle={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      convertPrices: !prevState.convertPrices,
                    }))
                  }
                />
              )}
            </OrderInfoSection>
          </div>
        </div>

        <div>
          <OrderDetailItem style={{ marginBottom: "10px" }}>
            <div
              style={{ width: "50px", fontWeight: "500", marginRight: "88px" }}
            >
              Image
            </div>

            <div style={{ width: "128px", fontWeight: "500" }}>Qty</div>

            <div style={{ width: "200px", fontWeight: "500" }}>Product</div>

            <ResponsiveDiv
              canHide="true"
              style={{ width: "150px", fontWeight: "500" }}
            >
              Price Per Item
            </ResponsiveDiv>

            <ResponsiveDiv
              canHide="true"
              style={{ width: "150px", fontWeight: "500" }}
            >
              Shipping Per Item
            </ResponsiveDiv>
          </OrderDetailItem>

          {supplier.products.map((product, i) => {
            const showAdjustedRate =
              product.shipping_rate &&
              product.adjusted_shipping_rate &&
              product.adjusted_shipping_rate.original_amount !==
                product.shipping_rate.original_amount;

            const isRefundBeingProcessed =
              product.has_refunds || this.state.refundCreated;

            return (
              <React.Fragment key={i}>
                {this.state.showRefundForm && (
                  <ModalRefund
                    createRefund={this.createRefund}
                    supplier={supplier}
                    order={order}
                    product={product}
                    hideModal={() =>
                      this.setState({
                        showRefundForm: false,
                      })
                    }
                  />
                )}

                <OrderDetailItem style={{ marginBottom: "10px" }}>
                  <img
                    alt="pic. of the product"
                    src={resizeImage(product.image, 150)}
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "cover",
                      marginRight: "88px",
                    }}
                  />

                  <div style={{ width: "128px" }}>{product.quantity}</div>

                  <div
                    style={{
                      width: "200px",
                      color:
                        supplier.status !== "shipped" &&
                        (product.out_of_stock || !product.active)
                          ? "red"
                          : null,
                    }}
                  >
                    {product.title}
                  </div>

                  <ResponsiveDiv canHide="true" style={{ width: "150px" }}>
                    <Price
                      data={product.price}
                      convert={this.state.convertPrices}
                    />
                  </ResponsiveDiv>

                  <ResponsiveDiv
                    canHide="true"
                    style={{ width: "150px", fontWeight: "500" }}
                  >
                    <Price
                      slashData={showAdjustedRate && product.shipping_rate}
                      slashHint={product.adjustment_reason}
                      data={product.adjusted_shipping_rate}
                      convert={this.state.convertPrices}
                    />
                  </ResponsiveDiv>

                  {product.out_of_stock && (
                    <ButtonNew
                      type="red"
                      text="OUT OF STOCK"
                      disabled
                      extra="margin-left: 50px"
                    />
                  )}

                  {supplier.status !== "shipped" && !product.active && (
                    <ButtonNew
                      type="red"
                      text="UNAVAILABLE"
                      disabled
                      extra="margin-left: 50px"
                    />
                  )}

                  {/*{supplier.status !== "pending" &&*/}
                  {/*  supplier.status !== "cancelled" && (*/}
                  {/*    <ButtonNew*/}
                  {/*      type="main"*/}
                  {/*      text={*/}
                  {/*        isRefundBeingProcessed*/}
                  {/*          ? "Processing"*/}
                  {/*          : "Request Refund"*/}
                  {/*      }*/}
                  {/*      disabled={isRefundBeingProcessed}*/}
                  {/*      onClick={() => {*/}
                  {/*        this.setState({*/}
                  {/*          showRefundForm: true,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  )}*/}
                </OrderDetailItem>

                {product.properties &&
                  product.properties.map((property, i) => (
                    <div key={i}>
                      <strong>{property.name}: </strong>
                      {property.value}
                    </div>
                  ))}
              </React.Fragment>
            );
          })}
        </div>
      </OrderDetailsWrapper>
    );
  }
}

export default OrderSupplier;
