import React from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { theme } from "../modules/Theme";
import Spinner from "./Spinner";

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  padding-top: 30px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background: rgba(11, 28, 28, 0.7);
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 30px;
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

const Bubble = styled.div`
  width: 100%;
  margin: 0 auto;
  ${(p) => p.fullScreen && "max-width: 100%; width: 100%; height: 100%;"}
  ${(p) => p.maxWidth && `max-width: ${p.maxWidth};`}
  ${(p) => p.minHeight && `height: ${p.minHeight};`}
  ${(p) => p.fitContent && "height: fit-content; width: 500px; margin: auto;"}

  @media (max-width: 800px) {
    background: white;
    padding: 16px 0px;
    max-height: 100vh;
    max-width: 100vw;
    overflow: scroll;
  }
`;

const BubbleWhite = styled.div`
  background: white;
  padding: 35px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  max-height: 85vh;
  ${(p) => p.transparent && "padding: 0px; border-radius: 0px;"}

  @media (max-width: 800px) {
    padding: 5px 0px;
    border-radius: 0px;
    height: 100%;
    max-width: 100%;
  }
`;

export const Modal = ({
  hide,
  children,
  fullScreen,
  maxWidth,
  minHeight,
  transparent,
  isFetching,
  fitContent,
}) => (
  <Background>
    <Bubble
      fitContent={fitContent}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      minHeight={minHeight}
    >
      <FiX
        size="40"
        color={theme.colors.medLightGrey}
        onClick={hide}
        style={{ cursor: "pointer", marginBottom: "10px" }}
      />
      <BubbleWhite transparent={transparent}>
        {isFetching ? <Spinner /> : children}
      </BubbleWhite>
    </Bubble>
  </Background>
);
