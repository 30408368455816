import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { Row, Column } from "../../components";
import Text from "../../modules/Text";
import Cookies from "js-cookie";
import { fetchAffiliateReport } from "../affiliate/AffiliateActions";
import {
  getIsFetching,
  getAffiliateReport,
} from "../affiliate/AffiliateSelectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Stat, Spinner } from "../../components";
import logo from "../../images/dropcommerce-logo-2021.svg";

const Logo = styled.img`
  height: 80px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
  color: ${theme.colors.medDarkGrey};
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  color: ${theme.colors.medDarkGrey};
`;

const ColorWrapper = styled.div`
  overflow-y: auto;
  padding: 30px;
`;

const StatGroup = ({ data, margin }) => (
  <div style={{ margin: margin }}>
    <Text.Large extra="margin-bottom: 10px; margin-top: 20px;">
      {data.period}
    </Text.Large>
    <Row>
      <Stat name="Signups" value={data.signups} change={null} width="160px" />
      <Stat
        wide
        name="Trials"
        value={data.trials}
        change={null}
        width="160px"
      />
      <Stat name="Paid Users" value={data.paid} change={null} width="160px" />
      <Stat
        name="Revenue"
        value={`$${data.revenue.toFixed(2)}`}
        change={null}
        width="160px"
      />
      <Stat
        name="Commission"
        value={`$${data.commission.toFixed(2)}`}
        change={null}
        width="160px"
      />
    </Row>
  </div>
);

class AffiliateDashboard extends Component {
  componentDidMount = () => {
    this.props.fetchAffiliateReport();
    if (!Cookies.get("accessToken")) {
      this.props.history.push("/affiliate/login");
    }
  };

  logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("isAffiliate");
    this.props.history.push(`/affiliate/login`);
  };

  render() {
    const data = get(this.props, "affiliateReport");

    return (
      <ColorWrapper>
        <Logo src={logo} />
        {this.props.isFetching ? (
          <Spinner />
        ) : (
          <div>
            <Row style={{ justifyContent: "space-between" }}>
              <Column>
                <Title>{data.name}</Title>
                <Subtitle>Affiliate Dashboard</Subtitle>
              </Column>
              <Text
                light
                white
                extra="cursor:pointer; opacity: 0.6;"
                onClick={this.logout}
              >
                Logout
              </Text>
            </Row>
            {data.all_time && (
              <StatGroup data={data.all_time} margin="0 0 40px 0" />
            )}
            {data.months &&
              data.months.map((month, idx) => (
                <StatGroup key={idx} data={month} />
              ))}
          </div>
        )}
      </ColorWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  affiliateReport: getAffiliateReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchAffiliateReport }, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AffiliateDashboard)
);
