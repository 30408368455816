import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  addToImportList,
  selectVariantOption,
} from "../../views/products/ProductsActions";
import { getSettings } from "../../Selectors";
import {
  getError,
  getIsImporting,
  getProduct,
} from "../../views/products/ProductsSelectors";
import BackNavBar from "../../components/BackNavBar";
import ProductInfoSection from "../../views/products/components/ProductInfoSection";
import ProductImages from "../../views/products/components/ProductImages";
import { get } from "lodash";
import { ExpandableSection } from "../../components/ExpandableSection";
import SkeletonLoader from "../../components/SkeletonLoader";

import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { Row } from "../../components/Layout";
import Text from "../../modules/Text";
import { getRequest } from "../../modules/API";
import { trackEvent } from "../../modules/EventTracking";

export const ProductInfoSkeletonContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
  width: 100%;
`;

export const ProductImagesSkeletonContainer = styled.div`
  display: flex;
  margin-right: 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
`;

export const ProductLoadingImages = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const ProductRowColumn = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 8px;
  }
`;

export const ProductHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductWrapper = styled.div`
  background: white;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  width: 100%;
  margin-top: 15px;
  ${(props) => props.marginLeft && "margin-left: 15px"};
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

export const ProductPageContainer = styled.div`
  padding: 20px;
  padding-top: 30px;
  @media (max-width: 800px) {
    padding: 0px;
    padding-bottom: 64px;
  }
`;

export const ProductTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const TitleSkeleton = styled(SkeletonLoader)`
  height: 24px;
  width: 300px;
  min-width: 300px;
  margin-bottom: 10px;
`;

export const SupplierLogo = styled.img`
  object-fit: contain;
  width: 220px;
  height: 100px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.colors.main};
  }
  @media screen and (max-width: 800px) {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const mapStateToProps = (state) => ({
  product: getProduct(state),
  isImporting: getIsImporting(state),
  settings: getSettings(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      addToImportList,
      selectVariantOption,
    },
    dispatch
  ),
});

const defaultProps = {
  settings: {},
  product: {},
  isImporting: false,
};

class Product extends Component {
  state = {
    product: {}, // details are fetched when component mounts
    convertPrices: false,
    hintVisible: false,
    expandSupplier: false,
  };

  showConvertedPrice = () => {
    this.setState({ convertPrices: !this.state.convertPrices });
  };

  componentDidMount = () => {
    document.body.scrollTop = 0;

    getRequest({
      url: `product/?id=${this.props.match.params.productId}&store=${this.props.settings.store.id}`,
    }).then((response) => {
      trackEvent("View Product");
      this.setState({ product: response.data.product });
    });
  };

  renderMain = () => {
    const product = this.state.product;

    const returnPolicy =
      product.supplier.return_policy === ""
        ? "None"
        : product.supplier.return_policy;

    return (
      <div>
        <ProductRowColumn>
          {product.images.length > 0 && (
            <ProductImages product={product} mouseEnter={this.mouseEnter} />
          )}
          <ProductWrapper marginLeft>
            {product.active ? (
              <ProductInfoSection
                showApprovalRequirements={this.props.showApprovalRequirements}
                product={product}
                settings={this.props.settings}
                convert={this.state.convertPrices}
                showConvertedPrice={this.showConvertedPrice}
                addToImportList={this.props.addToImportList}
                selectVariantOption={this.props.selectVariantOption}
              />
            ) : (
              <Text extra="padding: 20px;">
                This product is no longer available.
              </Text>
            )}
          </ProductWrapper>
        </ProductRowColumn>

        <ExpandableSection title="Description" defaultExpanded>
          <div
            className="product-page-description"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </ExpandableSection>

        <ExpandableSection title="Return Policy" defaultExpanded>
          {returnPolicy}
        </ExpandableSection>
      </div>
    );
  };

  render() {
    const supplier = get(this.state, ["product", "supplier"]);

    // Patch for NFT demo
    const location = window.location.toString();

    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    return (
      <ProductPageContainer>
        <div>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <div>
              <BackNavBar
                title="BACK"
                onClick={() => this.props.history.goBack()}
              />
              {get(this.state, ["product", "title"]) ? (
                <ProductTitle>{this.state.product.title}</ProductTitle>
              ) : (
                <TitleSkeleton />
              )}
              {supplier && !isGlobal && (
                <Text.Small
                  light
                  link
                  extra="margin-top: 10px;"
                  onClick={() =>
                    this.props.history.push(`/supplier-id/${supplier.id}`)
                  }
                >
                  By {supplier.name}
                </Text.Small>
              )}
            </div>
            {supplier && !isGlobal && (
              <SupplierLogo
                src={supplier.logo}
                onClick={() =>
                  this.props.history.push(`/supplier-id/${supplier.id}`)
                }
              />
            )}
          </Row>
        </div>
        {supplier ? (
          this.renderMain()
        ) : (
          <Row style={{ marginTop: "20px" }}>
            <ProductImagesSkeletonContainer>
              <ProductLoadingImages>
                <SkeletonLoader width="140px" height="140px" border />
                <SkeletonLoader
                  width="140px"
                  height="140px"
                  style={{ marginTop: "10px" }}
                  border
                />
                <SkeletonLoader
                  width="140px"
                  height="140px"
                  style={{ marginTop: "10px" }}
                  border
                />
              </ProductLoadingImages>
              <SkeletonLoader width="440px" height="440px" border />
            </ProductImagesSkeletonContainer>
            <ProductInfoSkeletonContainer>
              <SkeletonLoader width="100%" height="440px" />
            </ProductInfoSkeletonContainer>
          </Row>
        )}
      </ProductPageContainer>
    );
  }
}

Product.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Product);
