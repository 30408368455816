import React, { Component } from "react";
import styled from "styled-components";
import ButtonNew from "./ButtonNew";
import alert from "../images/alert.png";
import Text from "../modules/Text";

export const AlertImage = styled.img`
  height: 60px;
  margin-right: 20px;
`;

export const PuppyImage = styled.img`
  height: 300px;
  margin-bottom: 30px;
  border-radius: 8px;
`;

export const BorderWrapper = styled.div`
  background: white;
  border: 1px solid #d6dedd;
  padding: 40px;
  text-align: center;
`;

class Notice extends Component {
  render() {
    return (
      <BorderWrapper>
        {this.props.puppy && (
          <PuppyImage src="https://res.cloudinary.com/dropcommerce/image/upload/w_700/v1563650410/puppy_vrnr9a.png" />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!this.props.noIcon && <AlertImage src={alert} />}
          <Text bold extra="font-size: 40px">
            {this.props.title}
          </Text>
        </div>

        <Text extra="margin: 0 auto; margin-top: 20px; margin-bottom: 35px; max-width: 600px;">
          {this.props.message}
        </Text>
        {this.props.description && (
          <Text.Small extra="margin: 0 auto; margin-top: 20px; margin-bottom: 35px; max-width: 600px;">
            {this.props.description}
          </Text.Small>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonNew
            text={this.props.buttonOneText}
            type={this.props.buttonOneType}
            onClick={() => this.props.buttonOneAction()}
            extra="padding: 16px; width: 300px; margin-right: 30px;"
            fontStyle="font-size: 22px;"
          />
          {this.props.buttonTwoAction && (
            <ButtonNew
              text={this.props.buttonTwoText}
              type={this.props.buttonTwoType}
              onClick={() => this.props.buttonTwoAction()}
              extra="padding: 16px; width: 300px;"
              fontStyle="font-size: 22px;"
            />
          )}
        </div>
      </BorderWrapper>
    );
  }
}

export default Notice;
