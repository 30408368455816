import { get } from "lodash";

const getLocalState = (state) => get(state, "admin");

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getShopifyPreview = (state) => getLocalState(state).shopifyPreview;
export const getAudit = (state) => getLocalState(state).audit;
export const getAnalytics = (state) => getLocalState(state).analytics;
export const getSuppliers = (state) => getLocalState(state).suppliers;
export const getLateOrders = (state) => getLocalState(state).lateOrders;
export const getIssues = (state) => getLocalState(state).issues;
export const getSupplierManagement = (state) =>
  getLocalState(state).supplierManagement;
export const getEmailData = (state) => getLocalState(state).emailData;
export const getPrebuiltStores = (state) => getLocalState(state).prebuiltStores;
export const getUncategorizeProducts = (state) =>
  getLocalState(state).uncategorizeProducts;
export const getPagination = (state) => getLocalState(state).pagination;
export const getInboxManagement = (state) =>
  getLocalState(state).inboxManagement;
