import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import Text from "../../modules/Text";
import { ButtonNew, Row, Column } from "../../components";
import { Modal } from "../../components/Modal";
import { FiClock, FiDollarSign, FiGrid, FiZap } from "react-icons/fi";

import checkGreen from "../../images/icons/feather/check-green.svg";
import fire from "../../images/icons/fire-emoji.png";
import Testimonials from "../../components/Testimonials";
import StoreCard from "./StoreCard";
import FAQ from "../../components/FAQ";
import { getUrlParameter } from "../../modules/Format";
import SellingPoint from "./components/SellingPoint";
import dcLogo from "../../images/dropcommerce-logo-2021-small.png";
import Checkout from "./Checkout";
import { camelCase, get, startCase } from "lodash";
import { getRequest } from "../../modules/API";
import { getSettings } from "../../Selectors";
import { fetchSettings } from "../../Actions";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSettings }, dispatch),
});

// const SpecialOffer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;
//   font-size: 18px;
//   font-weight: 400;
//   border-radius: 6px;
//   color: white;
//   background: ${theme.colors.orange};
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const MainImage = styled.img`
  height: 450px;
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
    margin: 0 10px 0 10px;
  }
`;

const BoldHeroText = styled.span`
  font-weight: 500;
`;

const HeroText = styled.div`
  font-size: 45px;
  width: 450px;
  font-weight: 300;
  @media (max-width: 800px) {
    font-size: 35px;
    width: 100%;
  }
`;

const CustomRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const DCLogo = styled.img`
  height: 25px;
`;

const FireEmoji = styled.img`
  display: flex;
  margin-right: 10px;
  height: 18px;
`;

const StoreImage = styled.img`
  border-radius: 4px;
  height: 300px;
  cursor: pointer;
  ${(p) => p.marginLeft && "margin-left: 40px;"}
  ${(p) => p.marginRight && "margin-right: 40px;"}
  max-width: 100%;
  @media (max-width: 800px) {
    height: 250px;
    margin: 0px;
    margin-top: 15px;
    ${(p) =>
      p.sellingPoint && p.imagePlacement === "left" && "margin-bottom: 30px;"};
  }
`;

const GreySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.ultraLightGrey};
  padding: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;

const WhiteBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const PackageOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 4px;
  padding: 20px;
  width: 350px;
  margin-right: 30px;
  @media (max-width: 800px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

const PackageOptionTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
`;

const PackageOptionPrice = styled.div`
  font-size: 50px;
  color: ${(p) => (p.sale ? theme.colors.main : theme.colors.medDarkGrey)};
  font-weight: 700;
  margin: 15px;
  ${(p) => p.slashed && "text-decoration: line-through;"}
`;

const PackageOptionIcon = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const PackageOptionDescription = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  margin-bottom: 5px;
  text-align: center;
`;

const PackageOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const WhatsIncludedRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ImageSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: 60px;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StoreCardsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const SellingPointColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  @media (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ErinTestimonial = styled.img`
  margin-top: 30px;
  height: 350px;
  @media screen and (max-width: 800px) {
    height: auto;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const faqData = [
  [
    {
      question: "What's included with a pre-built store?",
      answer:
        "In addition to a professional Shopify site full of great products, you also get 3 months free the DropCommerce Pro plan! That means you won't pay a subscription fee for 3 whole months!",
    },
    {
      question: "Will my store look exactly like the demo store?",
      answer:
        "To put it simply, yes! There may be small differences in the products that are available if stock / availability changes, but otherwise it will be identical. No surprises :). Once you receive the store you are welcome to make any modifications you like.",
    },
    {
      question: "Can I change the pricing of products?",
      answer:
        "Absolutely! Once we give you the store, it's yours to do whatever you want with it. You can add / remove products, change the design, etc.",
    },
  ],
  [
    {
      question: "Can I add products from other apps?",
      answer:
        "Yes, our prebuilt stores are Shopify stores like any other so you can still use other apps and product sourcing options.",
    },
    {
      question: "Will my store be like others who buy the same store?",
      answer:
        "We designed our Pre-Built stores to be great jump off points for stores - we find that each customer ends up customizing their stores to tailor them more to their niche after purchase, so no two stores are the same after purchase.",
    },
  ],
];

const PackageOption = ({ checkout, price }) => (
  <PackageOptionWrapper>
    <PackageOptionTitle>Kickstart Your Store</PackageOptionTitle>
    <PackageOptionPrice sale>{`$${price}`}</PackageOptionPrice>
    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>
        100+ Preloaded Products
      </PackageOptionDescription>
    </Row>

    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>Professional Design</PackageOptionDescription>
    </Row>

    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>
        3 Months Of Pro Plan ($267 Value)
      </PackageOptionDescription>
    </Row>

    <ButtonNew
      size="large"
      text={`Buy Now - $${price}`}
      width="200px"
      margin="20px 0 0 0"
      onClick={checkout}
    />
  </PackageOptionWrapper>
);

const ImageSection = ({ imagePlacement, title, textOne, textTwo, image }) => (
  <ImageSectionRow>
    {imagePlacement === "left" && (
      <StoreImage
        src={image}
        marginRight
        sellingPoint
        imagePlacement={imagePlacement}
      />
    )}
    <SellingPointColumn>
      <Text l bold>
        {title}
      </Text>
      <Text s light extra="margin-top: 15px; margin-bottom: 15px;">
        {textOne}
      </Text>
      <Text s light>
        {textTwo}
      </Text>
    </SellingPointColumn>
    {imagePlacement === "right" && (
      <StoreImage
        src={image}
        marginLeft
        sellingPoint
        imagePlacement={imagePlacement}
      />
    )}
  </ImageSectionRow>
);

const FireList = ({ text }) => (
  <Row>
    <FireEmoji src={fire} />
    <Text s light>
      {text}
    </Text>
  </Row>
);

class Prebuilt extends Component {
  state = { storeType: null, stores: null };

  componentDidMount = () => {
    window.tap("detect");
    let storeTypeInURL = getUrlParameter("type", this.props.location);
    if (storeTypeInURL) {
      this.setState({ storeType: storeTypeInURL });
    }

    // BOOT INTERCOM
    if (this.props.external) {
      window.Intercom("boot", {
        app: window.location.href.includes("global")
          ? "GLOBAL"
          : "DROPCOMMERCE",
        app_id: "ulrlc7kx",
      });
    }
    getRequest({
      url: `buy_premade/`,
    }).then((response) =>
      this.setState({
        stores: response.data,
      })
    );
  };

  checkout = () => {
    this.setState({ storeType: "home_decor" });
  };

  render() {
    const v = getUrlParameter("v", this.props.location);
    let textVersion = startCase(camelCase(v));

    const { settings } = this.props;
    const price = get(settings, ["global", "months_to_plan_price", [3]], 249.0);
    const onSale = get(settings, ["global", "show_sale_banner"], false);

    return (
      <div>
        {this.state.storeType && this.state.stores && (
          <Modal
            fullScreen
            maxWidth="900px"
            hide={() => this.setState({ storeType: null })}
          >
            <Checkout
              external={this.props.external}
              stores={this.state.stores}
              storeType={this.state.storeType}
              updateTheme={(type) => this.setState({ storeType: type })}
            />
          </Modal>
        )}

        <Container>
          {/* <SpecialOffer>
            This week only! All pre-built purchases come with 4+ free months of
            DropCommerce Pro!
          </SpecialOffer> */}
          <CustomRow>
            <div>
              {textVersion ? (
                <HeroText>
                  <BoldHeroText>{textVersion}</BoldHeroText>
                </HeroText>
              ) : (
                <HeroText>
                  Professional <BoldHeroText>dropshipping stores</BoldHeroText>{" "}
                  for sale
                </HeroText>
              )}

              <ButtonNew
                size="large"
                text={`Buy Now - $${price}`}
                width="200px"
                margin="20px 0 0 0"
                onClick={this.checkout}
              />
              <Row style={{ margin: "20px 0 0 0" }}>
                <Text.Small light margin="0 5px 0 0">
                  Powered by
                </Text.Small>
                <DCLogo alt="The DropCommerce Logo" src={dcLogo} />
              </Row>
            </div>
            <MainImage src="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/wireframe.svg" />
          </CustomRow>

          <CustomRow style={{ margin: "80px 0 40px 0" }}>
            <SellingPoint
              icon={<FiGrid size="40" color={theme.colors.main} />}
              title="Fast-Shipping Products"
              description="Your store will be loaded with 100+ fast-shipping US-based products in your niche and ready to sell"
            />
            <SellingPoint
              icon={<FiClock size="40" color={theme.colors.main} />}
              title="Save Time & Money"
              description="Save thousands of dollars and countless hours on store setup, and get started selling right away"
            />
            <SellingPoint
              icon={<FiDollarSign size="40" color={theme.colors.main} />}
              title="3 Months Free*"
              description="Each pre-built store comes with 3 months of free DropCommerce Pro subscription ($267 value)"
            />
          </CustomRow>
        </Container>

        <Column justify align style={{ marginBottom: "50px" }}>
          <GreySection>
            <Text center bold extra="font-size: 35px;" margin="0 0 20px 0">
              Available Stores
            </Text>

            <StoreCardsRow>
              {this.state.stores &&
                this.state.stores
                  .filter((x) => x.level === "PREMIUM")
                  .map((storeData, i) => (
                    <StoreCard
                      key={i}
                      storeData={storeData}
                      onClick={() =>
                        this.setState({ storeType: storeData.type })
                      }
                      sale={onSale}
                    />
                  ))}
              {this.state.stores &&
                this.state.stores
                  .filter((x) => x.level === "STARTER")
                  .map((storeData, i) => (
                    <StoreCard
                      key={i}
                      storeData={storeData}
                      onClick={() =>
                        this.setState({ storeType: storeData.type })
                      }
                      sale={onSale}
                      defaultPrice={price}
                    />
                  ))}
              {this.state.stores &&
                this.state.stores
                  .filter((x) => x.level === "PREMIUM_SOLD")
                  .map((storeData, i) => (
                    <StoreCard
                      key={i}
                      storeData={storeData}
                      onClick={() =>
                        this.setState({ storeType: storeData.type })
                      }
                    />
                  ))}
            </StoreCardsRow>

            <CTASection
              title="Start Your Business Today"
              subtitle="Purchase a high quality dropshipping business today"
              onClick={this.checkout}
              price={price}
            />
          </GreySection>

          <ErinTestimonial src="https://dc-imports.s3.ca-central-1.amazonaws.com/erintestimonial-2.png" />

          <FAQ data={faqData} />

          <Testimonials />

          <Text.Large>How To Get Started</Text.Large>
          <CustomRow style={{ margin: "30px 0 80px 0" }}>
            <SellingPoint
              icon={<FiGrid size="40" color={theme.colors.main} />}
              title="1. Choose A Template"
              description="Choose from 15+ high-quality templates designed to help you start selling fast"
            />
            <SellingPoint
              icon={<FiDollarSign size="40" color={theme.colors.main} />}
              title="2. Purchase Store"
              description="Once you've found a store you like, purchase the store and our team will be notified."
            />
            <SellingPoint
              icon={<FiZap size="40" color={theme.colors.main} />}
              title="3. We Setup Your Store"
              description="Our team will begin working on your store. Most stores are completed within 24 hours."
            />
          </CustomRow>

          <CTASection
            title="Get Started Today"
            subtitle="Purchase a high quality dropshipping business today"
            onClick={this.checkout}
            price={price}
          />
        </Column>

        <GreySection>
          <PackageOptionRow>
            <PackageOption checkout={this.checkout} price={price} />
            <Column
              style={{ maxWidth: "450px", width: "99vw", alignSelf: "center" }}
            >
              <Text bold m center>
                Get your pre-built Shopify store loaded with converting products
              </Text>

              <Text s light center style={{ marginTop: "20px" }}>
                Each store is ready out of the box. Simply advertise your
                website and start selling. If you need help, we have a range of
                guides and provide support to assist you.
              </Text>
            </Column>
          </PackageOptionRow>

          <Text
            xs
            light
            center
            style={{ maxWidth: "800px", marginTop: "20px" }}
          >
            You (the seller) do not purchase, store, or ship any inventory – the
            manufacturer or supplier does. In other words, you sell the products
            online, and the supplier ships them. Starting a business doesn’t get
            any easier.
          </Text>
        </GreySection>

        <WhiteBackground>
          <Column justify align>
            <ImageSection
              imagePlacement="left"
              image="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logistics.svg"
              title="Why Dropshipping?"
              textOne="Dropshipping allows you to earn money selling products online without actually purchasing any products or physically handling inventory."
              textTwo="Promote your store, earn big profits on sales, and let the supplier ship directly to your customer. Starting a business doesn’t get any easier."
            />

            <CTASection
              title="Get Started Today"
              subtitle="Purchase a high quality dropshipping business today"
              onClick={this.checkout}
              price={price}
            />

            <ImageSection
              imagePlacement="right"
              image="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_wallet_aym5.svg"
              title="Pre-Built Saves You Money"
              textOne="Shopify Developers can charge you as much as $5,000 - $10,000 for a website design and store setup, and that doesn't even include any products!"
              textTwo="With a DropCommerce pre-built store, you can save thousands and use that money for marketing."
            />
            <ImageSection
              imagePlacement="left"
              image="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/finishline.svg"
              title="Speed Matters"
              textOne="Getting your products to market before your competitors is key.
            While you’re contemplating layout and color schemes for your
            website, you can be sure your competition is converting sales, with YOUR customers."
              textTwo="Instead of spending weeks (sometimes
              months) designing your store, you could be making sales with a
              turnkey Shopify Pre-built Store by DropCommerce."
            />

            <CTASection
              title="What Are You Waiting For?"
              subtitle="Purchase a high quality dropshipping business today"
              onClick={this.checkout}
              price={price}
            />
          </Column>
        </WhiteBackground>

        <GreySection>
          <Text center l bold>
            Don't wait. Launch your store today!
          </Text>
          <Text center s light extra="margin-top: 10px; margin-bottom: 40px;">
            Create Facebook ads instead of spending time and money on building
            your website.
          </Text>

          <WhatsIncludedRow>
            <Column style={{ marginRight: "20px" }}>
              <Text m style={{ marginBottom: "10px" }}>
                What’s included?
              </Text>
              <FireList text="Niche categories" />
              <FireList text="Trending products" />
              <FireList text="Historical proven sales" />
              <FireList text="Facebook ads documentation" />
              <FireList text="High-converting descriptions" />
            </Column>
            <StoreImage src="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/wireframe.svg" />
          </WhatsIncludedRow>
        </GreySection>

        <CTASection
          title="Start Your E-commerce Store Today"
          subtitle="Purchase a high quality dropshipping business today"
          onClick={this.checkout}
          price={price}
        />
      </div>
    );
  }
}

const CTASection = ({ title, subtitle, onClick, price }) => (
  <Column justify align style={{ marginBottom: "50px", marginTop: "50px" }}>
    <Text l bold center>
      {title}
    </Text>
    {subtitle && (
      <Text s light center>
        {subtitle}
      </Text>
    )}
    <ButtonNew
      size="large"
      text={`Buy Now - $${price}`}
      width="200px"
      margin="20px 0 0 0"
      onClick={onClick}
    />
  </Column>
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Prebuilt)
);
