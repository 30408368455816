import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import { Row } from "../../../../../components/Layout";

class ShippingOptionCard extends Component {
  render() {
    const { data, onClick } = this.props;

    // const regionsList = countryOptions.filter((option) => option.region);

    // const regions = regionsList.map((option) =>
    //   getRegionFromCode(option.region)
    // );

    const active = data.deactivated === null;

    return (
      <ShippingOptionWrapper onClick={onClick}>
        <Row align style={{ justifyContent: "space-between" }}>
          {/* <div>
            <Title>
              {data.region ? getRegionFromCode(data.region) : "Whole Country"}
            </Title>
            {regionsList.length > 0 && !data.region && (
              <Regions>{`Except ${this.arrayToCommaString(regions)}`}</Regions>
            )}
          </div> */}
          <div>
            <Carrier active={active}>
              {data.carrier || "No Carrier Specified"}
            </Carrier>
            <Delivery
              active={active}
            >{`${data.delivery_days_min} - ${data.delivery_days_max} days`}</Delivery>
          </div>
          {active ? (
            <Price>${data.price}</Price>
          ) : (
            <Inactive>Inactive</Inactive>
          )}
        </Row>
      </ShippingOptionWrapper>
    );
  }
}

export default ShippingOptionCard;

const ShippingOptionWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 300px;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
`;

const Delivery = styled.div`
  font-size: 18px;
  color: ${theme.colors.medGrey};
  font-weight: 300;
  margin-top: 10px;
  opacity: ${(p) => (p.active ? "1.0" : "0.4")};
`;

const Inactive = styled.div`
  font-size: 18px;
  color: ${theme.colors.accent};
  font-weight: 500;
  opacity: 0.7;
`;

const Carrier = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  opacity: ${(p) => (p.active ? "1.0" : "0.4")};
`;

// const Regions = styled.div`
//   font-size: 13px;
//   color: ${theme.colors.medGrey};
//   font-weight: 300;
//   margin-top: 5px;
// `;

// const Title = styled.div`
//   font-size: 20px;
//   color: ${theme.colors.medDarkGrey};
//   font-weight: 500;
// `;

const Price = styled.div`
  font-size: 20px;
  color: ${theme.colors.orange};
  font-weight: 600;
`;
