import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateSettings } from "../../../../Actions";
import { fetchImportedProducts } from "../../../../views/products/ProductsActions";
import { getSettings } from "../../../../Selectors";
import {
  getImportedProducts,
  getIsFetchingImportedProducts,
  getPagination,
  getIssueCount,
  getWarningCount,
} from "../../../../views/products/ProductsSelectors";
import Pagination from "../../../../components/Pagination";
import FetchingWrapper from "../../../../components/FetchingWrapper";
import ProductCardInside from "../../../../views/products/components/ProductCardInside";
import {
  getUrlParameter,
  truncateText,
  getDefaultStoreRoute,
} from "../../../../modules/Format";
import { Container } from "../../../../components/Container";
import { ProductsRow } from "../../../../components/Layout";
import Search from "../../../../components/Search";
import NoResults from "../../../../components/NoResults";
import Card from "../../../../components/Card";
import TabNavigationLight from "../../../../components/TabNavigationLight";
import { Row } from "../../../../components";
import { get } from "lodash";
import { theme } from "../../../../modules/Theme";
import Text from "../../../../modules/Text";

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingImportedProducts(state),
  importedProducts: getImportedProducts(state),
  settings: getSettings(state),
  issueCount: getIssueCount(state),
  warningCount: getWarningCount(state),
  pagination: getPagination(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchImportedProducts, updateSettings }, dispatch),
});

const defaultProps = {
  isFetching: false,
  importedProducts: [],
  issueCount: null,
  warningCount: null,
  settings: {},
  pagination: {},
};

class Shopify extends Component {
  state = { searchTerm: "" };

  componentDidMount = () => {
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const page = getUrlParameter("p", this.props.location) || 1;
    const status = getUrlParameter("status", this.props.location) || "all";

    this.props.fetchImportedProducts(page, searchTerm, status);

    this.setState({ searchTerm });
  };

  fetchImportedProducts = (page) => {
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const status = getUrlParameter("status", this.props.location) || "all";
    this.props.fetchImportedProducts(page, searchTerm, status);
    this.props.history.push(
      `/imported/store?status=${status}&p=${page}&search=${searchTerm}`
    );
  };

  onChange = (term) => {
    this.setState({ searchTerm: term });
  };

  onEnterPress = (e) => {
    if (e.key === "Enter") {
      const status = getUrlParameter("status", this.props.location) || "all";
      this.props.fetchImportedProducts(1, this.state.searchTerm, status);
      this.props.history.push(
        `/imported/store?status=${status}&p=1&search=${this.state.searchTerm}`
      );
    }
  };

  updateStatus = (status) => {
    const search = getUrlParameter("search", this.props.location) || "";
    this.props.fetchImportedProducts(1, search, status);
    this.props.history.push(
      `/imported/store?status=${status}&p=1&search=${search}`
    );
  };

  renderPage = () => {
    const status = getUrlParameter("status", this.props.location) || "all";
    const issueCount = get(this.props, ["issueCount"]);
    const warningCount = get(this.props, ["warningCount"]);

    const tabs = [
      {
        name: "All Products",
        link: () => this.updateStatus("all"),
        active: status === "all",
      },
      {
        name: `Issues ${issueCount > 0 ? `(${issueCount})` : ""}`,
        link: () => this.updateStatus("issues"),
        active: status === "issues",
        bubble: issueCount && {
          color: theme.colors.lightRed,
          text: issueCount,
        },
      },
      {
        name: `Low Stock ${warningCount > 0 ? `(${warningCount})` : ""}`,
        link: () => this.updateStatus("warnings"),
        active: status === "warnings",
        bubble: warningCount && {
          color: theme.colors.lightRed,
          text: warningCount,
        },
      },
    ];

    if (this.props.isFetching > 0) {
      return this.loading;
    } else {
      let initialSearchTerm = getUrlParameter("s", this.props.location) || "";
      return (
        <FetchingWrapper>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <TabNavigationLight tabs={tabs} />

            <Search
              onChange={this.onChange}
              onEnterPress={this.onEnterPress}
              search={this.state.searchTerm}
              initialSearchTerm={initialSearchTerm}
              searchForProducts={(searchTerm) =>
                this.fetchImportedProducts(1, searchTerm)
              }
              clear={() => this.fetchImportedProducts(1, "")}
            />
          </Row>

          {status === "warnings" && (
            <div style={{ width: "auto" }}>
              <Text.Small light extra="margin-top: 10px;">
                No action is required for Low / Out Of Stock products. To remove
                them from your store, click 'Delete'
              </Text.Small>
            </div>
          )}

          {status === "issues" && (
            <div style={{ width: "auto" }}>
              <Text.Small light extra="margin-top: 10px;">
                To resolve a product issue, click either 'Sync' or 'Delete'.{" "}
                <Text.Small
                  light
                  link
                  onClick={() =>
                    window.open(
                      "https://help.dropcommerce.com/en/articles/4621126-fixing-product-sync-issues"
                    )
                  }
                >
                  Learn More
                </Text.Small>
              </Text.Small>

              {/* <Switch
                active={automaticResolution}
                label="Automatic Issue Resolution"
                description="Allow DropCommerce to automatically fix product issues by either updating or removing them, to prevent potential order issues."
                toggle={() =>
                  this.props.updateSettings({
                    automatic_resolution: !automaticResolution,
                  })
                }
              />

              {!automaticResolution && (
                <Text.Small red extra="margin-top: 10px;">
                  Warning: With Automatic Issue Resolution off, your products
                  may not be in sync. DropCommerce will not be responsible for
                  order issues caused by keeping this setting turned off.
                </Text.Small>
              )} */}
            </div>
          )}

          <ProductsRow style={{ marginTop: "20px" }}>
            {this.props.importedProducts.map((product) => (
              <Card
                key={product.id}
                isFetching={product.isFetching}
                name={truncateText(product.title, 25)}
                image={product.image}
                subtext={product.supplier.name}
                description={
                  product.issue || `$${Number(product.price).toFixed(2)}`
                }
                descriptionColor="orange"
                onClick={() => {
                  this.props.history.push(`/product/${product.product_id}`);
                }}
              >
                <ProductCardInside
                  product={product}
                  type="imported"
                  image={product.image}
                />
              </Card>
            ))}

            {this.props.importedProducts.length === 0 && (
              <NoResults
                title="No imported products match this filter"
                message="Once you import products into your store they will show up here. If you've added items to your import list, you can view them on the Import List page."
                buttonLink={() => this.props.history.push(getDefaultStoreRoute)}
                buttonText="FIND PRODUCTS TO SELL"
                buttonTwoLink={() => this.props.history.push(`/imported/list`)}
                buttonTwoText="GO TO IMPORT LIST"
              />
            )}
          </ProductsRow>

          {this.props.importedProducts.length > 0 && (
            <Pagination
              onClick={(page) => this.fetchImportedProducts(page)}
              pagination={this.props.pagination}
            />
          )}
        </FetchingWrapper>
      );
    }
  };

  loading = (
    <ProductsRow>
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
      <Card.Skeleton />
    </ProductsRow>
  );

  render() {
    return <Container>{this.renderPage()}</Container>;
  }
}

Shopify.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Shopify);
