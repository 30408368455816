import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import ShippingOption from "./ShippingOption";
import ShippingOptions from "./ShippingOptions";

class Shipping extends Component {
  render() {
    const path = "/supplier/settings/shipping";

    return (
      <Switch>
        <Route path={`${path}/new`} component={ShippingOption} />
        <Route path={`${path}/:optionId`} component={ShippingOption} />
        <Route path={`${path}/`} component={ShippingOptions} />
      </Switch>
    );
  }
}

export default withRouter(Shipping);
