import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import Titlebar from "../../components/Titlebar";

import SettingsCards from "./components/SettingsCards";
import PricingOptions from "../../views/PricingOptions";
import { Container } from "../../components/Container";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  BrandedInvoicing,
  ImportSettings,
  AccountDetails,
  Notifications,
  Platforms,
  StoreProfile,
} from "./views/store";
import Addresses from "./views/store/Addresses";
import Agreement from "./views/store/Agreement";
import Referrals from "./views/shared/Referrals";
import { getSettings, getIsFetchingSettings } from "../../Selectors";
import { get } from "lodash";
import { ResetPassword } from "./views/supplier";
import {
  FiClipboard,
  FiBell,
  FiTruck,
  FiLock,
  FiFileText,
  FiCreditCard,
  FiUserPlus,
  FiChevronLeft,
  FiLayers,
  FiArrowRightCircle,
  FiBarChart,
  FiPlusCircle,
  FiDollarSign,
  FiSettings,
} from "react-icons/fi";
import { getUrlSubdomain } from "../../modules/Format";
import StoreHealth from "./views/store/StoreHealth";
import { StoreRefund } from "../refunds";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetchingSettings: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

export const SettingsHeaderItem = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
  color: ${(p) => (p.active ? theme.colors.main : theme.colors.medDarkGrey)};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.main};
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const SettingsBack = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.medDarkGrey};
  width: 90px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const SettingsHeaderRow = styled.div`
  display: flex;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;
`;

class StoreSettings extends Component {
  state = { tab: "account_details" };

  getTabName = (cards) => {
    let name = null;

    cards.forEach((card) => {
      if (card.route === this.props.match.params.tab) {
        name = card.title;
      }
    });
    return name;
  };

  goBack = () => {
    const url = get(this.props, ["match", "url"]);

    // OVERRIDE FOR ADDRESSES PAGE
    if (url.includes("settings/addresses")) {
      const addressId = get(this.props, ["match", "params", "type"]);
      if (addressId) {
        this.props.history.push("/settings/addresses");
        return;
      }
    }

    this.props.history.push("/settings");
  };

  render() {
    let tab = get(this.props, ["match", "params", "tab"]);

    if (!tab) {
      tab = "";
    }

    const privatePortal = getUrlSubdomain() !== "app";

    // Patch for NFT demo
    const location = window.location.toString();
    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    const cards = getCards(privatePortal, isGlobal);

    const titlebarName = tab ? this.getTabName(cards) : "Settings";

    return (
      <>
        {tab !== "plans" && <Titlebar title={titlebarName} />}
        <Container>
          {tab === "" ? (
            <SettingsCards
              cards={cards.filter((card) => card.title)}
              settingsPath={"/settings"}
            />
          ) : (
            <SettingsBack onClick={this.goBack}>
              <FiChevronLeft size="24px" style={{ cursor: "pointer" }} />
              Settings
            </SettingsBack>
          )}
          <Switch>
            <Route path="/settings/profile" component={StoreProfile} />
            <Route
              path="/settings/account_details"
              component={AccountDetails}
            />
            <Route path="/settings/referrals" component={Referrals} />
            <Route path="/settings/platforms" component={Platforms} />
            <Route path="/settings/notifications" component={Notifications} />
            <Route
              path="/settings/branded_invoicing"
              component={BrandedInvoicing}
            />
            <Route path="/settings/addresses" component={Addresses} />
            <Route
              path="/settings/import_settings"
              component={ImportSettings}
            />
            <Route path="/settings/plans/:type" component={PricingOptions} />
            <Route path="/settings/plans" component={PricingOptions} />
            <Route path="/settings/password" component={ResetPassword} />
            <Route path="/settings/store_health" component={StoreHealth} />
            <Route path="/settings/agreement" component={Agreement} />
            {/*<Route path="/settings/refunds" component={StoreRefund} />*/}
          </Switch>
        </Container>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreSettings)
);

const getCards = (privatePortal, isGlobal) => [
  {
    icon: <FiSettings size="20px" color={theme.colors.medDarkGrey} />,
    title: "Profile",
    description: "Adjust your profile settings",
    route: "profile",
  },
  {
    icon: <FiCreditCard size="20px" color={theme.colors.medDarkGrey} />,
    title: "Payment",
    description: "Manage your order settings and payment card",
    route: "account_details",
  },
  {
    icon: <FiLayers size="20px" color={theme.colors.medDarkGrey} />,
    title: "Platform",
    description: "Connect an ecommerce platform to import products",
    route: "platforms",
  },
  {
    icon: <FiBarChart size="20px" color={theme.colors.medDarkGrey} />,
    title: "Plans",
    description: "View your current plan, features and other available plans",
    route: "plans",
    displayCondition: privatePortal ? isGlobal : true,
  },
  {
    icon: <FiUserPlus size="20px" color={theme.colors.medDarkGrey} />,
    title: "Referrals",
    description: "Earn free months by referring users to DropCommerce",
    route: "referrals",
    displayCondition: !privatePortal,
  },
  {
    icon: <FiBell size="20px" color={theme.colors.medDarkGrey} />,
    title: "Notifications",
    description: "Adjust settings related to notification emails",
    route: "notifications",
  },
  {
    icon: <FiFileText size="20px" color={theme.colors.medDarkGrey} />,
    title: "Branded Invoicing",
    description:
      "Adjust settings for the invoice feature offered by some suppliers",
    route: "branded_invoicing",
  },
  {
    icon: <FiArrowRightCircle size="20px" color={theme.colors.medDarkGrey} />,
    title: "Import Settings",
    description: "Adjust rules for default product import pricing",
    route: "import_settings",
  },
  {
    icon: <FiTruck size="20px" color={theme.colors.medDarkGrey} />,
    title: "Shipping Addresses",
    description:
      "Adjust your shipping address for product samples and manual orders ",
    route: "addresses",
  },
  {
    icon: <FiLock size="20px" color={theme.colors.medDarkGrey} />,
    title: "Password",
    description: "Reset your password for entering the app via the login page",
    route: "password",
  },
  privatePortal && {
    icon: <FiClipboard size="20px" color={theme.colors.medDarkGrey} />,
    title: "User Agreement",
    description: "View the terms and conditions of using this account",
    route: "agreement",
  },
  {
    icon: <FiPlusCircle size="20px" color={theme.colors.medDarkGrey} />,
    title: "Store Health",
    description: "View and address issues pertaining to your store",
    route: "store_health",
  },
  {
    icon: <FiDollarSign size="20px" color={theme.colors.medDarkGrey} />,
    title: "Refunds",
    description: "View and updated refunds for your store",
    route: "refunds",
  },
];
