import React, { Component } from "react";
import Text from "../../../modules/Text";
import { ButtonNew, Column, Row, OptionBubbles } from "../../../components";
import lock from "../../../images/icons/feather/lock.svg";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import createNotification from "../../../modules/Notification";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { getSettings, getIsBuyingStore } from "../../../Selectors";
import { buyPremadeStore } from "../../../Actions";
import { get } from "lodash";

const ContentBoxIcon = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const ConfirmTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

const ConfirmPrice = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

const StoreNameText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ConfirmDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 40px;
  max-width: 600px;
`;

const AgreeText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 20px;
`;

const AgreeLink = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.main};
  cursor: pointer;
`;

const SecurePayment = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const StoreNameInput = styled.input`
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 300px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const InnerContainer = styled.div`
  width: 600px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isBuyingStore: getIsBuyingStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ buyPremadeStore }, dispatch),
});

class BuyConfirm extends Component {
  state = { buyConfirmClicked: false, storeEmail: "", emailError: false };

  buyConfirmHandler = () => {
    this.setState({ buyConfirmClicked: true });
    if (this.props.existing) {
      this.props.buyPremadeStore(
        this.state.storeEmail,
        this.props.storeData.type,
        this.props.storeName,
        this.props.storeURL,
        this.props.extraPrebuiltMonths,
        this.props.existing,
        null
      );
    } else {
      // if not an existing store, then check for a valid email
      this.validateEmail()
        ? this.props.buyPremadeStore(
            this.state.storeEmail,
            this.props.storeData.type,
            this.props.storeName,
            this.props.storeURL,
            this.props.extraPrebuiltMonths,
            this.props.existing,
            null
          )
        : this.setState({ buyConfirmClicked: false });
    }
  };

  validateEmail = () => {
    if (
      !this.state.storeEmail.includes("@") ||
      !this.state.storeEmail.includes(".")
    ) {
      this.setState({ emailError: true });
      return false;
    } else return true;
  };

  renderBuyButton = () => {
    if (this.props.purchased) {
      return <ButtonNew size="large" disabled text="Purchased" />;
    }

    const validStoreName = this.props.storeName.length >= 3;
    const validStoreURL = this.props.storeURL.length >= 3;

    const { buyText, isBuyingStore } = this.props;

    if (
      (!this.props.existing && !validStoreName) ||
      (this.props.existing && !validStoreURL)
    ) {
      return (
        <ButtonNew
          size="large"
          disabled
          text={buyText}
          // This onclick won't work right now
          onClick={() =>
            createNotification({
              type: "danger",
              title: "Error",
              message: "Please enter a store name",
            })
          }
        />
      );
    }

    if (isBuyingStore) {
      return <ButtonNew size="large" isFetching />;
    }

    return (
      <ButtonNew
        disabled={this.state.buyConfirmClicked}
        size="large"
        text={buyText}
        onClick={this.buyConfirmHandler}
      />
    );
  };

  render() {
    const { purchased, storeData, settings } = this.props;

    const getPrice = (id, defaultPrice) =>
      get(settings, ["global", "months_to_plan_price", [id]], defaultPrice);

    return (
      <div>
        <InnerContainer>
          <Column>
            <ConfirmPrice>
              {purchased ? "Payment Successful!" : "Complete Purchase:"}
            </ConfirmPrice>
            <ConfirmTitle>{`Premade ${this.props.storeData.name} Store`}</ConfirmTitle>
          </Column>

          {!this.props.purchased && (
            <div style={{ width: "100%", marginBottom: "20px" }}>
              <StoreNameText>Select Your Package:</StoreNameText>
              <OptionBubbles
                options={[
                  {
                    label: `3 Month Package - $${getPrice(3, 249.0)}`,
                    description:
                      "Pre-built Shopify store with 3 months of DropCommerce Pro plan (package valued at $249.00)",
                    selected: this.props.extraPrebuiltMonths === 0,
                    onClick: () =>
                      this.props.updateField("extraPrebuiltMonths", 0),
                  },
                  {
                    label: `6 Month Package - $${getPrice(6, 379)}`,
                    description:
                      "Pre-built Shopify store with 6 months of DropCommerce Pro plan (package valued at $379)",
                    selected: this.props.extraPrebuiltMonths === 3,
                    onClick: () =>
                      this.props.updateField("extraPrebuiltMonths", 3),
                  },
                  {
                    label: `12 Month Package - $${getPrice(12, 499)}`,
                    description:
                      "Pre-built Shopify store with 12 months of DropCommerce Pro plan (package valued at $499)",
                    selected: this.props.extraPrebuiltMonths === 9,
                    onClick: () =>
                      this.props.updateField("extraPrebuiltMonths", 9),
                  },
                ]}
              />

              <StoreNameText>Choose Setup Type:</StoreNameText>
              <OptionBubbles
                options={[
                  {
                    label: "I Want To Upgrade My Existing Shopify Store",
                    description:
                      "Our team will upgrade your existing Shopify store with your chosen Template",
                    selected: this.props.existing,
                    onClick: () => this.props.updateField("existing", true),
                  },
                  {
                    label: "I Want A Brand New Shopify Store",
                    description:
                      "Our team will setup a brand new Shopify account with your chosen Template",
                    selected: !this.props.existing,
                    onClick: () => this.props.updateField("existing", false),
                  },
                ]}
              />

              {this.props.existing ? (
                <React.Fragment>
                  <StoreNameText>Confirm Your Store URL:</StoreNameText>
                  {this.props.storeURL.length > 0 &&
                    this.props.storeURL.length < 4 && (
                      <Text.Small light red>
                        Please enter a valid URL
                      </Text.Small>
                    )}
                  <StoreNameInput
                    onChange={(e) =>
                      this.props.updateField("storeURL", e.target.value)
                    }
                    value={this.props.storeURL}
                    placeholder="yourstore.myshopify.com"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Column>
                    <StoreNameText>Desired New Store Name:</StoreNameText>
                    {this.props.storeName.length > 0 &&
                      this.props.storeName.length < 4 && (
                        <Text.Small light red>
                          Please enter a valid store name
                        </Text.Small>
                      )}
                    <StoreNameInput
                      onChange={(e) =>
                        this.props.updateField("storeName", e.target.value)
                      }
                      value={this.props.storeName}
                      placeholder="Store Name"
                    />
                  </Column>
                  <Column>
                    <StoreNameText>
                      Email ID to be associated with this store:
                    </StoreNameText>
                    {this.state.emailError && (
                      <Text.Small light red>
                        Please enter a valid email address
                      </Text.Small>
                    )}
                    <StoreNameInput
                      onChange={(e) =>
                        this.setState({ storeEmail: e.target.value })
                      }
                      value={this.props.storeEmail}
                      placeholder="Email id"
                    />
                  </Column>
                </React.Fragment>
              )}
            </div>
          )}

          {this.renderBuyButton(storeData)}

          {purchased && (
            <ButtonNew
              size="large"
              margin="10px 0 0 0"
              text="Download Free Store Setup E-Book"
              type="secondary"
              onClick={() => {
                window.open(
                  "https://dc-imports.s3.ca-central-1.amazonaws.com/DropCommerce-Prebuilt-Store-eBook.pdf"
                );
              }}
            />
          )}

          {purchased ? (
            <React.Fragment>
              <ConfirmDescription>
                Your payment was successful. Our team will begin building your
                store right away, and deliver the login credentials within 24
                hours.
              </ConfirmDescription>
              <ConfirmDescription>
                Questions? Contact support@dropcommerce.com
              </ConfirmDescription>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AgreeText>
                By purchasing a pre-built store, you are agreeing to our{" "}
                <AgreeLink
                  onClick={() =>
                    window.open(
                      "https://help.dropcommerce.com/en/articles/4361388-prebuilt-store-terms"
                    )
                  }
                >
                  Pre-built Store Terms
                </AgreeLink>
              </AgreeText>

              <Row align style={{ marginTop: "20px" }}>
                <ContentBoxIcon src={lock} />
                <SecurePayment>Secure Payment</SecurePayment>
              </Row>

              <ConfirmDescription>
                After payment, our team will professionally build your store and
                deliver your login details within 24 hours.
              </ConfirmDescription>
            </React.Fragment>
          )}
        </InnerContainer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyConfirm);
