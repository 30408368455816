import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../../../../modules/Theme";

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`;

const NavItem = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid
    ${(p) => (p.selected ? "white" : theme.colors.lightBorder)};
  border-right: 1px solid ${theme.colors.lightBorder};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.main};
  }
  display: flex;
  align-items: center;
  height: 60px;
`;

const Counter = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 4px;
  color: white;
  background: ${theme.colors.medGrey};
  border-radius: 50px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const NavChildren = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  align-items: center;
`;
class Navigation extends Component {
  render() {
    const { product, tab, setLocalState } = this.props;

    return (
      <NavWrapper>
        <NavItem
          selected={tab === "product"}
          onClick={() => setLocalState({ tab: "product" })}
        >
          <div>Product</div>
        </NavItem>
        <NavItem
          selected={tab === "description"}
          onClick={() => setLocalState({ tab: "description" })}
        >
          <div>Description</div>
        </NavItem>
        <NavItem
          selected={tab === "variants"}
          onClick={() => setLocalState({ tab: "variants" })}
        >
          <div>Variants</div>
          {product.variants.length > 1 && (
            <Counter>{product.variants.length}</Counter>
          )}
        </NavItem>
        <NavItem
          selected={tab === "images"}
          onClick={() => setLocalState({ tab: "images" })}
        >
          <div>Images</div>
          {product.images.length > 1 && (
            <Counter>{product.images.length}</Counter>
          )}
        </NavItem>
        <NavChildren>{this.props.children}</NavChildren>
      </NavWrapper>
    );
  }
}

export default Navigation;
