import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import ScrollToBottom from "react-scroll-to-bottom";
import Icon from "../../views/inbox/components/Icon";
import { Row } from "../../components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { getSettings } from "../../Selectors";
import { web } from "../../modules/Config";
import { withRouter } from "react-router-dom";
import { getSettings as getSupplierSettings } from "../../views/supplier/SupplierSelectors";
import { formatTimestamp } from "../../utils";

const ChatBubbleWrapper = styled.div`
  ${(p) =>
    p.activeUser
      ? "border-radius: 24px 24px 0 24px;"
      : "border-radius: 24px 24px 24px 0;"}
  padding: 15px;
  margin-bottom: 10px;
  background: ${(p) =>
    p.activeUser ? theme.colors.main : theme.colors.lightGrey};
`;

const MessageContainer = styled.div``;

const MessagesContainer = styled.div`
  padding: 10px;
  padding-top: 0;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.medLightGrey};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.medGrey};
  }
`;

const ChatRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.activeUser ? "flex-end" : "flex-start")};
`;

const ChatBubbleText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${(p) => (p.activeUser ? "white" : theme.colors.medDarkGrey)};
`;

const ChatBubbleTimestamp = styled.div`
  font-size: 10px;
  font-weight: 300;
  color: ${(p) => (p.activeUser ? "white" : theme.colors.medDarkGrey)};
  margin-top: 6px;
  text-align: ${(p) => (p.activeUser ? "left" : "right")};
`;

const MessageModelType = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${(p) => (p.activeUser ? "white" : theme.colors.medDarkGrey)};
`;

const MessageModelName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${(p) => (p.activeUser ? "white" : theme.colors.main)};
`;

const MessageModelClick = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${(p) => (p.activeUser ? "white" : theme.colors.medDarkGrey)};
`;

const MessageModelWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.inputBorder};
  }
`;

const MessageDate = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${theme.colors.medLightGrey};
  text-align: center;
  margin-top: 10px;
`;

const ChatBubble = ({
  text,
  activeUser,
  products,
  supplierOrders,
  sentTimestamp,
  isStore,
}) => (
  <ChatRow activeUser={activeUser}>
    <ChatBubbleWrapper activeUser={activeUser}>
      <ChatBubbleText activeUser={activeUser}>{text}</ChatBubbleText>
      <ChatBubbleTimestamp activeUser={activeUser}>
        {sentTimestamp}
      </ChatBubbleTimestamp>
    </ChatBubbleWrapper>

    {products &&
      products.map((p, i) => (
        <MessageModel key={i} type="PRODUCT" data={p} isStore={isStore} />
      ))}

    {supplierOrders &&
      supplierOrders.map((p, i) => (
        <MessageModel
          key={i}
          type="SUPPLIER_ORDER"
          data={p}
          isStore={isStore}
        />
      ))}
  </ChatRow>
);

const MessageModel = ({ type, data, isStore }) => {
  let link = null;
  let modelType = null;

  if (type === "PRODUCT") {
    modelType = "Product";

    link = isStore
      ? `${web}/product/${data.id}`
      : `${web}/supplier/product/${data.id}`;
  } else if (type === "SUPPLIER_ORDER") {
    modelType = "Order";
    link = isStore
      ? `${web}/order/${data.parent_order_id}`
      : `${web}/supplier/order/${data.id}`;
  }

  return (
    <MessageModelWrapper onClick={() => window.open(link)}>
      <MessageModelType>{modelType}</MessageModelType>
      <MessageModelName>{data.name}</MessageModelName>
      <MessageModelClick>Click to view</MessageModelClick>
    </MessageModelWrapper>
  );
};

class Messages extends Component {
  dates = [];

  render() {
    const { conversation } = this.props;
    const isStore = !this.props.match.path.includes("supplier");
    const settingsKey = isStore ? "settings" : "supplierSettings";
    const userId = get(this.props, [settingsKey, "user_id"]);

    const renderDaySent = (timestamp, previousTimestamp) => {
      const today = new Date().toDateString();
      const messageDate = new Date(timestamp).toDateString();
      if (previousTimestamp) {
        const previousDate = new Date(previousTimestamp).toDateString();
        if (messageDate === previousDate) {
          return "";
        }
      }

      let dateText = "Today";
      if (messageDate !== today) {
        dateText = new Date(timestamp).toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      }
      return dateText;
    };

    return (
      <MessagesContainer>
        <ScrollToBottom>
          {conversation &&
            conversation.messages.map((message, i, messages) => {
              return (
                <div key={i}>
                  <MessageDate>
                    {renderDaySent(
                      message.sent_timestamp,
                      i !== 0 ? messages[i - 1].sent_timestamp : null
                    )}
                  </MessageDate>

                  {message.sender_id === userId ? (
                    <MessageContainer>
                      <ChatBubble
                        text={message.text}
                        name={message.name}
                        activeUser={message.sender_id === userId}
                        products={message.products}
                        supplierOrders={message.supplier_orders}
                        isStore={isStore}
                        sentTimestamp={formatTimestamp(message.sent_timestamp)}
                      />
                    </MessageContainer>
                  ) : (
                    <MessageContainer>
                      <Row justify align>
                        <Icon
                          name={conversation.other_user}
                          color={conversation.color}
                        />

                        <ChatBubble
                          text={message.text}
                          name={message.name}
                          products={message.products}
                          supplierOrders={message.supplier_orders}
                          sentTimestamp={formatTimestamp(
                            message.sent_timestamp
                          )}
                        />
                      </Row>
                    </MessageContainer>
                  )}
                </div>
              );
            })}
        </ScrollToBottom>
      </MessagesContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
