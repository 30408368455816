import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import Text from "../../../modules/Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  margin: 0px 30px 0px 30px;
  width: 33%;
  @media (max-width: 800px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`;

class SellingPoint extends Component {
  render() {
    return (
      <Container>
        {this.props.icon}
        <Text bold center margin="10px 0 0 0">
          {this.props.title}
        </Text>
        <Text.Small light center margin="5px 0 0 0">
          {this.props.description}
        </Text.Small>
      </Container>
    );
  }
}

export default withRouter(SellingPoint);
