import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SelectDropdown from "../../../components/SelectDropdown";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

function SortingDropdown(props) {
  const [sorting, setSorting] = useState("default");

  const showSorting = () => {
    props.showHideFilter("sort");
  };

  const sortProducts = (id) => {
    setSorting(id);
    props.showHideFilter("sort");
    props.updateFilters("sorting", id);
  };

  const styles = { display: "flex", alignItems: "center", gap: "4px" };

  return (
    <SelectDropdown
      displayText={`Sort By: ${
        sorting.toUpperCase()[0] + sorting.substring(1).toLowerCase()
      }`}
      onClick={showSorting}
      showHideFilter={showSorting}
      show={props.data.show}
    >
      <div
        style={{
          width: "150px",
          padding: "15px 10px",
        }}
      >
        {props.items.map((item) => (
          <div style={styles} key={item.key}>
            <input
              type="radio"
              name="sorting"
              id={item.key}
              onChange={() => sortProducts(item.key)}
              checked={sorting === item.key}
            />
            <label htmlFor={item.key}>{item.label}</label>
          </div>
        ))}
      </div>
    </SelectDropdown>
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SortingDropdown)
);
