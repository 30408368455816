import {
  LOGIN_RES,
  LOGIN_ERR,
  SET_ERR,
  REGISTER_RES,
  REGISTER_ERR,
  FETCH_ORDER_REQ,
  FETCH_ORDER_RES,
  FETCH_ORDERS_REQ,
  FETCH_ORDERS_RES,
  FETCH_ORDERS_ERR,
  SHOPIFY_AUTH_RES,
  SHOPIFY_AUTH_ERR,
  CREATE_SHOPIFY_ORDER_ERR,
  FULFILL_ORDER_REQ,
  FULFILL_ORDER_RES,
  FULFILL_ORDER_ERR,
  GENERIC_REQ,
  GENERIC_RES,
  GENERIC_ERR,
  STRIPE_SUPPLIER_AUTH_REQ,
  STRIPE_SUPPLIER_AUTH_RES,
  UPDATE_SETTINGS_REQ,
  UPDATE_SETTINGS_RES,
  UPDATE_SETTINGS_ERR,
  UPDATE_SUPPLIER_SETTINGS_RES,
  SET_SETTINGS_SAVED_RES,
  SAVE_STRIPE_CARD_REQ,
  SAVE_STRIPE_CARD_RES,
  REMOVE_STRIPE_CARD_RES,
  SET_LAST_PAGINATION_STATE,
  SET_LAST_SEARCH,
  SET_PLAN_RES,
  PLAN_SAVED_RES,
  PLAN_SAVED_ERR,
  HIDE_INTRO_NOTICE,
  UPDATE_SETTINGS_FROM_LS,
  UPDATE_SUPPLIERS_FROM_LS,
  UPDATE_VENDOR_APPROVAL_RES,
  UPDATE_AUTO_FULFILLMENT_RES,
  CANCEL_ORDER_REQ,
  CANCEL_ORDER_RES,
  CANCEL_ORDER_ERR,
  SUBMIT_REVIEW_REQ,
  FETCH_STORE_APPROVAL_DATA_REQ,
  FETCH_STORE_APPROVAL_DATA_RES,
  FETCH_STORE_APPROVAL_DATA_ERR,
  FEATURED_SUPPLIERS_REQ,
  FEATURED_SUPPLIERS_RES,
  FEATURED_SUPPLIERS_ERR,
  UPDATE_STATE,
  UPDATE_STORE,
  BUY_PREMADE_STORE_REQ,
  BUY_PREMADE_STORE_RES,
  BUY_PREMADE_STORE_ERR,
  ADD_MESSAGE_TO_CONVERSATION_REQ,
  ADD_MESSAGE_TO_CONVERSATION_RES,
  FETCH_CONVERSATIONS_RES,
  CREATE_CONVERSATION_RES,
  FETCH_RECIPIENTS_RES,
  FETCH_RECIPIENTS_REQ,
  FETCH_RECIPIENTS_ERR,
  DISMISS_OFFER,
  DISMISS_OFFER_ERR,
  ARCHIVE_CONVERSATION_RES,
  UNARCHIVE_CONVERSATION_RES,
  ADD_CONFIRMATION_MODAL,
  REMOVE_CONFIRMATION_MODAL,
  FETCH_ARCHIVED_CONVERSATIONS_REQ,
  FETCH_ARCHIVED_CONVERSATIONS_RES,
  FETCH_ARCHIVED_CONVERSATIONS_ERROR,
  RESET_SETTINGS,
} from "./Actions";

import {
  UPDATE_IMPORTED_TO_SHOPIFY_COUNT,
  UPDATE_IMPORT_LIST_COUNT,
} from "./views/products/ProductsActions";

import { get } from "lodash";
import { INITIAL_FILTERS } from "./constants";

export const updateState = (state, data) => {
  let newState = { ...state };
  let depthArray = [];
  let depthArrayReversed = [];

  data.forEach((item, i) => {
    // NAVIGATE TO KEY
    if (item.key) {
      if (Array.isArray(newState[item.key])) {
        newState = [...newState[item.key]];
      } else {
        newState = { ...newState[item.key] };
      }
    }

    // UPDATE FIELD
    if (item.fields) {
      item.fields.forEach((field) => {
        if (Array.isArray(newState)) {
          newState = newState.map((n) => {
            // Match Function used to only apply update to specific array element
            if (!item.matchArrayElement || item.matchArrayElement(n.id)) {
              return { ...n, [field[0]]: field[1] };
            }
            return n;
          });
        } else {
          newState = { ...newState, [field[0]]: field[1] };
        }
      });
    }

    const newData = {
      index: i,
      key: item.key,
      data: newState,
    };

    depthArrayReversed.unshift(newData);
    depthArray.push(newData);
  });

  let finalState = {};

  depthArrayReversed.forEach((item, i) => {
    let lastObject = depthArrayReversed[i - 1];

    if (lastObject) {
      let lastKey = lastObject.key;
      finalState = { ...item.data, [lastKey]: finalState };
    } else {
      finalState = item.data;
    }

    if (item.index === 0 && item.key) {
      finalState = { ...state, [item.key]: finalState };
    }
  });

  return finalState;
};

const defaultCategories = [];

const initialState = {
  confirmationData: null,
  order: {},
  hasUnreadMessage: false,
  errors: {},
  isFetching: 0,
  cart_items: [],
  authenticated: false,
  orders: [],
  products: [],
  suppliers: [],
  featuredSuppliers: {
    featured: [],
    newest: [],
    categories: defaultCategories,
  },
  supplier: {},
  analytics: {},
  currencyNotice: false,
  settings: {},
  supplierCreated: null,
  isFetchingReview: false,
  storeApprovalData: {},
  isFetchingApprovalData: false,
  isFetchingSettings: false,
  conversations: [],
  archivedConversations: [],
  recipients: [],
  isFetchingReferrals: false,
  dashboardData: {},
  referrals: [],
  // FILTERS
  filters: INITIAL_FILTERS,
  subdomainData: null,
};

const base = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SETTINGS:
      return { ...state, settings: {} };

    case ADD_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationData: action.payload.confirmationData,
      };

    case REMOVE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationData: null,
      };

    case ARCHIVE_CONVERSATION_RES:
      return {
        ...state,
        conversations: state.conversations.filter(
          (c) => action.payload.conversation.id !== c.id
        ),
        archivedConversations: [
          ...state.archivedConversations,
          action.payload.conversation,
        ],
      };

    case UNARCHIVE_CONVERSATION_RES:
      return {
        ...state,
        archivedConversations: state.archivedConversations.filter(
          (c) => action.payload.conversation.id !== c.id
        ),
        conversations: [...state.conversations, action.payload.conversation],
      };

    case "base/FETCH_HAS_UNREAD_MESSAGE":
      return {
        ...state,
        settings: {
          ...state.settings,
          has_unread_messages: action.payload,
        },
      };

    case "base/FETCH_SUBDOMAIN_DATA_REQ":
      return {
        ...state,
        subdomainData: { isFetching: true },
      };

    case "base/FETCH_SUBDOMAIN_DATA_RES":
      return {
        ...state,
        subdomainData: action.payload,
      };

    case "base/FETCH_SUBDOMAIN_DATA_ERR":
      return {
        ...state,
        subdomainData: null,
      };

    case CREATE_CONVERSATION_RES:
      return {
        ...state,
        conversations: action.payload.existing
          ? state.conversations
          : [...state.conversations, action.payload],
        isFetching: state.isFetching - 1,
      };

    case FETCH_CONVERSATIONS_RES:
      return {
        ...state,
        conversations: action.payload.conversations,
        isFetching: state.isFetching - 1,
      };

    case FETCH_RECIPIENTS_REQ:
      return {
        ...state,
        recipients: [],
        isFetchingRecipients: true,
      };

    case FETCH_RECIPIENTS_RES:
      return {
        ...state,
        recipients: action.payload,
        isFetchingRecipients: false,
      };

    case FETCH_RECIPIENTS_ERR:
      return {
        ...state,
        recipients: [],
        isFetchingRecipients: false,
      };

    case ADD_MESSAGE_TO_CONVERSATION_REQ:
      return {
        ...state,
        conversations: state.conversations.map((conversation) => {
          if (conversation.label === action.payload.label) {
            return {
              ...conversation,
              messages: [...conversation.messages, action.payload],
            };
          }
          return conversation;
        }),
      };

    case ADD_MESSAGE_TO_CONVERSATION_RES:
      return {
        ...state,
        conversations: state.conversations.map((conversation) => {
          if (conversation.label === action.payload.label) {
            return {
              ...conversation,
              messages: conversation.messages.map((message) => {
                if (message.temp_id === action.payload.temp_id) {
                  return {
                    ...message,
                    id: action.payload.id,
                    products: action.payload.products,
                    supplier_orders: action.payload.supplier_orders,
                  };
                }
                return message;
              }),
            };
          }
          return conversation;
        }),
      };

    case "base/CREATE_MANUAL_ORDER_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        cart_items: state.cart_items.filter(
          (item) => !action.payload.includes(item.id)
        ),
      };

    case "base/DELETE_CART_ITEMS_RES":
      return {
        ...state,
        cart_items: state.cart_items
          .filter((item) => !action.payload.includes(item.id))
          .map((item) => {
            return { ...item, selected: action.payload };
          }),
      };

    case "base/SELECT_ALL_TABLE_ROWS":
      return {
        ...state,
        cart_items: state.cart_items.map((item) => {
          return { ...item, selected: action.payload };
        }),
      };

    case "base/SELECT_CART_ITEM":
      return {
        ...state,
        cart_items: state.cart_items.map((item) => {
          if (action.payload.itemId === item.id) {
            return { ...item, selected: !item.selected };
          }
          return item;
        }),
      };

    case "base/GET_CART_ITEMS_RES":
      return {
        ...state,
        isFetching: state.isFetching - 1,
        cart_items: action.payload,
      };

    case "base/CREATE_REFERRAL_RES":
      return {
        ...state,
        referrals: action.payload,
        isFetching: state.isFetching - 1,
      };

    case "base/GET_REFERRALS_REQ":
      return {
        ...state,
        isFetchingReferrals: true,
      };

    case "base/GET_REFERRALS_RES":
      return {
        ...state,
        referrals: action.payload,
        isFetchingReferrals: false,
      };

    case "base/GET_REFERRALS_ERR":
      return {
        ...state,
        isFetchingReferrals: false,
      };

    case "base/GET_ACCOUNT_TYPE_RES":
      return {
        ...state,
        settings: {
          ...state.settings,
          account_type: action.payload.account_type,
        },
        isFetching: state.isFetching - 1,
      };

    case "base/RESET_RANGE": {
      let newData = { min: "", max: "", show: false };

      if (action.payload.type === "profit") {
        newData = { min: "", show: false };
      }

      if (action.payload.type === "shipping") {
        newData = { max: "", show: false };
      }

      // CUSTOMIZE FIELDS

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: {
            ...state.filters[action.payload.type],
            ...newData,
          },
        },
      };
    }

    case "base/RESET_MORE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          moreFilters: {
            ...state.filters.moreFilters,
            filters: state.filters.moreFilters.filters.map((filter) => {
              return {
                ...filter,
                options: filter.options.map((option) => {
                  return {
                    ...option,
                    selected: false,
                  };
                }),
              };
            }),
          },
        },
      };

    case "base/UPDATE_MORE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          moreFilters: {
            ...state.filters.moreFilters,
            filters: state.filters.moreFilters.filters.map((filter) => {
              if (filter.label === action.payload.filterLabel) {
                return {
                  ...filter,
                  options: filter.options.map((option) => {
                    if (option.value === action.payload.value) {
                      return {
                        ...option,
                        selected: !option.selected,
                      };
                    } else {
                      if (action.payload.maxOneSelected) {
                        return { ...option, selected: false };
                      }
                    }
                    return option;
                  }),
                };
              }
              return filter;
            }),
          },
        },
      };

    case "base/CLEAR_FILTERS":
      return { ...state, filters: INITIAL_FILTERS };

    case "base/INITIALIZE_FILTERS":
      return { ...state, filters: action.payload };

    case "base/SHOW_HIDE_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: {
            ...state.filters.sort,
            show:
              action.payload.type === "sort" ? !state.filters.sort.show : false,
          },
          price: {
            ...state.filters.price,
            show:
              action.payload.type === "price"
                ? !state.filters.price.show
                : false,
          },
          profit: {
            ...state.filters.profit,
            show:
              action.payload.type === "profit"
                ? !state.filters.profit.show
                : false,
          },
          shipping: {
            ...state.filters.shipping,
            show:
              action.payload.type === "shipping"
                ? !state.filters.shipping.show
                : false,
          },
          moreFilters: {
            ...state.filters.moreFilters,
            show:
              action.payload.type === "moreFilters"
                ? !state.filters.moreFilters.show
                : false,
          },
        },
      };

    case "base/UPDATE_FILTERS": {
      const { type, value, field } = action.payload;

      let newFilters = {
        ...state,
        filters: {
          ...state.filters,
          [type]: field
            ? {
                ...state.filters[type],
                [field]: value,
              }
            : value,
        },
      };

      if (type === "category") {
        newFilters = {
          ...newFilters,
          filters: {
            ...newFilters.filters,
            page: 1,
            search: "",
            subcategory: "",
          },
        };
      }

      if (type === "subcategory") {
        newFilters = {
          ...newFilters,
          filters: {
            ...newFilters.filters,
            page: 1,
            search: "",
          },
        };
      }

      return newFilters;
    }

    case DISMISS_OFFER:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            offer: { ...state.settings.store.offer, dismissed: true },
          },
        },
      };

    case DISMISS_OFFER_ERR:
      return {
        ...state,
        error: "Error dismissing offer. Contact support@dropcommerce.com",
      };

    case "FETCH_CATEGORIES_RES":
      return {
        ...state,
        categories: action.payload,
        isFetching: state.isFetching - 1,
      };

    case BUY_PREMADE_STORE_REQ:
      return {
        ...state,
        isBuyingStore: true,
      };

    case BUY_PREMADE_STORE_RES:
      return {
        ...state,
        isBuyingStore: false,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            purchased_stores: [
              ...state.settings.store.purchased_stores,
              action.payload.storeType,
            ],
          },
        },
      };

    case BUY_PREMADE_STORE_ERR:
      return {
        ...state,
        isBuyingStore: false,
        error: action.payload.error,
      };

    case UPDATE_STORE:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: { ...state.settings.store, ...action.payload },
        },
      };

    case UPDATE_STATE:
      return updateState(state, action.payload);

    // REQUESTS
    case FETCH_STORE_APPROVAL_DATA_REQ:
      return {
        ...state,
        isFetchingApprovalData: true,
      };

    case FETCH_STORE_APPROVAL_DATA_RES:
      return {
        ...state,
        isFetchingApprovalData: false,
        storeApprovalData: action.payload.data,
      };

    case FETCH_STORE_APPROVAL_DATA_ERR:
      return {
        ...state,
        isFetchingApprovalData: false,
        storeApprovalData: action.payload.data,
      };

    case SUBMIT_REVIEW_REQ:
      return {
        ...state,
        isFetchingReview: true,
        settings: {
          ...state.settings,
          store: { ...state.settings.store, show_review: false },
        },
      };

    case UPDATE_AUTO_FULFILLMENT_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            auto_fulfillment: action.payload.autoFulfillment,
          },
        },
      };

    case UPDATE_IMPORTED_TO_SHOPIFY_COUNT:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            imported_to_shopify_count:
              state.settings.store.imported_to_shopify_count + 1,
          },
        },
      };

    case UPDATE_IMPORT_LIST_COUNT:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            import_list_count: state.settings.store.import_list_count + 1,
          },
        },
      };

    case FEATURED_SUPPLIERS_REQ:
      return {
        ...state,
      };

    case GENERIC_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
      };

    case FEATURED_SUPPLIERS_RES:
      return {
        ...state,
        featuredSuppliers: action.payload.suppliers,
        isFetching: state.isFetching - 1,
      };

    case UPDATE_SUPPLIERS_FROM_LS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        pagination: action.payload.pagination,
      };

    case UPDATE_SETTINGS_FROM_LS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    case CANCEL_ORDER_REQ:
      return {
        ...state,
        order: { ...state.order, cancelling: true },
      };

    case CANCEL_ORDER_RES:
      return {
        ...state,
        order: { ...state.order, cancelling: false, status: "cancelled" },
      };

    case CANCEL_ORDER_ERR:
      return {
        ...state,
        order: { ...state.order, cancelling: false },
      };

    case FULFILL_ORDER_REQ:
      return {
        ...state,
        order: {
          ...state.order,
          suppliers: state.order.suppliers.map((supplier) => {
            if (supplier.id === action.payload.supplier_id) {
              return { ...supplier, isFetching: true };
            } else {
              return supplier;
            }
          }),
        },
      };

    case UPDATE_SETTINGS_REQ:
      return {
        ...state,
        isFetchingSettings: true,
        settings: {
          ...state.settings,
          [`isSaving_${action.payload.savingType}`]: true,
        },
      };

    case UPDATE_SETTINGS_ERR:
      return {
        ...state,
        isFetchingSettings: false,
        settings: {
          ...state.settings,
          [`isSaving_${action.payload.savingType}`]: false,
        },
      };

    case SAVE_STRIPE_CARD_REQ:
      return {
        ...state,
        isSavingStripe: true,
      };

    case REMOVE_STRIPE_CARD_RES:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            stripe_connected: false,
          },
        },
        isSavingStripe: false,
      };

    case SET_LAST_SEARCH:
      return {
        ...state,
        lastSearch: action.payload.searchTerm,
      };

    case SET_LAST_PAGINATION_STATE:
      return {
        ...state,
        lastPagination: state.pagination,
      };

    // RESPONSES
    case SET_PLAN_RES:
      return {
        ...state,
        planAuthUrl: action.payload.planAuthUrl,
        isFetching: state.isFetching - 1,
      };

    case PLAN_SAVED_RES:
      // THIS IS HACKY BUT NOT SURE HOW TO FIX, RACE CONDITION WITH FETCH SETTINGS
      if (get(state, ["settings", "store"])) {
        return {
          ...state,
          isFetching: state.isFetching - 1,
          settings: {
            ...state.settings,
            store: {
              ...state.settings.store,
              payment_plan: action.payload,
            },
          },
        };
      } else {
        return {
          ...state,
          isFetching: state.isFetching - 1,
        };
      }

    case SAVE_STRIPE_CARD_RES:
      return {
        ...state,
        isSavingStripe: false,
        settings: {
          ...state.settings,
          store: { ...state.settings.store, stripe_connected: true },
        },
      };

    case SET_SETTINGS_SAVED_RES:
      return {
        ...state,
        settingsSaved: action.payload.settingsSaved,
      };

    case UPDATE_SETTINGS_RES:
      return {
        ...state,
        isFetchingSettings: false,
        settingsSaved: true,
      };

    case UPDATE_SUPPLIER_SETTINGS_RES:
      return {
        ...state,
        isFetchingSettings: false,
        settings: {
          ...state.settings,
          supplier: action.payload.supplier,
          [`isSaving_${action.payload.savingType}`]: false,
          [`isSaved_${action.payload.savingType}`]: true,
        },
      };

    case GENERIC_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
      };

    case SHOPIFY_AUTH_RES:
      return {
        ...state,
        shopifyData: action.payload.shopifyData,
      };

    case REGISTER_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        accountCreated: true,
        error: "",
      };

    case FULFILL_ORDER_RES:
      return {
        ...state,
        order: {
          ...state.order,
          suppliers: state.order.suppliers.map((supplier) => {
            if (supplier.id === action.payload.supplier_id) {
              return { ...supplier, isFetching: true, status: "paid" };
            } else {
              return supplier;
            }
          }),
        },
      };

    case UPDATE_VENDOR_APPROVAL_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        settings: { ...state.settings, supplier: action.payload.supplier },
      };

    case FETCH_ORDER_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
        order: {},
      };

    case FETCH_ORDER_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        order: action.payload.order,
      };

    case FETCH_ORDERS_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1,
        orders: [],
        pagination: {},
      };

    case FETCH_ORDERS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        orders: action.payload.orders,
        pagination: action.payload.pagination,
      };

    case LOGIN_RES:
      return {
        ...state,
        authenticated: true,
        isFetching: state.isFetching - 1,
        accessToken: action.payload.accessToken,
        error: "",
      };

    case HIDE_INTRO_NOTICE:
      return {
        ...state,
        settings: {
          ...state.settings,
          store: {
            ...state.settings.store,
            intro_state: 3,
          },
        },
      };

    case PLAN_SAVED_ERR:
      return {
        ...state,
        errorType: action.payload.errorType,
      };

    case STRIPE_SUPPLIER_AUTH_REQ:
      return {
        ...state,
        settings: {
          ...state.settings,
          supplier: { ...state.settings.supplier, isSavingStripe: true },
        },
      };

    case STRIPE_SUPPLIER_AUTH_RES:
      return {
        ...state,
        settings: {
          ...state.settings,
          supplier: {
            ...state.settings.supplier,
            stripe_id_saved: true,
            isSavingStripe: false,
          },
        },
      };

    case FULFILL_ORDER_ERR:
      return {
        ...state,
        error: action.payload.error,
        order: {
          ...state.order,
          suppliers: state.order.suppliers.map((supplier) => {
            if (supplier.id === action.payload.supplier_id) {
              return {
                ...supplier,
                isFetching: false,
                status: action.payload.paid ? "paid" : supplier.status,
                // error: action.payload.error
              };
            }
            return supplier;
          }),
        },
      };

    case SET_ERR:
      return {
        ...state,
        error: action.payload.error,
      };

    case "base/FETCH_SETTINGS_REQ":
      return {
        ...state,
        isFetchingSettings: true,
      };

    case "base/FETCH_SETTINGS_RES":
      return {
        ...state,
        isFetchingSettings: false,
        settings: action.payload,
      };

    case "base/FETCH_SETTINGS_ERR":
      return {
        ...state,
        isFetchingSettings: false,
      };

    case REGISTER_ERR:
      return {
        ...state,
      };

    case LOGIN_ERR:
      return {
        ...state,
        isFetching: state.isFetching - 1,
      };

    case SHOPIFY_AUTH_ERR:
      return {
        ...state,
      };

    case CREATE_SHOPIFY_ORDER_ERR:
      return {
        ...state,
      };

    case FETCH_ORDERS_ERR:
      return {
        ...state,
      };

    case FEATURED_SUPPLIERS_ERR:
      return {
        ...state,
      };

    case GENERIC_ERR:
      // 1 means loading, 0 means idle
      return {
        ...state,
        error: action.payload.error,
        errorType: action.payload.errorType,
        isFetching: state.isFetching - 1,
      };

    case FETCH_ARCHIVED_CONVERSATIONS_REQ:
      return {
        ...state,
      };

    case FETCH_ARCHIVED_CONVERSATIONS_RES:
      return {
        ...state,
        archivedConversations: action.payload.conversations,
      };

    case FETCH_ARCHIVED_CONVERSATIONS_ERROR:
      return {
        ...state,
      };

    default: {
      return state;
    }
  }
};

export default base;
