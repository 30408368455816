import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import createNotification from "../../../modules/Notification";
import {
  featureProduct,
  fetchShippingOptions,
} from "../../../views/supplier/SupplierActions";
import {
  getIsFetching,
  getProduct,
  getSettings,
  getShippingOptions,
} from "../../../views/supplier/SupplierSelectors";
import TabNavigationLight from "../../../components/TabNavigationLight";
import Text from "../../../modules/Text";
import { getUrlParameter } from "../../../modules/Format";
import Switch from "../../../components/Switch";
import Titlebar from "../../../components/Titlebar";
import BackNavBar from "../../../components/BackNavBar";
import { Column, Row } from "../../../components/Layout";
import Label from "../../../components/Label";
import ButtonNew from "../../../components/ButtonNew";
import Wrapper from "../../../components/Wrapper";
import Input from "../../../components/Input";
import { Container } from "../../../components/Container";
import { getRequest, postRequest } from "../../../modules/API";
import NewImage from "../components/NewImage";
import VariantRelation from "../components/VariantRelation";
import { theme } from "../../../modules/Theme";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import { get } from "lodash";
import ShippingOptionsList from "../../../views/settings/views/supplier/shipping/ShippingOptionsList";
import { FiCheck, FiPlus } from "react-icons/fi";
import { trackEvent } from "../../../modules/EventTracking";
import StyledLink from "../../../components/Link";
import { Checkbox } from "@material-ui/core";

const mapStateToProps = (state) => ({
  product: getProduct(state),
  settings: getSettings(state),
  isFetching: getIsFetching(state),
  shippingOptions: getShippingOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchShippingOptions,
      featureProduct,
    },
    dispatch
  ),
});

class Product extends Component {
  state = {
    isFetching: this.props.match.params.productId,
    product: { variants: [] },
    images: [],
    errors: [],
    editingDescription: false,
    description: EditorState.createWithContent(
      stateFromHTML("<p>Enter your product description here.</p>")
    ),
    saved: false,
    tab: "basic",
  };

  componentDidMount = () => {
    const productId = this.props.match.params.productId;
    if (productId) {
      this.fetchSupplierProduct(this.props.match.params.productId)
        .then((response) => {
          const description = EditorState.createWithContent(
            stateFromHTML(response.description)
          );

          this.setState({ description });
        })
        .catch((error) => {});
    }

    this.props.fetchShippingOptions(this.props.match.params.productId);
  };

  fetchSupplierProduct = (id) =>
    getRequest({ url: `supplier_product/?id=${id}` }).then((response) => {
      trackEvent("View Supplier Product");
      this.setState({ product: response.data.product, isFetching: false });
      return response.data.product;
    });
  // .catch((error) => {
  //   console.log(error);
  // });

  updateVariantRelationField = (id, field, value) => {
    this.setState({
      product: {
        ...this.state.product,
        variants: this.state.product.variants.map((variant) => {
          if (id === variant.id) {
            return {
              ...variant,
              [field]: value,
            };
          }
          return variant;
        }),
      },
    });
  };

  showWidget = () => {
    const uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dropcommerce",
        uploadPreset: "hjeazqpb",
      },
      (error, result) => this.saveUpload(error, result)
    );

    uploadWidget.open();
  };

  deleteImage = (imageId) => {
    this.setState({ isFetching: true });
    postRequest({ url: "image_delete/", data: { image_id: imageId } })
      .then((response) =>
        this.setState({
          product: { ...this.state.product, images: response.data.images },
        })
      )
      .then((_res) => this.setState({ isFetching: false }));
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  saveUpload = (error, result) => {
    if (!error && result && result.event === "success") {
      const productId = this.props.match.params.productId;
      this.saveImage(productId, result.info.secure_url);
    } else {
      // ERROR
    }
  };

  saveImage = (productId, url) => {
    this.setState({
      isFetchingImages: true,
    });

    postRequest({ url: "image_upload/", data: { product_id: productId, url } })
      .then((_response) => {
        this.setState({ isFetchingImages: false });
      })
      .then(this.fetchSupplierProduct(this.props.match.params.productId));
  };

  onEditorStateChange = (description) => {
    this.setState({
      description,
    });
  };

  activateProduct = () => {
    const newState = {
      id: this.state.product.id,
      active: !this.state.product.active,
    };

    this.createProduct(newState);
  };

  createProduct = (data) => {
    return new Promise((resolve, _reject) => {
      this.setState({ isFetching: true });
      postRequest({ url: "create_product/", data }).then((_res) => {
        this.fetchSupplierProduct(this.props.match.params.productId).then(
          (res) => {
            resolve(res);
            createNotification({
              type: "success",
              title: "UPDATED",
              message: "Updated Successfully",
            });
          }
        );
      });
    });
  };

  saveProduct = () => {
    if (this.validateFields()) {
      // Save product details

      this.createProduct(this.state.product).then(() => {
        this.setState({ saved: true, errors: [] });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 2000);
      });
    } else
      createNotification({ type: "danger", title: "ERROR", message: "Error!" });
  };

  updateProductField = (field, value) => {
    this.setState({ product: { ...this.state.product, [field]: value } });
  };

  selectFeatureImage = (imageId) => {
    this.setState({
      product: {
        ...this.state.product,
        images: this.state.product.images.map((image) => {
          return { ...image, feature: image.id === imageId };
        }),
      },
    });
  };

  addVariant = (evt) => {
    this.setState({
      product: {
        ...this.state.product,
        variants: [
          ...this.state.product.variants,
          {
            active: true,
            name: "",
            price: 0.0,
            quantity: 100,
            retail_price: 0.0,
            sku: "",
          },
        ],
      },
    });
  };

  validateFields = () => {
    const { title, variants, discount_percent } = this.state.product;

    // Temporary solution until we have a better situation with pricing for private portal
    const subdomain = get(this.props, ["settings", "subdomain"]);
    const allowAnyPrice =
      get(this.props, ["settings", "allow_any_price"]) || subdomain !== "";

    let errors = {};
    let variantError = false;

    if (discount_percent < 30) {
      errors["discount_percent"] = "Reseller margin must be at least 30%";
    }

    if (title.length < 4) {
      errors["title"] = "Please enter a valid title";
    }

    if (variants.length < 1) {
      errors["variants"] = "Please create at least one variant.";
    }

    if (!variants.filter((variant) => variant.active).length > 0) {
      errors["variants"] = "You must have at least one active variant.";
    }

    let newVariants = variants.map((variant) => {
      let variantErrors = {};

      if (variant.active) {
        if (variant.name.length < 1) {
          variantErrors["name"] = "Please enter a valid name";
        }

        if (variant.price < 1) {
          variantErrors["price"] = "Please enter a valid price";
        }

        if (variant.retail_price < 1) {
          variantErrors["retail_price"] = "Please enter a valid retail price";
        }

        if (!allowAnyPrice) {
          if (
            parseFloat(variant.price) >
            parseFloat(variant.retail_price * 0.7 + 1)
          ) {
            variantErrors["price"] =
              "Price must be at least 30% off retail price";
          }
        }
      }

      if (Object.keys(variantErrors).length > 0) {
        variantError = true;
      }

      return { ...variant, errors: variantErrors };
    });

    this.updateProductField("errors", errors);
    this.updateProductField("variants", newVariants);

    return Object.keys(errors).length === 0 && !variantError;
  };

  saveDescription = () => {
    const description = stateToHTML(this.state.description.getCurrentContent());

    this.setState({ savingDescription: true });

    this.createProduct({
      id: this.state.product.id,
      description,
      sku: this.state.product.sku,
    });
    this.setState({ editingDescription: false, savingDescription: false });
  };

  removeVariant = (index, id) => {
    const variants = this.state.product.variants;
    if (variants[index].new) {
      this.deleteVariant(index);
    } else {
      this.updateVariantRelationField(id, "active", false);
    }
  };

  deleteVariant = (id) => {
    this.setState({
      product: {
        ...this.state.product,
        variants: this.state.product.variants.filter((v, i) => id !== i),
      },
    });
  };

  featuredProduct = () => {
    this.props.featureProduct(this.state.product.id);
    this.setState({
      product: {
        ...this.state.product,
        featured: !this.state.product.featured,
      },
    });
  };

  markFeatured = (index) => {
    this.setState({
      images: this.state.images.map((image, i) => {
        if (i === index) {
          return { ...image, feature: true };
        }
        return { ...image, feature: false };
      }),
    });
  };

  updateImageInput = (value, input, index) => {
    this.setState({
      images: this.state.images.map((image, i) => {
        if (i === index) {
          return { ...image, [input]: value };
        }
        return image;
      }),
    });
  };

  render() {
    const productId = this.props.match.params.productId;
    // const isNew = getUrlParameter("new", this.props.location);
    const tab = getUrlParameter("tab", this.props.location);
    const pastPagination =
      getUrlParameter("past_pagination", this.props.location) || 1;

    const trackInventory = get(this.props, ["settings", "sync_inventory"]);
    const trackPricing = get(this.props, ["settings", "sync_pricing"]);
    const shopifyConnected =
      get(this.props, ["settings", "shopify_url"]) !== "" &&
      get(this.props, ["settings", "shopify_token_saved"]);

    const product = get(this.state, "product");
    const images = get(product, "images");
    const variants = get(product, "variants");
    const imgCount = images
      ? images.filter((image) => !image.delete).length
      : 0;

    const isFetchingProduct = get(this.state, ["product", "isFetching"]);
    const productTitle = get(this.state, ["product", "title"]);

    const baseUrl = get(this.props, ["match", "url"]);
    const tabs = [
      {
        name: "Basic Info",
        link: () => this.props.history.push(`${baseUrl}?tab=basic`),
        active: tab === "basic" || !tab,
      },
      {
        name: "Images",
        link: () => this.props.history.push(`${baseUrl}?tab=images`),
        active: tab === "images",
      },
      {
        name: "Pricing & Variants",
        link: () => this.props.history.push(`${baseUrl}?tab=variants`),
        active: tab === "variants",
      },
      {
        name: "Shipping Rates",
        link: () => this.props.history.push(`${baseUrl}?tab=shipping`),
        active: tab === "shipping",
      },
    ];

    if (this.state.editingDescription) {
      let description = this.state.description;

      if (!description._immutable) {
        description = stateFromHTML(description);
      }
      return (
        <Wrapper>
          <Titlebar title="Edit Product Description" />

          <Container>
            <Row style={{ marginTop: "20px", marginBottom: "30px" }}>
              <ButtonNew
                type="main"
                text="Save Changes"
                extra="width: 180px; padding: 15px;"
                onClick={this.saveDescription}
                isFetching={this.state.savingDescription}
              />
              <ButtonNew
                type="secondary"
                text="Cancel"
                extra="width: 180px; padding: 15px; margin-left: 20px; background: white;"
                onClick={() => this.setState({ editingDescription: false })}
              />
            </Row>

            {description && description._immutable && (
              <EditorWrapper>
                <Editor
                  editorStyle={{
                    padding: "20px",
                    minHeight: "200px",
                  }}
                  editorState={description}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </EditorWrapper>
            )}
          </Container>
        </Wrapper>
      );
    }

    if (get(product, "error")) {
      return (
        <Wrapper>
          <Titlebar title="Invalid Product" />
          <BackNavBar
            title="PRODUCTS"
            onClick={() =>
              this.props.history.push(
                `/supplier/products?page=${pastPagination}`
              )
            }
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        additionalBanner={
          !get(this.state, ["product", "images", "length"]) ? (
            <StyledLink
              to={`${this.props.history.location.pathname}/?tab=images`}
              color="white"
              extra={{
                padding: "10px",
                width: "100%",
                display: "block",
                textAlign: "center",
                backgroundColor: "darkRed",
              }}
            >
              Products without images won't appear in the Marketplace.
            </StyledLink>
          ) : null
        }
      >
        <Container>
          <Row style={{ justifyContent: "space-between" }}>
            <Column>
              <BackNavBar
                title="PRODUCTS"
                onClick={() =>
                  this.props.history.push(
                    `/supplier/products?page=${pastPagination}`
                  )
                }
              />

              <Text.Large>
                {productId && productTitle ? productTitle : "New Product"}
              </Text.Large>
            </Column>

            {!this.state.editingDescription && (
              <Row style={{ flexWrap: "wrap", gap: "20px" }}>
                <ButtonNew
                  size="large"
                  type={this.state.saved ? "main-light" : "main"}
                  text={this.state.saved ? "Saved" : "Save Changes"}
                  icon={
                    this.state.saved ? (
                      <FiCheck size="18" color="white" />
                    ) : null
                  }
                  disabled={this.state.saved}
                  onClick={this.saveProduct}
                  isFetching={isFetchingProduct}
                />

                <ButtonNew
                  size="large"
                  type="secondary"
                  text={
                    get(product, "active")
                      ? "Deactivate Product"
                      : "Activate Product"
                  }
                  disabled={this.state.saved}
                  onClick={this.activateProduct}
                  isFetching={isFetchingProduct}
                />
              </Row>
            )}
          </Row>

          <TabNavigationLight
            tabs={tabs}
            extra="margin-top: 30px; margin-bottom: 30px;"
          />

          {!this.state.isFetching ? (
            <React.Fragment>
              {get(product, ["errors", "variants"]) && (
                <Text.Small extra="color: #c12119; margin-top: 5px; margin-bottom: 15px;">
                  {get(product, ["errors", "variants"])}
                </Text.Small>
              )}

              {tab === "shipping" && (
                <div>
                  <Text.Small light extra="margin-top: 15px;">
                    Set custom shipping rates for this specific product to
                    override your account-level shipping rates.
                  </Text.Small>

                  <Text.Small
                    link
                    extra="margin-top: 15px;"
                    onClick={() =>
                      this.props.history.push("/supplier/settings/shipping")
                    }
                  >
                    Edit General Shipping Rates
                  </Text.Small>

                  <ShippingOptionsList
                    shippingOptions={get(this.props, "shippingOptions")}
                    product={get(product, "id")}
                    productTitle={get(product, "title")}
                  />
                </div>
              )}

              {tab === "images" && (
                <div>
                  <Text extra="margin-bottom: 10px;">Images</Text>

                  {get(product, ["errors", "no_image"]) && (
                    <Text.Small extra="color: #c12119; margin-top: 5px; margin-bottom: 10px;">
                      {get(product, ["errors", "no_image"])}
                    </Text.Small>
                  )}

                  {this.state.isFetchingImages ? (
                    <ImagesFetchingContainer>
                      <MDSpinner singleColor="rgb(73,190,175)" size={50} />
                    </ImagesFetchingContainer>
                  ) : (
                    <React.Fragment>
                      {!productId ? (
                        <Text.Small extra="margin-top: 5px;">
                          Images can be added once you save the product
                          information.
                        </Text.Small>
                      ) : (
                        <React.Fragment>
                          {get(product, ["errors", "feature_image"]) && (
                            <Text.Small extra="color: #c12119; margin-top: 5px;">
                              {get(product, ["errors", "feature_image"])}
                            </Text.Small>
                          )}
                          <ButtonNew
                            type="main"
                            text="Upload Image"
                            extra="width: 180px; padding: 15px; margin-bottom: 20px;"
                            icon={<FiPlus size="18" color="white" />}
                            onClick={this.showWidget}
                          />
                          <Row style={{ flexWrap: "wrap" }}>
                            {get(product, "images") &&
                              get(product, "images").map((image, i) => (
                                <NewImage
                                  canDelete={imgCount > 1}
                                  key={i}
                                  image={image}
                                  updateInput={this.updateImageInput}
                                  markFeatured={() =>
                                    this.selectFeatureImage(image.id)
                                  }
                                  index={i}
                                  deleteImage={() => this.deleteImage(image.id)}
                                />
                              ))}
                          </Row>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}

              {(!tab || tab === "basic") && (
                <div style={{ maxWidth: "600px" }}>
                  <Text light>Featured</Text>
                  <Checkbox
                    style={{ marginLeft: "-10px" }}
                    color="primary"
                    checked={this.state.product.featured}
                    onClick={this.featuredProduct}
                  />
                  <Input
                    label="Title"
                    onChange={(e) =>
                      this.updateProductField("title", e.target.value)
                    }
                    placeholder="New Product"
                    value={get(product, "title")}
                    error={get(product, ["errors", "title"])}
                  />

                  <Input
                    label="SKU"
                    onChange={(e) =>
                      this.updateProductField("sku", e.target.value)
                    }
                    placeholder="1234"
                    value={get(product, "sku")}
                  />

                  <Label
                    linkAction={() =>
                      this.setState({ editingDescription: true })
                    }
                    linkText="Edit"
                  >
                    Description
                  </Label>

                  {product.description === "" ? (
                    <DescriptionDisplay
                      empty
                      onClick={() =>
                        this.setState({ editingDescription: true })
                      }
                    >
                      Add your product description here.
                    </DescriptionDisplay>
                  ) : (
                    <DescriptionDisplay
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  )}
                </div>
              )}

              {tab === "variants" && (
                <div>
                  {shopifyConnected && (
                    <React.Fragment>
                      {trackInventory && (
                        <Switch
                          active={product.track_inventory}
                          label="Sync Shopify Inventory"
                          description="This product's inventory is synced from your Shopify account daily. Please ensure your inventory is accurate in Shopify."
                          toggle={() =>
                            this.updateProductField(
                              "track_inventory",
                              !product.track_inventory
                            )
                          }
                        />
                      )}

                      {trackPricing && (
                        <Switch
                          active={product.track_pricing}
                          label="Sync Shopify Pricing"
                          description="This product's price is synced from your Shopify account daily, based on a % off Retail Price, as defined in your profile settings."
                          toggle={() =>
                            this.updateProductField(
                              "track_pricing",
                              !product.track_pricing
                            )
                          }
                        />
                      )}

                      {trackPricing && (
                        <React.Fragment>
                          <Switch
                            active={product.override_discount_percent}
                            label="Override Reseller Margin"
                            description="If you need to offer a different margin for this product specifically, adjust the discount % here"
                            toggle={() =>
                              this.updateProductField(
                                "override_discount_percent",
                                !product.override_discount_percent
                              )
                            }
                          />
                          {product.override_discount_percent && (
                            <Input
                              label="Reseller Margin %"
                              onChange={(text) =>
                                this.updateProductField(
                                  "discount_percent",
                                  text.target.value
                                )
                              }
                              placeholder="30"
                              value={product.discount_percent}
                              error={get(product, [
                                "errors",
                                "discount_percent",
                              ])}
                              type="number"
                              extraStyle="width: 100px;"
                              width="100px;"
                            />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  <Text extra="margin-top: 30px;">Variant Options</Text>
                  <Text.Small
                    light
                    extra="margin-top: 10px; margin-bottom: 10px;"
                  >
                    List the different options for your product, like Color or
                    Size.
                  </Text.Small>
                  {get(product, ["errors", "variants"]) && (
                    <Text.Small extra="color: #c12119; margin-top: 5px;">
                      {get(product, ["errors", "variants"])}
                    </Text.Small>
                  )}

                  <ButtonNew
                    type="main"
                    text="Add Variant"
                    extra="width: 200px; margin-top: 20px;"
                    onClick={this.addVariant}
                  />
                  {variants &&
                    variants.map((variant, i) => (
                      <VariantRelation
                        key={i}
                        trackInventory={
                          shopifyConnected &&
                          trackInventory &&
                          product.track_inventory
                        }
                        trackPricing={
                          shopifyConnected &&
                          trackPricing &&
                          product.track_pricing
                        }
                        variant={variant}
                        updateInput={(field, value) =>
                          this.updateVariantRelationField(
                            variant.id,
                            field,
                            value
                          )
                        }
                        removeVariant={() => this.removeVariant(i, variant.id)}
                        index={i}
                      />
                    ))}
                </div>
              )}
            </React.Fragment>
          ) : (
            <SpinnerWrapper>
              <MDSpinner singleColor="rgb(73,190,175)" size={50} />
            </SpinnerWrapper>
          )}
        </Container>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product)
);

const DescriptionDisplay = styled.div`
  border: 1px solid ${theme.colors.inputBorder};
  border-radius: 4px;
  padding: 15px;
  margin-top: 10px;
  ${(p) => p.empty && "cursor: pointer; height: 200px;"}
`;

export const VariantsWrapper = styled.div`
  margin-top: 30px;
`;

const EditorWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  width: 700px;
  max-width: 95vw;
`;

export const SpinnerWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ImagesFetchingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
`;

export const Image = styled.img`
  height: 200px;
  width: 200px;
  margin-right: 10px;
  object-fit: contain;
  border: 1px solid ${theme.colors.inputBorder};
`;

export const Variant = styled.div`
  margin-bottom: 15px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
`;
