import React from "react";
import { Switch, Route } from "react-router-dom";
import AddressList from "./AddressList";
// import ShippingAddress from "./ShippingAddress";
import CustomerAddress from "./CustomerAddress";

function Addresses() {
  const path = `/settings/addresses`;
  return (
    <Switch>
      <Route path={`${path}/new`} exact component={CustomerAddress} />
      <Route path={`${path}/:id`} component={CustomerAddress} />
      <Route path={`${path}`} exact component={AddressList} />
    </Switch>
  );
}

export default Addresses;

// Using this component in place of Shipping Address
// Search where component={Addresses} is being used to revert to using the old component
// try going to {path}/settings/samples before deleting this comment
