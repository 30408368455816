import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ButtonNew from "../../components/ButtonNew";

import {
  PrivacyContainer,
  PrivacySection,
  PrivacyTitle,
} from "../../views/landing/LandingStyle";
import { trackEvent } from "../../modules/EventTracking";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const ContentWhite = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
`;

export const ContentGreyFlat = styled.div`
  background: #fafafa;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 100px;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: ${(p) => (p.reverse ? "wrap-reverse" : "wrap")};
    padding: 20px;
    justify-content: center;
  }
`;

class Privacy extends Component {
  UNSAFE_componentWillMount = () => {
    trackEvent("Privacy");
  };
  render() {
    return (
      <PageWrapper>
        <ContentWhite>
          <Content>
            <PrivacyContainer>
              <PrivacyTitle>DropCommerce Privacy Policy</PrivacyTitle>
              <PrivacySection>
                DropCommerce (“DropCommerce”, “us”, or “we”) operates the
                website www.dropcommerce.com (referred to as the “Site”).
              </PrivacySection>
              <PrivacySection>
                We at DropCommerce are committed to protecting your private
                information. This Privacy Policy set out our practices regarding
                the collection of our users’ personal information (“Personal
                Information”). This Privacy Policy applies to all users of the
                Site and its services. If you do not agree to the terms set out
                in this Privacy Policy, you must immediately stop using the
                Site. If you have any questions or concerns regarding this
                Privacy Policy, please contact us using the information below
                prior to using the Site.
              </PrivacySection>

              <PrivacyTitle>Collection of Information</PrivacyTitle>
              <PrivacySection>
                We may save any Personal Information you provide us for the
                purpose of facilitating our services, and for record-keeping
                purposes. Personal Information may include but is not limited to
                your home address, phone number, email address, city and name.
                Our website uses SSL encryption to protect from unauthorized
                access to any Personal Information. All requests to erase
                personal information will be completed within 30 days.
              </PrivacySection>
              <PrivacySection>
                As an additional security measure, any payment information you
                provide, including but not limited to credit card information,
                is never sent to or stored on our server. Your information is
                sent directly to the servers at Stripe, a third party payment
                processing company, and is returned to us as an anonymized token
                containing the information required to charge you for service
                fees, but not view the information directly. This token may be
                stored on our server. We recommend that you review the terms and
                policies of Stripe at www.stripe.com.
              </PrivacySection>

              <PrivacyTitle>Commitment to Data Security</PrivacyTitle>
              <PrivacySection>
                Your Personal Information is kept secure. Only authorized
                employees, agents and contractors (who have agreed to keep
                information secure and confidential) have access to this
                information.{" "}
              </PrivacySection>
              <PrivacySection>
                All emails and newsletters from this site allow you to opt out
                of further mailings.
              </PrivacySection>

              <PrivacyTitle>Cookie/Tracking Technology</PrivacyTitle>
              <PrivacySection>
                The Site may use cookie and tracking technology depending on the
                features offered. Cookie and tracking technology are useful for
                gathering information such as browser type and operating system,
                tracking the number of visitors to the Site, and understanding
                how visitors use the Site. Cookies can also help customise the
                Site for visitors. Personal information cannot be collected via
                cookies and other tracking technology, however, if you
                previously provided Personal Information, cookies may be tied to
                such Personal Information. Aggregate cookie and tracking
                information may be shared with third parties.
              </PrivacySection>

              <PrivacyTitle>Distribution of Information</PrivacyTitle>
              <PrivacySection>
                We may share information with governmental agencies or other
                companies assisting us in fraud prevention or investigation. We
                may do so when: (1) permitted or required by law; or, (2) trying
                to protect against or prevent actual or potential fraud or
                unauthorized transactions; or, (3) investigating fraud which has
                already taken place. The information is not provided to these
                companies for marketing purposes.{" "}
              </PrivacySection>
              <PrivacySection>
                We may share any of your provided information with Stripe, or
                any other third party payment processing company we choose in
                our sole discretion, for payment processing and record keeping
                purposes. We do not share user information with third parties
                other than with our third party payment processing company. We
                do not sell any user information to third parties.
              </PrivacySection>

              <PrivacyTitle>Privacy Contact Information</PrivacyTitle>
              <PrivacySection>
                If you have any questions, concerns, or comments about our
                privacy policy you may contact us using the information below:
              </PrivacySection>
              <PrivacySection>
                By email: support@dropcommerce.com
              </PrivacySection>
              <PrivacySection>
                We reserve the right, at our sole discretion, to modify or
                replace this Privacy Policy at any time. If a revision is
                material we will make reasonable efforts to provide at least 30
                days’ notice prior to any new Privacy Policy taking effect. What
                constitutes a material change will be determined at our sole
                discretion. By continuing to use the Site after such revisions
                become effective, you agree to be bound by the revised Privacy
                Policy. If you do not agree to the new Privacy Policy, please
                stop using the Site.
              </PrivacySection>
              <ButtonNew
                text="SIGN UP NOW"
                type="main"
                onClick={() => this.props.history.push("/signup")}
                fontStyle="font-size: 20px;"
                extra="width: 255px"
              />
            </PrivacyContainer>
          </Content>
        </ContentWhite>
      </PageWrapper>
    );
  }
}

export default withRouter(Privacy);
