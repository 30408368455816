import { get, isEmpty } from "lodash";
import React, { Component } from "react";
import {
  FiAward,
  FiCheck,
  FiDollarSign,
  FiFastForward,
  FiGrid,
  FiSidebar,
} from "react-icons/fi";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { buyPremadeStore } from "../../Actions";
import { getSettings } from "../../Selectors";
import { ButtonNew, StripeCard } from "../../components";
import Text from "../../modules/Text";
import { theme } from "../../modules/Theme";
import BuyConfirm from "./components/BuyConfirm";
import ShowPassword from "./components/ShowPassword";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      buyPremadeStore,
    },
    dispatch
  ),
});

const MobileScreenshot = styled.img`
  height: 400px;
  max-width: 234px;
  object-fit: contain;
  margin-left: 80px;

  @media (max-width: 800px) {
    height: 200px;
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ContentBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.lightGrey};
  border-radius: 4px;
  padding: 30px 30px 0 30px;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 800px) {
    padding: 10px 10px 0 10px;
    flex-direction: column;
  }
`;

const FeatureRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FeatureContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media (max-width: 800px) {
    width: 100%;
    padding: 10px;
  }
`;

const Feature = ({ icon, title, description }) => (
  <FeatureContainer>
    {icon}
    <Text bold center margin="5px 0 5px 0">
      {title}
    </Text>
    <Text.Small light center extra="word-break: break-word;">
      {description}
    </Text.Small>
  </FeatureContainer>
);

class Checkout extends Component {
  state = {
    showPassword: false,
    buyConfirm: false,
    showConnectPayment: false,
    storeName: "",
    storeURL: "",
    extraPrebuiltMonths: 0,
    existing: true,
    showPremium: false,
  };

  buttonPrice = () => {
    const storeData = this.props.stores.find(
      (s) => s.type === this.props.storeType
    );

    if (storeData.premium) {
      return storeData.price;
    }

    const { settings } = this.props;
    let buttonPrice = get(
      settings,
      ["global", "months_to_plan_price", [3]],
      249.0
    );
    if (this.state.extraPrebuiltMonths === 3) {
      buttonPrice = get(settings, ["global", "months_to_plan_price", [6]], 379);
    } else if (this.state.extraPrebuiltMonths === 6) {
      buttonPrice = get(
        settings,
        ["global", "months_to_plan_price", [12]],
        499
      );
    }
    return buttonPrice;
  };

  livePreview = (storeData) => {
    if (storeData.premium) {
      this.setState({ showPremium: true });
    }
    if (storeData.password) {
      this.setState({ showPassword: storeData.password });
    } else {
      window.open(storeData.demo);
    }
  };

  renderInitialBuyButton = (
    settings,
    storeData,
    stripeConnected,
    buyText,
    purchased
  ) => {
    if (this.props.external) {
      return (
        <ButtonNew
          text={buyText}
          size="large"
          onClick={() =>
            this.props.history.push(`/signup?prebuilt=${storeData.type}`)
          }
        />
      );
    }

    if (isEmpty(settings)) {
      return <ButtonNew isFetching size="large" />;
    }

    if (storeData.sold) {
      return <ButtonNew text="Sold" disabled size="large" />;
    }

    if (storeData.premium) {
      return (
        <a
          rel="noopener noreferrer"
          href="mailto:support@dropcommerce.com"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <ButtonNew text="Email Support To Purchase" size="large" />
        </a>
      );
    }

    if (!stripeConnected) {
      return (
        <StripeCard
          text="Add Payment Card"
          description="Authorize Your Credit Card"
          panelLabel="Authorize Card"
          redirectUrl={`/prebuilt?type=${storeData.type}`}
        />
      );
    }

    return (
      <ButtonNew
        text={buyText}
        disabled={purchased}
        size="large"
        onClick={() => this.setState({ buyConfirm: true })}
      />
    );
  };

  render() {
    const storeData = this.props.stores.find(
      (s) => s.type === this.props.storeType
    );

    const purchasedStores = get(this.props, [
      "settings",
      "store",
      "purchased_stores",
    ]);

    // const isPaid =
    //   get(this.props, ["settings", "store", "plan"]) === "professional";

    const purchased =
      purchasedStores && purchasedStores.includes(storeData.type);

    const stripeConnected = get(this.props, [
      "settings",
      "store",
      "stripe_connected",
    ]);

    const buyText = purchased
      ? "Purchased"
      : `Buy Now - $${this.buttonPrice()}`;

    const { settings } = this.props;

    let defaultPrice = get(
      settings,
      ["global", "months_to_plan_price", [3]],
      249.0
    );
    const storeOptions = this.props.stores.map((s) => ({
      value: s.type,
      label: `${s.name} - ${s.premium ? `$${s.price}` : `$${defaultPrice}`}`,
    }));

    if (this.state.showPassword) {
      return (
        <StoreContainer>
          <ShowPassword
            storeData={storeData}
            hide={() => this.setState({ showPassword: false })}
          />
        </StoreContainer>
      );
    }

    if (this.state.buyConfirm) {
      return (
        <StoreContainer>
          <BuyConfirm
            purchased={purchased}
            storeData={storeData}
            buyText={buyText}
            storeName={this.state.storeName}
            storeURL={this.state.storeURL}
            existing={this.state.existing}
            extraPrebuiltMonths={this.state.extraPrebuiltMonths}
            updateField={(field, value) => this.setState({ [field]: value })}
          />
        </StoreContainer>
      );
    }

    return (
      <StoreContainer>
        <Text.Large margin="0 0 30px 0">
          {`Purchase A ${
            !storeData.premium ? "Pre-Built" : "Premium"
          } Shopify Store`}
        </Text.Large>
        <ContentBoxWrapper>
          <div>
            <Text.Large>Choose Your Template:</Text.Large>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <Select
                value={{
                  value: storeData.type,
                  label: `${storeData.name} - ${
                    storeData.premium
                      ? `$${storeData.price}`
                      : `$${defaultPrice}`
                  }`,
                }}
                options={storeOptions}
                onChange={(data) => this.props.updateTheme(data.value)}
              />
            </div>

            <div style={{ width: "100%", marginTop: "20px" }}>
              {this.renderInitialBuyButton(
                this.props.settings,
                storeData,
                stripeConnected,
                buyText,
                purchased
              )}
              <ButtonNew
                type="secondary"
                size="large"
                text="View Demo"
                margin="15px 0 0 0"
                onClick={() => this.livePreview(storeData)}
              />
            </div>
          </div>
          <MobileScreenshot src={storeData.screenshot} />
        </ContentBoxWrapper>

        <FeatureRow>
          {storeData.premium && (
            <Feature
              title="Unique Store"
              description="Premium stores are one-off, unique stores (no duplicates)"
              icon={<FiSidebar size="35" color={theme.colors.main} />}
            />
          )}
          {storeData.premium && (
            <Feature
              title="Premium Domain"
              description={`Purchase includes the professional ${
                storeData.demo.split("//")[1]
              } domain`}
              icon={<FiCheck size="35" color={theme.colors.main} />}
            />
          )}
          <Feature
            title="3 Months Free"
            description="Get 3 months free DropCommerce Pro Plan ($267 Value)"
            icon={<FiDollarSign size="35" color={theme.colors.main} />}
          />
          <Feature
            title="100+ US Products"
            description="Each store is filled with 100+ products in the niche you choose"
            icon={<FiGrid size="35" color={theme.colors.main} />}
          />
          <Feature
            title="3-7 Day Shipping"
            description="All our suppliers offer fast shipping from within the US"
            icon={<FiFastForward size="35" color={theme.colors.main} />}
          />
          <Feature
            title="Premium Template"
            description="Our team professionally designs each template to optimize for sales"
            icon={<FiAward size="35" color={theme.colors.main} />}
          />
        </FeatureRow>

        <div style={{ padding: "10px" }}>
          {storeData.premium && (
            <React.Fragment>
              <Text green bold margin="30px 0 0 0">
                {storeData.name} is a one-off Premium prebuilt store, which
                means no one else will ever own this store but you!
              </Text>
              <Text margin="30px 0 0 0">
                This purchase includes the{" "}
                <strong>{`${storeData.demo.split("//")[1]}`}</strong> domain
                name and branding / logo.
              </Text>
            </React.Fragment>
          )}

          {purchased && (
            <Text green style={{ marginTop: "20px", maxWidth: "800px" }}>
              You have purchased this pre-built store. You will receive a
              notification by email as soon as it's ready, within 24 hours.
            </Text>
          )}

          <Text light margin="30px 0 0 0">
            Save time and money by purchasing a premium US dropshipping store
            and start selling in just minutes.
          </Text>

          <Text light margin="30px 0 0 0">
            * $38/month Shopify subscription required
          </Text>

          <Text.Small light margin="30px 0 0 0">
            Other features: Branding & Banner Files, Copyright-Free Images,
            Prebuilt Store Setup E-Book
          </Text.Small>

          {purchased && (
            <ButtonNew
              size="large"
              margin="10px 0 0 0"
              text="Download Free Store Setup E-Book"
              onClick={() => {
                window.open(
                  "https://dc-imports.s3.ca-central-1.amazonaws.com/DropCommerce-Prebuilt-Store-eBook.pdf"
                );
              }}
            />
          )}
        </div>
      </StoreContainer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
