import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchInboxManagement } from "../../../views/admin/AdminActions";
import {
  getInboxManagement,
  getIsFetching,
} from "../../../views/admin/AdminSelectors";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import { Spinner } from "../../../components";
import moment from "moment";

const mapStateToProps = (state) => ({
  suppliers: getInboxManagement(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchInboxManagement }, dispatch),
});

class InboxManagement extends Component {
  componentDidMount = () => {
    this.props.fetchInboxManagement();
  };

  render() {
    const suppliers = this.props.suppliers;

    if (this.props.isFetching) {
      return (
        <PageWrapper>
          <Spinner />
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <Text light extra="margin-bottom: 10px;">
          Inbox Management
        </Text>

        {suppliers &&
          suppliers.map((supplier, i) => (
            <Supplier key={i}>
              <SupplierName>
                {supplier.name} - {supplier.email}
              </SupplierName>
              {supplier.conversations.map((conversation, i) => (
                <Conversation key={i}>
                  <Text>
                    {conversation.sender__store__name} -{" "}
                    {conversation.sender__email}
                  </Text>
                  <Text light>{conversation.text}</Text>
                  <Text bold>
                    {moment(conversation.sent_timestamp).fromNow()}
                  </Text>
                </Conversation>
              ))}
            </Supplier>
          ))}
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InboxManagement)
);

const SupplierName = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const Supplier = styled.div`
  padding: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 20px;
`;

const Conversation = styled.div`
  padding: 20px;
  border: 1px solid ${theme.colors.lightBorder};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
