import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import BackNavBar from "../../components/BackNavBar";

import { trackEvent } from "../../modules/EventTracking";
import { getSettings } from "../../Selectors";
import { Modal } from "../../components/Modal";
import { ButtonNew, Container, Row, Spinner, Titlebar } from "../../components";
import Text from "../../modules/Text";
import moment from "moment";

import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { getUrlParameter } from "../../modules/Format";
import {
  HintDescription,
  HintTitle,
  OrderDetailsWrapper,
} from "../../views/orders/OrdersStyle.jsx";
import OrderSupplier from "../../views/orders/OrderSupplier";
import { isEmpty } from "lodash";
import { axiosInstance } from "../../services/api";
import {
  errorNotification,
  successNotification,
} from "../../modules/Notification";

export const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 150px;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

const ErrorModalHtml = styled.div`
  color: ${theme.colors.medDarkGrey};
  font-size: 20px;
  max-width: 800px;
`;

const ErrorModalHeader = styled.div`
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

class OrderDetails extends Component {
  state = {
    confirmFulfillment: false,
    supplierData: {},
    showReview: false,
    order: { note: "", suppliers: [], shipping_address: {} },
    isPaying: false,
    isPaid: false,
    showCancelForm: false,
    isFetching: false,
    isCancelling: false,
    error: false,
  };

  cancelOrder = () => {
    const orderId = this.props.match.params.orderId;

    // Cancel order
    this.setState((prev) => ({ ...prev, isCancelling: true }));
    axiosInstance
      .post("cancel_order/", { order_id: orderId })
      .then((response) => {
        this.setState((prev) => ({
          ...prev,
          order: { ...prev.order, status: "cancelled" },
        }));
        successNotification("Order has been canceled");
      })
      .catch((error) => {
        errorNotification("Cancel order action has failed");
      })
      .finally(() => {
        this.setState((prev) => ({ ...prev, isCancelling: false }));
      });
  };

  paySupplier = () => {
    const orderId = this.props.match.params.orderId;
    const supplierId = this.state.supplierData.id;
    const note = this.state.order.note;

    // Pay supplier
    this.setState((prev) => ({ ...prev, isPaying: true }));
    axiosInstance
      .post("pay_supplier/", {
        order_id: orderId,
        supplier_id: supplierId,
        note,
      })
      .then((response) => {
        if (response.error_html) {
          this.setState((prev) => ({
            ...prev,
            errorHtml: response.error_html,
          }));
        } else {
          this.setState((prev) => ({
            ...prev,
            isPaid: true,
          }));
        }
      })
      .catch((error) => {
        this.setState((prev) => ({
          ...prev,
          errorHtml: error,
        }));
      })
      .finally(() => {
        this.setState((prev) => ({
          ...prev,
          isPaying: false,
        }));
      });
  };

  componentDidMount() {
    const orderId = this.props.match.params.orderId;
    const storeId = this.props.settings.store.id;

    const params = { id: orderId, store: storeId };

    trackEvent("View Order");
    // Get order
    this.setState((prev) => ({ ...prev, isFetching: true }));
    axiosInstance
      .get(`order/`, { params })
      .then((response) => {
        this.setState((prev) => ({
          ...prev,
          order: response.data.order,
        }));
      })
      .catch((error) => {
        this.setState((prev) => ({
          ...prev,
          error: true,
        }));
      })
      .finally(() => {
        this.setState((prev) => ({
          ...prev,
          isFetching: false,
        }));
      });
  }

  confirmFulfillment = () => {
    this.setState((prev) => ({
      ...prev,
      confirmFulfillment: false,
    }));
    this.paySupplier();
  };

  render() {
    const paginationRecord = getUrlParameter(
      "paginationRecord",
      this.props.location
    );
    const paginationType = getUrlParameter(
      "paginationType",
      this.props.location
    );

    const order = this.state.order;
    const address = order.shipping_address;
    const unpaidSupplierCount = order.suppliers.filter(
      (s) => s.status !== "paid"
    ).length;

    const isFetching = this.state.isFetching || isEmpty(order);

    const { total, name } = this.state.supplierData;

    return (
      <>
        {isFetching ? (
          <Spinner />
        ) : this.state.error ? (
          <div style={{ padding: "30px" }}>Error Fetching Order</div>
        ) : (
          <React.Fragment>
            <Titlebar
              title={`Order ${order.name} (${order.new_name})`}
              subtitle={moment(order.created_at).format("MMMM Do YYYY, h:mma")}
            />
            <Container>
              <Link
                to={`/orders?page=${paginationRecord}&type=${paginationType}`}
              >
                <BackNavBar title="ALL ORDERS" />
              </Link>

              {this.state.confirmFulfillment ? (
                <OrderDetailsWrapper>
                  <HintTitle>Confirm Order Fulfillment</HintTitle>
                  <HintDescription>
                    Once paid, {name} will begin work on shipping your order to
                    your customer. You will receive an email notifying you when
                    the order has been shipped.
                  </HintDescription>
                  <HintTitle style={{ fontSize: "20px" }}>
                    Your saved credit card will be charged $
                    {total.original_amount.toFixed(2)} {total.original_currency}
                  </HintTitle>

                  <HintTitle style={{ fontSize: "20px", marginTop: "25px" }}>
                    Order Notes (Optional)
                  </HintTitle>
                  <HintDescription>
                    Warning: These notes will show up on the customer's packing
                    slip.
                  </HintDescription>
                  <StyledTextArea
                    style={{
                      maxWidth: "100%",
                      width: "600px",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                    onChange={(evt) => {
                      this.setState((prev) => ({
                        ...prev,
                        order: { ...prev.order, note: evt.target.value },
                      }));
                    }}
                    value={this.state.order.note || ""}
                  />

                  <div style={{ display: "flex" }}>
                    <ButtonNew
                      text="Pay Now"
                      type="main"
                      isFetching={this.state.isPaying}
                      onClick={() => this.confirmFulfillment()}
                      extra="width: 300px;"
                      fontStyle="font-size: 20px;"
                    />
                    <ButtonNew
                      text="Cancel"
                      type="secondary"
                      onClick={() =>
                        this.setState((prev) => ({
                          ...prev,
                          supplierData: {},
                          confirmFulfillment: false,
                          order: { ...prev.order, note: "" },
                        }))
                      }
                      extra="width: 300px; margin-left: 20px;"
                      fontStyle="font-size: 20px;"
                    />
                  </div>
                </OrderDetailsWrapper>
              ) : (
                <div>
                  <Text>Type: {order.type}</Text>

                  {this.state.errorHtml && (
                    <Modal
                      hide={() => {
                        this.setState((prev) => ({
                          ...prev,
                          errorHtml: null,
                        }));
                      }}
                    >
                      <ErrorModalHeader>
                        Error Processing Order
                      </ErrorModalHeader>
                      <ErrorModalHtml
                        dangerouslySetInnerHTML={{
                          __html: this.state.errorHtml,
                        }}
                      />
                    </Modal>
                  )}

                  {order.status === "error" && (
                    <React.Fragment>
                      {order.shipping_address ? (
                        <Text
                          style={{ marginBottom: "15px", marginTop: "25px" }}
                        >
                          There is an error with this order. Please contact
                          support@dropcommerce.com.
                        </Text>
                      ) : (
                        <Text
                          red
                          style={{ marginBottom: "15px", marginTop: "25px" }}
                        >
                          This order is missing a{" "}
                          <strong>Shipping Address.</strong> Please contact
                          support@dropcommerce.com
                        </Text>
                      )}

                      <Text
                        light
                        style={{ marginBottom: "15px", marginTop: "25px" }}
                      >
                        If this order was not processed through a normal
                        checkout, you may be missing important order details
                        such as shipping address, etc.
                      </Text>
                    </React.Fragment>
                  )}

                  <Row style={{ justifyContent: "space-between" }}>
                    <Text
                      light
                      style={{ marginBottom: "15px", marginTop: "25px" }}
                    >
                      <strong>Customer Note:</strong> {order.note || "None"}
                    </Text>
                  </Row>

                  {order.suppliers.map((supplier, i) => (
                    <OrderSupplier
                      supplier={supplier}
                      order={order}
                      isPaying={this.state.isPaying}
                      isPaid={this.state.isPaid}
                      settings={this.props.settings}
                      key={i}
                      showConfirmation={() => {
                        this.setState((prev) => ({
                          ...prev,
                          confirmFulfillment: true,
                          supplierData: supplier,
                        }));
                      }}
                    />
                  ))}

                  {order.status !== "error" && (
                    <div>
                      <Text style={{ marginBottom: "15px" }}>Details</Text>
                      <OrderDetailsWrapper>
                        <Text.Small bold style={{ marginBottom: "15px" }}>
                          Shipping Address
                        </Text.Small>
                        <div>{address.name}</div>
                        <div>
                          {address.address1}
                          {address.address2 && ` - ${address.address2}`}
                        </div>
                        <div>
                          {`${address.city}${
                            address.province ? `, ${address.province}` : ""
                          },  ${address.country}`}
                        </div>
                        <div>{address.zip}</div>
                      </OrderDetailsWrapper>
                    </div>
                  )}

                  {unpaidSupplierCount === order.suppliers.length &&
                    order.status === "pending" && (
                      <ButtonNew
                        text="Cancel Order"
                        type="secondary"
                        width="180px"
                        onClick={this.cancelOrder}
                        isFetching={this.state.isCancelling}
                        disabled={order.status === "cancelled"}
                      />
                    )}

                  {/* TODO: implement the redux action before enabling */}
                  {/* {unpaidSupplierCount < order.suppliers.length &&
                    order.status !== "shipped" && (
                      <ModalCancel
                        order={order}
                        hideModal={() =>
                          this.setState({ showCancelForm: false })
                        }
                      />
                    )} */}
                </div>
              )}
            </Container>
          </React.Fragment>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
);
