import Cookies from "js-cookie";
import React from "react";
import { AiOutlineAntDesign } from "react-icons/ai";
import {
  FiArchive,
  FiCreditCard, FiCrosshair,
  FiDollarSign,
  FiGrid,
  FiLayout,
  FiList,
  FiLogOut,
  FiMessageCircle,
  FiShoppingCart,
  FiTrendingUp,
  FiTruck,
  FiUsers,
} from "react-icons/fi";
import { GoTasklist } from "react-icons/go";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../../hooks/useWindowSize";
import appIcon from "../../../images/dc-box-2021-white-grey.svg";
import { theme } from "../../../modules/Theme";

const LogoWrapper = styled.div`
  display: grid;
  place-items: center;
`;

const Logo = styled.img`
  height: 40px;
  margin: 15px 0;
`;

export function AdminSidebar() {
  const { width } = useWindowSize();

  const { params } = useRouteMatch();
  const history = useHistory();

  const logout = () => {
    Cookies.remove("adminAccessToken");
    history.push("/");
  };

  return (
    <Sidebar
      collapsed={width <= 1051}
      backgroundColor={theme.colors.darkGreen}
      style={{ height: "100vh" }}
      width="220px"
    >
      <LogoWrapper>
        <Logo src={appIcon} alt="DropCommerce logo" />
      </LogoWrapper>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              color: "#ffffff",
              fontWeight: active ? "400" : undefined,
              "&:hover": {
                color: theme.colors.darkGreen,
              },
            };
          },
        }}
      >
        {getLinks(params.tab).map((link) => (
          <MenuItem
            key={link.name}
            icon={link.icon}
            active={link.active}
            component={<Link to={link.link} />}
          >
            {link.name}
          </MenuItem>
        ))}
        <MenuItem
          icon={<FiLogOut size="20" />}
          onClick={logout}
          style={{ margin: "0 0 15px 0" }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

const getLinks = (tab) => [
  {
    name: "Stores",
    link: "/admin/stores",
    active: tab === "stores",
    icon: <FiShoppingCart size="20" />,
  },
  {
    name: "Categorize",
    link: "/admin/uncategorize",
    active: tab === "uncategorize",
    icon: <FiGrid size="20" />,
  },
  {
    name: "Commands",
    link: "/admin/commands",
    active: tab === "commands",
    icon: <FiList size="20" />,
  },
  {
    name: "Impersonate",
    link: "/admin/impersonate",
    active: tab === "impersonate",
    icon: <FiUsers size="20" />,
  },
  {
    name: "Refunds",
    link: "/admin/refunds",
    active: tab === "refunds",
    icon: <FiArchive size="20" />,
  },
  {
    name: "Supplier Stats",
    link: "/admin/supplier-stats",
    active: tab === "supplier-stats",
    icon: <FiTrendingUp size="20" />,
  },
  {
    name: "Inbox",
    link: "/admin/inbox",
    active: tab === "inbox",
    icon: <FiMessageCircle size="20" />,
  },
  {
    name: "Prebuilt Stores",
    link: "/admin/prebuilt",
    active: tab === "prebuilt",
    icon: <FiLayout size="20" />,
  },
  {
    name: "Price Audit",
    link: "/admin/audit",
    active: tab === "audit",
    icon: <FiDollarSign size="20" />,
  },
  {
    name: "Store Designs",
    link: "/admin/premade-store-designs",
    active: tab === "premade-store-designs",
    icon: <AiOutlineAntDesign size="20" />,
  },
  {
    name: "Suppliers",
    link: "/admin/suppliers",
    active: tab === "suppliers",
    icon: <FiTruck size="20" />,
  },
  {
    name: "Late orders",
    link: "/admin/orders",
    active: tab === "orders",
    icon: <FiCreditCard size="20" />,
  },
  {
    name: "Order Status",
    link: "/admin/order-status",
    active: tab === "orders-status",
    icon: <FiCrosshair size="20" color="white" />,
  },
  {
    name: "Task Log",
    link: "/admin/task-log",
    active: tab === "task-log",
    icon: <GoTasklist size="20" />,
  },
];
