import Cookies from "js-cookie";
import { api } from "../../modules/Config";
import { checkStatus } from "../../utils/index";

const type = "affiliate";

export const GENERIC_REQ = `${type}/GENERIC_REQ`;
export const GENERIC_RES = `${type}/GENERIC_RES`;
export const GENERIC_ERR = `${type}/GENERIC_ERR`;
export const FETCH_AFFILIATE_REPORT_RES = `${type}/FETCH_AFFILIATE_REPORT_RES`;

export const fetchAffiliateReport = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/affiliate_report/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_AFFILIATE_REPORT_RES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
      });
    });
};
