import axios from "axios";
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import Select from "@material-ui/core/Select";
import { Column, Row } from "../../../components";
import ButtonNew from "../../../components/ButtonNew";
import styled from "styled-components";
import { Modal } from "../../../components/Modal";
import MDSpinner from "react-md-spinner";
import { history } from "../../../BaseApp";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { truncateText } from "../../../modules/Format";
import ReactSelect from "react-select";
import { allCategories } from "../../../components/CategoriesNew";

// TODO: move these 2 to a common place and import it from there
const StyledTableCell = withStyles(() => ({
  root: { textAlign: "center" },
  head: {
    color: "#2c857d",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledInputLabel = withStyles({
  root: {
    marginTop: "10px",
    textTransform: "capitalize",
  },
})(InputLabel);

const StyledTableRow = withStyles(() => ({
  root: {
    cursor: "pointer",
  },
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

const StyledSelect = withStyles({ root: { width: "220px" } })(Select);

/*
 * Renders the Premade Designs in the Admin Dashboard
 * API Endpoint: api/admin/premade-store-design/
 * URL: admin/premade-store-designs
 * */

const Thumbnail = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

const ErrorMessage = styled.p`
  margin: 0.1rem;
  color: red;
  padding: 0;
`;

const StyledInput = styled.input`
  height: 40px;
  width: 250px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  :active {
    border: rgba(0, 0, 0, 0.3);
  }
`;

const CATEGORIES = Object.entries(allCategories.subs).map(([key, value]) => ({
  label: value.name,
  value: key,
}));

// not a reactive value
const endpoint = "api/admin/premade-store-design/";

export default function PremadeStoreDesign() {
  const thumbnailCols = ["screenshot_url", "image_url"];

  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [selectedID, setSelectedID] = useState(0);
  const [ordering, setOrdering] = useState("-id");
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [fieldVals, setFieldVals] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let get_params = [
      ordering && `&ordering=${ordering}`,
      page && `&page=${page}`,
      searchTerm && `&search=${searchTerm}`,
    ].join("");
    fetch(`${api}/${endpoint}?${get_params}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((rawResponse) => rawResponse.json())
      .then((res) => {
        setData(res);
        history.push(`?${get_params}`);
      })
      .catch(() => {
        setErrorMessage("There was an error fetching stores");
      });
  }, [ordering, page, searchTerm]);

  const sortBy = (column) => {
    setOrdering((currentOrdering) => {
      return currentOrdering === column ? `-${column}` : column;
    });
  };

  const onSubmit = () => {
    let formData = new FormData();
    Object.entries(fieldVals).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    axios({
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      },
      method: "id" in fieldVals ? "PUT" : "POST",
      data: formData,
      url: `${api}/${endpoint}${"id" in fieldVals ? fieldVals.id + "/" : ""}`,
    })
      .then((res) => {
        setData((d) => {
          d.results[d.results.findIndex((obj) => obj.id === res.data.id)] =
            res.data;
          return d;
        });
        setFieldVals({});
        setError({});
        setShowModal(false);
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <React.Fragment>
      {Object.keys(data).length === 0 ? (
        <MDSpinner />
      ) : (
        <React.Fragment>
          <Row align style={{ justifyContent: "space-between" }}>
            <StyledInput
              type="text"
              placeholder="Search By ID, Name, or Description"
              onKeyUp={(e) =>
                e.key === "Enter" && setSearchTerm(e.target.value)
              }
            />

            <ButtonNew onClick={() => setShowModal(true)} text="New Design" />
          </Row>
          <TableContainer
            component={Paper}
            style={{ margin: "15px 0", overflow: "auto" }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {Object.keys(data.results[0]).map((column, i) => (
                    <StyledTableCell key={i} onClick={() => sortBy(column)}>
                      {column.replaceAll("_", " ") + " "}
                      {ordering === column && <FiArrowUp size="18" />}
                      {ordering === "-" + column && <FiArrowDown size="18" />}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.results.map((datum) => (
                  <StyledTableRow
                    key={datum.id}
                    style={{
                      backgroundColor:
                        datum.id === selectedID ? "rgba(0,0,0,0.05)" : null,
                    }}
                    onMouseOver={() => setSelectedID(datum.id)}
                    onClick={() => {
                      setFieldVals(datum);
                      setShowModal(true);
                    }}
                  >
                    {Object.keys(data.results[0]).map((column, i) => {
                      return (
                        <StyledTableCell key={i}>
                          {thumbnailCols.includes(column) ? (
                            <Thumbnail src={datum[column]} alt={datum.name} />
                          ) : column === "description" ? (
                            typeof datum[column] === "string" ? (
                              truncateText(datum[column], 10)
                            ) : (
                              datum[column]
                            )
                          ) : (
                            datum[column]
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Row gap="10px">
            <ButtonNew
              disabled={!data.previous}
              type="main"
              text="Prev"
              onClick={() => setPage((currentPage) => currentPage - 1)}
            />

            <ButtonNew
              disabled={!data.next}
              type="main"
              text="Next"
              onClick={() => setPage((currentPage) => currentPage + 1)}
            />
          </Row>
        </React.Fragment>
      )}

      {showModal && (
        <Modal
          hide={() => {
            setShowModal(false);
            setFieldVals({});
          }}
          maxWidth="650px"
        >
          <Column
            align
            style={{
              minWidth: "150px",
              minHeight: "150px",
            }}
          >
            <h2>
              {"id" in fieldVals
                ? "Edit Design #" + fieldVals.id
                : "Add New Design"}
            </h2>
            {data.form_fields.names.map((fieldName, i) => (
              <Column key={i} style={{ minWidth: "200px" }}>
                <StyledInputLabel variant="standard" htmlFor={`${fieldName}Id`}>
                  {fieldName.replaceAll("_", " ") + " "}
                </StyledInputLabel>
                {(() => {
                  if (data.form_fields.types.array.includes(fieldName)) {
                    return (
                      <ReactSelect
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            backgroundColor: "white",
                            width: "250px",
                          }),
                        }}
                        closeMenuOnSelect={false}
                        isMulti
                        name="categories"
                        options={CATEGORIES}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        id={`${fieldName}Id`}
                        value={(fieldVals[fieldName] || []).map((i) => ({
                          label: i,
                          value: i,
                        }))}
                        onChange={(array) => {
                          setFieldVals((currentFormData) => {
                            return {
                              ...currentFormData,
                              ...{
                                [fieldName]:
                                  array === null
                                    ? null
                                    : array.map((i) => i.value),
                              },
                            };
                          });
                        }}
                      />
                    );
                  } else if (
                    data.form_fields.types.integer.includes(fieldName)
                  ) {
                    return (
                      <StyledInput
                        id={`${fieldName}Id`}
                        type="number"
                        value={fieldVals[fieldName]}
                        onChange={(e) =>
                          setFieldVals((currentFormData) => ({
                            ...currentFormData,
                            ...{ [fieldName]: e.target.value },
                          }))
                        }
                      />
                    );
                  } else if (
                    data.form_fields.types.decimal.includes(fieldName)
                  ) {
                    return (
                      <StyledInput
                        type="number"
                        min="0"
                        step=".01"
                        value={fieldVals[fieldName]}
                        onChange={(e) =>
                          setFieldVals((currentFormData) => ({
                            ...currentFormData,
                            ...{ [fieldName]: e.target.value },
                          }))
                        }
                      />
                    );
                  } else if (data.form_fields.types.file.includes(fieldName)) {
                    return (
                      <StyledInput
                        id={`${fieldName}Id`}
                        type="file"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={(e) =>
                          setFieldVals((currentFormData) => ({
                            ...currentFormData,
                            ...{ [fieldName]: e.target.files[0] },
                          }))
                        }
                      />
                    );
                  } else if (
                    Object.keys(data.form_fields.choices).includes(fieldName)
                  ) {
                    return (
                      <StyledSelect
                        value={fieldVals[fieldName]}
                        onChange={(e) =>
                          setFieldVals((currentFormData) => ({
                            ...currentFormData,
                            ...{ [fieldName]: e.target.value },
                          }))
                        }
                      >
                        {data.form_fields.choices[fieldName].map((item, i) => (
                          <MenuItem key={i} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    );
                  }
                  return (
                    <StyledInput
                      type="text"
                      id={`${fieldName}Id`}
                      value={fieldVals[fieldName]}
                      onChange={(e) =>
                        setFieldVals((currentFormData) => ({
                          ...currentFormData,
                          ...{ [fieldName]: e.target.value },
                        }))
                      }
                    />
                  );
                })()}
                {fieldName in error && (
                  <ErrorMessage>{error[fieldName]}</ErrorMessage>
                )}
              </Column>
            ))}
            <Column style={{ minWidth: "150px" }}>
              <ButtonNew
                onClick={onSubmit}
                text="Submit"
                extra="margin-top: 20px;"
              />
            </Column>
          </Column>
        </Modal>
      )}
    </React.Fragment>
  );
}
