import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchOrders } from "../../Actions";
import {
  getIsFetching,
  getIsFetchingSettings,
  getLocalState,
  getOrders,
  getPagination,
  getSettings,
} from "../../Selectors";
import { ButtonNew, Container, Row, Search, Table } from "../../components";
import Text from "../../modules/Text";
import {
  arrayToCommaString,
  getDefaultStoreRoute,
  getUrlParameter,
} from "../../modules/Format";
import { postRequestCSV } from "../../modules/API";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
  orders: getOrders(state),
  settings: getSettings(state),
  pagination: getPagination(state),
  baseState: getLocalState(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchOrders }, dispatch),
});

const defaultProps = {
  isFetching: false,
  orders: [],
};

class Orders extends Component {
  state = { searchTerm: "" };

  componentDidMount = () => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const status = getUrlParameter("status", this.props.location) || "paid";
    this.props.fetchOrders(page, searchTerm, status);
    this.setState({ searchTerm });
  };

  exportOrders = () => {
    const storeId = this.props.settings.store.id;
    postRequestCSV({ url: `orders/`, data: { store: storeId } });
  };

  clearSearch = () => {
    const status = getUrlParameter("status", this.props.location) || "paid";
    this.props.fetchOrders(1, "", status);
    this.props.history.push(`/orders?status=${status}&page=1&search=`);
    this.setState({ searchTerm: "" });
  };

  searchForOrders = (value) => {
    if (value === "") {
      this.clearSearch();
    } else {
      let searchTerm = this.state.searchTerm;
      const status = getUrlParameter("status", this.props.location) || "paid";
      this.props.fetchOrders(1, searchTerm, status);
      this.props.history.push(
        `/orders?status=${status}&page=1&search=${searchTerm}`
      );
      this.setState({ searchTerm });
    }
  };

  onChange = (term) => {
    this.setState({ searchTerm: term });
  };

  onEnterPress = (e) => {
    if (e.key === "Enter") {
      this.searchForOrders();
    }
  };

  render() {
    const storeId = this.props.settings.store.id;
    const paginationRecord = getUrlParameter("page", this.props.location);
    const paginationType = getUrlParameter("type", this.props.location);
    let initialSearchTerm =
      getUrlParameter("search", this.props.location) || "";

    const tabs = [
      { name: "All", key: "all" },
      { name: "Unpaid", key: "pending" },
      { name: "Paid", key: "fulfilled" },
      { name: "Shipped", key: "shipped" },
      { name: "Cancelled", key: "cancelled" },
    ];

    const noResultsData = {
      title: "You do not have any orders.",
      message:
        "When you make sales in your store, orders will show up here. " +
        "Once you make a sale, come here to review and submit the order " +
        "to the supplier for fulfillment.",
      buttonText: "FIND PRODUCTS TO SELL",
      onClick: () => this.props.history.push(getDefaultStoreRoute),
    };

    const columns = [
      { field: "name", name: "Name", width: "240px" },
      { field: "created_at", name: "Date", width: "130px", type: "date" },
      {
        field: "status",
        name: "Status",
        width: "110px",
        statusMap: {
          pending: "yellow",
          partial_paid: "orange",
          fulfilled: "lightGreen",
          partial: "orange",
          shipped: "green",
          cancelled: "grey",
        },
        labelMap: {
          pending: "Unpaid",
          partial_paid: "Partially Paid",
          partial: "Partially Shipped",
          fulfilled: "Paid",
          shipped: "Shipped",
          cancelled: "Cancelled",
        },
      },
      { field: "customer", name: "Customer", width: "130px" },
      {
        field: "tracking_data",
        width: "400px",
        name: "Tracking",
        customValue: (v) =>
          arrayToCommaString(
            v
              .filter((v) => v.tracking_number !== null)
              .map((v) => v.tracking_number)
          ),
      },
    ];

    return (
      <Container>
        <Row
          align
          style={{ justifyContent: "space-between", marginBottom: "30px" }}
        >
          <div>
            <Text.Large>Orders</Text.Large>
          </div>

          <div className="grid-container">
            <ButtonNew
              className="grid-child export-orders"
              text="Export Orders"
              onClick={this.exportOrders}
            />

            <ButtonNew
              className="grid-child new-order"
              text="New Order"
              onClick={() => this.props.history.push("/order/new")}
            />
          </div>
        </Row>

        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: "40px",
            flexWrap: "wrap",
          }}
        >
          <Search
            placeholder="Search orders"
            onChange={this.onChange}
            onEnterPress={this.onEnterPress}
            search={this.state.searchTerm}
            searchForProducts={this.searchForOrders}
            initialSearchTerm={initialSearchTerm}
            clear={() => this.searchForOrders("")}
          />
        </Row>

        <Table
          hideMobile={["Status", "Customer"]}
          columns={columns}
          action={{
            endpoint: `/orders/`,
            mixpanelName: "View Orders",
            fields: ["orders", "pagination"],
            queryParams: [
              { name: "store", default: storeId, reset: false },
              { name: "page", default: 1, reset: true },
              {
                name: "status",
                default: "pending",
                reset: true,
                useForTabs: true,
              },
            ],
          }}
          baseState={this.props.baseState}
          rowsLocation={["orders"]}
          tabsRoute="/orders"
          tabs={tabs}
          noResultsData={noResultsData}
          rowBodyOnClick={(orderId) =>
            this.props.history.push(
              `/order/${orderId}?paginationRecord=${paginationRecord}&paginationType=${paginationType}`
            )
          }
        />
      </Container>
    );
  }
}

Orders.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
