import React, { Component } from "react";
import Price from "../../components/Price";
import styled from "styled-components";
import { AttributeLabel } from "../../components/ProductAttribute";
import { theme } from "../../modules/Theme";

export const OrderAttributeText = styled.div`
  font-weight: ${(props) => props.weight || "300"};
  color: ${(props) => props.color || theme.colors.medDarkGrey};
`;

export const OrderAttributeHintText = styled.div`
  font-weight: 300;
  color: ${theme.colors.mainLight};
  margin-left: 10px;
  cursor: pointer;
`;

export const OrderAttributeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

class OrderAttribute extends Component {
  render() {
    return (
      <OrderAttributeWrapper>
        <AttributeLabel>{this.props.label}</AttributeLabel>
        {this.props.isPrice && this.props.value !== "Not offered" ? (
          <Price
            data={this.props.value}
            convert={this.props.convert}
            badgeClick={this.props.badgeClick}
            orange={this.props.orangeText}
            label={this.props.label}
          />
        ) : (
          <OrderAttributeText
            weight={this.props.weight}
            color={this.props.color}
          >
            {this.props.isPrice && "$"}
            {this.props.value}
          </OrderAttributeText>
        )}
        {this.props.hint && (
          <OrderAttributeHintText onClick={this.props.hint}>
            What's this?
          </OrderAttributeHintText>
        )}
      </OrderAttributeWrapper>
    );
  }
}

export default OrderAttribute;
