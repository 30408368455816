import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { FiArrowDownCircle, FiArrowRightCircle, FiClock } from "react-icons/fi";
import { get } from "lodash";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";
import FetchingWrapper from "../../../../components/FetchingWrapper";
import { updateSettings } from "../../../../Actions";
import { getUrlParameter } from "../../../../modules/Format";
import {
  fetchImportList,
  toggleSelectImportedList,
  importToStore,
} from "../../../../views/products/ProductsActions";
import {
  getImportList,
  getIsFetchingImportList,
  getPagination,
} from "../../../../views/products/ProductsSelectors";
import { getSettings, getIsFetchingSettings } from "../../../../Selectors";
import ProductWrapper from "./components/ProductWrapper";
import {
  Search,
  Hint,
  Row,
  Column,
  Container,
  Pagination,
  NoResults,
  ButtonNew,
} from "../../../../components";
import Text from "../../../../modules/Text";
import { getDefaultStoreRoute } from "../../../../modules/Format";
import { getRequest } from "../../../../modules/API";
import { Modal } from "../../../../components/Modal";

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingImportList(state),
  importList: getImportList(state),
  pagination: getPagination(state),
  settings: getSettings(state),
  isFetchingSettings: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchImportList,
      updateSettings,
      toggleSelectImportedList,
      importToStore,
    },
    dispatch
  ),
});

const defaultProps = {
  isFetching: false,
  products: undefined,
};

export const ListWrapper = styled.div`
  margin-top: 20px;
`;

class List extends Component {
  state = {
    showModal: false,
    searchTerm: "",
    showCSVPopup: false,
    csvFormat: null,
  };

  exportCSV = () => {
    const privatePortal = get(this.props, [
      "settings",
      "store",
      "private_portal",
    ]);

    this.setState({ isFetchingCSV: true });

    getRequest({
      url: `export-csv/${this.state.csvFormat}/${privatePortal.id}/`,
    }).then((response) => {
      this.setState({ csvData: response.data, isFetchingCSV: false });
    });
  };

  UNSAFE_componentWillMount = () => {
    let searchTerm = getUrlParameter("search", this.props.location) || "";
    let page = getUrlParameter("page", this.props.location) || 1;
    this.props.fetchImportList(page, searchTerm);
    this.setState({ searchTerm });
  };

  fetchProducts = (page) => {
    this.props.fetchImportList(page, "");
    this.props.history.push(`/imported/list?page=${page}`);
  };

  renderCSVDownload = (data) => {
    if (data) {
      if (data.type === "woocommerce") {
        return (
          <div>
            <Text light margin="10px 0 0 0">
              WooCommerce requires products and variants to be imported via
              separate CSVs, so you'll need to download both Products and
              Variants files below:
            </Text>
            <Column justify align>
              <CSVLink filename="import-list-products.csv" data={data.products}>
                <ButtonNew
                  size="large"
                  text="Download Products CSV"
                  margin="15px 0 0 0"
                  icon={<FiArrowDownCircle size="20px" color="white" />}
                />
              </CSVLink>
              <CSVLink filename="import-list-variants.csv" data={data.variants}>
                <ButtonNew
                  size="large"
                  text="Download Variants CSV"
                  margin="15px 0 0 0"
                  icon={<FiArrowDownCircle size="20px" color="white" />}
                />
              </CSVLink>
            </Column>
          </div>
        );
      } else if (data.type === "dropcommerce") {
        return (
          <CSVLink filename="import-list-products.csv" data={data.products}>
            <ButtonNew
              size="large"
              text="Download Products CSV"
              margin="15px 0 0 0"
              icon={<FiArrowDownCircle size="20px" color="white" />}
            />
          </CSVLink>
        );
      }
    }
  };

  renderNoResults = () => {
    const productCount = get(this.props, ["importList", "length"]);

    const message =
      "Click the 'Add To Import List' button on a product to add it here. Use this page to edit product prices, description, images and variants before importing to your store. Click the button below to find products.";

    if (productCount === 0) {
      if (this.props.pagination && this.props.pagination.pages > 1) {
        return (
          <NoResults
            title="There are no more products on this page."
            message="Click the button below to view other products in your import list."
            buttonLink={() => this.fetchProducts(1, "")}
            buttonText="VIEW NEXT PAGE"
          />
        );
      } else {
        return (
          <NoResults
            title="There are no products in your Import List"
            message={message}
            buttonLink={() => this.props.history.push(getDefaultStoreRoute)}
            buttonText="FIND PRODUCTS TO SELL"
            buttonTwoLink={() => this.props.history.push("/imported/store")}
            buttonTwoText="VIEW IMPORTED PRODUCTS"
          />
        );
      }
    }
  };

  render() {
    let initialSearchTerm = getUrlParameter("s", this.props.location) || "";
    const privatePortal = get(this.props, [
      "settings",
      "store",
      "private_portal",
    ]);
    const showBulkImports = get(this.props, [
      "settings",
      "store",
      "show_bulk_imports",
    ]);

    // Patch for NFT demo
    const location = window.location.toString();
    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    let selectedIds = get(this.props, ["importList"], [])
      .filter((imp) => imp.selected)
      .map((imp) => imp.imported_product_id);
    let selectedCount = selectedIds.length;
    const productCount = get(this.props, ["importList", "length"]);

    return (
      <Container>
        <FetchingWrapper
          fetching={this.props.isFetching || this.props.isFetchingSettings}
        >
          {this.state.showCSVPopup && (
            <Modal
              maxWidth="500px"
              minHeight="500px"
              hide={() => this.setState({ showCSVPopup: false })}
            >
              <Text.Large margin="0 0 15px 0">
                Select your CSV Format:
              </Text.Large>
              <Select
                style={{ zIndex: "9999" }}
                options={[
                  {
                    value: "dropcommerce",
                    label: "DropCommerce",
                  },
                  {
                    value: "woocommerce",
                    label: "WooCommerce",
                  },
                ]}
                onChange={(data) => this.setState({ csvFormat: data.value })}
              />
              {this.state.csvData ? (
                this.renderCSVDownload(this.state.csvData)
              ) : (
                <ButtonNew
                  size="large"
                  text="Generate CSV"
                  isFetching={this.state.isFetchingCSV}
                  onClick={this.exportCSV}
                  disabled={!this.state.csvFormat}
                  margin="15px 0 0 0"
                />
              )}
            </Modal>
          )}

          <Search
            onChange={(term) => {
              this.setState({ searchTerm: term });
            }}
            search={this.state.searchTerm}
            initialSearchTerm={initialSearchTerm}
            searchForProducts={(searchTerm) =>
              this.fetchProducts(1, searchTerm)
            }
            clear={() => this.fetchProducts(1, "")}
            onEnterPress={(e) => {
              if (e.key === "Enter") {
                this.props.fetchImportList(1, this.state.searchTerm);
                this.props.history.push(
                  `/imported/list?page=1&search=${this.state.searchTerm}`
                );
              }
            }}
          />

          <Row style={{ justifyContent: "space-between", marginTop: "20px" }}>
            <Row align>
              <div style={{ display: "flex", alignItems: "center" }}>
                {showBulkImports && (
                  <Checkbox
                    onChange={(e) =>
                      this.props.toggleSelectImportedList(e.target.checked)
                    }
                  />
                )}
              </div>
              <Text
                link
                onClick={() =>
                  this.props.history.push("/settings/import_settings")
                }
              >
                Edit Import Settings
              </Text>
              <Hint hint="Import Settings save you time by adjusting the default pricing, markup, and other details to prevent needing to manually edit each product." />
            </Row>

            {privatePortal &&
              privatePortal.status === "APPROVED" &&
              !isGlobal && (
                <ButtonNew
                  size="large"
                  text="Download Import List CSV"
                  onClick={() => this.setState({ showCSVPopup: true })}
                  icon={<FiArrowDownCircle size="20px" color="white" />}
                />
              )}

            {privatePortal &&
              privatePortal.status !== "APPROVED" &&
              !isGlobal && (
                <ButtonNew
                  size="large"
                  disabled
                  text="Pending Supplier Approval"
                  icon={<FiClock size="20px" color="white" />}
                />
              )}

            {showBulkImports && (
              <ButtonNew
                size="large"
                disabled={selectedCount === 0}
                text={`${
                  selectedCount === 0
                    ? "Select products to import"
                    : `Import ${selectedCount} of ${productCount} Products`
                }`}
                icon={
                  selectedCount !== 0 && (
                    <FiArrowRightCircle size="20px" color="white" />
                  )
                }
                onClick={() => this.props.importToStore(selectedIds)}
              />
            )}
          </Row>

          {get(this.props, ["importList"]) !== undefined && (
            <ListWrapper>
              {get(this.props, ["importList"]).map((product, i) => (
                <ProductWrapper
                  key={product.id}
                  product={product}
                  index={i}
                  showModal={() => this.setState({ showModal: true })}
                  checked={selectedIds.includes(product.imported_product_id)}
                />
              ))}
            </ListWrapper>
          )}

          {this.renderNoResults()}

          <Pagination
            onClick={(e) => {
              this.props.fetchImportList(e, this.state.searchTerm);
            }}
            pagination={this.props.pagination}
          />
        </FetchingWrapper>
      </Container>
    );
  }
}

List.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(List);
