import styled from "styled-components";
import { theme } from "../../modules/Theme";

import dropcommerceBackground from "../../images/dropcommerce-background.png";

export const LandingButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LandingSignupButtonWrapper = styled.div`
  background: white;
  border: 0px;
  border-radius: 5px;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    background: transparent;
    width: 100%;
  }
`;

export const LandingSignupButton = styled.div`
  padding: 17px;
  padding-left: 25px;
  padding-right: 25px;
  background: ${theme.colors.main};
  border: 0px;
  border-radius: 0px 5px 5px 0px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.mainDark};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    width: 100%;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
  }
`;

export const LandingSignupInput = styled.input`
  width: 300px;
  padding: 18px;
  border: 0px;
  border-radius: 5px 0px 0px 5px;
  background: transparent;
  font-size: 21px;
  font-weight: 300;
  @media (max-width: 800px) {
    width: 100%;
    text-align: center;
    background: white;
    border-radius: 5px;
  }
`;

export const LandingButtonWrapper = styled.div`
  margin-right: 50px;
  @media (max-width: 800px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const BannerTitle = styled.div`
  color: white;
  padding: 15px;
  padding-top: 15px;
  font-weight: 700;
  font-size: 45px;
  @media (max-width: 800px) {
    font-size: 35px;
  }
`;

export const BannerSubtitle = styled.div`
  color: white;
  padding: 15px;
  font-weight: 300;
  font-size: 23px;
  width: 950px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const PrivacyTitle = styled.div`
  color: ${theme.colors.darkGrey};
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: left;
`;

export const PrivacySection = styled.div`
  color: ${theme.colors.darkGrey};
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
`;

export const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Banner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-image: url(${dropcommerceBackground});
  background-size: cover;
  background-position: 50%;
`;

export const BannerColor = styled.div`
  background: rgba(13, 66, 59, 0.7);
  padding: 20px;
  padding-bottom: 55px;
  ${(props) => props.mainColor && "background: rgba(73, 190, 175, 0.80);"}
`;

export const BannerLogo = styled.img`
  height: 45px;
  margin-right: 30px;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-right: 0px;
  }
`;

export const ProductCardsImage = styled.img`
  margin-top: 50px;
  margin-bottom: 20px;
  height: 340px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-right: 30px;
  padding-left: 30px;
  @media (max-width: 800px) {
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: center;
  }
`;

export const HeaderLink = styled.a`
  color: white;
  margin-right: 30px;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;
  opacity: 0.7;
`;

export const HeaderLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 380px;
  margin-bottom: 20px;
  text-align: left;
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const InfoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
  @media (max-width: 800px) {
    padding-top: 30px;
  }
`;

export const InfoItemsSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export const InfoText = styled.div`
  color: ${theme.colors.darkGrey};
  font-weight: 300;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 20px;
  text-align: left;
`;

export const InfoTitle = styled.div`
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 45px;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  @media (max-width: 800px) {
    margin-bottom: 20px;
    font-size: 30px;
  }
`;

export const PricingOption = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 450px;
  margin: 20px;
  padding: 15px;
  padding-bottom: 30px;
  background: ${theme.colors.white};
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.45);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const PricingPlanName = styled.div`
  font-size: 22px;
  color: ${theme.colors.medGrey};
  font-weight: 300;
  width: 100%;
  margin-bottom: 15px;
`;

export const PricingOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
`;

export const PricingFeature = styled.div`
  color: ${theme.colors.darkGrey};
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: left;
  ${(props) => props.bold && "font-weight: 700;"}
`;

export const PricingFeatureIcon = styled.img`
  height: 16px;
`;

export const PricingFeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const PricingFeatures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const LinkText = styled.div`
  color: ${theme.colors.main};
  font-weight: 400;
  font-size: 18px;
  margin-top: 50px;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.mainDark};
  }
`;

export const PricingOptionTitle = styled.div`
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`;

export const PricingOptionCurrency = styled.div`
  color: ${theme.colors.medGrey};
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

export const PricingOptionPrice = styled.div`
  color: ${theme.colors.medDarkGrey};
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PricingTransactionFee = styled.div`
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
`;

export const InfoIcon = styled.img`
  height: 32px;
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  background: #49beaf;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FeatureImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: 50%;
  padding: 40px;
  @media (max-width: 800px) {
    height: 200px;
  }
`;

export const FeatureInfo = styled.div`
  flex: 1;
  padding: 40px;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 800px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const FeatureHeadline = styled.div`
  color: ${theme.colors.white};
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const FeatureText = styled.div`
  color: ${theme.colors.white};
  font-weight: 300;
  font-size: 22px;
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const BoldText = styled.span`
  font-weight: 400;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  background: white;
  @media (max-width: 800px) {
    flex-direction: column;
    padding-top: 10px;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  @media (max-width: 800px) {
    margin-top: 15px;
    width: 100%;
  }
`;

export const FooterSectionItem = styled.a`
  font-weight: 300;
  text-decoration: none;
  color: ${theme.colors.darkGrey};
  margin-bottom: 6px;
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: ${theme.colors.darkGrey};
  }
`;

export const FooterSectionTitle = styled.div`
  font-weight: 700;
  color: ${theme.colors.darkGrey};
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
`;

export const FooterCopyright = styled.div`
  font-weight: 100;
  color: ${theme.colors.medGrey};
  font-size: 13px;
  margin-top: 10px;
`;

export const FooterLogo = styled.img`
  height: 50px;
`;
