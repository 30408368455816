import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";

export const Wrapper = styled("div")`
  margin-bottom: 24px;
`;

export const Text = styled("h3")`
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  color: ${theme.colors.medDarkGrey};
  text-transform: uppercase;
`;
