import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import searchIcon from "../images/icons/feather/search.svg";

export const SearchIcon = styled.img`
  height: 20px;
  opacity: 0.6;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  /* border: 1px solid ${theme.colors.lightBorder}; */
  border-left: 0px;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 25vw;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 0px;
  font-size: 15px;
  color: ${theme.colors.medDarkGrey};
  padding: 10px;
  font-weight: 300;
  outline: 0px;

  ::placeholder {
    color: ${theme.colors.medGrey};
  }

  @media screen and (max-width: 800px) {
    padding: 10px;
    margin-right: 0px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  /* border: 1px solid ${theme.colors.medLightGrey}; */
  border-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.colors.white};
  ${(p) => p.extraStyle};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.colors.lightBorder};
  width: ${(p) => p.width || "280px"};
  max-width: ${(props) => props.maxWidth || "auto"};
`;

class Search extends Component {
  render() {
    const search = this.props.search.replace("%", "");

    return (
      <Container width={this.props.width}>
        <SearchContainer extraStyle={this.props.extraStyle}>
          <SearchInput
            placeholder={this.props.placeholder || "Search products"}
            onChange={(e) =>
              this.props.onChange(e.target.value.replace("%", ""))
            }
            onKeyPress={(e) => {
              this.props.onEnterPress && this.props.onEnterPress(e);
            }}
            value={search}
          />

          {this.props.initialSearchTerm !== "" && (
            <Text.Small
              light
              link
              extra="width: auto; font-size: 15px;"
              onClick={this.props.clear}
            >
              Clear
            </Text.Small>
          )}
        </SearchContainer>

        <SearchIconContainer>
          <SearchIcon
            src={searchIcon}
            onClick={() => {
              this.props.searchForProducts(search);
            }}
          />
        </SearchIconContainer>
      </Container>
    );
  }
}

export default Search;
