import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Input from "./Input";
import ButtonNew from "./ButtonNew";
import Switch from "./Switch";
import { Checkbox } from "@material-ui/core";
import Hint from "./Hint";
import { get } from "lodash";
import createNotification from "../modules/Notification";
import { postRequest } from "../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { buildObjectFromFields } from "../modules/Format";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      postRequest,
    },
    dispatch
  ),
});

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  width: ${(p) => p.width || "600px"};
  max-width: 99vw;
`;

const FormTop = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  width: 100%;
`;

const FormMiddle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

const FormBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  width: 100%;
`;

const FormItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  @media screen and (max-width: 576px) {
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  margin-top: ${(p) => (p.marginTop ? "20px" : "0px")};
`;

const FormItemLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  width: ${(p) => p.width || "420px"};
  text-align: right;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 576px) {
    justify-content: initial;
  }
`;

const FormTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const FormItem = ({
  item,
  labelWidth,
  marginTop,
  stateLocation,
  formOnChange,
}) => {
  return (
    <FormItemWrapper marginTop={marginTop}>
      <FormItemLabel width={labelWidth} style={{ width: "250px" }}>
        {item.hint && <Hint hint={item.hint} margin="2px 10px 0 0" />}
        {item.label}
      </FormItemLabel>

      <FormItemContent
        item={item}
        formOnChange={formOnChange}
        stateLocation={stateLocation}
      />
    </FormItemWrapper>
  );
};

const InputError = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #c12119;
  text-align: left;
  width: 100%;
`;

const FormItemContent = ({ item, stateLocation, formOnChange }) => {
  const value = get(stateLocation, item.key);

  if (item.type === "checkbox") {
    return (
      <div style={{ width: "250px" }}>
        <Checkbox
          color="primary"
          checked={item.checked}
          onChange={(e) => formOnChange(item.key, e.target.checked)}
        />
        {/* When the checkbox is clicked, send the value to state
            make an object using item.label as the key and a bool value.
            from that object, build a comma-separated string
            to be used when sending the response to the API */}
      </div>
    );
  }

  if (item.type === "switch") {
    return (
      <div style={{ width: "250px" }}>
        <Switch
          active={value}
          margin="0px"
          noTopMargin
          toggle={(data) => formOnChange(item.key, !value)}
        />
      </div>
    );
  }

  if (item.type === "select") {
    const selectValue = item.labelMap
      ? {
          label: item.labelMap[value],
          value: value,
        }
      : value;

    return (
      <div style={{ width: "250px" }}>
        <Select
          isSearchable={item.selectIsSearchable}
          options={item.selectOptions}
          value={selectValue}
          onChange={(data) => formOnChange(item.key, data.value)}
        />

        {item.error && <InputError>{item.error}</InputError>}
      </div>
    );
  }

  if (item.type === "input") {
    return (
      <Input
        small
        placeholder={item.placeholder}
        error={item.error}
        value={value}
        type={item.inputType || "text"}
        onChange={(e) => formOnChange(item.key, e.target.value)}
        width="250px"
      />
    );
  }
};

class Form extends Component {
  state = { saveState: null, changes: [] };

  formAction = (data) => {
    if (data.type === "POST") {
      return this.props.postRequest(data);
    }
    // else if (data.type === "GET") {
    //   return this.props.getRequest(data);
    // }
  };

  validate = () => {
    const { stateLocation, validation, items } = this.props;

    let errors = [];

    let fieldErrors = [];

    items.forEach((item) => {
      const value = get(stateLocation, item.key);
      const itemValidation = item.validation && item.validation(value);

      if (itemValidation) {
        fieldErrors.push(item.label);
        errors.push(itemValidation);
      }
    });

    if (fieldErrors.length > 0) {
      createNotification({
        type: "danger",
        title: "Error",
        message: `Field Errors: ${fieldErrors.join(", ")}`,
      });
    }

    // Form-level validation
    validation &&
      validation.forEach((v) => {
        if (v.rule) {
          errors.push(v.error);
          createNotification({
            type: "danger",
            title: "Error",
            message: v.error,
          });
        }
      });

    if (errors.length === 0) {
      return true;
    }
  };

  submit = () => {
    if (this.validate()) {
      if (this.props.submit) {
        this.props.submit();
      } else {
        const { title, stateLocation, action, items } = this.props;
        this.setState({ saveState: "saving" }, () => {
          const dataObject = buildObjectFromFields(
            stateLocation,
            items.map((i) => i.key)
          );

          this.formAction({ ...action, body: dataObject }).then((response) => {
            if (response.id && this.props.onReceiveIdRoute) {
              this.props.onChange("id", response.id);
              this.props.history.push(
                `${this.props.onReceiveIdRoute}${response.id}`
              );
            }

            if (response.error) {
              this.setState({ saveState: null });
            } else {
              this.setState({ saveState: null, changes: [] });
              createNotification({
                type: "success",
                title: "Success",
                message: `${title} Saved`,
              });
              if (action.callback) {
                action.callback(response);
              }
            }
          });
        });
      }
    }
  };

  render() {
    const { items, title, labelWidth, onChange, stateLocation, width } =
      this.props;

    const visibleItems = items.filter(
      (item) => item.displayRequirement === undefined || item.displayRequirement
    );

    const activeChanges = this.state.changes.length > 0;

    const isFetching =
      this.props.isFetching ||
      ["changed", "saving"].includes(this.state.saveState);

    return (
      <FormWrapper width={width}>
        <FormTop>{title && <FormTitle>{title}</FormTitle>}</FormTop>

        <FormMiddle>
          {visibleItems.map((item, i) => (
            <FormItem
              item={item}
              marginTop={item.marginTop}
              labelWidth={labelWidth}
              formOnChange={(field, value) => {
                let newChanges = [...this.state.changes];

                if (newChanges.length === 0) {
                  newChanges = [field];
                } else if (!newChanges.includes(field)) {
                  newChanges = [...newChanges, field];
                }

                this.setState({ changes: newChanges });

                onChange(field, value);
              }}
              stateLocation={stateLocation}
              key={i}
            />
          ))}
        </FormMiddle>

        <FormBottom>
          {this.props.cancel && (
            <ButtonNew
              text="Cancel"
              type="secondary"
              isFetching={isFetching}
              disabled={isFetching}
              onClick={this.props.cancel}
              margin="0 10px 0 0"
            />
          )}
          <ButtonNew
            text="Save"
            type="main"
            isFetching={isFetching}
            disabled={isFetching || !activeChanges}
            onClick={this.submit}
          />
        </FormBottom>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
