import React from "react";

import Text from "../../modules/Text";
import { getRequest, postRequest } from "../../modules/API";

import ButtonNew from "../../components/ButtonNew";
import { Row, Column } from "../../components/Layout";
import Search from "../../components/Search";
import { Modal } from "../../components/Modal";

import {
  Button,
  Card,
  CardMedia,
  Checkbox,
  Select,
  Typography,
} from "@material-ui/core";
import MDSpinner from "react-md-spinner";
import {} from "./AdminActions";
import createNotification from "../../modules/Notification";
import { Show } from "../../components/Show";

class Autocat extends React.Component {
  constructor(props) {
    super(props);
    this.approveAll = this.approveAll.bind(this);
  }

  state = {
    supplierData: [],
    viewCategorized: 1,
    operation: "add",
    modalOpen: false,
    fetching: false,
    supplierId: "",
    selectedProducts: [],
    selectAll: false,
  };

  componentDidMount = () => {
    if (this.props.history.location.search.includes("supplier")) {
      getRequest({
        url: `categorize_predictions/${this.props.history.location.search}&categorized=1`,
      }).then((r) => {
        r.data.categories &&
          r.data.categories.forEach((category) => {
            getRequest({
              url: `categorize_predictions/${this.props.history.location.search}&categorized=1&category=${category}`,
            }).then((res) => {
              this.setState({
                supplierData: [
                  ...this.state.supplierData,
                  {
                    key: res.data.category.key,
                    name: res.data.category.name,
                    products: res.data.products,
                  },
                ],
              });
            });
          });
      });
    }
  };

  categories = [
    {
      key: "accessories",
      name: "Accessories",
      subs: {
        belts: "Belts",
        sunglasses: "Sunglasses",
        keychains: "Keychains",
        scarves: "Scarves",
        gloves_mittens: "Gloves & Mittens",
        protective_masks: "Protective Masks",
      },
    },
    {
      key: "bags_wallets",
      name: "Bags & Wallets",
      subs: {
        backpacks: "Backpacks",
        wallets: "Wallets",
        duffel_bags_totes: "Duffel Bags & Totes",
        travel_luggage: "Travel & Luggage",
        purses_clutches: "Purses & Clutches",
      },
    },
    {
      key: "beauty",
      name: "Beauty",
      subs: {
        face_body_care: "Face & Body Care",
        hair_care: "Hair Care",
        hygiene_toiletries: "Hygiene & Toiletries",
        make_up_cosmetics: "Make-Up & Cosmetics",
        perfumes_fragrances: "Perfumes & Fragrances",
        skin_nail_care: "Skin & Nail Care",
      },
    },
    {
      key: "food_drink",
      name: "Food & Drinks",
      subs: {
        beverages: "Beverages",
        coffee_tea: "Coffee & Tea",
        food_snacks: "Food & Snacks",
      },
    },
    {
      key: "health_wellness",
      name: "Health & Wellness",
      subs: {
        anxiety_stress: "Anxiety & Stress",
        oral_dental_care: "Oral & Dental Care",
        pain_management: "Pain Management",
        vitamins_supplements: "Vitamins & Supplements",
      },
    },
    {
      key: "home_garden",
      name: "Home & Garden",
      subs: {
        bathroom_accessories: "Bathroom",
        bedding_pillows_rugs: "Bedding, Pillows & Rugs",
        cleaning_products: "Cleaning Products",
        gardening_landscaping: "Gardening & Landscaping",
        home_appliances: "Home Appliances",
        mirrors_wall_art: "Mirrors & Wall Art",
        diffusers_oil_candles: "Diffusers, Oils & Candles",
        kitchen: "Kitchen",
        room_decor: "Room Decor",
      },
    },
    {
      key: "jewelry_watches",
      name: "Jewelry & Watches",
      subs: {
        bracelets: "Bracelets",
        charms_pendants: "Charms & Pendants",
        earrings: "Earrings",
        necklaces: "Necklaces",
        rings: "Rings",
        watches: "Watches",
      },
    },
    {
      key: "clothing",
      name: "Clothing",
      subs: {
        athletic: "Athletic",
        clothing_accessories: "Clothing Accessories",
        eyewear: "Eyewear",
        footwear: "Footwear",
        dresses: "Dresses",
        jackets_outerwear: "Jackets & Outerwear",
        pants_leggings: "Pants & Leggings",
        shirts_tops: "Shirts & Tops",
        formal_wear: "Formal Wear",
        headwear: "Headwear",
        swimwear: "Swimwear",
        underwear: "Underwear",
      },
    },
    {
      name: "Party, Event & Gifts",
      key: "party_event_gifts",
      subs: {
        cards: "Cards",
        costumes: "Costumes",
        gifts: "Gifts",
        holidays_seasonal_events: "Holidays & Seasonal Events",
      },
    },
    {
      name: "Parenting & Kids",
      key: "parenting_kids",
      subs: {
        parenthood_accessories: "Parenthood & Accessories",
        blankets_pillows: "Blankets & Pillows",
        children_baby_clothing: "Children & Baby Clothing",
      },
    },
    {
      key: "pets",
      name: "Pets",
      subs: {
        treats_feeding: "Treats & Feeding",
        pet_toys: "Pet Toys",
        apparel_leashes: "Apparel & Leashes",
      },
    },
    {
      key: "sports_outdoors",
      name: "Sport & Outdoors",
      subs: {
        fishing_boating: "Fishing & Boarding",
        hiking_camping: "Hiking & Camping",
        sports_equipment_accessories: "Sports Equipment & Accessories",
      },
    },
    {
      key: "stationery_crafts",
      name: "Stationery & Crafts",
      subs: {
        crafts: "Crafts",
        office_supplies: "Office Suppliers",
        pens_pencils: "Pens & Pencils",
        stickers: "Stickers",
        notebooks: "Notebooks",
      },
    },
    {
      key: "tech_accessories",
      name: "Tech & Accessories",
      subs: {
        mobile_laptop_accessories: "Mobile & Laptop Accessories",
        speakers_headphones: "Speakers & Headphones",
        renewable_alternative_energy: "Renewable & Alternative Energy",
        electronics: "Electronics",
      },
    },
    {
      key: "toys_games",
      name: "Toys & Games",
      subs: {
        card_board_games: "Card & Board Games",
        puzzles_brainteasers: "Puzzles & Brainteasers",
        toys: "Toys",
      },
    },
    { key: "no_category", name: "No Category", subs: {} },
  ];

  approveAll = function () {
    const approvedProducts = [];
    this.state.supplierData.map((category) =>
      category.products.results.forEach((product) =>
        approvedProducts.push({ category: product.category, id: product.id })
      )
    );

    postRequest({
      url: `categorize_predictions/`,
      data: {
        products: [...approvedProducts],
      },
    }).then((res) =>
      createNotification({
        type: "success",
        title: "SUCCESS!",
        message: "Approved smart categorization for all products on this page!",
      })
    );
  };

  searchSupplier = (e, categorized = 1) => {
    const supplierId =
      this.state.supplierId || this.props.history.location.search.slice(10); // this gets just the ID# from url params
    if (e.key === "Enter") {
      this.setState({ fetching: true, supplierData: [] });
      this.props.history.push(`?supplier=${supplierId}`);
      getRequest({
        url: `categorize_predictions/?supplier=${supplierId}&categorized=${categorized}`,
      }).then((r) => {
        if (categorized === 0) {
          this.setState({
            viewCategorized: categorized,
            fetching: false,
            supplierData: [
              ...this.state.supplierData,
              {
                key: r.data.category.key,
                name: r.data.category.name,
                products: r.data.products,
              },
            ],
          });
          // when categorized === 0, we render the products in NO CATEGORY
          // then render the categories list as usual
          r.data.categories &&
            r.data.categories.forEach((category) => {
              getRequest({
                url: `categorize_predictions/${this.props.history.location.search}&categorized=${categorized}&category=${category}`,
              }).then((res) => {
                this.setState({
                  viewCategorized: categorized,
                  supplierData: [
                    ...this.state.supplierData,
                    {
                      key: res.data.category.key,
                      name: res.data.category.name,
                      products: res.data.products,
                    },
                  ],
                });
              });
            });
        } else {
          this.setState({
            fetching: false,
          });
          r.data.categories &&
            r.data.categories.forEach((category) => {
              getRequest({
                url: `categorize_predictions/${this.props.history.location.search}&categorized=${categorized}&category=${category}`,
              }).then((res) => {
                this.setState({
                  viewCategorized: categorized,
                  supplierData: [
                    ...this.state.supplierData,
                    {
                      key: res.data.category.key,
                      name: res.data.category.name,
                      products: res.data.products,
                    },
                  ],
                });
              });
            });
        }
      });
    }
  };

  toggleViewCategorized = () => {
    const viewCategorized = this.state.viewCategorized === 1 ? 0 : 1;
    this.setState({ viewCategorized });
    // TIP: Note the use of comma after setState()
    // Since setState() runs asynchronously, this.searchSupplier() will wait to be executed until setState() has completed.
    this.searchSupplier({ key: "Enter" }, viewCategorized);
  };

  toggleSelect = (param) => {
    if (
      this.state.selectedProducts.some((product) => product.id === param.id)
    ) {
      this.setState({
        selectedProducts: this.state.selectedProducts.filter(
          (product) => product.id !== param.id
        ),
      });
    } else {
      this.setState({
        selectedProducts: [...this.state.selectedProducts, param],
      });
    }
  };

  selectAll = () => {
    if (this.state.selectAll) {
      this.setState({
        selectAll: !this.state.selectAll,
        selectedProducts: [],
      });
    } else {
      this.setState({
        selectAll: !this.state.selectAll, // this would just put a border around all products
        // and instead of creating an array with all the products at this step,
        // we will do that operation when the user clicks on confirm move
      });
    }
  };

  confirmOperation = () => {
    if (
      this.state.viewCategorized &&
      this.state.operation === "move" &&
      !this.state.moveTo
    ) {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Select a Category and an optional subcategory",
      });
      return;
    }
    let products = [];
    if (this.state.selectAll) {
      this.state.supplierData.map((category) =>
        category.products.results.forEach((product) =>
          products.push({ id: product.id, category: product.category })
        )
      );
    } else {
      products = this.state.selectedProducts.map((product) => {
        return { id: product.id, category: product.category };
      });
    }
    let category = this.state.moveToSub;
    if (category === undefined) {
      if (this.state.moveTo) {
        category = this.state.moveTo.key;
      } else {
        category = "";
      }
    }

    postRequest({
      url: `categorize_predictions/`,
      data: {
        category: category,
        products: products,
        operation: this.state.operation,
      },
    })
      .then((res) => {
        this.setState({
          modalOpen: false,
          selectedProducts: [],
        });
        createNotification({
          type: "success",
          title: "Done!",
          message: "Confirmed successfully.",
        });
      })
      .catch((err) => {
        console.log("error", err);
        // createNotification({
        //   type: "danger",
        //   title: "Error",
        //   message: err,
        // });
      });
  };

  prevNextHandler = (wholeUrl, category) => {
    getRequest({}, wholeUrl).then((res) => {
      const currCategory = res.data.category.key;
      const index = this.state.supplierData.findIndex(
        (category) => category.key === currCategory
      );
      const newArr = this.state.supplierData;
      newArr.splice(index, 1, {
        key: res.data.category.key,
        name: res.data.category.name,
        products: res.data.products,
      });
      this.setState({ supplierData: newArr });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Row
            align
            style={{
              justifyContent: "space-between",
            }}
          >
            <Text.Large bold>Categories</Text.Large>

            <Search
              placeholder="Search for a supplier ID"
              onChange={(supplierId) => this.setState({ supplierId })}
              search={this.state.supplierId}
              initialSearchTerm=""
              searchForProducts={() => this.searchSupplier({ key: "Enter" })}
              onEnterPress={(e) => this.searchSupplier(e)}
            />
          </Row>

          <Row align gap="10px">
            <Text.Small>Category: </Text.Small>

            {/* Select a category */}
            <Select style={{ minWidth: "120px" }}>
              {this.categories.map((cat, i) => (
                <option
                  key={i}
                  value={cat.key}
                  style={{ padding: "3px" }}
                  onClick={() => this.setState({ moveTo: cat })}
                >
                  {cat.name}
                </option>
              ))}
            </Select>

            {/* Select a subcategory */}
            <Select style={{ minWidth: "120px" }}>
              {this.state.moveTo &&
                Object.entries(this.state.moveTo.subs).map((option, i) => (
                  <option
                    key={i}
                    value={option[0]}
                    style={{ padding: "3px" }}
                    onClick={() => this.setState({ moveToSub: option[0] })}
                  >
                    {option[1]}
                  </option>
                ))}
            </Select>

            {/* Action buttons */}
            <ButtonNew
              onClick={() =>
                this.setState({ modalOpen: true, operation: "add" })
              }
              text={"ADD"}
            />

            <ButtonNew
              onClick={() =>
                this.setState({ modalOpen: true, operation: "move" })
              }
              text={"MOVE"}
            />

            <ButtonNew
              onClick={() =>
                this.setState({ modalOpen: true, operation: "remove" })
              }
              text={"REMOVE"}
            />

            {this.state.supplierData && (
              <ButtonNew
                type="secondary"
                onClick={this.toggleViewCategorized}
                text={
                  this.state.viewCategorized
                    ? "VIEW UNCATEGORIZED"
                    : "VIEW CATEGORIZED "
                }
              />
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text.Small light>Select all </Text.Small>

              <Checkbox color="primary" onChange={this.selectAll} />
            </div>
          </Row>
        </div>

        {this.state.fetching && <MDSpinner />}

        {this.state.modalOpen && (
          <Modal
            hide={() => this.setState({ modalOpen: false })}
            maxWidth="680px"
          >
            <Show
              fallback={
                <Column align gap="10px" style={{}}>
                  <Text>Are you sure?</Text>

                  <Text.Small light>
                    Please confirm that you would like to {this.state.operation}{" "}
                    the categories in the selected product(s).
                  </Text.Small>

                  <Button onClick={this.confirmOperation}>{"CONFIRM"}</Button>
                </Column>
              }
              when={
                this.state.operation === "move" &&
                this.state.moveTo === undefined
              }
            >
              <Column align gap="10px" style={{}}>
                <Text>Missing Category!</Text>

                <Text.Small light>
                  Please select a category and an optional sub-category before a
                  move operation.
                </Text.Small>
              </Column>
            </Show>
          </Modal>
        )}

        <div>
          {this.state.supplierData &&
            this.state.supplierData.map((category, i) => (
              <Column key={i}>
                <Row align>
                  {category.products.previous && (
                    <Button
                      onClick={() =>
                        this.prevNextHandler(category.products.previous)
                      }
                    >
                      PREV
                    </Button>
                  )}

                  <Text.Large>{category.name}</Text.Large>

                  {category.products.next && (
                    <Button
                      onClick={() =>
                        this.prevNextHandler(category.products.next)
                      }
                    >
                      NEXT
                    </Button>
                  )}
                </Row>

                <Row style={{ flexWrap: "wrap" }}>
                  {category.products.results.map((product, index) => (
                    <Card
                      key={index}
                      onClick={() => this.toggleSelect(product)}
                      raised
                      style={{
                        flexShrink: "0",
                        cursor: "pointer",
                        maxWidth: "150px",
                        border:
                          this.state.selectAll ||
                          this.state.selectedProducts.some(
                            (listItem) => listItem.id === product.id // map through the selected items and see whether any of the products has the same id as this guy.
                          )
                            ? `2px solid #1d4040`
                            : "none",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{ margin: "3px" }}
                      >
                        {product.prediction_score}
                      </Typography>

                      <CardMedia
                        component="img"
                        minHeight="200px"
                        width="150px"
                        image={product.image}
                      />

                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{ margin: "3px" }}
                      >
                        {product.title}
                      </Typography>
                    </Card>
                  ))}
                </Row>
              </Column>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default Autocat;
