import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Chip } from "@material-ui/core";
import { FiUnlock } from "react-icons/fi";
import { theme } from "../../../modules/Theme";
import { Link } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    id: 1,
    label: "Chat directly with your suppliers!",
    description:
      "Any Questions? Ask your suppliers directly about orders, products, and shipping",
    imgPath:
      "https://dropcommerce-images.s3.ca-central-1.amazonaws.com/inbox.png",
  },
  {
    id: 2,
    label: "Chat directly with your suppliers!",
    description:
      "Connect with your favourite suppliers. Get product and order information " +
      "straight into your Inbox.",
    imgPath:
      "https://dropcommerce-images.s3.ca-central-1.amazonaws.com/screen2.png",
  },
  {
    id: 3,
    label: "Chat directly with your suppliers!",
    description:
      "Upgrade to a Growth, Professional, or Executive plan to access your Inbox now",
    imgPath:
      "https://dropcommerce-images.s3.ca-central-1.amazonaws.com/screen3.png",
  },
];

const styles = (muiTheme) => ({
  root: {
    width: "100%",
    maxWidth: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    flexWrap: "wrap",
    paddingLeft: muiTheme.spacing(4),
    backgroundColor: muiTheme.palette.background.default,
  },
  img: {
    display: "flex",
    maxHeight: "200px",
    overflow: "hidden",
    border: `2px solid ${theme.colors.lightBorder}`,
    borderRadius: "6px",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  description: {
    width: "100%",
    maxWidth: "75%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    paddingTop: muiTheme.spacing(3),
    paddingBottom: muiTheme.spacing(3),
  },
  upgrade: {
    marginLeft: "8px",
    fontWeight: "600",
  },
});

class InboxCarousel extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = tutorialSteps.length;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h5">
            {tutorialSteps[activeStep].label}
          </Typography>

          <Chip
            clickable
            component={Link}
            label="Upgrade"
            color={"primary"}
            icon={<FiUnlock />}
            to="/settings/plans?f=upgrade-inbox"
            className={classes.upgrade}
          />
        </div>

        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          interval={5000}
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.id}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div className={classes.imgContainer}>
                  <Typography variant={"body1"} className={classes.description}>
                    {step.description}
                  </Typography>

                  <img
                    className={classes.img}
                    src={step.imgPath}
                    alt={step.label}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(InboxCarousel);
