import "babel-polyfill";
import "isomorphic-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  authShopifySupplier,
  authShoplazzaStore,
  authStore,
  getParameterByName,
  isSupplierApp,
} from "./modules/Format";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { api } from "./modules/Config";
import Cookies from "js-cookie";
import { history } from "./BaseApp";

const supplierAuth = (params) => {
  fetch(`${api}/supplier_shopify_auth/`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json"},
  })
    .then((rawResponse) => (rawResponse.json()))
    .then((response) => {
      if (response.token) {
        Cookies.set("accessToken", response.token, { expires: 10 });
        if (response.new_account) {
          history.push("/supplier/onboarding/");
        } else {
          history.push("/supplier/products");
        }
      }
    })
    .catch((error) => {console.log("Sorry")});
}

// Aux functions
const isShoplazzaMerchant = (params) => {
  if (isSupplierApp) return false;
  if (params.shop === "") return false;
  return params.shop.includes("shoplaza");
}

const isShopifySupplierInstall = (params) => {
  if (!isSupplierApp) return false;
  if (params.shop === "") return false;
  if (params.hmac === "") return false;
  if (params.host === "") return false;
  if (params.code !== "") return false;
  if (params.state !== "") return false;
  if (params.timestamp === "") return false;
}

const isShopifySupplierLaunch = (params) => {
  if (!isSupplierApp) return false;
  if (params.shop === "") return false;
  if (params.hmac === "") return false;
  if (params.host === "") return false;
  if (params.code === "") return false;
  if (params.state === "") return false;
  if (params.timestamp === "") return false;
}

const isShopifyMerchant = (params) => {
  if (isSupplierApp) return false;
  if (params.shop === "") return false;
}

// Get URL parameters
const urlParameters = {
  "shop": getParameterByName("shop"),
  "code": getParameterByName("code"),
  "hmac": getParameterByName("hmac"),
  "host": getParameterByName("host"),
  "state": getParameterByName("state"),
  "timestamp": getParameterByName("timestamp")
}

if (isShoplazzaMerchant(urlParameters)) {
  // Install Shoplazza merchant app
  console.log("Shopplaza installation");
  authShoplazzaStore(urlParameters.shop);
} else if (isShopifySupplierInstall(urlParameters)) {
  // Install Shopify Supplier app
  console.log("Supplier App Install: redirect to oauth");
  authShopifySupplier(urlParameters.shop);
} else if (isShopifySupplierLaunch(urlParameters)) {
  // Launch Shopify Supplier App
  console.log("Supplier App Launch");
  supplierAuth(urlParameters);
} else if (isShopifyMerchant(urlParameters)) {
  // Install Shopify merchant app
  console.log("Shopify installation");
  authStore(urlParameters.shop);
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// hot module replacement
if (module.hot && process.env.NODE_ENV === "development") {
  module.hot.accept("./App", function () {
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, document.getElementById("root"));
  });
}
