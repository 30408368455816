import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { Row } from "./Layout";
import Stars from "./Stars";
import RatingModal from "./RatingModal";
import BarLine from "./BarLine";
import { get } from "lodash";
import moment from "moment";
import Select from "react-select";
import { Modal } from "./Modal";
import ButtonNew from "./ButtonNew";
import { Show } from "./Show";
import { ReactPortal } from "./ReactPortal";

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const ReviewsWrapper = styled.div`
  border: 1px solid #e8eaec;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  padding: 15px;
  cursor: default;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  text-align: left;
`;

const ReviewsLight = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  text-align: left;
  margin-bottom: 5px;

  @media (max-width: 1400px) {
    font-size: 12px;
  }
`;

const CustomerReview = styled.div`
  border: none;
  border: 1px solid #e8eaec;
  padding: 15px;
  margin-top: 10px;
`;

const DisplayStar = styled.div`
  padding-right: 5px;
`;

const ReviewStoreName = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const ReviewContainer = styled.div``;

const RatingTime = styled.div`
  font-weight: 400;
  font-size: 18px;
`;

const RatingContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const TotalRatingsContainer = styled.div`
  width: 30%;
  padding-left: 10px;
  padding-bottom: 15px;
`;

const RatingNumberText = styled.div`
  margin-right: 2px;
`;

class SupplierReviewsModal extends Component {
  state = {
    selectVal: { value: "recent", label: "Recent" },
    showInnerModal: false,
  };

  calculateNStarTotals = (data) => {
    const ratingsList = data.map((v) => v.rating);
    let starTotals = {
      5: 0,
      4: 0,
      3: 0,
      2: 0,
      1: 0,
    };

    for (let i = 0; i < ratingsList.length; i++) {
      if (ratingsList[i] in starTotals) {
        starTotals[ratingsList[i]]++;
      }
    }

    return { starTotals, ratingsList: ratingsList.length };
  };

  sortDataBy = (data, option) => {
    if (option === "highest") {
      return data.sort((a, b) => (a.rating > b.rating ? -1 : 1));
    } else if (option === "lowest")
      return data.sort((a, b) => (a.rating > b.rating ? 1 : -1));
    else {
      return data.reverse();
    }
  };

  render() {
    const reviews = get(this.props, ["supplier", "reviews"]);
    const data = reviews.reviews;

    const starResults = this.calculateNStarTotals(data);
    const starTotals = starResults.starTotals;
    const ratingsCount = starResults.ratingsList;

    const options = [
      { value: "recent", label: "Recent" },
      { value: "highest", label: "Highest" },
      { value: "lowest", label: "Lowest" },
    ];

    return (
      <>
        <Show when={this.state.showInnerModal}>
          <ReactPortal wrapperId="rating_modal">
            <RatingModal
              supplier={this.props.supplier}
              hideModal={() => this.setState({ showInnerModal: false })}
            />
          </ReactPortal>
        </Show>

        <Modal hide={this.props.hideModal} maxWidth="800px">
          <ModalTitle>
            {this.props.supplier.name.toUpperCase() || "Reviews"}
          </ModalTitle>

          <ReviewsWrapper>
            <Row style={{ justifyContent: "space-between" }}>
              <Title>SELLER REVIEWS</Title>

              <ButtonNew
                type="main"
                text="WRITE A REVIEW"
                extra="width: 180px; background-color: #e48832; :hover { background-color: #cb701a; }"
                onClick={() => {
                  this.setState({ showInnerModal: true });
                }}
              />
            </Row>

            <Row style={{ paddingBottom: "15px" }}>
              <Stars type={"display"} rating={reviews.rating} size={"35"} />

              <ReviewsLight>
                {data.length} {data.length === 1 ? "Review" : "Reviews"}
              </ReviewsLight>
            </Row>

            <Row>
              <TotalRatingsContainer>
                {Object.values(starTotals)
                  .reverse()
                  .map((star, i) => {
                    return (
                      <Row key={i}>
                        <DisplayStar>
                          <Row>
                            <RatingNumberText>
                              {5 - i > 0 ? 5 - i : null}
                            </RatingNumberText>

                            <Stars
                              type={"display"}
                              rating={0}
                              amount="1"
                              size="12px"
                            />
                          </Row>
                        </DisplayStar>

                        <BarLine percent={(star / ratingsCount) * 100} />

                        <ReviewsLight>{star}</ReviewsLight>
                      </Row>
                    );
                  })}
              </TotalRatingsContainer>
            </Row>

            {data.length > 0 ? (
              <Select
                options={options}
                value={this.state.selectVal}
                isSearchable={false}
                onChange={(data) => this.setState({ selectVal: data })}
              />
            ) : (
              "Be the first to leave a review!"
            )}

            {this.sortDataBy(data, this.state.selectVal.value).map(
              (customer, i) => {
                return (
                  <CustomerReview key={i}>
                    <Row style={{ justifyContent: "space-between" }}>
                      <ReviewStoreName>
                        {customer.store__name.toUpperCase()}
                      </ReviewStoreName>

                      <RatingTime>
                        {moment(customer.time).format("MMM Do YYYY")}
                      </RatingTime>
                    </Row>

                    <RatingContainer>
                      <Stars type={"display"} rating={customer.rating} />
                    </RatingContainer>

                    <ReviewContainer>{customer.review}</ReviewContainer>
                  </CustomerReview>
                );
              }
            )}
          </ReviewsWrapper>
        </Modal>
      </>
    );
  }
}

export default SupplierReviewsModal;
