import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  updateImportListItem,
  setImportListItemSaved,
  fetchProducts,
  removeFromImportList,
  importToStore,
} from "../../../../../views/products/ProductsActions";
import { getIsFetching, getSettings } from "../../../../../Selectors";
import { getProductSaved } from "../../../../../views/products/ProductsSelectors";

import { get, isEmpty, toNumber } from "lodash";

import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";

// Desktop
import ProductErrors from "./main/ProductErrors";
import ProductMain from "./main/ProductMain";
import ProductWarnings from "./main/ProductWarnings";
import { errorNotification } from "../../../../../modules/Notification";

export const ProductWrapper = styled.div`
  background: ${theme.colors.white};
  margin-bottom: 30px;
  width: 100%;
  ${(p) => p.padding && "padding: 15px;"}
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 20px;
  padding-left: 10px;
  padding-top: 7px;
  width: 100%;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${theme.colors.lightBorder};
  justify-content: space-between;
  padding: 15px;
`;

export const NavItem = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 15px;
  border-bottom: 3px solid
    ${(props) => (props.selected ? theme.colors.main : theme.colors.white)};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: ${theme.colors.main};
  }
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  productSaved: getProductSaved(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateImportListItem,
      setImportListItemSaved,
      fetchProducts,
      removeFromImportList,
      importToStore,
    },
    dispatch
  ),
});

const defaultProps = {};

class Product extends Component {
  state = {
    tab: "product",
    tags: [],
    title: "",
    description: EditorState.createEmpty(),
    type: "",
    collections: "",
    warnings: [],
    images: [],
    variantError: null,
  };

  setLocalState = (data) => {
    this.setState(data);
  };

  onEditorStateChange = (description) => {
    this.setState({
      description,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (get(nextProps, ["product", "productSaved"])) {
      setTimeout(() => {
        this.props.setImportListItemSaved(this.props.product.import_info.id);
      }, 5000);
    }
  };

  populateFields = (product) => {
    let info = product.import_info;
    let tags = info.tags;
    let selectedImages = info.selected_images;

    if (tags !== "" && tags !== undefined) {
      tags = tags.split(",").map((tag) => {
        return {
          id: tag,
          text: tag,
        };
      });
    } else {
      tags = [];
    }

    if (
      selectedImages !== "" &&
      selectedImages !== undefined &&
      selectedImages !== null
    ) {
      selectedImages = selectedImages.split(",");
    } else {
      selectedImages = [];
    }

    const images = product.images.map((image) => {
      let match = false;
      selectedImages.forEach((selectedImage) => {
        if (parseInt(selectedImage, 10) === image.id) {
          match = true;
        }
      });
      if (match) {
        return { ...image, selected: true };
      }
      return { ...image, selected: false };
    });

    const collections = () => {
      if (info.collection && info.collection.length > 0) {
        return JSON.parse(info.collection);
      } else {
        return [];
      }
    };

    this.setState({
      title: info.title,
      description: EditorState.createWithContent(
        stateFromHTML(info.description)
      ),
      type: info.product_type,
      collections,
      images,
      tags,
      variants: product.variants,
    });
  };

  UNSAFE_componentWillMount = () => {
    this.populateFields(this.props.product);
  };

  handleDelete = (i) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  };

  handleAddition = (tag) => {
    // eslint-disable-next-line
    var format = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(tag.text)) {
      errorNotification("Invalid Tag. Please remove special characters.");
    } else {
      this.setState((state) => ({ tags: [...state.tags, tag] }));
    }
  };

  handleDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    this.setState({ tags: newTags });
  };

  saveProduct = (alsoImport) => {
    let tags = "";
    this.state.tags.forEach((tag, i) => {
      tags = tags.concat(`${tag.text}`);
      if (i < this.state.tags.length - 1) return (tags = tags.concat(","));
    });

    let priceError = false;
    let atLeastOneActive = false;

    const newVariants = this.state.variants.map((variant) => {
      if (variant.compare_at_price === "" || isNaN(variant.compare_at_price)) {
        priceError = true;
        return {
          ...variant,
          comparePriceError:
            "Please enter a valid price, or enter 0.00 for no Compare Price.",
        };
      }
      if (toNumber(variant.compare_at_price) < toNumber(variant.custom_price)) {
        priceError = true;
        return {
          ...variant,
          comparePriceError: "Compare Price must be higher than Price.",
        };
      }
      if (variant.custom_price === "" || isNaN(variant.custom_price)) {
        priceError = true;
        return { ...variant, mapError: "Please enter a valid price" };
      }
      if (variant.active) {
        atLeastOneActive = true;
      }

      if (
        variant.enforce_minimum_selling_price &&
        parseFloat(variant.custom_price) < variant.retail_price.amount
      ) {
        priceError = true;
        return {
          ...variant,
          mapError: `This variant has a Minimum Selling Price of $${variant.retail_price.amount.toFixed(
            2
          )}. Please set the price at or above this price.`,
        };
      }

      return { ...variant, mapError: null, comparePriceError: null };
    });

    if (this.state.variants.length === 1) {
      atLeastOneActive = true;
    }

    this.setState({ variants: newVariants });

    if (priceError) {
      this.setState({
        tab: "variants",
      });
    } else if (!atLeastOneActive) {
      this.setState({
        warnings: [],
        tab: "variants",
        variantError:
          "Please ensure at least one variant is active for importing.",
      });
    } else {
      let product = {
        imported_product_id: this.props.product.imported_product_id,
        product_id: this.props.product.id,
        id: this.props.product.import_info.id,
        title: this.state.title,
        description: stateToHTML(this.state.description.getCurrentContent()),
        tags,
        images: this.state.images,
        collections: this.state.collections,
        product_type: this.state.type,
        variants: this.state.variants,
      };

      // SAVE UPDATED INFO
      this.props.updateImportListItem(product).then(() => {
        if (alsoImport) {
          this.props.importToStore([this.props.product.imported_product_id]);
        }
      });
    }
  };

  onChange = (e, input) => {
    console.log(e, input);
    if (e === "collections") {
      this.setState({ collections: input });
    } else {
      let errors = {};

      if (input === "type" && e.target.value.length > 250) {
        errors["type"] =
          "The Product Type field can have a max of 250 characters.";
        console.log("errors", errors);
      }

      if (isEmpty(errors)) {
        let newState = this.state;
        newState[input] = e.target.value;
        this.setState(newState);
      }
    }
  };

  variantCheckboxSelect = (e, variant_id) => {
    let newVariants = this.state.variants.map((v) => {
      if (variant_id === v.id) {
        return { ...v, active: !v.active };
      } else {
        return v;
      }
    });
    this.setState({ variants: newVariants });
  };

  editVariantField = (field, value, variant_id) => {
    this.setState({
      variants: this.state.variants.map((v) => {
        if (variant_id === v.id) {
          return {
            ...v,
            [field]: value,
          };
        } else {
          return v;
        }
      }),
    });
  };

  showWarnings = () => {
    let mapError = this.state.mapError;

    if (!mapError) {
      this.state.variants.forEach((v) => {
        if (v.mapError) {
          mapError = true;
        }
      });
    }

    if (!mapError) {
      let warnings = [];

      let shipsToCanada = get(this.props, [
        "product",
        "shipping_options",
        "CA",
      ]);
      let shipsToUS = get(this.props, ["product", "shipping_options", "US"]);
      let shipsIntl = get(this.props, ["product", "shipping_options", "INTL"]);
      let storeCountry = get(this.props, ["settings", "store", "country"]);
      let manufacturingTime = get(this.props, [
        "product",
        "supplier",
        "manufacturing_time",
      ]);

      if (storeCountry === "Canada" && !shipsToCanada) {
        warnings.push({
          name: "Supplier Does Not Ship To Canada",
          message: `${this.props.product.supplier.name} does not offer shipping to Canada. If your store sells to the United States, import this product. Otherwise, you may not want to sell this product.`,
        });
      }

      if (storeCountry === "United States" && !shipsToUS) {
        warnings.push({
          name: "Supplier Does Not Ship To The US",
          message: `${this.props.product.supplier.name} does not offer shipping to the United States. If your store sells to Canada, import this product. Otherwise, you may not want to sell this product.`,
        });
      }

      if (!["United States", "Canada"].includes(storeCountry) && !shipsIntl) {
        warnings.push({
          name: `Supplier Does Not Ship To ${storeCountry}`,
          message: `${this.props.product.supplier.name} does not offer shipping to ${storeCountry}.`,
        });
      }

      if (!["None", "1-2 Days", "", null].includes(manufacturingTime)) {
        warnings.push({
          name: "Additional Manufacturing Time Required",
          message: `${this.props.product.supplier.name} requires an additional manufacturing time of ${manufacturingTime}. Be sure to make this clear in your store listing for this product, so customers know what to expect. This time is in addition to the shipping time.`,
        });
      }

      if (warnings.length > 0) {
        this.setState({ warnings: warnings });
      } else {
        this.saveProduct(true);
      }
    }
  };

  hideWarningsAndRemove = (importedProductId) => {
    this.setState({ warnings: [] });
    this.props.removeFromImportList(importedProductId);
  };

  matchesType = (v, type) => {
    if (v === "size" && type === "Sizes") return true;
    else if (v === "color" && type === "Colors") return true;
    else if (v === "hand" && type === "Hand") return true;
  };

  selectImage = (id) => {
    this.setState({
      images: this.state.images.map((image, i) => {
        if (image.id === id) {
          return { ...image, selected: !image.selected };
        } else {
          return image;
        }
      }),
    });
  };

  editAll = (field, type, value) => {
    this.setState((prevState) => ({
      variants: prevState.variants.map((v) => {
        return {
          ...v,
          [field]:
            type === "value"
              ? parseFloat(value).toFixed(2)
              : (v.price.amount * value).toFixed(2),
        };
      }),
    }));
  };

  render() {
    const { product } = this.props;

    if (product.importError) {
      return <ProductErrors state={this.state} product={product} />;
    }

    if (this.state.warnings.length > 0) {
      return (
        <ProductWarnings
          state={this.state}
          product={product}
          saveProduct={this.saveProduct}
          setLocalState={this.setLocalState}
          hideWarningsAndRemove={this.hideWarningsAndRemove}
        />
      );
    }

    return (
      <ProductMain
        state={this.state}
        product={product}
        setLocalState={this.setLocalState}
        selectImage={this.selectImage}
        onChange={this.onChange}
        handleDelete={this.handleDelete}
        handleAddition={this.handleAddition}
        handleDrag={this.handleDrag}
        onEditorStateChange={this.onEditorStateChange}
        selectVariant={this.variantCheckboxSelect}
        editVariantField={this.editVariantField}
        editAll={this.editAll}
        removeFromList={this.props.removeFromImportList}
        importToStore={() =>
          this.props.importToStore([this.props.product.imported_product_id])
        }
        saveProduct={this.saveProduct}
        showWarnings={this.showWarnings}
      />
    );
  }
}

Product.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product)
);
