import React, { Component } from "react";
import {
  FiAward,
  FiCheck,
  FiDollarSign,
  FiFastForward,
  FiGrid,
  FiSidebar,
} from "react-icons/fi";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { ButtonNew } from "../../../components";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import ShowPassword from "../../../views/prebuilt/components/ShowPassword";
import BuyPrebuilt from "../BuyPrebuilt";

const MobileScreenshot = styled.img`
  height: 400px;
  object-fit: contain;
  margin-left: 80px;
  @media (max-width: 800px) {
    height: 200px;
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ContentBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.lightGrey};
  border-radius: 4px;
  padding: 30px 30px 0 30px;
  margin-bottom: 30px;
  width: 100%;
  @media (max-width: 800px) {
    padding: 10px 10px 0 10px;
    flex-direction: column;
  }
`;

const FeatureRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FeatureContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 10px;
  }
`;

const Feature = ({ icon, title, description }) => (
  <FeatureContainer>
    {icon}
    <Text bold center margin="5px 0 5px 0">
      {title}
    </Text>
    <Text.Small light center>
      {description}
    </Text.Small>
  </FeatureContainer>
);

class Checkout extends Component {
  state = {
    showPassword: false,
    buyConfirm: false,
  };

  livePreview = (storeData) => {
    if (storeData.premium) {
      this.setState({ showPremium: true });
    }
    if (storeData.password) {
      this.setState({ showPassword: storeData.password });
    } else {
      window.open(storeData.demo);
    }
  };

  renderInitialBuyButton = (storeData) => {
    if (storeData.purchased) {
      return <ButtonNew text="Purchased" disabled size="large" />;
    }

    if (storeData.premium) {
      return (
        <a
          href="mailto:support@dropcommerce.com"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <ButtonNew text="Email Support To Purchase" size="large" />
        </a>
      );
    }

    return (
      <ButtonNew
        text="Buy Now - $249.00"
        size="large"
        onClick={() => this.setState({ buyConfirm: true })}
      />
    );
  };

  render() {
    const storeData = this.props.stores.find(
      (s) => s.type === this.props.storeType
    );

    const storeOptions = this.props.stores.map((s) => ({
      value: s.type,
      label: `${s.name} - ${s.premium ? `$${s.price}` : "$249.00"}`,
    }));

    if (this.state.showPassword) {
      return (
        <StoreContainer>
          <ShowPassword
            storeData={storeData}
            hide={() => this.setState({ showPassword: false })}
          />
        </StoreContainer>
      );
    }

    if (this.state.buyConfirm) {
      return (
        <StoreContainer>
          <BuyPrebuilt
            storeData={storeData}
            refreshStoreDesigns={() => this.props.refreshStoreDesigns()}
          />
        </StoreContainer>
      );
    }

    return (
      <StoreContainer>
        <Text.Large margin="0 0 30px 0">
          {`Purchase A ${
            !storeData.premium ? "Pre-Built" : "Premium"
          } Shopify Store`}
        </Text.Large>
        <ContentBoxWrapper>
          <div>
            <Text.Large>Choose Your Template:</Text.Large>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <Select
                value={{
                  value: storeData.type,
                  label: `${storeData.name} - ${
                    storeData.premium ? `$${storeData.price}` : "$249.00"
                  }`,
                }}
                options={storeOptions}
                onChange={(data) => this.props.updateTheme(data.value)}
              />
            </div>

            <div style={{ width: "100%", marginTop: "20px" }}>
              {this.renderInitialBuyButton(storeData)}
              <ButtonNew
                type="secondary"
                size="large"
                text="View Demo"
                margin="15px 0 0 0"
                onClick={() => this.livePreview(storeData)}
              />
            </div>
          </div>
          <MobileScreenshot src={storeData.screenshot} />
        </ContentBoxWrapper>

        <FeatureRow>
          {storeData.premium && (
            <Feature
              title="Unique Store"
              description="Premium stores are one-off, unique stores (no duplicates)"
              icon={<FiSidebar size="35" color={theme.colors.main} />}
            />
          )}
          {storeData.premium && (
            <Feature
              title="Premium Domain"
              description={`Purchase includes the professional ${
                storeData.demo.split("//")[1]
              } domain`}
              icon={<FiCheck size="35" color={theme.colors.main} />}
            />
          )}
          <Feature
            title="3 Months Free"
            description="Get 3 months free DropCommerce Pro Plan ($267 Value)"
            icon={<FiDollarSign size="35" color={theme.colors.main} />}
          />
          <Feature
            title="100+ US Products"
            description="Each store is filled with 100+ products in the niche you choose"
            icon={<FiGrid size="35" color={theme.colors.main} />}
          />
          <Feature
            title="3-7 Day Shipping"
            description="All our suppliers offer fast shipping from within the US"
            icon={<FiFastForward size="35" color={theme.colors.main} />}
          />
          <Feature
            title="Premium Template"
            description="Our team professionally designs each template to optimize for sales"
            icon={<FiAward size="35" color={theme.colors.main} />}
          />
        </FeatureRow>

        <div style={{ padding: "10px" }}>
          {storeData.premium && (
            <React.Fragment>
              <Text green bold margin="30px 0 0 0">
                {storeData.name} is a one-off Premium prebuilt store, which
                means no one else will ever own this store but you!
              </Text>
              <Text margin="30px 0 0 0">
                This purchase includes the{" "}
                <strong>{`${storeData.demo.split("//")[1]}`}</strong> domain
                name and branding / logo.
              </Text>
            </React.Fragment>
          )}

          {storeData.purchased && (
            <Text green style={{ marginTop: "20px", maxWidth: "800px" }}>
              You have purchased this pre-built store. You will receive a
              notification by email as soon as it's ready, within 24 hours.
            </Text>
          )}

          <Text light margin="30px 0 0 0">
            Save time and money by purchasing a premium US dropshipping store
            and start selling in just minutes.
          </Text>

          <Text light margin="30px 0 0 0">
            * $38/month Shopify subscription required
          </Text>

          <Text.Small light margin="30px 0 0 0">
            Other features: Branding & Banner Files, Copyright-Free Images,
            Prebuilt Store Setup E-Book
          </Text.Small>

          {storeData.purchased && (
            <ButtonNew
              size="large"
              margin="10px 0 0 0"
              text="Download Free Store Setup E-Book"
              onClick={() => {
                window.open(
                  "https://dc-imports.s3.ca-central-1.amazonaws.com/DropCommerce-Prebuilt-Store-eBook.pdf"
                );
              }}
            />
          )}
        </div>
      </StoreContainer>
    );
  }
}

export default withRouter(Checkout);
