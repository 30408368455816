import styled from "styled-components";
import { theme } from "../modules/Theme";
import React, { Component } from "react";
import celebrateImage from "../images/celebrate.png";
import reviewButton from "../images/write-review.png";

export const ReviewModalNewDiv = styled.div`
  font-size: 16px;
  color: ${theme.colors.main};
  text-align: center;
  font-weight: 500;
  width: 48px;
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.main};
  border-radius: 4px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.mainDark};
    border: 1px solid ${theme.colors.mainDark};
  }
`;

export const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(73, 190, 175, 0.92);
  flex-direction: column;
  z-index: 9999;
  cursor: auto;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ReviewImage = styled.img`
  height: 70px;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
  @media (max-width: 800px) {
    height: 35px;
  }
`;

export const CelebrateImage = styled.img`
  height: 150px;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MainButton = styled.div`
  width: 380px;
  padding: 18px;
  margin: 10px;
  background: white;
  color: ${theme.colors.darkGrey};
  border-radius: 5px;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 100%;
    font-size: 22px;
    margin: 0px;
    margin-top: 15px;
  }
  &:hover {
    background: ${theme.colors.mainDark};
    color: ${theme.colors.white};
  }
`;

export const HollowButton = styled.div`
  width: 380px;
  padding: 18px;
  margin: 10px;
  color: ${theme.colors.white};
  border-radius: 5px;
  font-size: 30px;
  border: 1px solid white;
  text-align: center;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 100%;
    font-size: 22px;
    margin: 0px;
    margin-top: 15px;
  }
  &:hover {
    background: ${theme.colors.mainDark};
  }
`;

export const MainText = styled.div`
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const SubText = styled.div`
  color: ${theme.colors.white};
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const InstructionText = styled.div`
  color: ${theme.colors.white};
  font-size: 25px;
  font-weight: 500;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const InstructionsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 800px) {
    justify-content: space-between;
  }
`;

class ReviewModalNew extends Component {
  state = {
    response: null,
    feedback: "",
  };

  leaveReview = () => {
    window.open(
      "https://apps.shopify.com/dropcommerce#modal-show=ReviewListingModal"
    );
    this.props.hideModal();
  };

  render() {
    const { hideModal } = this.props;

    return (
      <React.Fragment>
        <Modal>
          <PageWrapper>
            <CelebrateImage src={celebrateImage} />
            <MainText>Congrats on your first order!</MainText>
            <SubText>
              Would you please consider leaving us a review on Shopify?
            </SubText>
            <SubText>
              Every review helps continue building a great dropshipping
              platform, and we'd really appreciate it!
            </SubText>
            <InstructionText>
              1. Click the 'Leave review' button to open Shopify
            </InstructionText>

            <InstructionsRow>
              <InstructionText>
                2. Click this button on the right side of the page:
              </InstructionText>
              <ReviewImage onClick={this.leaveReview} src={reviewButton} />
            </InstructionsRow>

            <ButtonsRow style={{ marginTop: "20px" }}>
              <MainButton onClick={this.leaveReview}>Leave review</MainButton>
              <HollowButton onClick={hideModal}>No thanks</HollowButton>
            </ButtonsRow>
          </PageWrapper>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ReviewModalNew;
