import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { FiStar, FiGrid, FiHelpCircle, FiHome, FiTablet } from "react-icons/fi";

const iconColor = "white";

export const items = (history, pathName, logout) => {
  let sideBarItems;
  sideBarItems = [
    // {
    //   text: "Dashboard",
    //   icon: <FiTerminal size="20" color={iconColor} />,
    //   active: pathName === "/dashboard",
    //   onClick: () => history.push("/dashboard"),
    //   top: true,
    // },
    {
      text: "Starter Stores",
      icon: <FiGrid size="20" color={iconColor} />,
      active: pathName === "/starter-stores",
      onClick: () => history.push("/starter-stores"),
      top: true,
    },
    {
      text: "Premium Stores",
      icon: <FiStar size="20" color={iconColor} />,
      active: pathName === "/premium-stores",
      onClick: () => history.push("/premium-stores"),
      top: true,
    },
    {
      text: "Custom Stores",
      icon: <FiTablet size="20" color={iconColor} />,
      active: pathName === "/custom-stores",
      onClick: () => history.push("/custom-stores"),
      top: true,
    },
    {
      text: "My Stores",
      icon: <FiHome size="20" color={iconColor} />,
      active: pathName === "/my-stores",
      onClick: () => history.push("/my-stores"),
      top: true,
    },
    {
      text: "Support",
      icon: <FiHelpCircle size="20" color={iconColor} />,
      onClick: () =>
        window.open(
          "https://help.dropcommerce.com/en/articles/4361377-prebuilt-stores-faq"
        ),
      bottom: true,
    },
    // {
    //   text: "Logout",
    //   icon: <FiLogOut size="20" color={iconColor} />,
    //   onClick: logout,
    //   bottom: true,
    // },
  ];

  return sideBarItems;
};

const StyledSideBarItem = styled.div`
  position: relative;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${(props) => (props.active ? "1.0" : "0.7")};
  &:hover {
    opacity: 1;
  }
`;

const SidebarItemText = styled.div`
  font-size: 14px;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  color: white;
  margin-left: 10px;
`;

const LabelPopup = styled.div`
  position: absolute;
  padding: 8px;
  left: 50px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  color: ${theme.colors.medDarkGrey};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
`;

class Item extends Component {
  state = { hover: false };

  render() {
    return (
      <StyledSideBarItem
        onClick={this.props.onClick}
        active={this.props.active}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {this.props.icon}
        {this.props.showLabels && (
          <SidebarItemText>{this.props.text}</SidebarItemText>
        )}
        &nbsp; &nbsp;
        {/* SPECIAL ICONS FOR SALES, ETC. */}
        {this.state.hover && !this.props.showLabels && (
          <LabelPopup>{this.props.text}</LabelPopup>
        )}
      </StyledSideBarItem>
    );
  }
}

const SidebarItemSkeleton = () => <SidebarItemSkeletonWrapper light />;
Item.Skeleton = SidebarItemSkeleton;
export { Item };

const SidebarItemSkeletonWrapper = styled(SkeletonLoader)`
  height: 32px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 4px;
  @media (max-width: 800px) {
    height: 42px;
  }
`;
