import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";
import { Modal } from "./Modal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { answerRefundRequest } from "../views/admin/AdminActions";
import { getIsFetchingRefund } from "../views/products/ProductsSelectors";
import { Checkbox } from "@material-ui/core";
import Text from "../modules/Text";
import { Row } from "./Layout";
import Collapsible from "./Collapsible";
import { FiAlertTriangle } from "react-icons/fi";

const mapStateToProps = (state) => ({
  isFetchingRefund: getIsFetchingRefund(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ answerRefundRequest }, dispatch),
});

export const Title = styled.div`
  color: #265454;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const ErrorText = styled.div`
  color: ${theme.colors.darkRed};
  font-size: 16px;
  font-weight: 400;
`;

export const SupplierName = styled.div`
  margin-bottom: 10px;
  color: #265454;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const ButtonStyle = styled.div`
  padding: 5px 5px 5px 0px;
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 125px;
  max-width: 800px;
  margin-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px !important;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const PolicyHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-left: 10px !important;
  color: #265454;
`;

const DetailsWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 100%;
  margin: 15px;
`;

const DetailsTop = styled.div`
  padding: 5px;
`;

const RefundWrapper = styled.div`
  border-top: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 100%;
  padding: 5px;
`;

class ModalRefundAdmin extends Component {
  state = {
    errors: {},
    rating: 0,
    answer: "",
    readed: false,
    quantity: 0,
    showModal: false,
    orderId: 0,
    supplierId: 0,
    include_shipping_rate: true,
    isFetchingAccept: false,
    isFetchingRefuse: false,
  };

  updateRating = (rating) => {
    this.setState({ rating: rating });
  };

  answerRefund = (id, status, shipping_refunded) => {
    let errors = {};

    this.setState({ errors });
    if (shipping_refunded || status === "REFUSED") {
      this.setState((p) => ({ ...p, include_shipping_rate: false }));
    }
    if (isEmpty(errors)) {
      if (status === "REFUSED") {
        this.setState({ isFetchingRefuse: true }, () => {
          this.props
            .answerRefundRequest(id, {
              status: status,
              answer: this.state.answer,
              shipping_included: this.state.include_shipping_rate,
            })
            .then((response) => {
              if (response.error) {
                this.setState({ error: response.error });
                this.setState({ isFetchingRefuse: false, showModal: false });
              } else {
                this.setState({ isFetchingRefuse: false, showModal: false });
                window.location.reload();
              }
            });
        });
      } else {
        this.setState({ isFetchingAccept: true }, () => {
          this.props
            .answerRefundRequest(id, {
              status: status,
              answer: this.state.answer,
              shipping_included: this.state.include_shipping_rate,
            })
            .then((response) => {
              if (response.error) {
                this.setState({ error: response.error });
                this.setState({ isFetchingAccept: false, showModal: false });
              } else {
                this.setState({ isFetchingAccept: false, showModal: false });
                window.location.reload();
              }
            });
        });
      }
    }
  };

  render() {
    const { data } = this.props;
    if (this.state.showModal) {
      return (
        <Modal hide={() => this.setState({ showModal: false })}>
          {data.error ? (
            <div>
              <Row align style={{ justifyContent: "center" }}>
                <FiAlertTriangle size="24px" color="red" />
              </Row>
              <DetailsWrapper>
                <DetailsTop>
                  <Row align style={{ justifyContent: "space-between" }}>
                    <Text.Large>We have a problem with this request</Text.Large>
                  </Row>
                </DetailsTop>
                <RefundWrapper>
                  <Row>
                    <Text.Small bold>{data.error["message"]}</Text.Small>
                  </Row>
                  <hr></hr>
                  <Row align style={{ justifyContent: "space-between" }}>
                    <Text.Large>Details</Text.Large>
                  </Row>
                  <Row>
                    <Text.Small bold>
                      Order: {data.error["details"]["order"]}
                    </Text.Small>
                  </Row>
                  <Row>
                    <Text.Small bold>
                      Store: {data.error["details"]["store"]}
                    </Text.Small>
                  </Row>
                  <Row>
                    <Text.Small bold>
                      Supplier: {data.error["details"]["supplier"]}
                    </Text.Small>
                  </Row>
                </RefundWrapper>
              </DetailsWrapper>
            </div>
          ) : (
            data &&
            data.refunds.map((refund, i) =>
              data.qty > 1 ? (
                <Collapsible
                  key={i}
                  label={"Refund Request " + refund.id + " Details"}
                >
                  <DetailsWrapper>
                    <DetailsTop>
                      <Row align style={{ justifyContent: "space-between" }}>
                        <Text.Large>{refund.store_name}</Text.Large>
                      </Row>
                    </DetailsTop>
                    <RefundWrapper>
                      <Row align style={{ justifyContent: "space-between" }}>
                        <Row align>
                          <Text
                            link
                            extra="margin-top: 5px; margin-bottom: 5px;"
                            onClick={() =>
                              window.open(
                                `/supplier/order/${refund.supplier_order}`
                              )
                            }
                          >
                            {refund.supplier_order}
                          </Text>
                        </Row>
                      </Row>
                      <Text.Small bold>Item / Quantity:</Text.Small>
                      <Text.Small light extra="opacity: 0.7;">
                        - {refund.product_name} / {refund.quantity}
                      </Text.Small>
                      <Text.Small bold>
                        Total (Item Price * Quantity) + Shipping Rate(
                        {refund.shipping_rate})
                      </Text.Small>
                      <Text.Small>
                        ${" "}
                        {refund.product_price * refund.quantity +
                          refund.shipping_rate}
                      </Text.Small>
                      <Row>
                        {data.shipping_refunded ? (
                          <Text
                            light
                            style={{ marginBottom: "15px", marginTop: "25px" }}
                          >
                            <Checkbox checked disabled />
                            Do not Include the shipping rate (
                            {refund.shipping_rate}) \ refund only the total of
                            items ({refund.product_price * refund.quantity}).
                          </Text>
                        ) : (
                          <Text
                            light
                            style={{ marginBottom: "15px", marginTop: "25px" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  include_shipping_rate: e.target.checked,
                                })
                              }
                            />
                            Do not Include the shipping rate (
                            {refund.shipping_rate}) refund only the total of
                            items ({refund.product_price * refund.quantity}).
                          </Text>
                        )}
                      </Row>
                      <Text bold>Request Justification:</Text>
                      <Text
                        extra="margin-top: 10px; margin-bottom: 10px;"
                        light={refund.reason === ""}
                      >
                        {refund.reason || "No justification"}
                      </Text>
                    </RefundWrapper>
                    <PolicyHeader>
                      Please, explain the motives for your decision.(Refuse
                      Cases):
                    </PolicyHeader>
                    <Row>
                      <StyledTextArea
                        onChange={(e) =>
                          this.setState({ answer: e.target.value })
                        }
                      />
                    </Row>
                  </DetailsWrapper>
                  {refund.status === "PENDING" && (
                    <Row align style={{ justifyContent: "end" }}>
                      <ButtonNew
                        type="main"
                        text="Accept"
                        isFetching={this.state.isFetchingAccept}
                        onClick={() =>
                          this.answerRefund(
                            refund.id,
                            "ACCEPTED",
                            data.shipping_refunded
                          )
                        }
                        extra="width: 200px; margin-top: 10px; background-color: darkGreen;"
                      />
                      <ButtonNew
                        type="main"
                        text="Refuse"
                        isFetching={this.state.isFetchingRefuse}
                        onClick={() =>
                          this.answerRefund(
                            refund.id,
                            "REFUSED",
                            data.shipping_refunded
                          )
                        }
                        extra="width: 200px; margin-top: 10px; margin-left: 20px !important; background-color: darkRed;"
                      />
                    </Row>
                  )}
                </Collapsible>
              ) : (
                <div key={i}>
                  <DetailsWrapper>
                    <DetailsTop>
                      <Row align style={{ justifyContent: "space-between" }}>
                        <Text.Large>{refund.store_name}</Text.Large>
                      </Row>
                    </DetailsTop>
                    <RefundWrapper>
                      <Row align style={{ justifyContent: "space-between" }}>
                        <Row align>
                          <Text
                            link
                            extra="margin-top: 5px; margin-bottom: 5px;"
                            onClick={() =>
                              window.open(
                                `/supplier/order/${refund.supplier_order}`
                              )
                            }
                          >
                            {refund.supplier_order}
                          </Text>
                        </Row>
                      </Row>
                      <Text.Small bold>Item / Quantity:</Text.Small>
                      <Text.Small light extra="opacity: 0.7;">
                        - {refund.product_name} / {refund.quantity}
                      </Text.Small>
                      <Text.Small bold>
                        Total (Item Price * Quantity) + Shipping Rate(
                        {refund.shipping_rate})
                      </Text.Small>
                      <Text.Small>
                        ${" "}
                        {refund.product_price * refund.quantity +
                          refund.shipping_rate}
                      </Text.Small>
                      <Row>
                        {data.shipping_refunded ? (
                          <Text
                            light
                            style={{ marginBottom: "15px", marginTop: "25px" }}
                          >
                            <Checkbox checked disabled />
                            Do not Include the shipping rate (
                            {refund.shipping_rate}) refund only the total of
                            items ({refund.product_price * refund.quantity}).
                          </Text>
                        ) : (
                          <Text
                            light
                            style={{
                              marginBottom: "15px",
                              marginTop: "25px",
                            }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                this.setState({
                                  include_shipping_rate: e.target.checked,
                                })
                              }
                            />
                            Do not Include the shipping rate (
                            {refund.shipping_rate}) refund only the total of
                            items ({refund.product_price * refund.quantity}).
                          </Text>
                        )}
                      </Row>
                      <Text bold>Request Justification:</Text>
                      <Text
                        extra="margin-top: 10px; margin-bottom: 10px;"
                        light={refund.reason === ""}
                      >
                        {refund.reason || "No justification"}
                      </Text>
                    </RefundWrapper>
                    <PolicyHeader>
                      Please, explain the motives for your decision.(Refuse
                      Cases):
                    </PolicyHeader>
                    <Row>
                      <StyledTextArea
                        onChange={(e) =>
                          this.setState({
                            answer: e.target.value,
                          })
                        }
                      />
                    </Row>
                  </DetailsWrapper>
                  {refund.status === "PENDING" && (
                    <Row align style={{ justifyContent: "end" }}>
                      <ButtonNew
                        type="main"
                        text="Accept"
                        isFetching={this.state.isFetchingAccept}
                        onClick={() =>
                          this.answerRefund(
                            refund.id,
                            "ACCEPTED",
                            data.shipping_refunded
                          )
                        }
                        extra="width: 200px; margin-top: 10px; background-color: darkGreen;"
                      />
                      <ButtonNew
                        type="main"
                        text="Refuse"
                        isFetching={this.state.isFetchingRefuse}
                        onClick={() =>
                          this.answerRefund(
                            refund.id,
                            "REFUSED",
                            data.shipping_refunded
                          )
                        }
                        extra="width: 200px; margin-top: 10px; margin-left: 20px !important; background-color: darkRed;"
                      />
                    </Row>
                  )}
                </div>
              )
            )
          )}
        </Modal>
      );
    } else {
      return (
        <React.Fragment>
          <ButtonStyle>
            <ButtonNew
              type="main"
              text="Refund Detail"
              extra="width: 180px; background-color: #32968E; :hover { background-color: #3296DD; }"
              onClick={() => {
                this.setState({ showModal: true });
              }}
            />
          </ButtonStyle>
        </React.Fragment>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRefundAdmin);
