import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { clearErrors } from "../views/products/ProductsActions";
import { getError as getProductError } from "../views/products/ProductsSelectors";
import { getSupplierWarnings } from "../modules/Format";
import {
  getIsFetchingSettings as getIsFetchingSupplierSettings,
  getSettings as getSupplierSettings,
} from "../views/supplier/SupplierSelectors";
import {
  getCurrencyNotice,
  getError,
  getIsFetching,
  getIsFetchingSettings,
  getSettings,
} from "../Selectors";
import { dismissOffer, setError, setPlan } from "../Actions";
import { theme } from "../modules/Theme";
import xIcon from "../images/denied-icon-white.png";
import { get, isEmpty } from "lodash";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";
import PricingOptions from "../views/PricingOptions";
import SpecialOffer from "./SpecialOffer";

import { Modal } from "./Modal";
import Cookies from "js-cookie";

import { trackEvent } from "../modules/EventTracking";
import Topbar from "./Topbar";
import { BannerAnnouncement } from "./BannerAnnouncement";
import { MemorialSaleBanner } from "./MemorialSaleBanner";
import { Show } from "./Show";
import { WelcomeModal } from "./WelcomeModal";
import { BlackFridaySaleBanner } from "./BlackFridaySaleBanner";

const mapStateToProps = (state) => ({
  error: getError(state),
  productError: getProductError(state),
  currencyNotice: getCurrencyNotice(state),
  settings: getSettings(state),
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
  supplierSettings: getSupplierSettings(state),
  isFetchingSupplierSettings: getIsFetchingSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    { setError, setPlan, dismissOffer, clearErrors },
    dispatch
  ),
});

const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
`;

const RightSideContainer = styled.div`
  flex: 1;
`;

const BannersContainer = styled("div")`
  /* overflow-y: auto; */
`;
BannersContainer.displayName = "BannersContainer";

const ErrorBanner = styled.div`
  width: 100%;
  padding: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${theme.colors.darkRed};
  color: ${theme.colors.white};
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: white;
  font-weight: 500;
`;

const ActiveShippingWarning = styled.div`
  padding: 10px;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 400;
  background-color: #c2474d;
`;

const MainContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    /* padding-top: 15px;
    padding-bottom: 45px; */
  }
`;

const FreeUserConversion = styled.div`
  position: absolute;
  border-radius: 7.5px;
  right: 50%;
  transform: translateX(50%);
  bottom: 0px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Post = styled.div`
  padding: 10px;
  display: flex;
  text-decoration: none;
  color: ${theme.colors.lightGrey};
  max-width: 300px;
  position: relative;
  border-radius: 7.5px;
  background-color: ${theme.colors.newDarkGreen};
  box-shadow: 0 3px 10px ${theme.colors.mainLight};
  cursor: pointer;

  &:before {
    content: "";
    border-radius: 7.5px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.mainLight};
    transform-origin: 0 bottom 0;
    transform: scaleY(0);
    transition: 0.2s ease-out;
  }

  &:hover {
    box-shadow: 0px;

    p {
      color: ${theme.colors.newDarkGreen};
    }

    &:before {
      transform: scaleY(1);
    }
  }
`;

const PostTitle = styled.p`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.333;
  transition: 0.2s ease-out;
`;

class Wrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFreeUserConversion: true,
      showOffer: true,
      showPlans: false,
      showPlatformModal: true,
      showPromotions: true,
      dismissedSale: false,
      isWelcomeModalOpen: false,
      showBlackFridayBanner: true,
    };

    this.bannersContainerRef = React.createRef(null);
    this.mainContainerRef = React.createRef(null);
  }

  claimOffer = () => {
    const offer = get(this.props, ["settings", "store", "offer"]);
    this.props.setPlan("CUSTOM", offer.type).then((response) => {
      this.props.dismissOffer(offer.id);
      if (response.plan_auth_url) {
        window.location = response.plan_auth_url;
      }
    });
  };

  hideError = (e) => {
    e.stopPropagation();
    this.props.setError("");
  };

  hideModal = () => {
    this.props.clearErrors();
    Cookies.set("trial_dismissed", true, { expires: 2 });
    this.setState({ showPlans: false });
  };

  hidePlatformModal = () => {
    this.props.clearErrors();
    Cookies.set("platform_dismissed", true, { expires: 2 });
    this.setState({ showPlatformModal: false });
    this.props.history.push("/settings/platforms");
  };

  hidePromotionsModal = () => {
    this.setState({ showPromotions: false });
    Cookies.set("promotions_dismissed", true, { expires: 2 });
  };

  freeUserConversionClicked = () => {
    this.setState({ showFreeUserConversion: false });
    trackEvent("Clicked: Try DropCommerce Pro for FREE");
    Cookies.set("hideFreeUserConversion", true, { expires: 2 });
  };

  setMainContainerHeight = () => {
    if (!this.bannersContainerRef.current || !this.mainContainerRef.current)
      return;
    const bannersHeight = this.bannersContainerRef.current.offsetHeight;
    this.mainContainerRef.current.style.height = `calc(100% - ${bannersHeight}px)`;
  };

  componentDidMount() {
    this.setMainContainerHeight();
  }

  componentDidUpdate() {
    this.setMainContainerHeight();
  }

  hideMemorialSaleBanner = () => {
    this.setState({ dismissedSale: true });
  };

  render() {
    const dismissedSale =
      Cookies.get("dismissedSale") || this.state.dismissedSale;
    const { error, productError, children } = this.props;
    const settings = get(this.props, "settings");
    const supplierSettings = get(this.props, "supplierSettings");
    const supplierWarnings = getSupplierWarnings(supplierSettings);
    const isStore = !isEmpty(get(settings, ["store"]));
    let businessName = "Welcome";
    if (isStore === true) {
      businessName = get(settings, ["store", "name"]);
    } else {
      businessName = get(supplierSettings, ["name"]);
    }

    const showMissingSupplierSettingsBanner =
      !isStore &&
      [
        supplierWarnings.active_shipping_options,
        supplierWarnings.values,
        supplierWarnings.profile_settings,
        supplierWarnings.branding_description,
        supplierWarnings.stripe,
        supplierWarnings.supplier_agreement,
      ].includes(true) &&
      !this.props.isFetchingSupplierSettings;

    const paymentPlan = get(settings, ["store", "payment_plan"]);
    const plan = get(settings, ["store", "payment_plan", "plan"]);
    const isPlansPage = get(this.props, ["location", "pathname"]).includes(
      "/settings/plans"
    );
    const isPrebuiltPage = this.props.match.path.includes("/prebuilt");
    const showUpgradeBanner =
      isStore && !plan && !isPlansPage && !isPrebuiltPage;
    const isConnected = get(settings, ["store", "platform", "is_connected"]);

    const showTrialModal =
      !isEmpty(settings) && !Cookies.get("trial_dismissed") && !plan;

    const otherPlatform = get(settings, ["store", "other_platform"]);

    const otherPlatformIsNull = [null, undefined, ""].includes(otherPlatform);

    const showPlatformBanner =
      !isConnected &&
      otherPlatformIsNull &&
      !isPrebuiltPage &&
      !isEmpty(settings) &&
      get(settings, "email");

    const privatePortal = get(this.props, [
      "settings",
      "store",
      "private_portal",
    ]);

    // Patch for NFT demo
    const location = window.location.toString();
    let isGlobal = false;
    if (location.includes("global") || location.includes("nft")) {
      isGlobal = true;
    }

    const showUpgrade =
      !privatePortal &&
      isConnected &&
      !error &&
      showUpgradeBanner &&
      !isPrebuiltPage;

    const platformName = get(settings, ["store", "platform", "name"]);

    let showSaleBanner = get(settings, ["global", "show_sale_banner"]);
    if (showSaleBanner && isStore) {
      // Hide for current annual plans
      if (paymentPlan && paymentPlan.type === "ONE_TIME") {
        showSaleBanner = false;
      }
    } else {
      // Hide for suppliers
      showSaleBanner = false;
    }

    const offer = get(settings, ["store", "offer"]);

    const platform = get(settings, ["store", "platform"]);

    const handleOpen = () => this.setState({ isWelcomeModalOpen: true });

    const { isWelcomeModalOpen } = this.state;

    return (
      <Container>
        <Show when={isWelcomeModalOpen}>
          <WelcomeModal
            onClose={() => this.setState({ isWelcomeModalOpen: false })}
          />
        </Show>

        <Sidebar handleOpen={handleOpen} />

        <RightSideContainer
          showUpgradeBanner={
            !privatePortal &&
            (showUpgradeBanner ||
              showPlatformBanner ||
              showMissingSupplierSettingsBanner ||
              (!isStore &&
                !showMissingSupplierSettingsBanner &&
                !supplierSettings.active))
          }
        >
          <Show when={!isEmpty({ ...supplierSettings, ...settings })}>
            <BannersContainer ref={this.bannersContainerRef}>
              <SidebarMobile handleOpen={handleOpen} />

              {showSaleBanner && !dismissedSale && (
                <MemorialSaleBanner hide={this.hideMemorialSaleBanner} />
              )}

              <Topbar
                isStore={isStore}
                name={businessName}
                showSettings={true}
              />

              {privatePortal &&
                privatePortal.status !== "APPROVED" &&
                !isGlobal && (
                  <BannerAnnouncement
                    warning
                    title="Your account is pending approval."
                  />
                )}

              {!isStore &&
                this.props.isFetchingSettings === 0 &&
                showMissingSupplierSettingsBanner && (
                  <BannerAnnouncement
                    warning
                    title="Your profile settings are incomplete."
                    onClick={() =>
                      this.props.history.push("/supplier/settings")
                    }
                    buttonText="View Settings"
                  />
                )}

              {supplierSettings && supplierSettings.showBanner && (
                <BannerAnnouncement
                  gradient="background: linear-gradient(135deg, #ff6f61 0%, #32968e 100%);"
                  warning
                  title="Start your onboarding process to access all DropCommerce features."
                  onClick={() => {
                    const sappUrl = process.env.REACT_APP_DROPCOMMERCE_SAPP_URL;

                    const url = new URL("/api/supplier/login", sappUrl);
                    url.searchParams.set("shop", supplierSettings.shopify_url);

                    // Navigate to the URL in the same tab
                    window.location.href = url.toString();
                  }}
                  buttonText="Go to onboarding"
                />
              )}

              {!isStore &&
                this.props.isFetchingSettings === 0 &&
                !showMissingSupplierSettingsBanner &&
                !supplierSettings.active &&
                !this.props.isFetchingSupplierSettings && (
                  <BannerAnnouncement
                    warning
                    title="Your profile is not live. Email support@dropcommerce.com to go live."
                  />
                )}

              {showUpgrade && (
                <BannerAnnouncement
                  title="Ready to start importing products and making sales? Start a 14-day free trial"
                  onClick={() => this.setState({ showPlans: true })}
                  buttonText={
                    platformName === "WIX" ? "Upgrade Now" : "Start Free Trial"
                  }
                />
              )}

              {showPlatformBanner && (
                <BannerAnnouncement
                  title="Action Required: Select your ecommerce platform to import products."
                  onClick={() => this.props.history.push("/settings/platforms")}
                  buttonText="Connect Platform"
                />
              )}

              {/* {Cookies.get("hideBlackFridayBanner") === undefined && (
                <BlackFridaySaleBanner
                  message="Level Up Your E-Commerce Business: BLACK FRIDAY SALE!"
                  hide={() => this.setState({ showBlackFridayBanner: false })}
                />
              )} */}

              {platform &&
                isStore &&
                offer &&
                !offer.claimed &&
                this.state.showOffer &&
                !offer.dismissed && (
                  <SpecialOffer
                    details={offer}
                    claimOffer={this.claimOffer}
                    hideOffer={() => this.props.dismissOffer(offer.id)}
                  />
                )}

              {error && error !== "upgrade" && (
                <ErrorBanner>
                  <ErrorText>{error}</ErrorText>
                  <img
                    alt="icon"
                    src={xIcon}
                    style={{ height: "18px" }}
                    onClick={(e) => this.hideError(e)}
                  />
                </ErrorBanner>
              )}

              {isConnected &&
                !privatePortal &&
                (showTrialModal ||
                  this.state.showPlans ||
                  get(productError, "code") === "IMPORT_LIMIT") && (
                  <Modal fullScreen hide={this.hideModal}>
                    <PricingOptions
                      hideModal={this.hideModal}
                      planLimit={get(productError, "message")}
                    />
                  </Modal>
                )}

              {/* BANNER, IF NO ACTIVE SHIPPING OPTIONS */}
              {!isEmpty(this.props.supplierSettings) &&
                !this.props.supplierSettings.active_shipping_options && (
                  <ActiveShippingWarning>
                    Products can't go live without a default shipping rate for
                    your country. Please set a shipping option{" "}
                    <Link
                      to="/supplier/settings/shipping"
                      style={{
                        color: theme.colors.white,
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                    >
                      here
                    </Link>
                    .
                  </ActiveShippingWarning>
                )}

              {this.props.additionalBanner}
            </BannersContainer>
          </Show>

          <MainContainer ref={this.mainContainerRef}>{children}</MainContainer>

          {/* FREE-USER CONVERSION BUTTON BEGIN */}
          {isStore &&
            !Cookies.get("hideFreeUserConversion") &&
            !plan &&
            this.state.showFreeUserConversion && (
              <FreeUserConversion onClick={this.freeUserConversionClicked}>
                <Link style={{ textDecoration: "none" }} to="/settings/plans">
                  <Post>
                    <PostTitle>
                      <span role="img" aria-label="A star">
                        ⭐
                      </span>{" "}
                      Try DropCommerce Pro for FREE
                    </PostTitle>
                  </Post>
                </Link>
              </FreeUserConversion>
            )}

          {/* FREE-USER CONVERSION BUTTON END*/}
        </RightSideContainer>
      </Container>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper)
);
