import { combineReducers } from "redux";

import base from "./Reducer";
import products from "./views/products/ProductsReducer";
import supplier from "./views/supplier/SupplierReducer";
import admin from "./views/admin/AdminReducer";
import affiliate from "./views/affiliate/AffiliateReducer";

const rootReducer = combineReducers({
  base,
  products,
  supplier,
  affiliate,
  admin,
});

export default rootReducer;
