import { Button, Card, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React from "react";
import { useLocalStorage } from "../../../../hooks";
import {
  errorNotification,
  successNotification,
} from "../../../../modules/Notification";
import { retryShopify, updateOrder } from "../../api/lateOrdersApi";
import { ChangeStatusPopover } from "./ChangeStatusPopover";

export function SupplierDetails({ activeSupplier, onCloseDrawer }) {
  const [successfullyRetriedOrders, setSuccessfullyRetriedOrders] =
    useLocalStorage("successfullyRetriedOrders", []);

  const handleSaveStatus = async (status, orderId) => {
    try {
      await updateOrder(status, orderId);

      successNotification("Order status has been changed");
    } catch (error) {
      // Handle error
      console.error("Error:", error);
      errorNotification("Failed to change order status.");
    }
  };

  const handleRetryShopify = async (name) => {
    try {
      await retryShopify(name);

      successNotification(
        "Great news! We've successfully reestablished a connection to Shopify."
      );
      setSuccessfullyRetriedOrders([...successfullyRetriedOrders, name]);
    } catch (error) {
      // Handle error
      console.error("Error:", error);
      errorNotification(
        "We're sorry, but we couldn't establish a connection to Shopify at the moment."
      );
    }
  };

  return (
    <Drawer anchor="right" open={!!activeSupplier} onClose={onCloseDrawer}>
      <Box
        sx={{
          width: 360,
          maxWidth: 360,
          px: 1,
          py: 0.5,
        }}
        role="presentation"
      >
        {activeSupplier &&
          activeSupplier.orders.map((order) => (
            <Card
              key={order.id}
              variant="outlined"
              sx={{ maxWidth: 360, mt: 2 }}
            >
              <Box sx={{ p: 2 }}>
                <Stack direction="column" justifyContent="space-between">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      color="primary.main"
                      variant="body1"
                      component="div"
                    >
                      {order.name}
                    </Typography>

                    <ChangeStatusPopover
                      onSaveStatus={(status) =>
                        handleSaveStatus(status, order.id)
                      }
                    />
                  </Stack>
                  <Typography gutterBottom variant="body2" component="div">
                    {order.days + " days ago"}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="div">
                    {order.email}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {order.adminNotes || "No notes"}
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography gutterBottom variant="body2">
                  Items
                </Typography>
                <Stack direction="column" spacing={1}>
                  {order.lineItems.map((item) => (
                    <Typography
                      key={item}
                      color="text.secondary"
                      variant="body2"
                    >
                      {item}
                    </Typography>
                  ))}
                </Stack>
              </Box>
              {order.shopifyFailed && (
                <Box sx={{ p: 2 }}>
                  <Button
                    disabled={successfullyRetriedOrders.includes(order.name)}
                    onClick={() => handleRetryShopify(order.name)}
                    variant="text"
                    color="warning"
                  >
                    Shopify Failed - Retry?
                  </Button>
                </Box>
              )}
            </Card>
          ))}
      </Box>
    </Drawer>
  );
}
