import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { theme } from "../modules/Theme";
import { get } from "lodash";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.padding || "30px"};
`;

class Spinner extends Component {
  render() {
    const sizes = { large: 65, medium: 50, small: 25 };
    const size = this.props.size;
    const finalSize = get(sizes, size) || sizes["medium"];
    const color = this.props.color || theme.colors.main;

    return (
      <PageWrapper padding={this.props.padding}>
        <MDSpinner singleColor={color} size={finalSize} />
      </PageWrapper>
    );
  }
}

export default withRouter(Spinner);
