import styled from "styled-components";
import { theme } from "../modules/Theme";

const TitlebarMobile = styled.div`
  display: none;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  @media screen and (max-width: 800px) {
    display: initial;
  }
`;

export default TitlebarMobile;
