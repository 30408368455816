import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row } from "../../../components";
import Text from "../../../modules/Text";


class NextSupplierManagement extends Component {
  render() {
    return (
      <PageWrapper>
        <Row style={{ marginBottom: "15px" }}>
          <Text>New Supplier Management</Text>
        </Row>
        <Row>
          <a href={"https://app.dropcommerce.co/"} target="_blank">here</a>
        </Row>
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect()(NextSupplierManagement)
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
