import React, { Component } from "react";

import styled from "styled-components";

import { theme } from "../modules/Theme";
import Hint from "./Hint";
import { get } from "lodash";
import Text from "../modules/Text";

const StyledPrice = styled.div`
  display: flex;
`;

// const ConvertedBadge = styled.div`
//   background: rgba(0, 0, 0, 0.06);
//   color: rgba(0, 0, 0, 0.5);
//   font-size: 12px;
//   padding: 2px;
//   border-radius: 6px;
//   padding-top: 4px;
//   padding-right: 8px;
//   padding-left: 7px;
//   margin-left: 3px;
//   height: 20px;
//   transition: 0.3s;
//   cursor: pointer;

//   &:hover {
//     background: rgba(0, 0, 0, 0.15);
//   }
// `;

class Price extends Component {
  render() {
    const { data, slashData, slashHint, convert } = this.props;
    const textStyle = { color: this.props.orange && "#e48832" };
    const slashStyle = {
      textDecoration: "line-through",
      marginRight: "5px",
      color: theme.colors.medGrey,
    };

    if (get(data, "amount") !== 0.0 && !get(data, "amount")) {
      return <StyledPrice>Error</StyledPrice>;
    }

    return (
      <StyledPrice>
        {slashData && (
          <Text.Small style={slashStyle}>
            $
            {convert
              ? slashData.amount.toFixed(2)
              : slashData.original_amount.toFixed(2)}
          </Text.Small>
        )}

        <Text.Small style={textStyle}>
          ${convert ? data.amount.toFixed(2) : data.original_amount.toFixed(2)}
        </Text.Small>

        <Text.Small light extra="margin-left: 3px;">
          {convert ? data.currency : data.original_currency}
        </Text.Small>

        {slashHint && <Hint hint={slashHint} narrow />}

        {/* {this.props.badgeClick && (
          <ConvertedBadge onClick={this.props.badgeClick}>?</ConvertedBadge>
        )} */}
      </StyledPrice>
    );
  }
}

export default Price;
