import React from "react";
import { ButtonNew, Input, Column, Row, Hint } from "../../../components";
import { Modal } from "../../../components/Modal";
import { postRequest } from "../../../modules/API";
import { FiPlus } from "react-icons/fi";
import Text from "../../../modules/Text";
import createNotification from "../../../modules/Notification";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { addVendorToList } from "../../../views/supplier/SupplierActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSettings } from "../../../views/supplier/SupplierSelectors";
import { get, isEmpty } from "lodash";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      addVendorToList,
    },
    dispatch
  ),
});

const StyledTextArea = styled.textarea`
  ::placeholder {
    color: ${theme.colors.medGrey};
  }
`;
class InviteVendor extends React.Component {
  state = {
    inputText: "",
    inputMessage: "",
    modalVisible: false,
    isFetching: false,
  };

  submitHandler = () => {
    const value = this.state.inputText;
    const message = this.state.inputMessage;

    if (value === "") {
      createNotification({
        type: "danger",
        title: "Invalid Email",
        message: "Please enter a valid email address",
      });
      return;
    }

    this.setState({ isFetching: true });

    postRequest({
      url: "api/vendors/invite/",
      data: { emails: value, message },
    })
      .then((response) => {
        this.setState({
          modalVisible: false,
          isFetching: false,
          inputText: "",
        });
        createNotification({
          type: "success",
          title: "Success",
          message: "Vendor invited successfully",
        });
        this.props.addVendorToList(response.data.new_vendors);
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ modalVisible: false, isFetching: false });
        createNotification({
          type: "danger",
          title: "Error",
          message: "Failed to invite vendor",
        });
      });
  };

  inviteHandler = () => {
    this.setState({ modalVisible: true });
  };

  inputUpdated = (e) => {
    this.setState({ inputText: e.target.value });
  };

  messageUpdated = (e) => {
    this.setState({ inputMessage: e.target.value });
  };

  publicLink = () => {
    const subdomain = get(this.props, ["settings", "subdomain"]);
    const resolvedSubdomain = subdomain === "" ? "app" : subdomain;
    const link = `https://${resolvedSubdomain}.dropcommerce.com/signup`;
    return link;
  };

  render() {
    this.publicLink();

    const subdomain = get(this.props, ["settings", "subdomain"]);

    return (
      <div>
        {this.state.modalVisible && (
          <Modal
            maxWidth="1000px"
            hide={() => this.setState({ modalVisible: false })}
          >
            <Text.Large margin="0 0 25px 0">
              Invite Vendors To DropCommerce
            </Text.Large>

            <Row>
              <Column
                style={{
                  padding: "0 50px 0 0",
                  width: "50%",
                }}
              >
                <Row>
                  <Text margin="0 0 25px 0">Private Portal</Text>
                  <Hint
                    hint="Private vendors will only see your products and you will have ownership over the relationship."
                    hintTwo="A Private Portal is free to join for vendors, but a 10% commission will be charged to you as the supplier for all private vendor orders."
                  />
                </Row>

                {isEmpty(this.props.settings) || subdomain === "" ? (
                  <Column>
                    <Text light>
                      Choose a custom subdomain to get started with your private
                      portal (lowercase and dashes only)
                    </Text>
                    <ButtonNew
                      width="150px"
                      text="Set Subdomain"
                      margin="16px 0px 0px"
                      onClick={() =>
                        this.props.history.push("/supplier/settings/other")
                      }
                    />
                  </Column>
                ) : (
                  <React.Fragment>
                    <Column>
                      <Text.Small light>
                        <b>Option 1:</b> Enter vendor emails (separated by
                        comma)
                      </Text.Small>
                      <Input
                        type="email"
                        placeholder="janesmith@email.com, johnsmith@email.com"
                        // onKeyPress={(e) =>
                        //   e.key === "Enter" && this.submitHandler()
                        // }
                        onChange={this.inputUpdated}
                        value={this.state.inputText}
                        autoFocus
                      />

                      <StyledTextArea
                        onChange={this.messageUpdated}
                        placeholder="Send an optional message..."
                        style={{
                          padding: "10px",
                          fontSize: "16px",
                          borderRadius: "4px",
                          width: "100%",
                          border: "1px solid rgba(0,0,0,0.15)",
                          height: "50px",
                        }}
                      />

                      <ButtonNew
                        width="150px"
                        text="Send Invites"
                        margin="16px 0px 0px"
                        onClick={this.submitHandler}
                        isFetching={this.state.isFetching}
                      />
                    </Column>

                    <Column>
                      <Text.Small light margin="20px 0 0 0">
                        <b>Option 2: </b>Add a sign up form to your external
                        website by pasting this code into your website.
                      </Text.Small>
                      <code
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "8px",
                          margin: "10px 0 0 0",
                          width: "100%",
                        }}
                      >
                        {`<iframe width=“500" height=“500” src=“${this.publicLink()}?type=iframe” id='signup_invite_iframe' title=“Dropshipping Program Signup”></iframe>`}
                      </code>
                      <Text.Small light margin="20px 0 10px 0">
                        <b>Option 3: </b> Invite vendors directly to{" "}
                        {this.publicLink()}
                      </Text.Small>
                      <ButtonNew
                        onClick={() => {
                          createNotification({
                            type: "success",
                            title: "Link Copied",
                            message: `${this.publicLink()} copied to your clipboard`,
                          });
                          navigator.clipboard.writeText(this.publicLink());
                        }}
                        text="Copy Signup Link"
                        width="150px"
                      />
                    </Column>
                  </React.Fragment>
                )}
              </Column>
              <Column
                style={{
                  borderLeft: `1px solid ${theme.colors.lightBorder}`,
                  padding: "0 0 0 50px",
                  width: "50%",
                }}
              >
                <Row>
                  <Text margin="0 0 25px 0">Public Marketplace</Text>
                  <Hint hint="Vendors invited to the public marketplace can sell your products and pay their own subscription fee." />
                </Row>

                <Column>
                  <Text.Small light>
                    <b>Option 1:</b> Submit a referral by email
                  </Text.Small>

                  <ButtonNew
                    width="150px"
                    text="Submit Referral"
                    margin="16px 0px 0px"
                    onClick={() =>
                      this.props.history.push("/supplier/settings/referrals")
                    }
                  />
                </Column>

                <Column>
                  <Text.Small light margin="20px 0 0 0">
                    <b>Option 2: </b>Add a sign up form to your external website
                    by pasting this code into your website.
                  </Text.Small>
                  <code
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "8px",
                      margin: "10px 0 0 0",
                      width: "100%",
                    }}
                  >
                    {`<iframe width=“500" height=“500” src=“https://app.dropcommerce.com/signup?type=iframe” id='signup_invite_iframe' title=“Dropshipping Program Signup”></iframe>`}
                  </code>
                  <Text.Small light margin="20px 0 10px 0">
                    <b>Option 3: </b> Invite vendors directly to
                    https://app.dropcommerce.com/signup
                  </Text.Small>
                  <ButtonNew
                    onClick={() => {
                      createNotification({
                        type: "success",
                        title: "Link Copied",
                        message: `https://app.dropcommerce.com/signup copied to your clipboard`,
                      });
                      navigator.clipboard.writeText(
                        "https://app.dropcommerce.com/signup"
                      );
                    }}
                    text="Copy Signup Link"
                    width="150px"
                  />
                </Column>
              </Column>
            </Row>
          </Modal>
        )}
        <ButtonNew
          text="Invite Vendors"
          icon={<FiPlus size="18" color="white" />}
          size="large"
          onClick={this.inviteHandler}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InviteVendor)
);
