import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";

class TabNavigation extends Component {
  render() {
    const { options, button } = this.props;

    return (
      <ProductTabs>
        <ProductTabsRow>
          {options.map((tab) => (
            <ProductTab
              key={tab.name}
              active={tab.active}
              onClick={tab.onClick}
            >
              <ProductTabTitle>{tab.name}</ProductTabTitle>
              <ProductTabSubtitle>{tab.description}</ProductTabSubtitle>
            </ProductTab>
          ))}
        </ProductTabsRow>
        {button && <ProductTabSpacer>{button}</ProductTabSpacer>}
      </ProductTabs>
    );
  }
}

export default TabNavigation;

export const ProductTabs = styled.div`
  display: flex;
  width: 100%;
  background: ${theme.colors.lightGrey};
`;

export const ProductTabSpacer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ProductTabsRow = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductTab = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 330px;
  ${(p) =>
    p.active
      ? "background: white;"
      : `
        cursor: pointer;
        &:hover {
        background: #fafafa;
      }
  `};
  @media screen and (max-width: 800px) {
    min-width: calc(100% / 2);
    width: calc(100% / 2);
    padding-left: 0px;
    padding: 10px;
    align-items: center;
  }
`;

export const ProductTabTitle = styled.div`
  font-size: 23px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  @media screen and (max-width: 800px) {
    font-size: 16px;
    text-align: center;
    max-width: 120px;
  }
`;

export const ProductTabSubtitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 8px;
  opacity: 0.6;
  @media screen and (max-width: 800px) {
    font-size: 10px;
    display: none;
  }
`;
