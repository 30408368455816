import styled from "styled-components";
import { theme } from "./Theme";

export const Small = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  ${(props) =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${(props) => props.light && "font-weight: 300;"}
  ${(props) => props.bold && "font-weight: 700;"}
  ${(props) => props.white && "color: white;"}
  ${(props) => props.center && "text-align: center;"}
  ${(props) => props.pointer && "cursor:pointer;"}
  ${(props) => props.green && `color: ${theme.colors.main};`}
  ${(props) => props.orange && `color: ${theme.colors.orange}`};
  ${(props) => props.red && `color: ${theme.colors.darkRed}`};

  ${(props) =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    display: inline-block;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
  ${(p) => p.underline && "text-decoration: underline;"}
  ${(props) => props.extra};
  margin: ${(p) => p.margin};
`;

export const Medium = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  ${(props) =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${(props) => props.light && "font-weight: 300;"}
  ${(props) => props.bold && "font-weight: 700;"}
  ${(props) => props.white && "color: white;"}
  ${(props) => props.center && "text-align: center;"}
  ${(props) => props.pointer && "cursor:pointer;"}
  ${(props) => props.green && `color: ${theme.colors.main};`}
  ${(props) => props.orange && `color: ${theme.colors.orange}`};
  ${(props) => props.red && `color: ${theme.colors.darkRed}`};
  ${(props) =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
  ${(p) => p.underline && "text-decoration: underline;"}
  ${(props) => props.extra};
  /* SIZES */
  ${(p) => p.xl && "font-size: 45px;"}
  ${(p) => p.l && "font-size: 40px;"}
  ${(p) => p.m && "font-size: 30px;"}
  ${(p) => p.s && "font-size: 20px;"}
  ${(p) => p.xs && "font-size: 14px;"}
  margin: ${(p) => p.margin};

  @media (max-width: 800px) {
    ${(p) => p.xl && "font-size: 35px;"}
    ${(p) => p.l && "font-size: 30px;"}
    ${(p) => p.m && "font-size: 20px;"}
    ${(p) => p.s && "font-size: 16px;"}
    ${(p) => p.xs && "font-size: 12px;"}
  }
`;

export const Large = styled.div`
  ${(props) => props.uppercase && "text-transform: uppercase;"}
  font-size: 25px;
  color: ${theme.colors.medDarkGrey};
  ${(props) =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${(props) => props.light && "font-weight: 300"};
  ${(props) => props.bold && "font-weight: 700"};
  ${(props) => props.white && "color: white"};
  ${(props) => props.center && "text-align: center;"}
  ${(props) => props.pointer && "cursor:pointer;"}
  ${(props) => props.green && `color: ${theme.colors.main}`};
  ${(props) => props.orange && `color: ${theme.colors.orange}`};
  ${(props) => props.red && `color: ${theme.colors.darkRed}`};
  ${(props) =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    display: inline-block;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
  ${(p) => p.underline && "text-decoration: underline;"}
    ${(props) => props.extra && props.extra};
  margin: ${(p) => p.margin};
`;

// Default Medium
const Text = Medium;

Text.Small = Small;
Text.Large = Large;

export default Text;
