import React from "react";
import { errorNotification } from "../modules/Notification";

async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  }
}

export function useClipboardCopy() {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyClick = async (copyText, delayedCallback) => {
    try {
      // Asynchronously call copyTextToClipboard
      await copyTextToClipboard(copyText);

      // If successful, update the isCopied state value
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
        delayedCallback();
      }, 1500);
    } catch (_error) {
      errorNotification("Failed to copy text");
    }
  };

  return { isCopied, handleCopyClick };
}
