import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import appLogo from "../images/dropcommerce-logo-white.png";
import { getUrlParameter } from "../modules/Format";
import { web } from "../modules/Config";

const GreenWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
`;

const GreenBanner = styled.div`
  background: ${theme.colors.main};
  padding-top: 30px;
  width: 100%;

  @media (max-width: 800px) {
    padding-top: 20px;
  }
`;

const WhiteAngle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
  width: 100%;
  padding-bottom: 50px;
  min-height: 500px;
  height: 500px;
  background: linear-gradient(350deg, #ffffff 49.8%, #32968e 50%);

  @media (max-width: 800px) {
    padding: 10px;
    min-height: 350px;
    height: 350px;
  }
`;

const Logo = styled.img`
  height: 50px;
  margin: 0 auto;

  @media (max-width: 800px) {
    height: 40px;
  }
`;

const MacBookImage = styled.img`
  height: 450px;
  margin-top: 50px;

  @media (max-width: 800px) {
    margin-top: 30px;
    height: 240px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  color: white;

  @media (max-width: 800px) {
    font-size: 32px;
    margin-top: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 28px;
  font-weight: 300;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: white;

  @media (max-width: 800px) {
    font-size: 18px;
    margin-top: 10px;
  }
`;

const SignupButton = styled.div`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  color: white;
  padding: 15px;
  border-radius: 5px;
  transition: 0.1s;
  background: ${theme.colors.medDarkGrey};
  width: 300px;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.darkGrey};
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
`;

const Disclaimer = styled.div`
  font-size: 14px;
  color: white;
  font-weight: 300;
  margin: 0 auto;
  margin-top: 30px;
  max-width: 600px;
  text-align: center;

  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

const SignupRow = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
  }
`;

const MainSection = styled.div`
  text-align: center;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const Launch = (props) => {
  const magicLink = () => {
    const magic = getUrlParameter("magic", props.location);
    const type = getUrlParameter("type", props.location) || "store";
    if (magic) {
      window.open(`${web}/?type=${type}&magic=${magic}`);
    } else {
      window.open(`${web}`);
    }
  };

  const magic = getUrlParameter("magic", props.location);

  let title = "Dropship high quality US products";
  let subtitle =
    "Find the best US & Canadian products with fast shipping and 30-50% margin";

  const error = getUrlParameter("error", props.location);

  if (error) {
    title = "Woops!";
    if (error === "install_error") {
      subtitle = `We're sorry, but there was an issue connecting with ${props.platform}. Please contact support@dropcommerce.com`;
    }

    if (error === "not_found") {
      subtitle =
        "We're sorry, but this account was not found. Please uninstall and reinstall the app, or contact support@dropcommerce.com";
    }
  }

  return (
    <GreenWrapper>
      <GreenBanner>
        <MainSection>
          <Logo
            alt="The DropCommerce Logo"
            src={appLogo}
            onClick={() => props.history.push("/")}
          />

          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>

          {magic && (
            <SignupRow>
              <SignupButton onClick={magicLink}>Open DropCommerce</SignupButton>
            </SignupRow>
          )}

          <Disclaimer>
            Try DropCommerce for free, no credit card required. Fast shipping on
            all products.
          </Disclaimer>
        </MainSection>

        <WhiteAngle>
          <MacBookImage
            alt="A MacBook"
            src="https://res.cloudinary.com/dropcommerce/image/upload/v1579299343/macbook-1_chw6px.png"
          />
        </WhiteAngle>
      </GreenBanner>
    </GreenWrapper>
  );
};

export default withRouter(Launch);
