import { axiosAdminInstance } from "./axiosAdminInstance";

// const delay = () => new Promise((res) => setTimeout(res, 3000));

const userStoresUrlEndpoint = "/api/admin/users/describe";

export async function getUserStores(email) {
  try {
    // await delay();
    const payload = {
      username: email,
    };
    const response = await axiosAdminInstance.post(
      `${userStoresUrlEndpoint}/`,
      payload
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error:", error);
    throw new Error(error);
  }
}
