import { get, isEmpty } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { ButtonNew, Input } from "../../../../components";
import { Show } from "../../../../components/Show";
import {
  errorNotification,
  successNotification,
} from "../../../../modules/Notification";
import { axiosAdminInstance } from "../../api/axiosAdminInstance";

const Form = styled("div")`
  width: 300px;
`;

export default function OrderShippingAddress() {
  const [id, setId] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [order, setOrder] = useState(null);
  const [errors, setErrors] = useState({});

  const orderId = id.replace("DROPCOM-", "");

  const changeId = (e) => setId(e.target.value);

  const validateId = () => {
    const errors = {};
    if (id === "") {
      errors.id = "Please enter an order id.";
    }
    setErrors(errors);
    return isEmpty(errors);
  };

  const getOrderById = () => {
    if (!validateId()) return;
    setIsFetching(true);

    // async
    axiosAdminInstance
      .get(`/order-address/${orderId}`)
      .then((responseData) => {
        const { data } = responseData;
        if (!data.success) {
          errorNotification(data.message);
          return;
        }
        setOrder({
          firstAddress: data.address1,
          secondAddress: data.address2,
          city: data.city,
          province: data.province,
          provinceCode: data.province_code,
          zipCode: data.zip,
        });
      })
      .catch((error) => {
        errorNotification(error.response.statusText);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const changeOrder = (e) =>
    setOrder((currentState) => ({
      ...currentState,
      [e.target.name]: e.target.value,
    }));

  const validateOrder = () => {
    const errors = {};
    if (order.firstAddress === "") {
      errors.firstAddress = "Please enter an address.";
    }
    if (order.city === "") {
      errors.city = "Please enter a city.";
    }
    if (order.province === "") {
      errors.province = "Please enter a province.";
    }
    if (order.provinceCode === "") {
      errors.provinceCode = "Please enter a province code.";
    }
    if (order.zipCode === "") {
      errors.zipCode = "Please enter a zip code.";
    }
    setErrors(errors);
    return isEmpty(errors);
  };

  const updateOrder = () => {
    if (!validateOrder()) return;
    setIsFetching(true);

    const payloadData = {
      id: orderId,
      address1: get(order, "firstAddress"),
      address2: get(order, "secondAddress"),
      city: get(order, "city"),
      province: get(order, "province"),
      province_code: get(order, "provinceCode"),
      zip: get(order, "zipCode"),
    };

    // async
    axiosAdminInstance
      .post(`/order-address/${orderId}`, payloadData)
      .then((responseData) => {
        if (!responseData.data.success) {
          errorNotification(responseData.message);
          return;
        }
        setOrder(null);
        setId("");
        successNotification("Updated successfully");
      })
      .catch((error) => {
        errorNotification("Failed updating order");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const fields = getFields(order, errors);

  return (
    <Form>
      <Input
        type="text"
        label="Order Name"
        name="id"
        value={id}
        onChange={changeId}
        error={errors.id}
        disabled={order !== null}
        extraStyle=""
        placeholder="DROPCOM-XXXXX"
      />

      <Show when={order !== null}>
        {fields.map(({ label, name, value, error, hint }, i) => (
          <Input
            key={i}
            type="text"
            label={label}
            name={name}
            value={value}
            onChange={changeOrder}
            error={error}
            hint={hint}
            extraStyle=""
          />
        ))}
      </Show>

      <ButtonNew
        type="main"
        text={order !== null ? "Update Order" : "Get Order By Name"}
        onClick={order !== null ? updateOrder : getOrderById}
        isFetching={isFetching}
        extra="margin-top: 20px;"
      />
    </Form>
  );
}

const getFields = (order, errors) => [
  {
    label: "First Address",
    name: "firstAddress",
    value: get(order, "firstAddress"),
    error: errors.firstAddress,
  },
  {
    label: "Second Address",
    name: "secondAddress",
    value: get(order, "secondAddress"),
    hint: "Optional",
  },
  {
    label: "City",
    name: "city",
    value: get(order, "city"),
    error: errors.city,
  },
  {
    label: "Province",
    name: "province",
    value: get(order, "province"),
    error: errors.province,
  },
  {
    label: "Province Code",
    name: "provinceCode",
    value: get(order, "provinceCode"),
    error: errors.provinceCode,
  },
  {
    label: "Zip Code",
    name: "zipCode",
    value: get(order, "zipCode"),
    error: errors.zipCode,
  },
];
