import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ButtonNew from "../../components/ButtonNew";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Text from "../../modules/Text";
import { login } from "../../Actions";
import { getIsFetching } from "../affiliate/AffiliateSelectors";
import Input from "../../components/Input";

const ColorWrapper = styled.div`
  background: #485d5a;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 30px;
`;

const MainForm = styled.div`
  width: 500px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
  }
`;

class AffiliateLogin extends Component {
  state = {
    username: "",
    password: "",
    errors: [],
  };

  onChange = (e) => {
    if (e.key === "Enter") {
      this.loginUser();
    }
  };

  loginUser = () => {
    this.props.login(this.state.username, this.state.password);
  };

  render() {
    const { errors } = this.state;

    return (
      <ColorWrapper>
        <MainForm>
          <Text light white>
            Affiliate Login
          </Text>
          <Input
            onChange={(text) => this.setState({ username: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            hint="The email address you used when you registered."
            placeholder="Email Address"
            value={this.state.username}
            extraStyle="margin-bottom: 5px; margin-top: 20px;"
            error={errors.username}
          />

          <Input
            onChange={(text) => this.setState({ password: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            hint="The password you set when you registered."
            placeholder="Password"
            value={this.state.password}
            extraStyle="margin-bottom: 5px;"
            error={errors.password}
            type="password"
          />

          <ButtonNew
            text="Login"
            type="main"
            extra="margin-top: 15px; padding: 15px;"
            fontStyle="font-size: 20px;"
            onClick={this.loginUser}
            isFetching={this.props.isFetching}
          />
        </MainForm>
      </ColorWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ login }, dispatch),
});

const defaultProps = {};

AffiliateLogin.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AffiliateLogin)
);
