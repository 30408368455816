import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { isValidElement, createElement } from "react";
import MDSpinner from "react-md-spinner";
import { theme } from "../../modules/Theme";
import styled from "styled-components";

const NoResultsText = styled.div`
  font-size: 16px;
  color: ${theme.colors.darkGrey};
  font-weight: 400;
  margin-bottom: 20px;
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  header: {
    backgroundColor: "#f8fbfb",
  },
  toolbar: {
    color: "#2c857d",
  },
});

export function TableNew({ title, columns, rows, isLoading, noResultsText }) {
  // hook
  const classes = useStyles();

  if (!rows.length && !isLoading)
    return <NoResultsText>{noResultsText}</NoResultsText>;

  return (
    <div>
      {isLoading && <MDSpinner singleColor={theme.colors.main} size={40} />}

      {!isLoading && (
        <Paper className={classes.card}>
          {title && (
            <Toolbar>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
                className={classes.toolbar}
              >
                {title}
              </Typography>
            </Toolbar>
          )}
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.header}>
                  {columns.map((column, i) => (
                    <StyledTableCell key={i}>{column}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <StyledTableRow key={i}>
                    {Object.values(row).map((value, i) => {
                      const Component = value;
                      return (
                        <StyledTableCell key={i} component="th" scope="row">
                          {typeof value === "function" &&
                          isValidElement(<Component />) &&
                          isValidElement(createElement(Component)) ? (
                            <Component />
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
}
