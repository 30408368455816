import React, { Component } from "react";
import notFound from "../images/not-found.png";
import styled from "styled-components";

export const AdditionalImage = styled.img`
  height: 140px;
  width: 140px;
  ${(p) => p.count === 5 && "height: 102px; width: 102px;"}
  ${(p) => p.count === 6 && "height: 80px; width: 80px;"}
  ${(p) => p.count === 7 && "height: 65px; width: 65px;"}
  object-fit: contain;
  border: 1px solid #e8eaec;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    height: 50px;
    width: 50px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
`;

export const ProductCardImageContainer = styled.div`
  position: relative;
`;

class AddImage extends Component {
  state = { error: false };
  render() {
    return (
      <ProductCardImageContainer>
        <AdditionalImage
          count={this.props.count}
          src={this.state.error ? notFound : this.props.image}
          alt="Additional Product Image"
          onMouseEnter={this.props.mouseEnter}
          onError={() => this.setState({ error: true })}
        />
      </ProductCardImageContainer>
    );
  }
}

export default AddImage;
