import mixpanel from "mixpanel-browser";

/*
 * Tracks User event in mixpanel
 * limited to production
 * */
export const trackEvent = (event, email = "", username = "") => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (!environment) {
    return;
  }
  if (environment !== "pro") {
    return;
  }

  mixpanel.init("4a3c60dec34049b7288d9b6aa1c5afa9");
  if (event === "Registration") {
    mixpanel.alias(email);
    mixpanel.people.set({ $email: email, company: username });
  }

  mixpanel.track(event);
};
