import React from "react";
import { get, toNumber } from "lodash";
import {
  countries,
  stateCodeMap,
  countryCodeMap,
  usStates,
  canadaProvinces,
} from "../../../../modules/Config";
import { Form, Spinner } from "../../../../components";
import { getRequest } from "../../../../modules/API";
import { getSettings } from "../../../../Selectors";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

class CustomerAddress extends React.Component {
  state = {
    isCreatingAddress: false,
    shippingAddress: {
      address1: "",
      address2: "",
      city: "",
      company: "",
      country_code: "",
      first_name: "",
      id: 0,
      is_store_address: false,
      last_name: "",
      province_code: "",
      zip: "",
      province: "",
    },
  };

  componentDidMount() {
    if (toNumber(this.props.match.params.id)) {
      this.setState({ isFetching: true }, () => {
        getRequest({ url: `api/addresses/${this.props.match.params.id}` }).then(
          (res) =>
            this.setState({
              shippingAddress: res.data,
              isFetching: false,
            })
        );
      });
    }
  }

  render() {
    const address = this.state.shippingAddress;

    const statesOrProvinces =
      this.state.shippingAddress &&
      this.state.shippingAddress.country_code === "US"
        ? usStates
        : this.state.shippingAddress &&
          this.state.shippingAddress.country_code === "CA"
        ? canadaProvinces
        : [];

    const isValidProvinceCountry = ["US", "CA"].includes(
      get(address, "country_code")
    );

    const hasLength = (value, length) => {
      return !value || (value && value.length < length);
    };

    const addressFormItems = [
      {
        type: "input",
        label: "First Name",
        key: "first_name",
        validation: (value) =>
          hasLength(value, 2) && "Please enter a valid First Name",
      },
      {
        type: "input",
        label: "Last Name",
        key: "last_name",
        validation: (value) =>
          hasLength(value, 2) && "Please enter a valid Last Name",
      },
      {
        type: "input",
        label: "Address 1",
        key: "address1",
        validation: (value) =>
          (hasLength(value, 2) || !/\d/.test(value)) &&
          "Please enter a valid Address",
      },
      {
        type: "input",
        label: "Address 2",
        key: "address2",
      },
      {
        type: "select",
        label: "Country",
        key: "country_code",
        validation: (value) => !value && "Please select a valid Country",
        selectOptions: countries,
        labelMap: countryCodeMap,
      },
      {
        type: "select",
        label: "Province",
        key: "province_code",
        validation: (value) =>
          isValidProvinceCountry &&
          (!value || !statesOrProvinces.some((item) => item.value === value)) &&
          "Please select a valid Province",
        displayRequirement: isValidProvinceCountry,
        selectOptions: statesOrProvinces,
        labelMap: stateCodeMap,
      },
      {
        type: "input",
        label: "Province",
        key: "province",
        displayRequirement: !isValidProvinceCountry,
      },
      {
        type: "input",
        label: "City",
        key: "city",
        validation: (value) =>
          hasLength(value, 2) && "Please enter a valid City",
      },
      {
        type: "input",
        label: "Zip / Postal Code",
        key: "zip",
        validation: (value) =>
          hasLength(value, 2) && "Please enter a valid Zip / Postal Code",
      },
      {
        type: "input",
        label: "Company",
        key: "company",
      },
    ];

    return (
      <div style={{ marginTop: "20px" }}>
        {this.state.isFetching ? (
          <Spinner />
        ) : (
          <Form
            title="Address Details"
            items={addressFormItems}
            isFetching={this.state.isCreatingAddress}
            labelWidth="180px"
            flex={true}
            onChange={(field, value) =>
              this.setState({
                shippingAddress: {
                  ...this.state.shippingAddress,
                  [field]: value,
                },
              })
            }
            action={{
              type: "POST",
              endpoint: "/api/addresses/",
            }}
            onReceiveIdRoute="/settings/addresses/"
            stateLocation={this.state.shippingAddress}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(CustomerAddress);
