import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: white;
  padding: 25px;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;
Container.displayName = "Container";

export const UnselectableText = styled.div`
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;
