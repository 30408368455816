import React from "react";
import { Modal } from "../../../components/Modal";
import {
  ButtonNew,
  Container,
  Row,
  Spinner,
  BackNavBar,
} from "../../../components";
import Text from "../../../modules/Text";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { postRequest, getRequest } from "../../../modules/API";

class VendorDetails extends React.Component {
  state = {
    isFetching: false,
    showDenyModal: false,
  };

  statusToText = (status) => {
    switch (status) {
      case "P":
        return "Access Requested";
      case "A":
        return "Approved";
      case "D":
        return "Denied Access";
      case "C":
        return "Cancelled";
      default:
        return "All";
    }
  };

  componentDidMount() {
    this.setState({ isFetching: true });
    const vendorId = get(this.props, ["match", "params", "vendorId"]);

    getRequest({
      url: `dcvendor/?vendor_id=${vendorId}`,
    })
      .then((response) => {
        this.setState({
          ...response.data,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isFetching: false });
      });
  }

  updateVendorStatus = (status) => {
    this.setState({
      isFetching: true,
      vendor: { ...this.state.vendor, status: status },
    });
    const vendorId = get(this.props, ["match", "params", "vendorId"]);
    postRequest({
      url: "update_vendor_status/",
      data: { vendor_id: vendorId, status },
    })
      .then((response) => {
        this.setState({
          ...response.data,
          isFetching: false,
        });
      })
      .catch((error) => console.log("error", error));
    this.setState({ isFetching: false });
  };

  renderButtons = (status) => {
    if (status === "P") {
      return (
        <Row>
          <ButtonNew
            text="APPROVE"
            onClick={() => this.updateVendorStatus("A")}
            margin="0 10px 0 0"
          />
          <ButtonNew text="DENY" onClick={() => this.updateVendorStatus("D")} />
        </Row>
      );
    } else if (status === "A") {
      return (
        <Row>
          <ButtonNew text="APPROVED" disabled margin="0 10px 0 0" />
          <ButtonNew
            text="REVOKE ACCESS"
            onClick={() => this.updateVendorStatus("D")}
          />
        </Row>
      );
    } else if (status === "D") {
      return (
        <Row>
          <ButtonNew
            text="APPROVE ACCESS"
            onClick={() => this.updateVendorStatus("A")}
            margin="0 10px 0 0"
          />
          <ButtonNew text="DENIED" disabled />
        </Row>
      );
    }
  };

  render() {
    return (
      <Container>
        {this.state.showDenyConfirm && (
          <Modal>
            <Text margin="0 0 10px 0">
              Are you sure you want to remove access from this Vendor?
            </Text>
            <Text margin="0 0 10px 0">
              The vendor will lose access to all your products
            </Text>
            <Row>
              <ButtonNew
                text="CANCEL"
                size="large"
                onClick={() => this.setState({ showDenyModal: false })}
                margin="0 15px 0 0"
              />
              <ButtonNew
                size="large"
                text="DENY ACCESS"
                type="secondary"
                onClick={() => this.updateVendorStatus("D")}
              />
            </Row>
          </Modal>
        )}

        {this.state.isFetching ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <BackNavBar
              title="Vendors"
              onClick={() => this.props.history.goBack()}
            />

            <Text.Large margin="0 0 10px 0">
              {get(this.state, ["vendor", "name"])}
            </Text.Large>

            <Text
              margin="0 0 10px 0"
              link
              onClick={() =>
                window.open(`https://${get(this.state, ["vendor", "url"])}`)
              }
            >
              View Website
            </Text>

            {this.renderButtons(get(this.state, ["vendor", "status"]))}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default withRouter(VendorDetails);
