import { api } from "./Config";
import axios from "axios";
import Cookies from "js-cookie";
import createNotification from "./Notification";
import { get } from "lodash";

function parseError(err) {
  let errorTitle = null;
  let errorMessage = null;
  if (get(err, ["response", "data", "error", "description"])) {
    errorTitle = get(err, ["response", "data", "error", "message"]);
    errorMessage = get(err, ["response", "data", "error", "description"]);
  } else {
    errorTitle = get(err, ["response", "statusText"]);
    errorMessage = get(err, ["response", "data", "detail"]);
  }
  createNotification({
    type: "danger",
    title: errorTitle || "Error",
    message:
      errorMessage ||
      `Uh-oh. An error occurred. Please contact support@dropcommerce.com for help.`,
  });
}

// NEW CHANGE -- Our Django backend sends an entire URL to view next page and previous page.
// Typically, we just provided the endpoint to this "getRequest" function below.

export const getRequest = (data, wholeUrl) => {
  const headers = {
    Authorization: `Token ${
      Cookies.get("adminAccessToken") || Cookies.get("accessToken")
    }`,
    "Content-Type": "application/json",
  };

  let url = data.url;
  let searchParams = null;

  // Get storeId and set it as a query parameter if it exists
  const storeId = Cookies.get("storeId");

  if (storeId) {
    url = data.url.split("?")[0];
    const queryString = data.url.split("?")[1];

    searchParams = new URLSearchParams(queryString);
    if (!searchParams.get("store")) {
      searchParams.set("store", storeId);
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: wholeUrl ? wholeUrl : `${api}/${url}`,
      headers,
      ...(searchParams && !wholeUrl ? { params: searchParams } : {}),
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const deleteRequest = (data) => {
  const headers = {
    Authorization: `Token ${
      Cookies.get("adminAccessToken") || Cookies.get("accessToken")
    }`,
    "Content-Type": "application/json",
  };

  // Get storeId and set it to the request body if it exists
  const storeId = Cookies.get("storeId");

  if (storeId) {
    if (!("store" in data.data)) {
      data.data = { ...data.data, store: storeId };
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: `${api}/${data.url}`,
      data: data.data,
      headers,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const signUpRequest = (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${api}/${data.url}`,
      data: data.data,
      headers,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const postRequest = (data) => {
  const headers = {
    Authorization: `Token ${
      Cookies.get("adminAccessToken") || Cookies.get("accessToken")
    }`,
    "Content-Type": "application/json",
  };

  // Get storeId and set it to the request body if it exists
  const storeId = Cookies.get("storeId");

  if (storeId) {
    if (!("store" in data.data)) {
      data.data = { ...data.data, store: storeId };
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${api}/${data.url}`,
      data: data.data,
      headers,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const postRequestCSV = (data) => {
  const headers = {
    Authorization: `Token ${Cookies.get("accessToken")}`,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      headers,
      method: "POST",
      data: data.data,
      responseType: "blob",
      url: `${api}/${data.url}`,
    })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition !== undefined) {
          const filename = contentDisposition.split("filename=")[1];
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const putRequest = (data) => {
  const headers = {
    Authorization: `Token ${Cookies.get("accessToken")}`,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url: `${api}/${data.url}`,
      data: data.data,
      headers,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};

export const patchRequest = (data) => {
  const headers = {
    Authorization: `Token ${Cookies.get("accessToken")}`,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    axios({
      method: "PATCH",
      url: `${api}/${data.url}`,
      data: data.data,
      headers,
    })
      .then((res) => resolve(res))
      .catch((err) => {
        parseError(err);
        reject(err);
      });
  });
};
