import React from "react";
import MDSpinner from "react-md-spinner";
import Account from "../../views/account/components/AccountComponents";

export function FullScreenSpinner() {
  return (
    <Account title="DropCommerce">
      <MDSpinner singleColor="seagreen" style={{ margin: "32px auto" }} />
    </Account>
  );
}
