import React, { Component } from "react";

import {} from "react-router-dom";
import styled from "styled-components";
import {} from "../../../modules/Theme";
import { Row, BorderBox } from "../../../components";
import Text from "../../../modules/Text";
import Commitment from "./Commitment";

const Label = styled.div`
  font-size: 14px;
  font-weight: 300;
  width: ${(p) => p.width || "80px"};
`;

class ManualOrderSupplier extends Component {
  state = { orderType: "dropship" };
  render() {
    const { supplier } = this.props;

    let products = supplier.products && supplier.products;

    return (
      <BorderBox margin="20px 0 0 0" key={supplier.id}>
        <Text margin="0 0 15px 0">{supplier.name}</Text>

        <Row style={{ marginTop: "15px" }}>
          <Label width="70px">Image</Label>
          <Label width="180px">Title</Label>
          <Label width="150px">Variant</Label>
          <Label width="140px">Price Dropship</Label>

          <Label light width="150px">
            Quantity
          </Label>
          <Label>Item Total</Label>
        </Row>
        {products.length === 0 && (
          <Text.Small margin="10px 0 0 0">
            Products ineligible for Wholesale have been hidden
          </Text.Small>
        )}
        {products.map((product) => (
          <Commitment
            key={product.variant_id}
            data={product}
            delete={(itemId) => this.props.deleteCartItem(itemId, supplier.id)}
            updateQuantity={(qty) =>
              this.props.updateQuantity(supplier.id, product.id, qty)
            }
          />
        ))}
      </BorderBox>
    );
  }
}

export default ManualOrderSupplier;
