import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { TableToolbar } from "../../components/TableToolbar";
import { SupplierDetails } from "./SupplierDetails";
import { SupplierRow } from "./SupplierRow";
import { TablePaginationActions } from "./TablePaginationActions";

export function SuppliersTable({ suppliers }) {
  const [searchTerm, setSearchTerm] = useState("");

  const trimmedSearch = searchTerm.trim().toLowerCase();

  let filteredSuppliers = suppliers;

  // Check if the search string is empty
  if (trimmedSearch !== "") {
    filteredSuppliers = filteredSuppliers.filter((supplier) =>
      supplier.name.toLowerCase().includes(trimmedSearch)
    );
  }

  const handleChangeSearch = (newSearch) => {
    setSearchTerm(newSearch);
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredSuppliers.length)
      : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [activeSupplier, setActiveSupplier] = useState(null);

  const drawerMarkup = (
    <SupplierDetails
      activeSupplier={activeSupplier}
      onCloseDrawer={() => setActiveSupplier(null)}
    />
  );

  const sx = {
    display: { xs: "none", md: "table-cell" },
  };

  return (
    <>
      {drawerMarkup}
      <Box sx={{ width: "100%" }}>
        <TableToolbar
          title="Late orders"
          search={searchTerm}
          onSearchChange={handleChangeSearch}
        />
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Supplier</TableCell>
                <TableCell sx={sx}>Processing Time</TableCell>
                <TableCell sx={sx}>Email</TableCell>
                <TableCell sx={sx}>Store URL</TableCell>
                <TableCell sx={sx}>Platform</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredSuppliers.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredSuppliers
              ).map((supplier) => (
                <SupplierRow
                  key={supplier.id}
                  supplier={supplier}
                  onOpenDrawer={() => setActiveSupplier(supplier)}
                />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    1,
                    2,
                    7,
                    14,
                    25,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={5}
                  count={filteredSuppliers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
