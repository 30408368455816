import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { Column } from "../components";
import { PrebuiltStoreCard } from "./components/PrebuiltStoreCard";
import { getRequest } from "../modules/API";
import Cookies from "js-cookie";
import ButtonNew from "../components/ButtonNew";
import MDSpinner from "react-md-spinner";
import { Show } from "../components/Show";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const MyStores = (props) => {
  const [myStores, setMyStores] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getRequest({
      url: `prebuilt/purchases/`,
    })
      .then((response) => {
        setMyStores(response.data);
        setIsFetching(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Cookies.remove("accessToken");
          setIsFetching(false);
        }
      });
  }, []);

  return (
    <Container>
      <Show
        when={!isFetching}
        fallback={<MDSpinner singleColor={theme.colors.main} size={50} />}
      >
        <Show
          when={myStores.length}
          fallback={
            <>
              <Text center bold extra="font-size: 24px;" margin="0 0 20px 0">
                You don't have any prebuilt stores yet
              </Text>

              <Text center bold extra="font-size: 16px;" margin="0 0 20px 0">
                Check out our available stores
              </Text>

              <ButtonNew
                text="Available Stores"
                extra=""
                onClick={() => props.history.push("/dashboard")}
              />
            </>
          }
        >
          <Text center bold extra="font-size: 35px;" margin="0 0 20px 0">
            My Stores
          </Text>

          <Column gap="20px">
            {myStores.map((myStore, i) => (
              <PrebuiltStoreCard key={i} store={myStore} />
            ))}
          </Column>
        </Show>
      </Show>
    </Container>
  );
};

export default MyStores;
