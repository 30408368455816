import React, { Component } from "react";

import leftCaret from "../images/icons/feather/caret-left.svg";
import leftCaretWhite from "../images/caret-left-white.png";
import styled from "styled-components";
import { theme } from "../modules/Theme";

export const TitlebarBackArrow = styled.img`
  height: 12px;
  opacity: 0.4;
  margin-right: 3px;
`;

export const TitlebarText = styled.div`
  font-size: 14px;
  transition: 0.3s;
  font-weight: 400;
  color: ${(props) => (props.white ? "white" : theme.colors.darkGrey)};
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

class BackNavBar extends Component {
  render() {
    const { white } = this.props;
    return (
      <Container>
        <TitlebarBackArrow
          alt="back arrow"
          src={white ? leftCaretWhite : leftCaret}
        />
        <TitlebarText white={white} onClick={this.props.onClick}>
          {this.props.title}
        </TitlebarText>
      </Container>
    );
  }
}

export default BackNavBar;
