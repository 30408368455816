import React from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";

const Wrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
`;

const Content = styled("div")`
  display: inline-block;
  min-width: 20px;
  border-radius: 20px;
  text-align: center;
  color: rgb(255, 255, 255);
  ${(props) => props.singleColor && `background-color: ${props.singleColor};`}
  ${(props) =>
    props.gradient &&
    `background: linear-gradient(90deg, ${props.gradient.startingColor}, ${props.gradient.finishingColor});`}
  transition: background 100ms linear 0s;
  position: relative;
  margin: 0px;
  padding: 0px 6px;
  line-height: 16px;
  height: 16px;
  font-weight: normal;
  font-size: 11px;
  vertical-align: middle;
`;

export function Badge({ text, singleColor, gradient }) {
  return (
    <Wrapper>
      <Content
        singleColor={singleColor}
        gradient={{
          startingColor: gradient.startingColor,
          finishingColor: gradient.finishingColor,
        }}
      >
        {text}
      </Content>
    </Wrapper>
  );
}

const DotWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const Dot = styled("div")`
  display: inline-block;
  position: relative;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: ${(props) => props.backgroundColor || theme.colors.white};
`;

function NotificationDot({ backgroundColor }) {
  return (
    <DotWrapper>
      <Dot backgroundColor={backgroundColor} />
    </DotWrapper>
  );
}

Badge.NotificationDot = NotificationDot;
