import React, { Fragment } from "react";
import styled from "styled-components";

import { Column, OptionBubbles } from "../../components";
import Text from "../../modules/Text";
import { theme } from "../../modules/Theme";

const StoreNameText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  margin-top: 30px;
  margin-bottom: 10px;
`;

const StoreNameInput = styled.input`
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 300px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const PrebuiltDetailSelection = (props) => {
  return (
    <Fragment>
      <StoreNameText>Select Your Package:</StoreNameText>
      <OptionBubbles
        options={[
          {
            label: `3 Month Package - $${props.priceByMonth[3]}`,
            description:
              "Prebuilt Shopify Store + 3 months of DropCommerce Pro (valued at $249.00)",
            selected: props.packageDetails.extraPrebuiltMonths === 0,
            onClick: () =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ extraPrebuiltMonths: 0 },
              })),
          },
          {
            label: `6 Month Package - $${props.priceByMonth[6]}`,
            description:
              "Prebuilt Shopify Store + 6 months of DropCommerce Pro (valued at $379)",
            selected: props.packageDetails.extraPrebuiltMonths === 3,
            onClick: () =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ extraPrebuiltMonths: 3 },
              })),
          },
          {
            label: `12 Month Package - $${props.priceByMonth[12]}`,
            description:
              "Prebuilt Shopify Store + 12 months of DropCommerce Pro (valued at $499)",
            selected: props.packageDetails.extraPrebuiltMonths === 9,
            onClick: () =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ extraPrebuiltMonths: 9 },
              })),
          },
        ]}
      />
      <StoreNameText>Choose Setup Type:</StoreNameText>
      <OptionBubbles
        options={[
          {
            label: "I Want To Upgrade My Existing Shopify Store",
            description:
              "Our team will upgrade your existing Shopify store with your chosen theme",
            selected: props.packageDetails.useCurrentStore,
            onClick: () =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ useCurrentStore: true },
              })),
          },
          {
            label: "I Want A Brand New Shopify Store",
            description:
              "Our team will setup a brand new Shopify account with your chosen theme",
            selected: !props.packageDetails.useCurrentStore,
            onClick: () =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ useCurrentStore: false },
              })),
          },
        ]}
      />
      {props.packageDetails.useCurrentStore && (
        <React.Fragment>
          <StoreNameText>Confirm Your Store URL:</StoreNameText>
          {props.packageDetails.storeURL &&
            props.packageDetails.storeURL.length > 0 &&
            props.packageDetails.storeURL.length < 4 && (
              <Text.Small light red>
                Please enter a valid URL{" "}
              </Text.Small>
            )}
          <StoreNameInput
            onChange={(e) =>
              props.setPackageDetails((currentPackageDetails) => ({
                ...currentPackageDetails,
                ...{ storeURL: e.target.value },
              }))
            }
            value={props.packageDetails.storeURL}
            placeholder={props.packageDetails.storeURL}
          />
        </React.Fragment>
      )}
      {!props.packageDetails.useCurrentStore && (
        <React.Fragment>
          <Column>
            <StoreNameText>Desired New Store Name:</StoreNameText>
            {props.packageDetails.storeName.length > 0 &&
              props.packageDetails.storeName.length < 4 && (
                <Text.Small light red>
                  Please enter a valid store name
                </Text.Small>
              )}
            <StoreNameInput
              onChange={(e) =>
                props.setPackageDetails((currentPackageDetails) => ({
                  ...currentPackageDetails,
                  ...{ storeName: e.target.value },
                }))
              }
              value={props.packageDetails.storeName}
              placeholder="Store Name"
            />
          </Column>
          <Column>
            <StoreNameText>
              Email ID to be associated with this store:
            </StoreNameText>
            {props.packageDetails.storeEmail.length > 0 &&
              (!props.packageDetails.storeEmail.includes("@") ||
                !props.packageDetails.storeEmail.includes(".")) && (
                <Text.Small light red>
                  Please enter a valid email address
                </Text.Small>
              )}
            <StoreNameInput
              onChange={(e) =>
                props.setPackageDetails((currentPackageDetails) => ({
                  ...currentPackageDetails,
                  ...{ storeEmail: e.target.value },
                }))
              }
              value={props.packageDetails.storeEmail}
              placeholder="Email id"
            />
          </Column>
        </React.Fragment>
      )}
    </Fragment>
  );
};

export default PrebuiltDetailSelection;
