import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { Row } from "../../../components/Layout";
import { MdSend } from "react-icons/md";

const InputWrapper = styled.form`
  border-top: 1px solid ${theme.colors.lightGrey};
`;

const InputBar = styled.input`
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: 100%;
  border-radius: 16px;
  border: none;
  background-color: ${theme.colors.lightGrey};

  &:focus {
    outline: 1px solid ${theme.colors.main};
  }
`;

const InputSend = styled.button`
  border: none;
  border-radius: 50%;
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-left: 5px;
  background-color: ${theme.colors.main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

class Input extends Component {
  render() {
    const { setText, sendMessage, text } = this.props;

    return (
      <InputWrapper>
        <Row
          style={{
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <InputBar
            color={theme.colors.lightGrey}
            type="text"
            value={text}
            placeholder="Type a message..."
            onChange={(event) => setText(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                sendMessage(event);
              }
            }}
          />

          <InputSend
            onClick={(event) => {
              sendMessage(event);
            }}
          >
            <MdSend size="22" color={theme.colors.white} />
          </InputSend>
        </Row>
      </InputWrapper>
    );
  }
}

export default Input;
