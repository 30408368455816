import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../modules/Theme";
import Label from "./Label";

const LabelContainer = styled.div`
  margin-left: ${(p) => (p.marginLeft ? "10px" : "0px")};
  margin-right: ${(p) => (p.marginRight ? "20px" : "0px")};
  width: ${(p) => p.width || "100%"};
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none"};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "300px")};
  }
`;

const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

const styles = (p) => `
  ${p.disabled && "cursor: not-allowed"};
  background: ${p.disabled ? theme.colors.lightGrey : theme.colors.white};
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  font-size: 16px;
  ${
    p.small &&
    `
    font-size: 14px;
    padding: 8px;
    margin: 0px;
  `
  }
  ${p.extraStyle};
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
  ::placeholder {
    color: ${theme.colors.medGrey};
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`;

export const StyledInput = styled("input")`
  ${(props) => styles(props)}
`;

const StyledTextarea = styled("textarea")`
  ${(props) => styles(props)}
  height: 120px;
  resize: none;
`;

const InputError = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #c12119;
  text-align: left;
  width: 100%;
`;

const InputWarning = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #ef9f09;
`;

class Input extends Component {
  render() {
    const { marginLeft, marginRight, width, labelStyle } = this.props;

    return (
      <LabelContainer
        marginLeft={marginLeft}
        marginRight={marginRight}
        width={width}
        maxWidth={this.props.maxWidth}
        canHide={this.props.canHide}
      >
        {this.props.label && (
          <Label
            hint={this.props.hint}
            warning={this.props.hintWarning}
            extra={labelStyle}
          >
            {this.props.label}
          </Label>
        )}

        {this.props.description && (
          <InputDescription>{this.props.description}</InputDescription>
        )}

        {React.createElement(
          this.props.textarea ? StyledTextarea : StyledInput,
          {
            min: this.props.min,
            maxWidth: this.props.maxWidth,
            small: this.props.small,
            type: this.props.type || "text",
            className: this.props.className,
            style: { border: this.props.error && "1px solid #c12119" },
            onChange: this.props.onChange,
            onKeyPress: this.props.onKeyPress,
            value: this.props.value,
            placeholder: this.props.placeholder,
            extraStyle: this.props.extraStyle,
            maxLength: this.props.maxLength || null,
            disabled: this.props.disabled,
            autoFocus: this.props.autoFocus,
            name: this.props.name,
          }
        )}

        {this.props.error && <InputError>{this.props.error}</InputError>}

        {this.props.warning && (
          <InputWarning>{this.props.warning}</InputWarning>
        )}
      </LabelContainer>
    );
  }
}

export default Input;
