import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import caretLeft from "../images/icons/feather/caret-left.svg";
import { get } from "lodash";
import newCategories from "../modules/NewGoogleCategories";

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  border: 1px solid ${theme.colors.lightBorder};
  margin-right: 20px;
  @media (max-width: 1200px) {
    width: 180px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

const CategoryText = styled.div`
  font-size: 15px;
  font-weight: ${(p) => (p.active ? "500" : "300")};
  width: 100%;
  color: ${(p) => (p.active ? theme.colors.main : theme.colors.medDarkGrey)};
  transition: 300ms;
  @media (max-width: 1200px) {
    font-size: 15px;
  }
`;

// const CountText = styled.div`
//   font-size: 13px;
//   font-weight: 500;
//   color: ${theme.colors.medGrey};
//   opacity: 0.7;
//   margin-left: 10px;
// `;

const CategoryWrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  &:hover ${CategoryText} {
    color: ${theme.colors.medDarkGrey};
  }
`;

const LeftArrow = styled.img`
  height: 15px;
  opacity: 0.3;
  margin-right: 3px;
`;

const CategoryOptions = styled.div`
  padding: 10px;
  padding-top: 0px;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

const Header = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  ${(p) => p.onClick && "cursor: pointer;"}
  &:hover ${HeaderText} {
    color: ${theme.colors.medDarkGrey};
  }
  &:hover ${LeftArrow} {
    opacity: 0.6;
  }
`;

export const allCategories = {
  name: "All",
  subs: {
    beauty_health: {
      name: "Beauty & Health",
      type: "category",
      subs: {
        beauty_health: { name: "All" },
        makeup: { name: "Makeup" },
        haircare: { name: "Haircare" },
        perfume: { name: "Perfume" },
        tools: { name: "Tools" },
        bath_body: { name: "Bath & Body" },
        skincare: { name: "Skincare" },
        misc: { name: "Miscellaneous" },
      },
    },
    clothing_mens: {
      name: "Men's Clothing",
      type: "category",
      subs: {
        clothing_mens: { name: "All" },
        t_shirts: { name: "Shirts" },
        sweatshirts_hoodies: { name: "Sweatshirts & Hoodies" },
        jackets: { name: "Jackets" },
        shorts: { name: "Shorts & Pants" },
        mens_hat: { name: "Hats" },
        mens_shoes: { name: "Footwear" },
        mens_sleepwear: { name: "Sleepwear" },
        misc: { name: "Miscellaneous" },
      },
    },
    gifts: { name: "Gifts & Misc", type: "category", subs: {} },
    tech_access: {
      name: "Tech & Accessories",
      type: "category",
      subs: {
        tech_access: { name: "All" },
        phone_access: { name: "Phone Accessories" },
        solar_panels: { name: "Solar Panels" },
        misc: { name: "Miscellaneous" },
      },
    },
    pets: {
      name: "Pets",
      type: "category",
      subs: {
        pets: { name: "All" },
        treats: { name: "Treats & Feeding" },
        pet_toys: { name: "Pet Toys" },
        pet_apparel: { name: "Apparel & Leashes" },
        pet_decor: { name: "Decor" },
        misc: { name: "Miscellaneous" },
      },
    },
    home_garden: {
      name: "Home & Garden",
      type: "category",
      subs: {
        home_garden: { name: "All" },
        zero_waste: { name: "Zero Waste" },
        candles: { name: "Candles" },
        bedding_pillows_rugs: { name: "Bedding, Pillows & Rugs" },
        room_decor: { name: "Room Decor" },
        kitchen: { name: "Kitchen" },
        celebrations: { name: "Celebrations" },
        mirrors_art: { name: "Mirrors & Wall Art" },
        cleaning_supplies: { name: "Cleaning Products & Tools" },
        misc: { name: "Miscellaneous" },
      },
    },
    music: { name: "Music", type: "category", subs: {} },
    wellness: {
      name: "Wellness",
      type: "category",
      subs: {
        wellness: { name: "All" },
        vitamins_supplements: { name: "Vitamins & Supplements" },
        aromatherapy: { name: "Aromatherapy" },
        personal_care: { name: "Personal Care" },
        yoga_well: { name: "Yoga" },
        soap: { name: "Soap" },
        meditation: { name: "Meditation" },
        misc: { name: "Miscellaneous" },
      },
    },
    outdoor: {
      name: "Outdoor",
      type: "category",
      subs: {
        outdoor: { name: "All" },
        gardening: { name: "Gardening" },
        sports_outside: { name: "Sports & Equipment" },
        swimwear_outside: { name: "Swimwear" },
        activewear_outside: { name: "Activewear" },
        misc: { name: "Miscellaneous" },
      },
    },
    jewelry: {
      name: "Jewelry",
      type: "category",
      subs: {
        jewelry: { name: "All" },
        necklaces: { name: "Necklaces" },
        charms: { name: "Charms" },
        earrings: { name: "Earrings" },
        hair: { name: "Hair" },
        finger_rings: { name: "Rings" },
        bracelets: { name: "Bracelets" },
        misc: { name: "Miscellaneous" },
      },
    },
    womens_clothing: {
      name: "Women's Clothing",
      type: "category",
      subs: {
        womens_clothing: { name: "All" },
        women_jumpsuits: { name: "Jumpsuits" },
        dresses: { name: "Dresses" },
        jackets_coats: { name: "Jackets & Coats" },
        pants: { name: "Pants & Leggings" },
        shorts_skirts: { name: "Shorts & Skirts" },
        tops: { name: "Tops & Shirts" },
        women_hats: { name: "Hats" },
        athletic_women: { name: "Sports & Fitness" },
        women_swimwear: { name: "Swimwear" },
        women_sweaters: { name: "Sweaters & Hoodies" },
        women_shoes: { name: "Shoes" },
        women_undies: { name: "Underwear" },
        women_sleepwear: { name: "Sleepwear" },
        misc: { name: "Miscellaneous" },
      },
    },
    party_event: {
      name: "Party & Event",
      type: "category",
      subs: {
        all: { name: "All" },
        formal: { name: "Formal Wear" },
        planning: { name: "Event Planning & Gifts" },
        misc: { name: "Miscellaneous" },
      },
    },
    sports_fitness: {
      name: "Sports & Fitness",
      type: "category",
      subs: {
        sports_fitness: { name: "All" },
        sport_equipment: { name: "Sports Equipment & Accessories" },
        active_clothes: { name: "Active Wear" },
        active_yoga: { name: "Yoga Accessories" },
        misc: { name: "Miscellaneous" },
      },
    },
    mother_kids: {
      name: "Parenting & Kids",
      type: "category",
      subs: {
        all: { name: "All" },
        nursery: { name: "Nursery" },
        apparel_access: { name: "Apparel & Accessories" },
        human_toys: { name: "Toys" },
        misc: { name: "Miscellaneous" },
      },
    },
    accessories: {
      name: "Accessories",
      type: "category",
      subs: {
        all: { name: "All" },
        keychains: { name: "Keychains" },
        socks_gloves: { name: "Socks & Gloves" },
        eyewear: { name: "Eyewear" },
        wallets_access: { name: "Wallets" },
        watch_access: { name: "Watches" },
        hats_access: { name: "Hats & Headwear" },
        jewel_access: { name: "Jewelry" },
        bags_access: { name: "Bags" },
        belts_access: { name: "Belts" },
        scarves_access: { name: "Scarves" },
        misc: { name: "Miscellaneous" },
      },
    },
    bags_category: {
      name: "Bags & Luggage",
      type: "category",
      subs: {
        bags_category: { name: "All" },
        purses_clutches: { name: "Purses & Clutches" },
        totes: { name: "Totes" },
        crossbody: { name: "Crossbody" },
        travel_luggage: { name: "Travel & Luggage" },
        cosmetic_bags: { name: "Cosmetic bags" },
        backpacks: { name: "Backpacks" },
        bag_wallets: { name: "Wallets" },
        misc: { name: "Miscellaneous" },
      },
    },
    food_drink: {
      name: "Food & Beverage",
      type: "category",
      subs: {
        food_drink: { name: "All" },
        tea: { name: "Tea" },
        coffee: { name: "Coffee" },
        edible: { name: "Food" },
        beverages: { name: "Beverages" },
        misc: { name: "Miscellaneous" },
      },
    },
    stationery: {
      name: "Stationery",
      type: "category",
      subs: {
        stationery: { name: "All" },
        stickers: { name: "Stickers" },
        writing: { name: "Notebooks & Pens" },
        cards: { name: "Greeting Cards" },
        misc: { name: "Miscellaneous" },
      },
    },
    watches: { name: "Watches", type: "category", subs: {} },
  },
};

const Category = ({ name, active, onClick, count }) => (
  <CategoryWrapper active={active} onClick={onClick}>
    <CategoryText active={active}>{name}</CategoryText>
    {/* {count && <CountText active={active}>{count}</CountText>} */}
  </CategoryWrapper>
);

class CategoriesNew extends Component {
  render() {
    let currentCategory = newCategories;
    // let matchingSubcategory = null;

    if (this.props.category) {
      const matchingCategory = get(newCategories, [
        "subs",
        this.props.category,
      ]);

      if (matchingCategory) {
        currentCategory = matchingCategory;

        // matchingSubcategory = get(matchingCategory, [
        //   "subs",
        //   this.props.subcategory,
        // ]);
      }
    }

    const categoryName = get(currentCategory, "name");

    return (
      <CategoriesContainer>
        <Header
          onClick={
            currentCategory ? () => this.props.clearCategory("category") : null
          }
        >
          {categoryName && categoryName !== "All" && (
            <LeftArrow src={caretLeft} />
          )}
          <HeaderText>
            {(categoryName &&
              categoryName !== "All" &&
              categoryName.toUpperCase()) ||
              "CATEGORY"}
          </HeaderText>
        </Header>
        <CategoryOptions>
          {Object.keys(currentCategory.subs)
            .sort((a, b) => a.value - b.value)
            .map((category) => {
              return (
                <Category
                  key={currentCategory.subs[category].name}
                  name={currentCategory.subs[category].name}
                  active={this.props.subcategory === category}
                  onClick={() =>
                    this.props.setCategory(
                      category,
                      currentCategory.subs[category].type
                        ? "category"
                        : "subcategory"
                    )
                  }
                />
              );
            })}
          <Category
            key="other"
            name="Other"
            active={this.props.subcategory === "other"}
            onClick={() =>
              this.props.setCategory(
                "other",
                currentCategory.subs.type ? "category" : "subcategory"
              )
            }
          />
        </CategoryOptions>
      </CategoriesContainer>
    );
  }
}

export default CategoriesNew;
