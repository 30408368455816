import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { postRequest } from "../../modules/API";
import createNotification from "../../modules/Notification";

import CartOption from "./CartOption";
import ButtonNew from "../../components/ButtonNew";
import { withRouter } from "react-router-dom";

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  flex-direction: column;
  z-index: 99999;
  cursor: auto;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  background: ${theme.colors.lightBorder};
  width: 700px;
  height: 100%;
  margin: 20px;
  padding: 50px;
  border-radius: 4px;
  overflow-y: auto;
  @media screen and (max-width: 1260px) {
    width: 100%;
    margin: 5px;
  }
  @media screen and (max-width: 1260px) {
    padding: 20px;
  }
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
`;

export const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
`;

export const Disclaimer = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 30px;
`;

export const RequestButton = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
  padding: 20px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  ${(p) =>
    p.hollow &&
    `
    margin-right: 15px;
    border: 2px solid #485d5a;
    color: #485d5a;
  `}
  ${(p) =>
    !p.hollow &&
    `
    background: #39a698;
  `}
  &:hover {
    background: #485d5a;
    ${(p) => p.hollow && "color: white;"}
  }
  @media (max-width: 800px) {
    padding: 12px;
    font-size: 18px;
  }
`;

export const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
`;

class AddToCartModal extends Component {
  state = {
    variantsInCart: this.props.product.variants.filter(
      (variant) => variant.in_sample_cart
    ), // we will update this array
    // when the user clicks on addToCart button for any other variants
  };

  addToCart = (productId, variantId, variant) => {
    this.setState({
      variantsInCart: [...this.state.variantsInCart, variant],
    });

    postRequest({
      url: `cart_item/`,
      data: {
        product: productId,
        variant_relation: variantId,
        quantity: 1,
      },
    })
      .then((res) => {
        createNotification({
          type: "success",
          title: "Success",
          message: `Variant added to cart`,
        });
        this.setState({ isFetching: false });
      })
      .catch((err) =>
        createNotification({
          type: "danger",
          title: "Error",
          message: err,
        })
      );
  };

  render() {
    const { product, hideModal } = this.props;

    const currency = product.supplier.currency;

    return (
      <PageWrapper>
        <Container>
          <div style={{ width: "100%" }}>
            <React.Fragment>
              <Title style={{ marginTop: "30px" }}>Order Product Samples</Title>
              <Subtitle style={{ marginBottom: "30px" }}>
                Please select which variants you would like to order samples of.
              </Subtitle>
            </React.Fragment>

            <ButtonsRow>
              <ButtonNew
                extra="padding: 20px;"
                fontStyle="font-size: 20px;"
                onClick={hideModal}
                type="main-light"
                text="CLOSE WINDOW"
              />
              <ButtonNew
                extra="padding: 20px; margin-left: 15px;"
                fontStyle="font-size: 20px;"
                onClick={() => this.props.history.push("/order/new")}
                type="main"
                text="VIEW CART"
              />
            </ButtonsRow>

            {product.variants.map((variant) => {
              if (variant.quantity > 0) {
                const retailPrice = `$${variant.retail_price.original_amount.toFixed(
                  2
                )} ${currency}`;

                return (
                  <CartOption
                    key={variant.id}
                    // sampleOrdered={variant.sample_ordered}
                    isFetching={
                      this.state.variantsInCart[
                        this.state.variantsInCart.length - 1
                      ] === variant && this.state.isFetching
                    }
                    inCart={this.state.variantsInCart.includes(variant)}
                    viewCart={() => this.props.history.push("/order/new")}
                    title={variant.name}
                    price={`$${variant.price.original_amount.toFixed(
                      2
                    )} ${currency}`}
                    retailPrice={retailPrice}
                    onClick={() => {
                      this.setState({ isFetching: true });
                      this.addToCart(product.id, variant.id, variant);
                    }}
                  />
                );
              } else return null;
            })}
          </div>
        </Container>
      </PageWrapper>
    );
  }
}

export default withRouter(AddToCartModal);
