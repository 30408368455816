import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export function Calendar(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        {props.items.map((item, index) => {
          return (
            <DesktopDatePicker
              key={index}
              label={item.label}
              inputFormat={item.inputFormat}
              value={item.value}
              onChange={item.onChange}
              renderInput={(params) => <TextField {...params} />}
            />
          );
        })}
      </Stack>
    </LocalizationProvider>
  );
}
