import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../../../modules/Theme";

export const InputLabel = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${theme.colors.medGrey};
`;

export const StyledProductInput = styled.input`
  background: ${theme.colors.white};
  padding: ${(props) => props.padding || "15px;"};
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: ${(props) => props.fontSize || "18px;"};
  width: ${(props) => props.width || "300px;"};
  margin-right: 20px;
`;

class ProductInput extends Component {
  render() {
    const { width, padding, fontSize, type, value, onChange, marginRight } =
      this.props;
    return (
      <div style={{ width: "100%", marginRight: marginRight || "0px" }}>
        <InputLabel>{this.props.label}</InputLabel>
        <StyledProductInput
          width={width}
          padding={padding}
          fontSize={fontSize}
          type={type || "text"}
          value={value}
          onChange={onChange}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default ProductInput;
