import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import Select from "react-select";
import { Column, Row } from "../../../components";
import { Button } from "@material-ui/core";
import { getRequest, postRequest } from "../../../modules/API";
import styled from "styled-components";
import { Modal } from "../../../components/Modal";
import MDSpinner from "react-md-spinner";
import { get } from "lodash";
import { history } from "../../../BaseApp";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { theme } from "../../../modules/Theme";

// TODO: move these 2 to a common place and import it from there
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

export const Demerit = styled.span`
  border-radius: 4px;
  padding: 8px;
  color: white;
  margin: 5px;
  min-width: 0px;
  background: ${theme.colors.darkRed};
`;

/*
 * Renders the Supplier Stats table in the Admin Dashboard
 * API Endpoint: /api/supplier-stats/
 * URL: /admin/supplier-stats
 * */
export default class SupplierStats extends React.Component {
  state = {
    timeFrame: "ALL_TIME",
    ordering: null,
    fetching: true,
    data: { results: [] },
    demerit_type: "",
    points: null,
    selectedSupplierID: false,
    selectedSupplierManualDemerits: false,
  };

  columnNames = {
    supplier_id: "ID",
    name: "Name",
    supplier_since: "Supplier Since",
    product_count: "Products",
    import_count: "Imports",
    mean_product_margin: "Ave. Product Margin (%)",
    rating_count: "Ratings",
    mean_rating: "Ave. Rating",
    shipped_orders: "Orders Shipped",
    processing_time: "Processing Time",
    shipments_delayed_count: "Shipments Delayed",
    normalized_total_time_delayed: "Time Delayed",
    conversations_ignored: "Conversations Ignored",
    conversations_replied: "Conversations Replied",
    reply_rate: "Reply Rate",
    mean_reply_time: "Ave. Time To Reply",
  };

  componentDidMount() {
    const fetchUrl = this.props.location.search.includes("page")
      ? `${api}/api/supplier-stats/${this.props.location.search}`
      : `${api}/api/supplier-stats/`;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data, fetching: false }))
      .catch((error) => console.log(error));
  }

  nextPage() {
    this.setState({ fetching: true });
    getRequest({}, this.state.data.next).then((res) => {
      this.setState({
        data: res.data,
        selectedSupplierID: false,
        selectedSupplierManualDemerits: false,
        fetching: false,
      });
      history.push(
        this.state.ordering
          ? `?time_frame=${this.state.timeFrame}&ordering=${this.state.ordering}&page=${this.state.data.page}`
          : `?time_frame=${this.state.timeFrame}&page=${this.state.data.page}`
      );
    });
  }

  prevPage() {
    getRequest({}, this.state.data.previous).then((res) =>
      this.setState({
        data: res.data,
        selectedSupplierID: false,
        selectedSupplierManualDemerits: false,
        fetching: false,
      })
    );
  }

  search(term) {
    getRequest({
      url: `api/supplier-stats/?time_frame=${this.state.timeFrame}&search=${term}&ordering=${this.state.ordering}`,
    }).then((res) => {
      this.setState({ data: res.data, ordering: null });
      history.push(
        `?time_frame=${this.state.timeFrame}&search=${term}&ordering=${this.state.ordering}`
      );
    });
  }

  setTimeFrame(timeFrame) {
    getRequest({
      url: `api/supplier-stats/?time_frame=${timeFrame}&ordering=${this.state.ordering}`,
    }).then((res) => {
      this.setState({ data: res.data, timeFrame: timeFrame });

      history.push(`?time_frame=${this.state.timeFrame}`);
    });
  }

  applyDemerit() {
    postRequest({
      url: `api/supplier-stats/${this.state.selectedSupplierID}/demerit/`,
      data: {
        points: Number(this.state.points),
        reason: this.state.demerit_type,
      },
    });
  }

  sortBy(column) {
    if (this.state.ordering === column) {
      this.setState({ sortingSearching: true, ordering: `-${column}` });
      history.push(`?ordering=${column}&page=${this.state.data.page}`);
      getRequest({
        url: `api/supplier-stats/?time_frame=${this.state.timeFrame}&ordering=-${column}&page=${this.state.data.page}`,
      }).then((res) =>
        this.setState({
          sortingSearching: false,
          data: res.data,
        })
      );
    } else {
      this.setState({ sortingSearching: true, ordering: column });
      history.push(
        `?time_frame=${this.state.timeFrame}&ordering=${column}&page=${this.state.data.page}`
      );
      getRequest({
        url: `api/supplier-stats/?time_frame=${this.state.timeFrame}&ordering=${column}&page=${this.state.data.page}`,
      }).then((res) =>
        this.setState({
          sortingSearching: false,
          ordering: column,
          data: res.data,
        })
      );
    }
  }

  render() {
    const durationOptions = [
      {
        value: "WEEK",
        label: "Past Week",
      },
      {
        value: "MONTH",
        label: "Past Month",
      },
      {
        value: "3_MONTH",
        label: "Past Three Months",
      },
      {
        value: "YEAR",
        label: "Past Year",
      },
      {
        value: "ALL_TIME",
        label: "All Time",
      },
    ];
    let orderingArrow;
    if (this.state.ordering && this.state.ordering[0] === "-") {
      orderingArrow = <FiArrowDown size="18" />;
    } else orderingArrow = <FiArrowUp size="18" />;

    return (
      <React.Fragment>
        <h2>Supplier Stats</h2>
        <Row align style={{ justifyContent: "space-between" }}>
          <StyledInput
            type="text"
            placeholder="Search Suppliers by ID or Name"
            onKeyUp={(e) => e.key === "Enter" && this.search(e.target.value)}
          />
          <div style={{ width: "200px" }}>
            <Select
              options={durationOptions}
              onChange={(selected) => this.setTimeFrame(selected.value)}
            />
          </div>
        </Row>
        {this.state.sortingSearching && <MDSpinner />}
        <TableContainer
          component={Paper}
          style={{ marginBottom: "15px", overflow: "auto" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(this.columnNames).map((column, i) => (
                  <StyledTableCell key={i} onClick={() => this.sortBy(column)}>
                    {this.columnNames[column] + " "}
                    {(this.state.ordering === column ||
                      this.state.ordering === "-" + column) &&
                      orderingArrow}
                  </StyledTableCell>
                ))}
                <StyledTableCell style={{ cursor: "auto" }}>
                  Demerit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {get(this, ["state", "data", "results"], []).map((datum, i) => (
                <StyledTableRow
                  key={i}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      datum.supplier_id === this.state.selectedSupplierID
                        ? "rgba(0,0,0,0.05)"
                        : null,
                  }}
                  onClick={() =>
                    this.setState({
                      selectedSupplierID: datum.supplier_id,
                      selectedSupplierManualDemerits: datum.manual_demerits,
                    })
                  }
                >
                  {Object.keys(this.columnNames).map((column, i) => {
                    return (
                      <StyledTableCell key={i}>{datum[column]}</StyledTableCell>
                    );
                  })}
                  <StyledTableCell>
                    <Button
                      onClick={() =>
                        this.setState({
                          selectedSupplierID: datum.supplier_id,
                          selectedSupplierManualDemerits: datum.manual_demerits,
                          modal: true,
                        })
                      }
                    >
                      {datum.manual_demerits.length !== 0
                        ? datum.manual_demerits.reduce(
                            (p0, d) => p0 + d.points,
                            0
                          ) + " Edit"
                        : "Add"}{" "}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Row>
          {this.state.data.previous && (
            <Button onClick={() => this.prevPage()}>Prev</Button>
          )}
          {this.state.data.next && (
            <Button onClick={() => this.nextPage()}>Next</Button>
          )}
          {this.state.fetching && <MDSpinner />}
        </Row>
        {this.state.modal && (
          <Modal hide={() => this.setState({ modal: false })} maxWidth="350px">
            <Column
              align
              style={{
                justifyContent: "space-evenly",
              }}
            >
              {this.state.selectedSupplierManualDemerits.length !== 0 && (
                <Column style={{ width: "275px" }}>
                  <h3>Demerits</h3>{" "}
                  {this.state.selectedSupplierManualDemerits.map((d, i) => {
                    return (
                      <Demerit key={i}>
                        {demeritOptions.find((x) => x.value === d.demerit_type)
                          .label +
                          " (" +
                          d.points +
                          ")"}
                      </Demerit>
                    );
                  })}
                </Column>
              )}
              <Column style={{ width: "275px" }}>
                <h3>Demerit</h3>{" "}
                <Select
                  options={demeritOptions}
                  onChange={(e) => this.setState({ demerit_type: e.value })}
                />
              </Column>
              <Column style={{ width: "275px" }}>
                <h3>Score</h3>{" "}
                <Select
                  options={scoreOptions}
                  onChange={(e) => this.setState({ points: e.value })}
                />
              </Column>
              {this.state.selectedSupplierID && (
                <div style={{ width: "275px", margin: "20px auto" }}>
                  <Button
                    onClick={() => {
                      this.setState({ modal: false });
                      this.applyDemerit(this.state.selectedSupplierID);
                    }}
                  >
                    Add
                  </Button>{" "}
                  new demerit for supplier #{this.state.selectedSupplierID}
                </div>
              )}
            </Column>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const demeritOptions = [
  { value: "promotional", label: "Promo Material" },
  { value: "invoice", label: "Incorrect Invoice" },
  { value: "inventory", label: "Inventory" },
  { value: "responsiveness", label: "Non Responsive" },
];

const scoreOptions = [
  { value: -1, label: "-1" },
  { value: -2, label: "-2" },
  { value: -3, label: "-3" },
  { value: -4, label: "-4" },
  { value: -5, label: "-5" },
];

const StyledInput = styled.input`
  margin-bottom: 10px;
  height: 40px;
  width: 250px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  :active {
    border: rgba(0, 0, 0, 0.3);
  }
`;
