import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSettings } from "../Selectors";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import {
  stripeSupplierAuth,
  stripeStoreAuth,
  removeStripeCard,
  startStripeSession,
  fetchSettings,
} from "../Actions";
import { getUrlParameter } from "../modules/Format";
import { loadStripe } from "@stripe/stripe-js";
import ButtonNew from "./ButtonNew";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      stripeSupplierAuth,
      stripeStoreAuth,
      removeStripeCard,
      startStripeSession,
      fetchSettings,
    },
    dispatch
  ),
});

async function startAsyncSession(
  startStripeSession,
  stripeKey,
  redirectUrl = "/settings/account_details"
) {
  const stripe = await loadStripe(stripeKey);

  startStripeSession(redirectUrl).then((response) => {
    const sessionId = get(response, ["session", "id"]);

    if (sessionId) {
      stripe
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then(function (result) {
          console.log(result);
        });
    }
  });
}

class StripeCard extends Component {
  state = {
    confirmRemoveCard: false,
  };

  UNSAFE_componentWillMount = () => {
    let action = getUrlParameter("action", this.props.location);
    let result = getUrlParameter("result", this.props.location);
    let sessionId = getUrlParameter("session_id", this.props.location);
    let redirectUrl = getUrlParameter("redirect_url", this.props.location);

    if (action === "payment" && result === "success" && sessionId) {
      this.props.history.push("/settings/account_details");
      this.props.settings.store
        ? this.props.stripeStoreAuth({
            session_id: sessionId,
            store: this.props.settings.store.id,
          })
        : this.props
            .stripeSupplierAuth({
              session_id: sessionId,
              supplier: this.props.settings.supplier.id,
            })
            .then(() => {
              console.log("STRIPE STORE AUTH IS COMPLETE, CHECK FOR REDIRECT");
              console.log("redirectUrl", redirectUrl);
              this.props.history.push(redirectUrl);
            });
    }
  };

  startSession = () => {
    const store = get(this.props, ["settings", "store"]);
    const stripeKey =
      process.env.NODE_ENV === "development" || get(store, "test_account")
        ? process.env.REACT_APP_STRIPE_CHECKOUT_TEST_API_KEY
        : process.env.REACT_APP_STRIPE_CHECKOUT_LIVE_API_KEY;

    startAsyncSession(
      this.props.startStripeSession,
      stripeKey,
      this.props.redirectUrl
    );
  };

  render() {
    const stripeConnected = get(this.props, [
      "settings",
      "store",
      "stripe_connected",
    ]);
    const { text } = this.props;

    if (!stripeConnected) {
      return (
        <div>
          <ButtonNew
            margin={this.props.margin}
            onClick={this.startSession}
            text={text}
            isFetching={this.props.isSavingStripe}
          />
        </div>
      );
    } else {
      let payload = null;
      if (this.props.settings.store && this.props.settings.store.id) {
        payload = { store: this.props.settings.store.id };
      } else if (
        this.props.settings.supplier &&
        this.props.settings.supplier.id
      ) {
        payload = { supplier: this.props.settings.supplier.id };
      } else {
        payload = {};
      }

      return (
        <React.Fragment>
          {this.state.confirmRemoveCard ? (
            <ButtonNew
              text="Confirm Remove?"
              onClick={() => this.props.removeStripeCard(payload)}
              isFetching={this.props.isSavingStripe}
              type="danger"
            />
          ) : (
            <ButtonNew
              text="Remove Card"
              onClick={() => this.setState({ confirmRemoveCard: true })}
              type="secondary"
            />
          )}
        </React.Fragment>
      );
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StripeCard)
);
