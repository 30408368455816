import Cookies from "js-cookie";
import { get } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { clearSettings } from "../../actionCreators";
import { fetchAccountType } from "../../Actions";
import { getDefaultStoreRoute } from "../../modules/Format";
import createNotification from "../../modules/Notification";
import { theme } from "../../modules/Theme";
import { getIsFetching, getSettings } from "../../Selectors";
import { clearSupplierSettings } from "../supplier/actionCreators";
import Account, {
  AccountButton,
  AccountSubtitle,
  AccountTitle,
} from "./components/AccountComponents";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchAccountType,
      clearSettings,
      clearSupplierSettings,
    },
    dispatch
  ),
});

const AccountTypeRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
  overflow: auto;
`;

const AccountType = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
`;

const AccountImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AccountImage = styled.img`
  width: 70px;
  height: 70px;
  padding: 10px 10px;
`;

const AccountDescription = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 auto;
  margin-top: 12px;
  text-align: center;
  color: #57777a;

  @media (max-width: 800px) {
    font-size: 15px;
    margin-top: 8px;
  }
`;

class ChooseAccount extends Component {
  state = { accounts: [] };

  componentDidMount() {
    this.props.fetchAccountType().then((response) => {
      if (response.account_type === "affiliate") {
        this.props.history.push("/affiliate/dashboard");
      } else if (response.account_type === "supplier") {
        this.props.history.push("/supplier/products");
      } else if (response.account_type === "store") {
        this.props.history.push(getDefaultStoreRoute);
      } else {
        this.setState({ accounts: response.accounts });
      }
    });
  }

  selectDropshipper = (hasAccount) => {
    if (hasAccount) {
      this.props.dispatch(clearSupplierSettings());
      this.props.history.push(getDefaultStoreRoute);
    } else {
      createNotification({
        title: "Error",
        type: "default",
        message:
          "To sign up as a dropshipper, please contact support@dropcommerce.com",
      });
    }
  };

  selectSupplier = (hasAccount) => {
    if (hasAccount) {
      this.props.dispatch(clearSettings());
      this.props.history.push("/supplier/products");
    } else {
      window.open("https://app.dropcommerce.com/apply/supplier");
    }
  };

  handleSelection = (account) => {
    if (account.accountType === "store") {
      Cookies.set("storeId", account.id, { expires: 10 });
      this.selectDropshipper(true);
    } else if (account.accountType === "supplier") {
      this.selectSupplier(true);
    } else {
      alert("Missing account type");
    }
  };

  render() {
    const accounts = get(this.state, "accounts");

    return (
      <Account
        isFetching={this.props.isFetching > 0}
        title="Select account"
        subtitle="Hi! We've found multiple accounts in our system. Which account would you like to launch?"
        allowOverflow
      >
        <AccountTypeRow>
          {accounts.map((account) => (
            <AccountType key={account.id}>
              <AccountImageWrapper>
                <AccountImage src="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logos/logo.webp" />
              </AccountImageWrapper>
              <AccountTitle style={{ fontSize: "30px" }}>
                {account.name}
              </AccountTitle>
              <AccountSubtitle>{account.accountType} account</AccountSubtitle>
              <AccountDescription>
                {account.platformURL || "Not connected"}
              </AccountDescription>
              <AccountButton
                text="Launch"
                onClick={() => this.handleSelection(account)}
              />
            </AccountType>
          ))}
        </AccountTypeRow>
      </Account>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChooseAccount)
);
