import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import { Column, Row } from "../../../components";
import { Button } from "@material-ui/core";
import { getRequest } from "../../../modules/API";
import styled from "styled-components";
import { Modal } from "../../../components/Modal";
import MDSpinner from "react-md-spinner";
import { history } from "../../../BaseApp.jsx";
import { theme } from "../../../modules/Theme";
import Text from "../../../modules/Text";

// TODO: move these 2 to a common place and import it from there
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

export const Demerit = styled.span`
  border-radius: 4px;
  padding: 8px;
  color: white;
  margin: 5px;
  min-width: 0px;
  background: ${theme.colors.darkRed};
`;

/*
 * Renders the order Stats table in the Admin Dashboard
 * API Endpoint: /api/order-stats/
 * URL: /admin/order-stats
 * */
export default class OrderStatus extends React.Component {
  state = { data: { results: [] } };

  componentDidMount() {
    const fetchUrl = this.props.location.search.includes("page")
      ? `${api}/api/admin/admin-orders/${this.props.location.search}`
      : `${api}/api/admin/admin-orders/`;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data }))
      .catch((error) => console.log(error));
  }

  nextPage() {
    getRequest({}, this.state.data.next).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  prevPage() {
    getRequest({}, this.state.data.previous).then((res) =>
      this.setState({
        data: res.data,
      })
    );
  }

  search(term) {
    getRequest({
      url: `api/admin/admin-orders/?search=${term}`,
    }).then((res) => {
      this.setState({ data: res.data });
      history.push(`?search=${term}`);
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row align style={{ justifyContent: "space-between" }}>
          <Text bold extra="margin-bottom: 5px">
            Order Status
          </Text>
          <StyledInput
            type="text"
            placeholder="Search Order Name"
            onKeyUp={(e) => e.key === "Enter" && this.search(e.target.value)}
          />
        </Row>
        <APImappingTable data={this.state.data.results} clickable />
        <Row>
          {this.state.data.previous && (
            <Button onClick={() => this.prevPage()}>Prev</Button>
          )}
          {this.state.data.next && (
            <Button onClick={() => this.nextPage()}>Next</Button>
          )}
        </Row>

        {this.props.match.params.orderId && (
          <OrderDetailModal orderId={this.props.match.params.orderId} />
        )}
      </React.Fragment>
    );
  }
}

const APImappingTable = ({ data, clickable }) => {
  const [selectedID, setSelectedID] = useState(null);

  return (
    <React.Fragment>
      {!(data && data.length) ? (
        <MDSpinner />
      ) : (
        <TableContainer
          component={Paper}
          style={{ marginBottom: "15px", overflow: "auto" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(data[0]).map((column, i) => (
                  <StyledTableCell key={i}>
                    {column.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((datum, i) => (
                <TableRow
                  key={i}
                  style={{
                    backgroundColor:
                      datum.id === selectedID ? "rgba(0,0,0,0.05)" : null,
                  }}
                  onMouseOver={() => setSelectedID(datum.id)}
                  onClick={() =>
                    clickable && history.push(`/admin/order-status/${datum.id}`)
                  }
                >
                  {Object.keys(data[0]).map((column, i) => {
                    return (
                      <StyledTableCell key={i}>{datum[column]}</StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

const OrderDetailModal = ({ orderId }) => {
  const [data, setData] = useState({});
  useEffect(() => {
    getRequest({
      url: `api/admin/admin-orders/${orderId}/`,
    }).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <Modal hide={() => history.push(`/admin/order-status/`)} maxWidth="650px">
      <Column
        align
        style={{
          justifyContent: "space-evenly",
        }}
      >
        <Text bold extra="margin-bottom: 5px">
          Order Details
        </Text>
        <br />
        {["id", "name", "store", "type", "status", "total", "created"].map(
          (field) => {
            return (
              <Row
                key={field}
                align
                style={{ width: "500px", justifyContent: "space-between" }}
              >
                <Column>
                  <Text xs>
                    <b>{field.toUpperCase()}</b>
                  </Text>
                </Column>
                <Column>
                  <Text xs>{data[field]}</Text>
                </Column>
              </Row>
            );
          }
        )}
        <br />
        <Text light extra="margin-bottom: 5px">
          Line Items
        </Text>
        <APImappingTable data={data["line_items"]} />

        {data["order_issues"] && data["order_issues"].length > 0 && (
          <React.Fragment>
            <br />
            <Text light extra="margin-bottom: 5px">
              Order Issues
            </Text>
            <APImappingTable data={data["order_issues"]} />
          </React.Fragment>
        )}

        {data["line_item_issues"] && data["line_item_issues"].length > 0 && (
          <React.Fragment>
            <br />
            <Text light extra="margin-bottom: 5px">
              Line Item Issues
            </Text>
            <APImappingTable data={data["line_item_issues"]} />
          </React.Fragment>
        )}
      </Column>
    </Modal>
  );
};

const StyledInput = styled.input`
  margin-bottom: 10px;
  height: 40px;
  width: 250px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  :active {
    border: rgba(0, 0, 0, 0.3);
  }
`;
