import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Column } from "../../../components";
import { theme } from "../../../modules/Theme";
import appIcon from "../../../images/dc-box-2021-white-grey.svg";
import { items as getItems, Item } from "./Items";

const showLabels = true;

class Sidebar extends Component {
  UNSAFE_componentWillMount() {
    if (!Cookies.get("accessToken")) {
      this.props.history.push(`/login`);
    }
  }

  logout = () => {
    Cookies.remove("accessToken");
    this.props.history.push(`/login`);
  };

  renderOptions = () => {
    const items = getItems(
      this.props.history,
      this.props.location.pathname,
      this.logout
    );

    return (
      <SidebarContainer>
        <SidebarInnerContainer>
          <div>
            {items
              .filter((i) => i.top)
              .map((item) => (
                <Item
                  key={item.text}
                  marginBottom={item.marginBottom}
                  showLabels={showLabels}
                  text={item.text}
                  icon={item.icon}
                  active={item.active}
                  onClick={item.onClick}
                />
              ))}
          </div>
          <div>
            {items
              .filter((i) => i.bottom)
              .map((item) => (
                <Item
                  key={item.text}
                  marginBottom={item.marginBottom}
                  showLabels={showLabels}
                  text={item.text}
                  icon={item.icon}
                  active={item.active}
                  onClick={item.onClick}
                />
              ))}
          </div>
        </SidebarInnerContainer>
      </SidebarContainer>
    );
  };

  render() {
    return (
      <StyledSideBar showLabels={showLabels}>
        <Logo
          src={appIcon}
          onClick={() => this.props.history.push("/dashboard")}
        />
        {this.props.isFetchingSettings ||
        this.props.isFetchingSupplierSettings ? (
          <Column
            style={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              justifyContent: "space-between",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div>
              <Item.Skeleton />
              <Item.Skeleton />
              <Item.Skeleton />
              <Item.Skeleton />
              <Item.Skeleton />
            </div>
            <div>
              <Item.Skeleton />
              <Item.Skeleton />
            </div>
          </Column>
        ) : (
          this.renderOptions()
        )}
      </StyledSideBar>
    );
  }
}

export default withRouter(Sidebar);

const SidebarInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Logo = styled.img`
  height: 40px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.darkGreen};
  width: ${(p) => (p.showLabels ? "190px" : "80px")};
  min-width: ${(p) => (p.showLabels ? "190px" : "80px")};
  padding-top: 15px;
  padding-bottom: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
