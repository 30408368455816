import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import {
  Registration,
  PrivateRegistration,
  GetPrebuilt,
  Launch,
} from "./views";
import Thanks from "./views/Thanks";
import { Login, Signup, Forgot, DFSSignup } from "./views/account";
import { Contact, Terms, Privacy } from "./views/landing";
import { getUrlSubdomain } from "./modules/Format";
import { bindActionCreators } from "redux";
import { fetchSubdomainData } from "./Actions";
import { connect } from "react-redux";
import SupplierAppInstall from "./views/account/SupplierAppInstall";
import SupplierAppLogin from "./views/account/SupplierAppLogin";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchSubdomainData,
    },
    dispatch
  ),
});

class Public extends Component {
  componentDidMount() {
    const subdomain = getUrlSubdomain();
    const isSupplierPortal = subdomain !== "app";
    if (isSupplierPortal) {
      this.props.fetchSubdomainData(subdomain);
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route
          exact
          path="/suppliers/private/apply"
          component={PrivateRegistration}
        />
        <Route exact path="/apply/supplier" component={Registration} />
        <Route exact path="/suppliers" component={Registration} />
        <Route exact path="/sapp/login/" component={SupplierAppLogin} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/getprebuilt" component={GetPrebuilt} />
        <Route
          exact
          path="/bigcommerce"
          render={() => <Launch platform="BigCommerce" />}
        />
        <Route exact path="/wix" render={() => <Launch platform="Wix" />} />
        <Route
          exact
          path="/shoplazza"
          render={() => <Launch platform="Shoplazza" />}
        />

        {/* TRACKING PAGES */}
        <Route exact path="/dfs" component={DFSSignup} />
        <Route exact path="/dodropshipping" component={Signup} />
        <Route exact path="/dodropshippingpricing" component={Signup} />
        <Route exact path="/ecom-era" component={Signup} />
        <Route exact path="/youtube" component={Signup} />
        <Route exact path="/facebook" component={Signup} />
        <Route exact path="/tiktok" component={Signup} />
        <Route exact path="/twitter" component={Signup} />
        <Route exact path="/podcast" component={Signup} />
        <Route exact path="/instagram" component={Signup} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/thanks" component={Thanks} />
        <Route path="/" component={Login} />
      </Switch>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Public);
