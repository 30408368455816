import React, { Component } from "react";
import Text from "../../../modules/Text";
import { ButtonNew } from "../../../components";
import styled from "styled-components";

const ButtonRow = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

class ShowPassword extends Component {
  render() {
    return (
      <div>
        <Text.Large light margin="0 0 10px 0">
          To preview, click <strong>View Live Demo</strong> and enter the
          password: <strong>{this.props.storeData.password}</strong>
        </Text.Large>

        <ButtonRow>
          <ButtonNew
            width="200px"
            margin="10px"
            size="large"
            text="View Live Demo"
            onClick={() => window.open(this.props.storeData.demo)}
          />
          <ButtonNew
            width="200px"
            margin="10px"
            type="secondary"
            size="large"
            text="Return To Checkout"
            onClick={this.props.hide}
          />
        </ButtonRow>
      </div>
    );
  }
}

export default ShowPassword;
