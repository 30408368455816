import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import styled from "styled-components";

const DescriptionList = styled("dl")`
  @media (min-width: 801px) {
    display: none;
  }
`;

export function SupplierRow({ supplier, onOpenDrawer }) {
  const sx = {
    display: { xs: "none", md: "table-cell" },
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
        onClick={onOpenDrawer}
      >
        <TableCell>
          {supplier.name}
          <DescriptionList
            style={{
              marginTop: "8px",
              // marginLeft: "16px",
              fontStyle: "italic",
              color: "#555",
            }}
          >
            <dt style={{ display: "none" }}>Processing Time</dt>
            <dd style={{ margin: "4px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Processing Time:</span>{" "}
              {supplier.manufacturingTime}
            </dd>
            <dt style={{ display: "none" }}>Email</dt>
            <dd style={{ margin: "4px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
              {supplier.email}
            </dd>
            <dt style={{ display: "none" }}>Store URL</dt>
            <dd style={{ margin: "4px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Store URL:</span>{" "}
              {supplier.shopifyUrl}
            </dd>
            <dt style={{ display: "none" }}>Platform</dt>
            <dd style={{ margin: "4px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Platform:</span>{" "}
              {supplier.platform}
            </dd>
          </DescriptionList>
        </TableCell>
        <TableCell sx={sx}>{supplier.manufacturingTime}</TableCell>
        <TableCell sx={sx}>{supplier.email}</TableCell>
        <TableCell sx={sx}>{supplier.shopifyUrl}</TableCell>
        <TableCell sx={sx}>{supplier.platform}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
