import { createTheme } from "@material-ui/core/styles";
import { getAppName } from "./Format";

const mainTheme = {
  colors: {
    // NEW COLORs
    main: "#32968e", // UPDATED FROM PUBLIC THEME
    darkGreen: "#265454", // UPDATED FROM PUBLIC THEME
    newDarkGreen: "#1d4040",
    // ------------------------

    mainDark: "#2c857d",
    mainLight: "#8DC4C2",
    darkerGreen: "#26403E",
    // DARK GREY
    medGrey: "#839996",
    medLightGrey: "#abbab8",
    darkGrey: "#223b37",
    medDarkGrey: "#32554f",
    buttonTextGrey: "#3a4b4a",
    midGrey: "#737376",
    grey: "#b0b0b0",
    darkerGrey: "#222222",

    // LIGHT GREY
    lightBorder: "#dae7e5",
    lightGrey: "#f1f7f6",
    ultraLightGrey: "#f8fbfb",
    inputBorder: "#d3d3d3",

    // OTHER
    accent: "#be4958",
    darkRed: "#db3232",
    white: "#ffffff",
    yellow: "#ffd110",
    orange: "#e48832",
    orangeDark: "#cb701a",
    peach: "#f5c070",
  },
};

const globalTheme = {
  colors: {
    main: "#2A96B5",
    darkGreen: "#177892",
    newDarkGreen: "#177892",
    // ------------------------

    mainDark: "#177892",
    mainLight: "#00b5df",
    darkerGreen: "#016879",

    // DARK GREY
    medGrey: "#839996",
    medLightGrey: "#abbab8",
    darkGrey: "#223b37",
    medDarkGrey: "#32554f",
    buttonTextGrey: "#3a4b4a",
    midGrey: "#737376",
    grey: "#b0b0b0",
    darkerGrey: "#222222",

    // LIGHT GREY
    lightBorder: "#dae7e5",
    lightGrey: "#f1f7f6",
    ultraLightGrey: "#f8fbfb",
    inputBorder: "#d3d3d3",

    // OTHER
    accent: "#be4958",
    darkRed: "#db3232",
    white: "#ffffff",
    yellow: "#ffd110",
    orange: "#e48832",
    orangeDark: "#cb701a",
    peach: "#f5c070",
  },
};

const prebuiltTheme = {
  colors: {
    main: "#2A96B5",
    darkGreen: "#177892",
    newDarkGreen: "#0f667d",
    // ------------------------

    mainDark: "#177892",
    mainLight: "#00b5df",
    darkerGreen: "#016879",

    // DARK GREY
    medGrey: "#839996",
    medLightGrey: "#abbab8",
    darkGrey: "#223b37",
    medDarkGrey: "#32554f",
    buttonTextGrey: "#3a4b4a",
    midGrey: "#737376",
    grey: "#b0b0b0",
    darkerGrey: "#222222",

    // LIGHT GREY
    lightBorder: "#dae7e5",
    lightGrey: "#f1f7f6",
    ultraLightGrey: "#f8fbfb",
    inputBorder: "#d3d3d3",

    // OTHER
    accent: "#be4958",
    darkRed: "#db3232",
    white: "#ffffff",
    yellow: "#ffd110",
    orange: "#e48832",
    orangeDark: "#cb701a",
    peach: "#f5c070",
  },
};

const appName = getAppName();

let dropCommerceTheme = mainTheme;

if (appName === "PREBUILT") {
  dropCommerceTheme = prebuiltTheme;
} else if (appName === "GLOBAL") {
  dropCommerceTheme = globalTheme;
}

export const theme = dropCommerceTheme;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.main,
    },
  },
});
