import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as z from "zod";
import { useClipboardCopy } from "../../../hooks";
import { errorNotification } from "../../../modules/Notification";
import { ToggleColorMode } from "../ToggleColorMode";
import { getLink } from "../api/impersonateApi";

const Container = styled.main`
  padding: 0 30px;

  @media (min-width: 801px) {
    padding: 0;
  }
`;

const schema = z
  .object({
    id: z.string(),
  })
  .refine(({ id }) => Number(id), {
    path: ["id"],
    message: "Please enter a number",
  });

function Impersonate() {
  const { isCopied, handleCopyClick } = useClipboardCopy();

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ resolver: zodResolver(schema) });

  // State to keep track of the switch value
  const [isStore, setIsStore] = useState(true);
  const [link, setLink] = useState("");

  const onSubmit = async ({ id }) => {
    try {
      const type = isStore ? "store" : "supplier";
      const data = await getLink(type, id);
      const link = data.link;
      setLink(link);

      // Reset the field to its initial state
      resetField("id");
    } catch (error) {
      // Handle error
      console.error("Error:", error);
      errorNotification("Failed to get link.");
    }
  };

  // Function to handle switch state changes
  const handleSwitchChange = () => {
    setIsStore((isStore) => !isStore);
  };

  const content = (
    <>
      {isSubmitSuccessful && link ? (
        <>
          <Typography
            variant="body1"
            component="p"
            color="primary"
            gutterBottom
          >
            {isStore ? "Store" : "Supplier"} URL: <code>{link}</code>
          </Typography>

          <Button
            disabled={isCopied}
            type="button"
            variant="outlined"
            onClick={() => {
              handleCopyClick(link, () => {
                setLink("");
              });
            }}
          >
            {!isCopied ? "Copy link" : "Copied"}
          </Button>
        </>
      ) : (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{
            width: { sm: 250 },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          noValidate
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={isStore} onChange={handleSwitchChange} />
              }
              label="Store"
            />
          </FormGroup>

          <TextField
            label="ID"
            type="number"
            autoComplete="off"
            {...register("id")}
            defaultValue=""
            error={!!errors.id}
            helperText={errors.id && errors.id.message}
          />

          <Button disabled={isSubmitting} type="submit" variant="contained">
            {isSubmitting ? "Getting link..." : "Get link"}
          </Button>
        </Box>
      )}
    </>
  );

  return (
    <ToggleColorMode>
      <CssBaseline />
      <Container>{content}</Container>
    </ToggleColorMode>
  );
}

export default Impersonate;
