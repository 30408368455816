import React from "react";
import styled from "styled-components";
import closeIcon from "../images/icons/feather/x-circle.svg";
import { theme } from "../modules/Theme";

const CloseIcon = styled.img`
  opacity: 0.7;
  height: 26px;
  cursor: pointer;
  @media (max-width: 800px) {
    height: 20px;
  }
`;

const Background = styled.div`
  background: white;
  padding: 60px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 30px;
  }
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const Bubble = styled.div`
  background: white;
  padding: 35px;
  width: 100%;
  border-radius: 5px;
  max-width: 800px;
  margin: 0 auto;
`;

const SpecialOfferTitle = styled.div`
  font-size: 26px;
  color: ${theme.colors.main};
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 14px;
  }
`;

const SpecialOfferSubtitle = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  margin: 20px;
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 14px;
  }
`;

const SpecialOfferBenefit = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
  ${(p) => p.bold && "font-weight: 500;"}
  @media (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const PopupModal = ({ hide, originalRate }) => (
  <Background>
    <Bubble>
      <CloseIcon onClick={hide} src={closeIcon} />
      <SpecialOfferTitle>{`This supplier charges $${originalRate} for US shipping, but we're bringing that down to $2.99`}</SpecialOfferTitle>

      <SpecialOfferBenefit>
        To help you sell more products, DropCommerce is offering a special
        shipping subsidy, bringing your US shipping costs down to max $2.99 per
        item.
      </SpecialOfferBenefit>

      <SpecialOfferBenefit bold>How it works:</SpecialOfferBenefit>

      <SpecialOfferBenefit>
        You pay ${originalRate} for shipping, but DropCommerce will reimburse
        you ${(originalRate - 2.99).toFixed(2)}.
      </SpecialOfferBenefit>

      <SpecialOfferSubtitle>
        * You will be reimbursed via PayPal when your total amount owing reaches
        a threshold of $20.00 or more.
      </SpecialOfferSubtitle>

      <SpecialOfferSubtitle>
        * This subsidy is limited to 20 orders per month.
      </SpecialOfferSubtitle>
    </Bubble>
  </Background>
);
