import Cookies from "js-cookie";
import React from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import background from "../../images/susan-wilkinson-nSs1mMaUVu8-unsplash.jpg";

const Banner = styled.div`
  background-image: url(${background});
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #ffffff;
`;

const Message = styled.div`
  color: #ffffff;
  font-weight: 300;
`;

export function BlackFridaySaleBanner({ message, hide }) {
  return (
    <Banner>
      <Message>{message}</Message>

      <FiX
        style={{ marginLeft: "2%", cursor: "pointer" }}
        onClick={() => {
          Cookies.set("hideBlackFridayBanner", true, { expires: 1 });
          hide();
        }}
      />
    </Banner>
  );
}
