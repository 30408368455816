import React from "react";
import Cookies from "js-cookie";
import { history } from "../../../../BaseApp";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Spinner, ButtonNew, Row, Hint } from "../../../../components";
import Text from "../../../../modules/Text";
import { getRequest } from "../../../../modules/API";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { getSettings, getIsFetchingSettings } from "../../../../Selectors";
import { updateStateDirectly } from "../../../../Actions";
import { FiCheck, FiPlus } from "react-icons/fi";
import { theme } from "../../../../modules/Theme";
import { get } from "lodash";
import { checkStatus } from "../../../../utils/index";
import { api } from "../../../../modules/Config";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ updateStateDirectly }, dispatch),
});

class AddressList extends React.Component {
  state = {
    shippingAddresses: [],
    hint: "Prevent orders from being created if the supplier doesn't ship to that region.",
    isFetching: false,
    syncDeliveryProfiles: false,
  };

  componentDidMount() {
    this.setState({ isFetching: true }, () => {
      getRequest({
        url: "api/addresses/",
      })
        .then((res) => this.setState({ shippingAddresses: res.data }))
        .finally(() => {
          this.setState({ isFetching: false });
        });
    });
  }

  syncDeliveryProfiles = () => {
    this.setState({ syncDeliveryProfiles: true });

    fetch(`${api}/api/delivery-profiles/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ store: this.props.settings.store.id }),
    })
      .then((response) => checkStatus(response))
      .catch((error) => {});
  };

  render() {
    return (
      <div style={{ marginTop: "25px" }}>
        {get(this.props, ["settings", "store", "platform", "name"]) ===
          "SHOPIFY" && (
          <Row>
            <ButtonNew
              width="280px"
              disabled={this.state.syncDeliveryProfiles}
              type="main-dark"
              text="Sync Delivery Profiles"
              margin="0 0 15px 0px"
              icon={
                this.state.syncDeliveryProfiles && (
                  <FiCheck size="20" color="white" />
                )
              }
              onClick={this.syncDeliveryProfiles}
            />
            <Hint hint={this.state.hint} />
          </Row>
        )}
        <Row style={{ marginBottom: "25px" }}>
          <ButtonNew
            text="New Address"
            icon={<FiPlus size="20" color="white" />}
            onClick={() => history.push("/settings/addresses/new")}
          />
          <ButtonNew
            text="New Order"
            margin="0 0 0 15px"
            icon={<FiPlus size="20" color="white" />}
            onClick={() => history.push("/order/new")}
          />
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.isFetching && <Spinner />}
          {this.state.shippingAddresses.map((address, i) => (
            <Card
              key={i}
              onClick={() => history.push(`/settings/addresses/${address.id}`)}
            >
              <Text>{address.first_name + " " + address.last_name}</Text>
              <Text.Small light>{address.address1}</Text.Small>
              <Text.Small light>
                {address.city}, {address.province_code}, {address.country_code}
              </Text.Small>
              <Text.Small light>{address.zip}</Text.Small>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

const Card = styled.div`
  cursor: pointer;
  margin: 0px 20px 20px 0px;
  padding: 15px;
  min-height: 100px;
  min-width: 300px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 300px;
  border-radius: 5px;
`;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressList)
);
