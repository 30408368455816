import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  updateSupplier,
  saveSupplierSettings,
} from "../../../../views/supplier/SupplierActions";
import { getSettings } from "../../../../views/supplier/SupplierSelectors";
import { Row, Column, ButtonNew } from "../../../../components";
import Text from "../../../../modules/Text";
import { theme } from "../../../../modules/Theme";
import styled from "styled-components";
import check from "../../../../images/check-circle-green.svg";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

const AgreementItem = ({ title, description, active, onClick }) => (
  <ItemWrapper onClick={onClick} active={active}>
    <ItemRow>
      <Column style={{ marginRight: "15px" }}>
        <Row>
          <ItemTitle>{title}</ItemTitle>
          {active && (
            <Text bold green>
              Agreed
            </Text>
          )}
        </Row>
        <ItemDescription>{description}</ItemDescription>
      </Column>
      <div style={{ width: "55px" }}>
        <CheckIcon src={check} active={active} />
      </div>
    </ItemRow>
  </ItemWrapper>
);

class Integrations extends Component {
  state = {
    shipping: false,
    inventory: false,
    pricing: false,
    communication: false,
  };

  updateSupplier = () => {
    this.props.saveSupplierSettings({ supplier_agreement: true });
    this.props.updateSupplier({ supplier_agreement: true });
  };

  render() {
    const settings = get(this.props, ["settings"]);

    const { shipping, inventory, pricing, communication } = this.state;

    const mainText =
      "Suppliers are the backbone of DropCommerce. Consistency, profile accuracy, fast shipping and communication are integral to your success in our marketplace. We appreciate your involvement and your commitment to comply with our standards.";

    const shippingDescription =
      "You agree to ship orders within 3 business days of receiving payment for an order. If there is any delay, you agree to notify DropCommerce immediately. *Exceptions made for hand made products with a large processing time, but must be listed on the profile. If an order is not marked as shipped within 7 days, it may be auto cancelled.";
    const inventoryDescription =
      "You agree to keep accurate inventory levels. If you use Shopify sync, you agree that your Shopify inventory levels are up to date. Otherwise, you agree to update DropCommerce when items are out of stock.";
    const pricingDescription =
      "You agree to keep your pricing up to date within DropCommerce. Your listed Retail Price must always match the Retail Price in DropCommerce. If you update many products and need help syncing them, contact support@dropcommerce.com";
    const communicationDescription =
      "You agree to be easy to get in touch with in the case of product or order related questions from customers or DropCommerce staff. If there is an active question / issue and we do not hear back from you within 1-2 business days, orders may be auto-cancelled and refunded.";

    return (
      <SubSection>
        <Column>
          <Text bold>{mainText}</Text>
          <Text extra="margin-top: 10px; margin-bottom: 10px;">
            {settings.supplier_agreement
              ? "You have successfully agreed to the Supplier Agreement."
              : "Please click on each item below to confirm you agree."}
          </Text>
          <Text.Small light extra="margin-bottom: 20px;">
            If you have any questions, please contact support@dropcommerce.com
          </Text.Small>
        </Column>

        <AgreementItem
          title="1. Shipping"
          description={shippingDescription}
          active={settings.supplier_agreement || shipping}
          onClick={() => this.setState({ shipping: !this.state.shipping })}
        />
        <AgreementItem
          title="2. Inventory"
          description={inventoryDescription}
          active={settings.supplier_agreement || inventory}
          onClick={() => this.setState({ inventory: !this.state.inventory })}
        />
        <AgreementItem
          title="3. Pricing"
          description={pricingDescription}
          active={settings.supplier_agreement || pricing}
          onClick={() => this.setState({ pricing: !this.state.pricing })}
        />
        <AgreementItem
          title="4. Communication"
          description={communicationDescription}
          active={settings.supplier_agreement || communication}
          onClick={() =>
            this.setState({ communication: !this.state.communication })
          }
        />

        <Text red bold extra="margin-top: 20px;">
          Failure to comply with the supplier agreement may result in your
          profile being suspended.
        </Text>

        {(settings.supplier_agreement ||
          (shipping && inventory && pricing && communication)) && (
          <ButtonNew
            text={settings.supplier_agreement ? "Agreed" : "I Agree"}
            type={settings.supplier_agreement ? "main-light" : "main"}
            extra="width: 150px; padding: 15px; margin-top: 20px;"
            onClick={this.updateSupplier}
          />
        )}
      </SubSection>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Integrations)
);

const CheckIcon = styled.img`
  height: 55px;
  width: 55px;
  opacity: ${(p) => (p.active ? "1.0" : "0.3")};
`;

const ItemWrapper = styled.div`
  border: 2px solid
    ${(p) => (p.active ? theme.colors.main : theme.colors.lightBorder)};
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${theme.colors.main};
  }
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemTitle = styled.div`
  font-size: ${(p) => (p.small ? "16px" : "20px")};
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
  margin-right: 15px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const ItemDescription = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  margin-top: 8px;
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
