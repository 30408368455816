import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createSupplier } from "../Actions";
import { getIsFetching } from "../Selectors";
import Input from "../components/Input";
import Switch from "../components/Switch";
import ButtonNew from "../components/ButtonNew";
import Label from "../components/Label";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { get, isEmpty } from "lodash";
import Select from "react-select";
import { Show } from "../components/Show";
import { countryOptions, platformOptions } from "../constants";

import { Checkbox } from "@material-ui/core";
import { Row } from "../components";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const MainForm = styled.div`
  width: 500px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 800px) {
    font-size: 32px;
    margin-top: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 18px;
    margin-top: 10px;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ createSupplier }, dispatch),
});

const defaultProps = {
  isFetching: false,
};

class Registration extends Component {
  state = {
    submitted: false,
    name: "",
    website: "",
    email: "",
    phone: "",
    country: "United States",
    shipCountry: "United States",
    supplierAgreementAccepted: false,
    platform: "Shopify",
    city: "",
    currency: "USD",
    discount: 40,
    shipping: false,
    errors: {},
  };

  checkBasicInfoFields = () => {
    const {
      name,
      phone,
      website,
      email,
      city,
      country,
      platform,
      discount,
      dropshipping,
      // shipping,
      supplierAgreementAccepted,
    } = this.state;

    let errors = {};

    // if (dropshipping && country === "Other") {
    //   errors.country =
    //     "Suppliers must be located in the US or Canada to join DropCommerce";
    // }

    if (discount < 30) {
      errors.discount =
        "Please enter a discount at 30% or higher (numbers only, no % symbol)";
    }

    // if (!shipping) {
    //   errors.shipping = "You must ship from either the US or Canada";
    // }

    if (phone.length < 10) {
      errors.phone = "Please enter a valid phone number";
    }

    if (platform === "") {
      errors.platform = "Please select an option";
    }

    if (name.length < 3) {
      errors.name = "Please enter a valid brand name";
    }

    if (name.includes("LLC") || name.includes("INC")) {
      errors.name =
        "Please use your customer-facing brand name. Do not include LLC or INC";
    }

    if (website.length < 3 || !website.includes(".")) {
      errors.website = "Please enter a valid website URL";
    }

    if (city.length < 3) {
      errors.city = "Please enter a valid city";
    }

    if (email.length < 3 || !email.includes("@") || !email.includes(".")) {
      errors.email = "Please enter a valid email address";
    }

    console.log({ state: this.state, errors });

    if (!supplierAgreementAccepted && isEmpty(errors)) {
      errors.supplierAgreementAccepted =
        "You must agree to the supplier agreement to proceed";
    }

    this.setState({ errors });

    return isEmpty(errors) && supplierAgreementAccepted;
  };

  checkPricingFields = () => {
    if (this.checkBasicInfoFields()) {
      this.props.createSupplier(this.state).then((response) => {
        if (response.token) {
          this.setState({ submitted: true });
        }
        if (response.hybrid_created) {
          this.props.history.push("/accounts");
        }
        if (get(response, "error") === "unknown") {
          this.setState({
            errors: {
              general:
                "Error creating supplier account. Please contact support at support@dropcommerce.com",
            },
          });
        }
      });
    }
  };

  updateRate = (type, rate) => {
    let newRate = parseFloat(rate);

    if (isNaN(newRate)) {
      newRate = 0.0;
    }

    this.setState({ [type]: newRate }, this.fieldValidation);
  };

  render() {
    const { errors } = this.state;

    return (
      <PageWrapper>
        <CenterDiv>
          <Show when={!this.state.submitted}>
            <Title>Join DropCommerce</Title>
            <Subtitle>
              Fill out the form below to register as a dropshipping supplier.
            </Subtitle>
          </Show>

          {!this.state.submitted && (
            <React.Fragment>
              <Text.Small
                light
                link
                center
                extra="margin-bottom: 10px; margin-top: 20px;"
                onClick={() => this.props.history.push("/login")}
              >
                Already have a supplier account? Click here to login.
              </Text.Small>
            </React.Fragment>
          )}
          {errors.general && (
            <Text.Small light red center extra="margin-bottom: 10px;">
              {errors.general}
            </Text.Small>
          )}
        </CenterDiv>

        <MainForm>
          {this.state.submitted && (
            <div>
              <Text.Large bold center extra="margin-bottom: 10px;">
                Application Submitted
              </Text.Large>
              <Text light center extra="margin-bottom: 10px;">
                Check your email for a confirmation that your application was
                submitted successfully.
              </Text>
              <Text light center extra="margin-bottom: 10px;">
                We will review your submission and someone will be in touch.
              </Text>
            </div>
          )}

          {!this.state.submitted && (
            <div>
              <Input
                onChange={(t) => this.setState({ name: t.target.value })}
                placeholder="Brand / Company Name"
                extraStyle="margin-bottom: 5px;"
                error={errors.name}
                value={this.state.name}
              />
              <Input
                onChange={(t) => this.setState({ website: t.target.value })}
                placeholder="Website URL"
                extraStyle="margin-bottom: 5px;"
                error={errors.website}
                value={this.state.website}
              />
              <Input
                onChange={(t) => this.setState({ phone: t.target.value })}
                placeholder="Phone Number"
                extraStyle="margin-bottom: 5px;"
                error={errors.phone}
                value={this.state.phone}
              />
              <Input
                onChange={(t) => this.setState({ email: t.target.value })}
                placeholder="Email Address"
                extraStyle="margin-bottom: 5px;"
                error={errors.email}
                value={this.state.email}
              />
              <Input
                onChange={(t) => this.setState({ city: t.target.value })}
                placeholder="City"
                extraStyle="margin-bottom: 5px;"
                error={errors.city}
                value={this.state.city}
              />

              <Label>Where is your business located?</Label>
              {this.state.errors.country && (
                <Text.Small margin="10px 0 0 0" red>
                  {this.state.errors.country}
                </Text.Small>
              )}
              <div style={{ width: "100%" }}>
                <Select
                  value={{
                    value: this.state.country,
                    label: this.state.country,
                  }}
                  options={countryOptions.map((country) => ({
                    value: country,
                    label: country,
                  }))}
                  onChange={(data) => this.setState({ country: data.value })}
                />
              </div>
              <Label>Where are your products shipped from?</Label>
              {this.state.errors.shipCountry && (
                <Text.Small margin="10px 0 0 0" red>
                  {this.state.errors.shipCountry}
                </Text.Small>
              )}
              <div style={{ width: "100%" }}>
                <Select
                  value={{
                    value: this.state.shipCountry,
                    label: this.state.shipCountry,
                  }}
                  options={countryOptions.map((country) => ({
                    value: country,
                    label: country,
                  }))}
                  onChange={(data) =>
                    this.setState({ shipCountry: data.value })
                  }
                />
              </div>
              <Label>Ecommerce Platform</Label>
              <div style={{ width: "100%" }}>
                <Select
                  value={{
                    value: this.state.platform,
                    label: this.state.platform,
                  }}
                  options={platformOptions}
                  onChange={(data) => this.setState({ platform: data.value })}
                />
              </div>

              <Input
                onChange={(t) => this.setState({ discount: t.target.value })}
                label="Dropship Margin (%)"
                hint="Dropship suppliers are required to offer stores a minimum 30-40% margin compared to retail price, which is the store's profit. If your product costs $100 and you offer a 35% margin, stores will pay you $65 for the product when sold. You can adjust prices manually later on a product level."
                placeholder="Dropship Margin % (30 or more)"
                value={this.state.discount}
                type="number"
                extraStyle="margin-bottom: 5px;"
                error={errors.discount}
              />
              {/* <Switch
                label="I ship from the US or Canada"
                hint="To join the Public Marketplace as a supplier, you must ship from either the United States or Canada."
                active={this.state.shipping}
                error={this.state.errors.shipping}
                toggle={() =>
                  this.setState({
                    shipping: !this.state.shipping,
                  })
                }
              /> */}

              <div>
                <Text
                  light
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    color="primary"
                    style={{ padding: 0 }}
                    onChange={(e) =>
                      this.setState({
                        supplierAgreementAccepted: e.target.checked,
                      })
                    }
                  />
                  I agree to the
                  <Text
                    margin="0 0 0 4px"
                    light
                    link
                    onClick={() =>
                      window.open(
                        "https://help.dropcommerce.com/en/articles/4364902-dropcommerce-supplier-agreement"
                      )
                    }
                  >
                    Supplier Agreement
                  </Text>
                </Text>
                {this.state.errors.supplierAgreementAccepted && (
                  <Text.Small margin="10px 0 0 0" red>
                    {this.state.errors.supplierAgreementAccepted}
                  </Text.Small>
                )}
              </div>

              <ButtonNew
                text="Submit Application"
                type="main"
                extra="margin-top: 25px; padding: 15px;"
                fontStyle="font-size: 20px;"
                onClick={this.checkPricingFields}
                isFetching={this.props.isFetching}
              />
            </div>
          )}
        </MainForm>
      </PageWrapper>
    );
  }
}

Registration.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registration)
);
