import React, { Component } from "react";
import styled from "styled-components";
// import { theme } from "../../../../../modules/Theme";
import check from "../../../../../images/check-icon-white.png";
import { resizeImage } from "../../../../../modules/Format";

export const ImageContainer = styled.div`
  height: 150px;
  width: 150px;
  object-fit: cover;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    height: 70px;
    width: 70px;
  }
`;

export const BackgroundOpacity = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, ${(props) => (props.selected ? "0.5" : "0.0")});
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckImage = styled.img`
  height: 50px;
`;

class SelectableImage extends Component {
  render() {
    let { image, selected, updateSelected } = this.props;
    return (
      <ImageContainer image={resizeImage(image, 150)} onClick={updateSelected}>
        <BackgroundOpacity selected={selected}>
          {selected && <CheckImage src={check} />}
        </BackgroundOpacity>
      </ImageContainer>
    );
  }
}

export default SelectableImage;
