import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { useLocalStorage } from "../../hooks";
import { theme as DCTheme } from "../../modules/Theme";
import { Topbar } from "./Topbar";
import { ColorModeContext } from "./context";

export function ToggleColorMode({ children }) {
  const [mode, setMode] = useLocalStorage("mode", "dark");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(mode === "light" ? "dark" : "light");
      },
    }),
    [mode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: DCTheme.colors.main,
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 801, // Our custom medium breakpoint
            lg: 1200,
            xl: 1920,
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Topbar />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
