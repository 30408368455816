import React, { Component } from "react";

import checkIcon from "../images/check-icon-white.png";
import plusIcon from "../images/plus-icon-white.png";
import clockIcon from "../images/pending-icon-white.png";
import xIcon from "../images/denied-icon-white.png";
import emailIcon from "../images/email-icon-white.png";
import shopifyIcon from "../images/shopify-icon-white.png";
import arrowRight from "../images/arrow-right-white-icon.png";
import starIcon from "../images/star.png";
import styled, { css } from "styled-components";
import MDSpinner from "react-md-spinner";

import { theme } from "../modules/Theme";

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  width: ${(props) => (props.fixedWidth ? "170px" : "auto")};
  ${(props) =>
    props.fit &&
    `
      width: auto;
      white-space: nowrap;
    `};
  transition: 0.3s;

  ${(props) =>
    props.type === "red" &&
    `
    background: ${theme.colors.accent};
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors.darkRed};
    }
  `}

  ${(props) =>
    props.type === "plan-button" &&
    `
    letter-spacing: 1px;
    color: ${theme.colors.main};
    font-weight: 500;
    color: ${props.accent ? "white" : theme.colors.mainDark};
    background: ${props.accent ? theme.colors.main : "rgba(73, 190, 175, 0.2)"};
    width: 250px;
    padding: 15px;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    @media (max-width: 1200px) {
      width: 100%;
    }
    &:hover {
      background: ${
        props.accent ? theme.colors.mainDark : "rgba(73, 190, 175, 0.3)"
      };
    }
  `}

  ${(props) =>
    props.type === "order-button" &&
    `
    background: ${theme.colors.main};
    color: ${theme.colors.white};
    padding: 5px;
    &:hover {
      background: ${theme.colors.mainDark};
    }
  `}

  ${(props) =>
    props.type === "secondary" &&
    css`
      border: 1px solid ${theme.colors.main};
      color: ${theme.colors.main};
      ${ButtonText} {
        color: ${theme.colors.main};
      }
      &:hover {
        border: 1px solid ${theme.colors.main};
        background: ${theme.colors.mainDark};
        ${ButtonText} {
          color: white;
        }
      }
    `}

  ${(props) =>
    props.type === "white-hollow" &&
    css`
      border: 1px solid white;
      color: white;
      &:hover {
        background: white;
        ${ButtonText} {
          color: ${theme.colors.medDarkGrey};
        }
      }
    `}

    ${(props) =>
    props.type === "main" &&
    `
    background: ${theme.colors.main};
    color: ${theme.colors.white};
    &:hover {
      ${
        !props.disabled &&
        `
      background: ${theme.colors.mainDark};
      `
      }
    }
  `}

${(props) =>
    props.type === "main-new" &&
    `
    background: ${theme.colors.mainNew};
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors.mainDark};
    }
  `}

${(props) =>
    props.type === "main-dark" &&
    `
    background: ${theme.colors.darkGreen};
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors.darkGrey};
    }
  `}

${(props) =>
    props.type === "orange" &&
    `
    background: ${theme.colors.orange};
    color: ${theme.colors.white};
    &:hover {
      background: ${(p) =>
        p.disabled ? theme.colors.orange : theme.colors.orangeDark};
    }
  `}

    ${(props) =>
    props.type === "main-light" &&
    `
    background: ${theme.colors.main};
    color: ${theme.colors.white};
    opacity: 0.5;
    &:hover {
      opacity: 0.7;
    }
  `}

    ${(props) =>
    props.type === "light-grey" &&
    `
    background: ${theme.colors.lightGrey};
    color: ${theme.colors.white};
  `}

    ${(props) =>
    props.type === "green" &&
    `
    background: ${theme.colors.green};
    color: ${theme.colors.white};
  `}

  ${(props) => props.buttonStyle};

  @media screen and (max-width: 800px) {
    ${(props) => props.mobile100 && "width: 100%;"}
  }
`;

export const ButtonText = styled.div`
  font-size: 14px;
  color: ${theme.colors.white};
  font-weight: 400;
  ${(props) => props.fontStyle};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const ButtonIcon = styled.img`
  height: 18px;
  padding: 0px;
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

class Button extends Component {
  render() {
    let icon = null;
    if (this.props.icon === "check") icon = checkIcon;
    else if (this.props.icon === "plus") icon = plusIcon;
    else if (this.props.icon === "clock") icon = clockIcon;
    else if (this.props.icon === "x") icon = xIcon;
    else if (this.props.icon === "email") icon = emailIcon;
    else if (this.props.icon === "shopify") icon = shopifyIcon;
    else if (this.props.icon === "star") icon = starIcon;
    return (
      <StyledButton
        className={this.props.className}
        mobile100={this.props.mobile100}
        buttonStyle={this.props.buttonStyle}
        accent={this.props.accent}
        type={this.props.type}
        fixedWidth={this.props.fixedWidth}
        onClick={this.props.disabled ? null : this.props.onClick}
        disabled={this.props.disabled}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        {this.props.isFetching ? (
          <MDSpinner
            singleColor={
              this.props.type === "secondary"
                ? "rgb(73,190,175)"
                : "rgb(255, 255, 255)"
            }
            size={20}
          />
        ) : (
          <ButtonWrapper>
            {icon && <ButtonIcon src={icon} alt="" />}
            <ButtonText
              fontStyle={this.props.fontStyle}
              type={this.props.type}
              // className={this.props.className}
            >
              {this.props.text}
            </ButtonText>
            {this.props.rightArrow && (
              <ButtonIcon
                src={arrowRight}
                alt=""
                style={{
                  height: "15px",
                  marginLeft: "15px",
                  marginRight: "0px",
                }}
              />
            )}
          </ButtonWrapper>
        )}
      </StyledButton>
    );
  }
}

export default Button;
