import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchUncategorizeProducts,
  updateUncategorizeProduct,
  selectAllUncategorizeProducts,
  saveUncategorizeProducts,
} from "../../../views/admin/AdminActions";
import {
  getUncategorizeProducts,
  getIsFetching,
  getPagination,
} from "../../../views/admin/AdminSelectors";

import { getSettings } from "../../../Selectors";
import Pagination from "../../../components/Pagination";
import { Row } from "../../../components/Layout";
import ButtonNew from "../../../components/ButtonNew";
import { Container } from "../../../components/Container";
import { getUrlParameter } from "../../../modules/Format";
import Text from "../../../modules/Text";
import { get, isEmpty } from "lodash";
import Search from "../../../components/Search";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { Switch } from "../../../components";
import MDSpinner from "react-md-spinner";
import newGoogleCategories from "../../../modules/NewGoogleCategories";
import { Button } from "@material-ui/core";

const TagProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  border: 2px solid
    ${(p) => (p.active ? theme.colors.main : theme.colors.lightBorder)};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.darkGrey};
`;

const TagBorder = styled.div`
  display: flex;
  flex-direction: column;
  border: 5px solid white;
  ${(p) =>
    p.active &&
    p.type === "remove" &&
    `border: 5px solid ${theme.colors.darkRed}`};
  ${(p) =>
    p.active && p.type === "add" && `border: 5px solid ${theme.colors.main}`};
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 220px;
`;

const TagProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.lightBorder};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

const TagProductImage = styled.img`
  height: 200px;
  width: 200px;
  object-fit: contain;
`;

const TagProductTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.darkGrey};
  margin-bottom: 5px;
`;

const TopText = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${theme.colors.darkGrey};
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  products: getUncategorizeProducts(state),
  settings: getSettings(state),
  pagination: getPagination(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchUncategorizeProducts,
      updateUncategorizeProduct,
      selectAllUncategorizeProducts,
      saveUncategorizeProducts,
    },
    dispatch
  ),
});

const defaultProps = {
  isFetching: false,
  products: [],
  settings: {},
  pagination: {},
};

const TagProduct = ({ title, image, active, onClick, showTitle, type }) => (
  <TagProductWrapper onClick={onClick}>
    <TagBorder active={active} type={type}>
      {showTitle && <TagProductTitle>{title}</TagProductTitle>}
      <TagProductImage src={image} />
    </TagBorder>
  </TagProductWrapper>
);

// const categories = [
//   { category: "music", name: "Music" },
//   { category: "gifts", name: "Gifts & Misc" },
//   { category: "clothing_mens", name: "Mens Clothing" },
//   { category: "home_garden", name: "Home & Garden" },
//   { category: "wellness", name: "Wellness" },
//   { category: "bags_category", name: "Bags & Luggage" },
//   { category: "beauty_health", name: "Beauty & Health" },
//   { category: "jewelry", name: "Jewelry" },
//   { category: "watches_category", name: "Watches" },
//   { category: "food_drink", name: "Food & Beverage" },
//   { category: "mother_kids", name: "Parenting & Kids" },
//   { category: "pets", name: "Pets" },
//   { category: "sports_fitness", name: "Sports & Fitness" },
//   { category: "outdoor", name: "Outdoor" },
//   { category: "party_event", name: "Party & Event" },
//   { category: "womens_clothing", name: "Womens Clothing" },
//   { category: "stationery", name: "Stationery" },
//   { category: "tech_accessories", name: "Tech & Accessories" },
//   { category: "accessories", name: "Accessories" },
// ];

class Products extends Component {
  state = { search: "", showTitle: true };

  componentDidMount = () => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const category = getUrlParameter("category", this.props.location);
    const type = getUrlParameter("type", this.props.location) || "remove";
    const subcategory =
      getUrlParameter("subcategory", this.props.location) || "";
    this.props.fetchUncategorizeProducts(
      category,
      subcategory,
      page,
      searchTerm,
      supplier,
      type
    );
  };

  saveUncategorizeProducts = () => {
    const products = get(this.props, "products");
    const category = getUrlParameter("category", this.props.location);
    const subcategory = getUrlParameter("subcategory", this.props.location);
    const type = getUrlParameter("type", this.props.location) || "remove";
    const filteredProducts =
      products &&
      products.filter((product) => product.active).map((product) => product.id);
    this.props.saveUncategorizeProducts(
      category,
      subcategory,
      filteredProducts,
      type
    );
  };

  updateSubcategory = (subcategory) => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const category = getUrlParameter("category", this.props.location) || "";
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const type = getUrlParameter("type", this.props.location) || "remove";
    this.props.history.push(
      `/admin/categorize?category=${category}&subcategory=${subcategory}&page=${page}&search=${searchTerm}&supplier=${supplier}&type=${type}`
    );
    this.props.fetchUncategorizeProducts(
      category,
      subcategory,
      page,
      searchTerm,
      supplier,
      type
    );
  };

  updateCategory = (category) => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const type = getUrlParameter("type", this.props.location) || "remove";
    this.props.history.push(
      `/admin/categorize?category=${category}&page=${page}&search=${searchTerm}&supplier=${supplier}&type=${type}`
    );
    this.props.fetchUncategorizeProducts(
      category,
      "",
      page,
      searchTerm,
      supplier,
      type
    );
  };

  updateType = (type) => {
    const category = getUrlParameter("category", this.props.location);
    const subcategory = getUrlParameter("subcategory", this.props.location);
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const page = getUrlParameter("page", this.props.location) || 1;
    this.props.history.push(
      `/admin/categorize?category=${category}&subcategory=${subcategory}&page=${page}&search=${searchTerm}&supplier=${supplier}&type=${type}`
    );
    this.props.fetchUncategorizeProducts(
      category,
      subcategory,
      page,
      searchTerm,
      supplier,
      type
    );
  };

  updatePage = (page) => {
    const category = getUrlParameter("category", this.props.location);
    const subcategory = getUrlParameter("subcategory", this.props.location);
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const type = getUrlParameter("type", this.props.location) || "remove";
    this.props.history.push(
      `/admin/categorize?category=${category}&subcategory=${subcategory}&page=${page}&search=${searchTerm}&supplier=${supplier}&type=${type}`
    );
    this.props.fetchUncategorizeProducts(
      category,
      subcategory,
      page,
      searchTerm,
      supplier,
      type
    );
  };

  searchForProducts = () => {
    let category = getUrlParameter("category", this.props.location);
    let subcategory = getUrlParameter("subcategory", this.props.location) || "";
    const supplier = getUrlParameter("supplier", this.props.location) || "";
    const type = getUrlParameter("type", this.props.location) || "remove";
    this.props.history.push(
      `/admin/categorize?category=${category}&subcategory=${subcategory}&search=${this.state.search}&supplier=${supplier}&type=${type}`
    );
    this.props.fetchUncategorizeProducts(
      category,
      subcategory,
      1,
      this.state.search,
      supplier,
      type
    );
  };

  onEnterPress = (e) => {
    if (e.key === "Enter") {
      this.searchForProducts();
    }
  };

  render() {
    let category = getUrlParameter("category", this.props.location);
    let subcategory = getUrlParameter("subcategory", this.props.location) || "";

    const type = getUrlParameter("type", this.props.location) || "remove";

    const products = get(this.props, "products");

    const categories = newGoogleCategories.subs;

    return (
      <Container>
        <Button
          onClick={() => this.props.history.push("/admin/uncategorize")}
          style={{ margin: "0px 0px 12px -8px" }}
        >
          Take me to the new page
        </Button>
        <Text bold extra="margin-bottom: 5px">
          Categories
        </Text>
        <Row style={{ marginBottom: "15px", flexWrap: "wrap" }}>
          {Object.keys(categories).map((cat) => (
            <Tag
              key={cat}
              onClick={() => this.updateCategory(cat)}
              active={cat === category}
            >
              {categories[cat].name}
            </Tag>
          ))}
          <Tag onClick={() => this.updateCategory("")} active={category === ""}>
            Uncategorized
          </Tag>
        </Row>

        <Text bold extra="margin-bottom: 5px">
          Subcategories
        </Text>
        <Row style={{ marginBottom: "15px", flexWrap: "wrap" }}>
          {category !== "" &&
            Object.keys(categories[category].subs).map((subcat, idx) => {
              const subData = get(categories, [
                category,
                "subs",
                subcat,
                "name",
              ]);
              return (
                <Tag
                  key={idx}
                  onClick={() => this.updateSubcategory(subcat)}
                  active={subcat === subcategory}
                >
                  {subData}
                </Tag>
              );
            })}
        </Row>

        <Row align style={{ marginBottom: "20px" }}>
          <TopText>
            {type === "add" ? "ADDING TO CATEGORY" : "REMOVING FROM CATEGORY"}
          </TopText>

          <Text.Small
            light
            link
            extra="margin-left: 10px;"
            onClick={() => this.updateType(type === "add" ? "remove" : "add")}
          >
            {type === "add" ? "Switch to Removing" : "Switch to Adding"}
          </Text.Small>
        </Row>

        <Row align style={{ marginBottom: "30px" }}>
          <Search
            onChange={(search) => this.setState({ search })}
            search={this.state.search}
            initialSearchTerm=""
            searchForProducts={this.searchForProducts}
            onEnterPress={this.onEnterPress}
          />
          <ButtonNew
            type="main"
            text="Save Changes"
            extra="margin-right: 30px; margin-left: 30px; width: 255px;"
            onClick={() => this.saveUncategorizeProducts()}
          />
          <Switch
            active={this.state.showTitle}
            label="Show Titles"
            toggle={() => this.setState({ showTitle: !this.state.showTitle })}
          />
          <ButtonNew
            type="main"
            text="Select All"
            extra="margin-right: 30px; margin-left: 30px; width: 255px;"
            onClick={() => this.props.selectAllUncategorizeProducts(true)}
          />
          <ButtonNew
            type="main"
            text="Deselect All"
            extra="margin-right: 30px; margin-left: 30px; width: 255px;"
            onClick={() => this.props.selectAllUncategorizeProducts(false)}
          />
        </Row>

        {!isEmpty(this.props.pagination) && (
          <Pagination
            onClick={this.updatePage}
            pagination={this.props.pagination}
          />
        )}

        <div style={{ marginTop: "15px" }}>
          {this.props.isFetching > 0 ? (
            <React.Fragment>
              <MDSpinner singleColor="rgb(73,190,175)" size={60} />
            </React.Fragment>
          ) : (
            <div>
              {/* <Row style={{ marginBottom: "20px" }}>
                <SectionTitle>In Category</SectionTitle>
                <Text
                  light
                  link
                  onClick={() =>
                    this.props.updateAll({
                      tag: subcategory != "" ? subcategory : category,
                      type: "remove",
                      productIds: matchingProducts.map((product) => product.id),
                    })
                  }
                >
                  Remove All
                </Text>
              </Row> */}

              <TagProductsWrapper>
                {products &&
                  products.map((product) => (
                    <TagProduct
                      key={product.id}
                      showTitle={this.state.showTitle}
                      active={product.active}
                      image={product.image}
                      title={product.title}
                      type={type}
                      onClick={() =>
                        this.props.updateUncategorizeProduct(product.id)
                      }
                    />
                  ))}
                {products && products.length === 0 && (
                  <div>{category ? "No products" : "Select a category"}</div>
                )}
              </TagProductsWrapper>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

Products.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Products);
