import React from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { UnselectableText } from "../Container";

const MenuOptionWrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 10px;
  color: ${theme.colors.medDarkGrey};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.darkGrey};
    background: ${theme.colors.ultraLightGrey};
  }
`;

const MenuOptionText = styled(UnselectableText)`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export const MenuOption = ({ icon, text, onClick }) => (
  <MenuOptionWrapper onClick={onClick}>
    {icon}
    <MenuOptionText>{text}</MenuOptionText>
  </MenuOptionWrapper>
);
