import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { updateStateDirectly } from "../../../../Actions";
import { theme } from "../../../../modules/Theme";
import { fetchSupplierSettings } from "../../../../views/supplier/SupplierActions";
import {
  getSettings,
  getIsFetchingSettings,
} from "../../../../views/supplier/SupplierSelectors";
import {
  ButtonNew,
  BorderBox,
  Row,
  Column,
  Spinner,
} from "../../../../components";
import styled from "styled-components";
import Text from "../../../../modules/Text";
import { getUrlParameter } from "../../../../modules/Format";
import createNotification from "../../../../modules/Notification";
import { isEmpty } from "lodash";
import { postRequest, getRequest } from "../../../../modules/API";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "../../../../components/Modal";

async function startAsyncSession() {
  const stripeKey =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STRIPE_CHECKOUT_TEST_API_KEY
      : process.env.REACT_APP_STRIPE_CHECKOUT_LIVE_API_KEY;

  const stripe = await loadStripe(stripeKey);

  getRequest({
    url: "supplier_stripe_session/",
  })
    .then((response) => {
      const sessionId = get(response, ["data", "session", "id"]);
      if (sessionId) {
        stripe.redirectToCheckout({
          sessionId: sessionId,
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
      createNotification({
        type: "danger",
        title: "Error",
        message: "Error saving billing method",
      });
    });
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchSupplierSettings,
      updateStateDirectly,
    },
    dispatch
  ),
});

class Billing extends Component {
  state = { isFetching: false, showCancelPopup: false };

  setPlanActive = (bool) => {
    this.props.updateStateDirectly(
      [
        { key: "settings" },
        {
          key: "payment_plan",
          fields: [["is_active", bool]],
        },
      ],
      "supplier"
    );
  };

  componentDidMount = () => {
    // To avoid a race-condition, we manually exclude the app from fetching supplier settings globally if the page is /billing
    // And instead, do it here so that we can chain the promise
    this.props.fetchSupplierSettings().then(() => {
      const paymentPlan = get(this.props, ["settings", "payment_plan"]);
      let action = getUrlParameter("action", this.props.location);
      let result = getUrlParameter("result", this.props.location);
      let sessionId = getUrlParameter("session_id", this.props.location);
      if (action === "payment" && result === "success" && sessionId) {
        this.setState({ isFetching: true });
        postRequest({
          url: "supplier_payment_method_auth/",
          data: { session_id: sessionId, supplier_plan_id: paymentPlan.id },
        })
          .then((response) => {
            this.setState({ isFetching: false });
            createNotification({
              type: "success",
              title: "Success",
              message: "Created New Plan Successfully",
            });
            if (response.data.start_date) {
              this.setPlanActive(true);
            }
          })
          .catch(() => {
            createNotification({
              type: "danger",
              title: "Error",
              message: "Error saving billing method",
            });
          });
      }
    });
  };

  cancelSubscription = () => {
    const paymentPlan = get(this.props, ["settings", "payment_plan"]);
    this.setState({ isFetching: true });

    postRequest({
      url: "supplier_billing/",
      data: { plan_id: paymentPlan.id, cancel: true },
    }).then(() => {
      this.setPlanActive(false);
      this.setState({ showCancelPopup: false, isFetching: false });
      createNotification({
        type: "success",
        title: "Success",
        message: "Plan deleted successfully",
      });
    });
  };

  createSubscription = () => {
    this.setState({ isFetching: true });
    const paymentPlan = get(this.props, ["settings", "payment_plan"]);
    postRequest({
      url: "supplier_billing/",
      data: { plan_id: paymentPlan.id },
    }).then((response) => {
      this.setState({ isFetching: false });
      createNotification({
        type: "success",
        title: "Success",
        message: "Created New Plan Successfully",
      });
      if (response.data.start_date) {
        this.setPlanActive(true);
      }
    });
  };

  getFrequency = (type) => {
    if (type === "MONTHLY") {
      return "Every Month";
    } else if (type === "SIX_MONTHS") {
      return "Every 6 Months";
    } else if (type === "YEARLY") {
      return "Every Year";
    }
  };

  render() {
    const paymentPlan = get(this.props, ["settings", "payment_plan"]);
    const active = get(this.props, ["settings", "payment_plan", "is_active"]);

    if (this.props.isFetching || isEmpty(this.props.settings)) {
      return (
        <BorderBox width="400px">
          <Spinner />
        </BorderBox>
      );
    }

    if (!paymentPlan) {
      return (
        <PlanBox style={{ maxWidth: "100%" }}>
          <PlanBoxSection
            style={{
              justifyContent: "center",
            }}
          >
            <Text.Large bold>Free Plan</Text.Large>
          </PlanBoxSection>
          <PlanBoxSection
            style={{
              justifyContent: "center",
              borderTop: `1px solid ${theme.colors.lightBorder}`,
            }}
          >
            <Text light margin="0 0 10px 0">
              &#8226; 10% Commission On Sales
            </Text>
            <Text light margin="0 0 10px 0">
              &#8226; Unlimited Vendors & Orders
            </Text>
            <Text.Small bold margin="25px 0 10px 0">
              Want to discuss volume pricing?
            </Text.Small>
            <ButtonNew
              onClick={() =>
                window.open("https://calendly.com/dropcommerce/demo")
              }
              text="Contact our sales team"
              size="large"
            />
          </PlanBoxSection>
        </PlanBox>
      );
    }

    return (
      <div>
        {this.state.showCancelPopup && (
          <Modal
            maxWidth="600px"
            minHeight="300px"
            hide={() => this.setState({ showCancelPopup: false })}
          >
            <Text.Large margin="0 0 15px 0">
              Are you sure you want to cancel?
            </Text.Large>
            <Text light margin="0 0 15px 0">
              All your active vendors will lose access to your products
              immediately and your program will no longer be active.
            </Text>
            <Text light margin="0 0 15px 0">
              Be very careful selecting this option.
            </Text>
            <Row>
              <ButtonNew
                size="large"
                text="Keep My Plan"
                onClick={() => this.setState({ showCancelPopup: false })}
              />
              <ButtonNew
                size="large"
                text="Cancel Plan"
                type="secondary"
                isFetching={this.state.isFetching}
                onClick={this.cancelSubscription}
                margin="0 0 0 15px"
              />
            </Row>
          </Modal>
        )}

        {paymentPlan && (
          <PlanBox>
            <PlanBoxHeader>
              <Text.Large bold>Custom Plan</Text.Large>
              <Column style={{ alignItems: "flex-end" }}>
                <Text.Large bold green>{`$${paymentPlan.price.toFixed(
                  2
                )}`}</Text.Large>
                <Text extra="font-size: 14px;" light>
                  {this.getFrequency(paymentPlan.plan_type)}
                </Text>
              </Column>
            </PlanBoxHeader>
            <PlanBoxSection>
              <Text margin="0 0 10px 0">Details</Text>
              <Text light margin="0 0 10px 0">
                &#8226; All Core DropCommerce Features
              </Text>
              <Text light margin="0 0 10px 0">
                &#8226; {paymentPlan.commission}% Commission On Sales
              </Text>
              {active ? (
                <ButtonNew
                  onClick={() => this.setState({ showCancelPopup: true })}
                  text="Cancel Plan"
                  type="secondary"
                  size="large"
                  isFetching={this.state.isFetching}
                  margin="15px 0 0 0"
                />
              ) : (
                <ButtonNew
                  onClick={() => {
                    this.setState({ isFetching: true });
                    const stripeConnected = get(this.props, [
                      "settings",
                      "stripe_card_saved",
                    ]);
                    if (stripeConnected) {
                      this.createSubscription();
                    } else {
                      startAsyncSession();
                    }
                  }}
                  text={
                    paymentPlan.trial_days > 0
                      ? `Start Free ${paymentPlan.trial_days} Day Trial`
                      : "Start Plan"
                  }
                  isFetching={this.state.isFetching}
                  size="large"
                  margin="15px 0 0 0"
                />
              )}
            </PlanBoxSection>

            {active && (
              <PlanBoxSection
                style={{
                  justifyContent: "center",
                  borderTop: `1px solid ${theme.colors.lightBorder}`,
                }}
              >
                <Text.Small center light>
                  Looking for more features?
                  <Text.Small
                    link
                    margin="0 0 0 5px"
                    onClick={() =>
                      window.open("https://calendly.com/dropcommerce/demo")
                    }
                  >
                    Talk to our sales team
                  </Text.Small>
                </Text.Small>
              </PlanBoxSection>
            )}
          </PlanBox>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Billing)
);

const PlanBox = styled.div`
  width: 450px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
`;

const PlanBoxHeader = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
`;

const PlanBoxSection = styled.div`
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`;
