export const INITIAL_CATEGORIES = [
  {
    name: "Home & Garden",
    value: "home_garden",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_500/v1590131281/veeevu0vhdgrc4d8cnfn.jpg",
  },
  {
    name: "Health & Wellness",
    value: "health_wellness",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_500/v1583963263/v26ii02fcil9u7mavv89.jpg",
  },
  {
    name: "Clothing",
    value: "clothing",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_500/v1590133390/vm2ptdg3j7l1bmlhkiwu.jpg",
  },
  {
    name: "Party, Event & Gifts",
    value: "party_event_gifts",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_500/v1596843274/leab45zr3ggkaxhsdbps.jpg",
  },
  {
    name: "Parenting & Kids",
    value: "parenting_kids",
    selected: false,
    image:
      "https://s3.ca-central-1.amazonaws.com/dropcommerce-images/tushbaby_inc_447729.jpg",
  },
  {
    name: "Pets",
    value: "pets",
    selected: false,
    image:
      "https://s3.ca-central-1.amazonaws.com/dropcommerce-images/us_essentials_dog_bed_us_essentials340272.jpg",
  },
  {
    name: "Beauty",
    value: "beauty",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_500/v1592680340/adxrwgmpnp8ejhbgwguz.jpg",
  },
  {
    name: "Food & Drink",
    value: "food_drink",
    selected: false,
    image:
      "https://shivelightbeverage.com/wp-content/uploads/2019/08/apple-shrub-drink.png",
  },
  {
    name: "Sports & Outdoors",
    value: "sports_outdoors",
    selected: false,
    image:
      "https://s3.ca-central-1.amazonaws.com/dropcommerce-images/us_essentials_fitness_jump_rope_us_essentials340284.jpg",
  },
  {
    name: "Stationery & Crafts",
    value: "stationery_crafts",
    selected: false,
    image:
      "https://res.cloudinary.com/dropcommerce/image/upload/h_150/v1628443679/tijxlqxenjsmdz4lrhkp.jpg",
  },
  {
    name: "Tech accessories",
    value: "tech_accessories",
    selected: false,
    image:
      "https://dnvlxjuzig5dx.cloudfront.net/eyJidWNrZXQiOiJkcm9wY29tbWVyY2UtaW1hZ2VzIiwia2V5IjoiYWNvcG93ZXJfMzg1ODQ1LmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJoZWlnaHQiOjUwMH19fQ==",
  },
  {
    name: "Toys & Games",
    value: "toys_games",
    selected: false,
    image:
      "https://cdn.shopify.com/s/files/1/0339/1605/9786/products/Website-ProductPhotoTemplate_FrontPhoto_22.jpg?v=1644958314",
  },
];

export const INITIAL_FILTERS = {
  page: 1,
  search: "",
  category: "",
  subcategory: "",
  product_type: "all",
  moreFilters: {
    show: false,
    filters: [
      {
        label: "Supplier Country",
        maxOneSelected: true,
        options: [
          { value: "country_us", label: "United States", selected: false },
          { value: "country_canada", label: "Canada", selected: false },
        ],
      },
      {
        label: "Ships To",
        options: [
          { value: "ships_to_us", label: "United States", selected: false },
          { value: "ships_to_canada", label: "Canada", selected: false },
          { value: "ships_intl", label: "All Countries", selected: false },
        ],
      },
      {
        label: "Other",
        options: [
          {
            value: "my_suppliers",
            label: "My Suppliers",
            selected: false,
          },
          {
            value: "no_approval_required",
            label: "No Approval Required",
            selected: false,
          },
          {
            value: "branded_invoicing",
            label: "Branded Invoicing",
            selected: false,
          },
        ],
      },
      {
        label: "Manufacturing Country",
        maxOneSelected: true,
        options: [
          {
            value: "made_in_us",
            label: "Made In US",
            selected: false,
          },
          {
            value: "made_in_canada",
            label: "Made In Canada",
            selected: false,
          },
        ],
      },
      {
        label: "Values",
        options: [
          {
            value: "eco_friendly",
            label: "Eco Friendly",
            selected: false,
          },
          {
            value: "organic",
            label: "Organic",
            selected: false,
          },
          {
            value: "handmade",
            label: "Handmade",
            selected: false,
          },
          {
            value: "fair_trade",
            label: "Fair Trade",
            selected: false,
          },
          {
            value: "kosher",
            label: "Kosher",
            selected: false,
          },
          {
            value: "women_owned",
            label: "Women Owned Business",
            selected: false,
          },
          // {
          //   value: "bipoc",
          //   label: "BIPOC Owned Business",
          //   selected: false,
          // },
          {
            value: "vegan",
            label: "Vegan",
            selected: false,
          },
          {
            value: "small_batch",
            label: "Small Batch",
            selected: false,
          },
          {
            value: "social_good",
            label: "Social Good",
            selected: false,
          },
        ],
      },
    ],
  },
  sort: {
    // min: "",
    show: false,
    optionsType: "radio",
    options: [
      {
        label: "Recommended",
        value: "recommended",
        hint: "The discounted price you will pay the supplier to fulfill an order.",
      },
    ],
  },
  price: {
    show_dollar_symbol: true,
    min: "",
    max: "",
    show: false,
    description: "*This filter uses the average variant price for each product",
    optionsField: "price_type",
    optionsValue: "cost",
    optionsType: "radio",
    options: [
      {
        label: "Price",
        value: "cost",
        hint: "The discounted price you will pay the supplier to fulfill an order.",
      },
      {
        label: "Retail Price",
        value: "retail_price",
        hint: "The supplier's Retail Price that they normally sell to customers for.",
      },
      // {
      //   label: "DC Fulfillment Price",
      //   value: "dc_fulfillment_price",
      //   hint: "The price for this product if ordered to the DC Fulfillment Center.",
      // },
    ],
  },
  profit: {
    show_dollar_symbol: true,
    min: "",
    show: false,
    description:
      "*Estimated profit if sold at retail price, based on average variant price",
  },
  shipping: {
    show_dollar_symbol: true,
    max: "",
    show: false,
    optionsField: "shipping_country",
    optionsValue: "US",
    optionsType: "radio",
    options: [
      {
        label: "United States",
        value: "US",
        hint: "Find products with a US shipping rate below your max value, or suppliers who have at least one product that fits this criteria.",
      },
      // { label: "Canada", value: "CA" },
      // { label: "Rest Of World", value: "INTL" },
    ],
  },
};

export const platformOptions = [
  {
    value: "Shopify",
    label: "Shopify",
  },
  {
    value: "Shoplazza",
    label: "Shoplazza",
  },
  {
    value: "WooCommerce",
    label: "WooCommerce",
  },
  {
    value: "BigCommerce",
    label: "BigCommerce",
  },
  {
    value: "Wix",
    label: "Wix",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const countryOptions = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
