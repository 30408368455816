import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRecipients, getIsFetchingRecipients } from "../../../Selectors";
import { Spinner } from "../../../components";
import { Modal } from "../../../components/Modal";
import { fetchRecipients, createNewConversation } from "../../../Actions";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  recipients: getRecipients(state),
  isFetching: getIsFetchingRecipients(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchRecipients, createNewConversation }, dispatch),
});

const RecipientsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 400px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  margin-top: 30px;
`;

const Recipient = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 15px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const RecipientName = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
`;

const RecipientDescription = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  margin-top: 5px;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
  margin-bottom: 20px;
`;

const Search = styled.input`
  width: 100%;
  padding: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;

  ::placeholder {
    color: ${theme.colors.medGrey};
  }
`;

class RecipientModal extends Component {
  state = {
    search: "",
  };

  componentDidMount = () => {
    this.props.fetchRecipients();
  };

  createNewConversation = (recipientId) => {
    const inboxPath = this.props.match.path.includes("supplier")
      ? "/supplier/inbox"
      : "/inbox";

    this.props.createNewConversation(recipientId).then((response) => {
      if (response.label) {
        this.props.history.push(`${inboxPath}/${response.label}`);
        this.props.hide();
      }
    });
  };

  render() {
    const recipients = this.props.recipients.filter((r) =>
      r.name.toLowerCase().includes(this.state.search.toLowerCase())
    );

    return (
      <Modal hide={this.props.hide} maxWidth="800px">
        <Title>Start A New Conversation</Title>

        <Search
          value={this.state.search}
          onChange={(e) => this.setState({ search: e.target.value })}
          placeholder="Type to filter recipients by name"
        />

        <RecipientsList>
          {this.props.isFetching && <Spinner />}

          {!this.props.isFetching &&
            recipients
              .filter((r) => r.name)
              .map((recipient) => (
                <Recipient
                  key={recipient.id}
                  onClick={() => this.createNewConversation(recipient.user)}
                >
                  <RecipientName>{recipient.name}</RecipientName>

                  {recipient.description && (
                    <RecipientDescription>
                      {recipient.description}
                    </RecipientDescription>
                  )}
                </Recipient>
              ))}
        </RecipientsList>
      </Modal>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipientModal)
);
