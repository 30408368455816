import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import xIcon from "../../../../../images/denied-icon.png";

export const ChangeValueInput = styled.input`
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  width: 160px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
`;

export const ChangeValueButton = styled.div`
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background: #fafafa;
    color: ${theme.colors.main};
  }
`;

export const ChangeValuePopup = styled.div`
  display: flex;
  padding: 5px;
  background: #fafafa;
  position: absolute;
  top: 25px;
  right: 1px;
  z-index: 1000;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  align-items: center;
`;

export const CloseIcon = styled.img`
  height: 18px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

class ChangeValue extends Component {
  render() {
    return (
      <ChangeValuePopup onClick={(e) => e.stopPropagation()}>
        <ChangeValueInput
          placeholder={
            this.props.type === "value" ? "Enter Value" : "Enter multiplier"
          }
          onChange={this.props.onChange}
          type="number"
        />
        <ChangeValueButton onClick={this.props.apply}>Apply</ChangeValueButton>
        <CloseIcon onClick={this.props.close} src={xIcon} />
      </ChangeValuePopup>
    );
  }
}

export default ChangeValue;
