export const getRegionFromCode = (code) => {
  const regions = {
    US_WEST: "US West",
    US_CENTRAL: "US Central",
    US_EAST: "US East",
    CA_WEST: "CA West",
    CA_CENTRAL: "CA Central",
    CA_EAST: "CA East",
  };

  return regions[code];
};

export const getCountryFromCode = (code) => {
  const countries = {
    US: "United States",
    CA: "Canada",
    INTL: "International",
  };

  return countries[code];
};

export const getCodeFromCountry = (code) => {
  const countries = {
    "United States": "US",
    Canada: "CA",
  };

  return countries[code];
};
