import React, { Component } from "react";
import { Column } from "./Layout";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { help } from "../images/icons/feather";
import { withRouter } from "react-router-dom";

class HelpBubble extends Component {
  render() {
    return (
      <HelpBubbleWrapper>
        <HelpBubbleContainer
          onClick={() => window.open("https://help.dropcommerce.com")}
        >
          <Column>
            <HelpBubbleText>Help Center</HelpBubbleText>
            <HelpBubbleTextSmall>Learn The Basics</HelpBubbleTextSmall>
          </Column>
          <HelpIcon src={help} />
        </HelpBubbleContainer>
      </HelpBubbleWrapper>
    );
  }
}

export default withRouter(HelpBubble);

export const HelpBubbleWrapper = styled.div`
  transition: all 0.3s;
  padding: 12px;
  border-radius: 4px;
  display: inline-block;
  margin: 15px;
  background: ${theme.colors.main};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.mainDark};
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const HelpBubbleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HelpIcon = styled.img`
  height: 26px;
  width: 26px;
  margin-left: 15px;
`;

export const HelpBubbleText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: white;
  display: inline-block;
  white-space: nowrap;
`;

export const HelpBubbleTextSmall = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: white;
  display: inline-block;
  min-width: 120px;
`;
