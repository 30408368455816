import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { runAdminCommand } from "../../../../views/admin/AdminActions";
import Text from "../../../../modules/Text";
import { Input, ButtonNew, Switch } from "../../../../components";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import Select from "react-select";
import { getRequest } from "../../../../modules/API";
import dayjs from "dayjs";
import { Calendar } from "../../../../components/Calendar";
import { StyledTextArea } from "../../../orders/OrderDetails";
import { Show } from "../../../../components/Show";
import OrderShippingAddress from "./OrderShippingAddress";
import { ShopifyProductImport } from "./ShopifyProductImport";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ runAdminCommand }, dispatch),
});

class Commands extends Component {
  state = {
    completed: false,
    isFetching: false,
    showConfirm: false,
    errors: [],
    action: null,
    UPDATE_USER: { account_type: null, email: "", account_id: null },
    SYNC_SUPPLIER: { supplier_id: null, sync_pricing: false },
    SHOPIFY_SUP_FAILED_ORDER: {
      supplier_id: null,
      supplier_order_id: null,
    },
    COPY_PREBUILT_IMPORTS: {
      store_platform_uid: null,
      source_platform_uid: null,
      confirmed: false,
      pending_products: 0,
    },
    CSV_IMPORT: {
      csv_url: "",
      supplier_id: null,
      calculate_price: false,
      csv_model: "DropCommerce",
    },
    SHOPIFY_IMPORT: {
      supplier_id: null,
      collections: null,
      collections_count: null,
    },
    RUN_OUTREACH: {
      status: null,
      email: "",
    },
    REMOVE_SUPPLIER: {
      reason: null,
    },
    MERCHANT_MISSING_ORDER: {
      order_name: null,
    },
    TRANSFER_OWNERSHIP: {
      create_user: false,
      override_store: false,
      email: "",
    },
    SUPPLIER_ON_HOLIDAY: {
      notify_merchants: false,
      start_date: null,
      end_date: null,
    },
    MANAGE_DISCOUNTS: { plan_discount: 0, premade_discount: 0 },
    showChangeOrderShippingAddress: false,
  };

  updateData = (key, field, value) => {
    this.setState((prevState) => ({
      [key]: { ...prevState[key], [field]: value },
    }));
  };

  runCommand = (type) => {
    // VALIDATE USER INPUT
    const validatedData = this.validate(type);

    if (validatedData) {
      this.setState({ isFetching: true });
      this.props.runAdminCommand(type, validatedData).then((response) => {
        if (response.success) {
          this.setState({ isFetching: false, completed: true });

          if (type === "CREATE_SUPPLIER") {
            this.setState({ newSupplierData: response });
          } else if (type === "COPY_PREBUILT_IMPORTS") {
            this.setState((prevState) => ({
              COPY_PREBUILT_IMPORTS: {
                ...prevState.COPY_PREBUILT_IMPORTS,
                confirmed: true,
                pending_products: response.pending_products,
              },
              completed: !response.pending_products,
            }));
          }
        } else {
          // FAILED
          this.setState({
            isFetching: false,
            showConfirm: true,
            errors: ["API: Something failed when running the command."],
          });
        }
      });
    }
  };

  validate = (type) => {
    let errors = [];
    const data = this.state[type];
    const supplierRequiredCommands = [
      "UPDATE_SUPPLIER",
      "SYNC_SUPPLIER_IMAGES",
      "SYNC_SUPPLIER_VARIANTS",
      "SYNC_SUPPLIER",
      "SHOPIFY_IMPORT",
      "SHOPIFY_SUP_FAILED_ORDER",
    ];
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (type === "RUN_OUTREACH") {
      if (!data.email.includes("@") || !data.email.includes(".")) {
        errors.push("Email: Invalid email address.");
      }
      if (!data.status) {
        errors.push("Status: Please choose a status.");
      }
    }

    if (type === "CSV_IMPORT") {
      if (!data.csv_url.includes(".csv")) {
        errors.push("CSV URL: Invalid CSV URL.");
      }

      if (!data.supplier_id) {
        errors.push("Supplier ID: Please enter a supplier ID.");
      }
    }

    if (supplierRequiredCommands.includes(type)) {
      if (!data.supplier_id) {
        errors.push("Supplier ID: Please enter a supplier ID.");
      }
    }

    if (type === "UPDATE_USER") {
      if (!data.email.includes("@") || !data.email.includes(".")) {
        errors.push("Email: Invalid email address.");
      }

      if (!data.account_type) {
        errors.push("Account Type: Please select an account type.");
      }

      if (!data.account_id) {
        errors.push("Account ID: Please enter an account ID.");
      }
    }
    if (type === "TRANSFER_OWNERSHIP") {
      if (!data.email.includes("@") || !data.email.includes(".")) {
        errors.push("Email: Invalid email address.");
      }

      if (!data.url) {
        errors.push("Store URL: Please enter a Store URL.");
      } else {
        if (!pattern.test(data.url)) {
          errors.push("Store URL: Insert a valid URL");
        }
      }
    }
    if (type === "MERCHANT_MISSING_ORDER") {
      if (!data.order_name) {
        errors.push("Order Name: Please enter an order name.");
      }
      if (!data.store_id) {
        errors.push("Store ID: Please enter a store ID.");
      }
    }

    this.setState({ errors });

    if (errors.length === 0) {
      this.setState({ showConfirm: true });
      return data;
    }
    return false;
  };

  render() {
    const calendarItems = [
      {
        label: "Start date",
        inputFormat: "MMM/DD/YYYY",
        onChange: (value) => {
          this.setState((previousState) => ({
            ...previousState,
            SUPPLIER_ON_HOLIDAY: {
              ...previousState.SUPPLIER_ON_HOLIDAY,
              start_date: dayjs(value).format("MM/DD/YYYY"),
            },
          }));
        },
        value: this.state.SUPPLIER_ON_HOLIDAY.start_date,
      },
      {
        label: "End date",
        inputFormat: "MMM/DD/YYYY",
        onChange: (value) => {
          this.setState((previousState) => ({
            ...previousState,
            SUPPLIER_ON_HOLIDAY: {
              ...previousState.SUPPLIER_ON_HOLIDAY,
              end_date: dayjs(value).format("MM/DD/YYYY"),
            },
          }));
        },
        value: this.state.SUPPLIER_ON_HOLIDAY.end_date,
      },
    ];

    return (
      <PageWrapper>
        <Text light extra="margin-bottom: 10px;">
          Admin Command Type
        </Text>
        <div style={{ width: "300px" }}>
          <Select
            options={[
              {
                value: "showChangeOrderShippingAddress",
                label: "Change Order Shipping Address",
              },
              {
                value: "SYNC_SUPPLIER_IMAGES",
                label: "Sync Supplier Images",
              },
              {
                value: "SYNC_SUPPLIER_VARIANTS",
                label: "Sync Supplier Variants",
              },
              {
                value: "UPDATE_SUPPLIER",
                label: "Update Supplier",
              },
              {
                value: "REMOVE_SUPPLIER",
                label: "Delete Supplier",
              },
              {
                value: "SUPPLIER_ON_HOLIDAY",
                label: "Supplier on Holiday",
              },
              {
                value: "CREATE_SUPPLIER",
                label: "Create Supplier",
              },
              {
                value: "SYNC_SUPPLIER",
                label: "Supplier Sync",
              },
              {
                value: "CSV_IMPORT",
                label: "CSV Product Import",
              },
              {
                value: "UPDATE_USER",
                label: "Update User Email",
              },
              {
                value: "SHOPIFY_IMPORT",
                label: "Shopify Product Import",
              },
              {
                value: "SHOPIFY_SUP_FAILED_ORDER",
                label: "Supplier Order Missing (Shopify)",
              },
              {
                value: "RUN_OUTREACH",
                label: "Run Outreach",
              },
              {
                value: "TRANSFER_OWNERSHIP",
                label: "Transfer Store Ownership",
              },
              {
                value: "SYNC_STORE_VARIANTS",
                label: "Sync Store Variants",
              },
              {
                value: "MANAGE_DISCOUNTS",
                label: "Manage Discounts",
              },
              {
                value: "OPTIN_FULFILLMENT_SERVICE",
                label: "Opt-in New Fulfillment Service",
              },
              {
                value: "MERCHANT_MISSING_ORDER",
                label: "Fix Missing Order",
              },
              {
                value: "COPY_PREBUILT_IMPORTS",
                label: "Copy Prebuilt Imports",
              },
            ]}
            onChange={(data) => {
              this.setState({
                action: data.value,
                completed: false,
                errors: [],
                showChangeOrderShippingAddress: false,
              });
              if (data.value === "MANAGE_DISCOUNTS") {
                getRequest({
                  url: `admin_commands/manage_discount/`,
                })
                  .then((response) => {
                    this.setState((prevState) => ({
                      MANAGE_DISCOUNTS: response.data,
                      showChangeOrderShippingAddress: false,
                    }));
                  })
                  .catch((error) => {});
              }
              if ("showChangeOrderShippingAddress" === data.value) {
                this.setState({ showChangeOrderShippingAddress: true });
              }
            }}
          />
        </div>

        {this.state.errors.length > 0 && (
          <Errors>
            {this.state.errors.map((error, index) => (
              <Text key={index} extra="margin-bottom: 10px; color: red;">
                {error}
              </Text>
            ))}
          </Errors>
        )}

        <Show when={this.state.showChangeOrderShippingAddress}>
          <OrderShippingAddress />
        </Show>

        {this.state.action === "UPDATE_SUPPLIER" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Update Supplier
            </Text.Large>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData(
                  "UPDATE_SUPPLIER",
                  "supplier_id",
                  e.target.value
                );
                // this.updateData(
                //   "UPDATE_SUPPLIER",
                //   "command_user",
                //   "alex@dropcommerce.com"
                // );
              }}
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
          </Container>
        )}
        {this.state.action === "SYNC_SUPPLIER_IMAGES" && (
          <Container>
            <Text.Small extra="margin-bottom: 30px;">
              Broken images? This command fetches the latest Image urls from the
              Supplier's Shopify
            </Text.Small>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData(
                  "SYNC_SUPPLIER_IMAGES",
                  "supplier_id",
                  e.target.value
                );
              }}
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
          </Container>
        )}
        {this.state.action === "SYNC_SUPPLIER_VARIANTS" && (
          <Container>
            <Text extra="margin-bottom: 30px;">
              Fetches the supplier variants and ...
            </Text>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData(
                  "SYNC_SUPPLIER_VARIANTS",
                  "supplier_id",
                  e.target.value
                );
              }}
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
          </Container>
        )}
        {this.state.action === "SYNC_STORE_VARIANTS" && (
          <Container>
            <Text extra="margin-bottom: 30px;">
              Sync Variants from DropCommerce to the merchant's Shopify
            </Text>
            <InputLabel>Store ID</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData(
                  "SYNC_STORE_VARIANTS",
                  "store_id",
                  e.target.value
                );
              }}
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Store ID"
            />
          </Container>
        )}

        {this.state.action === "REMOVE_SUPPLIER" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Delete Supplier
            </Text.Large>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "REMOVE_SUPPLIER",
                  "supplier_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
            <InputLabel>Reason</InputLabel>
            <div style={{ width: "300px" }}>
              <StyledTextArea
                onChange={(e) =>
                  this.updateData("REMOVE_SUPPLIER", "reason", e.target.value)
                }
              />
            </div>
          </Container>
        )}

        {this.state.action === "SUPPLIER_ON_HOLIDAY" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Supplier on Holiday
            </Text.Large>
            <Text extra="margin-bottom: 30px;">
              Inactivate unresponsive supplier.
            </Text>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "SUPPLIER_ON_HOLIDAY",
                  "supplier_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
            <InputLabel>Period</InputLabel>
            <div style={{ width: "300px" }}>
              <Calendar items={calendarItems} />
            </div>
            <InputLabel>Notify Merchants</InputLabel>
            <Switch
              active={this.state.SUPPLIER_ON_HOLIDAY.notify_merchants}
              toggle={() =>
                this.updateData(
                  "SUPPLIER_ON_HOLIDAY",
                  "notify_merchants",
                  !this.state.SUPPLIER_ON_HOLIDAY.notify_merchants
                )
              }
            />
          </Container>
        )}

        {this.state.action === "CREATE_SUPPLIER" && (
          <Container>
            {this.state.newSupplierData && (
              <div style={{ marginBottom: "30px" }}>
                <Text>Name: {this.state.newSupplierData.name}</Text>
                <Text>Email: {this.state.newSupplierData.email}</Text>
                <Text>Password: {this.state.newSupplierData.password}</Text>
                <Text>Magic Link: {this.state.newSupplierData.magic}</Text>
              </div>
            )}

            <Text.Large extra="margin-bottom: 30px;">
              Create Supplier
            </Text.Large>
            <InputLabel>Name</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("CREATE_SUPPLIER", "name", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier Name"
            />
            <InputLabel>Email</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("CREATE_SUPPLIER", "email", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="email@email.com"
            />
            <InputLabel>Subdomain</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("CREATE_SUPPLIER", "subdomain", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Subdomain"
            />
          </Container>
        )}

        {this.state.action === "RUN_OUTREACH" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Run Outreach Sequences
            </Text.Large>
            <InputContainer>
              <InputLabel>Contact Status</InputLabel>
              <Select
                options={[
                  {
                    value: "FOLLOW_UP_TWO",
                    label: "Follow Up Two",
                  },
                  {
                    value: "FOLLOW_UP_ONE",
                    label: "Follow Up One",
                  },
                  {
                    value: "INITIAL_OUTREACH",
                    label: "Initial Outreach",
                  },
                  {
                    value: "NONE",
                    label: "None",
                  },
                ]}
                onChange={(data) =>
                  this.updateData("RUN_OUTREACH", "status", data.value)
                }
              />
              <InputLabel>Email</InputLabel>
              <Input
                onChange={(e) =>
                  this.updateData("RUN_OUTREACH", "email", e.target.value)
                }
                extraStyle="margin: 0px; width: 200px;"
                placeholder="email@email.com"
              />
            </InputContainer>
          </Container>
        )}

        {this.state.action === "SHOPIFY_IMPORT" && (
          <Container>
            <ShopifyProductImport
              addCollection={(collection_id) => {
                this.setState((prevState) => ({
                  SHOPIFY_IMPORT: {
                    ...prevState.SHOPIFY_IMPORT,
                    collections: [
                      ...prevState.SHOPIFY_IMPORT.collections,
                      collection_id,
                    ],
                  },
                }));
              }}
              removeCollection={(collection_id) => {
                this.setState((prevState) => ({
                  SHOPIFY_IMPORT: {
                    ...prevState.SHOPIFY_IMPORT,
                    collections: prevState.SHOPIFY_IMPORT.collections.filter(
                      (id) => id != collection_id
                    ),
                  },
                }));
              }}
              updateData={(field, value) =>
                this.updateData("SHOPIFY_IMPORT", field, value)
              }
              data={this.state.SHOPIFY_IMPORT}
            />
          </Container>
        )}
        {this.state.action === "SHOPIFY_SUP_FAILED_ORDER" && (
          <Container>
            <Text.Large extra="margin-bottom: 10px;">
              Supplier Order Missing at Shopify
            </Text.Large>
            <Text.Small light extra="margin-bottom: 30px;">
              This command will check for supplier orders that failed to enter
              Shopify.
            </Text.Small>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              type="number"
              onChange={(e) =>
                this.updateData(
                  "SHOPIFY_SUP_FAILED_ORDER",
                  "supplier_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />

            <InputLabel>Supplier Order ID</InputLabel>
            <Input
              type="number"
              onChange={(e) =>
                this.updateData(
                  "SHOPIFY_SUP_FAILED_ORDER",
                  "supplier_order_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier Order ID"
            />
          </Container>
        )}
        {this.state.action === "CSV_IMPORT" && (
          <Container>
            <Text.Large extra="margin-bottom: 10px;">
              Sync Supplier Products
            </Text.Large>
            <Text.Small light extra="margin-bottom: 30px;">
              This command will update the supplier's product information from
              Shopify
            </Text.Small>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              type="number"
              onChange={(e) =>
                this.updateData("CSV_IMPORT", "supplier_id", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
            <InputLabel>Calculate Price</InputLabel>
            {/* Use the Retail Price and supplier Discount % in their profile to calculate the DropCommerce Price */}
            <Switch
              active={this.state.CSV_IMPORT.calculate_price}
              toggle={() =>
                this.updateData(
                  "CSV_IMPORT",
                  "calculate_price",
                  !this.state.CSV_IMPORT.calculate_price
                )
              }
            />
            <InputLabel>CSV URL</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("CSV_IMPORT", "csv_url", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="CSV URL"
            />
            <InputLabel>CSV Origin</InputLabel>
            <div style={{ width: "200px" }}>
              <Select
                options={[
                  {
                    value: "DropCommerce",
                    label: "DropCommerce",
                  },
                  {
                    value: "WooCommerce",
                    label: "WooCommerce",
                  },
                  {
                    value: "SquareSpace",
                    label: "SquareSpace",
                  },
                  {
                    value: "OTHER",
                    label: "OTHER",
                  },
                ]}
                onChange={(data) =>
                  this.updateData("CSV_IMPORT", "csv_model", data.value)
                }
              />
            </div>
          </Container>
        )}

        {this.state.action === "SYNC_SUPPLIER" && (
          <Container>
            <Text.Large extra="margin-bottom: 10px;">
              Sync Supplier Products
            </Text.Large>
            <Text.Small light extra="margin-bottom: 30px;">
              This command will update the supplier's product information from
              Shopify
            </Text.Small>
            <InputLabel>Supplier ID</InputLabel>
            <Input
              type="number"
              onChange={(e) =>
                this.updateData("SYNC_SUPPLIER", "supplier_id", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Supplier ID"
            />
            <InputLabel>Sync Pricing</InputLabel>
            {/* Update the supplier's pricing based on their pricing in Shopify and the discount rate in their DC profile. This will only work if they have 'sync pricing' set in Django as well. */}
            <Switch
              active={this.state.SYNC_SUPPLIER.sync_pricing}
              toggle={() =>
                this.updateData(
                  "SYNC_SUPPLIER",
                  "sync_pricing",
                  !this.state.SYNC_SUPPLIER.sync_pricing
                )
              }
            />
          </Container>
        )}

        {this.state.action === "UPDATE_USER" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Update User Email
            </Text.Large>
            <InputContainer>
              <InputLabel>User Type</InputLabel>
              <Select
                options={[
                  {
                    value: "supplier",
                    label: "Supplier",
                  },
                  {
                    value: "store",
                    label: "Store",
                  },
                ]}
                onChange={(data) =>
                  this.updateData("UPDATE_USER", "account_type", data.value)
                }
              />
            </InputContainer>
            <InputLabel>Store / Supplier ID</InputLabel>
            <Input
              type="number"
              onChange={(e) =>
                this.updateData("UPDATE_USER", "account_id", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Store / Supplier ID"
            />
            <InputLabel>New Email</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("UPDATE_USER", "email", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="New Email"
            />
          </Container>
        )}
        {this.state.action === "TRANSFER_OWNERSHIP" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Transfer Store Ownership
            </Text.Large>

            <InputLabel>Store URL </InputLabel>
            <Input
              type="url"
              onChange={(e) =>
                this.updateData("TRANSFER_OWNERSHIP", "url", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Store URL"
            />
            <InputLabel>New Email</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData("TRANSFER_OWNERSHIP", "email", e.target.value)
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="New Email"
            />
            <InputLabel>Create User</InputLabel>
            <Switch
              active={this.state.TRANSFER_OWNERSHIP.create_user}
              toggle={() =>
                this.updateData(
                  "TRANSFER_OWNERSHIP",
                  "create_user",
                  !this.state.TRANSFER_OWNERSHIP.create_user
                )
              }
            />
            <InputLabel>Override Store</InputLabel>
            <Switch
              active={this.state.TRANSFER_OWNERSHIP.override_store}
              toggle={() =>
                this.updateData(
                  "TRANSFER_OWNERSHIP",
                  "override_store",
                  !this.state.TRANSFER_OWNERSHIP.override_store
                )
              }
            />
          </Container>
        )}

        {this.state.action === "MANAGE_DISCOUNTS" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Manage Discounts
            </Text.Large>
            <InputLabel>Premade Discount (%)</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "MANAGE_DISCOUNTS",
                  "premade_discount",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              value={this.state.MANAGE_DISCOUNTS.premade_discount}
              type="number"
              min="0"
              max="100"
            />
            <InputLabel>Plan Discount (%)</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "MANAGE_DISCOUNTS",
                  "plan_discount",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              value={this.state.MANAGE_DISCOUNTS.plan_discount}
              type="number"
              min="0"
              max="100"
            />
          </Container>
        )}
        {this.state.action === "OPTIN_FULFILLMENT_SERVICE" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Opt-In New Fulfillment Service
            </Text.Large>
            <InputLabel>Entity ID</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "OPTIN_FULFILLMENT_SERVICE",
                  "entity_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Entity ID"
            />
            <InputLabel>Entity Type</InputLabel>
            <div style={{ width: "200px" }}>
              <Select
                options={[
                  {
                    value: "supplier",
                    label: "Supplier",
                  },
                  {
                    value: "store",
                    label: "Store",
                  },
                ]}
                onChange={(data) =>
                  this.updateData(
                    "OPTIN_FULFILLMENT_SERVICE",
                    "entity_type",
                    data.value
                  )
                }
              />
            </div>
          </Container>
        )}
        {this.state.action === "MERCHANT_MISSING_ORDER" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Fix Missing Order
            </Text.Large>
            <InputLabel>Store ID</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "MERCHANT_MISSING_ORDER",
                  "store_id",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="Store ID"
            />
            <InputLabel>Order Name</InputLabel>
            <Input
              onChange={(e) =>
                this.updateData(
                  "MERCHANT_MISSING_ORDER",
                  "order_name",
                  e.target.value
                )
              }
              extraStyle="margin: 0px; width: 200px;"
              placeholder="#0000"
            />
          </Container>
        )}
        {this.state.action === "COPY_PREBUILT_IMPORTS" && (
          <Container>
            <Text.Large extra="margin-bottom: 30px;">
              Copy Imports for Prebuilts
            </Text.Large>
            {!this.state.COPY_PREBUILT_IMPORTS.confirmed ? (
              <Text.Small extra="margin-bottom: 10px; color: red;">
                ENSURE SOURCE STORE IS SYNCED <br />
                RUN COMMAND "Sync Store Variants" ON SOURCE STORE BEFORE RUNNING
                THIS COMMAND
              </Text.Small>
            ) : (
              <Text.Small extra="margin-bottom: 10px;">
                {this.state.COPY_PREBUILT_IMPORTS.pending_products} Products
                will be imported.
              </Text.Small>
            )}
            <InputLabel>Copy from</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData("COPY_PREBUILT_IMPORTS", "confirmed", false);
                this.updateData(
                  "COPY_PREBUILT_IMPORTS",
                  "source_platform_uid",
                  e.target.value
                );
              }}
              extraStyle="margin: 0px; width: 400px;"
              placeholder="Source Platform ID"
            />
            <InputLabel>Copy to</InputLabel>
            <Input
              onChange={(e) => {
                this.updateData("COPY_PREBUILT_IMPORTS", "confirmed", false);
                this.updateData(
                  "COPY_PREBUILT_IMPORTS",
                  "store_platform_uid",
                  e.target.value
                );
              }}
              extraStyle="margin: 0px; width: 400px;"
              placeholder="Platform ID"
            />
          </Container>
        )}
        <Show when={!this.state.showChangeOrderShippingAddress}>
          {/*<Show*/}
          {/*  when={*/}
          {/*    !this.state.showChangeOrderShippingAddress &&*/}
          {/*    this.state.action !== "SHOPIFY_IMPORT"*/}
          {/*  }*/}
          {/*>*/}
          {this.state.action && (
            <React.Fragment>
              {this.state.completed ? (
                <ButtonNew
                  disabled
                  type="main-light"
                  text="FINISHED"
                  extra="width: 200px; padding: 15px; margin-top: 30px;"
                  fontStyle="font-size: 20px;"
                />
              ) : (
                <ButtonNew
                  isFetching={this.state.isFetching}
                  type={this.state.showConfirm ? "main-dark" : "main"}
                  text={this.state.showConfirm ? "CONFIRM" : "RUN COMMAND"}
                  onClick={() =>
                    this.state.showConfirm
                      ? this.runCommand(this.state.action)
                      : this.validate(this.state.action)
                  }
                  extra="width: 200px; padding: 15px; margin-top: 30px;"
                  fontStyle="font-size: 20px;"
                />
              )}
            </React.Fragment>
          )}
        </Show>
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Commands)
);

const InputLabel = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Errors = styled.div`
  padding: 20px;
  background: white;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  width: 200px;
`;

const Container = styled.div`
  margin-top: 50px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
