import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { getAppName, getUrlParameter } from "../modules/Format";
import { api } from "../modules/Config";
import Account, {
  AccountButton,
  AccountInput,
  AccountLink,
  Form,
  LoginOptions,
} from "../views/account/components/AccountComponents";
import createNotification from "../modules/Notification";
import { trackEvent } from "../modules/EventTracking";
import { validEmail, validPassword } from "../validation";
import { isEmpty } from "lodash";

const Login = (props) => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFetching, isFetchingSet] = useState(false);

  const appName = getAppName();

  const onChange = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const login = () => {
    if (!isValid()) return;
    isFetchingSet(true);
    fetch(`${api}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.token) {
          Cookies.set("accessToken", response.token, { expires: 10 });
          Cookies.set("currentPlatform", response.platformURL, { expires: 10 });
          props.history.push("/dashboard");
        } else {
          createNotification({
            type: "danger",
            title: "Login Error",
            message: "Invalid username or password",
          });
          isFetchingSet(false);
        }
      })
      .catch((error) => {
        createNotification({
          type: "danger",
          title: "Login Error",
          message: "Something went wrong",
        });
        isFetchingSet(false);
      });
  };

  const isValid = () => {
    const hasErrors = {};

    if (!validPassword(password)) {
      hasErrors.password = "Please enter a longer, more secure password";
    }

    if (!validEmail(email)) {
      hasErrors.email = "Please enter a valid email address";
    }

    setErrors(hasErrors);

    return isEmpty(hasErrors);
  };

  useEffect(() => {
    trackEvent("Login");

    if (getUrlParameter("type", props.location) === "unauthorized") {
      createNotification({
        type: "danger",
        message: "Your login session has expired. Please login again.",
      });
    }

    const code = getUrlParameter("code", props.location);
    const shop = getUrlParameter("shop", props.location);
    const state = getUrlParameter("state", props.location);
    const hmac = getUrlParameter("hmac", props.location);
    const timestamp = getUrlParameter("timestamp", props.location);
    const querystring = props.location.search;
    if (code && shop && state && hmac && timestamp) {
      if (Cookies.get("nonce") === state) {
        isFetchingSet(true);
        fetch(`${api}/prebuilt/auth/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code, querystring }),
        })
          .then((r) => {
            r.json().then((response) => {
              if (response.token) {
                Cookies.set("accessToken", response.token, { expires: 10 });
                Cookies.set("currentPlatform", response.platformURL, {
                  expires: 10,
                });
                window.tap("customer", response.store_id);
                props.history.push("/dashboard");
              }
            });
          })
          .catch((error) => {
            isFetchingSet(false);
            createNotification({
              type: "danger",
              message:
                "Unexpected error connecting to Shopify. Contact support@dropcommerce.com",
            });
          });
      } else {
        createNotification({
          type: "danger",
          message:
            "Unexpected error connecting to Shopify. Contact support@dropcommerce.com",
        });
      }
    } else {
      const magic = getUrlParameter("magic", props.location);
      if (magic) {
        Cookies.set("accessToken", magic, { expires: 1 });
        props.history.push("/dashboard");
      }
    }
  }, []);

  return (
    <Account title={"Prebuilt Stores"} isFetching={isFetching}>
      <Form>
        <AccountInput
          onChange={(text) => setEmail(text.target.value)}
          onKeyPress={(e) => onChange(e)}
          placeholder="Your email"
          value={email}
          error={errors.email}
        />
        <AccountInput
          onChange={(text) => setPassword(text.target.value)}
          onKeyPress={(e) => onChange(e)}
          placeholder="Your password"
          value={password}
          error={errors.password}
          type="password"
        />
        <AccountButton isFetching={isFetching} text="Sign in" onClick={login} />
        <AccountLink
          margin="20px 0 0 0"
          linkText="Forgot your password?"
          onClick={() => props.history.push("/forgot")}
        />
      </Form>
      <LoginOptions appName={appName} />
    </Account>
  );
};

export default withRouter(Login);
