import React, { useEffect, useState } from "react";
import { api } from "../../../../modules/Config";
import Cookies from "js-cookie";
import moment from "moment";

import { FiCheck, FiMinus } from "react-icons/fi";

import { TableNew } from "../../../../components/TableNew";

function formatExpiryDate(endTimestamp) {
  if (endTimestamp) {
    return moment(endTimestamp).format("MMM Do YYYY");
  } else {
    return <FiMinus />;
  }
}

function formatPrice(price, active) {
  const formattedPrice = "$" + price;
  if (!active) {
    return <del>{formattedPrice}</del>;
  }
  return formattedPrice;
}

export default function PaymentPlansTable(store) {
  const [records, setRecords] = useState([]);
  const [fetching, fetchingSet] = useState(true);

  useEffect(() => {
    if (store) {
      fetch(`${api}/api/payment-plans/?store=${store.store}`, {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(setRecords)
        .catch((error) => console.log(error))
        .finally(() => fetchingSet(false));
    }
  }, []);

  const transformedRecords = records.map((record) => ({
    plan: record.plan,
    price: formatPrice(record.price, record.active),
    active: record.active ? "Active" : "Expired",
    start_date: moment(record.start_date).format("MMM Do YYYY"),
    expiry_date: formatExpiryDate(record.expiry_date),
    confirmed: record.confirmed ? FiCheck : FiMinus,
  }));

  return (
    <TableNew
      title="Your Payment History"
      columns={[
        "Plan",
        "Price",
        "Status",
        "Start Date",
        "Expiry Date",
        "Confirmed",
      ]}
      rows={transformedRecords}
      isLoading={fetching}
      noResultsText="You don't have any payments."
    />
  );
}
