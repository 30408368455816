import React, { Component } from "react";

import Prebuilt from "./Prebuilt";

class PrebuiltWrapper extends Component {
  render() {
    return <Prebuilt />;
  }
}

export default PrebuiltWrapper;
