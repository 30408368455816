import React, { Fragment } from "react";
import styled from "styled-components";

import { ButtonNew, Row } from "../../../components";
import lock from "../../../images/icons/feather/lock.svg";
import { theme } from "../../../modules/Theme";

const ConfirmDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 40px;
  max-width: 600px;
`;

const AgreeText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 20px;
`;

const AgreeLink = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.main};
  cursor: pointer;
`;

const ContentBoxIcon = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const SecurePayment = styled.div`
  font-size: 16px;
  font-weight: 400;
`;
// endregion Style

export const PaymentConfirmation = (props) => {
  return (
    <Fragment>
      <ButtonNew
        size="large"
        margin="10px 0 0 0"
        text="Download Free Store Setup E-Book"
        type="secondary"
        onClick={() => {
          window.open(
            "https://dc-imports.s3.ca-central-1.amazonaws.com/DropCommerce-Prebuilt-Store-eBook.pdf"
          );
        }}
      />
      <ConfirmDescription>
        Your payment was successful. Our team will begin building your store
        right away, and deliver the login credentials within 24 hours.
      </ConfirmDescription>
      <ConfirmDescription>
        Questions? Contact support@dropcommerce.com
      </ConfirmDescription>
    </Fragment>
  );
};

export const TermsAndConditions = (props) => {
  return (
    <Fragment>
      <AgreeText>
        By purchasing a pre-built store, you are agreeing to our{" "}
        <AgreeLink
          onClick={() =>
            window.open(
              "https://help.dropcommerce.com/en/articles/4361388-prebuilt-store-terms"
            )
          }
        >
          Pre-built Store Terms
        </AgreeLink>
      </AgreeText>

      <Row align style={{ marginTop: "20px" }}>
        <ContentBoxIcon src={lock} />
        <SecurePayment>Secure Payment</SecurePayment>
      </Row>

      <ConfirmDescription>
        After payment, our team will professionally build your store and deliver
        your login details within 24 hours.
      </ConfirmDescription>
    </Fragment>
  );
};
