import Cookies from "js-cookie";
import {
  web,
  webGlobal,
  webPrebuilt,
  webShoplazza,
  webSupplierApp,
} from "./Config";

export const getUrlSubdomain = () => {
  let url = window.location.href;

  // Override URL in test environments
  if (
    process.env.REACT_APP_ENVIRONMENT !== "pro" &&
    process.env.REACT_APP_FAKE_URL
  )
    url = process.env.REACT_APP_FAKE_URL;

  const splitURL = url.split(".dropcommerce.com");
  let subdomain = "app";
  if (splitURL.length === 2) {
    subdomain = splitURL[0].replace("https://", "").replace("http://", "");
  }
  return subdomain;
};

export const getAppName = () => {
  switch (getUrlSubdomain()) {
    case "app":
      return "MAIN";
    case "suppliers":
      return "SUPPLIERS";
    case "prebuilt":
      return "PREBUILT";
    default:
      return "PORTAL";
  }
};

export const isSupplierApp = () => {
  const urlSplit = window.location.href.split("dropcommerce.com");
  if (urlSplit.length === 2) {
    return urlSplit[1].indexOf("sapp") >= 0;
  }
  return false;
};

export const getLoginTypeFromSubdomain = () => {
  const subdomain = getUrlSubdomain();
  let isGlobal;
  let isSupplierPortal;
  if (subdomain === "global" || subdomain === "nft") {
    isGlobal = true;
    isSupplierPortal = false;
  } else if (subdomain === "app") {
    isGlobal = false;
    isSupplierPortal = false;
  } else {
    isGlobal = false;
    isSupplierPortal = true;
  }
  return [isGlobal, isSupplierPortal];
};

export const getDefaultStoreRoute =
  getUrlSubdomain() === "app"
    ? "/dashboard"
    : getUrlSubdomain() === "global"
    ? "/products"
    : "/settings";

export const arrayToCommaString = (array) => {
  let array_string = "";

  let count = 0;

  array.forEach((item) => {
    const itemString = item.toString();

    if (count > 0) {
      array_string += `, ${itemString}`;
    } else {
      array_string += itemString;
    }

    count += 1;
  });

  return array_string;
};

export const getSupplierWarnings = (settings) => {
  let profileSettingsWarning = [
    settings.return_policy,
    settings.short_description,
    settings.description,
    settings.logo,
  ].includes("");

  let valuesWarning =
    ![
      settings.made_in_canada,
      settings.made_in_us,
      settings.fair_trade,
      settings.organic,
      settings.handmade,
      settings.kosher,
      settings.non_gmo,
      settings.women_owned,
      settings.bipoc,
      settings.vegan,
      settings.eco_friendly,
      settings.social_good,
      settings.small_batch,
    ].includes(true) && !settings.no_values;

  return {
    active_shipping_options: !settings.active_shipping_options,
    values: valuesWarning,
    profile_settings: profileSettingsWarning,
    branding_description: settings.branding_description === "",
    payment: !settings.stripe_id_saved && !settings.merchant_id,
    supplier_agreement: !settings.supplier_agreement,
  };
};

const resizeCloudinary = (url, height, width) => {
  const isResized = url.includes(`/h_`);

  if (isResized) {
    // ALREADY RESIZED
    const splitUrl = url.split("upload/");
    const existingMod = splitUrl[1].split("/")[0];
    return url.replace(existingMod, `h_${height}`);
  } else {
    // NOT RESIZED
    const splitUrl = url.split("upload/");
    const newUrl = `${splitUrl[0]}upload/h_${height}/${splitUrl[1]}`;
    return newUrl;
  }
};

const resizeAWS = (url, height, width) => {
  const imageKey = url.split("dropcommerce-images/")[1];
  const imageRequest = JSON.stringify({
    bucket: "dropcommerce-images",
    key: imageKey,
    edits: {
      resize: {
        height: height,
        // width: width || height,
        // fit: "cover",
      },
    },
  });
  const resizedImage = `https://dnvlxjuzig5dx.cloudfront.net/${btoa(
    imageRequest
  )}`;
  return resizedImage;
};

export const resizeImage = (url, height, width) => {
  if (!url) {
    return null;
  }

  const isAWS = url.includes("dropcommerce-images");
  const isCloudinary = url.includes("cloudinary");

  if (isAWS) {
    return resizeAWS(url, height, width);
  }

  if (isCloudinary) {
    return resizeCloudinary(url, height, width);
  }

  return url;
};
export const shopifyInstallationURL = (shop, randString) => {
    const redirectUri = `${web}/login/`;
    const appId = process.env.REACT_APP_SHOPIFY_API_KEY;
    const scopes =
      "read_products,write_products,read_orders,write_orders,read_fulfillments," +
      "write_fulfillments,read_merchant_managed_fulfillment_orders,read_inventory,write_inventory," +
      "write_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders," +
      "write_third_party_fulfillment_orders,write_shipping,read_shipping,read_assigned_fulfillment_orders";
    const permissionUrl = `/oauth/authorize?client_id=${appId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${randString}&grant_options[]=`;
    return `https://${shop}/admin${permissionUrl}`;
};

export const authStore = (shop) => {
  if (shop) {
    const randString = randomString(20);
    Cookies.set("nonce", randString, { expires: 1 });
    window.top.location.href = shopifyInstallationURL(shop, randString);
  }
};

export const authShoplazzaStore = (shop) => {
  if (!shop) return;

  const redirectUri = `${webShoplazza}`;
  const randString = randomString(20);
  Cookies.set("nonce", randString, { expires: 1 });
  const appId = process.env.REACT_APP_SHOPLAZZA_CLIENT_ID;

  let scopes =
    "read_product write_product read_order write_order read_collection ";
  scopes +=
    "write_collection read_payment_info write_payment_info read_comments write_comments ";
  scopes +=
    "read_script_tags write_script_tags read_shop write_shop read_customer write_customer ";
  scopes +=
    "read_data write_data read_price_rules write_price_rules read_themes write_themes";

  let url = `https://${shop}/admin/oauth/authorize`;
  url += `?client_id=${appId}&scope=${scopes}`;
  url += `&redirect_uri=${redirectUri}&response_type=code&state=${randString}`;
  window.top.location.href = url;
};

export const authGlobalStore = (shop) => {
  const redirectUri = `${webGlobal}/login/`;
  const randString = randomString(20);
  Cookies.set("nonce", randString, { expires: 1 });
  const appId = process.env.REACT_APP_GLOBAL_APP_ID;
  const scopes =
    "read_products,write_products,read_orders,write_orders,read_fulfillments";
  const permissionUrl = `/oauth/authorize?client_id=${appId}&amp;scope=${scopes}&amp;redirect_uri=${redirectUri}&amp;state=${randString}&amp;grant_options[]=`;
  const url = `https://${shop}/admin${permissionUrl}`;
  window.top.location.href = url;
};

export const authPrebuiltStore = (shop) => {
  const redirectUri = `${webPrebuilt}/login/`;
  const randString = randomString(20);
  Cookies.set("nonce", randString, { expires: 1 });
  const appId = process.env.REACT_APP_PREBUILT_APP_ID;
  const scopes =
    "read_products,write_products,read_orders,write_orders,read_fulfillments";
  const permissionUrl = `/oauth/authorize?client_id=${appId}&amp;scope=${scopes}&amp;redirect_uri=${redirectUri}&amp;state=${randString}&amp;grant_options[]=`;
  const url = `https://${shop}/admin${permissionUrl}`;
  window.top.location.href = url;
};

export const authShopifySupplier = (shop) => {
  const redirectUri = `${webSupplierApp}/login/`;
  const randString = randomString(20);
  Cookies.set("nonce", randString, { expires: 1 });
  const appId = process.env.REACT_APP_SUPPLIER_APP_ID;
  const scopes = "read_products,read_orders,write_orders,read_fulfillments";
  const permissionUrl = `/oauth/authorize?client_id=${appId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${randString}&grant_options[]=`;
  const url = `https://${shop}/admin${permissionUrl}`;
  window.top.location.href = url;
};

export const truncateText = (text, length) => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};

export const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (p in obj) {
      const key = encodeURIComponent(p);
      const val = encodeURIComponent(obj[p]);

      if (val !== "") {
        str.push(key + "=" + val);
      }
    }
  return `?${str.join("&")}`;
};

export const getActiveFilters = (filters) => {
  let filtersArray = [];
  filters.filters.forEach((filter) => {
    filter.options
      .filter((option) => option.selected)
      .forEach((option) => {
        filtersArray.push(option.value);
      });
  });
  return filtersArray;
};

// plain javascript
export const getParameterByName = (name) => {
  var url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getProductWithId = (products, id) => {
  let returnedProduct = {};
  products.forEach((product) => {
    if (product.id === parseInt(id)) {
      returnedProduct = product;
    }
  });
  return returnedProduct;
};

export const listToCommaString = (list) => {
  let string = "";
  let stringCount = 0;

  list.forEach((item) => {
    if (stringCount > 0) {
      string += ",";
    }
    stringCount += 1;
    string += item;
  });

  return string;
};

export const inUrl = (string) => {
  return window.location.href.includes(string);
};

export const buildObjectFromFields = (object, fields) => {
  let newObject = {};

  fields.forEach((field) => {
    newObject[field] = object[field];
  });

  return newObject;
};

export const getUrlParameter = (name, location) => {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const randomString = (length) => {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const updatePriceObject = (object, conversionRate) => {
  if (object.original_currency === "USD" && object.currency === "CAD") {
    return {
      ...object,
      amount: object.original_amount * conversionRate,
    };
  } else if (object.original_currency === "CAD" && object.currency === "USD") {
    return {
      ...object,
      amount: object.original_amount / conversionRate,
    };
  }
};
