import React, { Component } from "react";
import { Row, Column } from "../../components/Layout";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import Hint from "../../components/Hint";

export const SettingsTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

export const SettingsSubtitle = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

export const New = styled.div`
  font-size: 12px;
  background: ${theme.colors.main};
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  margin-left: 5px;
`;

export const ProFeature = styled.div`
  font-size: 12px;
  background: ${theme.colors.orange};
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  margin-left: 5px;
`;

class SettingsItem extends Component {
  render() {
    return (
      <Column style={{ maxWidth: "700px" }}>
        <Row style={{ alignItems: "center", marginBottom: "10px" }}>
          <SettingsTitle>{this.props.title}</SettingsTitle>
          {this.props.hintText && (
            <Hint hint={this.props.hintText} link={this.props.hintLink} />
          )}
          {this.props.new && <New>New</New>}
          {this.props.pro && <ProFeature>Pro Feature</ProFeature>}
          {this.props.growth && <ProFeature>Growth Feature</ProFeature>}
        </Row>
        <SettingsSubtitle>{this.props.description}</SettingsSubtitle>
      </Column>
    );
  }
}

export default SettingsItem;
