import React, { Component } from "react";
import styled from "styled-components";
import { ButtonNew, Row } from "../../components";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  @media (max-width: 800px) {
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
  opacity: 0.5;
  margin-right: 10px;
  width: 100px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const RetailPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
  opacity: 0.5;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
  opacity: 0.8;
  color: #e48832;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const TitleRow = styled.div`
  width: 100%;
  text-align: left;
  margin-right: 20px;
`;

class CartOption extends Component {
  render() {
    const {
      title,
      price,

      retailPrice,
      onClick,
      isFetching,
      inCart,
      viewCart,
      sampleOrdered,
    } = this.props;

    let buttonText = "ADD TO CART";

    if (inCart) {
      buttonText = "ADDED TO CART";
    } else if (sampleOrdered) {
      buttonText = "SAMPLE ORDERED";
    }

    return (
      <PageWrapper>
        <TitleRow>
          <Title>{title}</Title>
          <Row style={{ marginTop: "10px" }}>
            <Text>Dropship:</Text>
            <Price>{price}</Price>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Text>Retail:</Text>
            <RetailPrice>{retailPrice}</RetailPrice>
          </Row>
        </TitleRow>
        <ButtonNew
          isFetching={isFetching}
          type="main"
          disabled={inCart}
          text={buttonText}
          onClick={inCart ? viewCart : onClick}
        />
      </PageWrapper>
    );
  }
}

export default CartOption;
