import React from "react";
import {
  FiAlertTriangle,
  FiArrowRightCircle,
  FiArrowUpCircle,
} from "react-icons/fi";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { Row } from "./Layout";

const Announcement = styled(Row)`
  background: ${theme.colors.main};
  ${(props) => (props.$gradient ? props.$gradient : "")}
  padding: 10px;
`;

const ErrorText = styled.div`
  color: white;
  font-weight: 500;
`;

const UpgradeButton = styled.div`
  background: rgba(22, 63, 66, 0.8);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: rgba(22, 63, 66, 1);
  }
`;

const UpgradeButtonText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-right: 15px;
`;

export const BannerAnnouncement = ({
  title,
  onClick,
  buttonText,
  warning,
  gradient,
}) => (
  <Announcement
    gap="10px"
    flexDirection="column"
    align
    justify
    $gradient={gradient}
  >
    {warning ? (
      <FiAlertTriangle size="24px" color="white" />
    ) : (
      <FiArrowUpCircle size="24px" color="white" />
    )}
    <div style={{ textAlign: "center" }}>
      <ErrorText style={{}}>{title}</ErrorText>
    </div>
    {buttonText && (
      <UpgradeButton onClick={onClick}>
        <UpgradeButtonText>{buttonText}</UpgradeButtonText>
        <FiArrowRightCircle size="20px" color="white" />
      </UpgradeButton>
    )}
  </Announcement>
);
