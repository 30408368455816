import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import SkeletonLoader from "../../../components/SkeletonLoader";

export const TextBlob = styled(SkeletonLoader)`
  height: 15px;
  width: 50px;
  min-width: 50px;
`;

export const Spacer = styled.div`
  width: 110px;
`;

export const CardImage = styled(SkeletonLoader)`
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 4px;
`;

export const Row = styled.div`
  border-bottom: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
`;

class SupplierProductRowSkeleton extends Component {
  render() {
    return (
      <Row>
        <Spacer>
          <CardImage />
        </Spacer>
        <Spacer style={{ width: "400px" }}>
          <TextBlob style={{ width: "350px", midWidth: "350px" }} />
        </Spacer>
        <Spacer>
          <TextBlob />
        </Spacer>
        <Spacer>
          <TextBlob />
        </Spacer>
        <Spacer>
          <TextBlob />
        </Spacer>
      </Row>
    );
  }
}

export default SupplierProductRowSkeleton;
