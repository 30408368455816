import React, { Component } from "react";
import caretUp from "../images/icons/feather/caret-up.svg";
import caretDown from "../images/icons/feather/caret-down.svg";
import styled from "styled-components";

import { theme } from "../modules/Theme";

const PageWrapper = styled.div`
  width: 100%;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  padding: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  margin: 15px auto auto;

  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }
`;

const Caret = styled.img`
  height: 14px;
  opacity: 0.8;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.colors.medDarkGrey};
`;

const HeaderRow = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

const ChildrenContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export class ExpandableSection extends Component {
  state = { expanded: this.props.defaultExpanded || false };

  render() {
    return (
      <PageWrapper backgroundColor={this.props.backgroundColor}>
        <HeaderRow
          onClick={() =>
            this.setState((prevState) => ({
              expanded: !prevState.expanded,
            }))
          }
        >
          <Title>{this.props.title}</Title>

          <Caret src={this.state.expanded ? caretUp : caretDown} />
        </HeaderRow>

        {this.state.expanded && (
          <ChildrenContainer>{this.props.children}</ChildrenContainer>
        )}
      </PageWrapper>
    );
  }
}
