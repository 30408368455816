import React, { Component } from "react";
import { Row } from "./Layout";
import ButtonNew from "./ButtonNew";
import SelectDropdown from "./SelectDropdown";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { FiX } from "react-icons/fi";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFilters } from "../Selectors";
import { getActiveFilters } from "../modules/Format";
import { resetMoreFilters as resetMoreFiltersAction } from "../Actions";

const mapStateToProps = (state) => ({
  filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ resetMoreFiltersAction }, dispatch),
});

class MoreFilters extends Component {
  submit = () => {
    this.props.showHideFilter("moreFilters");
    this.props.submit();
  };

  clear = () => {
    this.props.resetMoreFilters();
    this.props.resetMoreFiltersAction().then(() => {
      this.props.submit();
    });
  };

  reset = () => {
    this.props.showHideFilter("moreFilters");
    this.clear();
  };

  render() {
    const moreFilters = this.props.moreFilters;
    const filterCount = getActiveFilters(moreFilters).length;
    const displayText = filterCount > 0 ? `(${filterCount})` : null;
    const active = filterCount > 0;

    return (
      <SelectDropdown
        showHideFilter={() => this.props.showHideFilter("moreFilters")}
        name={this.props.name || "More Filters"}
        margin="0 0 0 15px"
        full
        displayText={displayText}
        active={active}
        show={moreFilters.show}
        reset={this.clear}
      >
        <Container>
          <Header>
            <FiX
              size="20"
              color={theme.colors.medDarkGrey}
              onClick={() => this.props.showHideFilter("moreFilters")}
              style={{ cursor: "pointer" }}
            />
            <HeaderText>More Filters</HeaderText>
            <div />
          </Header>
          <FiltersContainer>
            {moreFilters.filters &&
              moreFilters.filters.map((filter, i) => (
                <FilterSection
                  border={i < moreFilters.filters.length - 1}
                  key={i}
                >
                  <SectionTitle>{filter.label}</SectionTitle>
                  <SectionOptions>
                    {filter.options.map((option, i) => (
                      <Row
                        align
                        style={{
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                        key={i}
                      >
                        <div>{option.label}</div>
                        <Checkbox
                          color="primary"
                          checked={option.selected}
                          onChange={(e) =>
                            this.props.updateMoreFilters(
                              filter.label,
                              option.value,
                              false,
                              filter.maxOneSelected
                            )
                          }
                          value={option.value}
                          inputProps={{ "aria-label": option.label }}
                        />
                      </Row>
                    ))}
                  </SectionOptions>
                </FilterSection>
              ))}
          </FiltersContainer>
          <Footer>
            <ButtonNew
              type="secondary"
              text="Clear"
              onClick={this.reset}
              margin="0 20px 0 0"
              width="100%"
            />
            <ButtonNew
              type="main"
              text="Apply"
              onClick={this.submit}
              width="100%"
            />
          </Footer>
        </Container>
      </SelectDropdown>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFilters);

const FilterSection = styled.div`
  padding: 15px;
  border-bottom: ${(p) => (p.border ? "1px" : "0px")} solid
    ${theme.colors.lightBorder};
`;

const SectionOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
`;

const HeaderText = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  flex-shrink: 0 !important;
`;

const FiltersContainer = styled.div`
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
  height: 100% !important;
  overflow: auto !important;
  display: block;
`;

const Footer = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid ${theme.colors.lightBorder};
  flex: 0 0 auto !important;
`;
