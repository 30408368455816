import React from "react";
import { withRouter } from "react-router-dom";

import styled from "styled-components";
import { FiDollarSign, FiGrid, FiZap } from "react-icons/fi";

import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { ButtonNew, Column, FAQ, Row, Testimonials } from "../components";
import fire from "../images/icons/fire-emoji.png";
import SellingPoint from "../views/prebuilt/components/SellingPoint";
import { CardList, PrebuiltCard } from "./components";
import {
  CoverSection,
  ImageSection,
  PackageOption,
} from "./components/dashboard";

import {} from "../modules/Format";

const WideRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FireEmoji = styled.img`
  display: flex;
  margin-right: 10px;
  height: 18px;
`;

const StoreImage = styled.img`
  border-radius: 4px;
  height: 300px;
  cursor: pointer;
  ${(p) => p.marginLeft && "margin-left: 40px;"}
  ${(p) => p.marginRight && "margin-right: 40px;"} 
  max-width: 100%;
  @media (max-width: 800px) {
    height: 250px;
    margin: 0px;
    margin-top: 15px;
    ${(p) =>
      p.sellingPoint && p.imagePlacement === "left" && "margin-bottom: 30px;"};
  }
`;

const GreySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.ultraLightGrey};
  padding: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;

const WhiteBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const PackageOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const WhatsIncludedRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ErinTestimonial = styled.img`
  max-width: 100%;
  margin-top: 30px;
  max-height: 350px;
  @media screen and (max-width: 800px) {
    height: auto;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const faqData = [
  [
    {
      question: "What's included with a pre-built store?",
      answer:
        "In addition to a professional Shopify site full of great products, you also get 3 months free the DropCommerce Pro plan! That means you won't pay a subscription fee for 3 whole months!",
    },
    {
      question: "Will my store look exactly like the demo store?",
      answer:
        "To put it simply, yes! There may be small differences in the products that are available if stock / availability changes, but otherwise it will be identical. No surprises :). Once you receive the store you are welcome to make any modifications you like.",
    },
    {
      question: "Can I change the pricing of products?",
      answer:
        "Absolutely! Once we give you the store, it's yours to do whatever you want with it. You can add / remove products, change the design, etc.",
    },
  ],
  [
    {
      question: "Can I add products from other apps?",
      answer:
        "Yes, our prebuilt stores are Shopify stores like any other so you can still use other apps and product sourcing options.",
    },
    {
      question: "Will my store be like others who buy the same store?",
      answer:
        "We designed our Pre-Built stores to be great jump off points for stores - we find that each customer ends up customizing their stores to tailor them more to their niche after purchase, so no two stores are the same after purchase.",
    },
  ],
];

const storeDesignLevelData = [
  {
    level: "PREMIUM",
    label: "Premium Stores",
    url: "/premium-stores",
  },
  { level: "STARTER", label: "Starter Stores", url: "/starter-stores" },
];

const FireList = ({ text }) => (
  <Row>
    <FireEmoji src={fire} />
    <Text s light>
      {text}
    </Text>
  </Row>
);

const CTASection = ({ title, subtitle, onClick }) => (
  <Column justify align style={{ marginBottom: "50px", marginTop: "50px" }}>
    <Text l bold center>
      {title}
    </Text>
    {subtitle && (
      <Text s light center>
        {subtitle}
      </Text>
    )}
    <ButtonNew
      size="large"
      text="Buy Now - $249.00"
      width="200px"
      margin="20px 0 0 0"
      onClick={onClick}
    />
  </Column>
);

const Dashboard = (props) => {
  const { selectStoreDesignTypeHandler } = props;

  const checkout = () => selectStoreDesignTypeHandler("home_decor");

  return (
    <div>
      <CoverSection onClick={checkout} />

      <Column justify align style={{ marginBottom: "50px" }}>
        <GreySection>
          <Text center bold extra="font-size: 35px;" margin="0 0 20px 0">
            Available Stores
          </Text>

          {storeDesignLevelData.map((levelData, i) => (
            <CardList
              key={i}
              title={levelData.label}
              listView={() => props.history.push(levelData.url)}
            >
              {props.storeDesigns
                .filter((storeDesign) =>
                  storeDesign.level.startsWith(levelData.level)
                )
                .map((storeDesign, i) => (
                  <PrebuiltCard
                    key={i}
                    onClick={() =>
                      selectStoreDesignTypeHandler(storeDesign.type)
                    }
                    background={storeDesign.image}
                  />
                ))}
            </CardList>
          ))}

          <CTASection
            title="Start Your Business Today"
            subtitle="Purchase a high quality dropshipping business today"
            onClick={checkout}
          />
        </GreySection>

        <ErinTestimonial src="https://dc-imports.s3.ca-central-1.amazonaws.com/erintestimonial-2.png" />

        <FAQ data={faqData} />

        <Testimonials />

        <Text.Large>How To Get Started</Text.Large>
        <WideRow style={{ margin: "30px 0 80px 0" }}>
          <SellingPoint
            icon={<FiGrid size="40" color={theme.colors.main} />}
            title="1. Choose A Template"
            description="Choose from 15+ high-quality templates designed to help you start selling fast"
          />
          <SellingPoint
            icon={<FiDollarSign size="40" color={theme.colors.main} />}
            title="2. Purchase Store"
            description="Once you've found a store you like, purchase the store and our team will be notified."
          />
          <SellingPoint
            icon={<FiZap size="40" color={theme.colors.main} />}
            title="3. We Setup Your Store"
            description="Our team will begin working on your store. Most stores are completed within 24 hours."
          />
        </WideRow>

        <CTASection
          title="Get Started Today"
          subtitle="Purchase a high quality dropshipping business today"
          onClick={checkout}
        />
      </Column>

      <GreySection>
        <PackageOptionRow>
          <PackageOption checkout={checkout} />
          <Column style={{ maxWidth: "450px", alignSelf: "center" }}>
            <Text bold m center>
              Get your pre-built Shopify store loaded with converting products
            </Text>

            <Text s light center style={{ marginTop: "20px" }}>
              Each store is ready out of the box. Simply advertise your website
              and start selling. If you need help, we have a range of guides and
              provide support to assist you.
            </Text>
          </Column>
        </PackageOptionRow>

        <Text xs light center style={{ maxWidth: "800px", marginTop: "20px" }}>
          You (the seller) do not purchase, store, or ship any inventory – the
          manufacturer or supplier does. In other words, you sell the products
          online, and the supplier ships them. Starting a business doesn’t get
          any easier.
        </Text>
      </GreySection>

      <WhiteBackground>
        <Column justify align>
          <ImageSection
            imagePlacement="left"
            image="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logistics.svg"
            title="Why Dropshipping?"
            textOne="Dropshipping allows you to earn money selling products online without actually purchasing any products or physically handling inventory."
            textTwo="Promote your store, earn big profits on sales, and let the supplier ship directly to your customer. Starting a business doesn’t get any easier."
          />
          <CTASection
            title="Get Started Today"
            subtitle="Purchase a high quality dropshipping business today"
            onClick={checkout}
          />
          <ImageSection
            imagePlacement="right"
            image="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_wallet_aym5.svg"
            title="Pre-Built Saves You Money"
            textOne="Shopify Developers can charge you as much as $5,000 - $10,000 for a website design and store setup, and that doesn't even include any products!"
            textTwo="With a DropCommerce pre-built store, you can save thousands and use that money for marketing."
          />
          <ImageSection
            imagePlacement="left"
            image="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/finishline.svg"
            title="Speed Matters"
            textOne="Getting your products to market before your competitors is key.
          While you’re contemplating layout and color schemes for your
          website, you can be sure your competition is converting sales, with YOUR customers."
            textTwo="Instead of spending weeks (sometimes
            months) designing your store, you could be making sales with a
            turnkey Shopify Pre-built Store by DropCommerce."
          />

          <CTASection
            title="What Are You Waiting For?"
            subtitle="Purchase a high quality dropshipping business today"
            onClick={checkout}
          />
        </Column>
      </WhiteBackground>

      <GreySection>
        <Text center l bold>
          Don't wait. Launch your store today!
        </Text>
        <Text center s light extra="margin-top: 10px; margin-bottom: 40px;">
          Create Facebook ads instead of spending time and money on building
          your website.
        </Text>

        <WhatsIncludedRow>
          <Column style={{ marginRight: "20px" }}>
            <Text m style={{ marginBottom: "10px" }}>
              What’s included?
            </Text>
            <FireList text="Niche categories" />
            <FireList text="Trending products" />
            <FireList text="Historical proven sales" />
            <FireList text="Facebook ads documentation" />
            <FireList text="High-converting descriptions" />
          </Column>
          <StoreImage src="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/wireframe.svg" />
        </WhatsIncludedRow>
      </GreySection>

      <CTASection
        title="Start Your E-commerce Store Today"
        subtitle="Purchase a high quality dropshipping business today"
        onClick={checkout}
      />
    </div>
  );
};

export default withRouter(Dashboard);
