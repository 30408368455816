import { get } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ButtonNew,
  Container,
  Explanation,
  Row,
  Search,
  Table,
} from "../../../components";
import { api } from "../../../modules/Config";
import { getUrlParameter } from "../../../modules/Format";
import {
  errorNotification,
  infoNotification,
} from "../../../modules/Notification";
import Text from "../../../modules/Text";
import { fetchSupplierProducts } from "../../../views/supplier/SupplierActions";
import { getLocalState } from "../../../views/supplier/SupplierSelectors";

const mapStateToProps = (state) => ({
  baseState: getLocalState(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSupplierProducts }, dispatch),
});

const defaultProps = {};

// const tabModalData = {
//   connect_store: {
//     name: "Connect Your Shopify Store (If Applicable)",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_business_shop_qw5t.svg",
//     description: [
//       "Connect your Shopify store with DropCommerce and we’ll automatically upload your product catalog, pricing, and inventory levels.",
//       "If you are not on Shopify — upload your products with our CSV template",
//     ],
//   },
//   connect_stripe: {
//     name: "Connect Your Stripe Account",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_stripe_payments_o7aw.svg",
//     description: [
//       "Connect your DropCommerce account with a new or existing Stripe account. It’s free to sign up and securely handles all transactions on DropCommerce.",
//     ],
//   },
//   complete_profile: {
//     name: "Complete Your Profile",
//     image:
//       "https://s3.console.aws.amazon.com/s3/object/dc-imports?region=ca-central-1&prefix=graphics/undraw_fill_forms_yltj+%281%29.svg",
//     description: [
//       "Complete any missing information in your profile, like return policy, shipping information, brand information, etc. Let retailers know what you’re all about!",
//     ],
//   },
//   ready_to_go: {
//     name: "Ready to go live!",
//     image:
//       "https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/undraw_finish_line_katerina_limpitsouni_xy20.svg",
//     description: [
//       "After you’ve completed all the necessary steps. Let us know, and we’ll set your products live on the DropCommerce Marketplace.",
//     ],
//   },
// };

class Products extends Component {
  state = { searchTerm: "", showConfirmation: false };

  componentDidMount = () => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const type = getUrlParameter("type", this.props.location) || "active";
    this.props.fetchSupplierProducts(page, searchTerm, type);
    this.setState({ searchTerm });
  };

  onChange = (term) => {
    this.setState({ searchTerm: term });
  };

  onEnterPress = (e) => {
    if (e.key === "Enter") {
      this.searchForProducts();
    }
  };

  clearSearch = () => {
    const type = getUrlParameter("type", this.props.location) || "active";
    this.props.fetchSupplierProducts(1, "", type);
    this.props.history.push(`/supplier/products?type=${type}&page=1&search=`);
    this.setState({ searchTerm: "" });
  };

  searchForProducts = (value) => {
    if (value === "") {
      this.clearSearch();
    } else {
      let searchTerm = this.state.searchTerm;
      const type = getUrlParameter("type", this.props.location) || "active";
      this.props.fetchSupplierProducts(1, searchTerm, type);
      this.props.history.push(
        `/supplier/products?type=${type}&page=1&search=${searchTerm}`
      );
      this.setState({ searchTerm });
    }
  };

  render() {
    let initialSearchTerm =
      getUrlParameter("search", this.props.location) || "";

    const shopifyConnected =
      get(this.props, ["settings", "shopify_url"]) !== "" &&
      get(this.props, ["settings", "shopify_token_saved"]);

    // This helps with displaying the correct message if no products are to be displayed
    let typeOf_noProducts = getUrlParameter("type", this.props.location);
    switch (typeOf_noProducts) {
      case "low_stock":
        typeOf_noProducts = "low stock";
        break;

      case "all":
        typeOf_noProducts = "";
        break;

      default:
    }

    const searchParameterExists = getUrlParameter(
      "search",
      this.props.location
    );

    const noResultsData = {
      title: searchParameterExists
        ? `You do not have any ${typeOf_noProducts} products that meet the search criteria.`
        : `You do not have any ${typeOf_noProducts} products`,
      message:
        "Manually create products or import them from your platform through one of our Integrations",
      buttonText: "VIEW INTEGRATIONS",
      onClick: () => this.props.history.push("/supplier/settings/integrations"),
    };

    const columns = [
      { field: "image", name: "Image", width: "110px" },
      { field: "title", name: "Title", width: "250px" },
      { field: "price", name: "Dropship Price", width: "140px", type: "price" },
      {
        field: "retail_price",
        name: "Retail Price",
        width: "140px",
        type: "price",
      },
      {
        field: "profit",
        name: "Profit",
        width: "140px",
        type: "price",
      },
      {
        field: "profit_with_shipping",
        name: "Profit with Shipping",
        width: "150px",
        type: "price",
      },
      {
        field: "status",
        name: "Status",
        width: "120px",
        statusMap: {
          active: "green",
          out_of_stock: "orange",
          partial_stock: "yellow",
          low_stock: "yellow",
          inactive: "grey",
        },
        labelMap: {
          active: "Active",
          out_of_stock: "Out Of Stock",
          partial_stock: "Partial Stock",
          low_stock: "Low Stock",
          inactive: "Inactive",
        },
      },
      {
        field: "track_inventory",
        name: "Inventory Sync",
        width: "130px",
        type: "boolean",
        hint: "Inventory Sync is only available for suppliers that have connected a Shopify account.",
      },
    ];

    const tabs = [
      {
        name: "All",
        key: "all",
      },
      {
        name: "Active",
        key: "active",
      },
      {
        name: "Inactive",
        key: "inactive",
      },
      {
        name: "Low Stock",
        key: "low_stock",
      },
    ];

    const paginationPage = get(this.props, ["baseState", "pagination", "page"]);
    return (
      <Container>
        {/* {get(this.props, ["baseState", "settings", "active"]) == false && (
          <OnboardingProgress /> // render progress bar only for suppliers that aren't active yet
        )} */}

        {this.state.showConfirmation && (
          <Explanation
            title="Shopify Product Import"
            description='Import products from your Shopify store. This process may take a while, and we will notify you once it is complete. Please select "Proceed" to start the import or "Cancel" to exit.'
            cancel={() => this.setState({ showConfirmation: false })}
            confirm={async () => {
              this.setState({ loading: true });
              try {
                const rawResponse = await fetch(`${api}/supplier_import`, {
                  method: "POST",
                });

                if (!rawResponse.ok) {
                  throw new Error("Oops! Something went wrong :(");
                }

                infoNotification(
                  "We'll let you know when we're done importing your products"
                );
                this.setState({ showConfirmation: false });
              } catch (error) {
                // Handle error
                console.log("Error: ", error);
                errorNotification("Failed to import products");
              } finally {
                this.setState({ loading: false });
              }
            }}
            loading={this.state.loading}
          />
        )}

        <Text.Large extra="margin-bottom: 20px;">Products</Text.Large>
        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: "40px",
            flexWrap: "wrap",
          }}
        >
          <Search
            onChange={this.onChange}
            onEnterPress={this.onEnterPress}
            search={this.state.searchTerm}
            searchForProducts={this.searchForProducts}
            initialSearchTerm={initialSearchTerm}
            clear={() => this.searchForProducts("")}
          />
          <Row>
            {shopifyConnected && (
              <ButtonNew
                text="Shopify import"
                onClick={() => this.setState({ showConfirmation: true })}
                extra="margin: 15px 1px;"
              />
            )}
            {!shopifyConnected && (
              <ButtonNew
                text="New Product"
                onClick={() => this.props.history.push("/supplier/product/new")}
                extra="margin: 15px 1px;"
              />
            )}
          </Row>
        </Row>

        <Table
          hideMobile={["Status", "Retail Price", "Inventory Sync", "Title"]}
          columns={columns}
          action={{
            endpoint: "/supplier_products/",
            mixpanelName: "View Supplier Products",
            fields: ["products", "pagination"],
            queryParams: [
              { name: "search", default: "" },
              { name: "page", default: 1, reset: true },
              {
                name: "type",
                default: "active",
                reset: true,
                useForTabs: true,
              },
            ],
          }}
          reducer="supplier"
          baseState={this.props.baseState}
          rowsLocation={["products"]}
          tabsRoute="/supplier/products"
          tabs={tabs}
          noResultsData={noResultsData}
          rowBodyOnClick={(productId) =>
            this.props.history.push(
              `/supplier/product/${productId}?past_pagination=${paginationPage}`
            )
          }
        />
      </Container>
    );
  }
}

Products.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Products);
