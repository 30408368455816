import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  background: ${(p) => p.color};
  color: white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50%;
  display: flex;
  padding: 14px;
  height: 14px;
  width: 14px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

class Icon extends Component {
  render() {
    const initial = this.props.name
      ? this.props.name.toUpperCase().charAt(0)
      : "?";

    return (
      <Container
        color={this.props.color}
        // activeUser={this.props.activeUser}
      >
        {initial}
      </Container>
    );
  }
}

export default Icon;
