import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLocalState } from "../../../views/supplier/SupplierSelectors";
import { Table, Container } from "../../../components";

const mapStateToProps = (state) => ({
  baseState: getLocalState(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

class Vendors extends Component {
  render() {
    const noResultsData = {
      title: "You don't have any vendors yet.",
      message: `Once vendors have imported your products, they will appear here.`,
    };

    const columns = [
      { field: "name", name: "Name", width: "300px" },
      {
        field: "status",
        name: "Status",
        width: "180px",
        statusMap: {
          P: "yellow",
          A: "green",
          D: "grey",
          C: "grey",
        },
        labelMap: {
          P: "Pending",
          A: "Approved",
          D: "Denied",
          C: "Cancelled",
        },
      },
    ];

    const tabs = [
      {
        name: "All",
        key: "ALL",
      },
      {
        name: "Pending",
        key: "P",
      },
      {
        name: "Approved",
        key: "A",
      },
      {
        name: "Denied",
        key: "D",
      },
    ];

    return (
      <Container>
        <Table
          columns={columns}
          action={{
            endpoint: "/dcvendors/",
            mixpanelName: "View Vendors",
            fields: ["vendors", "pagination"],
            queryParams: [
              { name: "page", default: 1, reset: true },
              {
                name: "status",
                default: "ALL",
                reset: true,
                useForTabs: true,
              },
            ],
          }}
          tabs={tabs}
          tabsRoute="/supplier/vendors/public"
          reducer="supplier"
          baseState={this.props.baseState}
          rowsLocation={["vendors"]}
          noResultsData={noResultsData}
          rowBodyOnClick={(vendorId) =>
            this.props.history.push(`/supplier/vendors/public/${vendorId}`)
          }
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
