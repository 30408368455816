import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import ButtonNew from "../../components/ButtonNew";
import { Modal } from "../../components/Modal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateRefundRequest } from "../../Actions";
import { getIsFetchingRefund } from "../../views/products/ProductsSelectors";
import Text from "../../modules/Text";
import { Input, Row } from "../../components";
import Collapsible from "../../components/Collapsible";

const mapStateToProps = (state) => ({
  isFetchingRefund: getIsFetchingRefund(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ updateRefundRequest }, dispatch),
});

export const Title = styled.div`
  color: #265454;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const ErrorText = styled.div`
  color: ${theme.colors.darkRed};
  font-size: 16px;
  font-weight: 400;
`;

export const SupplierName = styled.div`
  margin-bottom: 10px;
  color: #265454;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

export const ButtonStyle = styled.div`
  padding: 5px 5px 5px 0px;
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 125px;
  max-width: 800px;
  margin-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px !important;
  resize: none;
  @media (max-width: 800px) {
    height: 100px;
  }
`;
export const PolicyHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-left: 10px !important;
  color: #265454;
`;
const DetailsWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 100%;
  margin: 15px;
`;
const DetailsTop = styled.div`
  padding: 5px;
`;
const RefundWrapper = styled.div`
  border-top: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 100%;
  padding: 5px;
`;

class ModalRefundStore extends Component {
  state = {
    errors: {},
    reason: "",
    readed: false,
    quantity: 0,
    showModal: false,
    orderId: 0,
    supplierId: 0,
    isFetchingUpdate: false,
    isFetchingCancel: false,
  };

  updateRefund = (refund, status) => {
    let errors = {};

    if (this.state.quantity > refund.product_quantity) {
      errors["quantity"] = "You cannot ask for more than the quantity bought";
    }

    this.setState({ errors });
    if (isEmpty(errors)) {
      if (status === "CANCELLED") {
        this.setState({ isFetchingCancel: true }, () => {
          this.props
            .updateRefundRequest(refund.id, {
              order: refund.order,
              supplier: refund.supplier,
              store: refund.store,
              item: refund.product,
              status: status,
              reason: refund.reason,
              quantity: refund.quantity,
            })
            .then((response) => {
              if (response.error) {
                // TODO: - use new notification package
                this.setState({ error: response.error });
                this.setState({
                  isFetchingCancel: false,
                  showModal: false,
                });
              } else {
                this.setState({
                  isFetchingCancel: false,
                  showModal: false,
                });
                window.location.reload();
              }
            });
        });
      } else {
        if (this.state.reason === "") {
          this.setState((p) => ({ ...p, reason: refund.reason }));
        }
        if (this.state.quantity === 0) {
          this.setState((p) => ({ ...p, quantity: refund.quantity }));
        }
        this.setState({ isFetchingUpdate: true }, () => {
          this.props
            .updateRefundRequest(refund.id, {
              order: refund.order,
              supplier: refund.supplier,
              store: refund.store,
              item: refund.product,
              status: refund.status,
              reason: this.state.reason,
              quantity: this.state.quantity,
            })
            .then((response) => {
              if (response.error) {
                // TODO: - use new notification package
                this.setState({ error: response.error });
                this.setState({
                  isFetchingUpdate: false,
                  showModal: false,
                });
              } else {
                this.setState({
                  isFetchingUpdate: false,
                  showModal: false,
                });
                window.location.reload();
              }
            });
        });
      }
    }
  };

  render() {
    const { data } = this.props;
    if (this.state.showModal) {
      return (
        <Modal hide={() => this.setState({ showModal: false })}>
          {data &&
            data.refunds.map((refund, i) =>
              data.qty > 1 ? (
                <Collapsible
                  key={i}
                  label={"Refund Request " + refund.id + " Details"}
                >
                  <DetailsWrapper>
                    <DetailsTop>
                      <Row
                        align
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Text.Large>{refund.supplier_name}</Text.Large>
                      </Row>
                    </DetailsTop>
                    <RefundWrapper>
                      <Row
                        align
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Row align>
                          <Text
                            link
                            extra="margin-top: 5px; margin-bottom: 5px;"
                            onClick={() =>
                              window.open(`/order/${refund.order}`)
                            }
                          >
                            {refund.order}
                          </Text>
                        </Row>
                      </Row>
                      <div>
                        <Row>
                          <Text.Small bold>Item :</Text.Small>
                        </Row>
                        <Row>
                          <Text.Small light extra="opacity: 0.7;">
                            {refund.product_name}
                          </Text.Small>
                        </Row>
                      </div>
                      <br></br>
                      {refund.product_quantity > 1 ? (
                        refund.status === "PENDING" ? (
                          <div>
                            <Row>
                              <Text.Small bold>Quantity :</Text.Small>
                            </Row>
                            <Row>
                              <Input
                                placeholder={refund.quantity}
                                type="number"
                                onChange={(e) =>
                                  this.setState({
                                    quantity: e.target.value,
                                  })
                                }
                                width="200px"
                              />
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <Row>
                              <Text.Small bold>Quantity:</Text.Small>
                            </Row>
                            <Row>
                              <Text.Small light extra="opacity: 0.7;">
                                {refund.quantity}
                              </Text.Small>
                            </Row>
                          </div>
                        )
                      ) : (
                        <div></div>
                      )}
                      {this.state.errors.quantity && (
                        <ErrorText>{this.state.errors.quantity}</ErrorText>
                      )}
                      <br></br>
                      <div>
                        <Row>
                          <Text.Small bold>
                            Total (Item Price * Quantity)
                          </Text.Small>
                        </Row>
                        <Row>
                          <Text.Small>
                            $ {refund.product_price * refund.quantity}
                          </Text.Small>
                        </Row>
                      </div>
                      <br></br>

                      <Text bold>Request Justification:</Text>
                      {refund.status === "PENDING" ? (
                        <Row>
                          <StyledTextArea
                            placeholder={refund.reason}
                            onChange={(e) =>
                              this.setState({
                                reason: e.target.value,
                              })
                            }
                          />
                        </Row>
                      ) : (
                        <Text
                          extra="margin-top: 10px; margin-bottom: 10px;"
                          light={refund.reason === ""}
                        >
                          {refund.reason || "No justification"}
                        </Text>
                      )}
                    </RefundWrapper>
                  </DetailsWrapper>
                  {refund.status === "PENDING" && (
                    <Row align style={{ justifyContent: "end" }}>
                      <ButtonNew
                        type="main"
                        text="Update"
                        isFetching={this.state.isFetchingUpdate}
                        onClick={() => this.updateRefund(refund, "NONE")}
                        extra="width: 200px; margin-top: 10px;background-color:darkGreen;"
                      />
                      <ButtonNew
                        type="main"
                        text="Cancel Request"
                        isFetching={this.state.isFetchingCancel}
                        onClick={() => this.updateRefund(refund, "CANCELLED")}
                        extra="width: 200px; margin-top: 10px; margin-left: 20px !important; background-color:darkRed;"
                      />
                    </Row>
                  )}
                </Collapsible>
              ) : (
                <div key={i}>
                  <DetailsWrapper>
                    <DetailsTop>
                      <Row
                        align
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Text.Large>{refund.supplier_name}</Text.Large>
                      </Row>
                    </DetailsTop>
                    <RefundWrapper>
                      <Row
                        align
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <Row align>
                          <Text
                            link
                            extra="margin-top: 5px; margin-bottom: 5px;"
                            onClick={() =>
                              window.open(`/order/${refund.order}`)
                            }
                          >
                            {refund.order}
                          </Text>
                        </Row>
                      </Row>
                      <div>
                        <Row>
                          <Text.Small bold>Item :</Text.Small>
                        </Row>
                        <Row>
                          <Text.Small light extra="opacity: 0.7;">
                            {refund.product_name}
                          </Text.Small>
                        </Row>
                      </div>
                      <br></br>
                      {refund.product_quantity > 1 ? (
                        refund.status === "PENDING" ? (
                          <div>
                            <Row>
                              <Text.Small bold>Quantity :</Text.Small>
                            </Row>
                            <Row>
                              <Input
                                placeholder={refund.quantity}
                                type="number"
                                onChange={(e) =>
                                  this.setState({
                                    quantity: e.target.value,
                                  })
                                }
                                width="200px"
                              />
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <Row>
                              <Text.Small bold>Quantity:</Text.Small>
                            </Row>
                            <Row>
                              <Text.Small light extra="opacity: 0.7;">
                                {refund.quantity}
                              </Text.Small>
                            </Row>
                          </div>
                        )
                      ) : (
                        <div></div>
                      )}

                      <br></br>
                      <div>
                        <Row>
                          <Text.Small bold>
                            Total (Item Price * Quantity)
                          </Text.Small>
                        </Row>
                        <Row>
                          <Text.Small>
                            $ {refund.product_price * refund.quantity}
                          </Text.Small>
                        </Row>
                      </div>
                      <br></br>

                      <Text bold>Request Justification:</Text>
                      {refund.status === "PENDING" ? (
                        <Row>
                          <StyledTextArea
                            placeholder={refund.reason}
                            onChange={(e) =>
                              this.setState({
                                reason: e.target.value,
                              })
                            }
                          />
                        </Row>
                      ) : (
                        <Text
                          extra="margin-top: 10px; margin-bottom: 10px;"
                          light={refund.reason === ""}
                        >
                          {refund.reason || "No justification"}
                        </Text>
                      )}
                    </RefundWrapper>
                  </DetailsWrapper>
                  {refund.status === "PENDING" && (
                    <Row align style={{ justifyContent: "end" }}>
                      <ButtonNew
                        type="main"
                        text="Update"
                        isFetching={this.state.isFetchingUpdate}
                        onClick={() => this.updateRefund(refund, "NONE")}
                        extra="width: 200px; margin-top: 10px;background-color:darkGreen;"
                      />
                      <ButtonNew
                        type="main"
                        text="Cancel Request"
                        isFetching={this.state.isFetchingCancel}
                        onClick={() => this.updateRefund(refund, "CANCELLED")}
                        extra="width: 200px; margin-top: 10px; margin-left: 20px !important; background-color:darkRed;"
                      />
                    </Row>
                  )}
                </div>
              )
            )}
        </Modal>
      );
    } else {
      return (
        <ButtonStyle>
          <ButtonNew
            type="main"
            text="Refund Detail"
            extra="width: 180px; background-color: #32968E; :hover { background-color: #3296DD }"
            onClick={() => {
              this.setState({ showModal: true });
            }}
          />
        </ButtonStyle>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRefundStore);
