import React, { Component } from "react";
import { Row } from "./Layout";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import Label from "./Label";
import Hint from "./Hint";

export const Container = styled.div`
  margin-top: ${(p) => (p.noTopMargin ? "0px" : "15px")};
  margin-left: ${(p) => (p.indent ? "20px" : "0px")};
`;

export const SwitchWrapper = styled.div`
  background: ${(p) => (p.active ? theme.colors.main : "white")};
  width: 55px;
  border-radius: 100px;
  border: 1px solid
    ${(p) => (p.active ? theme.colors.main : theme.colors.medGrey)};
  padding: 2px;
  display: flex;
  justify-content: ${(p) => (p.active ? "flex-end" : "flex-start")};
  transition: 0.4s;
  margin: ${(p) => p.margin || "0px 0px 0px 15px"};
  cursor: pointer;
`;

export const SwitchBall = styled.div`
  background: ${(p) => (p.active ? "white" : theme.colors.medGrey)};
  border-radius: 100px;
  height: 22px;
  width: 22px;
  padding: 1px;
`;

class Switch extends Component {
  render() {
    return (
      <Container
        indent={this.props.indent}
        noTopMargin={this.props.noTopMargin}
      >
        <Row align>
          {this.props.label && (
            <Label pro={this.props.pro} rowStyle={{ margin: "0px" }}>
              {this.props.label}
            </Label>
          )}

          {this.props.warning && (
            <Hint hint={this.props.warning} type="warning" lessMargin />
          )}

          {this.props.hint && (
            <Hint hint={this.props.hint} hintTwo={this.props.hintTwo} />
          )}

          <SwitchWrapper
            onClick={this.props.disabled ? null : this.props.toggle}
            active={this.props.active}
            margin={this.props.margin}
          >
            <SwitchBall active={this.props.active} />
          </SwitchWrapper>
        </Row>

        {this.props.error && (
          <Text.Small margin="10px 0 0 0" red>
            {this.props.error}
          </Text.Small>
        )}

        {this.props.description && (
          <Text.Small light extra="margin-top: 5px; font-size: 14px;">
            {this.props.description}
          </Text.Small>
        )}
      </Container>
    );
  }
}

export default Switch;
