import React from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { postRequest } from "../../../modules/API";
import { paySupplierViaPayPal } from "../../../Actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setError } from "../../../Actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ paySupplierViaPayPal, setError }, dispatch),
});

class PayPalBtn extends React.Component {
  state = {
    amountToCharge: this.props.charge.toString(),
    currencyToCharge: this.props.currency,
  };

  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: this.props.currency,
            value: this.state.amountToCharge,
          },
          payee: {
            merchant_id: this.props.merchantID,
          },
          shipping: {
            name: {
              full_name: `${this.props.shipToAddress.first_name} ${this.props.shipToAddress.last_name}`,
            },
            address: {
              address_line_1: this.props.shipToAddress.address1,
              address_line_2: this.props.shipToAddress.address2 || "",
              admin_area_2: this.props.shipToAddress.city,
              admin_area_1: this.props.shipToAddress.province_code,
              postal_code: this.props.shipToAddress.zip,
              country_code: this.props.shipToAddress.country_code,
            },
          },
        },
      ],
    });
  };

  paymentApproved = (data, actions) => {
    return actions.order
      .capture()
      .then((details) =>
        postRequest({
          url: "api/payments/",
          data: {
            status: "PAYED",
            order: this.props.orderID,
            transaction_id: details.id,
            supplier: this.props.supplierID,
          },
        }).then(this.props.paySupplierViaPayPal(this.props.supplierID))
      )
      .catch((err) => console.log(err));
  };

  paymentIncomplete = (data, actions) => {
    postRequest({
      url: "api/payments/",
      data: {
        status: "UNPAID",
        order: this.props.orderID,
        supplier: this.props.supplierID,
        transaction_id: data.orderID,
      },
    }).then((response) => console.log(`response`, response));
  };

  render() {
    const providerOptions = {
      // Pass a client ID that is either:
      // A. associated with your partner, or
      // B. your marketplace account.
      // So, we use our sandbox marketplace account ID.
      // My theory is --
      // If the client ID provided to access the PayPal script isn't related to
      // neither of the PAYER or the payee in PayPal's records, PayPal responds with
      // a "404 unauthorized" error when we try to capture the transaction.

      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      "disable-funding": "credit,card",
    };
    if (this.props.currency && this.props.currency === "CAD") {
      providerOptions.currency = "CAD";
    }
    return (
      <div>
        <PayPalScriptProvider options={providerOptions}>
          <PayPalButtons
            createOrder={(data, actions) => this.createOrder(data, actions)}
            onApprove={(data, actions) => this.paymentApproved(data, actions)}
            onCancel={(data, actions) => this.paymentIncomplete(data, actions)}
            onError={() => {
              this.props.setError(
                "Oops! It appears there's a hiccup while processing your request, and it's related to the PayPal payment information"
              );
            }}
          />
        </PayPalScriptProvider>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPalBtn);
