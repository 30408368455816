import React, { Component } from "react";
import { ButtonNew, Row } from "../../../components";
import PricingSection from "./PricingSection";
import { theme } from "../../../modules/Theme";

import AddToCartModal from "../../cart/AddToCartModal";
import Text from "../../../modules/Text";
import { get } from "lodash";
import {
  FiArrowUp,
  FiCheck,
  FiClock,
  FiMail,
  FiPlus,
  FiTag,
} from "react-icons/fi";
import { withRouter } from "react-router-dom";
import { requestApproval } from "../ProductsActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ProductInfoSection extends Component {
  state = {
    showAddToCartModal: false,
    modalType: null,
  };

  showExplanation = (e, type, bool) => {
    e.stopPropagation();
    this.setState({ [type]: bool });
  };

  renderApprovalButton = (product) => {
    if (product.out_of_stock) {
      return (
        <ButtonNew
          text="Out Of Stock"
          disabled
          type="secondary"
          margin="0 15px 15px 0"
          width="200px"
        />
      );
    }

    if (product.supplier.approval_status === "A") {
      if (product.imported === "shopify") {
        return (
          <ButtonNew
            text="Added To Store"
            type="main"
            disabled
            icon={<FiCheck size="18" color="white" />}
            margin="0 15px 15px 0"
            width="200px"
            onClick={() => this.props.history.push("/imported/store")}
          />
        );
      } else if (
        product.imported === "list" ||
        this.state.addedToImportList === true
      ) {
        return (
          <ButtonNew
            text="View In Import List"
            isFetching={product.isFetching}
            type="main-light"
            icon={<FiCheck size="18" color="white" />}
            margin="0 15px 15px 0"
            width="200px"
            onClick={() => this.props.history.push("/imported/list")}
          />
        );
      } else {
        return (
          <ButtonNew
            disabled={product.supplier.status === "HIDDEN"}
            text="Add To Import List"
            isFetching={product.isFetching}
            onClick={() => {
              this.props.addToImportList(product.id);
              this.setState({ addedToImportList: true });
              // this helps with "disabling" the button
            }}
            icon={<FiPlus size="18" color="white" />}
            type="main"
            margin="0 15px 15px 0"
            width="200px"
          />
        );
      }
    } else if (product.supplier.approval_status === "P") {
      return (
        <ButtonNew
          text={this.state.hoverPending ? "Cancel Request" : "Pending Approval"}
          isFetching={product.isFetching}
          onClick={() => this.props.requestApproval(product.supplier.id, true)}
          onMouseEnter={() => this.setState({ hoverPending: true })}
          onMouseLeave={() => this.setState({ hoverPending: false })}
          type="main"
          disabled
          icon={<FiClock size="18" color="white" />}
          margin="0 15px 15px 0"
          width="200px"
          buttonStyle={this.state.hoverPending && "background: red;"}
        />
      );
    } else if (["R", "C", "NEW"].includes(product.supplier.approval_status)) {
      return (
        <ButtonNew
          disabled={product.supplier.status === "HIDDEN"}
          text="Request Approval"
          icon={<FiMail size="18" color="white" />}
          onClick={() =>
            this.props.showApprovalRequirements(product.supplier.id)
          }
          type="main"
          isFetching={product.isFetching}
          margin="0 15px 15px 0"
          width="200px"
        />
      );
    } else if (product.supplier.approval_status === "D") {
      return (
        <ButtonNew
          text="Pending Approval"
          type="main"
          disabled
          icon={<FiClock size="18" color="white" />}
          margin="0 15px 15px 0"
          width="200px"
        />
      );
    }
  };

  supplierShipsToSampleAddress = () => {
    const shippingOptions = get(this.props, ["product", "shipping_options"]);

    const storeCountry = get(this.props, [
      "settings",
      "store",
      "shipping_address",
      "country_code",
    ]);

    if (storeCountry === "CA" && get(shippingOptions, "CA")) {
      return true;
    }
    if (storeCountry === "US" && get(shippingOptions, "US")) {
      return true;
    }
    return !["CA", "US"].includes(storeCountry) && get(shippingOptions, "INTL");
  };

  render() {
    const { product } = this.props;

    const showSampleButtons = !["R", "C", "NEW", "P"].includes(
      product.supplier.approval_status
    );

    const storeCountry = get(this.props, [
      "settings",
      "store",
      "shipping_address",
      "country_code",
    ]);

    const isNonePlan =
      get(this.props, ["settings", "store", "plan"]) === "none";

    return (
      <React.Fragment>
        {this.state.showAddToCartModal && (
          <AddToCartModal
            product={product}
            hideModal={() => this.setState({ showAddToCartModal: false })}
            modalType={this.state.modalType}
          />
        )}

        <div style={{ padding: "20px" }}>
          <Row style={{ flexWrap: "wrap" }}>
            {this.renderApprovalButton(product)}

            {isNonePlan && (
              <ButtonNew
                text="Upgrade To Order Samples"
                isFetching={product.isFetching}
                onClick={() => this.props.history.push("/settings/plans")}
                type="secondary"
                icon={<FiArrowUp size="18" color={theme.colors.medGrey} />}
                margin="0 15px 15px 0"
              />
            )}

            {!isNonePlan &&
              storeCountry &&
              this.supplierShipsToSampleAddress() &&
              showSampleButtons && (
                <ButtonNew
                  disabled={product.supplier.status === "HIDDEN"}
                  text="Order Samples"
                  isFetching={product.isFetching}
                  onClick={() =>
                    this.setState({
                      showAddToCartModal: true,
                      modalType: "SAMPLE",
                    })
                  }
                  type="secondary"
                  icon={<FiTag size="18" color={theme.colors.medGrey} />}
                  margin="0 15px 15px 0"
                />
              )}

            <ButtonNew
              disabled={product.supplier.status === "HIDDEN"}
              text="Message Supplier"
              onClick={() =>
                this.props.history.push(
                  "/inbox?to_supplier=" + product.supplier.id
                )
              }
              type="secondary"
              icon={<FiMail size="18" color={theme.colors.medGrey} />}
              margin="0 15px 15px 0"
            />
          </Row>

          {!storeCountry && (
            <Text.Small light extra="margin-bottom: 15px;">
              You have not set an address for ordering samples.
            </Text.Small>
          )}

          {storeCountry && !this.supplierShipsToSampleAddress() && (
            <Text.Small light extra="margin-bottom: 15px;">
              This supplier does not offer shipping to your Sample Address
              country.
            </Text.Small>
          )}

          <PricingSection {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ requestApproval }, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(ProductInfoSection)
);
