import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchTags,
  updateTag,
  updateAll,
  saveTags,
} from "../../../views/supplier/SupplierActions";
import { getSettings } from "../../../Selectors";
import { Row } from "../../../components/Layout";
import Text from "../../../modules/Text";
import ButtonNew from "../../../components/ButtonNew";
import Titlebar from "../../../components/Titlebar";

import { Container } from "../../../components/Container";
import SupplierProductRowSkeleton from "../components/SupplierProductRowSkeleton";
import { getUrlParameter } from "../../../modules/Format";
import { get } from "lodash";
import Search from "../../../components/Search";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import {
  getTags,
  getIsFetching,
} from "../../../views/supplier/SupplierSelectors";
import xIcon from "../../../images/icons/feather/x-white.svg";
import plusIcon from "../../../images/icons/feather/plus-white.svg";

export const TagProductsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  border: 2px solid
    ${(p) => (p.active ? theme.colors.main : theme.colors.lightBorder)};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.darkGrey};
`;

export const TagProductWrapper = styled.div`
  width: 300px;
  display: flex;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

export const TagProductButton = styled.div`
  background: ${(p) =>
    p.type === "add" ? theme.colors.main : theme.colors.accent};
  padding: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

export const TagProductButtonIcon = styled.img`
  height: 12px;
  width: 12px;
`;

export const TagProductImage = styled.img`
  height: 80px;
  width: 80px;
  object-fit: contain;
  margin-right: 15px;
`;

export const TagProductTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.darkGrey};
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-right: 20px;
  color: ${theme.colors.darkGrey};
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  tags: getTags(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    { fetchTags, updateTag, updateAll, saveTags },
    dispatch
  ),
});

const defaultProps = {
  isFetching: false,
  products: [],
  settings: {},
  pagination: {},
};

const TagProduct = ({ title, image, type, onClick }) => (
  <TagProductWrapper>
    <TagProductImage src={image} />
    <TagProductTitle>{title}</TagProductTitle>
    <TagProductButton type={type} onClick={onClick}>
      <TagProductButtonIcon src={type === "add" ? plusIcon : xIcon} />
    </TagProductButton>
  </TagProductWrapper>
);

const categories = [
  {
    category: "womens_clothing",
    name: "Women's Clothing",
  },
  {
    category: "clothing_mens",
    name: "Men's Clothing",
  },
  {
    category: "mother_kids",
    name: "Mother & Kids",
  },
  {
    category: "accessories",
    name: "Accessories",
  },
  {
    category: "jewelry",
    name: "Jewelry",
  },
  {
    category: "electronics",
    name: "Electronics",
  },
  {
    category: "beauty_health",
    name: "Beauty & Health",
  },
  {
    category: "home_garden",
    name: "Home & Garden",
  },
  {
    category: "pets",
    name: "Pets",
  },
  {
    category: "bags",
    name: "Bags",
  },
  {
    category: "watches",
    name: "Watches",
  },
  {
    category: "sports_fitness",
    name: "Sports & Fitness",
  },
  {
    category: "outdoor",
    name: "Outdoor",
  },
  {
    category: "toys",
    name: "Toys",
  },
  {
    category: "music",
    name: "Music",
  },
  {
    category: "party_event",
    name: "Party & Event",
  },
  {
    category: "gifts",
    name: "Gifts & Misc",
  },
  {
    category: "food_drink",
    name: "Food & Drink",
  },
];

// const subcategories = {
//   accessories: [
//     { category: "jewelry", name: "Jewelry" },
//     { category: "watches", name: "Watches" },
//     { category: "wallets", name: "Wallets" },
//     { category: "hats", name: "Hats" },
//     { category: "eyewear", name: "Eyewear" },
//     { category: "phone_cases", name: "Phone Cases" },
//   ],
//   home_garden: [
//     { category: "art_canvas", name: "Art & Canvas" },
//     { category: "rugs", name: "Rugs & Carpets" },
//     { category: "lighting", name: "Lights & Candles" },
//     { category: "pillows", name: "Pillows" },
//     { category: "cooking_baking", name: "Cooking & Baking" },
//     { category: "gardening", name: "Gardening" },
//   ],
//   mother_kids: [
//     { category: "baby", name: "Baby & Toddler" },
//     { category: "maternity", name: "Maternity" },
//     { category: "party_event", name: "Party & Event" },
//     { category: "toys", name: "Toys" },
//   ],
//   jewelry: [
//     { category: "charms", name: "Charms" },
//     { category: "rings", name: "Rings" },
//     { category: "ear_jewelry", name: "Earrings" },
//     { category: "bracelets_bangles", name: "Bracelets & Bangles" },
//     { category: "necklaces_pendants", name: "Necklaces & Pendants" },
//   ],
//   womens_clothing: [
//     { category: "dresses", name: "Dresses" },
//     { category: "t_shirts", name: "Tops & Shirts" },
//     { category: "jackets_coats", name: "Jackets & Coats" },
//     { category: "pants", name: "Pants & Leggings" },
//     { category: "shorts_skirts", name: "Shorts & Skirts" },
//     { category: "shoes", name: "Shoes & Socks" },
//     { category: "sweatshirts_hoodies", name: "Sweaters & Hoodies" },
//     { category: "swimwear", name: "Swimwear" },
//     { category: "hats", name: "Hats" },
//     { category: "sports_fitness", name: "Sports & Fitness" },
//   ],
//   clothing_mens: [
//     { category: "hats", name: "Hats" },
//     { category: "jackets", name: "Jackets & Coats" },
//     { category: "pants", name: "Pants" },
//     { category: "shoes", name: "Shoes & Socks" },
//     { category: "shorts", name: "Shorts" },
//     { category: "sweatshirts_hoodies", name: "Sweatshirts & Hoodies" },
//     { category: "t_shirts", name: "Shirts & Tops" },
//   ],

//   // {
//   //   category: ,
//   //   name: "Women's Clothing"
//   // },
//   // {
//   //   category: "clothing_mens",
//   //   name: "Men's Clothing"
//   // },
//   // {
//   //   category: "mother_kids",
//   //   name: "Mother & Kids"
//   // },
//   // {
//   //   category: "accessories",
//   //   name: "Accessories"
//   // },
//   // {
//   //   category: "jewelry",
//   //   name: "Jewelry"
//   // },
//   // {
//   //   category: "electronics",
//   //   name: "Electronics"
//   // },
//   // {
//   //   category: "beauty_health",
//   //   name: "Beauty & Health"
//   // },
//   // {
//   //   category: "home_garden",
//   //   name: "Home & Garden"
//   // },
//   // {
//   //   category: "pets",
//   //   name: "Pets"
//   // },
//   // {
//   //   category: "bags",
//   //   name: "Bags"
//   // },
//   // {
//   //   category: "watches",
//   //   name: "Watches"
//   // },
//   // {
//   //   category: "sports_fitness",
//   //   name: "Sports & Fitness"
//   // },
//   // {
//   //   category: "outdoor",
//   //   name: "Outdoor"
//   // },
//   // {
//   //   category: "toys",
//   //   name: "Toys"
//   // },
//   // {
//   //   category: "music",
//   //   name: "Music"
//   // },
//   // {
//   //   category: "party_event",
//   //   name: "Party & Event"
//   // },
//   // {
//   //   category: "gifts",
//   //   name: "Gifts & Misc"
//   // },
//   // {
//   //   category: "food_drink",
//   //   name: "Food & Drink"
//   // }
// };

class Tags extends Component {
  state = { search: "" };

  componentDidMount = () => {
    // const page = getUrlParameter("p", this.props.location) || 1;
    // const searchTerm = getUrlParameter("s", this.props.location) || "";
    let category = getUrlParameter("category", this.props.location);
    this.props.fetchTags(category);
    // this.setState({ searchTerm });
  };

  // onChange = (term) => {
  //   this.setState({ searchTerm: term });
  // };

  // onEnterPress = (e) => {
  //   if (e.key === "Enter") {
  //     this.searchForProducts();
  //   }
  // };

  // fetchSupplierProducts = (page) => {
  //   const searchTerm = getUrlParameter("s", this.props.location) || "";
  //   this.props.history.push(`/supplier/products?p=${page}&s=${searchTerm}`);
  //   this.props.fetchSupplierProducts(page, searchTerm);
  // };

  // clearSearch = () => {
  //   this.props.fetchSupplierProducts(1, "");
  //   this.props.history.push(`/supplier/products?p=1&s=`);
  //   this.setState({ searchTerm: "" });
  // };

  // searchForProducts = (value) => {
  //   if (value === "") {
  //     this.clearSearch();
  //   } else {
  //     let searchTerm = this.state.searchTerm;
  //     this.props.fetchSupplierProducts(1, searchTerm);
  //     this.props.history.push(`/supplier/products?p=1&s=${searchTerm}`);
  //     this.setState({ searchTerm });
  //   }
  // };

  saveTags = () => {
    const products = get(this.props, ["tags", "products"]);

    const filteredProducts =
      products && products.filter((product) => product.changed);

    this.props.saveTags(filteredProducts);
  };

  updateCategory = (category) => {
    this.props.history.push(`/supplier/categories?category=${category}`);
  };

  updateSubcategory = (subcategory) => {
    let category = getUrlParameter("category", this.props.location);
    this.props.history.push(
      `/supplier/categories?category=${category}&subcategory=${subcategory}`
    );
  };

  searchForProducts = () => {
    let category = getUrlParameter("category", this.props.location);
    let subcategory = getUrlParameter("subcategory", this.props.location) || "";
    this.props.history.push(
      `/supplier/categories?category=${category}&subcategory=${subcategory}&search=${this.state.search}`
    );
  };

  onEnterPress = (e) => {};

  render() {
    let category = getUrlParameter("category", this.props.location);
    let subcategory = getUrlParameter("subcategory", this.props.location) || "";
    let search = getUrlParameter("search", this.props.location) || "";

    const products = get(this.props, ["tags", "products"]);

    let matchingProducts = [];
    let otherProducts = [];

    if (products) {
      if (category === "") {
        matchingProducts = products.filter(
          (product) => product.categories.length === 0
        );
      } else if (category && subcategory === "") {
        matchingProducts = products.filter((product) =>
          product.categories.includes(category)
        );
        otherProducts = products.filter(
          (product) => !product.categories.includes(category)
        );
      } else if (category && subcategory !== "") {
        matchingProducts = products.filter(
          (product) =>
            product.categories.includes(category) &&
            product.categories.includes(subcategory)
        );
        otherProducts = products.filter(
          (product) =>
            product.categories.includes(category) &&
            !product.categories.includes(subcategory)
        );
      } else {
        matchingProducts = products;
      }
    }

    if (search && search !== "") {
      matchingProducts = matchingProducts.filter((product) =>
        product.title.toUpperCase().includes(search.toUpperCase())
      );
      otherProducts = otherProducts.filter((product) =>
        product.title.toUpperCase().includes(search.toUpperCase())
      );
    }

    return (
      <>
        <Titlebar title="Category Manager" />
        <Container>
          <ButtonNew
            type="main"
            text="Save Changes"
            extra="margin-bottom: 20px; width: 255px;"
            onClick={() => this.saveTags(matchingProducts)}
          />

          <Text bold extra="margin-bottom: 5px">
            Categories
          </Text>
          <Row style={{ marginBottom: "15px", flexWrap: "wrap" }}>
            {categories.map((cat, idx) => (
              <Tag
                key={idx}
                onClick={() => this.updateCategory(cat.category)}
                active={cat.category === category}
              >
                {cat.name}
              </Tag>
            ))}
            <Tag
              onClick={() => this.updateCategory("")}
              active={category === ""}
            >
              Uncategorized
            </Tag>
          </Row>

          {/* <Text bold extra="margin-bottom: 5px">
            Subcategories
          </Text>
          <Row style={{ marginBottom: "15px", flexWrap: "wrap" }}>
            {subcategories[category].map((subcat, idx) => (
              <Tag
                key={idx}
                onClick={() => this.updateSubcategory(subcat.category)}
                active={subcat.category === subcategory}
              >
                {subcat.name}
              </Tag>
            ))}
          </Row> */}

          <Search
            onChange={(search) => this.setState({ search })}
            search={this.state.search}
            initialSearchTerm=""
            searchForProducts={this.searchForProducts}
            onEnterPress={this.onEnterPress}
          />

          <div style={{ marginTop: "15px" }}>
            {this.props.isFetching > 0 ? (
              <React.Fragment>
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
                <SupplierProductRowSkeleton />
              </React.Fragment>
            ) : (
              <div>
                <Row style={{ marginBottom: "20px" }}>
                  <SectionTitle>In Category</SectionTitle>
                  <Text
                    light
                    link
                    onClick={() =>
                      this.props.updateAll({
                        tag: subcategory !== "" ? subcategory : category,
                        type: "remove",
                        productIds: matchingProducts.map(
                          (product) => product.id
                        ),
                      })
                    }
                  >
                    Remove All
                  </Text>
                </Row>

                <TagProductsWrapper>
                  {matchingProducts &&
                    matchingProducts.map((product) => (
                      <TagProduct
                        type="remove"
                        key={product.id}
                        image={product.image}
                        title={product.title}
                        onClick={() =>
                          this.props.updateTag({
                            product: product.id,
                            tag: subcategory !== "" ? subcategory : category,
                            type: "remove",
                          })
                        }
                      />
                    ))}
                  {matchingProducts && matchingProducts.length === 0 && (
                    <div>{category ? "No products" : "Select a category"}</div>
                  )}
                </TagProductsWrapper>

                <Row style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <SectionTitle>Not In Category</SectionTitle>
                  <Text
                    light
                    link
                    onClick={() =>
                      this.props.updateAll({
                        tag: subcategory !== "" ? subcategory : category,
                        type: "add",
                        productIds: otherProducts.map((product) => product.id),
                      })
                    }
                  >
                    Add All
                  </Text>
                </Row>

                <TagProductsWrapper>
                  {otherProducts &&
                    otherProducts.map((product) => (
                      <TagProduct
                        type="add"
                        key={product.id}
                        image={product.image}
                        title={product.title}
                        onClick={() =>
                          this.props.updateTag({
                            product: product.id,
                            tag: subcategory !== "" ? subcategory : category,
                            type: "add",
                          })
                        }
                      />
                    ))}
                  {otherProducts && otherProducts.length === 0 && (
                    <div>{category ? "No products" : "Select a category"}</div>
                  )}
                </TagProductsWrapper>
              </div>
            )}
          </div>
        </Container>
      </>
    );
  }
}

Tags.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
