import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { Row } from "../../components";

const CardLink = styled.div`
  font-size: 16px;
  color: ${theme.colors.main};
  font-weight: 400;
  cursor: pointer;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 12px;
  border-radius: 4px;
  margin-left: 15px;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
  @media (max-width: 800px) {
    margin-left: 0px;
    margin-right: 15px;
    margin-top: 5px;
    padding: 8px;
    font-size: 14px;
  }
`;

const CardTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const StyledCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 25px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  @media (max-width: 800px) {
    ${(p) => p.hideMobile && "display: none;"}
  }
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export class CardList extends Component {
  state = { expanded: false };
  render() {
    const { title, listView, children } = this.props;
    return (
      <StyledCard>
        <CardRow>
          <CardTitle>{title}</CardTitle>
          <Row>
            <CardLink
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
              {this.state.expanded ? "VIEW LESS" : "VIEW 8 MORE +"}
            </CardLink>
            <CardLink onClick={listView}>VIEW ALL</CardLink>
          </Row>
        </CardRow>
        <ProductsRow>
          {children.slice(0, this.state.expanded ? 12 : 4)}
        </ProductsRow>
      </StyledCard>
    );
  }
}

const ProductsRow = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${(p) =>
    p.productCount &&
    p.productCount < 4 &&
    "grid-template-columns: 300px 300px 300px;"}
`;

const PrebuiltCardBackground = styled.div`
  height: 280px;
  background-image: url(${(p) => p.background});
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 4px;
  cursor: pointer;
`;

export class PrebuiltCard extends Component {
  render() {
    return (
      <PrebuiltCardBackground
        background={this.props.background}
        onClick={this.props.onClick}
      />
    );
  }
}
