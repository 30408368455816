import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import xIcon from "../images/denied-icon-white.png";
import { Topbar } from "../components";
import { Sidebar, SidebarMobile } from "./components/sidebar";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setError } from "../Actions";

const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
`;

const RightSideContainer = styled.div`
  flex: 1;
`;

const ErrorBanner = styled.div`
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background: ${theme.colors.darkRed};
  color: ${theme.colors.white};
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: white;
  font-weight: 500;
`;

const PageContainer = styled.div`
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
  }
`;

const PageWrapper = styled.div`
  background: ${theme.colors.lightGrey};
  padding: 20px;
  min-height: 100%;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

class Wrapper extends Component {
  constructor(props) {
    super(props);

    this.bannersContainerRef = React.createRef(null);
    this.mainContainerRef = React.createRef(null);
  }

  hideError = () => {
    this.props.setError("");
  };

  setMainContainerHeight = () => {
    if (!this.bannersContainerRef.current || !this.mainContainerRef.current)
      return;
    const bannersHeight = this.bannersContainerRef.current.offsetHeight;
    this.mainContainerRef.current.style.height = `calc(100% - ${bannersHeight}px)`;
  };

  componentDidMount() {
    this.setMainContainerHeight();
  }

  componentDidUpdate() {
    this.setMainContainerHeight();
  }

  render() {
    const { error, children } = this.props;
    const currentPlatform = Cookies.get("currentPlatform");
    const businessName =
      currentPlatform === "undefined" ? "Welcome" : currentPlatform;

    return (
      <Container>
        <Sidebar />

        <RightSideContainer>
          <div ref={this.bannersContainerRef}>
            <SidebarMobile />

            <Topbar isStore={true} name={businessName} showSettings={false} />

            {error && error !== "upgrade" && (
              <ErrorBanner>
                <ErrorText>{error}</ErrorText>
                <img
                  alt="icon"
                  src={xIcon}
                  style={{ height: "18px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.hideError();
                  }}
                />
              </ErrorBanner>
            )}
          </div>

          <PageContainer ref={this.mainContainerRef}>
            <PageWrapper>{children}</PageWrapper>
          </PageContainer>
        </RightSideContainer>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setError }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(Wrapper));
