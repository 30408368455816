import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { withRouter } from "react-router-dom";
import { getSettings } from "../Selectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

export const TitlebarText = styled.div`
  font-size: 25px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 5px;
  }
`;

export const SubTitlebarText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  margin-top: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledTitlebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  /* border-bottom: 1px solid ${theme.colors.lightBorder}; */
`;

export const TitlebarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  @media (max-width: 800px) {
    margin-left: 10px;
  }
`;

class Titlebar extends Component {
  render() {
    const { title, subtitle } = this.props;

    return (
      <StyledTitlebar>
        <TitlebarContainer>
          <TitlebarText>{title}</TitlebarText>
          {subtitle && <SubTitlebarText>{subtitle}</SubTitlebarText>}
        </TitlebarContainer>
      </StyledTitlebar>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Titlebar));
