import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import {
  ButtonNew,
  TabNavigation,
  Row,
  Column,
  Spinner,
} from "../../../components";
import Text from "../../../modules/Text";
import { inUrl } from "../../../modules/Format";
import PublicVendors from "./PublicVendors";
import PrivateVendors from "./PrivateVendors";
import InviteVendor from "../components/InviteVendor";
import { FiPlus } from "react-icons/fi";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSettings } from "../../../views/supplier/SupplierSelectors";
import { get, isEmpty } from "lodash";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

class Imported extends React.Component {
  renderNavigation = () => {
    const isFetching = isEmpty(this.props.settings);
    const hidePrivateFeature = true;
    const hasVendorFeature = get(this.props, [
      "settings",
      "has_vendor_feature",
    ]);

    const isPrivate = inUrl("private");
    const isPublic = !inUrl("private");

    const tabOptions = [
      {
        name: "Private Vendors",
        description: "Vendors in your private dropshipping program.",
        active: isPrivate,
        onClick: () => this.props.history.push("/supplier/vendors/private"),
      },
      {
        name: "Public Vendors",
        description: "Public vendors selling your products.",
        active: isPublic,
        onClick: () => this.props.history.push("/supplier/vendors/public"),
      },
    ];

    if (isFetching) {
      return <Spinner />;
    }

    if (hasVendorFeature) {
      return (
        <TabNavigation
          options={tabOptions}
          button={
            isPrivate ? (
              hidePrivateFeature ? (
                <div></div>
              ) : (
                <InviteVendor />
              )
            ) : (
              <ButtonNew
                text="Refer Vendors"
                icon={<FiPlus size="18" color="white" />}
                size="large"
                onClick={() =>
                  this.props.history.push("/supplier/settings/referrals")
                }
              />
            )
          }
        />
      );
    }

    return (
      <Row
        style={{
          justifyContent: "space-between",
          margin: "25px 25px 0 25px",
        }}
      >
        <Column>
          <Text.Large>Private Vendors</Text.Large>
          <Text light margin="10px 0 0 0">
            Vendors you've invited to your private dropshipping program
          </Text>
        </Column>
        {!hidePrivateFeature && <InviteVendor />}
      </Row>
    );
  };

  render() {
    return (
      <>
        {this.renderNavigation()}

        <Switch>
          <Route
            exact
            path="/supplier/vendors/public"
            component={PublicVendors}
          />
          <Route
            exact
            path="/supplier/vendors/private"
            component={PrivateVendors}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Imported)
);
