import React, { Component } from "react";
import styled from "styled-components";

export const StyledSideBarItem = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props) => (props.active ? "1.0" : "0.7")};
  width: 100px;
`;

const SidebarItemText = styled.div`
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  color: white;
  margin-top: 5px;
`;

class SidebarItemMobile extends Component {
  render() {
    return (
      <StyledSideBarItem
        onClick={this.props.onClick}
        active={this.props.active}
      >
        {this.props.icon}
        <SidebarItemText>{this.props.text}</SidebarItemText>
      </StyledSideBarItem>
    );
  }
}

export default SidebarItemMobile;
