import React, { Component } from "react";
import Text from "../../../modules/Text";

class CurrencyToggle extends Component {
  render() {
    const { convertPrices, supplierCurrency, storeCurrency, toggle } =
      this.props;
    return (
      <React.Fragment>
        {convertPrices ? (
          <div>
            <Text.Small
              extra="font-size: 14px; margin-top: 15px; cursor: pointer; color: #49beaf;"
              light
              onClick={toggle}
            >
              {`View Actual Prices In ${supplierCurrency}`}
            </Text.Small>
            <Text.Small
              extra="font-size: 14px; padding: 10px; width: auto; max-width: 350px; margin-top: 15px; margin-bottom:15px; border-radius: 4px; cursor: pointer; background: #c61111; color: white;"
              light
            >
              Warning: These prices are <strong>estimated</strong> based on the
              current conversion rate. The actual price you pay the supplier
              will always be in their currency.
            </Text.Small>
          </div>
        ) : (
          <Text.Small
            extra="font-size: 14px; margin-top: 15px; cursor: pointer; color: #49beaf;"
            light
            onClick={toggle}
          >
            {`View Estimated Prices In ${storeCurrency}`}
          </Text.Small>
        )}
      </React.Fragment>
    );
  }
}

export default CurrencyToggle;
