import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { withRouter } from "react-router-dom";
import Button from "./Button";

export const DeleteWrapper = styled.div`
  padding: 20px;
  width: 100%;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  @media (max-width: 800px) {
    padding: 5px;
    top: 16px;
  }
`;

export const DeleteTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.darkGrey};
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const DeleteDescription = styled.div`
  font-size: 14px;
  color: ${theme.colors.darkGrey};
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const DeleteMessage = styled.div`
  font-size: 16px;
  color: ${theme.colors.medGrey};
  font-weight: 300;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const DeleteButtonRow = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const OptionButton = styled(Button)`
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  @media screen and (max-width: 800px) {
    justify-content: center;
    font-size: 20px;
    margin: 10px auto;
    padding: 8px;
  }
`;

class DeleteItem extends Component {
  render() {
    const {
      title,
      description,
      buttonText,
      buttonLink,
      buttonTwoText,
      buttonTwoLink,
    } = this.props;
    return (
      <DeleteWrapper>
        <DeleteButtonRow>
          {buttonText && (
            <OptionButton text={buttonText} type="main" onClick={buttonLink} />
          )}
          {buttonTwoText && (
            <OptionButton
              text={buttonTwoText}
              type="main"
              onClick={buttonTwoLink}
            />
          )}
        </DeleteButtonRow>
        <DeleteTitle>{title}</DeleteTitle>
        <DeleteDescription>{description}</DeleteDescription>
      </DeleteWrapper>
    );
  }
}

export default withRouter(DeleteItem);
