import React from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import Text from "../../../modules/Text";
import { ButtonNew, Row } from "../../../components";
import { FiClock, FiDollarSign, FiGrid } from "react-icons/fi";
import SellingPoint from "../../../views/prebuilt/components/SellingPoint";
import dcLogo from "../../../images/dropcommerce-logo-2021-small.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const MainImage = styled.img`
  width: 50%;
  height: 450px;
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
    margin: 0 10px 0 10px;
  }
`;

const MainText = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
`;

const BoldHeroText = styled.span`
  font-weight: 500;
`;

const HeroText = styled.div`
  font-size: 45px;
  width: 450px;
  font-weight: 300;
  @media (max-width: 800px) {
    font-size: 35px;
    width: 100%;
  }
`;

const WideRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const DCLogo = styled.img`
  height: 25px;
`;

const CoverSection = (props) => (
  <Container>
    <Row flexDirection="column">
      <MainText>
        <HeroText>
          Professional <BoldHeroText>dropshipping stores</BoldHeroText> for sale
        </HeroText>
        <ButtonNew
          size="large"
          text="Buy Now - $249.00"
          width="200px"
          margin="20px 0 0 0"
          onClick={props.onClick}
        />
        <Row style={{ margin: "20px 0 0 0" }}>
          <Text.Small light margin="0 5px 0 0">
            Powered by
          </Text.Small>
          <DCLogo src={dcLogo} />
        </Row>
      </MainText>
      <MainImage src="https://dc-imports.s3.ca-central-1.amazonaws.com/graphics/wireframe.svg" />
    </Row>

    <WideRow style={{ margin: "80px 0 40px 0" }}>
      <SellingPoint
        icon={<FiGrid size="40" color={theme.colors.main} />}
        title="Fast-Shipping Products"
        description="Your store will be loaded with 100+ fast-shipping US-based products in your niche and ready to sell"
      />
      <SellingPoint
        icon={<FiClock size="40" color={theme.colors.main} />}
        title="Save Time & Money"
        description="Save thousands of dollars and countless hours on store setup, and get started selling right away"
      />
      <SellingPoint
        icon={<FiDollarSign size="40" color={theme.colors.main} />}
        title="3 Months Free*"
        description="Each pre-built store comes with 3 months of free DropCommerce Pro subscription ($267 value)"
      />
    </WideRow>
  </Container>
);

export default CoverSection;
