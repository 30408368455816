import Cookies from "js-cookie";
import { api } from "./modules/Config";
import { history } from "./BaseApp";
import {} from "./baseStore";
import { get } from "lodash";
import {
  getDefaultStoreRoute,
  getUrlParameter,
  getUrlSubdomain,
} from "./modules/Format";
import createNotification, {
  errorNotification,
  successNotification,
} from "./modules/Notification";
import { getRequest as newGetRequest, signUpRequest } from "./modules/API";
import { trackEvent } from "./modules/EventTracking";
import randomColor from "randomcolor";
import { checkStatus } from "./utils";
import { axiosInstance } from "./services/api";

const type = "base";

export const FETCH_HAS_UNREAD_MESSAGE = `${type}/FETCH_HAS_UNREAD_MESSAGE`;
export const FETCH_CHANGE_REQUESTS_RES = `${type}/FETCH_CHANGE_REQUESTS_RES`;
export const LOGIN_RES = `${type}/LOGIN_RES`;
export const LOGIN_ERR = `${type}/LOGIN_ERR`;
export const SET_ERR = `${type}/SET_ERR`;
export const FETCH_ORDER_REQ = `${type}/FETCH_ORDER_REQ`;
export const FETCH_ORDER_RES = `${type}/FETCH_ORDER_RES`;
export const FETCH_ORDERS_REQ = `${type}/FETCH_ORDERS_REQ`;
export const FETCH_ORDERS_RES = `${type}/FETCH_ORDERS_RES`;
export const FETCH_ORDERS_ERR = `${type}/FETCH_ORDERS_ERR`;
export const REGISTER_RES = `${type}/REGISTER_RES`;
export const REGISTER_ERR = `${type}/REGISTER_ERR`;
export const FETCH_SETTINGS_RES = `${type}/FETCH_SETTINGS_RES`;
export const FETCH_SETTINGS_ERR = `${type}/FETCH_SETTINGS_ERR`;
export const RESET_SETTINGS = `${type}/RESET_SETTINGS`;
export const SHOPIFY_AUTH_RES = `${type}/SHOPIFY_AUTH_RES`;
export const SHOPIFY_AUTH_ERR = `${type}/SHOPIFY_AUTH_ERR`;
export const UPDATE_SETTINGS_REQ = `${type}/UPDATE_SETTINGS_REQ`;
export const UPDATE_SETTINGS_RES = `${type}/UPDATE_SETTINGS_RES`;
export const UPDATE_SETTINGS_ERR = `${type}/UPDATE_SETTINGS_ERR`;
export const UPDATE_SUPPLIER_SETTINGS_RES = `${type}/UPDATE_SUPPLIER_SETTINGS_RES`;
export const CREATE_SHOPIFY_ORDER_RES = `${type}/CREATE_SHOPIFY_ORDER_RES`;
export const CREATE_SHOPIFY_ORDER_ERR = `${type}/CREATE_SHOPIFY_ORDER_ERR`;
export const FULFILL_ORDER_REQ = `${type}/FULFILL_ORDER_REQ`;
export const FULFILL_ORDER_RES = `${type}/FULFILL_ORDER_RES`;
export const FULFILL_ORDER_ERR = `${type}/FULFILL_ORDER_ERR`;
export const GENERIC_REQ = `${type}/GENERIC_REQ`;
export const GENERIC_RES = `${type}/GENERIC_RES`;
export const GENERIC_ERR = `${type}/GENERIC_ERR`;
export const STRIPE_SUPPLIER_AUTH_REQ = `${type}/STRIPE_SUPPLIER_AUTH_REQ`;
export const STRIPE_SUPPLIER_AUTH_RES = `${type}/STRIPE_SUPPLIER_AUTH_RES`;
export const SET_SETTINGS_SAVED_RES = `${type}/SET_SETTINGS_SAVED_RES`;
export const SAVE_STRIPE_CARD_REQ = `${type}/SAVE_STRIPE_CARD_REQ`;
export const SAVE_STRIPE_CARD_RES = `${type}/SAVE_STRIPE_CARD_RES`;
export const REMOVE_STRIPE_CARD_RES = `${type}/REMOVE_STRIPE_CARD_RES`;
export const SET_LAST_PAGINATION_STATE = `${type}/SET_LAST_PAGINATION_STATE`;
export const SET_LAST_SEARCH = `${type}/SET_LAST_SEARCH`;
export const SET_PLAN_RES = `${type}/SET_PLAN_RES`;
export const PLAN_SAVED_RES = `${type}/PLAN_SAVED_RES`;
export const PLAN_SAVED_ERR = `${type}/PLAN_SAVED_ERR`;
export const HIDE_INTRO_NOTICE = `${type}/HIDE_INTRO_NOTICE`;
export const PASSWORD_RESET_RES = `${type}/PASSWORD_RESET_RES`;
export const UPDATE_SETTINGS_FROM_LS = `${type}/UPDATE_SETTINGS_FROM_LS`;
export const UPDATE_SUPPLIERS_FROM_LS = `${type}/UPDATE_SUPPLIERS_FROM_LS`;
export const APPROVE_CHANGE_REQUEST_RES = `${type}/APPROVE_CHANGE_REQUEST_RES`;
export const UPDATE_VENDOR_APPROVAL_RES = `${type}/UPDATE_VENDOR_APPROVAL_RES`;
export const UPDATE_AUTO_FULFILLMENT_RES = `${type}/UPDATE_AUTO_FULFILLMENT_RES`;
export const CANCEL_ORDER_REQ = `${type}/CANCEL_ORDER_REQ`;
export const CANCEL_ORDER_RES = `${type}/CANCEL_ORDER_RES`;
export const CANCEL_ORDER_ERR = `${type}/CANCEL_ORDER_ERR`;
export const SUBMIT_REVIEW_REQ = `${type}/SUBMIT_REVIEW_REQ`;
export const FETCH_TOP_CATEGORIES_REQ = `${type}/FETCH_TOP_CATEGORIES_REQ`;
export const FETCH_TOP_CATEGORIES_RES = `${type}/FETCH_TOP_CATEGORIES_RES`;
export const FETCH_TOP_CATEGORIES_ERR = `${type}/FETCH_TOP_CATEGORIES_ERR`;
export const FETCH_STORE_APPROVAL_DATA_REQ = `${type}/FETCH_STORE_APPROVAL_DATA_REQ`;
export const FETCH_STORE_APPROVAL_DATA_RES = `${type}/FETCH_STORE_APPROVAL_DATA_RES`;
export const FETCH_STORE_APPROVAL_DATA_ERR = `${type}/FETCH_STORE_APPROVAL_DATA_ERR`;
export const SAVE_STORE_ADDRESS_REQ = `${type}/SAVE_STORE_ADDRESS_REQ`;
export const SAVE_STORE_ADDRESS_RES = `${type}/SAVE_STORE_ADDRESS_RES`;
export const SAVE_STORE_ADDRESS_ERR = `${type}/SAVE_STORE_ADDRESS_ERR`;
export const FEATURED_SUPPLIERS_REQ = `${type}/FEATURED_SUPPLIERS_REQ`;
export const FEATURED_SUPPLIERS_RES = `${type}/FEATURED_SUPPLIERS_RES`;
export const FEATURED_SUPPLIERS_ERR = `${type}/FEATURED_SUPPLIERS_ERR`;
export const UPDATE_STATE = `${type}/UPDATE_STATE`;
export const UPDATE_STORE = `${type}/UPDATE_STORE`;
export const RESET_PRODUCTS = `products/RESET_PRODUCTS`;
export const BUY_PREMADE_STORE_REQ = `${type}/BUY_PREMADE_STORE_REQ`;
export const BUY_PREMADE_STORE_RES = `${type}/BUY_PREMADE_STORE_RES`;
export const BUY_PREMADE_STORE_ERR = `${type}/BUY_PREMADE_STORE_ERR`;
export const ADD_MESSAGE_TO_CONVERSATION_REQ = `${type}/ADD_MESSAGE_TO_CONVERSATION_REQ`;
export const ADD_MESSAGE_TO_CONVERSATION_RES = `${type}/ADD_MESSAGE_TO_CONVERSATION_RES`;
export const ADD_MESSAGE_TO_CONVERSATION_ERR = `${type}/ADD_MESSAGE_TO_CONVERSATION_ERR`;
export const FETCH_CONVERSATIONS_RES = `${type}/FETCH_CONVERSATIONS_RES`;
export const FETCH_ARCHIVED_CONVERSATIONS_REQ = `${type}/FETCH_ARCHIVED_CONVERSATIONS_REQ`;
export const FETCH_ARCHIVED_CONVERSATIONS_RES = `${type}/FETCH_ARCHIVED_CONVERSATIONS_RES`;
export const FETCH_ARCHIVED_CONVERSATIONS_ERROR = `${type}/FETCH_ARCHIVED_CONVERSATIONS_ERROR`;
export const CREATE_CONVERSATION_RES = `${type}/CREATE_CONVERSATION_RES`;
export const FETCH_RECIPIENTS_REQ = `${type}/FETCH_RECIPIENTS_REQ`;
export const FETCH_RECIPIENTS_RES = `${type}/FETCH_RECIPIENTS_RES`;
export const FETCH_RECIPIENTS_ERR = `${type}/FETCH_RECIPIENTS_ERR`;
export const DISMISS_OFFER = `${type}/DISMISS_OFFER`;
export const DISMISS_OFFER_ERR = `${type}/DISMISS_OFFER_ERR`;
export const ANSWER_REFUND_RES = `${type}/ANSWER_REFUND_RES`;
export const ANSWER_REFUND_REQ = `${type}/ANSWER_REFUND_REQ`;
export const ANSWER_REFUND_ERR = `${type}/ANSWER_REFUND_ERR`;

export const ADD_CONFIRMATION_MODAL = `${type}/ADD_CONFIRMATION_MODAL`;
export const REMOVE_CONFIRMATION_MODAL = `${type}/REMOVE_CONFIRMATION_MODAL`;

export const ARCHIVE_CONVERSATION_RES = `${type}/ARCHIVE_CONVERSATION_RES`;
export const UNARCHIVE_CONVERSATION_RES = `${type}/UNARCHIVE_CONVERSATION_RES`;

export const wixAuth = (code, instanceId, history) => (dispatch) => {
  fetch(`${api}/wix/auth/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
      instance_id: instanceId,
      email: Cookies.get("wixID"),
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      }

      if (response.token) {
        Cookies.set("accessToken", response.token, { expires: 10 });

        if (response.new_account) {
          window.fbq("track", "CompleteRegistration");
          trackEvent("Registration", response.email, response.username);
          history.push("/setup");
        } else {
          history.push("/dashboard");
          trackEvent("Wix Store Login");
        }
      } else {
        dispatch({
          type: "base/WIX_AUTH_ERROR",
          payload: { error: response.error, errorType: response.error_type },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "base/WIX_AUTH_ERROR",
        payload: { error: error.error, errorType: "unknown" },
      });
    });
};

export const shoplazzaAuth = (code, hmac, state, shop) => (dispatch) => {
  fetch(`${api}/shoplazza/auth/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, hmac, state, shop }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      }

      if (response.token) {
        Cookies.set("accessToken", response.token, { expires: 10 });

        if (response.new_account) {
          window.fbq("track", "CompleteRegistration");
          trackEvent("Registration", response.email, response.username);
          history.push("/setup");
        } else {
          history.push("/dashboard");
          trackEvent("Shoplazza Install");
        }
      } else {
        dispatch({
          type: "base/WIX_AUTH_ERROR",
          payload: { error: response.error, errorType: response.error_type },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "base/WIX_AUTH_ERROR",
        payload: { error: error.error, errorType: "unknown" },
      });
    });
};

export const clearFilters = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "base/CLEAR_FILTERS",
    });
    resolve();
  });

export const createStoreReview = (data) => (dispatch) => {
  const storeId = Cookies.get("storeId");

  fetch(`${api}/store_app_review/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
  });
};

export const fetchHasUnreadMessage = () => (dispatch) =>
  new Promise((resolve) => {
    newGetRequest({ url: "has-unread-messages/" }).then((res) => {
      resolve(res);
      dispatch({
        type: FETCH_HAS_UNREAD_MESSAGE,
        payload: res.data.hasUnreadMessage,
      });
    });
  });

export const createReferral = (data) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/referrals/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: "base/CREATE_REFERRAL_RES",
          payload: response.referrals,
        });
        resolve(response);
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: error.error },
        });
      });
  });

export const fetchAccountType = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/account_type/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (!response.account_type) {
          Cookies.remove("accessToken");
        }

        resolve(response);

        dispatch({
          type: "base/GET_ACCOUNT_TYPE_RES",
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error fetching account types" },
        });
      });
  });

export const initializeFilters = (currentFilters, location) => (dispatch) =>
  new Promise((resolve) => {
    const loc = location;

    let page = getUrlParameter("page", loc) || 1;
    let category = getUrlParameter("category", loc) || "";
    let subcategory = getUrlParameter("subcategory", loc) || "";
    let search = getUrlParameter("search", loc) || "";
    let filters = getUrlParameter("filters", loc) || "";
    let price_min = getUrlParameter("price_min", loc) || "";
    let price_max = getUrlParameter("price_max", loc) || "";
    let price_type = getUrlParameter("price_type", loc) || "cost";
    let profit_min = getUrlParameter("profit_min", loc) || "";
    let shipping_max = getUrlParameter("shipping_max", loc) || "";
    let shipping_country = getUrlParameter("shipping_country", loc) || "US";

    const filtersSplit = filters.split(",");

    const initialFilters = {
      ...currentFilters,
      page,
      category,
      subcategory,
      search,
      price: {
        ...currentFilters.price,
        min: price_min,
        max: price_max,
        optionsValue: price_type,
      },
      profit: {
        ...currentFilters.profit,
        min: profit_min,
      },
      shipping: {
        ...currentFilters.shipping,
        max: shipping_max,
        optionsValue: shipping_country,
      },
      moreFilters: {
        ...currentFilters.moreFilters,
        filters: currentFilters.moreFilters.filters.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return {
                ...option,
                selected: filtersSplit.includes(option.value),
              };
            }),
          };
        }),
      },
    };

    dispatch({
      type: "base/INITIALIZE_FILTERS",
      payload: initialFilters,
    });

    resolve(initialFilters);
  });

export const resetRange = (type) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "base/RESET_RANGE",
      payload: { type },
    });
    resolve();
  });

export const showHideFilter =
  (type, bool = null) =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "base/SHOW_HIDE_FILTER",
        payload: { type, bool },
      });
      resolve();
    });

export const resetMoreFilters = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "base/RESET_MORE_FILTERS",
    });
    resolve();
  });

export const updateMoreFilters =
  (filterLabel, value, maxOneSelected = false) =>
  (dispatch) => {
    dispatch({
      type: "base/UPDATE_MORE_FILTERS",
      payload: { filterLabel, value, maxOneSelected },
    });
  };

export const updateFilters =
  (type, value, field = null) =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: "base/UPDATE_FILTERS",
        payload: { type, value, field },
      });
      resolve();
    });

export const updateStateDirectly =
  (data, reducer = "base") =>
  (dispatch) => {
    dispatch({
      type: `${reducer}/UPDATE_STATE`,
      payload: data,
    });
  };

export const fetchRecipients = () => (dispatch) => {
  dispatch({
    type: FETCH_RECIPIENTS_REQ,
  });
  const storeId = Cookies.get("storeId");
  const queryString = storeId ? `?store=${storeId}` : "";
  fetch(`${api}/api/recipients/${queryString}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_RECIPIENTS_RES,
        payload: response.recipients,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_RECIPIENTS_ERR,
        payload: { error: "Error fetching recipients" },
      });
    });
};

export const fetchConversations = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(`${api}/chat/api/conversations/?archived=false${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((conversations) => {
        resolve(conversations);
        dispatch({
          type: FETCH_CONVERSATIONS_RES,
          payload: { conversations },
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error fetching conversations" },
        });
      });
  });

export const fetchArchivedConversations = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: FETCH_ARCHIVED_CONVERSATIONS_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(`${api}/chat/api/conversations/?archived=true${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((conversations) => {
        dispatch({
          type: FETCH_ARCHIVED_CONVERSATIONS_RES,
          payload: { conversations },
        });
        resolve();
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ARCHIVED_CONVERSATIONS_ERROR,
          payload: {},
        });
        errorNotification("Error fetching archived conversations");
        reject();
      });
  });

export const createNewConversation = (recipientId) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/chat/api/conversations/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        receiver_id: recipientId,
        color: randomColor({ luminosity: "dark" }),
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        resolve(data);
        dispatch({
          type: CREATE_CONVERSATION_RES,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error creating new conversation" },
        });
      });
  });

export const getOrCreateChat = (supplierId) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    if (!supplierId) {
      return;
    }
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(
      `${api}/chat/api/conversations/getOrCreateChat/?to_supplier=${supplierId}${queryString}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        resolve(data);
        dispatch({
          type: CREATE_CONVERSATION_RES,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error creating new conversation" },
        });
      });
  });

export const dismissOffer = (offerId) => (dispatch) => {
  dispatch({
    type: DISMISS_OFFER,
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/dismiss_offer/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      offer_id: offerId,
      ...(storeId ? { store: storeId } : {}),
    }),
  })
    .then((response) => checkStatus(response))
    .catch(() => {
      dispatch({
        type: DISMISS_OFFER_ERR,
      });
    });
};

export const addMessageToConversation = (data) => (dispatch) => {
  const tempId = Math.floor(Math.random() * (100000000 - 1 + 1) + 1);

  dispatch({
    type: ADD_MESSAGE_TO_CONVERSATION_REQ,
    payload: {
      ...data,
      temp_id: tempId,
      sent_timestamp: new Date().toUTCString(),
    },
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/messages/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((response) => {
      dispatch({
        type: ADD_MESSAGE_TO_CONVERSATION_RES,
        payload: {
          temp_id: tempId,
          label: data.label,
          products: response.products,
          supplier_orders: response.supplier_orders,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: ADD_MESSAGE_TO_CONVERSATION_ERR,
        payload: { temp_id: tempId },
      });
    });
};

export const acceptInboxAgreement = () => (dispatch) => {
  const storeId = Cookies.get("storeId");

  fetch(`${api}/inbox_agreement/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    ...(storeId ? { body: { store: storeId } } : {}),
  });
};

export const markConversationRead = (conversationLabel) => (dispatch) => {
  const storeId = Cookies.get("storeId");

  fetch(`${api}/mark-as-read/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      label: conversationLabel,
      ...(storeId ? { store: storeId } : {}),
    }),
  }).then(fetchHasUnreadMessage);
};

export const archiveConversation = (conversation) => (dispatch) =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(`chat/api/conversations/${conversation.id}/archive/`)
      .then((responseData) => {
        dispatch({
          type: ARCHIVE_CONVERSATION_RES,
          payload: { conversation },
        });
        successNotification("Conversation archived successfully");
      })
      .catch((error) => {
        errorNotification("Archive conversation failed");
      });
  });

export const unarchiveConversation = (conversation) => (dispatch) =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(`chat/api/conversations/${conversation.id}/unarchive/`)
      .then((responseData) => {
        dispatch({
          type: UNARCHIVE_CONVERSATION_RES,
          payload: { conversation },
        });
        successNotification("Conversation unarchived successfully");
      })
      .catch((error) => {
        errorNotification("Unarchive conversation failed");
      });
  });

export const buyPremadeStore =
  (email, type, name, url, extraPrebuiltMonths, existing, tokenData) =>
  (dispatch, getState) =>
    new Promise((resolve) => {
      const state = getState();
      dispatch({
        type: BUY_PREMADE_STORE_REQ,
      });
      const storeId = Cookies.get("storeId");

      fetch(`${api}/buy_premade/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          type,
          name,
          url,
          extra_months: extraPrebuiltMonths,
          existing,
          token_data: tokenData,
          ...(storeId ? { store: storeId } : {}),
        }),
      })
        .then((rawResponse) => checkStatus(rawResponse))
        .then((data) => {
          if (data.error) {
            dispatch({
              type: BUY_PREMADE_STORE_ERR,
              payload: { error: data.error },
            });
            return;
          }
          // TAPFILIATE
          window.tap(
            "conversion",
            data.conversion_id,
            data.conversion_amount,
            {
              customer_id: data.customer_id,
            },
            "prebuilt"
          );

          const googleConversionValue = get(state, [
            "base",
            "settings",
            "global",
            "months_to_plan_price",
          ]);
          const value = googleConversionValue[extraPrebuiltMonths];

          // GOOGLE
          if (window.gtag && process.env.NODE_ENV === "production") {
            window.gtag("event", "conversion", {
              send_to: "AW-674757656/pcuCCJfhlZgCEJj438EC",
              value: value,
            });
          }

          trackEvent("Buy Prebuilt Store");

          // FACEBOOK
          window.fbq("track", "StartTrial");
          window.fbq("track", "Purchase", { value, currency: "USD" });

          dispatch({
            type: BUY_PREMADE_STORE_RES,
            payload: { storeType: type },
          });
        })
        .catch((error) => {
          dispatch({
            type: BUY_PREMADE_STORE_ERR,
            payload: { error: error.error },
          });
          errorNotification(
            "Oops! Something went wrong. Please contact support@dropcommerce.com for support."
          );
        });
    });

export const selectAllTableRows = (bool) => (dispatch) => {
  dispatch({
    type: "base/SELECT_ALL_TABLE_ROWS",
    payload: bool,
  });
};

export const selectCartItem = (itemId) => (dispatch) => {
  dispatch({
    type: "base/SELECT_CART_ITEM",
    payload: { itemId },
  });
};

export const deleteCartItems = (cartItemIds) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "base/DELETE_CART_ITEMS_RES",
      payload: cartItemIds,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/cart_item/`, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart_item_ids: cartItemIds,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error deleting cart item" },
        });
      });
  });

export const createManualOrder = (cartItemIds) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/samples/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart_item_ids: cartItemIds,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: "base/CREATE_MANUAL_ORDER_RES",
          payload: cartItemIds,
        });
        resolve(response);
      })
      .catch((response) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: response.error },
        });
      });
  });

export const fetchCartItems = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `?store=${storeId}` : "";
    fetch(`${api}/samples/${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: "base/GET_CART_ITEMS_RES",
          payload: response.cart_items,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error fetching cart" },
        });
      });
  });

export const fetchStoreApprovalData = (supplierId) => (dispatch) => {
  dispatch({
    type: FETCH_STORE_APPROVAL_DATA_REQ,
  });
  const storeId = Cookies.get("storeId");
  const queryString = storeId ? `&store=${storeId}` : "";
  fetch(`${api}/store_approval_data/?supplier_id=${supplierId}${queryString}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_STORE_APPROVAL_DATA_RES,
        payload: {
          data: response.data,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_STORE_APPROVAL_DATA_ERR,
        payload: { error: "Error fetching store approval data" },
      });
    });
};

export const setError = (error) => (dispatch) => {
  dispatch({
    type: SET_ERR,
    payload: { error },
  });
};

export const setLastPaginationState = () => (dispatch) => {
  dispatch({
    type: SET_LAST_PAGINATION_STATE,
  });
};

export const getRequest = (data) => (dispatch) =>
  new Promise((resolve) => {
    trackEvent(data.mixpanelName);
    fetch(`${api}${data.endpoint}${data.queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      });
  });

export const postRequest = (data) => (dispatch) =>
  new Promise((resolve) => {
    fetch(`${api}${data.endpoint}`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      });
  });

export const fetchCategories = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `?store=${storeId}` : "";
    fetch(`${api}/categories/${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: "FETCH_CATEGORIES_RES",
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error fetching categories" },
        });
      });
  });

export const fetchFeaturedSuppliers = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: RESET_PRODUCTS,
    });
    dispatch({
      type: FEATURED_SUPPLIERS_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `?store=${storeId}` : "";
    fetch(`${api}/featured_suppliers/${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: FEATURED_SUPPLIERS_RES,
          payload: { suppliers: response.suppliers },
        });
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: FEATURED_SUPPLIERS_ERR,
          payload: { error: "Error fetching featured suppliers" },
        });
      });
  });

export const forgotPassword = (email) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `?store=${storeId}` : "";
    fetch(`${api}/forgot_password/${queryString}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: GENERIC_RES,
        });
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "" },
        });
      });
  });

export const setNewPassword = (password) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `?store=${storeId}` : "";
    fetch(`${api}/password_reset/${queryString}`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        dispatch({
          type: GENERIC_RES,
        });
        resolve();
      })
      .catch((error) => {
        errorNotification("Change password failed");
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "" },
        });
      });
  });

export const paySupplierViaPayPal = (supplier_id) => (dispatch) => {
  dispatch({
    type: FULFILL_ORDER_RES,
    payload: { supplier_id },
  });
};

export const confirmPlan =
  (planId, planType, store = 0) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: GENERIC_REQ,
      });
      const payload = { plan_id: planId, plan_type: planType };
      if (store !== 0) {
        payload.store = store;
      }
      const storeId = Cookies.get("storeId");

      fetch(`${api}/api/payment-plans/confirm/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
          ...(storeId ? { store: storeId } : {}),
        }),
      })
        .then((rawResponse) => checkStatus(rawResponse))
        .then((response) => {
          if (response.success) {
            if (response.payment_plan) {
              trackEvent("Confirmed Plan");

              // Setup Intercom
              const paymentPlan = get(response, ["payment_plan"]);
              window.Intercom("boot", {
                app: window.location.href.includes("global")
                  ? "GLOBAL"
                  : "DROPCOMMERCE",
                account_type: "Store",
                app_id: "ulrlc7kx",
                email: paymentPlan.email,
                plan: paymentPlan.plan,
                store_id: response.store_id,
              });

              // This is busted. Not sure what it is.
              // Google: Scaled to 3 months * .6 trial => paid conversion value
              // const googleConversionValue = {BASIC: 34.2,GROWTH: 88.2,PROFESSIONAL: 160.2};
              // const value = googleConversionValue[paymentPlan.plan];
              // window.gtag("event", "conversion", {
              //   send_to: "AW-674757656/j-TnCP3Q-pcCEJj438EC",
              //   value: value,
              // });
            }
            window.fbq("track", "StartTrial");
            resolve(response);
            dispatch({
              type: PLAN_SAVED_RES,
              payload: response.payment_plan,
            });
          } else {
            reject(response);
            dispatch({
              type: PLAN_SAVED_ERR,
              payload: { errorType: "billing_decline" },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GENERIC_ERR,
            payload: { error: "" },
          });
        });
    });

export const leaveReview = () => (dispatch) => {
  dispatch({ type: SUBMIT_REVIEW_REQ });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/review/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...(storeId ? { store: storeId } : {}) }),
  });
};

export const setPlan = (plan, type, couponCode) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GENERIC_REQ });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/set_plan/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        plan,
        type,
        couponCode,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("Set Plan");
        resolve(response);
        if (response.error_code === "NO_CARD") {
          createNotification({
            type: "danger",
            title: "Error Setting Plan",
            message: "Please add a credit card to start a trial",
          });
        } else if (response.error) {
          dispatch({
            type: GENERIC_ERR,
            payload: {
              error:
                "Error choosing plan. Please contact support@dropcommerce.com for support",
            },
          });
        } else if (response.plan_auth_url) {
          // SHOPIFY ONLY - REDIRECT TO SHOPIFY ADMIN
          window.location = response.plan_auth_url;
        } else {
          // BIGCOMMERCE - EVERYTHING IS GOOD, SET PLAN IN REDUX
          createNotification({
            type: "success",
            title: "Success",
            message: "Plan changed successfully",
          });

          dispatch({
            type: PLAN_SAVED_RES,
            payload: response.payment_plan,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error choosing plan" },
        });
      });
  });

export const createPlan = (plan, type, couponCode, store) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GENERIC_REQ });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/api/payment-plans/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        plan,
        type,
        couponCode,
        store,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        trackEvent("Create Plan");
        resolve(data);
        if (data.error) {
          if (data.error.code === "NO_CARD") {
            createNotification({
              type: "danger",
              title: "Error Setting Plan",
              message: "Please add a credit card to start a trial",
            });
          } else {
            dispatch({
              type: GENERIC_ERR,
              payload: {
                error:
                  "Error choosing plan. Please contact support@dropcommerce.com for support",
              },
            });
          }
        } else {
          // TAPFILIATE
          if (type === "ONE_TIME") {
            window.tap(
              "conversion",
              data.conversion_id,
              data.conversion_amount,
              {
                customer_id: store,
              },
              "yearly-plans"
            );
          } else {
            window.tap(
              "conversion",
              data.conversion_id,
              data.conversion_amount,
              {
                customer_id: store,
              }
            );
          }

          if (data.plan_auth_url) {
            // SHOPIFY ONLY - REDIRECT TO SHOPIFY ADMIN
            window.location = data.plan_auth_url;
          } else {
            // BIGCOMMERCE - EVERYTHING IS GOOD, SET PLAN IN REDUX
            createNotification({
              type: "success",
              title: "Success",
              message: "Plan changed successfully",
            });
            dispatch({
              type: PLAN_SAVED_RES,
              payload: data.payment_plan,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error choosing plan" },
        });
      });
  });

export const createSupplier = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GENERIC_REQ });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/supplier_registration/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (response.token) {
          Cookies.set("accessToken", response.token, { expires: 30 });
        }
        dispatch({
          type: GENERIC_RES,
        });
        resolve(response);
      })
      .catch((response) => {
        dispatch({
          type: GENERIC_RES,
        });
        resolve(response);
      });
  });

export const importShopifyProducts = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  const storeId = Cookies.get("storeId");
  const queryString = storeId ? `?store=${storeId}` : "";
  return fetch(`${api}/import_supplier_shopify_products/${queryString}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: GENERIC_RES,
      });
      return response;
    });
};

export const signUp = (data) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    signUpRequest({
      url: data.hybridRequest ? `create_store_for_supplier/` : `signup/`,
      data,
    })
      .then((response) => {
        dispatch({
          type: GENERIC_RES,
        });
        if (response.data.token) {
          window.tap("customer", response.store_id);

          // FACEBOOK TRACKING
          window.fbq("track", "CompleteRegistration");

          // GOOGLE TAG MANAGER TRACKING
          // window.gtag("event", "conversion", {
          //   send_to: "AW-674757656/G9qVCJve_8EBEJj438EC"
          // });
          trackEvent("Signup - Created Account");
        }
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "ERROR", errorType: "ERROR TYPE" },
        });
      });
  });

export const stripeSupplierAuth = (data) => (dispatch) => {
  dispatch({
    type: STRIPE_SUPPLIER_AUTH_REQ,
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/stripe_supplier_auth/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("Supplier Authorize Stripe");
      dispatch({ type: STRIPE_SUPPLIER_AUTH_RES });
    })
    .catch(() => {
      dispatch({
        type: UPDATE_SETTINGS_ERR,
        payload: { error: "Error updating settings" },
      });
    });
};

export const startStripeSession = (redirectUrl) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(`${api}/stripe_session/?redirect_url=${redirectUrl}${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        resolve(data);
        dispatch({
          type: GENERIC_RES,
        });
      })
      .catch((error) => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error saving card to Stripe" },
        });
      });
  });

export const removeStripeCard = (data) => (dispatch) => {
  dispatch({
    type: SAVE_STRIPE_CARD_REQ,
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/stripe_remove_card/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
  })
    .then((response) => checkStatus(response))
    .then(() => {
      dispatch({ type: REMOVE_STRIPE_CARD_RES });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error saving card to Stripe" },
      });
    });
};

export const stripeStoreAuth = (data) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SAVE_STRIPE_CARD_REQ,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/stripe_store_auth/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
        trackEvent("Store Authorize Stripe");
        dispatch({
          type: SAVE_STRIPE_CARD_RES,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error saving card to Stripe" },
        });
      });
  });

export const updateSettings = (settings) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_STORE,
      payload: settings,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/store_settings/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...settings,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      });
  });

export const fetchSubdomainData = (subdomain) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "base/FETCH_SUBDOMAIN_DATA_REQ",
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(`${api}/subdomain_data/?subdomain=${subdomain}${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: "base/FETCH_SUBDOMAIN_DATA_RES",
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: "base/FETCH_SUBDOMAIN_DATA_ERR",
        });
      });
  });

export const fetchSettings = (path) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: "base/FETCH_SETTINGS_REQ" });
    const subdomain = getUrlSubdomain();
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(
      `${api}/store_settings/?path=${path}&subdomain=${subdomain}${queryString}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        const store = data.settings.store;
        const localhost = window.location.host.includes("localhost");
        const platform = get(store, ["platform", "name"]);
        if (
          !localhost &&
          !data.settings.store_override &&
          !store.hide_prebuilt
        ) {
          window.Intercom("boot", {
            app: window.location.href.includes("global")
              ? "GLOBAL"
              : "DROPCOMMERCE",
            account_type: "Store",
            app_id: "ulrlc7kx",
            email: data.settings.email,
            platform: platform,
            subdomain: subdomain,
            purchased_prebuilt: store.purchased_stores.length > 0,
            hide_prebuilt: store.hide_prebuilt,
            name: store.owner_name,
            created_at: store.created,
            shopify_created_at: store.shopify_created_datetime,
            referral_source: store.referral_source,
            days_left_in_trial: store.days_left_in_trial,
            order_count: store.order_count,
            import_list_count: store.import_list_count,
            imported_to_shopify_count: store.imported_to_shopify_count,
            plan: get(store, ["payment_plan", "plan"]),
            plan_frequency: store.plan_frequency,
            store_url: get(store, ["platform", "uid"]),
            store_id: store.id,
          });
        }

        dispatch({
          type: "base/FETCH_SETTINGS_RES",
          payload: data.settings,
        });

        resolve();
      })
      .catch((error) => {
        dispatch({
          type: "base/FETCH_SETTINGS_ERR",
        });
      });
  });

export const fetchTopCategories = () => (dispatch) => {
  dispatch({
    type: FETCH_TOP_CATEGORIES_REQ,
  });
  const storeId = Cookies.get("storeId");
  const queryString = storeId ? `?store=${storeId}` : "";
  fetch(`${api}/top_categories/${queryString}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_TOP_CATEGORIES_RES,
        payload: {
          categories: response.categories,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_TOP_CATEGORIES_ERR,
        payload: { error: "Error retrieving categories" },
      });
    });
};

export const cancelOrder = (orderId) => (dispatch) => {
  dispatch({
    type: CANCEL_ORDER_REQ,
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/cancel_order/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      order_id: orderId,
      ...(storeId ? { store: storeId } : {}),
    }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: CANCEL_ORDER_RES,
      });
    })
    .catch(() => {
      dispatch({
        type: CANCEL_ORDER_ERR,
        payload: { error: "There was an error cancelling this order." },
      });
    });
};

export const fetchReferrals = () => (dispatch) => {
  dispatch({
    type: "base/GET_REFERRALS_REQ",
  });
  const storeId = Cookies.get("storeId");
  const queryString = storeId ? `?store=${storeId}` : "";
  fetch(`${api}/referrals/${queryString}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("Fetch Referrals");
      dispatch({
        type: "base/GET_REFERRALS_RES",
        payload: response.referrals,
      });
    })
    .catch(() => {
      dispatch({
        type: "base/GET_REFERRALS_ERR",
        payload: {
          error:
            "Error retrieving orders. Please contact support at support@dropcommerce.com",
        },
      });
    });
};

export const fetchOrders =
  (page = 1, search = "", status) =>
  (dispatch) => {
    dispatch({
      type: FETCH_ORDERS_REQ,
    });
    const storeId = Cookies.get("storeId");
    const queryString = storeId ? `&store=${storeId}` : "";
    fetch(
      `${api}/orders/?status=${status}&p=${page}&search=${search}${queryString}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("View Orders");
        dispatch({
          type: FETCH_ORDERS_RES,
          payload: {
            orders: response.orders,
            pagination: response.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: {
            error:
              "Error retrieving orders. Please contact support at support@dropcommerce.com",
          },
        });
      });
  };

export const setLastSearch = (searchTerm) => (dispatch) => {
  dispatch({
    type: SET_LAST_SEARCH,
    payload: { searchTerm },
  });
};

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      password: password,
    }),
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((response) => {
      const accountType = response.account_type;

      if (response.token) {
        switch (accountType) {
          case "admin":
            Cookies.set("adminAccessToken", response.token, { expires: 10 });
            history.push("/admin/suppliers");
            break;
          case "affiliate":
            Cookies.set("accessToken", response.token, { expires: 10 });
            history.push("/affiliate/dashboard");
            break;
          case "accounts":
            Cookies.set("accessToken", response.token, { expires: 10 });
            history.push("/accounts");
            break;
          case "supplier":
            Cookies.set("accessToken", response.token, { expires: 10 });
            history.push("/supplier/products");
            break;
          case "store":
            Cookies.set("accessToken", response.token, { expires: 10 });
            history.push(getDefaultStoreRoute);
            break;

          default:
            dispatch({
              type: LOGIN_ERR,
              payload: {
                error: "Account error: Contact support@dropcommerce.com",
              },
            });
            break;
        }

        dispatch({
          type: GENERIC_RES,
        });
      } else {
        createNotification({
          type: "danger",
          title: "Login Error",
          message: "Invalid username or password",
        });
        dispatch({
          type: LOGIN_ERR,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: LOGIN_ERR,
        payload: { error: "Error logging in" },
      });
    });
};

export const saveSetup = (categories) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: GENERIC_REQ,
    });
    const storeId = Cookies.get("storeId");

    fetch(`${api}/save_setup/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        categories,
        ...(storeId ? { store: storeId } : {}),
      }),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        successNotification("Categories has been saved successfully");
        resolve();
        dispatch({
          type: GENERIC_RES,
        });
      })
      .catch((error) => {
        errorNotification("Save categories action has failed");
        dispatch({
          type: GENERIC_ERR,
          payload: { error: error.error },
        });
      });
  });

export const setPlatformUID =
  (uid, otherPlatform = null, resource = null, redirectURL = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: GENERIC_REQ });
      let payload = { uid, other_platform: otherPlatform };
      if (resource) {
        payload[resource["type"]] = resource["id"];
      }
      const storeId = Cookies.get("storeId");

      fetch(`${api}/set_platform_uid/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
          ...(storeId ? { store: storeId } : {}),
        }),
      })
        .then((rawResponse) => checkStatus(rawResponse))
        .then((responseData) => {
          if (!responseData.error) {
            createNotification({
              type: "success",
              title: "Success",
              message: "Platform saved",
            });
          }
          if (otherPlatform) {
            dispatch({
              type: "base/UPDATE_STATE",
              payload: [
                { key: "settings" },
                { key: "store", fields: [["other_platform", otherPlatform]] },
              ],
            });
          }
          dispatch({
            type: GENERIC_RES,
          });
          if (redirectURL) {
            // Redirect to Shopify app installation
            window.location.href = redirectURL;
          } else if (responseData.redirect) {
            // Redirect to Shopify app listing
            window.location.href = responseData.redirect;
          } else {
            resolve();
          }
        })
        .catch((error) => {
          dispatch({
            type: GENERIC_RES,
          });
          createNotification({
            type: "danger",
            message: error.error || "Unexpected error",
          });
        });
    });

export const shopifyAuth = (params) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/shopify_auth/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((response) => {
      dispatch({
        type: GENERIC_RES,
      });

      if (response.token) {
        // SUCCESSFUL LOGIN, SAVE TOKEN TO COOKIES
        Cookies.set("accessToken", response.token, { expires: 10 });
        window.tap("customer", response.store_id);

        if (response.new_account) {
          // NEW REGISTRATION, CREATE MIXPANEL USER AND REDIRECT TO SETUP
          window.fbq("track", "CompleteRegistration");
          trackEvent("Registration", response.email, response.username);
          history.push("/setup");
        } else {
          if (response.type === "supplier") {
            // SUPPLIER LOGGING IN VIA SHOPIFY
            history.push("/supplier/products");
            trackEvent("Supplier Shopify Auth");
          } else {
            // STORE LOGGING IN VIA SHOPIFY
            history.push(getDefaultStoreRoute);
            trackEvent("Shopify Store Login");
          }
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: error.error },
      });
    });
};

export const supplierShopifyAuth = (params) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/supplier_shopify_auth/`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((response) => {
      dispatch({ type: GENERIC_RES });
      if (response.token) {
        // SUCCESSFUL LOGIN, SAVE TOKEN TO COOKIES
        Cookies.set("accessToken", response.token, { expires: 10 });

        if (response.new_account) {
          // NEW REGISTRATION, CREATE MIXPANEL USER AND REDIRECT TO SETUP
          trackEvent(
            "Supplier Registration",
            response.email,
            response.username
          );
          history.push("/supplier/onboarding/");
        } else {
          trackEvent("Supplier Shopify Auth");
          history.push("/supplier/products");
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: error.error },
      });
    });
};

export const sendSupportEmail = (data) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  const storeId = Cookies.get("storeId");

  fetch(`${api}/support_email/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
  })
    .then((response) => checkStatus(response))
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const bigCommerceAuth = (signedPayload, history) => (dispatch) => {
  fetch(`${api}/bigcommerce_auth/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ signed_payload: signedPayload }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      if (response.token) {
        Cookies.set("accessToken", response.token, { expires: 10 });
        if (response.new_account) {
          window.fbq("track", "CompleteRegistration");
          trackEvent("Registration", response.email, response.username);
          history.push("/setup");
        } else {
          history.push(getDefaultStoreRoute);
          trackEvent("BigCommerce Store Login");
        }
      } else {
        dispatch({
          type: SHOPIFY_AUTH_ERR,
          payload: { error: response.error, errorType: response.error_type },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SHOPIFY_AUTH_ERR,
        payload: { error: error.error, errorType: "unknown" },
      });
    });
};

export const updateRefundRequest = (refundId, data) => (dispatch) =>
  new Promise((resolve) => {
    const storeId = Cookies.get("storeId");

    fetch(`${api}/api/refunds/${refundId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, ...(storeId ? { store: storeId } : {}) }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (response.error) {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_ERR,
            payload: { error: response.error },
          });
        } else {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_RES,
            payload: response.refunds,
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: ANSWER_REFUND_ERR,
          payload: { error: response.error },
        });
      });
  });
