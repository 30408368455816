import React from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { ExpandableSection } from "../ExpandableSection";
import { Column } from "../Layout";
import { ModalNew } from "../ModalNew";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${theme.colors.white};
  padding: 20px;
  overflow-y: auto;
  max-height: 493.8px;

  @media (min-width: 900px) {
    gap: 0;
  }
`;

const InnerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  ${(props) => props.align && "align-items: center;"}
  ${(props) => props.gap && "gap: 20px;"}

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const Welcome = styled("h1")`
  color: ${theme.colors.medDarkGrey};
  font-weight: bold;
  font-size: 32px;
`;

const Paragraph = styled("p")`
  color: ${theme.colors.mainDark};
  letter-spacing: 1px;
`;

const HelloCenter = styled("a")`
  color: ${theme.colors.mainDark};
  text-decoration: underline;
`;

const Illustration = styled("img")`
  width: 180px;
  aspect-ratio: 1/1;
  background-color: ${theme.colors.lightGrey};
  border-radius: 104px;
`;

const RecommendedArticles = styled("h3")`
  color: ${theme.colors.main};
  font-weight: 500;
  font-size: 16px;

  @media (min-width: 900px) {
    font-size: 20px;
  }
`;

const ReadMoreLink = styled("a")`
  color: ${theme.colors.main};
  text-decoration: underline;
`;

export function WelcomeModal({ onClose }) {
  const articles = [
    {
      id: 1,
      title: "How does DropCommerce work?",
      description:
        "DropCommerce allows Shopify store owners to import high quality and affordable products...",
      link: "https://help.dropcommerce.com/en/articles/4357042-how-does-dropcommerce-work",
    },
    {
      id: 2,
      title: "Subscription Plans",
      description:
        "We offer 3 unique subscription plans to get you started on your path to success! All of our plans...",
      link: "https://help.dropcommerce.com/en/articles/4365316-subscription-plans",
    },
    {
      id: 3,
      title: "Choosing a Niche",
      description:
        "Deciding what to sell is a crucial step in building your business...",
      link: "https://help.dropcommerce.com/en/articles/4365506-choosing-a-niche",
    },
    {
      id: 4,
      title: "Prebuilt Stores FAQ",
      description: "Common Questions About Prebuilt Stores...",
      link: "https://help.dropcommerce.com/en/articles/4361377-prebuilt-stores-faq",
    },
  ];

  return (
    <ModalNew
      id="welcome-modal"
      onClose={onClose}
      extra={`border: 4px solid ${theme.colors.main};`}
    >
      <Container>
        <InnerContainer align gap>
          <Column gap="24px">
            <Welcome>Welcome to DropCommerce!</Welcome>

            <Paragraph>
              We are so excited to join you on your Dropshipping journey. Allow
              us to introduce you to our{" "}
              <HelloCenter
                href="https://help.dropcommerce.com/en/articles/4357042-how-does-dropcommerce-work"
                target="_blank"
              >
                Hello Center
              </HelloCenter>{" "}
              - we provide our users with the best resources and guides to help
              navigate the ever changing world of E-Commerce.
            </Paragraph>
          </Column>

          <Illustration
            alt="A woman holding a cup of coffee"
            src="https://res.cloudinary.com/dropcommerce/image/upload/v1679505916/zci3gcg5tqdg565aslcw.svg"
          />
        </InnerContainer>

        <InnerContainer style={{ flexDirection: "column" }}>
          <RecommendedArticles>
            Recommended articles for you
          </RecommendedArticles>

          <div>
            {articles.map((article) => (
              <ExpandableSection
                backgroundColor={theme.colors.lightGrey}
                title={article.title}
                key={article.id}
              >
                {article.description}{" "}
                <ReadMoreLink href={article.link} target="_blank">
                  read more
                </ReadMoreLink>
              </ExpandableSection>
            ))}
          </div>
        </InnerContainer>
      </Container>
    </ModalNew>
  );
}
