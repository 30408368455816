import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Container,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useForm } from "react-hook-form";

export function ChangeStatusPopover({ onSaveStatus }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { status: "" } });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = ({ status }) => {
    onSaveStatus(status);
  };

  return (
    <div>
      <Tooltip title="Change status">
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Container sx={{ py: 4 }}>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{ width: 250, "& > button": { mt: 2 } }}
            noValidate
          >
            <TextField
              label="Status"
              {...register("status", { required: "Status is required" })}
              select
              defaultValue=""
              fullWidth
              error={!!errors.status}
              helperText={errors.status && errors.status.message}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="shipped">Shipped</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="deprecated">Deprecated</MenuItem>
            </TextField>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        </Container>
      </Popover>
    </div>
  );
}
