import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sendSupportEmail } from "../Actions";
import { get } from "lodash";

export const SupportRequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const SupportRequestInput = styled.input`
  padding: 15px;
  background: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  ${(props) => props.error && "border: 1px solid #ff0000;"} width: 600px;
  font-size: 20px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const SupportRequestTextArea = styled.textarea`
  padding: 15px;
  background: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  ${(props) => props.error && "border: 1px solid #ff0000;"} width: 600px;
  height: 300px;
  font-size: 20px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    width: 100%;
    height: 150px;
  }
`;

export const ErrorDisplay = styled.div`
  color: #ff0000;
  font-size: 20px;
  margin-bottom: 10px;
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ sendSupportEmail }, dispatch),
});

const defaultProps = {};

class SupportForm extends Component {
  state = {
    email: "",
    subject: "",
    message: "",
    errors: [],
    sent: false,
  };

  sendSupportEmail = () => {
    const { email, message, subject } = this.state;
    let errors = [];

    if (email.length < 6 || !email.includes("@") || !email.includes(".")) {
      errors.email = "Please enter a valid email address";
    }
    if (subject.length < 3 || !email.includes("@")) {
      errors.subject = "Please enter a valid subject";
    }
    if (message.length < 20) {
      errors.message = "Please enter a longer message";
    }

    if (errors.email || errors.subject || errors.message) {
      this.setState({ errors });
    } else {
      this.setState({ errors: [], sent: true });
      this.props.sendSupportEmail({
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      });
    }
  };

  render() {
    return (
      <SupportRequestWrapper>
        <SupportRequestInput
          error={get(this.state.errors, ["email"])}
          placeholder="Email Address"
          onChange={(t) => this.setState({ email: t.target.value })}
        />
        <ErrorDisplay>{get(this.state.errors, ["email"])}</ErrorDisplay>
        <SupportRequestInput
          error={get(this.state.errors, ["subject"])}
          placeholder="Subject"
          onChange={(t) => this.setState({ subject: t.target.value })}
        />
        <ErrorDisplay>{get(this.state.errors, ["subject"])}</ErrorDisplay>
        <SupportRequestTextArea
          error={get(this.state.errors, ["message"])}
          onChange={(t) => this.setState({ message: t.target.value })}
        />
        <ErrorDisplay>{get(this.state.errors, ["message"])}</ErrorDisplay>
        {!this.state.sent ? (
          <ButtonNew
            text="Send Support Request"
            type="main"
            extra="width:300px;"
            fontStyle="font-size: 20px;"
            onClick={() => this.sendSupportEmail()}
          />
        ) : (
          <ButtonNew
            text="Request Sent!"
            type="main-light"
            disabled
            extra="width:300px;"
            fontStyle="font-size: 20px;"
          />
        )}
      </SupportRequestWrapper>
    );
  }
}

SupportForm.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupportForm)
);
