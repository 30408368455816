import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchSettings } from "./Actions";

import {
  getSettings,
  getIsFetching as getIsFetchingSettings,
} from "./Selectors";

// VIEWS
import { ManualOrder } from "./views";
import Inbox from "./views/inbox/Inbox";
import Setup from "./views/setup/Setup";
import PrebuiltWrapper from "./views/prebuilt/PrebuiltWrapper";
import Dashboard from "./views/dashboard/Dashboard";
import Products from "./views/products/Products";
import Product from "./views/products/Product";
import Supplier from "./views/products/premium/Supplier";
import Suppliers from "./views/products/premium/Suppliers";

// PRODUCTS
import { Imported, Notifications, UnsyncedProducts } from "./views/products";

// ORDERS
import { Orders, OrderDetails } from "./views/orders";

// OTHER
import StoreSettings from "./views/settings/StoreSettings";
import { INITIAL_CATEGORIES } from "./constants/index";
import { FullScreenSpinner } from "./components/FullScreenSpinner";

import Wrapper from "./components/Wrapper";
import { requestApproval } from "./views/products/ProductsActions";
import ApprovalRequirements from "./views/products/components/ApprovalRequirements";
import { Show } from "./components/Show";

class Internal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: INITIAL_CATEGORIES,
      approvalRequirementsSupplierId: null,
    };
  }

  componentDidMount = () => {
    const accountDetailsPage = this.props.match.path.includes(
      "/settings/account_details"
    );
    const plansPage = this.props.match.path.includes("/settings/plans");

    // IF not plans page, fetch settings here
    if (!plansPage && !accountDetailsPage) {
      this.props.fetchSettings(this.props.match.path).then(() => {
        this.setState((currentState) => ({
          ...currentState,
          categories: currentState.categories.map((cat) => {
            if (this.props.settings.store.categories.includes(cat.value)) {
              return { ...cat, selected: true };
            }
            return cat;
          }),
        }));
      });
    }
  };

  handleChange = (value) =>
    this.setState((currentState) => ({
      ...currentState,
      categories: currentState.categories.map((cat) => {
        if (value === cat.value) {
          return { ...cat, selected: !cat.selected };
        }
        return cat;
      }),
    }));

  hideApprovalRequirements = () => {
    this.setState({ approvalRequirementsSupplierId: null });
  };

  showApprovalRequirements = (supplierId) => {
    this.setState({ approvalRequirementsSupplierId: supplierId });
  };

  requestApproval = (supplierId) => {
    this.props
      .requestApproval(supplierId)
      .finally(this.hideApprovalRequirements);
  };

  render() {
    if (!this.props.settings.store) {
      return (
        <Wrapper>
          <FullScreenSpinner />
        </Wrapper>
      );
    }

    return (
      <>
        <Show when={this.state.approvalRequirementsSupplierId !== null}>
          <ApprovalRequirements
            supplierId={this.state.approvalRequirementsSupplierId}
            hideApprovalRequirements={this.hideApprovalRequirements}
            requestApproval={this.requestApproval}
          />
        </Show>

        <Switch>
          <Route
            path="/setup"
            render={(routeProps) => (
              <Setup
                {...routeProps}
                categories={this.state.categories}
                handleChange={this.handleChange}
              />
            )}
            exact
          />

          <Route
            path="/"
            render={(routeProps) => (
              <Wrapper>
                <Switch>
                  <Route
                    path="/dashboard"
                    render={(routeProps) => (
                      <Dashboard
                        {...routeProps}
                        showApprovalRequirements={this.showApprovalRequirements}
                      />
                    )}
                  />
                  <Route path="/unsynced" component={UnsyncedProducts} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/prebuilt" component={PrebuiltWrapper} />
                  <Route path="/inbox/:conversationLabel" component={Inbox} />
                  <Route path="/inbox" component={Inbox} />
                  <Route path="/suppliers" component={Suppliers} />
                  <Route
                    path="/supplier-id/:id"
                    render={(routeProps) => (
                      <Supplier
                        {...routeProps}
                        showApprovalRequirements={this.showApprovalRequirements}
                      />
                    )}
                  />
                  <Route
                    path="/products"
                    render={(routeProps) => (
                      <Products
                        {...routeProps}
                        showApprovalRequirements={this.showApprovalRequirements}
                      />
                    )}
                  />
                  <Route
                    path="/product/:productId"
                    render={(routeProps) => (
                      <Product
                        {...routeProps}
                        showApprovalRequirements={this.showApprovalRequirements}
                      />
                    )}
                  />
                  <Route path="/imported" component={Imported} />
                  <Route
                    path="/settings/:tab/:type"
                    component={StoreSettings}
                  />
                  <Route
                    path="/settings/:tab"
                    component={StoreSettings}
                    exact
                  />
                  <Route path="/settings" component={StoreSettings} exact />
                  <Route path="/order/new" component={ManualOrder} />
                  <Route
                    path="/order/:orderId"
                    component={OrderDetails}
                    exact
                  />
                  <Route path="/orders" component={Orders} exact />
                </Switch>
              </Wrapper>
            )}
          />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetchingSettings: getIsFetchingSettings(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSettings, requestApproval }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Internal);
