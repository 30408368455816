import React from "react";
import { Link } from "react-router-dom";

function StyledLink(props) {
  return React.createElement(
    props.as === "a" ? "a" : Link,
    {
      ...props,
      ...(props.as === "a" ? { href: props.to } : { to: props.to }),
      style: {
        textDecoration: "none",
        color: props.color || "#177892",
        backgroundColor: props.bgColor || "#ffffff",
        ...props.extra,
      },
    },
    props.children
  );
}

export default StyledLink;
