import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchPrebuiltStores,
  updatePrebuiltStore,
  runAdminCommand,
} from "../../../views/admin/AdminActions";
import {
  getIsFetching,
  getPrebuiltStores,
} from "../../../views/admin/AdminSelectors";
import PrebuiltStore from "../components/PrebuiltStore";
import { getUrlParameter } from "../../../modules/Format";
import { Row, Spinner } from "../../../components";
import Text from "../../../modules/Text";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  stores: getPrebuiltStores(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    { fetchPrebuiltStores, updatePrebuiltStore, runAdminCommand },
    dispatch
  ),
});

class PrebuiltStores extends Component {
  UNSAFE_componentWillMount = () => {
    const tab = getUrlParameter("tab", this.props.location) || "pending";
    this.props.fetchPrebuiltStores(tab);
  };

  updateTab = (tab) => {
    this.props.history.push(`/admin/prebuilt?tab=${tab}`);
    this.props.fetchPrebuiltStores(tab);
  };

  render() {
    const { stores } = this.props;
    const tab = getUrlParameter("tab", this.props.location) || "pending";

    return (
      <PageWrapper>
        <Row style={{ marginBottom: "15px" }}>
          <Text
            extra="margin-right: 20px"
            light={tab !== "pending"}
            link={tab !== "pending"}
            onClick={() => this.updateTab("pending")}
          >
            Pending
          </Text>
          <Text
            light={tab !== "completed"}
            link={tab !== "completed"}
            onClick={() => this.updateTab("completed")}
          >
            Completed
          </Text>
        </Row>
        {this.props.isFetching > 0 ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {stores.map((store, i) => (
              <PrebuiltStore
                key={i}
                store={store}
                loadProducts={this.props.runAdminCommand}
                updatePrebuiltStore={this.props.updatePrebuiltStore}
              />
            ))}
          </React.Fragment>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrebuiltStores)
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
