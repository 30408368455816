import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Stars from "./Stars";
import ButtonNew from "./ButtonNew";
import { Modal } from "./Modal";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createReview } from "../views/products/ProductsActions";
import { getIsFetchingReview } from "../views/products/ProductsSelectors";
import { Row } from "./Layout";
import { successNotification } from "../modules/Notification";

const mapStateToProps = (state) => ({
  isFetchingReview: getIsFetchingReview(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ createReview }, dispatch),
});

export const Title = styled.div`
  color: ${theme.colors.darkGrey};
  font-size: 30px;
  align-items: center;
  font-weight: 500;

  @media (max-width: 800px) {
    font-size: 25px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  width: ${(p) => p.width || "420px"};
  text-align: right;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 576px) {
    justify-content: initial;
  }
`;

export const InfoText = styled.div`
  color: ${theme.colors.darkGrey};
  font-size: 10px;
  font-weight: 400;
`;

export const ErrorText = styled.div`
  color: ${theme.colors.darkRed};
  font-size: 16px;
  font-weight: 400;
`;

export const SupplierName = styled.div`
  margin-bottom: 10px;
`;

export const ButtonStyle = styled.div`
  padding: 5px 5px 5px 0px;
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 125px;
  max-width: 800px;
  margin-top: 5px;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

class RatingModal extends Component {
  state = { errors: {}, rating: 0, review: "" };

  updateRating = (rating) => {
    this.setState({ rating: rating });
  };

  isValid = () => {
    const errors = {};
    if (this.state.rating === 0) {
      errors["rating"] = "Please choose a rating from 1-5 stars.";
    }
    if (this.state.review.length < 10) {
      errors["review"] = "Please enter a longer, more descriptive review.";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  createReview = () => {
    if (!this.isValid()) return;
    this.props
      .createReview({
        rating: this.state.rating,
        review: this.state.review,
        supplier: this.props.supplier.id,
      })
      .then(() => {
        this.props.hideModal();
        successNotification("Created review successfully");
      });
  };

  render() {
    const { supplier, isFetchingReview, hideModal } = this.props;

    return (
      <Modal maxWidth="600px" hide={hideModal}>
        <Title>Review this supplier</Title>

        <SupplierName>{supplier.name.toUpperCase()}</SupplierName>

        <hr></hr>

        <br></br>

        <Stars
          type="write"
          updateRating={this.updateRating}
          rating={this.state.rating}
        />

        <br></br>

        <Label>Comments</Label>

        <StyledTextArea
          placeholder={"Write a review..."}
          onChange={(e) => this.setState({ review: e.target.value })}
        />

        {this.state.errors.rating && (
          <ErrorText>{this.state.errors.rating}</ErrorText>
        )}

        {this.state.errors.review && (
          <ErrorText>{this.state.errors.review}</ErrorText>
        )}

        <InfoText>
          * Your rating helps the DropCommerce and Suppliers to improve and
          deliver a better service for you.
        </InfoText>

        <br></br>

        <hr></hr>

        <Row align style={{ justifyContent: "end" }}>
          <ButtonNew
            type="main"
            text="SUBMIT REVIEW"
            onClick={this.createReview}
            isFetching={isFetchingReview}
            extra="width: 200px; margin-top: 10px; "
          />
        </Row>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingModal);
