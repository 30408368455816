import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import {
  saveSupplierSettings,
  updateSupplier,
} from "../../../supplier/SupplierActions";
import { signUp } from "../../../../Actions";
import { getSettings } from "../../../supplier/SupplierSelectors";
import { ButtonNew, Column, Input, Modal } from "../../../../components";
import { theme } from "../../../../modules/Theme";
import styled from "styled-components";
import { buildObjectFromFields } from "../../../../modules/Format";
import { postRequest } from "../../../../modules/API";
import Text from "../../../../modules/Text";
import Hint from "../../../../components/Hint";
import createNotification from "../../../../modules/Notification";
import { FiCheck } from "react-icons/fi";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      signUp,
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

class Integrations extends Component {
  state = {
    saveState: null,
    errors: {},
    merchantInput: "",
    merchant_id: this.props.settings.merchant_id,
    shopifyUrl: this.props.settings.shopify_url,
    isSyncing: this.props.settings.announced,
    importText:
      this.props.settings.product_count > 0
        ? `${this.props.settings.product_count} Product(s) Imported`
        : "IMPORT PRODUCTS",
  };

  deleteStripe = (supplierId) => {
    postRequest({ url: "stripe_remove_card/", data: { supplier: supplierId } })
      .then((res) => window.location.reload())
      .catch((err) => console.log(err));
  };

  paypalInfoUpdated = (value) => {
    this.setState({ merchant_id: value });
  };

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: "changed" });
  };

  setupStripeAccount = () => {
    const stripeAPIKey = process.env.REACT_APP_STRIPE_CONNECT_KEY;
    window.open(
      `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeAPIKey}&scope=read_write`
    );
  };

  validateFields = () => {
    let errors = {};

    this.setState({ errors });

    return isEmpty(errors);
  };

  saveSettings = () => {
    if (this.validateFields()) {
      const settings = get(this.props, ["settings"]);

      const data = buildObjectFromFields(settings, ["sync_inventory"]);

      this.setState({ saveState: "saving" }, () => {
        this.props.saveSupplierSettings(data).then(() => {
          this.setState({ saveState: null });
        });
      });
    }
  };

  syncSupplierProducts = (merchantID) => {
    this.setState({ isSyncing: true, importText: "Import in Progress" });
    if (merchantID !== "" && !merchantID.includes("@")) {
      postRequest({
        url: "api/suppliers/sync/",
        data: { shopify_url: merchantID },
      }).then((res) => window.location.reload());
    } else {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Please enter a valid PayPal Merchant ID",
      });
    }
  };

  connectPayPalAccount = (merchantID) => {
    if (merchantID !== "" && !merchantID.includes("@")) {
      postRequest({
        url: "api/payments/merchant/",
        data: { merchant_id: merchantID },
      });
      this.setState({ editIntegrations: false });
      this.props.updateSupplier({ merchant_id: merchantID });
    } else {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Please enter a valid PayPal Merchant ID",
      });
    }
  };

  editIntegrations = (bool) => {
    this.setState({ editIntegrations: bool });
  };

  signUp = () => {
    let errors = {};

    if (this.state.password.length < 8) {
      errors.password = "Please check the password you have entered.";
      createNotification({
        title: "Error",
        type: "danger",
        message: "Please check the password you have entered.",
      });
    } else {
      this.props
        .signUp({
          hybridRequest: true,
          email: this.props.settings.email,
        })
        .then((_response) => {
          this.props.history.push("/accounts");
        });
    }
  };

  render() {
    const settings = get(this.props, ["settings"]);

    return (
      <React.Fragment>
        {!isEmpty(settings) && (
          <div>
            {settings.shopify_url && settings.shopify_token_saved && (
              <div>
                <SectionTitle>Ecommerce</SectionTitle>
                <Section>
                  <SubSection>
                    <SettingTitle>Shopify</SettingTitle>
                    <SettingSubtitle>Currently connected to:</SettingSubtitle>
                    <SettingSubtitle>
                      <ExternalLink>
                        <a href={`http://${settings.shopify_url}/`}>
                          {settings.shopify_url}
                        </a>
                      </ExternalLink>
                    </SettingSubtitle>
                    <SettingSubtitle>
                      <ButtonNew
                        fit
                        text={this.state.importText}
                        onClick={() =>
                          this.syncSupplierProducts(this.state.shopifyUrl)
                        }
                        type={"main"}
                        disabled={
                          this.state.isSyncing || settings.product_count > 0
                        }
                        mobile100
                      />
                    </SettingSubtitle>
                  </SubSection>
                </Section>
              </div>
            )}
            <SectionTitle>Payment Gateways</SectionTitle>
            <Section>
              <SubSection>
                <SettingTitle>Stripe</SettingTitle>
                <SettingSubtitle>
                  Your Stripe User ID:
                  {!settings.stripe_id_saved && (
                    <Hint
                      hint="A Stripe account is required for accepting payments."
                      type="warning"
                      lessMargin
                    />
                  )}
                </SettingSubtitle>
                <SettingSubtitle>
                  {!settings.stripe_id_saved ? (
                    <Input
                      label=""
                      width="192px"
                      onChange={(e) =>
                        this.setState({ merchantInput: e.target.value })
                      }
                    />
                  ) : (
                    settings.stripe_id_saved
                  )}
                </SettingSubtitle>
                <SettingSubtitle>
                  <ButtonNew
                    fit
                    text={
                      settings.stripe_id_saved
                        ? "STRIPE CONNECTED"
                        : "CONNECT STRIPE ACCOUNT"
                    }
                    onClick={this.setupStripeAccount}
                    type={settings.stripe_id_saved ? "main-light" : "main"}
                    icon={
                      settings.stripe_id_saved ? (
                        <FiCheck color="white" />
                      ) : null
                    }
                    disabled={settings.stripe_id_saved}
                    isFetching={settings.isSavingStripe}
                  />
                </SettingSubtitle>
              </SubSection>
              <SubSection>
                <SettingTitle>PayPal</SettingTitle>
                {/* Label */}
                <SettingSubtitle>
                  Your PayPal Merchant ID:
                  <Hint hint={"Merchant ID looks like this: DFBH67E4KIBGC"} />
                </SettingSubtitle>
                {/* Display saved Merchant ID*/}
                <SettingSubtitle>
                  {!settings.merchant_id ? (
                    <Input
                      label=""
                      width="192px"
                      onChange={(e) =>
                        this.setState({ merchantInput: e.target.value })
                      }
                    />
                  ) : (
                    settings.merchant_id
                  )}
                </SettingSubtitle>
                {/* Button for PayPal 3rd party integration */}
                <SettingSubtitle>
                  <ButtonNew
                    fit
                    text={
                      settings.merchant_id
                        ? "PAYPAL CONNECTED"
                        : "CONNECT PAYPAL ACCOUNT"
                    }
                    onClick={() =>
                      this.connectPayPalAccount(this.state.merchantInput)
                    }
                    type={settings.merchant_id ? "main-light" : "main"}
                    icon={
                      settings.merchant_id ? <FiCheck color="white" /> : null
                    }
                    disabled={settings.merchant_id}
                    mobile100
                  />
                </SettingSubtitle>
              </SubSection>
              {(settings.stripe_id_saved || settings.merchant_id) && (
                <SubSection>
                  <ButtonNew
                    text="EDIT INTEGRATIONS"
                    width="168px"
                    margin="32px 0 0 0"
                    type="main-dark"
                    onClick={() => this.editIntegrations(true)}
                  />
                </SubSection>
              )}
            </Section>
            {settings.account !== "accounts" && (
              <div>
                <SectionTitle>DropCommerce</SectionTitle>
                <Section>
                  <SettingSubtitle>
                    Interested in selling products from other suppliers?
                  </SettingSubtitle>
                  <SettingSubtitle>
                    <ButtonNew
                      margin="10px 0 0 0"
                      text="CREATE STORE ACCOUNT"
                      width="200px"
                      onClick={() => this.setState({ signUpModal: true })}
                    />
                  </SettingSubtitle>
                </Section>
              </div>
            )}
          </div>
        )}

        {this.state.signUpModal && (
          <Modal
            hide={() => this.setState({ signUpModal: false })}
            maxWidth="320px"
          >
            <Text bold>CREATE STORE ACCOUNT</Text>
            {settings.merchant_id && (
              <Column>
                <Input
                  type="password"
                  label="Enter your password"
                  width="200px"
                  value={this.state.password}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
                <ButtonNew
                  text="CREATE MY ACCOUNT"
                  width="200px"
                  onClick={this.signUp}
                />
              </Column>
            )}
          </Modal>
        )}

        {/* the following modal is displayed when the user wants to edit or
        delete their paypal or stripe respectively */}

        {this.state.editIntegrations && (
          <Modal hide={() => this.editIntegrations(false)} maxWidth="320px">
            <Text.Large>Edit integrations</Text.Large>
            {settings.merchant_id && (
              <Column>
                <Input
                  label="PayPal Merchant ID"
                  width="200px"
                  value={this.state.merchant_id}
                  onChange={(event) =>
                    this.paypalInfoUpdated(event.target.value)
                  }
                />
                <ButtonNew
                  text="UPDATE PAYPAL"
                  width="150px"
                  onClick={() =>
                    this.connectPayPalAccount(this.state.merchant_id)
                  }
                />
              </Column>
            )}
            {settings.stripe_id_saved && (
              <div>
                <br />
                <hr />
                <Column>
                  <Text>Stripe Payment Account</Text>
                  <ButtonNew
                    margin="10px 0 0 0"
                    text="REMOVE CARD"
                    width="150px"
                    onClick={() => {
                      this.deleteStripe(settings.id);
                      this.editIntegrations(false);
                    }}
                  />
                </Column>
              </div>
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Integrations)
);

const SettingTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: ${(p) => (p.small ? "16px" : "20px")};
  color: ${theme.colors.medDarkGrey};
  font-weight: 600;
  margin-right: 15px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-top: 1.4em;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: ${(p) => (p.small ? "18px" : "22px")};
  color: ${theme.colors.medDarkGrey};
  font-weight: 400;
  margin: 1em 1em;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const ExternalLink = styled.span`
  font-size: 16px;
  font-weight: 450;
  color: ${theme.colors.main};
  cursor: pointer;
  padding: 0em 0.2em;
`;

const SettingSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  margin-top: 8px;
  @media (min-width: 700px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div`
  width: 90%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(211, 211, 211);
  margin: 1em 1em;
  padding: 1.5em 1.5em;

  @media (min-width: 750px) {
    display: flex;
  }
`;
