import React, { useState } from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { ButtonNew, Input, Switch, Column, Row } from "../../../../components";
import { errorNotification } from "../../../../modules/Notification";
import Text from "../../../../modules/Text";
import { axiosAdminInstance } from "../../api/axiosAdminInstance";

const InputLabel = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export function ShopifyProductImport({
  data,
  addCollection,
  removeCollection,
  updateData,
}) {
  const [selectedCollectionIDs, setSelectedCollectionIDs] = useState([]);
  const [importedCollectionID, setImportedCollectionID] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [collections, setCollections] = useState(null);
  const [importAllProducts, setImportAllProducts] = useState(true);

  const validateSupplierId = () => {
    const errors = {};
    if (data.supplier_id === "") {
      errors.supplierID = "Please enter a supplier id.";
    }
    setErrors(errors);
    return isEmpty(errors);
  };

  const getCollections = () => {
    if (!validateSupplierId()) return;
    setIsFetching(true);

    // async
    axiosAdminInstance
      .get(`/admin_commands/supplier_collections/?supplier=${data.supplier_id}`)
      .then((responseData) => {
        updateData(
          "collections_count",
          responseData.data.shopify_collections.length
        );
        setSelectedCollectionIDs(responseData.data.imported_collections);
        setCollections(responseData.data.shopify_collections);
        setImportedCollectionID(responseData.data.imported_collections);
      })
      .catch((error) => {
        if (error.response.data.detail) {
          errorNotification("Supplier not found");
        } else {
          errorNotification(error.response.data.error);
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <>
      <Text.Large extra="margin-bottom: 10px;">
        Shopify Supplier Product Import
      </Text.Large>
      <Text.Small light extra="margin-bottom: 30px;">
        This command will import products from the supplier's Shopify. This
        command does not remove products.
      </Text.Small>
      <Column style={{ maxWidth: "500px" }}>
        <InputLabel>Supplier ID</InputLabel>
        <Row>
          <Input
            type="number"
            onChange={(e) => updateData("supplier_id", e.target.value)}
            extraStyle="margin: 0px; width: 200px;"
            placeholder="Supplier ID"
            error={errors.supplierID}
          />
          <ButtonNew
            isFetching={isFetching}
            onClick={getCollections}
            type="main"
            text="Get Import Settings"
            extra="width: 200px;"
            size="large"
          />
        </Row>
        {collections && (
          <>
            <Row
              style={{
                justifyContent: "space-between",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Text.Small>Import All Products</Text.Small>
              <Column>
                <Switch
                  noTopMargin
                  active={importAllProducts}
                  toggle={() => {
                    setImportAllProducts((prev) => {
                      return !prev;
                    });
                    if (importAllProducts) {
                      updateData("collections", selectedCollectionIDs);
                    } else {
                      updateData("collections", null);
                    }
                  }}
                />
              </Column>
            </Row>
            <TableContainer
              component={Paper}
              style={{
                overflow: "auto",
                maxWidth: "550px",
                backgroundColor: importAllProducts && "lightgrey",
              }}
            >
              <Table aria-label="customized table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell>Sync</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {collections.map((datum) => (
                    <TableRow key={datum.value}>
                      <TableCell>{datum.label}</TableCell>
                      <TableCell>
                        <Switch
                          noTopMargin
                          disabled={
                            importedCollectionID.includes(datum.value) ||
                            importAllProducts
                          }
                          active={
                            importedCollectionID.includes(datum.value) ||
                            (selectedCollectionIDs.includes(datum.value) &&
                              !importAllProducts)
                          }
                          toggle={() => {
                            if (selectedCollectionIDs.includes(datum.value)) {
                              setSelectedCollectionIDs((ids) =>
                                ids.filter((id) => id != datum.value)
                              );
                              removeCollection(datum.value);
                            } else {
                              setSelectedCollectionIDs((ids) => [
                                ...ids,
                                datum.value,
                              ]);
                              addCollection(datum.value);
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {importAllProducts && (
              <Text.Small light style={{ paddingTop: "20px" }}>
                All Products will be imported.
              </Text.Small>
            )}
            {!importAllProducts && (
              <>
                {selectedCollectionIDs.length > 0 && (
                  <Text.Small light style={{ paddingTop: "20px" }}>
                    <b>Products will be imported from the collections:</b>
                    <br />
                    {collections
                      .filter((collection) =>
                        selectedCollectionIDs.includes(collection.value)
                      )
                      .map((collection) => collection.label)
                      .join(", ")}
                  </Text.Small>
                )}

                <Text.Small style={{ color: "orange", paddingTop: "20px" }}>
                  Warning: Products imported from collections cannot be removed
                  easily.
                </Text.Small>
              </>
            )}
          </>
        )}
      </Column>
    </>
  );
}
