import { get } from "lodash";

export const getLocalState = (state) => get(state, "supplier");

// export const getSearchVariants = (state) => getLocalState(state).searchVariants;
export const getIsSearchingVariants = (state) =>
  getLocalState(state).isSearchingVariants;

export const getShippingOption = (state) => getLocalState(state).shippingOption;
export const getIsFetchingShippingOption = (state) =>
  getLocalState(state).isFetchingShippingOption;
export const getIsFetchingShippingOptions = (state) =>
  getLocalState(state).isFetchingShippingOptions;
export const getShippingOptions = (state) =>
  getLocalState(state).shippingOptions;
export const getSettings = (state) => getLocalState(state).settings;

export const getSupplierOrder = (state) => getLocalState(state).supplierOrder;
export const getSupplierOrders = (state) => getLocalState(state).supplierOrders;
export const getProduct = (state) => getLocalState(state).product;
export const getProducts = (state) => getLocalState(state).products;
export const getSupplierProductSaved = (state) =>
  getLocalState(state).supplierProductSaved;
export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSettings = (state) =>
  getLocalState(state).isFetchingSettings;
export const getPagination = (state) => getLocalState(state).pagination;
export const getError = (state) => getLocalState(state).error;
export const getVendors = (state) => getLocalState(state).vendors;
export const getVendor = (state) => getLocalState(state).vendor;
export const getShipping = (state) => getLocalState(state).shipping;
export const getTags = (state) => getLocalState(state).tags;
export const getCategories = (state) => getLocalState(state).categories;
