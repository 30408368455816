import React, { Component } from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import HttpsRedirect from "react-https-redirect";
import Main from "./prebuilt/Main";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Login from "./prebuilt/Login";
import { Forgot } from "./prebuilt/Forgot";
import { ErrorBoundary } from "./components";

// prebuilt app history
const history = createBrowserHistory();

class PrebuiltApp extends Component {
  render() {
    return (
      <Router history={history}>
        <ErrorBoundary notFoundImage="https://res.cloudinary.com/dropcommerce/image/upload/v1680551214/c0xeeompengu72fvsged.svg">
          <ReactNotification />

          <HttpsRedirect>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path="/dashboard" component={Main} />
              <Route path="/starter-stores" component={Main} />
              <Route path="/premium-stores" component={Main} />
              <Route path="/custom-stores" component={Main} />
              <Route path="/my-stores" component={Main} />
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={Forgot} />
            </Switch>
          </HttpsRedirect>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default PrebuiltApp;
