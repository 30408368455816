import React, { Component } from "react";
import styled from "styled-components";
import { FiX } from "react-icons/fi";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";

const Background = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  padding-top: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(11, 28, 28, 0.7);
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 30px;
  }

  @media (max-width: 800px) {
    padding: 0px;
  }
`;

const Bubble = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 900px;

  @media (max-width: 800px) {
    background: white;
    padding: 5px;
    height: 100%;
  }
`;

const BubbleWhite = styled.div`
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const BubbleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabsColumn = styled.div`
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const Tab = styled.div`
  font-size: 22px;
  font-weight: 400;
  /* color: ${(p) =>
    p.active ? theme.colors.main : theme.colors.medDarkGrey}; */
  color: ${theme.colors.medDarkGrey};
  padding: 20px;
  border-right: ${(p) => (p.active ? "0px" : "1px")} solid
    ${theme.colors.lightBorder};
  border-bottom: ${(p) => (p.noBottomBorder ? "0px" : "1px")} solid
    ${theme.colors.lightBorder};
  width: 300px;
  height: calc(100% * (1 / ${(p) => p.tabCount}));
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.active ? "white" : theme.colors.ultraLightGrey)};
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
    width: 150px;
    padding: 10px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
  text-align: center;

  @media (max-width: 800px) {
    font-size: 22px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-top: 15px;
  text-align: center;
  max-width: 500px;

  @media (max-width: 800px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

const Image = styled.img`
  margin-bottom: 20px;
  height: 200px;
  width: 400px;

  @media (max-width: 800px) {
    height: 100px;
    width: 200px;
  }
`;

const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const HeaderTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  color: ${theme.colors.main};

  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 20px;

  @media (max-width: 800px) {
    padding: 10px;
  }
`;

class TabModal extends Component {
  state = { currentTab: Object.keys(this.props.data)[0] };

  render() {
    const { hide, fullScreen, maxWidth } = this.props;

    const tabs = Object.keys(this.props.data);

    return (
      <Background>
        <Bubble fullScreen={fullScreen} maxWidth={maxWidth}>
          <FiX
            size="40"
            color={theme.colors.medLightGrey}
            onClick={hide}
            style={{ cursor: "pointer", marginBottom: "10px" }}
          />
          <BubbleWhite>
            <TitleRow>
              <HeaderTitle>{this.props.title}</HeaderTitle>
              <ButtonNew
                text={this.props.buttonText}
                onClick={this.props.buttonOnClick}
                fontStyle="font-size: 20px;"
              />
            </TitleRow>
            <BubbleRow>
              <TabsColumn>
                {tabs.map((tab, i) => {
                  const matchingData = this.props.data[tab];
                  return (
                    <Tab
                      active={tab === this.state.currentTab}
                      onClick={() => this.setState({ currentTab: tab })}
                      tabCount={tabs.length}
                      key={i}
                    >
                      {matchingData.name}
                    </Tab>
                  );
                })}
              </TabsColumn>
              <Content>
                <Image src={this.props.data[this.state.currentTab].image} />
                <Title>{this.props.data[this.state.currentTab].name}</Title>
                {this.props.data[this.state.currentTab].description.map(
                  (description, i) => (
                    <Description key={i}>{description}</Description>
                  )
                )}
              </Content>
            </BubbleRow>
          </BubbleWhite>
        </Bubble>
      </Background>
    );
  }
}

export default TabModal;
