import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchSupplierSettings } from "./views/supplier/SupplierActions";
import { withRouter } from "react-router-dom";

// SUPPLIER
import { Product, NewProduct, Products, Vendors, Tags } from "./views/supplier";
import { getSettings as getSupplierSettings } from "./views/supplier/SupplierSelectors";
import SupplierAnalytics from "./views/supplier/views/SupplierAnalytics";
import Vendor from "./views/supplier/views/Vendor";
import VendorDetails from "./views/supplier/views/VendorDetails";
import AccessBlocked from "./views/supplier/views/AccessBlocked";
import Order from "./views/supplier/views/orders/Order";
import Orders from "./views/supplier/views/orders/Orders";
import Inbox from "./views/inbox/Inbox";
import SupplierReview from "./views/supplier/components/SupplierReview";

// ONBOARDING
import Onboarding from "./views/supplier/views/onboarding/Onboarding";

// OTHER
import SupplierSettings from "./views/settings/SupplierSettings";
import { get, isEmpty } from "lodash";
import SupplierRefund from "./views/supplier/components/SupplierRefund";
import Wrapper from "./components/Wrapper";
import { FullScreenSpinner } from "./components/FullScreenSpinner";

const mapStateToProps = (state) => ({
  settings: getSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSupplierSettings }, dispatch),
});

class Supplier extends Component {
  componentDidMount = () => {
    const billingPage = window.location.href.includes(
      "/supplier/settings/billing"
    );

    // IF not plans page, fetch settings here
    if (!billingPage) {
      this.props.fetchSupplierSettings().then((response) => {
        const onboardingStep = response.supplier.onboarding_step;

        if (
          response.supplier.onboarding_step &&
          response.supplier.subdomain === ""
        ) {
          this.props.history.push(`/supplier/onboarding/${onboardingStep}`);
        }
      });
    }
  };

  render() {
    const path = this.props.match.path;

    const billingPage = window.location.href.includes(
      "/supplier/settings/billing"
    );

    if (isEmpty(this.props.settings) && !billingPage) {
      return (
        <Wrapper>
          <FullScreenSpinner />
        </Wrapper>
      );
    }

    return get(this.props, ["settings", "status"]) === "REMOVED" ? (
      <AccessBlocked />
    ) : (
      <Switch>
        <Route path={`${path}/product`} component={Product} exact />

        <Route
          path="/"
          render={(routeProps) => (
            <Wrapper>
              <Switch>
                <Route
                  path={`${path}/onboarding/:page`}
                  component={Onboarding}
                />
                <Route path={`${path}/categories`} component={Tags} />
                <Route path={`${path}/products/:tab`} component={Products} />
                <Route path={`${path}/products`} component={Products} />
                <Route path={`${path}/product/new`} component={NewProduct} />
                <Route
                  path={`${path}/product/:productId`}
                  component={Product}
                />

                <Route path={`${path}/order/:orderId`} component={Order} />
                <Route path={`${path}/orders/:orderType`} component={Orders} />
                <Route path={`${path}/orders/`} component={Orders} />
                <Route
                  path={`${path}/vendors/public/:vendorId`}
                  component={Vendor}
                />
                <Route
                  path={`${path}/vendors/private/:vendorId`}
                  component={Vendor}
                />
                <Route path={`${path}/vendors/private`} component={Vendors} />
                <Route path={`${path}/vendors/public`} component={Vendors} />
                <Route
                  path={`${path}/vendor/:vendorId`}
                  component={VendorDetails}
                />

                <Route
                  path={`${path}/settings/:tab`}
                  component={SupplierSettings}
                />
                <Route
                  path={`${path}/settings`}
                  component={SupplierSettings}
                  exact
                />
                <Route
                  path={`${path}/inbox/:conversationLabel`}
                  component={Inbox}
                />
                <Route path={`${path}/inbox`} component={Inbox} />
                <Route
                  path={`${path}/analytics`}
                  component={SupplierAnalytics}
                />
                {/*<Route*/}
                {/*  path={`${path}/supplier-refunds`}*/}
                {/*  component={SupplierRefund}*/}
                {/*/>*/}
                <Route
                  path={`${path}/supplier-reviews`}
                  component={SupplierReview}
                />
                <Route
                  path={`${path}/supplier-reviews/:reviewId/appeal`}
                  component={SupplierReview}
                />
              </Switch>
            </Wrapper>
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Supplier)
);
