import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ButtonNew, Column, Modal, Row } from "../components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { FiCompass, FiStar, FiThumbsUp } from "react-icons/fi";
import SellingPoint from "../views/prebuilt/components/SellingPoint";
import dcLogo from "../images/dropcommerce-logo-2021-small.png";
import Checkout from "./components/dashboard/CustomCheckout";

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 16px;

  @media screen and (min-width: 801px) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    padding: 48px;
  }
`;

const Left = styled.div`
  width: 100%;

  @media screen and (min-width: 801px) {
    width: 45%;
  }
`;

const HeroText = styled.div`
  font-size: 35px;
  font-weight: 300;

  @media screen and (min-width: 801px) {
    font-size: 45px;
  }
`;

const BoldHeroText = styled.span`
  font-weight: 500;
`;

const PaddedText = styled(Text)`
  padding: 1em 1em;
`;

const GetStarted = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DCLogo = styled.img`
  height: 25px;
`;

const Right = styled.div`
  flex-grow: 1;
`;

const PoweredBy = styled(Row)`
  flex-direction: column;

  @media screen and (min-width: 640px) {
    flex-direction: row;
  }

  @media screen and (min-width: 801px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 390px;
  border-radius: 20px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const WideRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  padding: 40px 0;

  @media screen and (min-width: 801px) {
    flex-direction: row;
  }
`;

const BuyCustom = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      {showModal && (
        <Modal hide={() => setShowModal(false)}>
          <Checkout refreshStoreDesigns={() => props.refreshStoreDesigns()} />
        </Modal>
      )}

      <HeroContainer>
        <Left>
          <HeroText>
            Custom designed <BoldHeroText>prebuilt stores</BoldHeroText> for
            sale
          </HeroText>
          <Column>
            <PaddedText s>
              A few notches above our regular Pre-Built Store offering
            </PaddedText>
            <PaddedText s light>
              <p>
                Pre-Built Premium is our new top-shelf service for serious
                dropshippers
              </p>
              <p>
                Purchase one of a kind, professionally branded e-commerce store
              </p>
            </PaddedText>
            <PaddedText s>
              Dive right into managing your new business.
            </PaddedText>
            <GetStarted>
              <ButtonNew
                size="large"
                text="Get Started!"
                width="200px"
                margin="20px 0 0 0"
                onClick={() => setShowModal(true)}
              />
              <PoweredBy align style={{ margin: "20px 0 0 0" }}>
                <Text.Small light margin="0 5px 0 0">
                  Powered by
                </Text.Small>
                <DCLogo src={dcLogo} />
              </PoweredBy>
            </GetStarted>
          </Column>
        </Left>

        <Right>
          <ImageContainer>
            <Image
              src="https://premade-store-images.s3.ca-central-1.amazonaws.com/custom-prebuilt.png"
              alt="Premade Stores"
            />
          </ImageContainer>
        </Right>
      </HeroContainer>

      <WideRow>
        <SellingPoint
          icon={<FiStar size="40" color={theme.colors.main} />}
          title="1. Choose your categories"
          description="Select up to 3 product categories. We'll load products according to your picks"
        />
        <SellingPoint
          icon={<FiCompass size="40" color={theme.colors.main} />}
          title="2. Choose you Style Pack"
          description="Style Packs include stock images and a theme that matches your vibe"
        />
        <SellingPoint
          icon={<FiThumbsUp size="40" color={theme.colors.main} />}
          title="3. Checkout"
          description="Choose your plan and the Shopify URL you want for this custom store"
        />
      </WideRow>
    </React.Fragment>
  );
};

export default withRouter(BuyCustom);
