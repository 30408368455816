import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MDSpinner from "react-md-spinner";

import { getIsFetching } from "../../../views/admin/AdminSelectors";
import Text from "../../../modules/Text";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  //   products: getCategorizeProducts(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      //   fetchCategorizeProducts
    },
    dispatch
  ),
});

class Categorize extends Component {
  UNSAFE_componentWillMount = () => {
    // this.props.fetchCategorizeProducts();
  };

  render() {
    // const products = get(this.props, "products");

    return (
      <PageWrapper>
        <Text bold white extra="margin-bottom: 20px;">
          Categorize
        </Text>

        {this.props.isFetching > 0 ? (
          <MDSpinner singleColor="rgb(255, 255, 255)" size={50} />
        ) : (
          <React.Fragment>Coming soon.</React.Fragment>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Categorize)
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
