import React from "react";
import styled from "styled-components";
import { Row } from "../../../components";

import { theme } from "../../../modules/Theme";

const StylePackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px;
`;

const ImageWrapper = styled.div`
  margin-bottom: 5px;
  border: ${(p) => (p.selected ? "2px" : "0px")} solid
    ${(p) => (p.selected ? theme.colors.main : theme.colors.lightBorder)};
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const Image = styled.img`
  height: 220px;
  width: 440px;
  border-radius: 20px;
  @media (max-width: 1300px) {
    height: 200px;
    width: 400px;
  }
  @media (max-width: 800px) {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
`;

const StylePackName = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

const StylePackCard = (props) => {
  return (
    <StylePackContainer onClick={props.onClick}>
      <ImageWrapper selected={props.selected}>
        <Image src={props.image} />
      </ImageWrapper>
      <Row style={{ justifyContent: "center" }}>
        <StylePackName>{props.name}</StylePackName>
      </Row>
    </StylePackContainer>
  );
};

export default StylePackCard;
