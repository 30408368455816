import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createProduct,
  createNewProduct,
} from "../../../views/supplier/SupplierActions";
import {
  getProduct,
  getIsFetching,
  getSettings,
} from "../../../views/supplier/SupplierSelectors";
import Text from "../../../modules/Text";
import BackNavBar from "../../../components/BackNavBar";
import { Column } from "../../../components/Layout";
import ButtonNew from "../../../components/ButtonNew";

import Input from "../../../components/Input";
import { Container } from "../../../components/Container";

import { theme } from "../../../modules/Theme";
import styled from "styled-components";
import { isEmpty } from "lodash";

const mapStateToProps = (state) => ({
  product: getProduct(state),
  settings: getSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      createProduct,
      createNewProduct,
    },
    dispatch
  ),
});

class NewProduct extends Component {
  state = {
    title: "",
    errors: {},
  };

  isValid = () => {
    const errors = {};
    if (this.state.title.length < 3) {
      errors.title = "Please enter a longer product title";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  createProduct = () => {
    if (!this.isValid()) return;
    this.props.createNewProduct(this.state.title).then((response) => {
      if (response.productId) {
        this.props.history.push(`/supplier/product/${response.productId}`);
      }
    });
  };

  render() {
    return (
      <Container>
        <Column>
          <BackNavBar
            title="Products"
            onClick={() => this.props.history.push("/supplier/products")}
          />

          <Text.Large>New Product</Text.Large>
        </Column>

        <Input
          label="Title"
          onChange={(e) => this.setState({ title: e.target.value })}
          placeholder="New Product"
          value={this.state.title}
          error={this.state.errors.title}
          extraStyle="width: 400px; max-width: 95vw;"
        />

        <ButtonNew
          type="main"
          text="Create Product"
          extra="width: max-content; margin-top: 20px;"
          onClick={this.createProduct}
          isFetching={this.props.isFetching}
        />
      </Container>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewProduct)
);

export const VariantsWrapper = styled.div`
  margin-top: 30px;
`;

export const SpinnerWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ImagesFetchingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
`;

export const Image = styled.img`
  height: 200px;
  width: 200px;
  margin-right: 10px;
  object-fit: contain;
  border: 1px solid ${theme.colors.inputBorder};
`;

export const Variant = styled.div`
  margin-bottom: 15px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.inputBorder};
`;
