import Cookies from "js-cookie";
import { get, isEmpty } from "lodash";
import { history } from "../BaseApp";
import { store } from "../baseStore";
import { authStore, getDefaultStoreRoute } from "../modules/Format";
import createNotification from "../modules/Notification";

export function validateWebsite(website) {
  // Regular expression pattern for website validation
  const pattern =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

  // Test the input website against the regex pattern
  return pattern.test(website);
}

// Errors Related
const displayError = (response, error) => {
  // This seems hacky, but if error has an error key, grab the inner data
  if (get(error, "error")) {
    error = get(error, "error");
  }

  const state = store.getState();
  const route = get(error, "route");
  const autoRoute = get(error, "auto_route");
  const errorMessage = get(error, "message");

  const responsePlatformUID = get(response, [
    "settings",
    "store",
    "platform",
    "uid",
  ]);
  const statePlatformUID = get(state, [
    "base",
    "settings",
    "store",
    "platform",
    "uid",
  ]);
  const platformUID = responsePlatformUID || statePlatformUID;

  if (
    ["SHOPIFY_INVALID_AUTH", "SHOPIFY_INVALID_TOKEN"].includes(
      get(error, "code")
    ) &&
    platformUID
  ) {
    authStore(platformUID);
  }

  if (autoRoute) {
    history.push(route);
  }

  if (errorMessage) {
    // Error object exists
    createNotification({
      route,
      autoRoute,
      type: "danger",
      title: "Error",
      message: errorMessage,
    });
  } else if ((error && typeof error === "string") || error instanceof String) {
    // Error string exists
    createNotification({
      type: "danger",
      title: "Error",
      message: error,
    });
  }
};

const checkResponseErrors = (response) => {
  // Multiple errors array
  const errors = get(response, "errors");

  // Single error
  const error = get(response, "error");

  if (errors && Array.isArray(errors)) {
    errors.forEach((error) => {
      displayError(response, error);
    });
  } else {
    // depending on whatever the API calls it (erroR or errorS) display that
    error ? displayError(response, error) : displayError(response, errors);
  }

  return response;
};

const checkStatusAgain = (response) => {
  const state = store.getState();

  const isSupplier = !isEmpty(get(state, ["supplier", "settings"]));
  const isStore = !isEmpty(get(state, ["store", "settings"]));

  // REDIRECT TO DASHBOARD
  if (response.status === 403) {
    // SUPPLIER
    if (isSupplier) {
      history.push("/supplier/products");
    }

    // STORE
    else if (isStore) {
      history.push(getDefaultStoreRoute);
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("isSupplier");
    }
  }

  // REDIRECT TO LOGIN
  if (response.status === 401) {
    Cookies.remove("accessToken");
    Cookies.remove("isSupplier");
    history.push("/login?type=unauthorized");
  }

  return response.json();
};

export const checkStatus = (response) => {
  return checkStatusAgain(response).then((res) => {
    checkResponseErrors(res);
    return res;
  });
};

// Product Related
export function addQuantityPropertyToProductObject(productObj) {
  if (!productObj.variants) {
    return { ...productObj, quantity: 0 };
  }
  const quantity = productObj.variants.reduce(
    (accumulator, item) => accumulator + item.quantity,
    0
  );
  return { ...productObj, quantity };
}

// Date Related
export const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString();
};
