import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import {
  updateSupplier,
  saveSupplierSettings,
} from "../../../../views/supplier/SupplierActions";
import { getSettings } from "../../../../views/supplier/SupplierSelectors";
import { ButtonNew, Column } from "../../../../components";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { buildObjectFromFields } from "../../../../modules/Format";
import SettingsItem from "../../../../views/settings/SettingsItem";
import Text from "../../../../modules/Text";
import Switch from "../../../../components/Switch";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

class Values extends Component {
  state = { saveState: null, errors: {} };

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: "changed" });
  };

  validateFields = () => {
    const settings = get(this.props, ["settings"]);

    let errors = {};

    if (settings.made_in_canada && settings.made_in_us) {
      errors["general"] =
        "Product can not be made in US and Canada at the same time.";
    }

    let noneSelected = ![
      settings.made_in_canada,
      settings.made_in_us,
      settings.fair_trade,
      settings.organic,
      settings.handmade,
      settings.kosher,
      settings.non_gmo,
      settings.women_owned,
      settings.bipoc,
      settings.vegan,
      settings.eco_friendly,
      settings.social_good,
      settings.small_batch,
    ].includes(true);

    if (settings.no_values && !noneSelected) {
      errors["general"] =
        "Please either deselect the 'None Of These Values Apply To My Business' option, or deselect the Values below if they do not apply to your business.";
    }

    if (!settings.no_values && noneSelected) {
      errors["general"] =
        "Please select the 'None Of These Values Apply To My Business' option, or select at least one Values below.";
    }

    this.setState({ errors });

    return isEmpty(errors);
  };

  saveSettings = () => {
    if (this.validateFields()) {
      const settings = get(this.props, ["settings"]);

      const data = buildObjectFromFields(settings, [
        "no_values",
        "made_in_canada",
        "made_in_us",
        "fair_trade",
        "organic",
        "handmade",
        "kosher",
        "non_gmo",
        "women_owned",
        "bipoc",
        "vegan",
        "eco_friendly",
        "social_good",
        "small_batch",
      ]);

      this.setState({ saveState: "saving" }, () => {
        this.props.saveSupplierSettings(data).then(() => {
          this.setState({ saveState: null });
        });
      });
    }
  };

  render() {
    const settings = get(this.props, ["settings"]);

    const values = [
      {
        name: "Made In United States",
        key: "made_in_us",
        description: "The product is manufactured in the United States.",
      },
      {
        name: "Made In Canada",
        key: "made_in_canada",
        description: "The product is manufactured in Canada.",
      },
      {
        name: "Fair Trade",
        key: "fair_trade",
        description:
          "Products created and sources in a way that treats all workers on the supply chain fairly",
      },
      {
        name: "Organic",
        key: "organic",
        description:
          "Products created free from pesticides, chemical fertilizers, or other artificial ingredients",
      },
      {
        name: "Handmade",
        key: "handmade",
        description: "Handcrafted products made with love",
      },
      {
        name: "Kosher Certified",
        key: "kosher",
        description: "Products made in a way that is conscious of Jewish Law",
      },
      {
        name: "Non-GMO",
        key: "non_gmo",
        description:
          "Products made without GMOs - any plant or animal who has been altered using genetic engineering techniques",
      },
      {
        name: "Women Owned Business",
        key: "women_owned",
        description:
          "The business is at least 51 percent owned, operated and controlled by a woman or women",
      },
      // {
      //   name: "BIPOC Owned Business",
      //   key: "bipoc",
      //   description:
      //     "The business is at least 51 percent owned, operated and controlled by Black, Indigenous, and/or people of color"
      // },
      {
        name: "Vegan",
        key: "vegan",
        description: "Products made free from animal ingredients",
      },
      {
        name: "Eco Friendly",
        key: "eco_friendly",
        description:
          "Products Made free from practices or ingredients that are harmful to our planet",
      },
      {
        name: "Social Good",
        key: "social_good",
        description:
          "Brands and companies who give back to their communities, advocate for their workers, and make a positive impact on the world",
      },
      {
        name: "Small Batch",
        key: "small_batch",
        description:
          "Products are made in small batches, not mass-produced in a big factory",
      },
    ];

    return (
      <React.Fragment>
        <div style={{ marginTop: "15px" }}>
          <ButtonNew
            text={this.state.saveState ? "SAVE CHANGES" : "CHANGES SAVED"}
            type={this.state.saveState ? "main" : "main-light"}
            onClick={this.state.saveState && this.saveSettings}
            isFetching={this.state.saveState === "saving"}
            extra="height: 36px; width: 150px; margin-top: 10px;"
            // mobile100
          />
        </div>

        <SubSection>
          <SettingsItem
            title="Supplier Values"
            description="Indicate which values apply to your business. This information will be displayed as a special badge on your profile. This will be checked for accuracy, so please only select relevant features."
          />
          {this.state.errors["general"] && (
            <Text.Small red extra="margin-top: 15px;">
              {this.state.errors["general"]}
            </Text.Small>
          )}
          <Switch
            active={get(settings, "no_values")}
            label="None Of These Values Apply To My Business"
            description="If none of these values are relevant to your business, please select this option so we know that you reviewed this page. This can only be selected if all the Values are deselected."
            toggle={() =>
              this.updateSupplier({
                no_values: !get(settings, "no_values"),
              })
            }
          />
          <Column style={{ marginTop: "20px", maxWidth: "600px" }}>
            {values.map((value, i) => (
              <Switch
                key={i}
                active={get(settings, value.key)}
                label={value.name}
                description={value.description}
                toggle={() =>
                  this.updateSupplier({
                    [value.key]: !get(settings, value.key),
                  })
                }
              />
            ))}
          </Column>
        </SubSection>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Values));

export const LogoImage = styled.img`
  height: 100px;
  width: 250px;
  margin-bottom: 20px;
  margin-top: 20px;
  object-fit: contain;
  border: 1px solid ${theme.colors.lightBorder};
`;

// const StyledButton = styled(ButtonNew)`
//   margin-right: 20px;
//   margin-bottom: 20px;
// `;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
