import React, { Component } from "react";
import { Row, Column } from "../../../components/Layout";

import Input from "../../../components/Input";

import styled from "styled-components";

import { get } from "lodash";
import { theme } from "../../../modules/Theme";
import { Checkbox } from "@material-ui/core";

export const RemoveIcon = styled.img`
  height: 35px;
  width: 35px;
  opacity: 0.4;
  margin-left: 20px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const VariantDeletion = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: ${theme.colors.white};
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0px 10px;
  color: #32554f;
`;

class NewVariant extends Component {
  render() {
    const {
      variant,
      updateInput,
      removeVariant,
      trackInventory,
      trackPricing,
    } = this.props;

    return (
      <Row
        style={{
          position: "relative",
          justifyContent: "flex-start",
        }}
      >
        <Input
          label="Name"
          onChange={(text) => updateInput("name", text.target.value)}
          placeholder="Red - Small"
          value={variant.name}
          error={get(variant, ["errors", "name"])}
          marginRight
          extraStyle="width: 300px;"
          width="300px;"
          maxWidth="30.4%"
        />

        <Input
          canHide="hide in mobile devices"
          label="SKU"
          onChange={(text) => updateInput("sku", text.target.value)}
          placeholder="1234"
          value={variant.sku}
          error={get(variant, ["errors", "sku"])}
          marginRight
          width="100px"
          extraStyle="width: 100px;"
        />

        <Input
          canHide="hide in mobile devices"
          label="Quantity"
          onChange={(text) => updateInput("quantity", text.target.value)}
          placeholder="100"
          value={variant.quantity}
          error={get(variant, ["errors", "quantity"])}
          type="number"
          marginRight
          extraStyle="width: 100px;"
          width="100px;"
          disabled={trackInventory}
          maxWidth="16.7%"
        />

        <Input
          label="Price"
          onChange={(text) => updateInput("price", text.target.value)}
          placeholder="0.00"
          value={variant.price}
          type="number"
          error={get(variant, ["errors", "price"])}
          marginRight
          extraStyle="width: 100px;"
          width="100px;"
          disabled={trackPricing}
          maxWidth="16.7%"
        />

        <Input
          label="Retail Price"
          onChange={(text) => updateInput("retail_price", text.target.value)}
          placeholder="0.00"
          marginRight
          value={variant.retail_price}
          type="number"
          error={get(variant, ["errors", "retail_price"])}
          extraStyle="width: 100px;"
          width="100px;"
          disabled={trackPricing}
        />

        <Column style={{ justifyContent: "spaceBetween", minHeight: "100px" }}>
          <Label>Active</Label>
          <Checkbox
            checked={variant.active}
            color="primary"
            onClick={
              variant.active ? removeVariant : () => updateInput("active", true)
            }
          />
        </Column>
      </Row>
    );
  }
}

export default NewVariant;
