import React, { Component } from "react";
import { Row } from "../../../components/Layout";
import ButtonNew from "../../../components/ButtonNew";
import removeIcon from "../../../images/close-icon-black.svg";
import styled from "styled-components";
import Text from "../../../modules/Text";
import blank from "../../../images/not-found.png";
import { theme } from "../../../modules/Theme";
import MDSpinner from "react-md-spinner";
import { resizeImage } from "../../../modules/Format";

export const RemoveIcon = styled.img`
  height: 35px;
  width: 35px;
  opacity: 0.4;
  margin-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

export const Image = styled.img`
  height: 250px;
  width: 250px;
  margin-bottom: 5px;
  object-fit: contain;
`;

export const ImageDeletion = styled.div`
  background: rgba(0, 0, 0, 0.5);
  color: ${theme.colors.white};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  position: relative;
  padding: 10px;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 15px;
`;

class NewImage extends Component {
  state = { error: false };

  render() {
    const { image, updateInput, index, markFeatured, canDelete, deleteImage } =
      this.props;

    return (
      <ImageContainer>
        <Image
          src={this.state.error ? blank : resizeImage(image.url, 350)}
          onError={() => this.setState({ error: true })}
        />
        <Row>
          <ButtonNew
            text={image.feature ? "Main Image" : "Set As Main Image"}
            type={image.feature ? "main" : "main-light"}
            extra="width: 100%; padding: 10px;"
            fontStyle="font-size: 12px;"
            onClick={markFeatured}
          />
          {canDelete && <RemoveIcon src={removeIcon} onClick={deleteImage} />}
        </Row>
        {image.delete && (
          <ImageDeletion>
            {image.deleting ? (
              <MDSpinner singleColor="#ffffff" size={30} />
            ) : (
              <div>
                <Text.Small white extra="margin-bottom: 10px">
                  Confirm Delete?
                </Text.Small>
                <ButtonNew
                  text="Delete"
                  type="main"
                  extra="margin-bottom: 5px; width: 255px;"
                  fontStyle="font-size: 12px;"
                  onClick={() => deleteImage(image.id)}
                />
                <ButtonNew
                  text="Cancel"
                  type="white-hollow"
                  fontStyle="font-size: 12px; width: 255px;"
                  onClick={() => updateInput(false, "delete", index)}
                />
              </div>
            )}
          </ImageDeletion>
        )}
      </ImageContainer>
    );
  }
}

export default NewImage;
