import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setNewPassword, setPlatformUID } from "../../../../Actions";
import { getIsFetching } from "../../../../Selectors";
import {
  getSettings,
  getIsFetchingSettings,
} from "../../../../views/supplier/SupplierSelectors";
import { Row } from "../../../../components";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { get } from "lodash";
import createNotification from "../../../../modules/Notification";
import Account, {
  AccountInput,
  AccountButton,
  AccountSubtitle,
  AccountTitle,
  Form,
} from "../../../../views/account/components/AccountComponents";

const StyledLink = styled.p`
  font-weight: 400;
  color: #001f00;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: #000000;
  }
`;

const ProductsWrapper = styled.div`
  width: 800px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Part = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setNewPassword, setPlatformUID }, dispatch),
});

const defaultProps = {
  isFetching: false,
  settings: {},
};

class Onboarding extends Component {
  state = {
    password: "",
    passwordRepeat: "",
    csvDownloaded: false,
    shopifyUrl: "",
  };

  shopify = () => {
    const shopifySaved = get(this.props, ["settings", "shopify_token_saved"]);

    return (
      <Form>
        {shopifySaved ? (
          <React.Fragment>
            <AccountTitle>Shopify Account Connected!</AccountTitle>
            <AccountSubtitle>
              Please contact support@dropcommerce.com to have your products
              imported from Shopify.
            </AccountSubtitle>
            <AccountSubtitle>
              In the meantime, please review your account settings:
            </AccountSubtitle>
            <AccountButton
              text="Review Settings"
              onClick={() => this.props.history.push("/supplier/settings")}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AccountTitle>Connect Shopify</AccountTitle>
            <AccountSubtitle>
              Enter your original Shopify subdomain. It should look like{" "}
              <strong>yourstore.myshopify.com</strong>.
            </AccountSubtitle>
            <AccountInput
              onChange={(text) =>
                this.setState({ shopifyUrl: text.target.value })
              }
              placeholder="yourstore.myshopify.com"
              extraStyle="margin-bottom: 5px;"
            />
            <AccountButton
              text="Connect Shopify"
              isFetching={this.props.isFetching > 0}
              onClick={() =>
                this.props.history.push("/supplier/settings/platforms")
              }
            />
          </React.Fragment>
        )}
      </Form>
    );
  };

  csv = () => {
    return (
      <Form>
        <AccountTitle>Preparing Your CSV</AccountTitle>
        <AccountSubtitle>
          Please fill in the provided CSV template with your product
          information. Once completed, email it to support@dropcommerce.com with
          the subject line "Product Import".
        </AccountSubtitle>
        <AccountSubtitle>
          If you have hosted image URLs from your website, you can add them to
          the CSV. Otherwise, you can leave the image fields blank in the CSV
          and upload images in the app after importing.
        </AccountSubtitle>
        <AccountButton
          text="Download CSV Template"
          onClick={this.downloadCSV}
          isFetching={this.props.isFetching > 0}
        />
      </Form>
    );
  };

  password = () => {
    return (
      <Form>
        <AccountTitle>Create a password</AccountTitle>

        <AccountInput
          onChange={(text) => this.setState({ password: text.target.value })}
          placeholder="Password"
          type="password"
        />

        <AccountInput
          onChange={(text) =>
            this.setState({ passwordRepeat: text.target.value })
          }
          placeholder="Repeat Password"
          type="password"
        />

        <AccountButton
          text="Login"
          onClick={this.checkPassword}
          isFetching={this.props.isFetching > 0}
        />
      </Form>
    );
  };

  products = () => {
    return (
      <ProductsWrapper>
        <AccountTitle>Product Import</AccountTitle>
        <Row style={{ marginTop: "20px" }}>
          <Part>
            <AccountTitle style={{ fontSize: "30px" }}>Shopify</AccountTitle>
            <AccountSubtitle>
              If you use Shopify for your store, we can sync with your account
              to auto-import products and sync inventory levels (Recommended)
            </AccountSubtitle>
            <AccountButton
              text="Select"
              onClick={() => this.openTab("shopify")}
              isFetching={this.props.isFetching > 0}
            />
          </Part>
          <Part>
            <AccountTitle style={{ fontSize: "30px" }}>CSV File</AccountTitle>
            <AccountSubtitle>
              Download and complete the CSV Template, then email to
              support@dropcommerce.com for a manual import
            </AccountSubtitle>
            <AccountButton
              text="Select"
              onClick={() => this.openTab("csv")}
              isFetching={this.props.isFetching > 0}
            />
          </Part>
        </Row>
        <div style={{ marginTop: "40px" }}>
          <StyledLink
            onClick={() => this.props.history.push("/supplier/products")}
          >
            Skip
          </StyledLink>
        </div>
      </ProductsWrapper>
    );
  };

  setPlatformUID = () => {
    let url = this.state.shopifyUrl;

    if (url.includes(".myshopify.com")) {
      // Fix potential errors
      url = url.replace("www.", "");
      url = url.replace("https://", "");
      url = url.replace("http://", "");
      url = url.replace(".com/", ".com");

      this.props.setPlatformUID(url, null, {
        type: "supplier",
        id: get(this.props, ["supplierSettings", "id"]),
      });
    } else {
      createNotification({
        title: "Invalid URL",
        type: "danger",
        message: "Please enter a valid Shopify URL ending in .myshopify.com",
      });
    }
  };

  checkPassword = () => {
    const { password, passwordRepeat } = this.state;

    if (password !== "" && password === passwordRepeat) {
      this.props.setNewPassword(password).then(() => {
        this.props.history.push("/supplier/onboarding/products");
      });
    } else {
      createNotification({
        title: "Invalid Password",
        type: "danger",
        message: "Please enter a valid password and ensure they match.",
      });
    }
  };

  downloadCSV = () => {
    this.setState({ csvDownloaded: true });
    window.open(
      "https://dc-imports.s3.ca-central-1.amazonaws.com/DropCommerceImportTemplate.csv"
    );
  };

  openTab = (tab) => {
    this.props.history.push(`/supplier/onboarding/${tab}`);
  };

  render() {
    const page = get(this.props, ["match", "params", "page"]);
    return (
      <Account isFetching={this.props.isFetchingSettings}>
        {page === "password" && this.password()}
        {page === "products" && this.products()}
        {page === "shopify" && this.shopify()}
        {page === "csv" && this.csv()}
      </Account>
    );
  }
}

Onboarding.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
