import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { api } from "../../../modules/Config";
import Cookies from "js-cookie";
import Select from "react-select";
import { Row } from "../../../components";
import { Button } from "@material-ui/core";
import { getRequest } from "../../../modules/API";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { history } from "../../../BaseApp";
import { FiAlertTriangle } from "react-icons/fi";
import { theme } from "../../../modules/Theme";
import ModalRefundAdmin from "../../../components/ModalRefundAdmin";

// TODO: move these 2 to a common place and import it from there
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#2c857d",
    cursor: "pointer",
  },
  body: {
    fontSize: 14,
    color: "#839996",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  hover: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
  },
}))(TableRow);

export const Demerit = styled.span`
  border-radius: 4px;
  padding: 8px;
  color: white;
  margin: 5px;
  min-width: 0px;
  background: ${theme.colors.darkRed};
`;

/*
 * Renders the Supplier Stats table in the Admin Dashboard
 * API Endpoint: /api/supplier-stats/
 * URL: /admin/supplier-stats
 * */
export default class Refunds extends React.Component {
  state = {
    days_ago: null,
    ordering: null,
    fetching: true,
    data: [],
    showRefundModal: false,
    status_filter: "",
    store_filter: "",
    order_filter: "",
    days_filter: "",
  };

  componentDidMount() {
    const fetchUrl = this.props.location.search.includes("page")
      ? `${api}/api/refunds/?groupBy=order&${this.props.location.queryParams}`
      : `${api}/api/refunds/?groupBy=order`;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data, fetching: false }))
      .catch((error) => console.log(error));
  }

  nextPage() {
    this.setState({ fetching: true });
    getRequest({}, this.state.data.next).then((res) => {
      this.setState({
        data: res.data,

        fetching: false,
      });
      history.push(
        `?days_ago=${this.state.days_ago}&page=${this.state.data.page}`
      );
    });
  }

  prevPage() {
    getRequest({}, this.state.data.previous).then((res) =>
      this.setState({
        data: res.data,
        fetching: false,
      })
    );
  }

  search(type, value) {
    switch (type) {
      case "status":
        if (value === "ALL") {
          this.setState((p) => ({
            ...p,
            status_filter: "",
          }));
          break;
        } else {
          this.setState((p) => ({
            ...p,
            status_filter: `&${type}=${value}`,
          }));
          break;
        }

      case "store":
        this.setState((p) => ({
          ...p,
          store_filter: `&${type}=${value}`,
        }));
        break;

      case "order":
        this.setState((p) => ({
          ...p,
          order_filter: `&${type}=${value}`,
        }));
        break;

      case "days_ago":
        this.setState((p) => ({
          ...p,
          days_filter: `&${type}=${value}`,
        }));
        break;

      default:
    }

    let queryParams = `${this.state.status_filter}${this.state.store_filter}${this.state.order_filter}${this.state.days_filter}`;

    getRequest({ url: `api/refunds/?groupBy=order${queryParams}` }).then(
      (res) => {
        this.setState({ data: res.data, ordering: null });
        history.push(`?${queryParams}`);
      }
    );
  }

  clearSearch() {
    this.setState((p) => ({
      ...p,
      status_filter: "",
      store_filter: "",
      order_filter: "",
      days_filter: "",
    }));

    getRequest({ url: `api/refunds/?groupBy=order` }).then((res) => {
      this.setState({ data: res.data, ordering: null });
      history.push(`?`);
    });
  }

  render() {
    const statusOptions = [
      {
        value: "ACCEPTED",
        label: "Accepted",
      },
      {
        value: "PENDING",
        label: "Pending",
      },
      {
        value: "CANCELLED",
        label: "Cancelled",
      },
      {
        value: "REFUSED",
        label: "Refused",
      },
      {
        value: "ALL",
        label: "All",
      },
    ];

    return (
      <React.Fragment>
        <h2>Refunds</h2>
        <Row align style={{ justifyContent: "space-between" }}>
          <StyledInput
            type="text"
            placeholder="Search by Order Id"
            onKeyUp={(e) =>
              e.key === "Enter" && this.search("order", e.target.value)
            }
          />
          <StyledInput
            type="text"
            placeholder="Search by Store Id"
            onKeyUp={(e) =>
              e.key === "Enter" && this.search("store", e.target.value)
            }
          />
          <StyledInput
            type="text"
            placeholder="Search by days old"
            onKeyUp={(e) =>
              e.key === "Enter" && this.search("days_ago", e.target.value)
            }
          />
          <div style={{ width: "200px" }}>
            <Select
              options={statusOptions}
              onChange={(selected) => this.search("status", selected.value)}
            />
          </div>
          <Button onClick={() => this.clearSearch()}>Clear Search</Button>
        </Row>
        {this.state.sortingSearching && <MDSpinner />}
        <TableContainer component={Paper}>
          <Table aria-label="customized table" disabled={true}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Order Number</StyledTableCell>
                <StyledTableCell>Refunds Quantity</StyledTableCell>
                <StyledTableCell>Requested Refund</StyledTableCell>
                <StyledTableCell>Shipping Cost</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data &&
                Object.entries(this.state.data).map(([key, datum], i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>{key}</StyledTableCell>
                    <StyledTableCell>{datum.qty}</StyledTableCell>
                    <StyledTableCell>${datum.total_products}</StyledTableCell>
                    <StyledTableCell>${datum.shipping_rate}</StyledTableCell>

                    {datum.error ? (
                      <StyledTableCell>
                        <FiAlertTriangle size="24px" color="red" />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell>{datum.status}</StyledTableCell>
                    )}

                    <StyledTableCell>
                      <ModalRefundAdmin
                        data={datum}
                        hideModal={() =>
                          this.setState({ showRefundModal: false })
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Row>
          {this.state.data.previous && (
            <Button onClick={() => this.prevPage()}>Prev</Button>
          )}
          {this.state.data.next && (
            <Button onClick={() => this.nextPage()}>Next</Button>
          )}
          {this.state.fetching && <MDSpinner />}
        </Row>
      </React.Fragment>
    );
  }
}

const StyledInput = styled.input`
  margin-bottom: 10px;
  height: 40px;
  width: 250px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  :active {
    border: rgba(0, 0, 0, 0.3);
  }
`;
