import React from "react";
import Text from "../../../../../modules/Text";
import styled from "styled-components";
import Stars from "../../../../../components/Stars";
import { FaStar } from "react-icons/fa";
import { Row } from "../../../../../components";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { theme } from "../../../../../modules/Theme";
import MDSpinner from "react-md-spinner";
import { Show } from "../../../../../components/Show";

const Container = styled("div")`
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const LogoWrapper = styled("div")`
  display: grid;
  place-items: center;
`;

const SupplierLogo = styled("img")`
  width: 5.187rem;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 6px;
  user-select: none;

  @media screen and (min-width: 640px) {
    width: 7.375rem;
  }
`;

const SupplierInfo = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 6px;

  @media screen and (min-width: 1024px) {
    align-items: start;
    margin-top: 0;
  }
`;

const SupplierName = styled("h1")`
  font-size: 1.5rem;
  font-weight: 300;
`;

const SupplierSlogan = styled("p")`
  font-size: 13px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  @media screen and (min-width: 1024px) {
    text-align: start;
  }
`;

const StarText = styled("span")`
  font-size: 13px;
`;

const DropCommerceStar = styled(FaStar)`
  color: #32968e;
`;

// In JavaScript, using orderCount == null checks for both null and undefined
// values because the loose equality operator (==) considers them equivalent.
const getOrderCountDisplay = (orderCount) => {
  if (orderCount == null || orderCount < 100) {
    return ">100 Orders Shipped";
  } else {
    return `${orderCount} Orders Shipped`;
  }
};

export const SupplierStatsContainer = ({
  logo,
  supplierName,
  slogan,
  reviews,
  shippedOrders,
  isStar,
  openModal,
  isLoading,
  isFavorite,
  onFavorite,
  onUnfavorite,
  hideForSupplier,
}) => {
  return (
    <Container>
      <LogoWrapper>
        <SupplierLogo
          src={logo}
          alt={`${supplierName} Logo`}
          draggable="false"
        />
      </LogoWrapper>

      <SupplierInfo>
        <SupplierName>{supplierName}</SupplierName>
        <SupplierSlogan>{slogan}</SupplierSlogan>

        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          {isStar && (
            <React.Fragment>
              <StarText>
                <DropCommerceStar /> Star Supplier
              </StarText>
              <span> | </span>
            </React.Fragment>
          )}
          <StarText>{getOrderCountDisplay(shippedOrders)}</StarText>
          <span> | </span>
          <Stars type={"display"} rating={reviews.rating} size={"13"} />
        </div>

        <Show when={!hideForSupplier}>
          <Row gap="10px">
            <Text.Small extra="font-size: 14px;" link light onClick={openModal}>
              Reviews
            </Text.Small>

            <Show
              when={!isLoading}
              fallback={<MDSpinner singleColor={theme.colors.main} size={20} />}
            >
              {isFavorite ? (
                <BsHeartFill
                  color={theme.colors.main}
                  onClick={onUnfavorite}
                  size={20}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <BsHeart
                  color={theme.colors.main}
                  onClick={onFavorite}
                  size={20}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Show>
          </Row>
        </Show>
      </SupplierInfo>
    </Container>
  );
};
