import React from "react";
import styled, { css } from "styled-components";

const SkeletonLoader = ({
  height,
  width,
  radius,
  className,
  extra,
  green,
  light,
}) => (
  <SkeletonTest
    className={className}
    radius={radius}
    height={height}
    width={width}
    extra={extra}
    green={green}
    light={light}
  />
);

export default SkeletonLoader;

const SkeletonTest = styled.div`
  max-width: 100%;
  min-width: ${(p) => p.width || "100%"};
  min-height: ${(p) => p.height || "18px"};
  width: ${(p) => p.width || "100%"};
  height: ${(p) => p.height || "18px"};
  border-radius: ${(p) => p.radius || "4px"};
  background: ${(p) => (p.green ? "#49beaf" : "#d4e3e2")};
  background: ${(props) =>
    props.green
      ? css`linear-gradient(-90deg, #49beaf 0%, #A7E6DE 50%, #49beaf 100%)`
      : css`linear-gradient(-90deg, #f8fbfb 0%, #f1f7f6 50%, #f8fbfb 100%)`};
  background: ${(props) =>
    props.light &&
    css`linear-gradient(-90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.30) 50%, rgba(255,255,255,0.15) 100%)`};
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  ${(p) => p.border && "1px solid #d6dedd;"}
  ${(p) => p.extra};
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
