import { TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";

export function TableToolbar({ title, search, onSearchChange }) {
  const handleChange = (newValue) => {
    onSearchChange(newValue);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
        color="text.secondary"
      >
        {title}
      </Typography>
      <TextField
        label="Filter orders"
        variant="outlined"
        value={search}
        onChange={(e) => handleChange(e.target.value)}
      />
    </Toolbar>
  );
}
