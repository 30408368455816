import { Column } from "./Layout";

import { SidebarItem } from "./SidebarItem";
import React from "react";
import {
  fetchProducts,
  fetchSuppliers,
} from "../views/products/ProductsActions";
import {
  getAppName,
  getDefaultStoreRoute,
  getUrlSubdomain,
} from "../modules/Format";
import { getIsFetchingSettings, getSettings } from "../Selectors";
import {
  getIsFetchingSettings as getIsFetchingSupplierSettings,
  getSettings as getSupplierSettings,
} from "../views/supplier/SupplierSelectors";
import {
  storeSidebarItems,
  supplierSidebarItems,
} from "../modules/SidebarItems";

import Cookies from "js-cookie";
import appIcon from "../images/dc-box-2021-white-grey.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { setError } from "../Actions";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { withRouter } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useLocalStorage } from "../hooks";

const StyledSideBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.darkGreen};
  width: ${(p) => (p.showLabels ? "190px" : "80px")};
  min-width: ${(p) => (p.showLabels ? "190px" : "80px")};
  padding-top: 15px;
  padding-bottom: 10px;
  height: 100vh;
  /* -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; */

  @media (max-width: 800px) {
    display: none;
  }
`;

const ToggleWrapper = styled("button")`
  position: absolute;
  right: 0;
  top: 5.5px;
  z-index: 1;
  transform: translate(50%);
  display: grid;
  place-items: center;
  width: 24px;
  height: 24px;
  background-color: ${(props) => theme.colors.main};
  color: #ffffff;
  border-radius: 12px;
  border: 1px solid grey;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 40px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const SidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const mapStateToProps = (state) => ({
  storeSettings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
  isFetchingSettings: getIsFetchingSettings(state),
  isFetchingSupplierSettings: getIsFetchingSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setError, fetchProducts, fetchSuppliers }, dispatch),
});

function Sidebar(props) {
  const [expanded, setExpanded] = useLocalStorage("expanded", true);

  const logout = () => {
    Cookies.remove("accessToken");
    props.history.push("/");
  };

  const toggleSidebarHandle = () => {
    setExpanded((currentState) => !currentState);
  };

  const isSupplier = get(props, ["supplierSettings", "id"]);

  const isPortalSubdomain =
    getUrlSubdomain() !== "app" &&
    getUrlSubdomain() !== "global" &&
    getUrlSubdomain() !== "nft";

  const path = props.match.path;

  const productCount = get(props, ["supplierSettings", "product_count"]);

  const storeAccountType = get(props, ["storeSettings", "account_type"]);

  const supplierAccountType = get(props, ["supplierSettings", "account_type"]);

  const privatePortal = get(props, [
    "storeSettings",
    "store",
    "private_portal",
  ]);

  const appName = getAppName();

  const supplierPrivatePortal =
    get(props, ["supplierSettings", "subdomain"]) !== "";

  const hidePrebuilt = get(props, ["storeSettings", "store", "hide_prebuilt"]);

  const platformName = get(props, [
    "storeSettings",
    "store",
    "platform",
    "name",
  ]);

  const pathName = get(props, ["location", "pathname"]);

  const storeItems = storeSidebarItems(
    storeAccountType,
    props.history,
    privatePortal,
    path,
    pathName,
    hidePrebuilt,
    platformName,
    logout,
    appName,
    props.handleOpen
  );

  const supplierItems = supplierSidebarItems(
    supplierAccountType,
    productCount,
    supplierPrivatePortal,
    props.history,
    path,
    logout
  );

  return (
    <StyledSideBar showLabels={expanded}>
      <ToggleWrapper onClick={toggleSidebarHandle}>
        {expanded ? <FiChevronLeft /> : <FiChevronRight />}
      </ToggleWrapper>

      {!isPortalSubdomain && (
        <Logo
          src={appIcon}
          onClick={() =>
            props.history.push(
              isSupplier ? "/supplier/products" : getDefaultStoreRoute
            )
          }
        />
      )}

      {props.isFetchingSettings || props.isFetchingSupplierSettings ? (
        <Column
          style={{
            width: "100%",
            height: "100%",
            margin: "0 auto",
            justifyContent: "space-between",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div>
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
          </div>

          <div>
            <SidebarItem.Skeleton />
            <SidebarItem.Skeleton />
          </div>
        </Column>
      ) : (
        <SidebarContainer>
          {!isSupplier && (
            <SidebarInnerContainer>
              <div>
                {storeItems
                  .filter((i) => i.top)
                  .map((item) => (
                    <SidebarItem
                      key={item.text}
                      marginBottom={item.marginBottom}
                      showLabels={expanded}
                      text={item.text}
                      icon={item.icon}
                      active={item.active}
                      onClick={item.onClick}
                      badge={item.badge}
                    />
                  ))}
              </div>

              <div>
                {storeItems
                  .filter((i) => i.bottom)
                  .map((item) => (
                    <SidebarItem
                      key={item.text}
                      marginBottom={item.marginBottom}
                      showLabels={expanded}
                      text={item.text}
                      icon={item.icon}
                      active={item.active}
                      onClick={item.onClick}
                      badge={item.badge}
                    />
                  ))}
              </div>
            </SidebarInnerContainer>
          )}

          {isSupplier && (
            <SidebarInnerContainer>
              <div>
                {supplierItems
                  .filter((i) => i.top)
                  .map((item) => (
                    <SidebarItem
                      key={item.text}
                      marginBottom={item.marginBottom}
                      showLabels={expanded}
                      text={item.text}
                      icon={item.icon}
                      active={item.active}
                      onClick={item.onClick}
                      badge={item.badge}
                    />
                  ))}
              </div>

              <div>
                {supplierItems
                  .filter((i) => i.bottom)
                  .map((item) => (
                    <SidebarItem
                      key={item.text}
                      marginBottom={item.marginBottom}
                      showLabels={expanded}
                      text={item.text}
                      icon={item.icon}
                      active={item.active}
                      onClick={item.onClick}
                      badge={item.badge}
                    />
                  ))}
              </div>
            </SidebarInnerContainer>
          )}
        </SidebarContainer>
      )}
    </StyledSideBar>
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
