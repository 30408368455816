import styled from "styled-components";
import { theme } from "../modules/Theme";

const Image = styled.img`
  height: ${(p) => p.size || "50px"};
  width: ${(p) => p.size || "50px"};
  object-fit: ${(p) => (p.contain ? "contain" : "cover")};
  margin: ${(p) => p.margin || "0px"};
  ${(p) => p.border && `border: 1px solid ${theme.colors.lightBorder}`};
`;

export default Image;
