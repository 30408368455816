import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { ButtonNew, Column } from "../components";
import StoreCard from "../views/prebuilt/StoreCard";

const GreySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.ultraLightGrey};
  padding: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;

export const StoreCardsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const PrebuiltList = (props) => {
  const [viewAll, viewAllSet] = React.useState(true);

  const titleText =
    props.level === "PREMIUM" ? "Premium Stores" : "Starter Stores";

  const filteredStoreDesigns = viewAll
    ? props.storeDesigns
    : props.storeDesigns.filter((design) => !design.sold);

  return (
    <Column justify align style={{ marginBottom: "50px" }}>
      <GreySection style={{ position: "relative" }}>
        <Text center bold extra="font-size: 35px;" margin="0 0 20px 0">
          {titleText}
        </Text>

        {props.level === "PREMIUM" && (
          <ButtonNew
            text={viewAll ? "Available Stores" : "View All"}
            onClick={() => viewAllSet((previousValue) => !previousValue)}
            extra="position: absolute; top: 20px; right: 20px;"
          />
        )}

        <StoreCardsRow>
          {filteredStoreDesigns
            .filter((storeDesign) => storeDesign.level.startsWith(props.level))
            .map((storeDesign, i) => (
              <StoreCard
                key={i}
                storeData={storeDesign}
                onClick={() =>
                  props.selectStoreDesignTypeHandler(storeDesign.type)
                }
                defaultPrice={249.0}
              />
            ))}
        </StoreCardsRow>
      </GreySection>
    </Column>
  );
};

export default withRouter(PrebuiltList);
