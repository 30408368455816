import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { setNewPassword } from "../../../../Actions";
import {
  getIsFetching,
  getSettings as getStoreSettings,
} from "../../../../Selectors";
import { getSettings } from "../../../supplier/SupplierSelectors";
import { ButtonNew, Column, Input } from "../../../../components";
import styled from "styled-components";
import Text from "../../../../modules/Text";
import { successNotification } from "../../../../modules/Notification";
import { validPassword } from "../../../../validation";
import { Show } from "../../../../components/Show";

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

class ResetPassword extends Component {
  state = {
    errors: {},
    newPassword: "",
    newPasswordRepeat: "",
    resetPassword: false,
  };

  isValid = () => {
    const { newPassword, newPasswordRepeat } = this.state;
    const errors = {};
    if (!validPassword(newPassword)) {
      errors["newPassword"] = "Please enter a longer, more secure password";
    }
    if (newPassword !== newPasswordRepeat) {
      errors["newPasswordRepeat"] = "Passwords do not match!";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  changePassword = () => {
    if (!this.isValid()) return;
    const { newPassword } = this.state;
    this.props.setNewPassword(newPassword).then(() => {
      this.setState({
        newPassword: "",
        newPasswordRepeat: "",
        resetPassword: false,
      });
      successNotification("Password changed!");
    });
  };

  render() {
    const { errors, newPassword, newPasswordRepeat, resetPassword } =
      this.state;

    const { isFetching } = this.props;

    const settings = get(this.props, ["settings"]);
    const storeSettings = get(this.props, ["storeSettings"]);

    let email = "";
    if (settings && settings.email) {
      email = settings.email;
    }
    if (storeSettings && storeSettings.email) {
      email = storeSettings.email;
    }

    return (
      <SubSection>
        <Column style={{ maxWidth: "300px" }}>
          <Text extra="margin-bottom: 5px;">Username</Text>

          <Text.Small extra="margin-bottom: 20px;" light>
            {email}
          </Text.Small>

          <Show
            when={resetPassword}
            fallback={
              <Text
                light
                link
                extra="margin-bottom: 5px;"
                onClick={() => this.setState({ resetPassword: true })}
              >
                Reset Account Password
              </Text>
            }
          >
            <Text extra="margin-bottom: 5px;">Reset Password</Text>

            <Input
              onChange={(e) =>
                this.setState({
                  newPassword: e.target.value,
                })
              }
              placeholder="New Password"
              value={newPassword}
              error={errors.newPassword}
              type="password"
            />

            <Input
              onChange={(e) =>
                this.setState({
                  newPasswordRepeat: e.target.value,
                })
              }
              placeholder="New Password ( again )"
              value={newPasswordRepeat}
              error={errors.newPasswordRepeat}
              type="password"
            />

            <ButtonNew
              isFetching={isFetching}
              text="SAVE PASSWORD"
              onClick={this.changePassword}
              extra="margin-top: 5px;"
            />
          </Show>
        </Column>
      </SubSection>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  storeSettings: getStoreSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      setNewPassword,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
