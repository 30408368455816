import { axiosAdminInstance } from "./axiosAdminInstance";

// const delay = () => new Promise((res) => setTimeout(res, 3000));

const impersonateUrlEndpoint = "/impersonate";

export async function getLink(type, id) {
  try {
    // await delay();
    const response = await axiosAdminInstance.get(
      `${impersonateUrlEndpoint}/?type=${type}&id=${id}`
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error:", error);
    throw new Error("Failed to get link.");
  }
}
