import React from "react";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import { ReactPortal } from "../ReactPortal";

const Backdrop = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
`;

const CardWrapper = styled("div")`
  position: fixed;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  width: 80%;
  z-index: 100;

  @media (min-width: 900px) {
    left: calc(50% - 25rem);
    width: 50rem;
  }
`;

const Card = styled("div")`
  overflow: hidden;
  background: ${theme.colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  ${(props) => props.extra}
`;

export function ModalNew({ id, onClose, extra, children }) {
  return (
    <>
      <ReactPortal wrapperId={id + "-backdrop"}>
        <Backdrop />
      </ReactPortal>

      <ReactPortal wrapperId={id + "-card"}>
        <CardWrapper>
          <FiX
            size="40"
            color={theme.colors.medLightGrey}
            style={{ cursor: "pointer" }}
            onClick={onClose}
          />
          <Card extra={extra}>{children}</Card>
        </CardWrapper>
      </ReactPortal>
    </>
  );
}
