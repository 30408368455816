import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import Conversation from "./components/Conversation";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  getArchivedConversations,
  getIsFetching,
  getSettings,
} from "../../Selectors";
import { getSettings as getSupplierSettings } from "../../views/supplier/SupplierSelectors";
import {
  fetchHasUnreadMessage,
  markConversationRead,
  archiveConversation,
  unarchiveConversation,
} from "../../Actions";
import { fetchSupplierUnreadMessage } from "../supplier/SupplierActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Text from "../../modules/Text";
import { ButtonNew, Row } from "../../components";

import { Show } from "../../components/Show";
import ArchivedConversations from "./ArchivedConversations";

const Container = styled("div")`
  background-color: ${(props) => "#ffffff"};
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: 50;
  min-width: 270px;
  max-width: 270px;
  border-right: 1px solid ${(props) => theme.colors.lightBorder};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  -webkit-transition: left 0.25s ease-in-out;
  -o-transition: left 0.25s ease-in-out;
  transition: left 0.25s ease-in-out;

  ${(props) => props.expanded && `left: 0;`}

  @media screen and (min-width: 560px) {
    position: static;
  }
`;

export const ScrollableContainer = styled("div")`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
  flex: ${(props) => props.flex};

  @media screen and (max-width: 800px) {
  }
`;

export const Header = styled(Row)`
  justify-content: space-between;
  padding: 10px;
`;

class Conversations extends Component {
  state = { showArchived: false };

  openUnreadMessage = (conversation) => {
    this.props.handleUnreadChats(conversation);
  };

  render() {
    const { conversations } = this.props;

    const conversationLabel = get(this.props, [
      "match",
      "params",
      "conversationLabel",
    ]);

    const isSupplier = this.props.match.path.includes("supplier");

    const inboxPath = isSupplier ? "/supplier/inbox" : "/inbox";

    return (
      <Container expanded={this.props.expanded}>
        <Header align>
          <Text.Large>
            {!this.state.showArchived ? "Inbox" : "Archived"}
          </Text.Large>

          <Row gap="6px">
            <ButtonNew
              type="secondary"
              text={this.state.showArchived ? "Inbox" : "Archived"}
              onClick={() =>
                this.setState((currentState) => ({
                  showArchived: !currentState.showArchived,
                }))
              }
              extra=""
            />

            <Show when={!isSupplier}>
              <ButtonNew
                type="main"
                text="New"
                onClick={() => this.props.setRecipientModal(true)}
                extra=""
              />
            </Show>
          </Row>
        </Header>

        <Show
          when={this.state.showArchived}
          fallback={
            <ScrollableContainer flex="10">
              {conversations
                .sort(
                  (a, b) =>
                    Date.parse(
                      b.messages.length
                        ? b.messages[b.messages.length - 1].sent_timestamp
                        : 0
                    ) -
                    Date.parse(
                      a.messages.length
                        ? a.messages[a.messages.length - 1].sent_timestamp
                        : 0
                    )
                )
                .map((conversation, i) => {
                  const mostRecentMessage =
                    conversation.messages[conversation.messages.length - 1];

                  const userId = get(this.props, ["settings", "user_id"]);

                  const isUserSupplier = !get(this.props, [
                    "settings",
                    "user_id",
                  ]);

                  const supplierUserId = get(this.props, [
                    "supplierSettings",
                    "user_id",
                  ]);

                  let isActiveUser = false;

                  if (isUserSupplier) {
                    isActiveUser =
                      conversation.messages.length !== 0
                        ? mostRecentMessage.sender_id === supplierUserId
                        : false;
                  } else {
                    isActiveUser =
                      conversation.messages.length !== 0
                        ? mostRecentMessage.sender_id === userId
                        : false;
                  }

                  return (
                    <Conversation
                      actions={[
                        {
                          text: "Archive",
                          onClick: () =>
                            this.props.archiveConversation(conversation),
                        },
                      ]}
                      boldForUnread={
                        !isActiveUser &&
                        this.props.unreadChats.includes(conversation)
                      }
                      key={conversation.label}
                      onClick={() => {
                        this.openUnreadMessage(conversation);
                        this.props
                          .markConversationRead(conversation.label)
                          .then((data) => {
                            isUserSupplier
                              ? this.props.fetchSupplierUnreadMessage()
                              : this.props.fetchHasUnreadMessage();
                          });
                        this.props.history.push(
                          `${inboxPath}/${conversation.label}`
                        );
                      }}
                      conversation={conversation}
                      mostRecentMessage={mostRecentMessage}
                      isActiveConversation={
                        conversation.label === conversationLabel
                      }
                    />
                  );
                })}
            </ScrollableContainer>
          }
        >
          <ArchivedConversations
            archivedConversations={this.props.archivedConversations}
          />
        </Show>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: getSettings(state),
    supplierSettings: getSupplierSettings(state),
    isFetching: getIsFetching(state),
    archivedConversations: getArchivedConversations(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      markConversationRead,
      fetchHasUnreadMessage,
      fetchSupplierUnreadMessage,
      archiveConversation,
      unarchiveConversation,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Conversations)
);
