import {
  ADD_CONFIRMATION_MODAL,
  REMOVE_CONFIRMATION_MODAL,
  RESET_SETTINGS,
} from "./Actions";

export const addConfirmationModal = (confirmationData) => ({
  type: ADD_CONFIRMATION_MODAL,
  payload: {
    confirmationData,
  },
});

export const removeConfirmationModal = () => ({
  type: REMOVE_CONFIRMATION_MODAL,
});

export const clearSettings = () => ({
  type: RESET_SETTINGS,
});
