import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: ${(props) =>
      props.flexDirection ? props.flexDirection : "row"};
  }
  ${(p) => p.justify && "justify-content: center;"}
  ${(p) => p.align && "align-items: center;"}
  ${(props) => props.gap && `gap: ${props.gap};`}
`;
Row.displayName = "Row";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.justify && "justify-content: center;"}
  ${(p) => p.align && "align-items: center;"}
  ${(props) => props.gap && `gap: ${props.gap};`}
`;
Column.displayName = "Column";

export const BasicCategoryItemsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
`;

export const ProductsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (max-width: 1515px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  ${(p) =>
    p.productCount &&
    p.productCount < 4 &&
    "grid-template-columns: 300px 300px 300px;"}
`;
