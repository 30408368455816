import React, { Component } from "react";
import ButtonNew from "../../../../../../components/ButtonNew";
import alert from "../../../../../../images/alert.png";
import styled from "styled-components";
import { theme } from "../../../../../../modules/Theme";

export const ProductWrapper = styled.div`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 30px;
  width: 100%;
  ${(p) => p.padding && "padding: 15px;"}
`;

export const ILProductColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ILProductRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    props.borderTop &&
    "border-top: 1px solid " +
      theme.colors.lightBorder +
      "; padding-top: 15px; margin-top: 15px;"};
  ${(props) =>
    props.borderBottom &&
    "border-bottom: 1px solid " +
      theme.colors.lightBorder +
      ";  margin-bottom: 20px;"};
`;

export const WarningsHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
`;

export const WarningIcon = styled.img`
  height: 30px;
`;

export const ILProductWarningHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ILProductWarningText = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
`;

class ProductWarnings extends Component {
  render() {
    const { product, setLocalState, saveProduct, hideWarningsAndRemove } =
      this.props;
    const { warnings } = this.props.state;

    return (
      <ProductWrapper padding>
        <ILProductColumn>
          <ILProductRow style={{ marginBottom: "20px" }}>
            <WarningIcon src={alert} />
            <WarningsHeader>Warnings</WarningsHeader>
          </ILProductRow>
          {warnings.map((warning, i) => (
            <ILProductColumn key={i}>
              <ILProductWarningHeader>{warning.name}</ILProductWarningHeader>
              <ILProductWarningText>{warning.message}</ILProductWarningText>
            </ILProductColumn>
          ))}
          <ILProductRow style={{ marginTop: "20px" }}>
            <ButtonNew
              text={product.imported ? "IMPORTED" : "IMPORT ANYWAY"}
              icon={product.imported && "check"}
              type={product.imported ? "main-light" : "main"}
              onClick={() => saveProduct(true)}
              isFetching={product.isSaving || product.isFetching}
              disabled={product.imported}
              extra="margin-right: 20px; width: 255px; width: 255px;"
            />
            <ButtonNew
              text="CLOSE WINDOW"
              type="secondary"
              extra="width: 255px;"
              onClick={() => setLocalState({ warnings: [] })}
            />
            {!product.imported && (
              <ButtonNew
                text="REMOVE FROM IMPORT LIST"
                type="secondary"
                extra="width: 250px; margin-left: 20px;"
                onClick={() =>
                  hideWarningsAndRemove(product.imported_product_id)
                }
              />
            )}
          </ILProductRow>
        </ILProductColumn>
      </ProductWrapper>
    );
  }
}

export default ProductWarnings;
