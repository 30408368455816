import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { theme } from "../../modules/Theme";
import { getSupplierWarnings, getUrlParameter } from "../../modules/Format";
import { Route, Switch, withRouter } from "react-router-dom";
import SettingsCards from "./components/SettingsCards";
import {
  FiFileText,
  FiLayers,
  FiLink2,
  FiMail,
  FiPackage,
  FiSettings,
  FiToggleRight,
  FiTruck,
  FiUserPlus,
} from "react-icons/fi";
import {
  Agreement,
  Billing,
  Integrations,
  Profile,
  Packaging,
  Platforms,
  Shipping,
  Values,
} from "./views/supplier";
import Referrals from "./views/shared/Referrals";
import { Container, SettingsBack, Titlebar } from "../../components";
import { stripeSupplierAuth } from "../../Actions";
import {
  getIsFetching,
  getSettings,
} from "../../views/supplier/SupplierSelectors";
import { get } from "lodash";
import SupplierNotifications from "./views/supplier/SupplierNotifications";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      stripeSupplierAuth,
    },
    dispatch
  ),
});

const defaultProps = { settings: {} };

class SupplierSettings extends Component {
  state = {
    errors: [],
    tab: "",
    request_sent: false,
  };

  componentDidMount = () => {
    const supplier = this.props.settings;
    this.setState((prevState) => ({ ...prevState, ...supplier }));

    const code = getUrlParameter("code", this.props.location);
    const scope = getUrlParameter("scope", this.props.location);
    const error = getUrlParameter("error", this.props.location);

    if (code && scope && !error && !this.state.request_sent) {
      this.setState({ request_sent: true });
      this.props.stripeSupplierAuth({ auth_code: code });
    }
  };

  getTabName = (cards) => {
    let name = null;

    cards.forEach((card) => {
      if (card.route === this.props.match.params.tab) {
        name = card.title;
      }
    });
    return name;
  };

  renderBackButton = () => {
    let tab = get(this.props, ["match", "params", "tab"]);

    const isShipping = get(this.props, ["match", "url"]).includes("shipping");

    if (tab && tab !== "" && !isShipping) {
      return (
        <SettingsBack
          text="Settings"
          back={() => this.props.history.push("/supplier/settings")}
        />
      );
    }
  };

  render() {
    const settings = get(this.props, "settings");
    let tab = get(this.props, ["match", "params", "tab"]);
    const privatePortal = get(settings, "subdomain");
    const dropshipping = get(settings, "dropshipping");

    if (!tab) {
      tab = "";
    }

    const supplierWarnings = getSupplierWarnings(settings);
    let payment = get(supplierWarnings, "payment");

    if (payment === 0) {
      payment = false;
    }

    const path = "/supplier/settings";
    const cards = [
      {
        icon: <FiSettings size="20px" color={theme.colors.medDarkGrey} />,
        title: "Profile",
        description:
          "Adjust your bio, return policy, and other profile settings",
        route: "profile",
        warning:
          get(supplierWarnings, "profile_settings") &&
          "Missing Required Details",
      },
      {
        icon: <FiMail size="20px" color={theme.colors.medDarkGrey} />,
        title: "Notifications",
        description: "Adjust your notification settings",
        route: "notifications",
      },
      {
        icon: <FiLayers size="20px" color={theme.colors.medDarkGrey} />,
        title: "Platform",
        description: "Connect an ecommerce platform to import products",
        route: "platforms",
      },
      {
        icon: <FiToggleRight size="20px" color={theme.colors.medDarkGrey} />,
        title: "Values",
        description:
          "Indicate your brand values and special attributes to stores, like 'made in america' or 'organic'",
        route: "values",
        warning: get(supplierWarnings, "values") && "No Values Set",
      },
      {
        icon: <FiTruck size="20px" color={theme.colors.medDarkGrey} />,
        title: "Shipping",
        description: "Adjust your account-level shipping rates and settings",
        route: "shipping",
        warning:
          get(supplierWarnings, "active_shipping_options") &&
          "No Active Shipping Rates",
      },
      {
        icon: <FiPackage size="20px" color={theme.colors.medDarkGrey} />,
        title: "Packaging & Branding",
        description:
          "Let stores know what to expect in the physical package, such as branding and invoicing",
        route: "packaging",
        warning:
          get(supplierWarnings, "branding_description") &&
          "Missing Branding Description",
      },
      {
        icon: <FiUserPlus size="20px" color={theme.colors.medDarkGrey} />,
        title: "Referrals",
        description: "Refer dropshippers and suppliers to DropCommerce",
        route: "referrals",
        // displayCondition: !privatePortal
      },
      {
        icon: <FiLink2 size="20px" color={theme.colors.medDarkGrey} />,
        title: "Integrations",
        description: "Manage 3rd party integrations like PayPal and Stripe.",
        route: "integrations",
        warning: payment && "Stripe or PayPal Payment Account Required",
      },
      {
        icon: <FiFileText size="20px" color={theme.colors.medDarkGrey} />,
        title: "Supplier Agreement",
        description:
          "Review and complete the Supplier Agreement before your profile goes live",
        route: "agreement",
        warning:
          get(supplierWarnings, "supplier_agreement") &&
          "You must agree to the Supplier Agreement",
        displayCondition: !privatePortal || dropshipping,
      },
    ];

    return (
      <>
        {tab ? (
          <Titlebar title={this.getTabName(cards)} />
        ) : (
          <Titlebar
            title={"Settings"}
            subtitle="Suppliers are responsible for the accuracy of their profiles. Please ensure your settings are correct."
          />
        )}

        <Container>
          {tab === "" && (
            <SettingsCards
              cards={cards.filter((card) => card.title)}
              settingsPath={"/supplier/settings"}
            />
          )}

          {this.renderBackButton()}

          <Switch>
            <Route path={`${path}/billing`} component={Billing} />
            <Route path={`${path}/referrals`} component={Referrals} />
            <Route path={`${path}/platforms`} component={Platforms} />
            <Route
              path={`${path}/notifications`}
              component={SupplierNotifications}
            />
            <Route path={`${path}/profile`} component={Profile} />
            <Route path={`${path}/values`} component={Values} />
            <Route path={`${path}/shipping`} component={Shipping} />
            <Route path={`${path}/packaging`} component={Packaging} />
            <Route path={`${path}/integrations`} component={Integrations} />
            <Route path={`${path}/agreement`} component={Agreement} />
          </Switch>
        </Container>
      </>
    );
  }
}

SupplierSettings.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplierSettings)
);
