import React from "react";
import { Error404 } from "./Error404";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // Could use for logging
  componentDidCatch(error, errorInfo) {
    console.log("logErrorToMyService", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error404 src={this.props.notFoundImage} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
