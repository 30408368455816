import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { fetchStoreApprovalData } from "../../../Actions";
import {
  getStoreApprovalData,
  getIsFetchingApprovalData,
} from "../../../Selectors";
import ApprovalRequirement from "./ApprovalRequirement";
import { get } from "lodash";
import ButtonNew from "../../../components/ButtonNew";
import MDSpinner from "react-md-spinner";
import { getProduct } from "../ProductsSelectors";

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
`;

const Disclaimer = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 30px;
`;

// const RequestButton = styled.div`
//   font-size: 20px;
//   font-weight: 500;
//   margin-top: 40px;
//   padding: 20px;
//   border-radius: 4px;
//   color: white;
//   transition: 0.3s;
//   width: 100%;
//   cursor: pointer;

//   ${(p) =>
//     p.hollow &&
//     `
//     margin-right: 15px;
//     border: 2px solid #485d5a;
//     color: #485d5a;
//   `}

//   ${(p) =>
//     !p.hollow &&
//     `
//     background: #39a698;
//   `}

//   &:hover {
//     background: #485d5a;
//     ${(p) => p.hollow && "color: white;"}
//   }
// `;

const ButtonsRow = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
`;

class ApprovalRequirements extends Component {
  componentDidMount = () => {
    this.props.fetchStoreApprovalData(this.props.supplierId);
  };

  render() {
    const {
      storeApprovalData,
      isFetchingApprovalData,
      hideApprovalRequirements,
      requestApproval,
      supplierId,
      isRequestingApproval,
    } = this.props;

    const supplierName = get(storeApprovalData, ["supplier", "name"]);

    return (
      <Modal>
        {isFetchingApprovalData ? (
          <div style={{ marginTop: "40px" }}>
            <MDSpinner singleColor="rgb(73,190,175)" size={40} />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <React.Fragment>
              <Title>Approval Requirements</Title>

              <Subtitle>
                {`${supplierName} requires stores to request approval to sell their products.`}
              </Subtitle>

              <Disclaimer>
                * Meeting these basic requirements does not guarantee your
                approval by the supplier
              </Disclaimer>
            </React.Fragment>

            <Title style={{ marginTop: "30px" }}>
              {`${supplierName} requires stores to request approval to sell their products`}
            </Title>
            <Subtitle style={{ marginBottom: "30px" }}>
              Improve your chances of being approved by following these
              recommendations:
            </Subtitle>

            <ApprovalRequirement
              title={"Store Is Live"}
              description="If your store is not live and available to the public, suppliers will likely decline your request."
              alert
              pass
            />
            <ApprovalRequirement
              title="Professional Store Appearance"
              alert
              description="Avoid weird colors, blurry images and default themes. Suppliers often reject stores due to these issues, since it makes their store look unprofessional."
              pass
            />
            <ApprovalRequirement
              title="Choose A Niche"
              alert
              description="Suppliers prefer stores who build a brand around a niche or product category instead of online 'general stores'."
              pass
            />

            <ButtonsRow>
              <ButtonNew
                extra="padding: 25px; width: 100%;"
                fontStyle="font-size: 20px;"
                onClick={hideApprovalRequirements}
                type="main-light"
                text="CLOSE WINDOW"
              />

              <ButtonNew
                isFetching={isRequestingApproval}
                extra="padding: 25px; margin-left: 15px;  width: 100%;"
                fontStyle="font-size: 20px;"
                onClick={() => requestApproval(supplierId)}
                type="main"
                text="REQUEST APPROVAL"
              />
            </ButtonsRow>

            <Disclaimer>
              * By requesting approval, I assert that my store meets the above
              requirements.
            </Disclaimer>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  storeApprovalData: getStoreApprovalData(state),
  isFetchingApprovalData: getIsFetchingApprovalData(state),
  isRequestingApproval: getProduct(state).isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchStoreApprovalData }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalRequirements);

// Modal Component
const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  flex-direction: column;
  z-index: 99999;
  text-align: center;
  cursor: auto;
`;

const Container = styled.div`
  display: flex;
  background: ${theme.colors.lightBorder};
  width: 700px;
  height: 100%;
  margin: 20px;
  padding: 50px;
  border-radius: 4px;
  overflow-y: auto;

  @media screen and (max-width: 1260px) {
    width: 100%;
    margin: 5px;
  }
`;

function Modal({ children }) {
  return (
    <PageWrapper>
      <Container>{children}</Container>
    </PageWrapper>
  );
}
