import React from "react";
import { BsEnvelope } from "react-icons/bs";
import { FiMail, FiPhone } from "react-icons/fi";
import styled from "styled-components";
import { Hint, Row, StyledLink } from "../../../../../components";
import { Show } from "../../../../../components/Show";
import { theme } from "../../../../../modules/Theme";
import { Text, Wrapper } from "./sharedStyles";
import { truncateText } from "../../../../../modules/Format";

const ContactList = styled("ul")`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Item = styled("li")`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

export function ContactInfo({ email, phoneNumber, subdomain = "" }) {
  const hint =
    "Please contact support@dropcommerce.com to update your contact information";

  return (
    <Wrapper>
      <Row>
        <Text>Contact</Text>

        <Hint hint={hint} narrow />
      </Row>

      <ContactList>
        <Show when={email}>
          <Item>
            <BsEnvelope color={theme.colors.main} />

            <StyledLink color={theme.colors.main} as="a" to={`mailto:${email}`}>
              {truncateText(email, 32)}
            </StyledLink>
          </Item>
        </Show>

        <Show when={phoneNumber}>
          <Item>
            <FiPhone color={theme.colors.main} />

            <StyledLink
              color={theme.colors.main}
              as="a"
              to={`tel:${phoneNumber}`}
            >
              {phoneNumber}
            </StyledLink>
          </Item>
        </Show>

        <Show when={subdomain && subdomain !== ""}>
          <Item>
            <FiMail color={theme.colors.main} />

            <StyledLink
              target="_blank"
              color={theme.colors.main}
              as="a"
              to={`https://www.${subdomain}.dropcommerce.com`}
            >
              {subdomain}.dropcommerce.com
            </StyledLink>
          </Item>
        </Show>
      </ContactList>
    </Wrapper>
  );
}
