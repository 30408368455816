import React, { Component } from "react";

import Input from "./Input";
import ButtonNew from "./ButtonNew";
import { Row } from "./Layout";
import SelectDropdown from "./SelectDropdown";

import Radio from "@material-ui/core/Radio";
import styled from "styled-components";
import Hint from "./Hint";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 250px;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
`;

class Range extends Component {
  submit = () => {
    this.props.showHideFilter(this.props.range.type);
    this.props.submit();
  };

  clear = () => {
    this.props.resetRange(this.props.range.type);
    this.props.submit();
  };

  rangeDisplayText = (min, max) => {
    if (min && max) {
      return `$${min} - $${max}`;
    }

    if (min && !max) {
      return `$${min}+`;
    }

    if (!min && max) {
      return `Max $${max}`;
    }
  };

  render() {
    const { range, showHideFilter } = this.props;

    const data = this.props.data;

    if (data === undefined) {
      return <Container />;
    } else {
      return (
        <SelectDropdown
          active={
            (!!data.min && data.min !== "") || (!!data.max && data.max !== "")
          }
          displayText={this.rangeDisplayText(data.min, data.max)}
          show={data.show}
          showHideFilter={() => showHideFilter(range.type)}
          name={range.name}
          margin="0 0 0 15px"
          reset={this.clear}
        >
          <Container>
            {/* <PriceRange
              min={data.min}
              max={data.max}
              updateMinMax={updateMinMax}
            /> */}
            <Row>
              {(data.min || data.min === "") && (
                <Input
                  description={`${range.name} Min ${
                    data.show_dollar_symbol ? "$" : ""
                  }`}
                  placeholder="0"
                  type="number"
                  min={0}
                  value={data.min}
                  onChange={(e) =>
                    this.props.updateFilters(
                      range.type,
                      e.target.value,
                      "min",
                      false
                    )
                  }
                />
              )}
              {(data.max || data.max === "") && (
                <Input
                  description={`${range.name} Max ${
                    data.show_dollar_symbol ? "$" : ""
                  }`}
                  placeholder="500"
                  min={0}
                  type="number"
                  marginLeft={!!data.min || data.min === ""}
                  value={data.max}
                  onChange={(e) =>
                    this.props.updateFilters(
                      range.type,
                      e.target.value,
                      "max",
                      false
                    )
                  }
                />
              )}
            </Row>

            {data.description && <Description>{data.description}</Description>}

            {data.optionsType === "radio" &&
              data.options.map((option, i) => {
                return (
                  <Row
                    align
                    style={{ justifyContent: "space-between" }}
                    key={i}
                  >
                    <Row>
                      {option.hint && (
                        <Hint hint={option.hint} margin="0 8px 0 0" />
                      )}
                      <div>{option.label}</div>
                    </Row>
                    <Radio
                      checked={data.optionsValue === option.value}
                      onChange={(e) =>
                        this.props.updateFilters(
                          range.type,
                          e.target.value,
                          "optionsValue",
                          false
                        )
                      }
                      label={option.label}
                      value={option.value}
                      name={option.label}
                      inputProps={{ "aria-label": option.label }}
                    />
                  </Row>
                );
              })}
            {/* {data.optionsType === "checkboxes" &&
              data.options.map((option) => {
                return (
                  <Row align style={{ justifyContent: "space-between" }}>
                    <div>{option.label}</div>
                    <Checkbox
                      checked={data.optionsValue === option.value}
                      onChange={(e) =>
                        update("value", e.target.value === "true", "checkboxes")
                      }
                      value={option.value}
                      inputProps={{ "aria-label": option.label }}
                    />
                  </Row>
                );
              })} */}
            {/* {data.optionsType === "switch" && (
              <Row align style={{ justifyContent: "space-between" }}>
                <div>{data.switch.label}</div>
                <Switch
                  checked={data.optionValue}
                  onChange={() => update("value", !data.optionValue, "switch")}
                  name={data.switch.label}
                  inputProps={{ "aria-label": data.switch.label }}
                />
              </Row>
            )} */}
            <Row style={{ marginTop: "20px" }}>
              <ButtonNew
                type="secondary"
                text="Clear"
                onClick={this.clear}
                margin="0 10px 0 0"
                width="100%"
              />
              <ButtonNew
                type="main"
                text="Apply"
                onClick={this.submit}
                width="100%"
              />
            </Row>
          </Container>
        </SelectDropdown>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Range);
