import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { Row } from "./Layout";
import Hint from "./Hint";
import Text from "../modules/Text";

export const InputLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  ${(props) => props.extraStyle};
`;

export const New = styled.div`
  font-size: 12px;
  background: ${theme.colors.main};
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  margin-left: 10px;
`;

export const ProFeature = styled.div`
  font-size: 12px;
  background: ${theme.colors.orange};
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  margin-left: 10px;
`;

class Label extends Component {
  render() {
    return (
      <Row
        align
        style={{
          marginTop: "20px",
          marginBottom: "4px",
          ...this.props.rowStyle,
        }}
      >
        <InputLabel extraStyle={this.props.extra}>
          {this.props.children}
        </InputLabel>
        {this.props.hint && (
          <Hint
            hint={this.props.hint}
            narrow
            left={this.props.hintLeft}
            link={this.props.hintLink}
          />
        )}
        {this.props.warning && (
          <Hint hint={this.props.warning} type="warning" lessMargin />
        )}
        {this.props.linkAction && this.props.linkText && (
          <Text.Small
            link
            light
            onClick={this.props.linkAction}
            extra="margin-left: 10px;"
          >
            {this.props.linkText}
          </Text.Small>
        )}
        {this.props.new && <New>New</New>}
        {this.props.pro && <ProFeature>Pro Feature</ProFeature>}
      </Row>
    );
  }
}

export default Label;
