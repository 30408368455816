import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createSupplier } from "../Actions";
import { getIsFetching } from "../Selectors";
import Input from "../components/Input";
import ButtonNew from "../components/ButtonNew";
import Label from "../components/Label";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { get, isEmpty } from "lodash";
import Select from "react-select";
import { countryOptions, platformOptions } from "../constants";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

// const StepsRow = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 30px;
// `;

const MainForm = styled.div`
  width: 500px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 800px) {
    font-size: 32px;
    margin-top: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 18px;
    margin-top: 10px;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ createSupplier }, dispatch),
});

const defaultProps = {
  isFetching: false,
};

class Registration extends Component {
  state = {
    step: 2,

    // STEP 1
    name: "",
    website: "",
    email: "",
    phone: "",
    country: "United States",
    platform: "Shopify",
    city: "",

    // STEP 2
    currency: "USD",
    discount: 40,
    shipping: false,
    errors: {},
    warnings: {},
  };

  checkBasicInfoFields = () => {
    const { name, phone, website, email, city, platform } = this.state;

    let errors = {};

    if (phone.length < 10) {
      errors.phone = "Please enter a valid phone number";
    }

    if (platform === "") {
      errors.platform = "Please select an option";
    }

    if (name.length < 3) {
      errors.name = "Please enter a valid brand name";
    }

    if (name.includes("LLC") || name.includes("INC")) {
      errors.name =
        "Please use your customer-facing brand name. Do not include LLC or INC";
    }

    if (website.length < 3 || !website.includes(".")) {
      errors.website = "Please enter a valid website URL";
    }

    if (city.length < 3) {
      errors.city = "Please enter a valid city";
    }

    if (email.length < 3 || !email.includes("@") || !email.includes(".")) {
      errors.email = "Please enter a valid email address";
    }

    if (isEmpty(errors)) {
      this.setState({ step: 2, errors });
    } else {
      this.setState({ errors });
    }
  };

  fieldValidation = () => {
    const { discount, shipping } = this.state;

    let errors = {};
    let warnings = {};

    if (discount < 30) {
      errors.discount =
        "Please enter a discount at 30% or higher (numbers only, no % symbol)";
    }

    if (!shipping) {
      errors.shipping = "You must ship from either the US or Canada.";
    }

    this.setState({ errors, warnings });

    return errors;
  };

  checkPricingFields = () => {
    const errors = this.fieldValidation();

    if (isEmpty(errors)) {
      this.props.createSupplier(this.state).then((response) => {
        if (response.token) {
          this.setState({ step: 3 });
        }
        if (get(response, "error") === "username_exists") {
          this.setState({
            errors: {
              general:
                "A user with this email address already exists. Please login to your existing account.",
            },
          });
        }
        if (get(response, "error") === "unknown") {
          this.setState({
            errors: {
              general:
                "Error creating supplier account. Please contact support at support@dropcommerce.com",
            },
          });
        }
      });
    }
  };

  updateRate = (type, rate) => {
    let newRate = parseFloat(rate);

    if (isNaN(newRate)) {
      newRate = 0.0;
    }

    this.setState({ [type]: newRate }, this.fieldValidation);
  };

  render() {
    const { errors } = this.state;

    return (
      <PageWrapper>
        <CenterDiv>
          <Title>Start Your Dropship Program Today</Title>
          <Subtitle>Complete the form below to get started.</Subtitle>

          {this.state.step !== 3 && (
            <React.Fragment>
              <Text.Small
                light
                link
                center
                extra="margin-bottom: 10px; margin-top: 20px;"
                onClick={() => this.props.history.push("/login")}
              >
                Already have an account? Click here to login.
              </Text.Small>
            </React.Fragment>
          )}
        </CenterDiv>

        <MainForm>
          <div>
            <Input
              onChange={(t) => this.setState({ name: t.target.value })}
              placeholder="Brand / Company Name"
              extraStyle="margin-bottom: 5px;"
              error={errors.name}
              value={this.state.name}
            />
            <Input
              onChange={(t) => this.setState({ website: t.target.value })}
              placeholder="Website URL"
              extraStyle="margin-bottom: 5px;"
              error={errors.website}
              value={this.state.website}
            />
            <Input
              onChange={(t) => this.setState({ name: t.target.value })}
              placeholder="Your Name"
              extraStyle="margin-bottom: 5px;"
              error={errors.name}
              value={this.state.name}
            />
            <Input
              onChange={(t) => this.setState({ phone: t.target.value })}
              placeholder="Phone Number"
              extraStyle="margin-bottom: 5px;"
              error={errors.phone}
              value={this.state.phone}
            />
            <Input
              onChange={(t) => this.setState({ email: t.target.value })}
              placeholder="Email Address"
              extraStyle="margin-bottom: 5px;"
              error={errors.email}
              value={this.state.email}
            />
            <Input
              onChange={(t) => this.setState({ city: t.target.value })}
              placeholder="City"
              extraStyle="margin-bottom: 5px;"
              error={errors.city}
              value={this.state.city}
            />

            <Label>Country</Label>
            <div style={{ width: "100%" }}>
              <Select
                value={{
                  value: this.state.country,
                  label: this.state.country,
                }}
                options={countryOptions}
                onChange={(data) => this.setState({ country: data.value })}
              />
            </div>
            <Label>Ecommerce Platform</Label>
            <div style={{ width: "100%" }}>
              <Select
                value={{
                  value: this.state.platform,
                  label: this.state.platform,
                }}
                options={platformOptions}
                onChange={(data) => this.setState({ platform: data.value })}
              />
            </div>
            <ButtonNew
              text="Next Step"
              type="main"
              extra="margin-top: 15px; padding: 15px;"
              fontStyle="font-size: 20px;"
              onClick={this.checkBasicInfoFields}
            />
          </div>
        </MainForm>
      </PageWrapper>
    );
  }
}

Registration.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registration)
);
