import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Text from "../../../modules/Text";
import {
  listToCommaString,
  getUrlParameter,
  objectToQueryString,
  getActiveFilters,
} from "../../../modules/Format";
import Card from "../../../components/Card";
import CategoriesNew from "../../../components/CategoriesNew";
import Search from "../../../components/Search";
import NoResults from "../../../components/NoResults";

import { fetchSuppliers } from "../../../views/products/ProductsActions";
import {
  getSuppliers,
  getIsFetchingSuppliers,
  getPagination,
} from "../../../views/products/ProductsSelectors";
import Pagination from "../../../components/Pagination";
import { Container } from "../../../components/Container";
import styled from "styled-components";
import {
  updateFilters,
  initializeFilters,
  showHideFilter,
  updateMoreFilters,
  resetMoreFilters,
  resetRange,
} from "../../../Actions";
import { getSettings, getFilters } from "../../../Selectors";
import canadaIcon from "../../../images/canadian-flag.png";
import USIcon from "../../../images/american-flag.png";
import Range from "../../../components/Range";
import MoreFilters from "../../../components/MoreFilters";
import { FilterSearchRow, FiltersRow } from "../../../views/products/Products";
import MDSpinner from "react-md-spinner";
import { FiHeart } from "react-icons/fi";
import FilledHeart from "../../../images/icons/filled-heart.svg";
import { Show } from "../../../components/Show";
import { DropdownHeader } from "../../../components/SelectDropdown";

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingSuppliers(state),
  suppliers: getSuppliers(state),
  pagination: getPagination(state),
  settings: getSettings(state),
  filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchSuppliers,
      updateFilters,
      initializeFilters,
      showHideFilter,
      updateMoreFilters,
      resetMoreFilters,
      resetRange,
    },
    dispatch
  ),
});

const defaultProps = {
  isFetching: false,
  suppliers: [],
};

// const sortingItems = [
//   { key: "default", label: "Default" },
//   { key: "favorite", label: "Most Favorite" },
//   { key: "sold", label: "Most Sold" },
//   { key: "imported", label: "Most Imported" },
// ];

class Suppliers extends Component {
  state = { isFavourite: false };

  componentDidMount = () => {
    this.props
      .initializeFilters(this.props.filters, this.props.location)
      .then((filters) => {
        this.fetchSuppliers(filters, false);
      });
  };

  fetchSuppliers = (filterData = null, updateUrl = true) => {
    let f = filterData || this.props.filters;
    const moreFilters = f.moreFilters;

    const data = {
      category: f.category,
      subcategory: f.subcategory,
      page: f.page,
      search: f.search,
      sorting: f.sorting,
      filters: listToCommaString(getActiveFilters(moreFilters)),
      price_min: f.price.min,
      price_max: f.price.max,
      price_type: f.price.optionsValue,
      profit_min: f.profit.min,
      shipping_max: f.shipping.max,
      shipping_country: f.shipping.optionsValue,
    };
    if (f.favourite) {
      data.favourite = true;
    } else {
      delete data.favourite;
    }

    const queryString = filterData === "" ? "" : objectToQueryString(data);
    this.props.fetchSuppliers(queryString);

    if (updateUrl) {
      this.props.history.push(`/suppliers/${queryString}`);
    }
  };

  clear = () =>
    this.props.updateFilters("search", "").then(this.fetchSuppliers);

  getAll = () => {
    this.setState({ isFavourite: false });
    this.props.updateFilters("favourite", false).then(this.fetchSuppliers);
  };

  getFavourites = () => {
    this.setState({ isFavourite: true });
    this.props.updateFilters("favourite", true).then(this.fetchSuppliers);
  };

  render() {
    const search = getUrlParameter("search", this.props.location) || "";

    return (
      <Container style={{ height: "fit-content" }}>
        <Text.Large extra="margin-bottom: 20px;">Suppliers</Text.Large>
        <FilterSearchRow>
          <Search
            onChange={(value) => this.props.updateFilters("search", value)}
            onEnterPress={(e) => {
              if (e.key !== "Enter") return;
              this.fetchSuppliers();
            }}
            search={this.props.filters.search}
            placeholder="Search suppliers"
            clear={this.clear}
            initialSearchTerm={search}
            searchForProducts={() => this.fetchSuppliers()}
          />

          <FiltersRow>
            {/* <SortingDropdown
              items={sortingItems}
              submit={this.fetchSuppliers}
            /> */}

            <DropdownHeader
              onClick={
                this.state.isFavourite ? this.getAll : this.getFavourites
              }
              style={{ gap: "10px" }}
            >
              <span>Favorites</span>

              <Show
                fallback={
                  <MDSpinner singleColor="rgba(0, 0, 0, 0.3)" size={22} />
                }
                when={!this.props.isFetching}
              >
                {this.state.isFavourite ? (
                  <img
                    alt="A Filled Heart"
                    src={FilledHeart}
                    width="22px"
                    height="22px"
                  />
                ) : (
                  <FiHeart size="22px" color="rgba(0, 0, 0, 0.3)" />
                )}
              </Show>
            </DropdownHeader>

            <Range
              range={{ name: "Shipping Rate", type: "shipping" }}
              submit={this.fetchSuppliers}
              showHideFilter={this.props.showHideFilter}
              updateFilters={this.props.updateFilters}
              resetRange={this.props.resetRange}
              data={this.props.filters.shipping}
            />

            <MoreFilters
              name="Filters"
              submit={this.fetchSuppliers}
              showHideFilter={this.props.showHideFilter}
              updateMoreFilters={this.props.updateMoreFilters}
              resetMoreFilters={this.props.resetMoreFilters}
              moreFilters={this.props.filters.moreFilters}
            />
          </FiltersRow>
        </FilterSearchRow>

        <CategoriesProductsRow>
          <CategoriesNew
            category={this.props.filters.category}
            subcategory={this.props.filters.subcategory}
            clearCategory={() =>
              this.props.updateFilters("category", "").then(this.fetchSuppliers)
            }
            setCategory={(category, type) =>
              this.props.updateFilters(type, category).then(this.fetchSuppliers)
            }
          />
          <div style={{ width: "100%" }}>
            <SuppliersRow>
              {this.props.isFetching ? (
                <React.Fragment>
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                  <Card.Skeleton />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.props.suppliers.length > 0 ? (
                    this.props.suppliers.map((supplier) => {
                      let store = null;
                      if (this.props.settings.store) {
                        store = this.props.settings.store.id;
                      }
                      return (
                        <Card
                          supplierID={supplier.id}
                          key={supplier.id}
                          store={store}
                          image={supplier.header_image}
                          name={supplier.name}
                          subtext={`${supplier.city}, ${supplier.country}`}
                          favorite={supplier.favourite}
                          icon={
                            supplier.country === "Canada" ? canadaIcon : USIcon
                          }
                          onClick={() =>
                            this.props.history.push(
                              `/supplier-id/${supplier.id}`
                            )
                          }
                        />
                      );
                    })
                  ) : (
                    <NoResults
                      title="No suppliers fit this criteria"
                      message="Please contact support@dropcommerce.com for support"
                      buttonLink={this.clear}
                      buttonText="SHOW ALL SUPPLIERS"
                    />
                  )}
                </React.Fragment>
              )}
            </SuppliersRow>
            {!this.props.isFetching && (
              <Pagination
                onClick={(page) =>
                  this.props
                    .updateFilters("page", page)
                    .then(this.fetchSuppliers)
                }
                pagination={this.props.pagination}
              />
            )}
          </div>
        </CategoriesProductsRow>
      </Container>
    );
  }
}

Suppliers.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Suppliers)
);

const CategoriesProductsRow = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const SuppliersRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;
