import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import { connect } from "react-redux";
import styled from "styled-components";
import { ButtonNew, Row } from "../../components";
import Text from "../../modules/Text";
import { theme } from "../../modules/Theme";
import { getIsFetching } from "../../Selectors";
import { Modal } from "../Modal";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
`;

function ConfirmationModal({ data, isFetching }) {
  const { warning, confirm, hide } = data;

  return (
    <Modal hide={hide} maxWidth="500px">
      <Wrapper>
        <FiAlertCircle color={theme.colors.orange} size={70} />
        <Text.Small
          s
          style={{
            margin: "20px 0 30px 0",
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {warning}
        </Text.Small>

        <Row style={{ gap: "15px" }}>
          {confirm && (
            <ButtonNew
              text="Confirm"
              onClick={confirm}
              isFetching={isFetching}
            />
          )}

          {hide && <ButtonNew text="Cancel" onClick={hide} type="secondary" />}
        </Row>
      </Wrapper>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
});

export default connect(mapStateToProps)(ConfirmationModal);
