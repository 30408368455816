import axios from "axios";
import Cookies from "js-cookie";
import { api } from "../modules/Config";

export const axiosInstance = axios.create({
  baseURL: api,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("accessToken");
    const storeId = Cookies.get("storeId");

    // Set headers
    config.headers = {
      ...config.headers,
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };

    // Conditionally inject storeId in params or body based on request method
    if (storeId) {
      if (config.method === "get") {
        config.params = { ...config.params, store: storeId };
      } else {
        config.data = { ...config.data, store: storeId };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // console.log({ response });

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
