import React, { Component } from "react";
import styled from "styled-components";

import check from "../images/icons/feather/check-green.svg";
import closeIcon from "../images/icons/feather/x-circle.svg";
import { theme } from "../modules/Theme";
import ButtonNew from "./ButtonNew";

const Background = styled.div`
  background: white;
  padding: 60px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 30px;
  }
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const Bubble = styled.div`
  background: white;
  padding: 35px;
  width: 100%;
  border-radius: 5px;
  max-width: 800px;
  margin: 0 auto;
`;

const SpecialOfferTitle = styled.div`
  font-size: 40px;
  color: ${theme.colors.main};
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 14px;
  }
`;

const SpecialOfferBenefit = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const SpecialOfferLink = styled.div`
  font-size: 14px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 30px;
`;

const SpecialOfferDescription = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

// const Button = styled.div`
//   padding: 20px;
//   background: ${theme.colors.orange};
//   border-radius: 5px;
//   cursor: pointer;
//   color: white;
//   font-size: 24px;
//   font-weight: 400;
//   margin: 10px;
//   text-align: center;
// `;

const CheckmarkRow = styled.div`
  display: flex;
`;

const CloseIcon = styled.img`
  opacity: 0.7;
  height: 26px;
  cursor: pointer;
  @media (max-width: 800px) {
    height: 20px;
  }
`;

const Checkmark = styled.img`
  height: 26px;
  margin-right: 10px;
  @media (max-width: 800px) {
    height: 20px;
    margin-right: 8px;
  }
`;

const CheckmarkSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

class SpecialOffer extends Component {
  state = { loading: false };

  claimOffer = () => {
    this.setState({ loading: true }, this.props.claimOffer());
  };

  render() {
    const offerData = this.props.details;

    return (
      <Background>
        <Bubble>
          <CloseIcon onClick={this.props.hideOffer} src={closeIcon} />
          <SpecialOfferTitle>{offerData.title}</SpecialOfferTitle>
          <SpecialOfferDescription>
            {offerData.description}
          </SpecialOfferDescription>
          <ButtonRow>
            <ButtonNew
              isFetching={this.state.loading}
              size="large"
              text="CLAIM OFFER"
              onClick={this.claimOffer}
            />
          </ButtonRow>
          <SpecialOfferDescription>
            {offerData.features_header}
          </SpecialOfferDescription>

          <CheckmarkSection>
            <CheckmarkRow>
              <Checkmark src={check} />
              <SpecialOfferBenefit>{offerData.feature_1}</SpecialOfferBenefit>
            </CheckmarkRow>

            <CheckmarkRow>
              <Checkmark src={check} />
              <SpecialOfferBenefit>{offerData.feature_2}</SpecialOfferBenefit>
            </CheckmarkRow>

            <CheckmarkRow>
              <Checkmark src={check} />
              <SpecialOfferBenefit>{offerData.feature_3}</SpecialOfferBenefit>
            </CheckmarkRow>

            <CheckmarkRow>
              <Checkmark src={check} />
              <SpecialOfferBenefit>{offerData.feature_4}</SpecialOfferBenefit>
            </CheckmarkRow>
          </CheckmarkSection>

          <SpecialOfferLink>{offerData.disclaimer}</SpecialOfferLink>
        </Bubble>
      </Background>
    );
  }
}

export default SpecialOffer;
