import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";
import { get } from "lodash";
import { theme } from "../../../../../modules/Theme";
import MinimizedProduct from "./MinimizedProduct";
import Product from "./Product";
import {
  fetchImportListProduct,
  removeFromImportList,
  toggleSelectImportedProduct,
} from "../../../../../views/products/ProductsActions";
import { getSettings } from "../../../../../Selectors";

const mapStateToProps = (state) => ({ settings: getSettings(state) });

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchImportListProduct,
      removeFromImportList,
      toggleSelectImportedProduct,
    },
    dispatch
  ),
});

export const PageWrapper = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  ${(p) => p.padding && "padding: 15px;"}
`;

class ProductWrapper extends Component {
  render() {
    const showBulkImports = get(this.props, [
      "settings",
      "store",
      "show_bulk_imports",
    ]);
    const { product, showModal, checked } = this.props;
    return (
      <div style={{ width: "100%", display: "inline-flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {showBulkImports && !product.imported && (
            <Checkbox
              onChange={(e) =>
                this.props.toggleSelectImportedProduct(
                  product.imported_product_id
                )
              }
              checked={checked}
            />
          )}
        </div>
        {product.expanded && !product.imported ? (
          <Product key={product.id} product={product} showModal={showModal} />
        ) : (
          <PageWrapper
            index={this.props.index}
            onClick={() =>
              product.imported
                ? null
                : this.props.fetchImportListProduct(product.imported_product_id)
            }
          >
            <MinimizedProduct product={product} minimized />
          </PageWrapper>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWrapper);
