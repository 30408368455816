import React from "react";
import Styled from "styled-components";
import {
  ButtonNew,
  Container,
  Row,
  Spinner,
  BackNavBar,
} from "../../../components";
import Text from "../../../modules/Text";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { postRequest, getRequest } from "../../../modules/API";
import createNotification from "../../../modules/Notification";

const VendorDetailsWrapper = Styled.div`
  width: 384px;
`;

// const VendorDetailsContainer = Styled.div`
//   border: 1px solid rgba(0,0,0,0.1);
//   padding: 15px;
//   border-radius: 5px;
//   margin-top: 20px;
// `;

class VendorDetails extends React.Component {
  state = {
    isFetching: true,
    saved: false,
    order_volume: 0,
  };

  componentDidMount() {
    const vendorId = get(this.props, ["match", "params", "vendorId"]);

    getRequest({
      url: `api/vendors/${vendorId}/`,
    })
      .then((response) => {
        this.setState({
          ...response.data,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isFetching: false });
      });
  }

  discountUpdated = (e) => {
    this.setState({ discount_rate: e.target.value, changed: true });
  };

  saveVendorData = () => {
    postRequest({
      url: `api/vendors/`,
      data: this.state,
    })
      .then((response) => {
        this.setState({ changed: false });
        createNotification({
          type: "success",
          title: "Success",
          message: "Data saved",
        });
      })
      .catch((error) => console.log("error", error));
  };

  updateVendorStatus = (status) => {
    this.setState({ status });
    const vendorId = get(this.props, ["match", "params", "vendorId"]);
    const endpointType = status === "APPROVED" ? "approve" : "deny";

    postRequest({
      url: `api/vendors/${vendorId}/${endpointType}/`,
    })
      .then((response) => {
        this.setState({
          ...response.data,
          isFetching: false,
        });
      })
      .catch((error) => console.log("error", error));
  };

  renderButtons = (status) => {
    if (status === "PENDING") {
      return (
        <Row>
          {this.state.store && (
            <ButtonNew
              text="APPROVE"
              onClick={() => this.updateVendorStatus("APPROVED")}
              margin="0 10px 0 0"
            />
          )}
          <ButtonNew
            text="DENY ACCESS"
            onClick={() => this.updateVendorStatus("DENIED")}
          />
        </Row>
      );
    } else if (status === "APPROVED") {
      return (
        <Row>
          <ButtonNew text="APPROVED" disabled margin="0 10px 0 0" />
          <ButtonNew
            text="REVOKE ACCESS"
            onClick={() => this.updateVendorStatus("DENIED")}
          />
        </Row>
      );
    } else if (status === "DENIED") {
      return (
        <Row>
          <ButtonNew
            text="APPROVE ACCESS"
            onClick={() => this.updateVendorStatus("APPROVED")}
            margin="0 10px 0 0"
          />
          <ButtonNew text="DENIED" disabled />
        </Row>
      );
    }
  };

  render() {
    const vendorId = get(this.props, ["match", "params", "vendorId"]);

    return (
      <Container>
        {this.state.isFetching ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <BackNavBar
              title="Vendors"
              onClick={() => this.props.history.goBack()}
            />

            <Row style={{ marginBottom: "20px" }}>
              <Text.Large>{this.state.name}</Text.Large>
              <ButtonNew
                text="SAVE CHANGES"
                width="180px"
                disabled={!this.state.changed}
                onClick={this.saveVendorData}
                margin="0 0 0 30px"
              />
            </Row>

            {this.state.store && vendorId && (
              <Text
                link
                light
                margin="0 0 10px 0"
                onClick={() =>
                  this.props.history.push(
                    `/supplier/orders?vendor_id=${vendorId}`
                  )
                }
              >
                View Store Orders
              </Text>
            )}

            <VendorDetailsWrapper>
              {!this.state.store && (
                <Text green margin="0 0 20px 0">
                  This vendor has not registered yet.
                </Text>
              )}

              {this.state.store && (
                <Text bold margin="0 0 20px 0">
                  Order Volume: ${this.state.order_volume.toFixed(2)}
                </Text>
              )}

              <Row style={{ marginTop: "20px" }}>
                {this.renderButtons(this.state.status)}
              </Row>

              {/* <VendorDetailsContainer>
                <label htmlFor="discountInput">Discount %</label>
                <Input
                  htmlName="discountInput"
                  width="50%"
                  type="text"
                  value={this.state.discount_rate}
                  onChange={this.discountUpdated}
                />
              </VendorDetailsContainer> */}
            </VendorDetailsWrapper>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default withRouter(VendorDetails);
