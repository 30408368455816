import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getLocalState,
  getSettings,
} from "../../../../views/supplier/SupplierSelectors";
import { fetchSupplierOrders } from "../../../../views/supplier/SupplierActions";
import { postRequestCSV } from "../../../../modules/API";
import { get } from "lodash";
import {
  OrdersStripeWarning,
  OrdersStripeWarningText,
} from "../../../../views/orders/OrdersStyle.jsx";
import Text from "../../../../modules/Text";
import {
  Container,
  ButtonNew,
  Table,
  Row,
  Search,
} from "../../../../components";
import { getUrlParameter } from "../../../../modules/Format";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierState: getLocalState(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchSupplierOrders }, dispatch),
});

class Orders extends Component {
  state = { searchTerm: "" };

  componentDidMount = () => {
    const page = getUrlParameter("page", this.props.location) || 1;
    const searchTerm = getUrlParameter("search", this.props.location) || "";
    const status = getUrlParameter("status", this.props.location) || "paid";
    this.props.fetchSupplierOrders(page, searchTerm, status);
    this.setState({ searchTerm });
  };

  clearSearch = () => {
    const status = getUrlParameter("status", this.props.location) || "paid";
    this.props.fetchSupplierOrders(1, "", status);
    this.props.history.push(`/supplier/orders?status=${status}&page=1&search=`);
    this.setState({ searchTerm: "" });
  };

  exportOrders = () => {
    const supplierId = this.props.settings.id;
    postRequestCSV({ url: `orders/`, data: { supplier: supplierId } });
  };

  searchForOrders = (value) => {
    if (value === "") {
      this.clearSearch();
    } else {
      let searchTerm = this.state.searchTerm;
      const status = getUrlParameter("status", this.props.location) || "paid";
      this.props.fetchSupplierOrders(1, searchTerm, status);
      this.props.history.push(
        `/supplier/orders?status=${status}&page=1&search=${searchTerm}`
      );
      this.setState({ searchTerm });
    }
  };

  onChange = (term) => {
    this.setState({ searchTerm: term });
  };

  onEnterPress = (e) => {
    if (e.key === "Enter") {
      this.searchForOrders();
    }
  };

  render() {
    const noResultsData = {
      title: "There are no orders here.",
      message: "Once you receive an order it will show up on this page.",
      buttonText: "RETURN TO PRODUCTS",
      onClick: () => this.props.history.push("/supplier/products"),
    };

    let initialSearchTerm =
      getUrlParameter("search", this.props.location) || "";

    const columns = [
      { field: "name", name: "Name", width: "180px" },
      {
        field: "created_at",
        name: "Date Received",
        width: "180px",
        type: "date",
      },
      {
        field: "status",
        name: "Status",
        width: "180px",
        statusMap: {
          paid: "yellow",
          shipped: "green",
          cancelled: "grey",
        },
        labelMap: {
          paid: "Paid",
          shipped: "Shipped",
          cancelled: "Cancelled",
        },
      },
      { field: "customer", name: "Customer", width: "300px" },
    ];

    const tabs = [
      {
        name: "All",
        key: "all",
      },
      {
        name: "Paid",
        key: "paid",
      },
      {
        name: "Shipped",
        key: "shipped",
      },
      {
        name: "Cancelled",
        key: "cancelled",
      },
    ];

    return (
      <Container>
        <Row
          align
          style={{ justifyContent: "space-between", marginBottom: "30px" }}
        >
          <div>
            <Text.Large>Orders</Text.Large>
          </div>
          <div>
            <ButtonNew
              className="grid-child export-orders"
              text="Export Orders"
              onClick={this.exportOrders}
            />
          </div>
        </Row>
        {get(this.props.settings, ["supplier"]) &&
          !get(this.props.settings, ["supplier", "stripe_id_saved"]) && (
            <OrdersStripeWarning>
              <OrdersStripeWarningText>
                You will not be able to get paid until you connect a Stripe
                account. Please connect one now.
              </OrdersStripeWarningText>
              <ButtonNew
                text="GO TO SETTINGS"
                type="main"
                extra="width: 255px;"
                onClick={() => this.props.history.push("/settings")}
              />
            </OrdersStripeWarning>
          )}
        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: "40px",
            flexWrap: "wrap",
          }}
        >
          <Search
            placeholder="Search orders"
            onChange={this.onChange}
            onEnterPress={this.onEnterPress}
            search={this.state.searchTerm}
            searchForProducts={this.searchForOrders}
            initialSearchTerm={initialSearchTerm}
            clear={() => this.searchForOrders("")}
          />
        </Row>
        <Table
          hideMobile={["Status"]}
          columns={columns}
          action={{
            endpoint: "/supplier_orders/",
            mixpanelName: "View Supplier Orders",
            fields: ["supplier_orders", "pagination"],
            queryParams: [
              { name: "search", default: "" },
              { name: "page", default: 1, reset: true },
              { name: "vendor_id", default: "", reset: true },
              {
                name: "status",
                default: "paid",
                reset: true,
                useForTabs: true,
              },
            ],
          }}
          tabs={tabs}
          tabsRoute="/supplier/orders"
          reducer="supplier"
          baseState={this.props.supplierState}
          rowsLocation={["supplier_orders"]}
          noResultsData={noResultsData}
          rowBodyOnClick={(orderId) =>
            this.props.history.push(`/supplier/order/${orderId}`)
          }
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
