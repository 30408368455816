import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getLocalState,
  getSettings,
} from "../../../views/supplier/SupplierSelectors";
import { addVendorToList } from "../../../views/supplier/SupplierActions";
import { Table, Container } from "../../../components";

const mapStateToProps = (state) => ({
  baseState: getLocalState(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      addVendorToList,
    },
    dispatch
  ),
});

class Vendors extends Component {
  render() {
    const columns = [
      { field: "name", name: "Name", width: "300px" },
      {
        field: "status",
        name: "Status",
        width: "180px",
        statusMap: {
          PENDING: "yellow",
          APPROVED: "green",
          CANCELLED: "grey",
          DENIED: "grey",
        },
        labelMap: {
          PENDING: "Pending",
          APPROVED: "Approved",
          CANCELLED: "Cancelled",
          DENIED: "Denied",
        },
      },
      {
        field: "date",
        name: "Joined",
        width: "180px",
        type: "date",
      },
    ];

    const tabs = [
      {
        name: "All",
        key: "ALL",
      },
      {
        name: "Pending",
        key: "PENDING",
      },
      {
        name: "Approved",
        key: "APPROVED",
      },
      {
        name: "Denied",
        key: "DENIED",
      },
      {
        name: "Cancelled",
        key: "CANCELLED",
      },
    ];

    return (
      <Container>
        <Table
          columns={columns}
          action={{
            endpoint: "/api/vendors/",
            mixpanelName: "View Vendors",
            fields: ["vendors", "pagination"],
            queryParams: [
              { name: "page", default: 1, reset: true },
              {
                name: "status",
                default: "ALL",
                reset: true,
                useForTabs: true,
              },
            ],
          }}
          tabs={tabs}
          tabsRoute="/supplier/vendors/private"
          reducer="supplier"
          baseState={this.props.baseState}
          rowsLocation={["vendors"]}
          noResultsData={{
            title: "You don't have any private vendors yet.",
            message: "Invite vendors to your private portal to get started.",
          }}
          rowBodyOnClick={(vendorId) =>
            this.props.history.push(`/supplier/vendor/${vendorId}`)
          }
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
