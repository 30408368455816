import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";

const HeaderContainer = styled("div")`
  background-color: ${theme.colors.main};
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const Title = styled("h2")`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;

  @media screen and (min-width: 640px) {
    font-size: 20px;
  }
`;

const Status = styled("span")`
  font-size: 14px;
  font-weight: bold;
`;

export function Header({ title, status }) {
  return (
    <HeaderContainer>
      <Title>{title}</Title>

      <Status>{status}</Status>
    </HeaderContainer>
  );
}
