import { Provider as ReduxProvider } from "react-redux";
import React, { Component } from "react";
import BaseApp from "./BaseApp";
import PrebuiltApp from "./PrebuiltApp";
import { getAppName } from "./modules/Format";
import "./App.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "./modules/Theme";
import { store } from "./baseStore";

const appName = getAppName();

class App extends Component {
  render() {
    return (
      <ReduxProvider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          {appName === "PREBUILT" ? <PrebuiltApp /> : <BaseApp />}
        </MuiThemeProvider>
      </ReduxProvider>
    );
  }
}

export default App;
