import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { updateStateDirectly, updateSettings } from "../../../../Actions";
import { getSettings } from "../../../../Selectors";
import SettingsItem from "../../SettingsItem";
import ButtonNew from "../../../../components/ButtonNew";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import Switch from "../../../../components/Switch";
import { Row } from "../../../../components/Layout";
import Text from "../../../../modules/Text";
import {
  SettingsPageWrapper,
  SettingsSubWrapper,
} from "../../../../views/settings/SettingsStyle";
import { getUrlSubdomain } from "../../../../modules/Format";
import { FiPlus } from "react-icons/fi";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateStateDirectly,
      updateSettings,
    },
    dispatch
  ),
});

export const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

export const StoreLogo = styled.img`
  height: 70px;
  max-width: 300px;
  object-fit: contain;
  border: 1px solid ${theme.colors.lightBorder};
`;

export const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid ${theme.colors.lightBorder};
  height: 80px;
  max-width: 800px;
  margin-top: 5px;
  resize: none;

  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const PreviewModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  flex-direction: column;
  z-index: 9999;
  cursor: auto;
  overflow-y: auto;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

export const InvoiceHTML = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  width: 1000px;
  padding: 30px;
  margin-bottom: 30px;
`;

class BrandedInvoicing extends Component {
  state = {
    changed: false,
    saved: false,
    invoiceHtml: null,
  };

  showWidget = () => {
    const uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dropcommerce",
        uploadPreset: "hjeazqpb",
      },
      (error, result) => this.saveUpload(error, result)
    );

    uploadWidget.open();
  };

  removeLogo = () => {
    this.setState({
      isRemovingLogo: true,
    });

    this.props.updateSettings({ invoice_logo: "" }).then(() => {
      this.updateStore("invoice_logo", "");
      this.setState({ isRemovingLogo: false });
    });
  };

  saveUpload = (error, result) => {
    if (!error && result && result.event === "success") {
      this.setState({
        isSavingLogo: true,
      });

      this.props
        .updateSettings({ invoice_logo: result.info.secure_url })
        .then(() => {
          this.updateStore("invoice_logo", result.info.secure_url);
          this.setState({
            isSavingLogo: false,
          });
        });
    } else {
      // ERROR
    }
  };

  updateStore = (field, value) => {
    this.props.updateStateDirectly([
      { key: "settings" },
      { key: "store", fields: [[field, value]] },
    ]);
    this.setState({ savedState: "changed" });
  };

  saveSettings = () => {
    const store = get(this.props, ["settings", "store"]);

    this.setState({ savedState: "saving" }, () => {
      this.props
        .updateSettings({
          invoice_name: get(store, "invoice_name"),
          invoice_show_name: get(store, "invoice_show_name"),
          invoice_note: get(store, "invoice_note"),
        })
        .then(() => {
          this.setState({ savedState: null });
        });
    });
  };

  render() {
    const isBasic = get(this.props, ["settings", "store", "plan"]) === "basic";

    const store = get(this.props, ["settings", "store"]);

    const savedState = this.state.savedState;

    const privatePortal = getUrlSubdomain() !== "app";

    return (
      <SettingsPageWrapper>
        {this.state.invoiceHtml && (
          <PreviewModal>
            <Text.Large bold extra="margin-bottom: 30px;">
              Branded Invoice Preview
            </Text.Large>
            <InvoiceHTML
              dangerouslySetInnerHTML={{ __html: this.state.invoiceHtml }}
            />
            <ButtonNew
              text="CLOSE PREVIEW"
              type="main"
              onClick={() => this.setState({ invoiceHtml: null })}
              mobile100
              extra="width: 255px;"
            />
          </PreviewModal>
        )}
        <SettingsSubWrapper column={!isBasic}>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SettingsItem
              title="Branded Invoicing"
              description="Customize your invoice with your brand logo, custom note, and other details. Please confirm with the supplier if they are able to offer this feature. Click on the 'Branding' bubble on the supplier profile page to see what they can offer."
              growth={!privatePortal}
            />
            {["changed", "saving"].includes(savedState) && (
              <ButtonNew
                text="SAVE CHANGES"
                type="main"
                isFetching={savedState === "saving"}
                disabled={savedState === "saving"}
                onClick={this.saveSettings}
                extra="height: 48px; width: 255px;"
              />
            )}
          </Row>

          {isBasic ? (
            <ButtonNew
              text="UPGRADE NOW"
              extra="margin-top: 15px; width: 255px;"
              type="main"
              onClick={() => this.props.history.push("/settings/plans")}
              // mobile100
            />
          ) : (
            <React.Fragment>
              <ButtonNew
                type="secondary"
                text="View Sample Invoice"
                extra="width: 180px; padding: 10px; margin-top: 15px;"
                onClick={() =>
                  window.open(
                    "https://dc-imports.s3.ca-central-1.amazonaws.com/generic-packing-slip.pdf"
                  )
                }
                isFetching={this.state.isLoadingInvoice}
              />
              <Switch
                active={get(store, "invoice_show_name")}
                label="Show Store Name On Invoice"
                description="If enabled, your store display name will show on the invoice. If disabled, only your logo will show. If no logo is set, store name will always be shown."
                toggle={() =>
                  this.updateStore(
                    "invoice_show_name",
                    !get(store, "invoice_show_name")
                  )
                }
              />
              <Input
                label="Store Display Name"
                description="The store name displayed on your invoice. If not set, your default store name will be used."
                onChange={(e) =>
                  this.updateStore("invoice_name", e.target.value)
                }
                value={get(store, "invoice_name")}
                extraStyle="width: 300px;"
              />

              <Label>Custom Note</Label>
              <InputDescription>
                Write an optional custom plain-text note to be displayed on your
                invoice. No special formatting allowed.
              </InputDescription>

              <StyledTextArea
                onChange={(e) =>
                  this.updateStore("invoice_note", e.target.value)
                }
                value={get(store, "invoice_note")}
                extraStyle="width: 100%;"
                maxLength="255"
              />

              <Label>Invoice Logo</Label>
              {get(store, "invoice_logo") ? (
                <StoreLogo src={get(store, "invoice_logo")} />
              ) : (
                <Text.Small light>None set</Text.Small>
              )}

              <Row style={{ marginTop: "15px" }}>
                <ButtonNew
                  type="main"
                  text="Upload Logo"
                  extra="width: 155px; padding: 10px;"
                  icon={<FiPlus size="18" color="white" />}
                  onClick={this.showWidget}
                  isFetching={this.state.isSavingLogo}
                />

                {get(store, "invoice_logo") && (
                  <ButtonNew
                    type="secondary"
                    text="Remove Logo"
                    extra="width: 155px; padding: 10px; margin-left: 20px;"
                    onClick={this.removeLogo}
                    isFetching={this.state.isRemovingLogo}
                  />
                )}
              </Row>
            </React.Fragment>
          )}
        </SettingsSubWrapper>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrandedInvoicing)
);
