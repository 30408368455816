import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import styled from "styled-components";
import { theme } from "../modules/Theme";

const SettingsBack = ({ text, back }) => (
  <PageWrapper onClick={back}>
    <FiChevronLeft size="24px" style={{ cursor: "pointer" }} />
    {text}
  </PageWrapper>
);

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.medDarkGrey};
  width: 90px;
  opacity: 0.8;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export default SettingsBack;
