import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MDSpinner from "react-md-spinner";
import { fetchIssues } from "../../../views/admin/AdminActions";
import { getIsFetching, getIssues } from "../../../views/admin/AdminSelectors";
import Text from "../../../modules/Text";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  suppliers: getIssues(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fetchIssues }, dispatch),
});

class Issues extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.fetchIssues();
  };

  render() {
    // const suppliers = get(this.props, "suppliers");

    return (
      <PageWrapper>
        <Text bold white extra="margin-bottom: 20px;">
          Issues Overview
        </Text>

        {this.props.isFetching > 0 ? (
          <MDSpinner singleColor="rgb(255, 255, 255)" size={50} />
        ) : (
          <React.Fragment>
            {/* {suppliers &&
              suppliers.map(supplier => {
                return (
                  <SupplierWrapper>
                    <Row align style={{ justifyContent: "space-between" }}>
                      <Text.Large white>{supplier.name}</Text.Large>
                      <Text white light>
                        {supplier.email}
                      </Text>
                    </Row>
                    <Text
                      light
                      white
                      extra="margin-top: 5px; margin-bottom: 5px; opacity: 0.5;"
                    >
                      Processing Time: {supplier.manufacturing_time}
                    </Text>
                    {supplier.orders.map(order => (
                      <OrderWrapper>
                        <Row align style={{ justifyContent: "space-between" }}>
                          <Row align>
                            <Text
                              link
                              extra="margin-top: 5px; margin-bottom: 5px;"
                              onClick={() =>
                                window.open(
                                  `http://dropcommerce-app.herokuapp.com/admin/dropcommerce/supplierorder/${order.id}`
                                )
                              }
                            >
                              {order.name}
                            </Text>
                            <Text
                              white
                              light
                              extra="margin-left: 20px;"
                              red={order.days >= 7}
                            >
                              {order.days} Days Ago
                            </Text>
                            {order.shopify_failed && (
                              <FailedBubble>Shopify Failed</FailedBubble>
                            )}
                          </Row>

                          <Text.Small white light extra="opacity: 0.7;">
                            {order.email}
                          </Text.Small>
                        </Row>
                        <Text
                          white
                          extra="margin-top: 10px; margin-bottom: 10px;"
                          light={order.admin_notes == ""}
                        >
                          {order.admin_notes || "No notes"}
                        </Text>

                        <Text.Small white bold>
                          Items:
                        </Text.Small>
                        {order.line_items.map(item => (
                          <Text.Small white light extra="opacity: 0.7;">
                            {item}
                          </Text.Small>
                        ))}
                      </OrderWrapper>
                    ))}
                  </SupplierWrapper>
                );
              })} */}
          </React.Fragment>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Issues));

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

// *** UNUSED, hence commented ***

// const FailedBubble = styled.div`
//   background: ${theme.colors.accent};
//   color: white;
//   border-radius: 5px;
//   margin-left: 15px;
//   padding: 5px;
// `;

// const SupplierWrapper = styled.div`
//   background: rgba(0, 0, 0, 0.2);
//   border-radius: 5px;
//   width: 1000px;
//   margin: 15px;
//   padding: 20px;
// `;

// const OrderWrapper = styled.div`
//   background: rgba(0, 0, 0, 0.1);
//   border-radius: 5px;
//   width: 100%;
//   margin-top: 10px;
//   padding: 15px;
// `;
