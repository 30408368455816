import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Hint from "./Hint";
import { Row } from "./Layout";

class TabNavigationLight extends Component {
  render() {
    const darkTheme = this.props.darkTheme;

    return (
      <NavTabs padding={this.props.padding} extra={this.props.extra}>
        {this.props.tabs.map((tab) => {
          const active =
            tab.active ||
            (this.props.activeTab && this.props.activeTab === tab.key);
          return (
            <NavTab
              canHide={tab.name === "All" ? true : false}
              key={tab.name}
              darkTheme={darkTheme}
              active={active}
              onClick={
                this.props.onClick
                  ? () => this.props.onClick(tab.key)
                  : tab.link
              }
            >
              <Row style={{ alignItems: "center" }}>
                <TabText
                  darkTheme={darkTheme}
                  active={active}
                  warning={tab.warning}
                >
                  {tab.name}
                </TabText>
                {tab.warning && (
                  <Hint hint={tab.warning} type="warning" lessMargin />
                )}
              </Row>
            </NavTab>
          );
        })}
      </NavTabs>
    );
  }
}

export default TabNavigationLight;

export const TabText = styled.div`
  color: ${(p) => (p.active ? theme.colors.main : theme.colors.medGrey)};
  &:hover {
    color: ${theme.colors.mainDark};
  }

  ${(p) =>
    p.darkTheme &&
    `
    font-weight: ${p.active ? "500" : "400"};
    color: ${p.active ? theme.colors.white : theme.colors.medGrey};
    &:hover {
      color: ${theme.colors.lightBorder};
    }
  `}

  ${(p) => p.warning && `font-weight: 500; color: ${theme.colors.accent};`}
`;

export const NavTabs = styled.div`
  display: flex;
  ${(p) =>
    p.padding &&
    `
    padding: 30px;
    padding-bottom: 10px;
  `}
  ${(p) => p.extra};
  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

export const NavTab = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 3px solid ${(p) => (p.active ? theme.colors.main : "white")};
  cursor: pointer;
  margin-right: 20px;
  transition: all 100ms;

  ${(p) =>
    p.darkTheme &&
    `
    border-bottom: 3px solid ${p.active ? theme.colors.main : "#59736f"};
  `}

  @media screen and (max-width: 800px) {
    font-size: 16px;
    ${(props) => props.canHide && "display: none"}
  }
`;
