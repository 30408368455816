import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { capitalize, get } from "lodash";
import {
  updateSettings,
  updateStateDirectly,
  stripeStoreAuth,
} from "../../../../Actions";
import {
  getSettings,
  getIsFetchingSettings,
  getIsSavingStripe,
} from "../../../../Selectors";
import StripeCard from "../../../../components/StripeCard";
import ButtonNew from "../../../../components/ButtonNew";
import Label from "../../../../components/Label";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import {
  SettingsPageWrapper,
  SettingsSubWrapper,
} from "../../../../views/settings/SettingsStyle";
import Switch from "../../../../components/Switch";
import MDSpinner from "react-md-spinner";
import PaymentPlansTable from "./OrderHistoryTable.jsx";
import { TableNew } from "../../../../components/TableNew";
import { axiosInstance } from "../../../../services/api";
import moment from "moment";
import { errorNotification } from "../../../../modules/Notification";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetchingSettings: getIsFetchingSettings(state),
  isSavingStripe: getIsSavingStripe(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSettings,
      updateStateDirectly,
      stripeStoreAuth,
    },
    dispatch
  ),
});

export const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

export const ConnectButton = styled.div`
  background: white;
  font-weight: 500;
  text-align: center;
  color: ${theme.colors.medDarkGrey};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 230px;
  margin-right: 30px;
  ${(p) => p.hollow && `background: ${theme.colors.darkGreen}; color: white;`}
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false, stores: [], isLoading: true };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      ...prevState,
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  updateStore = (field, value) => {
    this.props.updateStateDirectly([
      { key: "settings" },
      { key: "store", fields: [[field, value]] },
    ]);
  };

  onToken = (token) => {
    const isStore = this.props.settings.store ? true : false;
    isStore
      ? this.props.stripeStoreAuth({
          token: token.id,
          email: token.email,
          country: token.card.country,
          store: this.props.settings.store.id,
        })
      : this.props.stripeSupplierAuth({
          token: token.id,
          email: token.email,
          country: token.card.country,
          supplier: this.props.settings.supplier.id,
        });
  };

  componentDidMount() {
    axiosInstance
      .get("/prebuilt/purchases/")
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          stores: response.data,
        }));
      })
      .catch((error) => {
        errorNotification("Error retrieving your stores");
      })
      .finally(() => {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
        }));
      });
  }

  render() {
    const store = get(this.props, ["settings", "store"]);
    const paymentPlan = get(store, "payment_plan");
    const storeId = get(store, "id");
    const planName = get(paymentPlan, "plan");
    const autoFulfillment = get(this.props, [
      "settings",
      "store",
      "auto_fulfillment",
    ]);
    const privatePortal = get(this.props, [
      "storeSettings",
      "store",
      "private_portal",
    ]);
    const autoFulfillmentDescription = privatePortal
      ? "Automatically pay the supplier with your credit card on file when an order is placed in your store."
      : "Automatically pay the supplier with your credit card on file when an order is placed in your store. This feature is available only on the Professional plan";

    if (this.props.isFetchingSettings || this.props.isSavingStripe) {
      return (
        <SettingsPageWrapper>
          <MDSpinner singleColor="rgb(73,190,175)" size={40} />
        </SettingsPageWrapper>
      );
    }

    return (
      <SettingsPageWrapper>
        <SettingsSubWrapper column style={{ marginTop: "20px" }}>
          <ButtonNew
            margin="0 0 30px 0"
            text="PAYMENT PLAN HISTORY"
            extra="margin-top: 15px; width: 255px;"
            type="main"
            onClick={this.handleClick}
          />
          {this.state.isToggleOn && <PaymentPlansTable store={storeId} />}
        </SettingsSubWrapper>

        <SettingsSubWrapper
          column={!planName === "BASIC"}
          style={{ marginTop: "20px" }}
        >
          {planName === "BASIC" ? (
            <div>
              <Label extra="margin-top: 5px; margin-bottom: 10px;">
                Automated Order Fulfillment
              </Label>
              <InputDescription>{autoFulfillmentDescription}</InputDescription>
              <ButtonNew
                text="UPGRADE NOW"
                extra="margin-top: 15px; width: 255px;"
                type="main"
                onClick={() => this.props.history.push("/settings/plans")}
                // mobile100
              />
            </div>
          ) : (
            <Switch
              disabled={!["PROFESSIONAL", "EXECUTIVE"].includes(planName)}
              pro={!privatePortal}
              noTopMargin
              active={autoFulfillment}
              label="Automated Order Fulfillment"
              description={autoFulfillmentDescription}
              toggle={() =>
                this.props.updateSettings({
                  auto_fulfillment: !autoFulfillment,
                })
              }
            />
          )}
        </SettingsSubWrapper>

        <SettingsSubWrapper column style={{ marginTop: "20px" }}>
          <Label
            extra="margin-top: 5px; margin-bottom: 10px;"
            hint="A credit card securely saved in your account to pay for orders."
            hintLink="https://help.dropcommerce.com/en/articles/3419902-new-payment-card-automated-fulfillment"
          >
            Payment Card
          </Label>
          <StripeCard
            text="Add Payment Card"
            description="Authorize Your Credit Card"
            panelLabel="Authorize Card"
          />
        </SettingsSubWrapper>

        <SettingsSubWrapper column style={{ marginTop: "20px" }}>
          <TableNew
            title="Your Prebuilt Stores"
            columns={[
              "Store",
              "Name",
              "Level",
              "Package",
              "Date",
              "Email",
              "URL",
            ]}
            rows={this.state.stores.map((store) => {
              const dateObject = new Date(get(store, "date"));
              const dateString = moment(dateObject).format("MMM Do, YYYY");
              return {
                store: get(store, "store"),
                name: get(store, ["design_set", "name"]),
                level: capitalize(get(store, ["design_set", "level"])),
                package: get(store, "package"),
                date: dateString,
                email: get(store, "email"),
                url: get(store, "url"),
              };
            })}
            isLoading={this.state.isLoading}
            noResultsText="You haven't purchased any prebuilt stores yet."
          />
        </SettingsSubWrapper>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
);
