import React from "react";
import styled from "styled-components";
import { Column } from "../../../../../components";
import { ContactInfo } from "./ContactInfo";
import { Header } from "./Header";
import { ProductCount } from "./ProductCount";

const Container = styled(Column)`
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  ${(props) => props.flex && `flex: ${props.flex}`};
  overflow: hidden;
`;

const Body = styled("div")`
  padding: 16px;
`;

export function ProfileCard({ flex, title, status, children }) {
  return (
    <Container flex={flex}>
      <Header title={title} status={status} />

      <Body>{children}</Body>
    </Container>
  );
}

ProfileCard.Header = Header;
ProfileCard.ProductCount = ProductCount;
ProfileCard.ContactInfo = ContactInfo;
