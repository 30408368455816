import { get, isEmpty } from "lodash";
import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { ButtonNew, Input, Row, Switch } from "../../../../components";
import { FullScreenSpinner } from "../../../../components/FullScreenSpinner";
import { Show } from "../../../../components/Show";
import { buildObjectFromFields } from "../../../../modules/Format";
import { successNotification } from "../../../../modules/Notification";
import {
  saveSupplierSettings,
  updateSupplier,
} from "../../../../views/supplier/SupplierActions";
import { getSettings } from "../../../../views/supplier/SupplierSelectors";

import { SupplierBanner } from "../../../products/premium/components/SupplierBanner";
import { ProfileCard } from "./ProfileCard";
import ResetPassword from "./ResetPassword";
import { validateWebsite } from "../../../../utils";

const RowOfCards = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
`;

class Profile extends Component {
  state = {
    saveState: false,
    isSaving: false,
    isOpeningWidget: false,
    errors: {},
  };

  updateSupplier = (data) => {
    this.props.updateSupplier(data);
    this.setState({ saveState: true });
  };

  isValid = () => {
    const settings = get(this.props, ["settings"]);
    const errors = {};
    if (settings.short_description.length < 6) {
      errors.short_description = "Please enter a longer description";
    }
    if (settings.description.length < 10) {
      errors.description = "Please enter a longer descriptive biography";
    }
    if (settings.return_policy.length < 10) {
      errors.return_policy = "Please enter a longer policy";
    }
    if (settings.discount < 30) {
      errors.discount = "Margin must be 30% or higher";
    }
    if (!validateWebsite(settings.website)) {
      errors.website = "Enter a valid website";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  saveSettings = () => {
    if (!this.isValid()) return;
    const settings = get(this.props, ["settings"]);

    const data = buildObjectFromFields(settings, [
      "return_policy",
      "short_description",
      "description",
      "approval_required", //
      "sync_inventory",
      "sync_pricing",
      "auto_import",
      "discount",
      "website",
      "vendor_request_notifications", //
      "use_compare_at_price",
    ]);

    this.setState({ isSaving: true });

    this.props.saveSupplierSettings(data).then(() => {
      this.setState({ saveState: false, isSaving: false });
      successNotification("Saved changes");
    });
  };

  showWidget = () => {
    this.setState({
      isOpeningWidget: true,
    });

    const options = {
      cloudName: "dropcommerce",
      uploadPreset: "hjeazqpb",
    };

    const callback = (error, result) => {
      if (!error && result && result.event === "success") {
        this.props
          .saveSupplierSettings({ logo: result.info.secure_url })
          .then(() => {
            successNotification("Photo has been changed successfully");
          });
      }
      this.setState({ isOpeningWidget: false });
    };

    window.cloudinary.createUploadWidget(options, callback).open();
  };

  render() {
    const settings = get(this.props, ["settings"]);

    const shopifyConnected =
      get(this.props, ["settings", "shopify_url"]) !== "" &&
      get(this.props, ["settings", "shopify_token_saved"]);

    const subdomain = get(this.props, ["settings", "subdomain"]);

    const info = (
      <ProfileCard
        flex={shopifyConnected ? "2" : "1"}
        title="Info"
        status={settings.status}
      >
        <ProfileCard.ProductCount numberOfProducts={settings.product_count} />

        <ProfileCard.ContactInfo
          email={settings.email}
          phoneNumber={settings.phone}
          subdomain={subdomain}
        />

        <ResetPassword />
        <Input
          label="Website"
          placeholder="Enter website URL..."
          onChange={(e) =>
            this.updateSupplier({
              website: e.target.value,
            })
          }
          value={settings.website || ""}
          error={this.state.errors.website}
        />
      </ProfileCard>
    );

    const form = (
      <ProfileCard flex="3" title="About">
        <Input
          maxWidth="auto"
          label="Short Description"
          description="A short summary of your brand to display on the All Suppliers page"
          placeholder="Eg. Handmade Pottery From Portland, Oregon"
          onChange={(e) =>
            this.updateSupplier({
              short_description: e.target.value,
            })
          }
          value={settings.short_description || ""}
          error={this.state.errors.short_description}
          maxLength="255"
        />

        <Input
          textarea
          maxWidth="auto"
          label="About / Biography"
          description="Tell stores about your brand and your story to help them understand who they are working with"
          onChange={(e) =>
            this.updateSupplier({
              description: e.target.value,
            })
          }
          value={settings.description || ""}
          error={this.state.errors.description}
        />

        <Input
          textarea
          maxWidth="auto"
          label="Return Policy"
          description="Paste your full return policy here or link to your policy"
          onChange={(e) =>
            this.updateSupplier({
              return_policy: e.target.value,
            })
          }
          value={settings.return_policy || ""}
          error={this.state.errors.return_policy}
        />
      </ProfileCard>
    );

    const buttons = (
      <Row style={{ justifyContent: "end", marginTop: "20px" }} gap="10px">
        <ButtonNew
          text={settings.logo ? "UPDATE LOGO" : "UPLOAD LOGO"}
          type="main"
          extra="width: 150px;"
          onClick={this.showWidget}
          isFetching={this.state.isOpeningWidget}
        />

        <ButtonNew
          text={!this.state.saveState ? "CHANGES SAVED" : "SAVE CHANGES"}
          type={!this.state.saveState ? "main-light" : "main"}
          extra="width: 150px;"
          onClick={this.saveSettings}
          isFetching={this.state.isSaving}
          disabled={!this.state.saveState}
        />
      </Row>
    );

    if (isEmpty(settings)) {
      return <FullScreenSpinner />;
    }

    return (
      <React.Fragment>
        <SupplierBanner hideForSupplier supplier={settings} />

        <RowOfCards>
          {info}

          <Show when={shopifyConnected}>
            <ProfileCard flex="3" title="Sync">
              <Switch
                active={settings.auto_import}
                label="Sync New Shopify Products"
                description="New products in your Shopify account will be auto-imported daily. Do not activate this if you have any products in Shopify that you do not want in DropCommerce."
                toggle={() =>
                  this.updateSupplier({ auto_import: !settings.auto_import })
                }
              />

              <Switch
                active={settings.sync_inventory}
                label="Sync Shopify Inventory"
                hint="Inventory sync can be toggled on or off for individual products."
                description="Inventory is synced from your Shopify account daily. Please ensure your inventory is accurate in Shopify."
                toggle={() =>
                  this.updateSupplier({
                    sync_inventory: !settings.sync_inventory,
                  })
                }
              />

              <Switch
                active={settings.sync_pricing}
                label="Sync Shopify Pricing"
                description="Product pricing is synced from your Shopify account daily, based on the Reseller Margin field defined below."
                toggle={() =>
                  this.updateSupplier({
                    sync_pricing: !settings.sync_pricing,
                  })
                }
              />

              {settings.sync_pricing && (
                <Input
                  maxWidth="auto"
                  label="Reseller Margin"
                  description="Enter the margin % you are offering to DropCommerce resellers, which is their profit. If your product costs $100 and you offer a 35% discount, stores will pay you $65 for the product when sold. You can adjust prices manually later on a product level."
                  placeholder="Reseller Margin % (30 or more)"
                  onChange={(e) =>
                    this.updateSupplier({ discount: e.target.value })
                  }
                  value={settings.discount}
                  error={this.state.errors.discount}
                  maxLength="255"
                  type="number"
                />
              )}

              {settings.sync_pricing && (
                <Switch
                  active={settings.use_compare_at_price}
                  label="Use Compare at Price"
                  description="When pricing syncs we'll use the Compare At Price instead. This allows you to change your prices without changing DropCommerce's margin."
                  toggle={() =>
                    this.updateSupplier({
                      use_compare_at_price: !settings.use_compare_at_price,
                    })
                  }
                />
              )}
            </ProfileCard>
          </Show>

          {form}
        </RowOfCards>

        {buttons}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      saveSupplierSettings,
      updateSupplier,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
