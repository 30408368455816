import { EmailSchema, PasswordSchema } from "./schemas";

export function validEmail(email) {
  const parsedEmail = EmailSchema.safeParse(email);
  return parsedEmail.success;
}

export function validPassword(password) {
  const parsedPassword = PasswordSchema.safeParse(password);
  return parsedPassword.success;
}
