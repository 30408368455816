import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { get, isEmpty } from "lodash";
import {
  createShippingOption,
  clearShippingOption,
  setShippingOptionActive,
  fetchSupplierSettings,
} from "../../../../../views/supplier/SupplierActions";
import {
  getShippingOption,
  getSettings,
  getIsFetchingShippingOption,
} from "../../../../../views/supplier/SupplierSelectors";
import { ButtonNew, SettingsBack, Row, Form } from "../../../../../components";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import MDSpinner from "react-md-spinner";
import { getUrlParameter } from "../../../../../modules/Format";
import { getRequest } from "../../../../../modules/API";
import createNotification from "../../../../../modules/Notification";

import { getCodeFromCountry } from "../../../../../modules/Countries";
import { FiAlertTriangle } from "react-icons/fi";

const NotActiveWrapper = styled.div`
  background: ${theme.colors.accent};
  padding: 15px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const NotActiveText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 10px;
`;

const ShippingOptionWrapper = styled.div`
  width: 400px;

  @media screen and (max-width: 800px) {
    margin-bottom: 64px;
  }
`;

const countryOptions = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "HA",
    label: "Hawaii/Alaska",
  },
  {
    value: "LA",
    label: "Latin America",
  },
  {
    value: "AU",
    label: "Australia/New Zealand",
  },
  {
    value: "EU",
    label: "European Union",
  },
  {
    value: "IS",
    label: "Iceland",
  },
  {
    value: "INTL",
    label: "International",
  },
];

const labelMap = {
  US: "United States",
  CA: "Canada",
  HA: "Hawaii/Alaska",
  LA: "Latin America",
  AU: "Australia/New Zealand",
  EU: "European Union",
  IS: "Iceland",
  INTL: "International",
};

const SHIPPING_OPTION_INITIAL_STATE = {
  to_country: "",
  price: "",
  additional_item_shipping_rate: 0.0,
  carrier: "",
  delivery_days_min: 0,
  delivery_days_max: 0,
  errors: {},
};

class ShippingOption extends Component {
  state = {
    saved: false,
    excluded_regions: [],
    shippingOption: SHIPPING_OPTION_INITIAL_STATE,
    isFetching: !this.props.match.path.includes("/new"),
  };

  fetchShippingOption = (optionId) => {
    getRequest({ url: `api/shipping-options/${optionId}/` })
      .then((res) => {
        this.setState((currentState) => ({
          ...currentState,
          shippingOption: { ...currentState.shippingOption, ...res.data },
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => this.setState({ isFetching: false }));
  };

  componentDidMount() {
    const isNew = this.props.match.path.includes("/new");
    const shippingOptionId = get(this.props, ["match", "params", "optionId"]);

    if (isNew) {
      const productId = getUrlParameter("product", this.props.location);
      this.props.clearShippingOption(productId);
    }

    if (shippingOptionId) {
      this.fetchShippingOption(shippingOptionId);
    }
  }

  update = (field, value) => {
    if (["exclude_hi", "exclude_ak", "exclude_pr"].includes(field)) {
      const shipOption = get(this.state, [
        "shippingOption",
        "excluded_regions",
      ]);

      const excludedRegions = {
        exclude_ak:
          field === "exclude_ak"
            ? value
            : shipOption && shipOption.includes("AK"),
        exclude_pr:
          field === "exclude_pr"
            ? value
            : shipOption && shipOption.includes("PR"),
        exclude_hi:
          field === "exclude_hi"
            ? value
            : shipOption && shipOption.includes("HI"),
      };

      const codeMap = {
        exclude_ak: "AK",
        exclude_pr: "PR",
        exclude_hi: "HI",
      };

      const excludeString = Object.keys(excludedRegions)
        .filter((key) => excludedRegions[key])
        .map((key) => codeMap[key])
        .join(",");

      field = "excluded_regions";
      value = excludeString || null;
    }

    this.setState({
      shippingOption: { ...this.state.shippingOption, [field]: value },
    });
  };

  validate = () => {
    const price = parseFloat(this.state.shippingOption.price);
    const delivery_days_max = parseInt(
      this.state.shippingOption.delivery_days_max
    );
    const delivery_days_min = parseInt(
      this.state.shippingOption.delivery_days_min
    );
    const toCountry = this.state.shippingOption.to_country;

    const errors = {};

    if (price === null || price < 0) {
      errors.price = "Price cannot be null or lower than 0.";
    }
    if (delivery_days_min < 0) {
      errors.delivery_days_min =
        "The minimum delivery time cannot be lower than 0.";
    }
    if (delivery_days_max < 0) {
      errors.delivery_days_max =
        "The maximum delivery time cannot be lower than 0.";
    }
    if (delivery_days_min > delivery_days_max) {
      errors.delivery_days_min =
        "The minimum delivery time cannot be greater than the maximum time.";
    }
    if (price > 20 && toCountry === "CA") {
      errors.to_country =
        "Canadian Shipping rates higher than $20 make dropshipping tough. Please contact support@dropcommerce.com to discuss your shipping settings.";
    }
    if (toCountry === "") {
      errors.to_country = "Please choose a country";
    }
    if (price > 15 && toCountry === "US") {
      errors.to_country =
        "US Shipping rates higher than $15 make dropshipping tough. Please contact support@dropcommerce.com to discuss your shipping settings.";
    }
    if (price > 30) {
      errors.price =
        "Shipping rates higher than $30 make dropshipping tough in general. Please contact support@dropcommerce.com to discuss your shipping settings.";
    }

    this.setState((currentState) => ({
      ...currentState,
      shippingOption: { ...currentState.shippingOption, errors },
    }));

    return isEmpty(errors);
  };

  createShippingOption = () => {
    if (!this.validate()) return;

    this.setState({ isFetching: true });

    const productId = getUrlParameter("product", this.props.location);

    this.props
      .createShippingOption({
        ...this.state.shippingOption,
        from_country: getCodeFromCountry(this.props.settings.country),
        region: "",
        supplier: this.props.settings.id,
        product: productId !== "" ? productId : null,
        deactivated: null,
      })
      .then((response) => {
        if (response.id) {
          this.setState({ shippingOption: response });
          this.props.history.push(`/supplier/settings/shipping/${response.id}`);

          createNotification({
            type: "success",
            title: "Success",
            message: "Shipping Rate Created",
          });

          // this.props.updateSupplier({
          //   active_shipping_options: true,
          // });

          this.props.fetchSupplierSettings();

          // The above method fetches all the settings again
          // OR else
          // should we set up a new action that fetches active shipping option?
        } else {
          const rateAlreadyExists =
            response.non_field_errors &&
            response.non_field_errors.includes(
              "A shipping rate already exists for this Country-Region"
            );

          let error = "Failed to create shipping option";

          if (rateAlreadyExists) {
            error =
              "A shipping rate already exists for this Country-Region. Please edit the existing shipping rate.";
          }

          createNotification({
            type: "danger",
            title: "Error",
            message: error,
          });
        }
      })
      .finally((result) => this.setState({ isFetching: false }));
  };

  setShippingOptionActive = (bool) => {
    this.setState({ isFetchingShippingOption: true });
    this.props
      .setShippingOptionActive(bool, this.state.shippingOption.id)
      .then((response) => {
        if (!response.error) {
          createNotification({
            type: "success",
            title: "Success",
            message: `Shipping Rate ${bool ? "Activated" : "Deactivated"}`,
          });
          this.setState({
            shippingOption: {
              ...this.state.shippingOption,
              deactivated: bool === false ? new Date() : null,
            },
          });
        }
      })
      .finally(() => {
        this.setState({
          isFetchingShippingOption: false,
        });
      });
  };

  render() {
    const shippingOption = get(this.state, "shippingOption");
    const excludedRegions = get(shippingOption, "excluded_regions");
    const isNew = this.props.match.path.includes("/new");

    if (this.state.isFetching || (isEmpty(shippingOption) && !isNew)) {
      return (
        <ShippingOptionWrapper>
          <MDSpinner singleColor="rgb(73,190,175)" size={30} />
        </ShippingOptionWrapper>
      );
    }

    const product =
      getUrlParameter("product", this.props.location) || shippingOption.product;

    const checkboxFormItems =
      shippingOption.to_country === "US"
        ? [
            {
              type: "checkbox",
              label: "Exclude Hawaii",
              key: "exclude_hi",
              checked: !!excludedRegions && excludedRegions.includes("HI"),
            },
            {
              type: "checkbox",
              label: "Exclude Alaska",
              key: "exclude_ak",
              checked: !!excludedRegions && excludedRegions.includes("AK"),
            },
            {
              type: "checkbox",
              label: "Exclude Puerto Rico",
              key: "exclude_pr",
              checked: !!excludedRegions && excludedRegions.includes("PR"),
            },
          ]
        : [];

    const items = [
      {
        type: "select",
        label: "Country",
        hint: "Add shipping settings for this specific country.",
        selectOptions: countryOptions,
        selectIsSearchable: true,
        labelMap,
        key: "to_country",
        error: get(
          this,
          ["state", "shippingOption", "errors", "to_country"],
          ""
        ),
      },
      {
        type: "input",
        inputType: "number",
        label: "First Item Price",
        hint: "The shipping rate charged for the first item in an order. If product shipping rates vary, the most expensive will be charged.",
        key: "price",
        error: get(this, ["state", "shippingOption", "errors", "price"], ""),
      },
      {
        type: "input",
        inputType: "number",
        label: "Additional Items Price",
        hint: "The shipping rate charged for items beyond the first item in an order. For one flat rate per order, enter 0.00.",
        key: "additional_item_shipping_rate",
        error: get(
          this,
          [
            "state",
            "shippingOption",
            "errors",
            "additional_item_shipping_rate",
          ],
          ""
        ),
      },
      {
        type: "input",
        inputType: "text",
        label: "Carrier",
        hint: "The shipping carrier you use for this shipping region.",
        placeholder: "Fedex, USPS, etc",
        key: "carrier",
        error: get(this, ["state", "shippingOption", "errors", "carrier"], ""),
      },
      {
        type: "input",
        inputType: "number",
        label: "Delivery Days Min",
        key: "delivery_days_min",
        error: get(
          this,
          ["state", "shippingOption", "errors", "delivery_days_min"],
          ""
        ),
        hint: "The minimum estimate for the delivery time of orders.",
        placeholder: 0,
      },
      {
        type: "input",
        inputType: "number",
        label: "Delivery Days Max",
        hint: "The maximum estimate for the delivery time of orders.",
        placeholder: 0,
        key: "delivery_days_max",
        error: get(
          this,
          ["state", "shippingOption", "errors", "delivery_days_max"],
          ""
        ),
      },
      ...checkboxFormItems,
    ];

    return (
      <ShippingOptionWrapper>
        <SettingsBack
          text="Shipping"
          back={() =>
            this.props.history.push(
              !product || product === "undefined"
                ? "/supplier/settings/shipping"
                : `/supplier/product/${product}?tab=shipping`
            )
          }
        />

        {!isNew && shippingOption.deactivated !== null && (
          <NotActiveWrapper>
            <FiAlertTriangle size="30px" color="white" />
            <NotActiveText>This rate is not active</NotActiveText>
            <ButtonNew
              text="Activate"
              type="white-hollow"
              onClick={() => this.setShippingOptionActive(true)}
              isFetching={this.state.isFetchingShippingOption}
            />
          </NotActiveWrapper>
        )}

        {isNew ? (
          <ButtonNew
            text={this.state.saved ? "Saved" : "Save Shipping Option"}
            type={this.state.saved ? "main-light" : "main"}
            icon={this.state.saved && "check"}
            extra="margin-bottom: 10px; width: 200px;"
            onClick={this.createShippingOption}
            isFetching={this.state.isFetchingShippingOption}
          />
        ) : (
          <Row style={{ marginBottom: "10px" }}>
            {shippingOption.deactivated === null && (
              <ButtonNew
                text="Deactivate"
                extra={`width: 200px; background-color: ${theme.colors.accent}; :hover { background-color: ${theme.colors.accent}; filter: brightness(117%); }`}
                onClick={() => this.setShippingOptionActive(false)}
                isFetching={this.state.isFetchingShippingOption}
              />
            )}
          </Row>
        )}

        <Form
          title="Shipping Rate"
          items={items}
          stateLocation={shippingOption}
          submit={this.createShippingOption}
          onChange={(field, value) => this.update(field, value)}
        />
      </ShippingOptionWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  shippingOption: getShippingOption(state),
  isFetchingShippingOption: getIsFetchingShippingOption(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      setShippingOptionActive,
      createShippingOption,
      clearShippingOption,
      fetchSupplierSettings,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOption);
