import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { get } from "lodash";
import { updateSettings } from "../../../../Actions";
import { getSettings } from "../../../../Selectors";

import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { SettingsPageWrapper } from "../../../../views/settings/SettingsStyle";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSettings,
    },
    dispatch
  ),
});

export const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

class AutoFulfillment extends Component {
  render() {
    // const store = get(this.props, ["settings", "store"]);

    return <SettingsPageWrapper></SettingsPageWrapper>;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoFulfillment)
);
