import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { theme } from "../../../modules/Theme";
import Hint from "../../../components/Hint";
import { Row } from "../../../components/Layout";

const SettingsCardsRow = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-auto-rows: auto;
  grid-gap: 15px;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (max-width: 700px) {
    grid-template-columns: 100%;
    margin-bottom: 64px;
  }

  ${(p) =>
    p.productCount &&
    p.productCount < 4 &&
    "grid-template-columns: 300px 300px 300px;"}
`;

const SettingsCardContainer = styled.div`
  background: ${theme.colors.white};
`;

const CardFormatContainer = styled.div`
  cursor: pointer;
  display: flex;
  border: ${theme.colors.lightBorder} 1px solid;
  padding: 20px;
  border-radius: 4px;
  min-height: 100px;
  box-sizing: border-box;
  align-items: flex-start;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
`;

const SettingsCardDetails = styled.div`
  display: flex;
  /* padding-bottom: 16px; */
  flex-direction: column;
`;

const SettingsCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 3px;
  opacity: 0.8;
  background: ${theme.colors.lightGrey};
  margin-right: 12px;
`;

const SettingsCardTitle = styled.div`
  color: ${theme.colors.main};
  font-weight: 500;
  margin-bottom: 3px;
`;

const SettingsCardDescription = styled.div`
  color: ${theme.colors.medGrey};
  font-size: 14px;
`;

class SettingsCards extends Component {
  render() {
    const { cards, settingsPath } = this.props;
    return (
      <SettingsCardContainer>
        <SettingsCardsRow>
          {cards
            .filter(
              (c) => c.displayCondition === undefined || c.displayCondition
            )
            .map((card) => (
              <CardFormatContainer
                key={card.title}
                onClick={() => {
                  card.link
                    ? card.link()
                    : this.props.history.push(`${settingsPath}/${card.route}`);
                }}
              >
                <SettingsCardIcon>{card.icon}</SettingsCardIcon>
                <SettingsCardDetails>
                  <Row>
                    <SettingsCardTitle>{card.title}</SettingsCardTitle>
                    {card.warning && (
                      <Hint hint={card.warning} type="warning" />
                    )}
                  </Row>
                  <SettingsCardDescription>
                    {card.description}
                  </SettingsCardDescription>
                </SettingsCardDetails>
              </CardFormatContainer>
            ))}
        </SettingsCardsRow>
      </SettingsCardContainer>
    );
  }
}

export default withRouter(SettingsCards);
