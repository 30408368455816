import { axiosAdminInstance } from "./axiosAdminInstance";

// const delay = () => new Promise((res) => setTimeout(res, 3000));

export const lateOrdersUrlEndpoint = "/late_orders";

export async function getLateOrders() {
  try {
    // await delay();
    const response = await axiosAdminInstance.get(`${lateOrdersUrlEndpoint}/`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error:", error);
    throw new Error("Failed to get orders.");
  }
}

export async function updateOrder(status, orderId) {
  try {
    // await delay();
    const response = await axiosAdminInstance.patch(
      `${lateOrdersUrlEndpoint}/api/admin/supplier-orders/${orderId}/`,
      { status }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error:", error);
    throw new Error("Failed to update order.");
  }
}

export async function retryShopify(name) {
  try {
    // await delay();
    const payload = {
      action: "SHOPIFY_FAILED",
      data: { order_name: name },
    };
    const response = await axiosAdminInstance.post("/admin_commands/", payload);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error:", error);
    throw new Error(error);
  }
}
