import React from "react";
import styled from "styled-components";
import { AdminSidebar } from "./components/AdminSidebar";

const PageWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
`;

const AdminContainer = styled.main`
  overflow-y: auto;
  width: 100%;

  @media (min-width: 801px) {
    padding: 30px;
  }
`;

export function AdminRootLayout({ children }) {
  return (
    <PageWrapper>
      <AdminSidebar />
      <AdminContainer>{children}</AdminContainer>
    </PageWrapper>
  );
}
