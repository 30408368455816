import React, { Component } from "react";
import AddImage from "../../../components/AddImage";
import blank from "../../../images/not-found.png";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { resizeImage } from "../../../modules/Format";

export const ProductWrapper = styled.div`
  background: ${theme.colors.white};
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  align-items: flex-start;
  margin-top: 15px;
  @media (max-width: 800px) {
    padding: 5px;
  }
`;

export const ProductMainImage = styled.img`
  height: 440px;
  width: 440px;
  border: 1px solid #e8eaec;
  object-fit: ${(props) => (props.crop ? "cover" : "contain")};
  @media (max-width: 800px) {
    height: 200px;
    width: 100%;
  }
`;

export const ImageSectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const SmallImagesColumn = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0px;
  }
`;

class ProductImages extends Component {
  state = {
    currentImage: undefined,
    imageError: false,
  };

  mouseEnter = (id) => {
    if (this.props.product) {
      this.setImage(id);
    }
  };

  componentDidMount = () => {
    this.setImage(0, this.props.product);
  };

  setImage = (id) => {
    if (this.props.product) {
      this.setState({
        currentImage: resizeImage(this.props.product.images[id].url, 500),
      });
    }
  };

  render() {
    const { product } = this.props;

    const images = product.images;

    const imageCount = images.length;

    const isSupplier = this.props.isSupplier;

    return (
      <ProductWrapper>
        <ImageSectionsWrapper>
          {images.length > 1 && (
            <SmallImagesColumn>
              {images.map(
                (image, i) =>
                  i !== 0 && (
                    <AddImage
                      key={i}
                      image={resizeImage(image.url, 150)}
                      mouseEnter={() => this.mouseEnter(i)}
                      count={imageCount}
                      isSupplier={isSupplier}
                      setFeatureImage={() =>
                        this.props
                          .setFeatureImage(product.id, image.id)
                          .then((response) => {
                            this.setState({
                              currentImage: resizeImage(response.imageUrl, 500),
                            });
                          })
                      }
                      updating={image.updating}
                    />
                  )
              )}
            </SmallImagesColumn>
          )}
          <ProductMainImage
            src={this.state.imageError ? blank : this.state.currentImage}
            alt="main product"
            onMouseEnter={() => this.mouseEnter(0)}
            onError={() => this.setState({ imageError: true })}
            crop={product.crop_image}
          />
        </ImageSectionsWrapper>
      </ProductWrapper>
    );
  }
}

export default ProductImages;
