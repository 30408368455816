import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as z from "zod";
import { errorNotification } from "../../../modules/Notification";
import { ToggleColorMode } from "../ToggleColorMode";
import { getUserStores } from "../api/userStores";

const DescriptionList = styled("dl")`
  @media (min-width: 801px) {
    display: none;
  }
`;

const Content = styled.main`
  padding: 0 30px;

  @media (min-width: 801px) {
    padding: 0;
  }
`;

const schema = z.object({
  email: z.string().email("Please enter a valid email"),
});

function AdminStores() {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ resolver: zodResolver(schema) });

  const [stores, setStores] = useState([]);

  const onSubmit = async ({ email }) => {
    try {
      const data = await getUserStores(email);
      const stores = data.stores;
      setStores(stores);

      // Reset the field to its initial state
      resetField("email");
    } catch (error) {
      // Handle error
      console.error("Error:", error);
      errorNotification("Failed to get stores.");
    }
  };

  const formMarkup = (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        width: { sm: 250 },
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      noValidate
    >
      <TextField
        label="EMAIL"
        type="email"
        {...register("email")}
        defaultValue=""
        error={!!errors.email}
        helperText={errors.email && errors.email.message}
      />

      <Button disabled={isSubmitting} type="submit" variant="contained">
        {isSubmitting ? "Getting stores..." : "Get stores"}
      </Button>
    </Box>
  );

  const sx = {
    display: { xs: "none", md: "table-cell" },
  };

  const tableMarkup = !!stores.length && (
    <Box sx={{ width: "100%" }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Stores
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell sx={sx}>Orders</TableCell>
              <TableCell sx={sx}>Imports</TableCell>
              <TableCell sx={sx}>Platform</TableCell>
              <TableCell sx={sx}>Connected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((store) => (
              <TableRow key={store.id}>
                <TableCell>
                  {store.id}
                  <DescriptionList
                    style={{
                      marginTop: "8px",
                      // marginLeft: "16px",
                      fontStyle: "italic",
                      color: "#555",
                    }}
                  >
                    <dt style={{ display: "none" }}>Orders</dt>
                    <dd style={{ margin: "4px 0 0 0" }}>
                      <span style={{ fontWeight: "bold" }}>Orders:</span>{" "}
                      {store.orders}
                    </dd>
                    <dt style={{ display: "none" }}>Imports</dt>
                    <dd style={{ margin: "4px 0 0 0" }}>
                      <span style={{ fontWeight: "bold" }}>Imports:</span>{" "}
                      {store.imports}
                    </dd>
                    <dt style={{ display: "none" }}>Platform</dt>
                    <dd style={{ margin: "4px 0 0 0" }}>
                      <span style={{ fontWeight: "bold" }}>Platform:</span>{" "}
                      {store.platform}
                    </dd>
                    <dt style={{ display: "none" }}>Connected</dt>
                    <dd style={{ margin: "4px 0 0 0" }}>
                      <span style={{ fontWeight: "bold" }}>Connected:</span>{" "}
                      {store.hasToken ? "Yes" : "No"}
                    </dd>
                  </DescriptionList>
                </TableCell>
                <TableCell sx={sx}>{store.orders}</TableCell>
                <TableCell sx={sx}>{store.imports}</TableCell>
                <TableCell sx={sx}>{store.platform}</TableCell>
                <TableCell sx={sx}>{store.hasToken ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <ToggleColorMode>
      <CssBaseline />
      <Content>
        {formMarkup}
        {tableMarkup}
      </Content>
    </ToggleColorMode>
  );
}

export default AdminStores;
