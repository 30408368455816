import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";

const Container = styled.div`
  progress[value] {
    width: 160px;
    height: 16px;
    appearance: none;

    ::-webkit-progress-bar {
      border-radius: 1px;
      background-color: #d9d9d9;
    }

    ::-webkit-progress-value {
      border-radius: 1px;
      background-color: ${theme.colors.medGrey};
    }
  }
`;

class BarLine extends Component {
  render() {
    return (
      <Container>
        <progress value={this.props.percent || ""} max="100" />
      </Container>
    );
  }
}

export default BarLine;
