import styled from "styled-components";
import { theme } from "../../modules/Theme";

export const SettingsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px;
  width: 100%;
`;

export const SettingsPageWrapper = styled.div`
  background: ${theme.colors.white};
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SettingsSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.column &&
    "flex-direction: column; justify-content: flex-start; align-items: flex-start;"}
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SettingsToggle = styled.div`
  background: ${(props) => props.on && theme.colors.main};
  padding: 12px;
  border-radius: ${(props) =>
    props.left ? "4px 0px 0px 4px" : "0px 4px 4px 0px"};
  color: ${(props) => (props.on ? "white" : theme.colors.main)};
  border: 1px solid ${theme.colors.main};
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
  transition: 0.3s;
  &:hover {
    background: ${(props) => !props.on && theme.colors.mainLight};
    color: white;
  }
`;

export const SettingsToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
