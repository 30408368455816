import { store } from "react-notifications-component";
import { history } from "../BaseApp";

export const infoNotification = (msg) =>
  createNotification({
    type: "info",
    message: msg,
  });

export const successNotification = (msg) =>
  createNotification({
    type: "success",
    message: msg,
  });

export const errorNotification = (msg) =>
  createNotification({
    type: "danger",
    message: msg,
  });

const createNotification = ({
  id = null,
  type,
  title,
  message,
  route,
  autoRoute,
  duration = 5000,
}) => {
  // success - GREEN
  // danger - RED
  // info - TEAL BLUE
  // default - BLUE
  // warning - YELLOW

  store.addNotification({
    id,
    title,
    message,
    type,
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
    onRemoval: (id, removedBy) => {
      if (route && !autoRoute && removedBy !== "timeout") {
        history.push(route);
      }
    },
  });
};

export default createNotification;
