import Cookies from "js-cookie";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Uncategorize from "../../views/supplier/views/Uncategorize";
import { AdminRootLayout } from "./AdminRootLayout";
import Autocat from "./Autocat";
import {
  Analytics,
  Commands,
  Emails,
  Impersonate,
  InboxManagement,
  LateOrders,
  PrebuiltStores,
  PriceAudit,
  Refunds,
  ShopifyPreview,
  SupplierStats,
  OrderStatus,
} from "./views";
import AdminStores from "./views/AdminStores";
import PremadeStoreDesign from "./views/PremadeStoreDesign";
import NextSupplierManagement from "./views/NextSupplierManagement";
import TaskLog from "./views/TaskLog";

class Admin extends Component {
  componentDidMount = () => {
    if (!Cookies.get("adminAccessToken")) {
      this.props.history.push("/admin/login");
    }
  };

  render() {
    return (
      <AdminRootLayout>
        <Switch>
          <Route path="/admin/shopify_preview" component={ShopifyPreview} />
          <Route path="/admin/categorize" component={Uncategorize} />
          <Route path="/admin/uncategorize" component={Autocat} />
          <Route path="/admin/audit" component={PriceAudit} />
          <Route path="/admin/commands" component={Commands} />
          <Route path="/admin/analytics" component={Analytics} />
          <Route path="/admin/stores" component={AdminStores} />
          <Route path="/admin/suppliers" component={NextSupplierManagement} />
          <Route path="/admin/refunds" component={Refunds} />
          <Route path="/admin/email" component={Emails} />
          <Route path="/admin/orders" component={LateOrders} />
          <Route path="/admin/impersonate" component={Impersonate} />
          <Route exact path="/admin/prebuilt" component={PrebuiltStores} />
          <Route exact path="/admin/inbox" component={InboxManagement} />
          <Route path="/admin/order-status/:orderId?" component={OrderStatus} />
          <Route exact path="/admin/supplier-stats" component={SupplierStats} />
          <Route
            exact
            path="/admin/premade-store-designs"
            component={PremadeStoreDesign}
          />
          <Route exact path="/admin/task-log" component={TaskLog} />
        </Switch>
      </AdminRootLayout>
    );
  }
}

export default Admin;
