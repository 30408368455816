import { get } from "lodash";

export const getLocalState = (state) => get(state, "base");

export const getConfirmationData = (state) =>
  getLocalState(state).confirmationData;
export const hasUnreadMessage = (state) =>
  getLocalState(state).hasUnreadMessage;
export const getFilters = (state) => getLocalState(state).filters;
export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getConversations = (state) => getLocalState(state).conversations;
export const getArchivedConversations = (state) =>
  getLocalState(state).archivedConversations;
export const getRecipients = (state) => getLocalState(state).recipients;
export const getIsFetchingSettings = (state) =>
  getLocalState(state).isFetchingSettings;
export const getIsFetchingRecipients = (state) =>
  getLocalState(state).isFetchingRecipients;
export const getIsFetchingReview = (state) =>
  getLocalState(state).isFetchingReview;
export const getSubdomainData = (state) => getLocalState(state).subdomainData;
export const getCartItems = (state) => getLocalState(state).cart_items;
export const getReferrals = (state) => getLocalState(state).referrals;
export const getIsSavingCart = (state) => getLocalState(state).isSavingCart;
export const getSettingsSaved = (state) => getLocalState(state).settingsSaved;
export const getIsSavingStripe = (state) => getLocalState(state).isSavingStripe;
export const getOrders = (state) => getLocalState(state).orders;
export const getErrors = (state) => getLocalState(state).errors;
export const getOrder = (state) => getLocalState(state).order;
export const getError = (state) => getLocalState(state).error;
export const getSettings = (state) => getLocalState(state).settings;
export const getPagination = (state) => getLocalState(state).pagination;
export const getAnalytics = (state) => getLocalState(state).analytics;
export const getShopifyData = (state) => getLocalState(state).shopifyData;
export const getPlanAuthUrl = (state) => getLocalState(state).planAuthUrl;
export const getCurrencyNotice = (state) => getLocalState(state).currencyNotice;
export const getSupplierCreated = (state) =>
  getLocalState(state).supplierCreated;
export const getTopCategories = (state) => getLocalState(state).topCategories;
export const getStoreApprovalData = (state) =>
  getLocalState(state).storeApprovalData;
export const getIsFetchingApprovalData = (state) =>
  getLocalState(state).isFetchingApprovalData;
export const getSamples = (state) => getLocalState(state).samples;
export const getIsSavingAddress = (state) =>
  getLocalState(state).isSavingAddress;
export const getFeaturedSuppliers = (state) =>
  getLocalState(state).featuredSuppliers;
export const getIsBuyingStore = (state) => getLocalState(state).isBuyingStore;
export const getCategories = (state) => getLocalState(state).categories;
