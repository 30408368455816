import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import blank from "../images/not-found.png";
import { truncateText, resizeImage } from "../modules/Format";
import styled from "styled-components";
import { theme } from "../modules/Theme";

export const Image = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
  border: 1px solid #e8eaec;
  margin-right: 60px;
  @media (max-width: 800px) {
  }
`;

export const Container = styled.div`
  border-bottom: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  padding: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

export const Status = styled.div`
  font-size: 14px;
  font-weight: 300;
  min-width: 50px;
  padding: 10px;
  color: ${theme.colors.white};
  border-radius: 5px;
  background: #49beaf;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => (p.outOfStock || p.inactive) && "background: #be4958;"}
  ${(p) =>
    !p.inactive && !p.outOfStock && p.partialStock && "background: #e48832;"}
  ${(p) =>
    !p.inactive &&
    !p.outOfStock &&
    !p.partialStock &&
    p.lowStock &&
    "background: #f5c30c;"}
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: 400px;
  color: #485d5a;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 110px;
  color: #e48832;
`;

export const RetailPrice = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 110px;
  color: #93aaa8;
`;

export const Tracking = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 150px;
  color: ${(p) => (p.active ? theme.colors.main : "#93aaa8")};
`;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

const defaultProps = {};

class SupplierProductCard extends Component {
  state = { error: false };
  render() {
    const product = this.props.product;
    const outOfStock = product.out_of_stock && product.active;
    let status = "Active";

    if (!product.active) {
      status = "Inactive";
    } else if (outOfStock) {
      status = "Out Of Stock";
    } else if (product.partial_stock) {
      status = "Partial Stock";
    } else if (product.low_stock) {
      status = "Low Stock";
    }

    const productImage = resizeImage(product.image, 150);

    return (
      <Container
        supplier
        onClick={() =>
          this.props.history.push(`/supplier/product/${product.id}`)
        }
      >
        <Image
          src={this.state.error || !productImage ? blank : productImage}
          imageFit="contain"
          onError={() => this.setState({ error: true })}
        />
        <Title>{truncateText(product.title, 36)}</Title>
        <Price grey>${product.price.toFixed(2)}</Price>
        <RetailPrice grey>${product.retail_price.toFixed(2)}</RetailPrice>
        <Tracking active={product.track_inventory}>
          {product.track_inventory ? "On" : "Off"}
        </Tracking>
        <Status
          outOfStock={outOfStock}
          lowStock={product.low_stock}
          inactive={!product.active}
          partialStock={product.partial_stock}
        >
          {status}
        </Status>
      </Container>
    );
  }
}

SupplierProductCard.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupplierProductCard)
);
