import Cookies from "js-cookie";
import { api } from "../../modules/Config";
import { checkStatus } from "../../utils/index";
import createNotification from "../../modules/Notification";

const type = "admin";

export const GENERIC_REQ = `${type}/GENERIC_REQ`;
export const GENERIC_RES = `${type}/GENERIC_RES`;
export const GENERIC_ERR = `${type}/GENERIC_ERR`;
export const GET_EMAIL_DATA_RES = `${type}/GET_EMAIL_DATA_RES`;
export const FETCH_ANALYTICS_RES = `${type}/FETCH_ANALYTICS_RES`;
export const GET_SUPPLIER_MANAGEMENT_RES = `${type}/GET_SUPPLIER_MANAGEMENT_RES`;
export const MANAGE_SUPPLIER_REQ = `${type}/MANAGE_SUPPLIER_REQ`;
export const MANAGE_SUPPLIER_RES = `${type}/MANAGE_SUPPLIER_RES`;
export const MANAGE_SUPPLIER_ERR = `${type}/MANAGE_SUPPLIER_ERR`;
export const FETCH_UNCATEGORIZE_PRODUCTS_RES = `${type}/FETCH_UNCATEGORIZE_PRODUCTS_RES`;
export const UPDATE_UNCATEGORIZE_PRODUCT = `${type}/UPDATE_UNCATEGORIZE_PRODUCT`;
export const SELECT_ALL_UNCATEGORIZE_PRODUCTS = `${type}/SELECT_ALL_UNCATEGORIZE_PRODUCTS`;
export const SAVE_UNCATEGORIZE_PRODUCTS_RES = `${type}/SAVE_UNCATEGORIZE_PRODUCTS_RES`;
export const ANSWER_REFUND_RES = `${type}/ANSWER_REFUND_RES`;
export const ANSWER_REFUND_REQ = `${type}/ANSWER_REFUND_REQ`;
export const ANSWER_REFUND_ERR = `${type}/ANSWER_REFUND_ERR`;

export const saveUncategorizeProducts =
  (category, subcategory, products, type) => (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/uncategorize/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category, subcategory, products, type }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: SAVE_UNCATEGORIZE_PRODUCTS_RES,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error saving tags" },
        });
      });
  };

export const selectAllUncategorizeProducts = (bool) => (dispatch) => {
  dispatch({
    type: SELECT_ALL_UNCATEGORIZE_PRODUCTS,
    payload: { bool },
  });
};

export const updateUncategorizeProduct = (productId) => (dispatch) => {
  dispatch({
    type: UPDATE_UNCATEGORIZE_PRODUCT,
    payload: { productId },
  });
};

export const fetchInboxManagement = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/inbox_management/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/GET_INBOX_MANAGEMENT",
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const fetchUncategorizeProducts =
  (category, subcategory, page, search, supplier, type) => (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(
      `${api}/uncategorize/?category=${category}&subcategory=${subcategory}&page=${page}&search=${search}&supplier=${supplier}&type=${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("adminAccessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: FETCH_UNCATEGORIZE_PRODUCTS_RES,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error retrieving products" },
        });
      });
  };

export const fetchLateOrders = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/late_orders/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/GET_LATE_ORDERS_RES",
        payload: { lateOrders: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const updateAudit =
  (field, value, variantId = null) =>
  (dispatch) => {
    dispatch({
      type: "admin/UPDATE_AUDIT",
      payload: { field, value, variantId },
    });
  };

export const fetchShopifyPreview = (data) => (dispatch) => {
  const { objectId, objectType, accountId, accountType } = data;

  dispatch({
    type: "admin/GENERIC_REQ",
  });
  fetch(
    `${api}/shopify_preview/?object_id=${objectId}&object_type=${objectType}&account_type=${accountType}&account_id=${accountId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/FETCH_AUDIT_RES",
        payload: response.audit,
      });
    })
    .catch(() => {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Error fetching audit",
      });
      dispatch({
        type: "admin/GENERIC_ERR",
      });
    });
};

export const fetchAudit = () => (dispatch) => {
  dispatch({
    type: "admin/GENERIC_REQ",
  });
  fetch(`${api}/audit/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/FETCH_AUDIT_RES",
        payload: response.audit,
      });
    })
    .catch(() => {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Error fetching audit",
      });
      dispatch({
        type: "admin/GENERIC_ERR",
      });
    });
};

export const submitAudit = (data) => (dispatch) => {
  dispatch({
    type: "admin/GENERIC_REQ",
  });
  fetch(`${api}/audit/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      createNotification({
        type: "success",
        title: "Success",
        message: "Audit submitted successfully",
      });
      dispatch({
        type: "admin/FETCH_AUDIT_RES",
        payload: response.audit,
      });
    })
    .catch(() => {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Error submitting audit",
      });
      dispatch({
        type: "admin/GENERIC_ERR",
      });
    });
};

export const chooseCampaign = (campaignId) => (dispatch) => {
  dispatch({
    type: "admin/CHOOSE_CAMPAIGN",
    payload: { campaignId },
  });
};

export const chooseOffer = (offerId) => (dispatch) => {
  dispatch({
    type: "admin/CHOOSE_OFFER",
    payload: { offerId },
  });
};

export const chooseVersion = (versionId) => (dispatch) => {
  dispatch({
    type: "admin/CHOOSE_VERSION",
    payload: { versionId },
  });
};

export const chooseUserGroup = (userGroupValue) => (dispatch) => {
  dispatch({
    type: "admin/CHOOSE_USER_GROUP",
    payload: { userGroupValue },
  });
};

export const manageSupplier =
  (supplier, action, value = null) =>
  (dispatch) => {
    dispatch({
      type: MANAGE_SUPPLIER_REQ,
      payload: { supplier, action },
    });
    fetch(`${api}/supplier_management/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ supplier, action, value }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: MANAGE_SUPPLIER_RES,
          payload: { supplier, action },
        });
      })
      .catch(() => {
        dispatch({
          type: MANAGE_SUPPLIER_ERR,
          payload: { supplier, action },
        });
      });
  };

export const fetchPrebuiltStores = (tab) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/prebuilt_stores/?tab=${tab}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/GET_PREBUILT_STORES_RES",
        payload: { prebuiltStores: response },
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error fetching report" },
      });
    });
};

export const runAdminCommand = (action, data) => (dispatch) =>
  new Promise((resolve) => {
    let url = `${api}/admin_commands/`;

    if (action === "MANAGE_DISCOUNTS") {
      url = url + "manage_discount/";
    }

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action, data }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  });

export const updatePrebuiltStore = (store_id, action) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: "admin/UPDATE_PREBUILT_STORE_REQ",
      payload: { store_id, action },
    });
    fetch(`${api}/prebuilt_stores/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ store_id, action }),
    })
      .then((response) => checkStatus(response))
      .then(() => {
        dispatch({
          type: "admin/UPDATE_PREBUILT_STORE_RES",
          payload: { store_id, action },
        });
        resolve();
      })
      .catch(() => {
        dispatch({
          type: "admin/UPDATE_PREBUILT_STORE_ERR",
          payload: { store_id, action },
        });
      });
  });

export const fetchSupplierManagement =
  (sorting = "default", status = "inactive") =>
  (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/supplier_management/?sorting=${sorting}&status=${status}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: GET_SUPPLIER_MANAGEMENT_RES,
          payload: { suppliers: response },
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error fetching report" },
        });
      });
  };

export const fetchAnalytics = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/analytics/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_ANALYTICS_RES,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const sendMarketingEmail = (data) => (dispatch) => {
  fetch(`${api}/marketing_emails/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const fetchEmailData = (urlOffer) => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/marketing_emails/?url_offer=${urlOffer}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: GET_EMAIL_DATA_RES,
        payload: { emailData: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const fetchIssues = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/issues/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("adminAccessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: "admin/GET_ISSUES_RES",
        payload: { issues: response },
      });
    })
    .catch((error) => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error },
      });
    });
};

export const answerRefundRequest = (refundId, data) => (dispatch) =>
  new Promise((resolve) => {
    fetch(`${api}/api/refunds/${refundId}/answer/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("adminAccessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (response.error) {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_ERR,
            payload: { error: response.error },
          });
        } else {
          resolve(response);
          dispatch({
            type: ANSWER_REFUND_RES,
            payload: response.refunds,
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: ANSWER_REFUND_ERR,
          payload: { error: response.error },
        });
      });
  });
