import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { theme } from "../modules/Theme";
import { setError } from "../Actions";
import { getIsFetchingSettings, getSettings } from "../Selectors";
import {
  getIsFetchingSettings as getIsFetchingSupplierSettings,
  getSettings as getSupplierSettings,
} from "../views/supplier/SupplierSelectors";
import { getAppName, getDefaultStoreRoute } from "../modules/Format";
import { Row } from "./Layout";
import Spinner from "./Spinner";
import SidebarItemMobile from "./SidebarItemMobile";
import styled from "styled-components";
import Cookies from "js-cookie";
import { get } from "lodash";
import {
  fetchProducts,
  fetchSuppliers,
} from "../views/products/ProductsActions";
import appIcon from "../images/dc-box-2021-white-grey.svg";
import {
  storeSidebarItems,
  supplierSidebarItems,
} from "../modules/SidebarItems";
import { FiMenu } from "react-icons/fi";

const mapStateToProps = (state) => ({
  storeSettings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
  isFetchingSettings: getIsFetchingSettings(state),
  isFetchingSupplierSettings: getIsFetchingSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setError, fetchProducts, fetchSuppliers }, dispatch),
});

const defaultProps = {};

const showLabels = true;

class SidebarMobile extends Component {
  state = { expanded: false };

  UNSAFE_componentWillMount() {
    if (!Cookies.get("accessToken")) {
      this.props.history.push("/");
    }
  }

  logout = () => {
    Cookies.remove("accessToken");
    this.props.history.push("/");
  };

  render() {
    const path = this.props.match.path;
    const isSupplier = get(this.props, ["supplierSettings", "id"]);
    let productCount = get(this.props, ["supplierSettings", "product_count"]);
    const storeAccountType = get(this.props, ["storeSettings", "account_type"]);

    const supplierAccountType = get(this.props, [
      "supplierSettings",
      "account_type",
    ]);

    const privatePortal = get(this.props, [
      "storeSettings",
      "store",
      "private_portal",
    ]);

    const supplierPrivatePortal =
      get(this.props, ["supplierSettings", "subdomain"]) !== "";

    const hidePrebuilt = get(this.props, [
      "storeSettings",
      "store",
      "hide_prebuilt",
    ]);

    const platformName = get(this.props, [
      "storeSettings",
      "store",
      "platform",
      "name",
    ]);

    const appName = getAppName();
    const pathName = get(this.props, ["location", "pathname"]);
    const storeItems = storeSidebarItems(
      storeAccountType,
      this.props.history,
      privatePortal,
      path,
      pathName,
      hidePrebuilt,
      platformName,
      this.logout,
      appName,
      this.props.handleOpen
    );
    const supplierItems = supplierSidebarItems(
      supplierAccountType,
      productCount,
      supplierPrivatePortal,
      this.props.history,
      path,
      this.logout
    );

    if (
      this.props.isFetchingSettings ||
      this.props.isFetchingSupplierSettings
    ) {
      return (
        <StyledSideBar>
          <Spinner color="white" size="small" />
        </StyledSideBar>
      );
    }

    return (
      <StyledSideBar>
        <Row
          style={{
            justifyContent: "space-between",
            padding: "10px",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
          }}
        >
          {!privatePortal && (
            <Logo
              src={appIcon}
              onClick={() =>
                this.props.history.push(
                  isSupplier ? "/supplier/products" : getDefaultStoreRoute
                )
              }
            />
          )}
          <FiMenu
            style={{ cursor: "pointer" }}
            size="30"
            color="white"
            onClick={() =>
              this.setState((prevState) => ({ expanded: !prevState.expanded }))
            }
          />
        </Row>
        {this.state.expanded && (
          <ItemsContainer>
            {!isSupplier &&
              storeItems.map((item, i) => (
                <SidebarItemMobile
                  key={i}
                  marginBottom={item.marginBottom}
                  showLabels={showLabels}
                  text={item.text}
                  icon={item.icon}
                  active={item.active}
                  onClick={item.onClick}
                />
              ))}

            {isSupplier &&
              supplierItems.map((item, i) => (
                <SidebarItemMobile
                  key={i}
                  marginBottom={item.marginBottom}
                  showLabels={showLabels}
                  text={item.text}
                  icon={item.icon}
                  active={item.active}
                  onClick={item.onClick}
                />
              ))}
          </ItemsContainer>
        )}
      </StyledSideBar>
    );
  }
}

SidebarMobile.defaultProps = defaultProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMobile)
);

const Logo = styled.img`
  height: 30px;
`;

const StyledSideBar = styled.div`
  background: ${theme.colors.darkGreen};
  width: 100%;
  display: none;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
`;
