import React, { Component } from "react";
import Cookies from "js-cookie";
import Account, {
  AccountButton,
  AccountInput,
  Form,
} from "./components/AccountComponents";

import { trackEvent } from "../../modules/EventTracking";
import { isEmpty } from "lodash";

class DFSSignup extends Component {
  state = {
    url: "",
    errors: {},
  };

  isValid = () => {
    const errors = {};
    if (!this.state.url.includes(".myshopify.com")) {
      errors.url = "Please enter a valid .myshopify.com URL";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  submit = () => {
    if (!this.isValid()) return;

    const url = `http://app.dropcommerce.com/login/?hmac=b235201e364e4c5b4d7a854d9fbf2ee36cdab5bcbcf08347132be78e98a6f342&shop=${this.state.url}&timestamp=1536538118`;
    window.location.href = url;
  };

  componentDidMount = () => {
    trackEvent("DropshipForSale Signup Page");
    Cookies.set("dc_referral_source", "dropshipforsale", { expires: 30 });
  };

  render() {
    return (
      <Account
        sideImage
        title="Install By URL"
        subtitle="Ensure you are logged into the Shopify store in the same browser before beginning."
      >
        <Form>
          <AccountInput
            onChange={(e) => this.setState({ url: e.target.value })}
            placeholder=".myshopify url"
            value={this.state.url}
            error={this.state.errors.url}
          />
          <AccountButton text="Submit" onClick={this.submit} />
        </Form>
      </Account>
    );
  }
}

export default DFSSignup;
