import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { updateSettings, updateStateDirectly } from "../../../../Actions";
import { getSettings } from "../../../../Selectors";
import styled from "styled-components";
import { theme } from "../../../../modules/Theme";
import { Form, Row, Spinner } from "../../../../components";
import {
  SettingsPageWrapper,
  SettingsSubWrapper,
} from "../../../../views/settings/SettingsStyle";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSettings,
      updateStateDirectly,
    },
    dispatch
  ),
});

export const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  border-radius: 4px;
  margin-left: 30px;
  @media screen and (max-width: 800px) {
    margin-left: auto;
    margin-top: 16px;
  }
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
`;

const ActionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const ActionDescription = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
`;

class Notifications extends Component {
  updateStore = (field, value) => {
    this.props.updateStateDirectly([
      { key: "settings" },
      { key: "store" },
      { key: "notification_settings", fields: [[field, value]] },
    ]);
  };

  render() {
    const selectOptions = [
      {
        value: "NONE",
        label: "No Action",
      },
      {
        value: "EMAIL_ONLY",
        label: "Email Only",
      },
      {
        value: "EMAIL_AND_REMOVE",
        label: "Email And Remove",
      },
      {
        value: "REMOVE_ONLY",
        label: "Remove Only",
      },
    ];

    const labelMap = {
      NONE: "No Action",
      EMAIL_ONLY: "Email Only",
      EMAIL_AND_REMOVE: "Email And Remove",
      REMOVE_ONLY: "Remove Only",
    };

    const notificationSettings = get(this.props, [
      "settings",
      "store",
      "notification_settings",
    ]);

    const items = [
      {
        type: "select",
        label: "Product Removed",
        key: "product_removed",
        hint: "The action to take if a product is removed by a supplier and no longer available for dropshipping.",
        selectOptions,
        labelMap,
      },
      {
        type: "select",
        label: "Product Price Changed",
        key: "product_price_changed",
        hint: "The action to take if a product's price changes.",
        selectOptions,
        labelMap,
      },
      {
        type: "select",
        label: "Product Out Of Stock",
        key: "product_out_of_stock",
        hint: "The action to take is a product goes out of stock.",
        selectOptions,
        labelMap,
      },
      {
        type: "select",
        label: "Product Low Stock",
        key: "product_low_stock",
        hint: "The action to take is a product becomes 'low stock'.",
        selectOptions,
        labelMap,
      },
      {
        type: "select",
        label: "Supplier Removed",
        key: "supplier_removed",
        hint: "The action to take if a supplier is removed from or leaves DropCommerce, making their products unavailable for dropshipping.",
        selectOptions,
        labelMap,
      },
      {
        type: "select",
        label: "Shipping Rate Changed",
        key: "shipping_rate_changed",
        hint: "The action to take if a shipping rate for a specific product or supplier changes.",
        selectOptions,
        labelMap,
      },
    ];

    return (
      <SettingsPageWrapper>
        <SettingsSubWrapper>
          {isEmpty(this.props.settings) ? (
            <Spinner />
          ) : (
            <Row flexDirection="column">
              <Form
                width="auto"
                title="User Defined Actions"
                items={items}
                labelWidth="200px"
                onChange={(field, value) => this.updateStore(field, value)}
                stateLocation={notificationSettings}
                action={{
                  type: "POST",
                  endpoint: "/store_notification_settings/",
                }}
              />
              <ActionsWrapper>
                <SectionTitle>Action Descriptions</SectionTitle>
                <ActionTitle>No Action</ActionTitle>
                <ActionDescription>
                  Take no action when a change happens.
                </ActionDescription>
                <ActionTitle>Email Only</ActionTitle>
                <ActionDescription>
                  When a change triggers this action, you will receive an email
                  notifying you.
                </ActionDescription>
                <ActionTitle>Email And Remove</ActionTitle>
                <ActionDescription>
                  When a change triggers this action, the product or supplier's
                  products will be removed from your store, and you will receive
                  an email notifying you
                </ActionDescription>
                <ActionTitle>Remove Only</ActionTitle>
                <ActionDescription>
                  When a change triggers this action, the product or supplier's
                  products will be removed from your store, but you will not
                  receive a notification.
                </ActionDescription>
              </ActionsWrapper>
            </Row>
          )}
        </SettingsSubWrapper>
      </SettingsPageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
