import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { theme } from "../modules/Theme";
import styled from "styled-components";
import { FiLogOut, FiSettings, FiUser } from "react-icons/fi";
import Cookies from "js-cookie";
import { getUrlSubdomain } from "../modules/Format";
import { PopupMenu } from "./PopupMenu";
import { connect } from "react-redux";
import { clearSettings } from "../actionCreators";
import { clearSupplierSettings } from "../views/supplier/actionCreators";

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

class Topbar extends Component {
  logout = () => {
    this.props.dispatch(
      this.props.isStore ? clearSettings() : clearSupplierSettings()
    );
    Cookies.remove("accessToken");
    this.props.history.push(`/login`);
  };

  subdomain = getUrlSubdomain();

  render() {
    const actions = [
      {
        icon: FiSettings,
        text: "Settings",
        onClick: () =>
          this.props.history.push(
            this.props.isStore ? "/settings" : "/supplier/settings"
          ),
        hide: this.subdomain === "prebuilt",
      },
      {
        icon: FiLogOut,
        text: "Logout",
        onClick: this.logout,
      },
    ];

    return (
      <TopbarContainer>
        <PopupMenu
          popupMenuOptions={actions}
          title={this.props.name}
          icon={{ element: FiUser, color: theme.colors.white, hover: true }}
        />
      </TopbarContainer>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Topbar));

const TopbarContainer = styled.div`
  display: flex;
  height: 35px;
  background-color: ${theme.colors.newDarkGreen};
  justify-content: right;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 400;

  @media (max-width: 800px) {
  }
`;
