import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { forgotPassword } from "../../Actions";
import { getIsFetching, getSubdomainData } from "../../Selectors";
import { getUrlParameter, getUrlSubdomain } from "../../modules/Format";
import createNotification from "../../modules/Notification";
import { get, isEmpty } from "lodash";

import Account, {
  AccountInput,
  AccountButton,
  AccountLink,
  Form,
} from "./components/AccountComponents";
import { validEmail } from "../../validation";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  subdomainData: getSubdomainData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      forgotPassword,
    },
    dispatch
  ),
});

class Forgot extends Component {
  state = {
    errors: {},
    email: "",
    success: false,
  };

  onChange = (e) => {
    if (e.key === "Enter") {
      this.sendEmail();
    }
  };

  isValid = () => {
    const { email } = this.state;

    const errors = {};

    if (!validEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    this.setState((previousState) => ({ ...previousState, errors: errors }));

    return isEmpty(errors);
  };

  sendEmail = () => {
    const { email } = this.state;

    if (this.isValid()) {
      this.props
        .forgotPassword(email)
        .then((response) => {
          if (response.success) {
            this.setState({ success: true });
            createNotification({
              type: "success",
              message: "Password reset email sent successfully.",
            });
          } else {
            createNotification({
              type: "danger",
              message:
                "Password reset email failed. Invalid email. Please contact support@dropcommerce.com",
            });
          }
        })
        .catch((error) => {
          createNotification({
            type: "danger",
            message:
              "Password reset email failed. Invalid email. Please contact support@dropcommerce.com",
          });
        });
    }
  };

  render() {
    const subdomain = getUrlSubdomain();
    const isSupplierPortal = subdomain !== "app";
    const logo = get(this.props, ["subdomainData", "logo"]);
    const fetchingSubdomainData = get(this.props, [
      "subdomainData",
      "isFetching",
    ]);

    const accountExists =
      getUrlParameter("type", this.props.location) === "account_exists";

    const subtitle = accountExists
      ? "An account with this email already exists. Use the form below to receive a login link by email."
      : "Please enter the email associated with your account.";

    if (this.state.success)
      return (
        <Account
          sideImage={!isSupplierPortal}
          isSupplierPortal={isSupplierPortal}
          logo={logo}
          isFetching={this.props.isFetching > 0 || fetchingSubdomainData}
          title="Email Sent"
          subtitle="Please check your email for a password reset link!"
        >
          <AccountButton
            text="Return To Login"
            onClick={() => this.props.history.push("/login")}
          />
        </Account>
      );
    else
      return (
        <Account
          title="Forgot Password"
          subtitle={subtitle}
          sideImage={!isSupplierPortal}
          isSupplierPortal={isSupplierPortal}
          logo={logo}
          isFetching={this.props.isFetching > 0 || fetchingSubdomainData}
        >
          <Form>
            <AccountInput
              value={this.state.email}
              maxWidth="100%"
              error={this.state.errors.email}
              placeholder="Your email"
              onChange={(text) => this.setState({ email: text.target.value })}
              onKeyPress={(e) => this.onChange(e)}
            />
            <AccountButton
              text="Send Login Email"
              onClick={this.sendEmail}
              isFetching={this.props.isFetching}
            />
            <AccountLink
              margin="20px 0 0 0"
              normalText="I remembered by password."
              linkText="Login"
              onClick={() => this.props.history.push("/login")}
            />
          </Form>
        </Account>
      );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));
