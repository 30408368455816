import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import MDSpinner from "react-md-spinner";
import useSWR from "swr";
import { errorNotification } from "../../../../modules/Notification";
import { theme } from "../../../../modules/Theme";
import { ToggleColorMode } from "../../ToggleColorMode";
import {
  lateOrdersUrlEndpoint as cacheKey,
  getLateOrders,
} from "../../api/lateOrdersApi";
import { SuppliersTable } from "./SuppliersTable";
import { transformSuppliers } from "./utils";

function LateOrders() {
  const {
    isLoading,
    error,
    data: suppliers,
  } = useSWR(cacheKey, getLateOrders, {
    onSuccess: (data) => data,
    onError: (err) => errorNotification(err.message),
  });

  let content = null;
  if (isLoading) {
    content = (
      <div style={{ display: "grid", placeItems: "center", padding: "80px 0" }}>
        <MDSpinner singleColor={theme.colors.main} size={50} />
      </div>
    );
  } else if (!error) {
    const transformedSuppliers = transformSuppliers(suppliers);

    content = <SuppliersTable suppliers={transformedSuppliers} />;
  }

  return (
    <ToggleColorMode>
      <CssBaseline />
      <main>{content}</main>
    </ToggleColorMode>
  );
}

export default LateOrders;
