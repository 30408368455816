import React, { Component } from "react";
import styled from "styled-components";
import { Row } from "../../../../../components/Layout";

import { theme } from "../../../../../modules/Theme";
import MDSpinner from "react-md-spinner";
import { truncateText, resizeImage } from "../../../../../modules/Format";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { getSettings } from "../../../../../Selectors";
import {
  fetchImportListProduct,
  removeFromImportList,
  importToStore,
} from "../../../../../views/products/ProductsActions";
import ProductButtons from "../../../../../views/imports/components/ProductButtons";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchImportListProduct,
      removeFromImportList,
      importToStore,
    },
    dispatch
  ),
});

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 30px;
  &:hover {
    background: #f9f9f9;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Thumbnail = styled.img`
  height: 60px;
  width: 60px;
  object-fit: ${(props) => (props.crop ? "cover" : "contain")};
  border: 1px solid ${theme.colors.lightBorder};
  margin-right: 20px;
  @media (max-width: 800px) {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
`;

const TitleText = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

const SupplierNameText = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  color: ${theme.colors.medDarkGrey};
`;

const EditDetailsText = styled.div`
  font-weight: 300;
  margin-top: 15px;
  color: ${theme.colors.medDarkGrey};
  font-size: 16px;
  @media (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const ErrorText = styled.div`
  font-weight: 300;
  margin-top: 15px;
  color: red;
  font-size: 20px;
  @media (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const SpinnerRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

class MinimizedProduct extends Component {
  removeFromList = (e, importedProductId) => {
    e.stopPropagation();
    this.props.removeFromImportList(importedProductId);
  };

  importToStore = (e) => {
    e.stopPropagation();
    const importedProductId = get(this.props, [
      "product",
      "imported_product_id",
    ]);
    this.props.importToStore([importedProductId]);
  };

  render() {
    const { product } = this.props;

    return (
      <Container>
        <Row style={{ alignItems: "center", width: "100%" }}>
          <Thumbnail src={resizeImage(product.image, 150)} />
          {!product.isFetching ? (
            <div>
              <TitleText>{truncateText(product.title, 40)}</TitleText>
              <SupplierNameText>{product.supplier.name}</SupplierNameText>
              {!product.imported && product.error && (
                <ErrorText>{product.error}</ErrorText>
              )}
              {!product.error && product.imported && (
                <EditDetailsText>
                  {product.imported
                    ? "Product imported. It may take up to a few minutes to show up in your store."
                    : "Click to edit details"}
                </EditDetailsText>
              )}
            </div>
          ) : (
            <SpinnerRow>
              <MDSpinner singleColor="rgb(73,190,175)" size={50} />
            </SpinnerRow>
          )}
        </Row>

        {!product.isFetching && (
          <ProductButtons
            product={product}
            importToStore={this.importToStore}
            removeFromList={this.removeFromList}
            minimized
          />
        )}
      </Container>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MinimizedProduct)
);
