import React, { Component } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { InputLabel } from "../../../../../../views/products/components/ProductInput";
import { Row, Column, ButtonNew } from "../../../../../../components";
import Text from "../../../../../../modules/Text";
import { get } from "lodash";
import Variants from "../Variants";
import ProductInput from "../../../../../../views/products/components/ProductInput";
import SelectableImage from "../SelectableImage";
import { Editor } from "react-draft-wysiwyg";

import styled from "styled-components";
import { theme } from "../../../../../../modules/Theme";
import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";
import Select from "react-select";
import { resizeImage } from "../../../../../../modules/Format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSettings } from "../../../../../../Selectors";
import { removeFromImportList } from "../../../../../../views/products/ProductsActions";
import { withRouter } from "react-router-dom";
import { getStoreCategories } from "../../../../../../views/products/ProductsSelectors";
import ProductButtons from "../../../../../../views/imports/components/ProductButtons";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  storeCategories: getStoreCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      removeFromImportList,
    },
    dispatch
  ),
});

export const ILProductColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ILProductRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(p) => p.flexWrap && "flex-wrap: wrap;"}
`;

export const ILProductMainImage = styled.img`
  height: 250px;
  width: 250px;
  object-fit: ${(props) => (props.crop ? "cover" : "contain")};
  border: 1px solid ${theme.colors.lightBorder};
  margin-right: 20px;
  margin-bottom: 23px;

  @media (max-width: 800px) {
    height: 80px;
    width: 80px;
    object-fit: ${(props) => (props.crop ? "cover" : "contain")};
    border: 1px solid ${theme.colors.lightBorder};
    margin-right: 15px;
  }
`;

export const ILProductWarningHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const WarningsHeader = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
`;

export const WarningIcon = styled.img`
  height: 30px;
`;

export const ILProductWarningText = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
`;

export const ILTagsContainer = styled.div`
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  padding: 7px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

export const Container = styled.div`
  background: ${theme.colors.white};
  margin-bottom: 30px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 100%;
`;

export const PricingSectionWrapper = styled.div`
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.lightBorder};
  padding-top: 30px;
  padding: 25px;
  min-height: 300px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const ProductTabDesktopColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const ProductTabMobileColumn = styled.div`
  display: none;
  flex-direction: column;
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const StyledButton = styled(ButtonNew)`
  ${(p) => p.marginRight && "margin-right: 10px;"}
  @media (max-width: 800px) {
    font-size: 12px;
    margin: 0px;
    ${(p) => p.marginRight && "margin-right: 5px;"}
    width: 100%;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  justify-content: flex-end;
  height: 60px;
  padding-right: 15px;
`;

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ProductMain extends Component {
  productTabMobile() {
    const {
      product,
      onChange,
      handleDelete,
      handleAddition,
      handleDrag,
      storeCategories,
    } = this.props;

    const { tags, suggestions, title, type, collections } = this.props.state;

    return (
      <ProductTabMobileColumn>
        <Column>
          <Row style={{ marginBottom: "15px", alignItems: "center" }}>
            <ILProductMainImage
              src={product.images[0].url}
              crop={product.crop_image}
            />
            <div>
              <Text light extra="margin-bottom: 5px;">
                {product.title}
              </Text>
              <Text
                onClick={() =>
                  this.props.history.push(`/supplier-id/${product.supplier.id}`)
                }
              >
                by {product.supplier.name}
              </Text>
            </div>
          </Row>
          <ProductInput
            label={"Display Name"}
            value={title}
            onChange={(text) => onChange(text, "title")}
            fieldId={"name"}
            productId={product.id}
            type={"text"}
            fontSize="15px"
            padding="12px"
            width="100%"
          />
          <InputLabel>Tags</InputLabel>
          <ILTagsContainer>
            <ReactTags
              tags={tags}
              suggestions={suggestions}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              delimiters={delimiters}
              autofocus={false}
              maxLength={255}
            />
          </ILTagsContainer>
          <ProductInput
            label={"Product Type"}
            value={type}
            onChange={(text) => onChange(text, "type")}
            fieldId={"product_type"}
            productId={product.id}
            type={"text"}
            fontSize="15px"
            padding="12px"
            width="100%"
            marginRight="20px"
          />
          <div>
            <InputLabel style={{ marginBottom: "5px" }}>Collections</InputLabel>
            <Select
              closeMenuOnSelect={false}
              value={collections}
              isMulti
              options={storeCategories}
              onChange={(data) => onChange("collections", data)}
            />
          </div>
        </Column>
      </ProductTabMobileColumn>
    );
  }

  productTabDesktop() {
    const {
      product,
      onChange,
      handleDelete,
      handleAddition,
      handleDrag,
      storeCategories,
    } = this.props;

    const { tags, suggestions, title, type, collections } = this.props.state;

    return (
      <ProductTabDesktopColumn>
        <div>
          <Text light extra="margin-bottom: 10px;">
            {product.title}
          </Text>
          <Text
            extra="margin-bottom: 15px; cursor: pointer; display: inline-block;"
            onClick={() =>
              this.props.history.push(`/supplier-id/${product.supplier.id}`)
            }
          >
            {product.supplier.name}
          </Text>
        </div>
        <ILProductRow>
          <ILProductMainImage
            src={resizeImage(product.images[0].url, 350)}
            crop={product.crop_image}
          />
          <ILProductColumn>
            <ProductInput
              label={"Display Name"}
              value={title}
              onChange={(text) => onChange(text, "title")}
              fieldId={"name"}
              productId={product.id}
              type={"text"}
              fontSize="15px"
              padding="12px"
              width="100%"
            />
            <div>
              <InputLabel>Tags</InputLabel>
              <ILTagsContainer>
                <ReactTags
                  tags={tags}
                  suggestions={suggestions}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  delimiters={delimiters}
                  autofocus={false}
                  maxLength={255}
                />
              </ILTagsContainer>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <InputLabel style={{ marginBottom: "5px" }}>
                Collection
              </InputLabel>
              <Select
                closeMenuOnSelect={false}
                value={collections}
                isMulti
                options={storeCategories}
                onChange={(data) => onChange("collections", data)}
              />
            </div>
            <ILProductRow style={{ alignItems: "flex-start" }}>
              <ProductInput
                label={"Product Type"}
                value={type}
                onChange={(text) => onChange(text, "type")}
                fieldId={"product_type"}
                productId={product.id}
                type={"text"}
                fontSize="15px"
                padding="12px"
                width="100%"
                marginRight="20px"
              />
            </ILProductRow>
          </ILProductColumn>
        </ILProductRow>
      </ProductTabDesktopColumn>
    );
  }

  render() {
    const {
      product,
      setLocalState,
      selectImage,
      onEditorStateChange,
      selectVariant,
      editVariantField,
      editAll,
      saveProduct,
      importToStore,
    } = this.props;

    const { tab, description, variants, mapError, images, variantError } =
      this.props.state;

    const storeCurrency = get(this.props, ["settings", "store", "currency"]);

    return (
      <Container>
        <Row>
          <Column style={{ width: "100%" }}>
            <Navigation
              product={product}
              tab={tab}
              setLocalState={setLocalState}
            >
              <ProductButtons
                product={product}
                importToStore={importToStore}
                saveProduct={saveProduct}
              />
            </Navigation>
            <NavigationMobile
              product={product}
              tab={tab}
              setLocalState={setLocalState}
            />

            {product.error && (
              <Text.Small
                light
                style={{
                  color: "#be4958",
                  marginTop: "15px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              >
                {product.error}
              </Text.Small>
            )}

            {variantError && (
              <Text.Small
                light
                style={{
                  color: "#be4958",
                  marginTop: "15px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              >
                {variantError}
              </Text.Small>
            )}

            {tab === "product" && (
              <React.Fragment>
                {this.productTabDesktop()}
                {this.productTabMobile()}
              </React.Fragment>
            )}

            {tab === "description" && (
              <Editor
                editorState={description}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{
                  padding: "20px",
                }}
                toolbarStyle={{
                  border: "0px",
                  borderBottom: "1px",
                  marginTop: "10px",
                }}
              />
            )}
            {tab === "variants" && (
              <Variants
                variants={variants}
                product={product}
                selectVariant={selectVariant}
                editField={editVariantField}
                editAll={editAll}
                mapError={mapError}
                userCurrency={storeCurrency}
              />
            )}

            {tab === "images" && (
              <ILProductRow flexWrap style={{ padding: "15px" }}>
                {images.map((image, i) => (
                  <SelectableImage
                    key={i}
                    selected={image.selected}
                    image={image.url}
                    updateSelected={() => selectImage(image.id)}
                  />
                ))}
              </ILProductRow>
            )}
          </Column>
        </Row>
      </Container>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductMain)
);
