import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";

export const Tag = styled.div`
  padding: 8px;
  background: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  margin-right: 10px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.colors.medGrey};
  }
`;

export const TagsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
`;

class Tags extends Component {
  render() {
    const { tags } = this.props;
    return (
      <React.Fragment>
        {tags.length > 0 && (
          <div>
            <Text.Small>Popular Tags</Text.Small>
            <TagsRow>
              {tags.map((tag, idx) => (
                <Tag key={idx} onClick={() => this.props.onClick(tag)}>
                  {tag}
                </Tag>
              ))}
            </TagsRow>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Tags;
