import { ButtonNew, Input, Row } from "../../../components";
import React, { Component } from "react";
import styled from "styled-components";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import MDSpinner from "react-md-spinner";

class Account extends Component {
  render() {
    // Render loading state
    if (this.props.isFetching) {
      return (
        <PageWrapper
          subdomain={this.props.subdomain}
          position={this.props.position}
          sideImage={this.props.sideImage}
        >
          Powered by
          <AccountTitle style={{ marginBottom: "10px" }}>
            {this.props.isSupplierPortal ? "Loading" : "DropCommerce"}
          </AccountTitle>
          <MDSpinner singleColor={theme.colors.main} size={50} />
        </PageWrapper>
      );
    }

    // Render Main Content
    return (
      <PageWrapper
        subdomain={this.props.subdomain}
        position={this.props.position}
        sideImage={this.props.sideImage}
        allowOverflow={this.props.allowOverflow}
      >
        {this.props.logo && <Logo src={this.props.logo} />}
        <AccountTitle>{this.props.title}</AccountTitle>
        {this.props.subtitle && (
          <AccountSubtitle>{this.props.subtitle}</AccountSubtitle>
        )}
        {this.props.children}
      </PageWrapper>
    );
  }
}

export default Account;

export const AccountButton = ({ text, onClick, isFetching, disabled }) => {
  return (
    <ButtonNew
      text={text}
      type="main"
      extra="margin-top: 20px; padding: 15px; border-radius: 8px 0px 8px 0px;"
      fontStyle="font-size: 18px;"
      onClick={onClick}
      isFetching={isFetching}
      disabled={disabled}
    />
  );
};

const Logo = styled.img`
  height: 70px;
  margin-bottom: 30px;
`;

export const AccountInput = styled(Input)`
  background: #f2fffe;
  border: 0;
  color: ${theme.colors.darkGreen};
  padding: 21px;
  border-radius: 8px 0px 8px 0px;
  margin-top: 15px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${theme.colors.darkGreen};
    opacity: 0.7;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${theme.colors.darkGreen};
    opacity: 0.7;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${theme.colors.darkGreen};
    opacity: 0.7;
  }
`;

export const AccountLink = ({
  normalText,
  linkText,
  onClick,
  margin = "0px",
}) => {
  return (
    <Row
      style={{
        margin,
        width: "100%",
        justifyContent: "center",
      }}
    >
      {normalText && (
        <Text.Small light extra="margin-right: 5px;">
          {normalText}
        </Text.Small>
      )}
      {linkText && (
        <Text.Small light link onClick={onClick}>
          {linkText}
        </Text.Small>
      )}
    </Row>
  );
};

const PageWrapper = ({
  children,
  subdomain,
  position,
  sideImage,
  allowOverflow,
}) => {
  return (
    <StyledWrapper position={position} allowOverflow={allowOverflow}>
      <PageWrapperRow>
        <PageWrapperLeft allowOverflow={allowOverflow} image={sideImage}>
          {children}
        </PageWrapperLeft>
        {sideImage && (
          <PageWrapperImage
            subdomain={subdomain}
            alt="Office People"
            src="https://dc-imports.s3.ca-central-1.amazonaws.com/signup-office.jpg"
          />
        )}
      </PageWrapperRow>
    </StyledWrapper>
  );
};

const LoginOption = ({ name, image, link }) => (
  <LoginOptionWrapper onClick={() => window.open(link)}>
    <LoginOptionText>LOGIN WITH</LoginOptionText>
    <LoginOptionImage src={image} />
  </LoginOptionWrapper>
);

export const LoginOptions = (appName) => (
  <LoginOptionsRow>
    <LoginOption
      name="Shopify"
      image="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/shopify_nnjsqq.png"
      // TODO: CHANGE URL BASED ON APP
      link="https://apps.shopify.com/dropcommerce"
    />
    {appName === "MAIN" && (
      <LoginOption
        name="BigCommerce"
        image="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/bigcommerce_de9kjg.png"
        link="https://www.bigcommerce.com/apps/dropcommerce-us-dropshipping/"
      />
    )}
    {appName === "MAIN" && (
      <LoginOption
        name="Wix"
        image="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/1024px-Wix.com_website_logo.svg.png"
        link="https://www.wix.com/app-market/dropcommerce-us-dropshipping"
      />
    )}
    {/* <LoginOption
      name="WooCommerce"
      image="https://res.cloudinary.com/dropcommerce/image/upload/v1602698747/WooCommerce_logo_Woo_Commerce_ca5wos.png"
      link="https://apps.shopify.com/dropcommerce"
    /> */}
  </LoginOptionsRow>
);

const LoginOptionImage = styled.img`
  height: 27px;
  margin-top: 5px;
  @media (max-width: 1400px) {
    height: 22px;
  }
`;

const LoginOptionText = styled.div`
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 300;
  opacity: 0.7;
  @media (max-width: 1400px) {
    font-size: 11px;
  }
`;

const LoginOptionsRow = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const LoginOptionWrapper = styled.div`
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.ultraLightGrey};
  }
  @media (max-width: 1400px) {
    width: 160px;
  }
`;

const PageWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.sideImage ? "50%" : "100%")};
  ${(p) => p.allowOverflow && "max-width: 1300px;"}
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const PageWrapperImage = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
  ${(props) =>
    props.subdomain === "prebuilt" &&
    "filter: sepia(300%) hue-rotate(150deg) saturate(450%)"};

  @media (max-width: 1000px) {
    display: none;
  }
`;

const PageWrapperRow = styled.div`
  display: flex;
  height: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  ${(p) => !p.allowOverflow && "height: 100%;"}
  ${(p) => p.position === "static" && !p.allowOverflow && "padding-top: 50px;"}
  ${(p) => p.position !== "static" && "position: fixed; bottom: 0; top: 0;"}
  @media (max-width: 800px) {
    text-align: center;
    ${(p) => !p.allowOverflow && "padding: 50px;"}
  }
  @media (max-width: 600px) {
    text-align: center;
    ${(p) => !p.allowOverflow && "padding: 20px;"}
  }
`;

export const Form = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const AccountTitle = styled.div`
  font-size: 45px;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  color: ${theme.colors.darkGreen};
  font-family: "DMSerifDisplay", sans-serif;
  @media (max-width: 800px) {
    font-size: 40px;
  }
`;

export const AccountSubtitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;
  color: #57777a !important;
  @media (max-width: 800px) {
    font-size: 18px;
    margin-top: 10px;
  }
`;
