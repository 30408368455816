import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchAudit,
  updateAudit,
  submitAudit,
} from "../../../views/admin/AdminActions";
import { getIsFetching, getAudit } from "../../../views/admin/AdminSelectors";
import Text from "../../../modules/Text";
import { get } from "lodash";
import { Row, Spinner, ButtonNew, Input, Label } from "../../../components";
import createNotification from "../../../modules/Notification";
import Select from "react-select";
import { theme } from "../../../modules/Theme";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  audit: getAudit(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ updateAudit, fetchAudit, submitAudit }, dispatch),
});

class PriceAudit extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.fetchAudit();
  };

  submitAudit = () => {
    const audit = get(this.props, "audit");
    let error = false;
    let productError = false;

    if (audit.error_type === "OTHER" && audit.error === "") {
      productError = true;
    }

    if (audit.error_type === "NONE") {
      audit.variants.forEach((v) => {
        if (v.error_type === "OTHER" && v.error === "") {
          error = true;
        }

        if (
          v.external_retail_price === "" &&
          (v.error_type !== "NONE" || v.error === "")
        ) {
          error = true;
        }
      });
    }

    if (!error && !productError) {
      this.props.submitAudit(audit);
    }

    if (productError) {
      createNotification({
        type: "danger",
        title: "Error",
        message: "Please enter an error message for the product.",
      });
    }

    if (error) {
      createNotification({
        type: "danger",
        title: "Error",
        message:
          "Please enter an error message for all variants with no price.",
      });
    }
  };

  render() {
    const audit = get(this.props, "audit");

    const errorTypeMap = {
      NONE: "None",
      PRODUCT_NOT_FOUND: "Product Not Found",
      VARIANT_NOT_FOUND: "Variant Not Found",
      OTHER: "Other",
    };

    const productErrorOptions = [
      {
        value: "NONE",
        label: "None",
      },
      {
        value: "PRODUCT_NOT_FOUND",
        label: "Product Not Found",
      },
      {
        value: "OTHER",
        label: "Other",
      },
    ];

    const selectOptions = [
      {
        value: "NONE",
        label: "None",
      },
      {
        value: "VARIANT_NOT_FOUND",
        label: "Variant Not Found",
      },
      {
        value: "OTHER",
        label: "Other",
      },
    ];

    return (
      <PageWrapper>
        <Text bold extra="margin-bottom: 20px;">
          Price Audit
        </Text>
        <ButtonNew
          type="main"
          text="Submit"
          onClick={this.submitAudit}
          extra="width: 255px"
        />
        {this.props.isFetching > 0 ? (
          <Spinner />
        ) : (
          <AuditWrapper>
            <Row>
              <Text.Large bold>{audit.product}</Text.Large>
              <Text
                link
                light
                onClick={() => window.open(`https://${audit.supplier_website}`)}
                margin="0 0 0 20px"
              >
                {audit.supplier}
              </Text>
            </Row>

            {audit.show_error ? (
              <Row style={{ width: "600px", marginBottom: "10px" }}>
                <div style={{ width: "300px", marginRight: "20px" }}>
                  <Label extra="margin-bottom: 4px;">Product Error Type</Label>
                  <Select
                    value={{
                      value: audit.error_type,
                      label: errorTypeMap[audit.error_type],
                    }}
                    options={productErrorOptions}
                    onChange={(data) =>
                      this.props.updateAudit("error_type", data.value)
                    }
                  />
                </div>

                <Input
                  label="Product Error Description"
                  onChange={(e) =>
                    this.props.updateAudit("error", e.target.value)
                  }
                />
              </Row>
            ) : (
              <Text
                link
                light
                onClick={() => this.props.updateAudit("show_error", true)}
                margin="20px 0 20px 0"
              >
                Show Product Error
              </Text>
            )}

            <VariantsContainer>
              {audit.error_type === "NONE" &&
                audit.variants &&
                audit.variants.map((v, i) => (
                  <VariantWrapper key={i}>
                    <Text.Large bold margin="0 0 10px 0">
                      {v.name}
                    </Text.Large>

                    {v.error_type !== "VARIANT_NOT_FOUND" && (
                      <React.Fragment>
                        <Text light>
                          Retail Price: <b>{v.retail_price.toFixed(2)}</b>
                        </Text>
                        <Input
                          label="External Retail Price"
                          type="number"
                          onChange={(e) =>
                            this.props.updateAudit(
                              "external_retail_price",
                              e.target.value,
                              v.id
                            )
                          }
                        />
                      </React.Fragment>
                    )}

                    <Label extra="margin-bottom: 4px;">Error Type</Label>
                    <div style={{ width: "100%" }}>
                      <Select
                        value={{
                          value: v.error_type,
                          label: errorTypeMap[v.error_type],
                        }}
                        options={selectOptions}
                        onChange={(data) =>
                          this.props.updateAudit("error_type", data.value, v.id)
                        }
                      />
                    </div>

                    <Input
                      label="Error Description"
                      onChange={(e) =>
                        this.props.updateAudit("error", e.target.value, v.id)
                      }
                    />
                  </VariantWrapper>
                ))}
            </VariantsContainer>
          </AuditWrapper>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PriceAudit)
);

const VariantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const AuditWrapper = styled.div`
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
`;

const VariantWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 15px;
  width: 300px;
`;
