import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchEmailData,
  chooseCampaign,
  chooseVersion,
  chooseUserGroup,
  chooseOffer,
  sendMarketingEmail,
} from "../../../views/admin/AdminActions";
import {
  getEmailData,
  getIsFetching,
} from "../../../views/admin/AdminSelectors";
import { get, isEmpty } from "lodash";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { theme } from "../../../modules/Theme";
import Text from "../../../modules/Text";
import SelectDropdown from "../../../components/SelectDropdown";
import { Row, ButtonNew, Input } from "../../../components";
import { getUrlParameter } from "../../../modules/Format";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const GreyButton = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 16px;
  padding: 15px;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  ${(p) =>
    p.selected &&
    `
      opacity: 1.0;
      border: 2px solid ${theme.colors.main};
      font-weight: 500;
  `}
`;

const UserGroupName = styled.div`
  font-size: 16px;
  color: white;
  font-weight: 400;
`;

const UserGroupCount = styled.div`
  font-size: 14px;
  color: white;
  font-weight: 300;
  opacity: 0.6;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  emailData: getEmailData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchEmailData,
      chooseCampaign,
      chooseVersion,
      chooseOffer,
      chooseUserGroup,
      sendMarketingEmail,
    },
    dispatch
  ),
});

class Emails extends Component {
  state = { maxSendCount: 0, showConfirm: false };

  UNSAFE_componentWillMount = () => {
    const urlOffer = getUrlParameter("offer", this.props.location);
    this.props.fetchEmailData(urlOffer);
  };

  sendEmailCampaign = (selectedCampaign, userGroups, testEmail) => {
    const campaignVersions = get(this.props, [
      "emailData",
      "campaign_versions",
    ]);
    const offers = get(this.props, ["emailData", "offers"]);
    let selectedVersion = null;
    let selectedUserGroup = null;
    let selectedOffer = null;

    offers &&
      offers.forEach((offer) => {
        if (offer.selected) {
          selectedOffer = offer.value;
        }
      });

    campaignVersions &&
      campaignVersions.forEach((version) => {
        if (version.selected) {
          selectedVersion = version.id;
        }
      });

    userGroups &&
      userGroups.forEach((userGroup) => {
        if (userGroup.selected) {
          selectedUserGroup = userGroup.value;
        }
      });

    if (selectedVersion) {
      this.props.sendMarketingEmail({
        max_send_count: this.state.maxSendCount,
        campaign_id: selectedCampaign,
        version_id: selectedVersion,
        user_group: selectedUserGroup,
        offer_id: selectedOffer,
        test_email: testEmail,
      });
      this.setState({ showSent: true });
    }
  };

  render() {
    const emailData = get(this.props, "emailData");
    const campaigns = get(this.props, ["emailData", "campaigns"]);
    const campaignVersions = get(this.props, [
      "emailData",
      "campaign_versions",
    ]);
    const userGroups = get(this.props, ["emailData", "user_groups"]);
    const offers = get(this.props, ["emailData", "offers"]);

    let selectedCampaign = null;
    let selectedCampaignName = null;

    let selectedUserGroup = null;

    // let selectedOffer = null;
    let selectedOfferName = null;

    offers &&
      offers.forEach((offer) => {
        if (offer.selected) {
          // selectedOffer = offer.value;
          selectedOfferName = offer.text;
        }
      });

    userGroups &&
      userGroups.forEach((userGroup) => {
        if (userGroup.selected) {
          selectedUserGroup = userGroup.value;
        }
      });

    campaigns &&
      campaigns.forEach((campaign) => {
        if (campaign.selected) {
          selectedCampaign = campaign.value;
          selectedCampaignName = campaign.text;
        }
      });

    let selectedVersion = null;
    let selectedVersionName = null;

    campaignVersions &&
      campaignVersions.forEach((version) => {
        if (version.selected) {
          selectedVersion = version.id;
          selectedVersionName = version.name;
        }
      });

    if (this.state.showSent) {
      return (
        <PageWrapper>
          <Text.Large white bold extra="margin-bottom: 20px;">
            Email Campaign Started
          </Text.Large>
          <Text white light extra="margin-bottom: 20px;">
            Check slack for updates.
          </Text>
        </PageWrapper>
      );
    }

    if (this.state.showConfirm) {
      return (
        <PageWrapper>
          <Text.Large white bold extra="margin-bottom: 20px;">
            Confirm Send?
          </Text.Large>

          <Text white light extra="margin-bottom: 10px;">
            <strong>User Group:</strong> {selectedUserGroup}
          </Text>

          <Text white light extra="margin-bottom: 10px;">
            <strong>Campaign:</strong> {selectedCampaignName}
          </Text>

          <Text white light extra="margin-bottom: 10px;">
            <strong>Version:</strong> {selectedVersionName}
          </Text>

          <Text white light extra="margin-bottom: 10px;">
            <strong>Offer:</strong> {selectedOfferName}
          </Text>

          <Text white light extra="margin-bottom: 10px;">
            <strong>Count:</strong> {this.state.maxSendCount} Users
          </Text>

          <ButtonNew
            type="main"
            text="Send Campaign"
            extra="width: 180px; padding: 15px; margin-top: 20px;"
            onClick={() =>
              this.sendEmailCampaign(selectedCampaign, userGroups, false)
            }
          />
          <ButtonNew
            type="secondary"
            text="Cancel"
            extra="width: 180px; padding: 15px; margin-top: 20px;"
            onClick={() => this.setState({ showConfirm: false })}
          />
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        {this.props.isFetching > 0 || isEmpty(emailData) ? (
          <MDSpinner singleColor="rgb(255, 255, 255)" size={40} />
        ) : (
          <div>
            <Text.Large white extra="margin-bottom: 30px;">
              Send An Email Campaign
            </Text.Large>

            <Text.Small white bold extra="margin-bottom: 5px;">
              Campaign
            </Text.Small>
            <SelectDropdown
              name="Campaign"
              fontSize="18px"
              options={campaigns}
              onClick={(campaignId) => this.props.chooseCampaign(campaignId)}
              auto
            />

            {selectedCampaign && (
              <React.Fragment>
                <Text.Small
                  white
                  bold
                  extra="margin-bottom: 5px; margin-top: 20px;"
                >
                  Version
                </Text.Small>

                <Row>
                  {campaignVersions &&
                    campaignVersions
                      .filter(
                        (version) => version.campaign_id === selectedCampaign
                      )
                      .map((version) => (
                        <GreyButton
                          key={version.id}
                          selected={version.selected}
                          onClick={() => this.props.chooseVersion(version.id)}
                        >
                          {version.name}
                        </GreyButton>
                      ))}
                </Row>

                {selectedVersion && (
                  <React.Fragment>
                    <Text.Small
                      white
                      bold
                      extra="margin-bottom: 5px; margin-top: 30px;"
                    >
                      Offer
                    </Text.Small>
                    <SelectDropdown
                      name="Offer"
                      fontSize="18px"
                      options={offers}
                      onClick={(offerId) => this.props.chooseOffer(offerId)}
                      auto
                    />

                    <React.Fragment>
                      <Text.Small
                        white
                        bold
                        extra="margin-bottom: 5px; margin-top: 20px;"
                      >
                        User Group
                      </Text.Small>

                      <Row style={{ flexWrap: "wrap" }}>
                        {userGroups &&
                          userGroups.map((userGroup) => (
                            <GreyButton
                              key={userGroup.value}
                              selected={userGroup.selected}
                              onClick={() =>
                                this.props.chooseUserGroup(userGroup.value)
                              }
                            >
                              <UserGroupName>{userGroup.name}</UserGroupName>
                              <UserGroupCount>
                                {userGroup.count} Total Users
                              </UserGroupCount>
                            </GreyButton>
                          ))}
                      </Row>

                      {selectedUserGroup && (
                        <React.Fragment>
                          <Text.Small
                            white
                            bold
                            extra="margin-bottom: 5px; margin-top: 30px;"
                          >
                            Max Email Send Count
                          </Text.Small>

                          <Input
                            type="number"
                            value={this.state.maxSendCount}
                            onChange={(e) =>
                              this.setState({ maxSendCount: e.target.value })
                            }
                          />

                          <ButtonNew
                            type="main"
                            text="Review Details"
                            extra="width: 180px; padding: 15px; margin-top: 20px;"
                            onClick={() => this.setState({ showConfirm: true })}
                          />

                          <ButtonNew
                            type="secondary"
                            text="Send Test Email"
                            extra="width: 180px; padding: 15px; margin-top: 20px;"
                            onClick={() =>
                              this.sendEmailCampaign(
                                selectedCampaign,
                                userGroups,
                                true
                              )
                            }
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Emails));
