import styled from "styled-components";
import { theme } from "../../modules/Theme";

export const OrdersWrapper = styled.div`
  padding: 0px;
  background: ${theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  margin-top: 25px;
`;

export const OrdersNav = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 15px 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  width: 100%;
`;

export const HintTitle = styled.div`
  font-weight: 700;
  font-size: 25px;
  color: ${theme.colors.darkGrey};
  margin-bottom: 10px;
`;

export const HintDescription = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: ${theme.colors.darkGrey};
  margin-bottom: 10px;
`;

export const OrdersNavItem = styled.div`
  font-size: 16px;
  padding-right: 15px;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? theme.colors.main : theme.colors.medGrey};
  font-weight: ${(props) => (props.selected ? 500 : 300)};
  &:hover {
    opacity: 0.7;
  }
`;

export const OrderDetailsWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  background: ${theme.colors.white};
  width: 100%;
  margin-bottom: 15px;
  @media screen and (max-width: 800px) {
    margin-bottom: 55px;
  }
`;

export const OrderDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
  ${(p) => p.error && "border: 3px solid red;"}
`;

export const OrderSupplierItem = styled.div`
  margin: 5px;
`;

export const OrderWrapper = styled.div`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    ${(p) => p.hideMobile && "display:none;"}
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OrdersStripeWarning = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  background: ${theme.colors.white};
  margin-bottom: 10px;
`;

export const OrdersStripeWarningText = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  font-weight: ${(props) => (props.selected ? 500 : 300)};
  margin-bottom: 10px;
`;

export const OrderDetail = styled.div`
  width: ${(props) => props.width || "150px"};
  color: ${(props) => props.color || theme.colors.darkGrey};
  font-weight: ${(props) => props.weight || "300"};
  padding-top: ${(props) => props.padding || "7px"};
  padding-bottom: ${(props) => props.padding || "7px"};
`;

export const OrderInfoSectionHeader = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const OrderInfoSection = styled.div`
  border-bottom: ${(props) => (props.borderBottom ? "1px" : "0px")} solid
    ${theme.colors.lightGrey};
  border-top: ${(props) => (props.borderTop ? "1px" : "0px")} solid
    ${theme.colors.lightGrey};
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const SupplierInOrder = styled.div`
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
`;
