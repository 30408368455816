import React, { Component } from "react";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import { getRequest } from "../../../modules/API";
import { Container } from "../../../components";
import Select from "react-select";
import MDSpinner from "react-md-spinner";
import { Pie, Doughnut, Bar } from "react-chartjs-2";
import styled from "styled-components";

const Card = styled.div`
  height: "64px";
  border-radius: "5px";
  border: "1px solid rgba(0,0,0,0.1)";
`;

export default class SupplierAnalytics extends Component {
  state = {
    selectValue: "salesByDestination",
    selectLabel: "Sales by destination",
    fetching: true,
    salesByVendor: {
      labels: ["Vendor A", "Vendor B"],
      datasets: [
        {
          backgroundColor: [theme.colors.mainLight, theme.colors.mainDark],
          data: [30, 70],
        },
      ],
    },
    salesByDestination: {
      labels: ["California", "New York", "Texas"],
      datasets: [
        {
          backgroundColor: [
            theme.colors.main,
            theme.colors.mainLight,
            theme.colors.mainDark,
          ],
          data: [90, 110, 200],
        },
      ],
    },
    salesByDay: {
      labels: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      datasets: [
        {
          backgroundColor: theme.colors.main,
          data: [8, 16, 32, 64, 128, 256, 512],
        },
      ],
    },
  };

  componentDidMount() {
    getRequest({ url: "api/vendors/" }).then((res) =>
      this.setState({ vendors: res.data.vendors, fetching: false })
    );
  }

  render() {
    return (
      <Container>
        <Text.Large>Analytics</Text.Large>
        <br />
        {this.state.fetching ? (
          <MDSpinner singleColor={theme.colors.main} />
        ) : (
          <React.Fragment>
            <Card />
            <Select
              value={{
                value: this.state.selectValue,
                label: this.state.selectLabel,
              }}
              options={[
                {
                  value: "salesByDestination",
                  label: "Sales by destination",
                },
                {
                  value: "salesByVendor",
                  label: "Sales by vendor",
                },
                {
                  value: "salesByDay",
                  label: "Sales by day of week",
                },
                {
                  value: "topSellingProducts",
                  label: "Top selling products",
                },
              ]}
              onChange={(data) =>
                this.setState({
                  selectValue: data.value,
                  selectLabel: data.label,
                })
              }
            />

            <br />
            <br />

            {this.state.selectValue === "salesByVendor" && (
              <Doughnut
                width={2}
                height={1}
                data={this.state.salesByVendor}
                options={{
                  title: {
                    display: true,
                    text: "Sales by vendor",
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                }}
              />
            )}

            {this.state.selectValue === "salesByDestination" && (
              <Pie
                style={{ display: "flex" }}
                width={2}
                height={1}
                data={this.state.salesByDestination}
                options={{
                  title: {
                    display: true,
                    text: "% share of sales by destination",
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                }}
              />
            )}

            {this.state.selectValue === "salesByDay" && (
              <Bar
                data={this.state.salesByDay}
                options={{
                  title: {
                    display: true,
                    text: "Average sales on days of the week",
                    fontSize: 20,
                  },
                  legend: {
                    display: false,
                    position: "right",
                  },
                }}
              />
            )}

            {this.state.selectValue === "topSellingProducts" && (
              <div>
                <Text.Large>1. Sling bag</Text.Large>
                <br />
                <Text>2. Pack bag</Text>
                <br />
                <Text light>3. Tote bag</Text>
              </div>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}
