import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import hintIcon from "../images/icons/feather/help-circle-green.svg";
import warningIcon from "../images/icons/feather/alert-circle-red.svg";

import Text from "../modules/Text";

export const QuestionMark = styled.img`
  margin-left: ${(p) => (p.lessMargin ? "3px" : "10px")};
  height: 18px;
  width: 18px;
  cursor: pointer;
  opacity: 0.7;
  ${(p) => p.margin && `margin: ${p.margin};`};
  &:hover {
    opacity: 1;
  }
`;

export const HintPopup = styled.div`
  position: absolute;
  padding: 10px;
  top: 18px;
  ${(p) => p.left && "right: 20px;"}
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  color: ${theme.colors.medDarkGrey};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  /* max-width: 350px; */
  /* max-width: 100%; */
  min-width: ${(p) => (p.narrow ? "200px" : "250px")};
`;

class Hint extends Component {
  state = { showHint: false };
  render() {
    return (
      <div style={{ position: "relative" }}>
        <QuestionMark
          src={this.props.type === "warning" ? warningIcon : hintIcon}
          onMouseEnter={() => this.setState({ showHint: true })}
          onMouseLeave={() => this.setState({ showHint: false })}
          lessMargin={this.props.lessMargin}
          margin={this.props.margin}
        />
        {this.state.showHint && (
          <HintPopup
            left={this.props.left}
            narrow={this.props.narrow}
            onMouseEnter={() => this.setState({ showHint: true })}
            onMouseLeave={() => this.setState({ showHint: false })}
          >
            <div>{this.props.hint}</div>
            {this.props.hintTwo && (
              <div style={{ marginTop: "5px" }}>{this.props.hintTwo}</div>
            )}
            {this.props.hintThree && (
              <div style={{ marginTop: "5px" }}>{this.props.hintThree}</div>
            )}
            {this.props.link && (
              <Text.Small
                extra="margin-top: 5px;"
                link
                light
                onClick={() => window.open(this.props.link)}
              >
                Learn more
              </Text.Small>
            )}
          </HintPopup>
        )}
      </div>
    );
  }
}

export default Hint;
