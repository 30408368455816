import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Row, SidebarItemMobile } from "../../../components";
import { theme } from "../../../modules/Theme";
import appIcon from "../../../images/dc-box-2021-white-grey.svg";
import { FiMenu } from "react-icons/fi";
import { items as getItems } from "./Items";

const showLabels = true;

class SidebarMobile extends Component {
  state = { expanded: false };

  UNSAFE_componentWillMount() {
    if (!Cookies.get("accessToken")) {
      this.props.history.push(`/login`);
    }
  }

  logout = () => {
    Cookies.remove("accessToken");
    this.props.history.push(`/login`);
  };

  render() {
    const items = getItems(
      this.props.history,
      this.props.location.pathname,
      this.logout
    );

    return (
      <StyledSideBar>
        <Row
          style={{
            justifyContent: "space-between",
            padding: "10px",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
          }}
        >
          <Logo
            src={appIcon}
            onClick={() => this.props.history.push("/dashboard")}
          />
          <FiMenu
            style={{ cursor: "pointer" }}
            size="30"
            color="white"
            onClick={() =>
              this.setState((prevState) => ({ expanded: !prevState.expanded }))
            }
          />
        </Row>
        {this.state.expanded && (
          <ItemsContainer>
            {items.map((item, i) => (
              <SidebarItemMobile
                marginBottom={item.marginBottom}
                showLabels={showLabels}
                text={item.text}
                icon={item.icon}
                active={item.active}
                onClick={item.onClick}
                key={i}
              />
            ))}
          </ItemsContainer>
        )}
      </StyledSideBar>
    );
  }
}

export default withRouter(SidebarMobile);

const Logo = styled.img`
  height: 30px;
`;

const StyledSideBar = styled.div`
  background: ${theme.colors.darkGreen};
  width: 100%;
  display: none;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
`;
