import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { FiArrowRight } from "react-icons/fi";

export const PageWrapper = styled.div`
  display: flex;
`;

export const ChangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 80px;
`;

export const PercentChange = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${(p) => (p.direction === "UP" ? "#49beaf" : "#f53b53")};
  ${(p) => p.direction === null && "color: rgba(0,0,0,0.7);"}
`;

export const Arrow = styled.img`
  height: 20px;
  opacity: 0.6;
`;

export const StatWrapper = styled.div`
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
  margin: 5px;
  border-radius: 5px;
  width: ${(p) => (p.width ? p.width : "100px")};
  text-align: center;
  @media (max-width: 800px) {
    width: calc(
      100% * (1 / ${(p) => (p.mobileWidth ? p.mobileWidth : "3")}) - 10px
    );
  }
`;

export const StatName = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.5);
`;

export const StatValue = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: ${(p) => (p.color ? "rgba(0,0,0,0.30)" : theme.colors.medDarkGrey)};
  ${(p) => p.opacity && `color: rgba(0,0,0,${p.opacity});`}
`;

class Stat extends Component {
  render() {
    const { change, direction, name, value, width } = this.props;

    return (
      <PageWrapper>
        {change !== null && (
          <ChangeWrapper>
            <PercentChange direction={direction}>{change}%</PercentChange>
            <FiArrowRight
              size="20"
              color={direction === "UP" ? "#49beaf" : "#f53b53"}
            />
          </ChangeWrapper>
        )}
        <StatWrapper width={width}>
          <StatName>{name}</StatName>
          <StatValue>{value}</StatValue>
        </StatWrapper>
      </PageWrapper>
    );
  }
}

export default Stat;
