const newGoogleCategories = {
  name: "All",
  subs: {
    accessories: {
      name: "Accessories",
      type: "category",
      subs: {
        "": { name: "All" },
        belts: { name: "Belts" },
        sunglasses: { name: "Sunglasses" },
        keychains: { name: "Keychains" },
        scarves: { name: "Scarves" },
        gloves_mittens: { name: "Gloves & Mittens" },
        protective_masks: { name: "Protective Masks" },
      },
    },
    bags_wallets: {
      name: "Bags & Wallets",
      type: "category",
      subs: {
        "": { name: "All" },
        backpacks: { name: "Backpacks" },
        wallets: { name: "Wallets" },
        duffel_bags_totes: { name: "Duffel Bags & Totes" },
        travel_luggage: { name: "Travel & Luggage" },
        purses_clutches: { name: "Purses & Clutches" },
      },
    },
    beauty: {
      name: "Beauty",
      type: "category",
      subs: {
        "": { name: "All" },
        face_body_care: { name: "Face & Body Care" },
        hair_care: { name: "Hair Care" },
        hygiene_toiletries: { name: "Hygiene & Toiletries" },
        make_up_cosmetics: { name: "Make-Up & Cosmetics" },
        perfumes_fragrances: { name: "Perfumes & Fragrances" },
        skin_nail_care: { name: "Skin & Nail Care" },
      },
    },
    food_drink: {
      name: "Food & Drink",
      type: "category",
      subs: {
        "": { name: "All" },
        beverages: { name: "Beverages" },
        coffee_tea: { name: "Coffee & Tea" },
        food_snacks: { name: "Food & Snacks" },
      },
    },
    health_wellness: {
      name: "Health & Wellness",
      type: "category",
      subs: {
        "": { name: "All" },
        anxiety_stress: { name: "Anxiety & Stress" },
        oral_dental_care: { name: "Oral & Dental Care" },
        pain_management: { name: "Pain Management" },
        vitamins_supplements: { name: "Vitamins & Supplements" },
      },
    },
    home_garden: {
      name: "Home & Garden",
      type: "category",
      subs: {
        "": { name: "All" },
        bathroom: { name: "Bathroom" },
        bedding_pillows_rugs: { name: "Bedding, Pillows & Rugs" },
        cleaning_products: { name: "Cleaning Products" },
        gardening_landscaping: { name: "Gardening & Landscaping" },
        home_appliances: { name: "Home Appliances" },
        mirrors_wall_art: { name: "Mirrors & Wall Art" },
        diffusers_oils_candles: { name: "Diffusers, Oils & Candles" },
        kitchen: { name: "Kitchen" },
        room_decor: { name: "Room Decor" },
      },
    },
    jewelry_watches: {
      name: "Jewelry & Watches",
      type: "category",
      subs: {
        "": { name: "All" },
        bracelets: { name: "Bracelets" },
        charms_pendants: { name: "Charms & Pendants" },
        earrings: { name: "Earrings" },
        necklaces: { name: "Necklaces" },
        rings: { name: "Rings" },
        watches: { name: "Watches" },
      },
    },
    clothing: {
      name: "Clothing",
      type: "category",
      subs: {
        "": { name: "All" },
        athletic: { name: "Athletic" },
        clothing_accessories: { name: "Clothing Accessories" },
        footwear: { name: "Footwear" },
        dresses: { name: "Dresses" },
        jackets_outerwear: { name: "Jackets & Outerwear" },
        pants_leggings: { name: "Pants & Leggings" },
        shirts_tops: { name: "Shirts & Tops" },
        formal_wear: { name: "Formal Wear" },
        headwear: { name: "Hats & Headwear" },
        swimwear: { name: "Swimwear" },
        underwear: { name: "Underwear" },
      },
    },
    party_event_gifts: {
      name: "Party, Event & Gifts",
      type: "category",
      subs: {
        "": { name: "All" },
        cards: { name: "Cards" },
        costumes: { name: "Costumes" },
        gifts: { name: "Gifts" },
        holidays_seasonal_events: { name: "Holidays & Seasonal Events" },
      },
    },
    parenting_kids: {
      name: "Parenting & Kids",
      type: "category",
      subs: {
        "": { name: "All" },
        parenthood_accessories: { name: "Parenthood & Accessories" },
        blankets_pillows: { name: "Blankets & Pillows" },
        children_baby_clothing: { name: "Children & Baby Clothing" },
      },
    },
    pets: {
      name: "Pets",
      type: "category",
      subs: {
        "": { name: "All" },
        treats_feeding: { name: "Treats & Feeding" },
        pet_toys: { name: "Pet Toys" },
        apparel_leashes: { name: "Apparel & Leashes" },
      },
    },
    sports_outdoors: {
      name: "Sports & Outdoors",
      type: "category",
      subs: {
        "": { name: "All" },
        fishing_boating: { name: "Fishing & Boating" },
        hiking_camping: { name: "Hiking & Camping" },
        sports_equipment_accessories: {
          name: "Sports Equipment & Accessories",
        },
      },
    },
    stationery_crafts: {
      name: "Stationery & Crafts",
      type: "category",
      subs: {
        "": { name: "All" },
        crafts: { name: "Crafts" },
        office_supplies: { name: "Office Supplies" },
        pens_pencils: { name: "Pens & Pencils" },
        stickers: { name: "Stickers" },
        notebooks: { name: "Notebooks" },
      },
    },
    tech_accessories: {
      name: "Tech Accessories",
      type: "category",
      subs: {
        "": { name: "All" },
        mobile_laptop_accessories: { name: "Mobile & Laptop Accessories" },
        speakers_headphones: { name: "Speakers & Headphones" },
        renewable_alternative_energy: {
          name: "Renewable & Alternative Energy",
        },
        electronics: { name: "Electronics" },
      },
    },
    toys_games: {
      name: "Toys & Games",
      type: "category",
      subs: {
        "": { name: "All" },
        card_board_games: { name: "Card & Board Games" },
        puzzles_brainteasers: { name: "Puzzles & Brainteasers" },
        toys: { name: "Toys" },
      },
    },
  },
};

export default newGoogleCategories;
