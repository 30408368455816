import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../../../../../../modules/Theme";

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  margin-bottom: 15px;
  width: 100%;
  justify-content: space-between;
  display: none;
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const NavItem = styled.div`
  font-size: 14px;
  font-weight: 300;
  padding: 4px;
  border-bottom: 3px solid
    ${(props) => (props.selected ? theme.colors.main : theme.colors.white)};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.main};
  }
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

class Navigation extends Component {
  render() {
    const { tab, setLocalState } = this.props;

    return (
      <NavWrapper>
        <NavItem
          selected={tab === "product"}
          onClick={() => setLocalState({ tab: "product" })}
        >
          Product
        </NavItem>
        <NavItem
          selected={tab === "description"}
          onClick={() => setLocalState({ tab: "description" })}
        >
          Description
        </NavItem>
        <NavItem
          selected={tab === "variants"}
          onClick={() => setLocalState({ tab: "variants" })}
        >
          Pricing
        </NavItem>
        {/* <NavItem
          selected={tab === "shipping"}
          onClick={() => setLocalState({ tab: "shipping" })}
        >
          Shipping
        </NavItem> */}
        <NavItem
          selected={tab === "images"}
          onClick={() => setLocalState({ tab: "images" })}
        >
          Images
        </NavItem>
      </NavWrapper>
    );
  }
}

export default Navigation;
