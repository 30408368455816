import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchNotifications,
  showAllChanges,
} from "../../views/products/ProductsActions";
import { getSettings } from "../../Selectors";
import { Row, Column } from "../../components/Layout";
import moment from "moment";
import { theme } from "../../modules/Theme";
import {
  getNotifications,
  getIsFetching,
  getPagination,
  getError,
} from "../../views/products/ProductsSelectors";

import { Container } from "../../components/Container";
import ButtonNew from "../../components/ButtonNew";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import Pagination from "../../components/Pagination";
import { getUrlParameter, resizeImage } from "../../modules/Format";
import Text from "../../modules/Text";
import Select from "react-select";
import TabNavigationLight from "../../components/TabNavigationLight";

export const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 5px;
`;

export const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${theme.colors.medDarkGrey};
`;

export const SubtitleSmall = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 30px;
`;

export const Date = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  margin-left: 20px;
  width: 100px;
  text-align: right;
`;

export const Image = styled.img`
  height: 80px;
  width: 80px;
  object-fit: ${(p) => (p.type === "SUPPLIER" ? "contain" : "cover")};
  margin-right: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
`;

export const Supplier = styled.span`
  font-weight: 700;
`;

export const NotificationsContainer = styled.div`
  display: flex;
  /* @media screen and (max-width: 800px) {
    flex-direction: column;
  } */
  flex-wrap: wrap;
`;

export const VariantsHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Variant = styled.div`
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #e6ebea;
  padding: 8px;
`;

export const ChangeWrapper = styled.div`
  border-radius: 4px;
  border-top: 1px solid #e6ebea;
  padding: 15px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ChangeType = styled.div`
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  color: white;
  margin-right: 20px;
  background: ${(p) =>
    p.type === "PRODUCT" ? theme.colors.main : theme.colors.orange};
`;

export const Product = styled.div`
  background: white;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  margin-top: 15px;
  margin-right: 15px;
  width: calc(100% / 2 - 15px);
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ChangeText = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
  margin-top: 10px;
`;

export const ChangeTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

export const ShippingProductTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-top: 5px;
  color: ${theme.colors.main};
`;

export const VariantName = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: ${theme.colors.medDarkGrey};
`;

export const MainColor = styled.span`
  font-weight: 600;
  color: ${theme.colors.darkGrey};
`;

export const VariantsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  notifications: getNotifications(state),
  settings: getSettings(state),
  pagination: getPagination(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchNotifications,
      showAllChanges,
    },
    dispatch
  ),
});

const typesObject = {
  ACTIVE: "Active",
  NEW: "New",
  PRICE: "Price",
  RETAIL_PRICE: "Retail Price",
  TITLE: "Title",
  QUANTITY: "Quantity",
  SHIPPING_RATE: "Shipping",
  XTRA_ITEM_SHIP_RATE: "Shipping",
  NAME: "Name",
  SHIPPING_OPTION: "Shipping",
};

const days = [
  { value: 1, label: "Last 24 Hours" },
  { value: 2, label: "Last 2 Days" },
  { value: 3, label: "Last 3 Days" },
  { value: 5, label: "Last 5 Days" },
  { value: 7, label: "Last 7 Days" },
  { value: 14, label: "Last 14 Days" },
  { value: 30, label: "Last 30 Days" },
];

const daysMap = {
  1: "Last 24 Hours",
  2: "Last 2 Days",
  3: "Last 3 Days",
  5: "Last 5 Days",
  7: "Last 7 Days",
  14: "Last 14 Days",
  30: "Last 30 Days",
};

class Notifications extends Component {
  state = {
    days: 7,
    type: "all",
  };

  fetchNotifications = (page, days, type) => {
    if (!page) {
      page = getUrlParameter("page", this.props.location) || 1;
    }

    if (!days) {
      days = getUrlParameter("days", this.props.location) || 7;
    }

    if (!type) {
      type = getUrlParameter("type", this.props.location) || "all";
    }

    this.props.fetchNotifications(page, days, type);
    this.props.history.push(`/notifications?p=1&days=${days}&type=${type}`);

    this.setState({
      days,
      type,
    });
  };

  componentDidMount = () => {
    this.fetchNotifications(null, null, null);
  };

  setDays = (days) => {
    this.fetchNotifications(1, days, null);
  };

  setType = (type) => {
    this.fetchNotifications(1, null, type);
  };

  renderChange = (change) => {
    const supplier = change.supplier;
    const shippingCountry = change.shipping_option__to_country;

    if (shippingCountry) {
      if (change.type === "ACTIVE") {
        const deactivated = change.new_value.toUpperCase() === "FALSE";

        return (
          <ChangeText>
            {shippingCountry} shipping rate{" "}
            <MainColor>{deactivated ? "removed" : "reactivated"}</MainColor>
          </ChangeText>
        );
      }

      return (
        <ChangeText>
          {shippingCountry} shipping rate{" "}
          {`${
            change.type === "XTRA_ITEM_SHIP_RATE" ? "for additional items" : ""
          }`}{" "}
          changed from <MainColor>{change.previous_value}</MainColor> to{" "}
          <MainColor>{change.new_value}</MainColor>
        </ChangeText>
      );
    }

    if (change.type === "ACTIVE") {
      const deactivated = change.new_value.toUpperCase() === "FALSE";

      let message = deactivated
        ? "Removed by supplier"
        : "Reactivated by supplier";

      if (supplier) {
        message = deactivated
          ? "Supplier profile removed"
          : "Supplier profile reactivated";
      }
      return (
        <ChangeText>
          <MainColor>{message}</MainColor>
        </ChangeText>
      );
    }

    if (change.previous_value === "NONE") {
      const message = `${typesObject[change.type]} changed to `;
      return (
        <ChangeText>
          {message}
          <MainColor>{change.new_value}</MainColor>
        </ChangeText>
      );
    } else {
      const message = `${typesObject[change.type]} changed from `;
      return (
        <ChangeText>
          {message}
          <MainColor>{change.previous_value}</MainColor> to{" "}
          <MainColor>{change.new_value}</MainColor>
        </ChangeText>
      );
    }
  };

  render() {
    const tabs = [
      {
        name: "All Changes",
        link: () => this.setType("all"),
        active: this.state.type === "all",
      },
      {
        name: "Price Changes",
        link: () => this.setType("price"),
        active: this.state.type === "price",
      },
      {
        name: "Inventory Changes",
        link: () => this.setType("quantity"),
        active: this.state.type === "quantity",
      },
      {
        name: "Shipping Changes",
        link: () => this.setType("shipping"),
        active: this.state.type === "shipping",
      },
    ];

    return (
      <Container>
        <Text.Large extra="margin-bottom: 20px;">
          Changes To Your Imported Products
        </Text.Large>
        <Subtitle>
          View relevant changes to products and supplier shipping rates for the
          products you've imported. Inventory is synced with your store on a
          regular basis.
        </Subtitle>
        <SubtitleSmall>
          We do not auto adjust prices in your store to prevent conflicts with
          your customized prices.
        </SubtitleSmall>
        <div style={{ width: "200px", marginBottom: "30px" }}>
          <Label>Timeline:</Label>
          <Select
            options={days}
            value={{
              value: this.state.days,
              label: daysMap[this.state.days],
            }}
            isSearchable={false}
            onChange={(data) => this.setDays(data.value)}
          />
        </div>
        <TabNavigationLight tabs={tabs} />

        <NotificationsContainer>
          {!this.props.isFetching &&
            !this.props.error &&
            this.props.notifications.length === 0 && (
              <Title style={{ marginTop: "20px" }}>
                There are no product changes in this range.
              </Title>
            )}

          {!this.props.isFetching &&
            this.props.error &&
            this.props.notifications.length === 0 && (
              <Title style={{ marginTop: "20px", color: "red" }}>
                {this.props.error}
              </Title>
            )}

          {this.props.isFetching ? (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                padding: "30px",
              }}
            >
              <MDSpinner singleColor="rgb(73,190,175)" size={40} />
            </div>
          ) : (
            <React.Fragment>
              {this.props.notifications.map((notification, idx) => {
                //variable was unused

                let changes = notification.changes;

                if (!notification.showAll) {
                  changes = changes.filter((_, i) => i < 3);
                }

                return (
                  <Product key={idx}>
                    <Row
                      style={{
                        alignItems: "center",
                        padding: "15px",
                      }}
                    >
                      <Image
                        type={notification.type}
                        src={resizeImage(notification.image, 150)}
                      />
                      <Column style={{ width: "100%" }}>
                        <Title>{notification.title}</Title>
                        <Supplier>{notification.supplier}</Supplier>
                        {notification.type === "SUPPLIER" && (
                          <Text.Small
                            light
                            link
                            extra="margin-top: 5px;"
                            onClick={() =>
                              this.props.history.push(
                                `/supplier-id/${notification.supplier_id}`
                              )
                            }
                          >
                            View Supplier
                          </Text.Small>
                        )}
                        {notification.product && (
                          <Text.Small
                            light
                            link
                            extra="margin-top: 5px;"
                            onClick={() =>
                              this.props.history.push(
                                `/product/${notification.product_id}`
                              )
                            }
                          >
                            View Product Page
                          </Text.Small>
                        )}
                      </Column>
                      {getUrlParameter("status", this.props.location) ===
                        "list" && (
                        <ButtonNew
                          type="secondary"
                          text="Edit"
                          onClick={() =>
                            this.props.history.push("/imported/list")
                          }
                          extra="width: 80px; margin-left: 15px;"
                        />
                      )}
                    </Row>
                    <Column style={{ width: "100%" }}>
                      {changes.map((change) => (
                        <ChangeWrapper key={change.id}>
                          <ChangeType type={change.type}>
                            {typesObject[change.type]}
                          </ChangeType>

                          <Column style={{ width: "100%" }}>
                            {change.variant_name && (
                              <ChangeTitle>{change.variant_name}</ChangeTitle>
                            )}

                            {this.renderChange(change)}
                          </Column>

                          <Date>
                            {change.date === "NONE"
                              ? "-"
                              : moment(change.date).format("MMM D, h:mma")}
                          </Date>
                        </ChangeWrapper>
                      ))}
                      {!notification.showAll &&
                        notification.changes.length > 3 && (
                          <ChangeWrapper>
                            <Text
                              link
                              onClick={() =>
                                this.props.showAllChanges(
                                  notification.product_id
                                )
                              }
                            >
                              {`View ${
                                notification.changes.length - 3
                              } more changes`}
                            </Text>
                          </ChangeWrapper>
                        )}
                    </Column>
                  </Product>
                );
              })}
            </React.Fragment>
          )}
        </NotificationsContainer>
        {this.props.isFetching === 0 && (
          <Pagination
            onClick={(page) =>
              this.props.fetchNotifications(
                page,
                getUrlParameter("days", this.props.location) || 7
              )
            }
            pagination={this.props.pagination}
          />
        )}
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
