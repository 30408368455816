import React, { useState } from "react";
import { FiHeart } from "react-icons/fi";
import FilledHeart from "../images/icons/filled-heart.svg";
import MDSpinner from "react-md-spinner";
import { Row } from "./Layout";
import SkeletonLoader from "./SkeletonLoader";
import notFound from "../images/not-found.png";
import { resizeImage } from "../modules/Format";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { Show } from "./Show";
import { useFavoriteSupplier } from "../hooks";

const CardWrapper = styled.div`
  position: relative;
  background: ${theme.colors.white};
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid ${theme.colors.lightBorder};
  width: 100%;
  max-width: 500px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid ${theme.colors.medGrey};
  }
`;

const CardSkeletonImage = styled(SkeletonLoader)`
  width: 100%;
  height: 250px;

  @media (max-width: 800px) {
    height: 130px;
  }
`;

const CardImage = styled.img`
  object-fit: ${(p) => (p.imageFit === "contain" ? "contain" : "cover")};
  object-position: 50% 50%;
  width: 100%;
  height: 250px;

  @media (max-width: 800px) {
    height: 130px;
  }
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  text-align: left;

  @media (max-width: 800px) {
    font-size: 14px;
    max-width: 99%;
    word-break: break-all;
  }
`;

const Icon = styled.img`
  height: 12px;
  opacity: 0.8;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.main};
  ${(p) => p.descriptionColor === "orange" && `color: ${theme.colors.orange}`};
  text-align: left;

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

const Subtext = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: ${theme.colors.medGrey};
  text-align: left;
  margin-left: 1px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const CardBottom = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1400px) {
    padding: 8px;
  }
`;

const CardHoverContainer = styled.div`
  background: white;
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  opacity: ${(p) => (p.show ? 1.0 : 0.0)};
`;

const FetchingContainer = styled.div`
  background: white;
  bottom: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  opacity: 0.9;
`;

const CardBottomSkeleton = styled.div`
  height: 50px;
`;

const Skeleton = ({ small }) => (
  <CardWrapper>
    <CardSkeletonImage small={small} />
    <CardBottomSkeleton />
  </CardWrapper>
);

export default function Card(props) {
  const { favorite, store: storeId, supplierID } = props;

  const {
    handleFavoriteSupplier,
    handleUnfavoriteSupplier,
    isFavorite,
    isLoading,
  } = useFavoriteSupplier(favorite, storeId, supplierID);

  const [state, setState] = useState({
    hover: false,
    imageError: false,
  });

  return (
    <CardWrapper
      onMouseEnter={() =>
        setState((prevState) => ({ ...prevState, hover: true }))
      }
      onMouseLeave={() =>
        setState((prevState) => ({ ...prevState, hover: false }))
      }
    >
      <CardImage
        onClick={props.onClick}
        onError={() =>
          setState((prevState) => ({ ...prevState, imageError: notFound }))
        }
        src={state.imageError || resizeImage(props.image, 350)}
        small={props.small}
        imageFit={props.imageFit}
      />

      <CardBottom>
        <div onClick={props.onClick}>
          <Name>{props.name}</Name>

          {props.subtext && <Subtext>{props.subtext}</Subtext>}
        </div>

        {props.description && (
          <Description descriptionColor={props.descriptionColor}>
            {props.description}
          </Description>
        )}

        {props.icon && (
          <Row style={{ alignItems: "center" }}>
            <Show
              fallback={
                <MDSpinner singleColor="rgba(0, 0, 0, 0.3)" size={22} />
              }
              when={!isLoading}
            >
              {isFavorite ? ( // if an icon prop exists, means it's a supplier card (we only want hearts for supplier cards at the moment)
                <img
                  alt="A Filled Heart"
                  src={FilledHeart}
                  width="22px"
                  height="22px"
                  onClick={handleUnfavoriteSupplier}
                />
              ) : (
                <FiHeart
                  size="22px"
                  color="rgba(0, 0, 0, 0.3)"
                  onClick={handleFavoriteSupplier}
                />
              )}
            </Show>

            {props.icon && (
              <Icon src={props.icon} style={{ marginLeft: "15px" }} />
            )}
          </Row>
        )}
      </CardBottom>

      {props.children && (
        <CardHoverContainer show={state.hover}>
          {props.children}
        </CardHoverContainer>
      )}

      {props.isFetching && (
        <FetchingContainer>
          <MDSpinner singleColor={theme.colors.main} size={50} />
        </FetchingContainer>
      )}
    </CardWrapper>
  );
}

Card.Skeleton = Skeleton;
