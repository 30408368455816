import { startCase } from "lodash";
import React from "react";
import styled from "styled-components";
import { ButtonNew } from ".";
import Text from "../modules/Text";
import { web } from "../modules/Config";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const NotFound = styled.img`
  height: 250px;
  margin-bottom: 30px;
  opacity: 0.6;
`;

export const Error404 = (props) => {
  const { error = "", errorCode = "", src } = props;

  return (
    <Container>
      <NotFound alt="Not Found" src={src} />

      <Text extra="font-weight: 600; font-size: 40px; margin: 0 0 30px 0;">
        {(errorCode && startCase(errorCode)) || "Oops!"}
      </Text>

      <Text extra="font-weight: 400; font-size: 20px; margin: 0 0 30px 0; text-align: center;">
        {error ||
          "Sorry, there was an unexpected error. Please contact support@dropcommerce.com"}
      </Text>

      <ButtonNew
        type="main"
        text="Return To Dashboard"
        onClick={() => window.open(`${web}/dashboard`)}
      />
    </Container>
  );
};
