import React from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { ButtonNew, Row } from "../../../components";
import checkGreen from "../../../images/icons/feather/check-green.svg";

const PackageOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 4px;
  padding: 20px;
  width: 350px;
  margin-right: 30px;
  @media (max-width: 800px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

const PackageOptionTitle = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 500;
`;

const PackageOptionPrice = styled.div`
  font-size: 50px;
  color: ${(p) => (p.sale ? theme.colors.main : theme.colors.medDarkGrey)};
  font-weight: 700;
  margin: 15px;
  ${(p) => p.slashed && "text-decoration: line-through;"}
`;

const PackageOptionIcon = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const PackageOptionDescription = styled.div`
  font-size: 18px;
  color: ${theme.colors.medDarkGrey};
  font-weight: 300;
  margin-bottom: 5px;
  text-align: center;
`;

const PackageOption = ({ checkout }) => (
  <PackageOptionWrapper>
    <PackageOptionTitle>Kickstart Your Store</PackageOptionTitle>
    <PackageOptionPrice sale>$249.00</PackageOptionPrice>

    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>
        100+ Preloaded Products
      </PackageOptionDescription>
    </Row>

    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>Professional Design</PackageOptionDescription>
    </Row>

    <Row align justify>
      <PackageOptionIcon src={checkGreen} />
      <PackageOptionDescription>
        3 Months Of Pro Plan ($267 Value)
      </PackageOptionDescription>
    </Row>

    <ButtonNew
      size="large"
      text="Buy Now - $249.00"
      width="200px"
      margin="20px 0 0 0"
      onClick={checkout}
    />
  </PackageOptionWrapper>
);

export default PackageOption;
