import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";

import { getSettings } from "../../../../views/supplier/SupplierSelectors";
import styled from "styled-components";
import Platform from "../../../../views/settings/views/store/components/Platform";
import { setPlatformUID } from "../../../../Actions";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setPlatformUID }, dispatch),
});

class Platforms extends Component {
  state = {};

  render() {
    const settings = get(this.props, ["settings"]);
    const shopifyUrl = get(settings, "shopify_url");
    const isConnected = get(settings, "shopify_token_saved");

    const platform = {
      name: "Shopify",
      is_connected: isConnected,
      uid: shopifyUrl,
    };

    return (
      <SubSection>
        <PlatformsRow>
          <Platform
            platform={platform}
            name="Shopify"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/shopify_nnjsqq.png"
          />
          {/* <Platform
            platform={platform}
            name="BigCommerce"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/bigcommerce_de9kjg.png"
          /> */}
          {/* <Platform
            platform={platform}
            name="WooCommerce"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602698747/WooCommerce_logo_Woo_Commerce_ca5wos.png"
          />
          <Platform
            platform={platform}
            name="Wix"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1603593623/wix-logo_y5l1um.png"
          /> */}
        </PlatformsRow>
      </SubSection>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Platforms)
);

const PlatformsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 25px;
  width: 100%;
  @media (max-width: 950px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
