import styled from "styled-components";
import { theme } from "../modules/Theme";

export const BorderBox = styled.div`
  width: ${(p) => p.width || "100%"};
  /* background: white; */
  display: flex;
  flex-direction: ${(p) => (p.row ? "row" : "column")};
  justify-content: ${(p) => (p.justify ? "center" : "flex-start")};
  align-items: ${(p) => (p.align ? "center" : "flex-start")};
  padding: ${(p) => p.padding || "20px"};
  margin: ${(p) => p.margin || "0px"};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;

  ${(p) =>
    p.hover &&
    `
    &:hover{
      cursor: pointer;
      border: 1px solid ${theme.colors.medGrey};
    }
  `}
`;
