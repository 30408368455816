import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import Shopify from "./shopify/Shopify";
import List from "./list/List";

import TabNavigation from "../../../components/TabNavigation";
import { inUrl } from "../../../modules/Format";
import { get } from "lodash";

class Imported extends React.Component {
  render() {
    const page = get(this.props, ["match", "params", "page"]);
    const history = this.props.history;
    const isList = inUrl("/imported/list");
    const isStore = inUrl("/imported/store");

    const tabOptions = [
      {
        name: "Import List",
        description: "Edit pricing & info before importing.",
        active: isList,
        onClick: !isList ? () => history.push("/imported/list") : null,
      },
      {
        name: "Imported To Store",
        description: "View products you've already imported.",
        active: isStore,
        onClick: !isStore ? () => history.push("/imported/store") : null,
      },
    ];

    return (
      <>
        <TabNavigation
          options={tabOptions}
          data={{
            page,
          }}
        />
        <Switch>
          <Route exact path="/imported/store" component={Shopify} />
          <Route exact path="/imported/list" component={List} />
        </Switch>
      </>
    );
  }
}

export default withRouter(Imported);
