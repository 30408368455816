import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Column } from "../../../components/Layout";
import ButtonNew from "../../../components/ButtonNew";
import Text from "../../../modules/Text";
import { theme } from "../../../modules/Theme";
import createNotification from "../../../modules/Notification";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
});

const StyledInput = styled.input`
  border: 0px;
  font-size: 20px;
  font-weight: 400;
  background: transparent;
  color: ${theme.colors.medDarkGrey};
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
  ::placeholder {
    color: ${theme.colors.medGrey};
  }
  outline: 0px;
  width: 100%;
`;

const Name = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Email = styled.div`
  font-size: 20px;
  font-weight: 300;
`;

const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medGrey};
  margin-right: 15px;
`;

const Section = styled.div`
  display: flex;
  border-top: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  align-items: center;
`;

const SupplierWrapper = styled.div`
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 5px;
  width: 1000px;
  margin: 15px;
`;

const Error = styled.div`
  background: #e37676;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: white;
  padding: 10px;
  white-space: nowrap;
  ${(p) =>
    p.onClick &&
    `
    cursor: pointer;
  `}
`;

class Supplier extends Component {
  state = { note: this.props.supplier.note, noteChanged: false };

  render() {
    const { supplier, manageSupplier } = this.props;
    return (
      <SupplierWrapper>
        <Section style={{ border: "0px" }}>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Column>
              <Name>{supplier.name}</Name>
              <Email>
                {supplier.id} - {supplier.email}
              </Email>
              <Text
                light
                link
                onClick={() => window.open(`https://${supplier.website}`)}
              >
                {supplier.website}
              </Text>
              {supplier.subdomain !== "" && (
                <Text light>Subdomain: {supplier.subdomain}</Text>
              )}
              <Text light>Created at: {supplier.created_at} UTC</Text>
            </Column>
            <Column>
              <Row style={{ justifyContent: "flex-end" }}>
                {!supplier.approved && (
                  <ButtonNew
                    isFetching={supplier.isFetching_initial_approval}
                    type={supplier.approved ? "main-light" : "main"}
                    text={supplier.approved ? "Approved" : "Approve For Setup"}
                    onClick={() =>
                      manageSupplier(supplier.id, "initial_approval")
                    }
                    extra="margin-right: 15px;"
                  />
                )}
                {supplier.approved && supplier.issues === 0 && (
                  <ButtonNew
                    isFetching={supplier.isFetching_set_live}
                    type={supplier.status === "ACTIVE" ? "main-light" : "main"}
                    text={
                      supplier.status === "ACTIVE" ? "Live" : "Set Profile Live"
                    }
                    onClick={() => manageSupplier(supplier.id, "set_live")}
                    extra="margin-right: 15px;"
                  />
                )}
                <ButtonNew
                  isFetching={supplier.isFetching_rejected}
                  type={supplier.status === "REJECTED" ? "main-light" : "main"}
                  text={
                    supplier.status === "REJECTED" ? "Fired" : "Fire Supplier"
                  }
                  onClick={() => manageSupplier(supplier.id, "rejected")}
                />
              </Row>
              <Row style={{ justifyContent: "flex-end" }}>
                <Text extra="margin: 20px 0" green>
                  Status: {supplier.status}
                </Text>
              </Row>
            </Column>
          </Row>
        </Section>

        <Section>
          <SectionHeader>Notes</SectionHeader>
          <Row style={{ alignItems: "center", width: "100%" }}>
            <StyledInput
              onChange={(e) =>
                this.setState({ note: e.target.value, noteChanged: true })
              }
              placeholder="Click to type notes"
              noNotes={supplier.note === ""}
              value={this.state.note}
            />
            {this.state.noteChanged && (
              <ButtonNew
                type="main"
                text="Save Note"
                onClick={() => {
                  manageSupplier(supplier.id, "save_note", this.state.note);
                  createNotification({
                    type: "success",
                    title: "Success",
                    message: "Note saved",
                  });
                }}
                extra="width: 100px;"
              />
            )}
          </Row>
        </Section>

        <Section>
          <SectionHeader>Issues</SectionHeader>
          <Row style={{ flexWrap: "wrap" }}>
            {supplier.no_shopify && <Error>Shopify Not Connected</Error>}

            {!supplier.active_shipping_options && (
              <Error>No Active Shipping Rates</Error>
            )}
            {supplier.no_values && <Error>No Values</Error>}
            {supplier.no_payment && <Error>Payment Method Missing</Error>}
            {supplier.profile_incomplete && <Error>Incomplete Info</Error>}
            {supplier.product_count === 0 && <Error>No Products</Error>}
            {!supplier.supplier_agreement && (
              <Error>Agreement Not Completed</Error>
            )}
            {supplier.description_links > 0 && (
              <Error>{`${supplier.description_links} Products With Description Links`}</Error>
            )}
            {supplier.uncategorized_products > 0 && (
              <Error
                onClick={() =>
                  this.props.history.push(
                    `/admin/categorize?supplier=${supplier.id}&type=add`
                  )
                }
              >{`${supplier.uncategorized_products} Uncategorized Products`}</Error>
            )}
          </Row>
        </Section>
      </SupplierWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Supplier)
);
