import { get } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { setPlatformUID } from "../../../../Actions";
import { getIsFetching, getSettings } from "../../../../Selectors";
import { SettingsPageWrapper } from "../../../../views/settings/SettingsStyle";
import Platform, { WooCommercePlatform } from "./components/Platform";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setPlatformUID }, dispatch),
});

const PlatformsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

class Platforms extends Component {
  render() {
    const store = get(this.props, ["settings", "store"]);
    const platform = get(store, "platform");
    const id = get(store, "id");
    return (
      <SettingsPageWrapper>
        <PlatformsRow>
          {/* <Text light>
            Don't use any of these currently supported platforms?
          </Text>
          <Text link margin="0 0 20px 0">
            Click here to use the manual integration
          </Text> */}
          <Platform
            platform={platform}
            name="Shopify"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/shopify_nnjsqq.png"
          />
          <Platform
            platform={platform}
            name="Shoplazza"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1676401089/b1b23joc7044zsf0e3ah.svg"
          />
          <Platform
            platform={platform}
            name="Wix"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1603593623/wix-logo_y5l1um.png"
          />
          <Platform
            platform={platform}
            name="BigCommerce"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602692803/bigcommerce_de9kjg.png"
          />
          <Platform
            platform={platform}
            name="Manual"
            logo="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logos/manual-integration.png"
          />
          {/* <Platform
            platform={platform}
            name="WooCommerce"
            logo="https://res.cloudinary.com/dropcommerce/image/upload/v1602698747/WooCommerce_logo_Woo_Commerce_ca5wos.png"
          /> */}
          <WooCommercePlatform store={id} />
        </PlatformsRow>
      </SettingsPageWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Platforms);
