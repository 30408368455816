import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import { Checkbox } from "@material-ui/core";

import { get } from "lodash";
import TabNavigationLight from "./TabNavigationLight";
import Pagination from "./Pagination";
import { withRouter } from "react-router-dom";
import SkeletonLoader from "./SkeletonLoader";
import ButtonNew from "./ButtonNew";
import Hint from "./Hint";
import NoResults from "./NoResults";
import moment from "moment";
import { getRequest, updateStateDirectly } from "../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUrlParameter, objectToQueryString } from "../modules/Format";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ getRequest, updateStateDirectly }, dispatch),
});

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /* border: 1px solid ${theme.colors.lightBorder}; */
  /* border-radius: 4px; */
  margin: ${(p) => p.margin};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
`;

const ColumnName = styled.div`
  display: flex;
  width: ${(p) => (p.width ? p.width : "110px")};
  color: ${theme.colors.midGrey};
  font-size: 14px;
  font-weight: 500;
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none;"}
    width: 120px;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  align-items: center;
  padding: 20px 15px 20px 15px;
  ${(p) => !p.disabled && p.rowBodyOnClick && "cursor: pointer;"}
  ${(p) => p.selected && `background: ${theme.colors.lightHighlight}`};
  &:hover {
    background: ${(p) =>
      p.selected ? theme.colors.medHighlight : theme.colors.greyHighlight};
    ${(p) => p.disabled && "background: white"}
  }
`;

const SkeletonBlock = styled(SkeletonLoader)`
  height: 15px;
  min-width: ${(p) => p.width};
`;

const RowValue = styled.div`
  display: flex;
  width: ${(p) => (p.width ? p.width : "110px")};
  color: ${(p) => (p.color ? p.color : theme.colors.darkerGrey)};
  font-size: 14px;
  font-weight: ${(p) => (p.bold ? 500 : 300)};
  @media screen and (max-width: 800px) {
    ${(props) => props.canHide && "display: none;"}
    min-width: 120px;
    max-width: 120px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedText = styled.div`
  color: ${theme.colors.medDarkGrey};
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
`;

const Thumbnail = styled.img`
  height: 60px;
  width: 60px;
  object-fit: ${(props) => (props.crop ? "cover" : "contain")};
  border: 1px solid ${theme.colors.lightBorder};
  margin-right: 20px;
  @media (max-width: 800px) {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
`;

const TabsContainer = styled.div`
  margin-bottom: 10px;
`;

const StatusCircle = styled.div`
  background: ${(p) => p.color};
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

export const generateTableActionButtons = (actions) => {
  return actions.map((action, i) => (
    <ButtonNew
      type={action.type}
      text={action.text}
      isFetching={action.isFetching}
      onClick={(e) => {
        e.stopPropagation();
        action.onClick();
      }}
      key={i}
    />
  ));
};

export const StatusBubble = ({ color }) => {
  const colorMap = {
    grey: theme.colors.medLightGrey,
    green: theme.colors.main,
    lightGreen: theme.colors.mainLight,
    red: theme.colors.darkRed,
    orange: theme.colors.orange,
    yellow: theme.colors.yellow,
  };

  return (
    <StatusCircle color={get(colorMap, color) || theme.colors.medLightGrey} />
  );
};

class Table extends Component {
  state = { isFetching: false };

  updateQueryString = (key, value) => {
    const queryString = this.getQueryString(key, value);
    this.props.history.push(`${this.props.tabsRoute}${queryString}`);
    this.getRequest(queryString);
  };

  getQueryString = (key = null, value = null) => {
    let queryObject = {};
    const queryParams = get(this.props, ["action", "queryParams"]);
    queryParams &&
      queryParams.forEach((param) => {
        if (key && value && param.name === key) {
          queryObject[key] = value;
        } else {
          queryObject[param.name] =
            getUrlParameter(param.name, this.props.location) || param.default;
        }
      });
    return objectToQueryString(queryObject);
  };

  getRequest = (queryString) => {
    this.setState({ isFetching: true }, () => {
      this.props
        .getRequest({
          endpoint: this.props.action.endpoint,
          queryString: queryString,
        })
        .then((response) => {
          const getResponseFields = this.props.action.fields.map((field) => [
            field,
            get(response, field),
          ]);
          this.props.updateStateDirectly(
            [{ fields: getResponseFields }],
            this.props.reducer || "base"
          );
          this.setState({ isFetching: false });
        });
    });
  };

  componentDidMount() {
    if (this.props.action) {
      const queryString = this.getQueryString();
      this.getRequest(queryString);
    }
  }

  renderRowValue = (column, rowValue) => {
    let statusColor = get(column.statusMap, rowValue);

    if (column.customValue) {
      rowValue = column.customValue(rowValue);
    }

    if (column.labelMap) {
      rowValue = get(column.labelMap, rowValue) || rowValue;
    }

    if (column.field === "date" || column.type === "date") {
      rowValue = moment(rowValue).format("MMM Do YYYY");
    } else if (column.field === "image") {
      rowValue
        ? (rowValue = (
            <Thumbnail src={rowValue} onError={() => console.log("onerror")} />
          ))
        : (rowValue = (
            <Hint
              type="warning"
              hint="Products without images won’t appear in the Marketplace."
            />
          ));
    } else if (column.type === "boolean") {
      rowValue = rowValue === true ? "True" : "False";
    } else if (column.type === "on_off") {
      rowValue = rowValue === true ? "On" : "Off";
    } else if (column.type === "price") {
      const value = rowValue || 0;
      rowValue = `$${value.toFixed(2)}`;
    }

    if (statusColor) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StatusBubble color={statusColor} />
          <div>{rowValue}</div>
        </div>
      );
    }

    return rowValue;
  };

  getTabData = () => {
    let data = {};
    this.props.action.queryParams &&
      this.props.action.queryParams.forEach((param) => {
        if (param.useForTabs) {
          data["name"] = param.name;
          data["default"] = param.default;
        }
      });
    return data;
  };

  selectAllRows = (bool) => {
    this.props.updateStateDirectly([
      {
        key: "cart_items",
        fields: [["selected", bool]],
      },
    ]);
  };

  selectRow = (id, bool) => {
    this.props.updateStateDirectly([
      {
        key: "cart_items",
        fields: [["selected", bool]],
        matchArrayElement: (itemId) => itemId === id,
      },
    ]);
  };

  renderTabs = () => {
    if (this.props.tabs) {
      const tabData = this.getTabData();
      const activeTab =
        getUrlParameter(tabData.name, this.props.location) || tabData.default;
      return (
        <TabsContainer>
          <TabNavigationLight
            tabs={this.props.tabs}
            activeTab={activeTab}
            onClick={(value) => this.updateQueryString(tabData.name, value)}
          />
        </TabsContainer>
      );
    }
  };

  render() {
    const {
      manualRows,
      columns,
      selectActions,
      rowBodyOnClick,
      noResultsData,
      margin,
      baseState,
      rowsLocation,
    } = this.props;

    let rows = baseState;

    // Used when rows are passed in manually instead of grabbed from state
    if (manualRows) {
      rows = manualRows;
    } else {
      rows &&
        rowsLocation.forEach((location) => {
          rows = get(rows, location);
        });
    }

    const noResults =
      !this.state.isFetching && !this.props.isFetching && rows.length === 0;

    const selectedRows = rows.filter((row) => row.selected);

    const selectedCount = selectedRows.length;

    return (
      <Container margin={margin}>
        {this.renderTabs()}

        {!noResults && (
          <Header>
            {selectActions && (
              <ColumnName width="50px">
                <Checkbox
                  color="primary"
                  checked={rows && selectedCount === rows.length}
                  onChange={() =>
                    this.selectAllRows(selectedCount < rows.length)
                  }
                />
              </ColumnName>
            )}
            {selectedCount > 0 ? (
              <HeaderRow>
                <SelectedText>{`${selectedCount} selected`}</SelectedText>
                {selectActions.map((action, i) => (
                  <ButtonNew
                    text={action.text}
                    icon={action.icon}
                    type="secondary"
                    onClick={action.onClick}
                    margin="0 10px 0 0"
                    key={i}
                  />
                ))}
              </HeaderRow>
            ) : (
              <HeaderRow>
                {columns.map((column) => (
                  <ColumnName
                    key={column.name}
                    canHide={
                      this.props.hideMobile &&
                      this.props.hideMobile.includes(column.name)
                    }
                    width={column.width}
                  >
                    {column.name}
                    {column.hint && (
                      <Hint
                        maxWidth="64px"
                        lessMargin
                        narrow
                        hint={column.hint}
                      />
                    )}
                  </ColumnName>
                ))}
              </HeaderRow>
            )}
          </Header>
        )}

        {noResults && noResultsData && (
          <NoResults
            noBorder
            title={noResultsData.title}
            message={noResultsData.message}
            buttonLink={noResultsData.onClick}
            buttonText={noResultsData.buttonText}
          />
        )}

        {(this.state.isFetching || this.props.isFetching) && (
          <React.Fragment>
            {[1, 2, 3, 4, 5, 6].map((_, i) => (
              <Row key={i} disabled>
                {columns.map((column, i) => (
                  <div
                    key={i}
                    style={{
                      width: column.width || "100px",
                    }}
                  >
                    <SkeletonBlock width="70px" />
                  </div>
                ))}
              </Row>
            ))}
          </React.Fragment>
        )}

        {!this.state.isFetching &&
          !this.props.isFetching &&
          rows.map((row, i) => (
            <Row
              key={i}
              rowBodyOnClick={rowBodyOnClick}
              selected={row.selected}
              onClick={() => rowBodyOnClick && rowBodyOnClick(row.id)}
            >
              {selectActions && (
                <ColumnName width="50px">
                  <Checkbox
                    color="primary"
                    checked={row.selected}
                    onChange={(e) => {
                      // THIS DOES NOT SEEM TO BE WORKING
                      e.stopPropagation();
                      this.selectRow(row.id, !row.selected);
                    }}
                  />
                </ColumnName>
              )}
              {columns.map((column, i) => {
                const rowValue = get(row, column.field);
                return (
                  <RowValue
                    key={i}
                    style={{ overflowWrap: "anywhere" }}
                    width={column.width}
                    bold={column.bold}
                    color={column.color}
                    canHide={
                      this.props.hideMobile &&
                      this.props.hideMobile.includes(column.name)
                    }
                  >
                    {this.renderRowValue(column, rowValue)}
                  </RowValue>
                );
              })}
            </Row>
          ))}

        {!!get(this.props, ["baseState", "pagination", "first_item"]) && (
          <Pagination
            onClick={(value) => this.updateQueryString("page", value)}
            pagination={get(this.props, ["baseState", "pagination"])}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table));
