import { get } from "lodash";

const getLocalState = (state) => get(state, "products");

export const getDashboardData = (state) => getLocalState(state).dashboardData;
export const getUnsyncedProducts = (state) =>
  getLocalState(state).unsyncedProducts;
export const getProducts = (state) => getLocalState(state).products;
export const getNotifications = (state) => getLocalState(state).notifications;
export const getImportList = (state) => getLocalState(state).importList;
export const getImportedProducts = (state) =>
  getLocalState(state).importedProducts;
export const getIsFetchingDashboard = (state) =>
  getLocalState(state).isFetchingDashboard;
export const getIsFetchingImportList = (state) =>
  getLocalState(state).isFetchingImportList;
export const getIsFetchingSupplier = (state) =>
  getLocalState(state).isFetchingSupplier;
export const getIsFetchingProducts = (state) =>
  getLocalState(state).isFetchingProducts;
export const getIsFetchingImportedProducts = (state) =>
  getLocalState(state).isFetchingImportedProducts;
export const getIsFetchingSuppliers = (state) =>
  getLocalState(state).isFetchingSuppliers;
export const getIssueCount = (state) => getLocalState(state).issueCount;
export const getWarningCount = (state) => getLocalState(state).warningCount;
export const getProduct = (state) => getLocalState(state).product;
export const getPageImported = (state) => getLocalState(state).pageImported;
export const getPageImporting = (state) => getLocalState(state).pageImporting;
export const getProductTags = (state) => getLocalState(state).productTags;
export const getProductSaved = (state) => getLocalState(state).productSaved;
export const getIsImporting = (state) => getLocalState(state).isImporting;
export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getPagination = (state) => getLocalState(state).pagination;
export const getProductIds = (state) => getLocalState(state).productIds;
export const getSupplier = (state) => getLocalState(state).supplier;
export const getSuppliers = (state) => getLocalState(state).suppliers;
export const getError = (state) => getLocalState(state).error;
export const getStoreCategories = (state) =>
  getLocalState(state).storeCategories; // SHOPIFY STORE COLLECTIONS
export const getAllStoreProducts = (state) =>
  getLocalState(state).allStoreProducts;
export const getIsFetchingReview = (state) =>
  getLocalState(state).isFetchingReview;
export const getIsFetchingRefund = (state) =>
  getLocalState(state).isFetchingRefund;
