import React from "react";
import styled from "styled-components";
import { theme } from "../../../modules/Theme";
import { truncateText } from "../../../modules/Format";
import Icon from "../../../views/inbox/components/Icon";
import { Row, Column } from "../../../components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { getSettings } from "../../../Selectors";
import { getSettings as getSupplierSettings } from "../../../views/supplier/SupplierSelectors";
import { markConversationRead } from "../../../Actions";
import { PopupMenu } from "../../../components/PopupMenu";
import { FiMoreVertical } from "react-icons/fi";

const Card = styled("div")`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;

  ${(props) => {
    if (!props.archived) {
      return `
        &:hover {
          background-color: ${theme.colors.lightBorder};
        }

        ${
          props.active &&
          `
          background-color: ${theme.colors.lightGrey};
        `
        }
      `;
    }
  }}
`;

const Title = styled.div`
  ${(props) => props.highlight && "font-weight: 600;"}
  color: ${theme.colors.medGrey};
  width: 100%;

  @media screen and (max-width: 800px) {
    font-size: 15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
`;

const PreviewText = styled.div`
  font-size: 12px;
  color: ${theme.colors.grey};
  margin-top: 5px;
`;

function Conversation(props) {
  const {
    conversation,
    onClick,
    isActiveConversation,
    mostRecentMessage,
    archived,
  } = props;

  const title = truncateText(conversation.other_user, 12);

  const userId = get(props, ["settings", "user_id"]);

  const isUserSupplier = !get(props, ["settings", "user_id"]);

  const supplierUserId = get(props, ["supplierSettings", "user_id"]);

  let isActiveUser = false;

  if (isUserSupplier) {
    isActiveUser =
      conversation.messages.length !== 0
        ? (isActiveUser = mostRecentMessage.sender_id === supplierUserId)
        : false;
  } else {
    isActiveUser =
      conversation.messages.length !== 0
        ? (isActiveUser = mostRecentMessage.sender_id === userId)
        : false;
  }

  return (
    <Card
      archived={archived}
      active={!archived && isActiveConversation}
      onClick={archived ? null : onClick}
    >
      <Row align style={{ width: "100%", justifyContent: "space-between" }}>
        <Icon name={title} color={archived ? "gray" : conversation.color} />

        <Column style={{ flex: 1 }}>
          <Title highlight={props.boldForUnread}>{title}</Title>

          {mostRecentMessage && (
            <PreviewText>
              {isActiveUser ? "You" : title}:{" "}
              {mostRecentMessage && truncateText(mostRecentMessage.text, 12)}
            </PreviewText>
          )}
        </Column>

        <PopupMenu
          popupMenuOptions={props.actions}
          icon={{ element: FiMoreVertical, color: "#839996" }}
          optionsWidth="120px"
          extra="top: 10px; right: 30px;"
        />
      </Row>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ markConversationRead }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
