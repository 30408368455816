import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MDSpinner from "react-md-spinner";
import { fetchShopifyPreview } from "../../../views/admin/AdminActions";
import {
  getIsFetching,
  getShopifyPreview,
} from "../../../views/admin/AdminSelectors";
import Text from "../../../modules/Text";
import { ButtonNew, Input, Row } from "../../../components";
// import { get } from "lodash";
import Select from "react-select";

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  shopifyPreview: getShopifyPreview(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchShopifyPreview,
    },
    dispatch
  ),
});

class ShopifyPreview extends Component {
  state = {
    accountType: "store",
    accountId: 0,
    objectType: "product",
    objectId: 0,
  };

  preview = () => {
    this.props.fetchShopifyPreview(this.state);
  };

  render() {
    // const data = get(this.props, "shopifyPreview");

    return (
      <PageWrapper>
        {this.props.isFetching > 0 ? (
          <MDSpinner singleColor="rgb(255, 255, 255)" size={50} />
        ) : (
          <React.Fragment>
            <Row align style={{ marginBottom: "20px" }}>
              <Text extra="margin-right: 20px; width: 200px;">
                Account Type
              </Text>
              <div style={{ width: "200px" }}>
                <Select
                  options={[
                    {
                      value: "SUPPLIER",
                      label: "Supplier",
                    },
                    {
                      value: "STORE",
                      label: "Store",
                    },
                  ]}
                  onChange={(data) =>
                    this.setState({ accountType: data.value })
                  }
                />
              </div>
            </Row>
            <Row align style={{ marginBottom: "20px" }}>
              <Text extra="margin-right: 20px; width: 200px;">Account ID</Text>
              <Input
                value={this.state.accountId}
                type="number"
                onChange={(e) => this.setState({ accountId: e.target.value })}
                extraStyle="width: 200px;"
              />
            </Row>
            <Row align style={{ marginBottom: "20px" }}>
              <Text extra="margin-right: 20px; width: 200px;">Object Type</Text>
              <div style={{ width: "200px" }}>
                <Select
                  options={[
                    {
                      value: "PRODUCT",
                      label: "Product",
                    },
                    {
                      value: "ORDER",
                      label: "Order",
                    },
                  ]}
                  onChange={(data) => this.setState({ objectType: data.value })}
                />
              </div>
            </Row>
            <Row align style={{ marginBottom: "20px" }}>
              <Text extra="margin-right: 20px; width: 200px;">Object ID</Text>
              <Input
                value={this.state.objectId}
                type="number"
                onChange={(e) => this.setState({ objectId: e.target.value })}
                extraStyle="width: 200px;"
              />
            </Row>
            <ButtonNew text="Preview" type="main" onClick={this.preview} />
          </React.Fragment>
        )}
      </PageWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShopifyPreview)
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
